import { Schema_Brand } from './brands';
import { Schema_SubstanceBrands } from './substance-brands';
import { Schema_Deal } from './deal';
import { Schema_Company } from './company';
import { Schema_CompanyDirectoryLeads } from './company-directory-leads';
import { Schema_ContactPerson } from './contact-person';
import { Schema_Request } from './request';
import { Schema_DealMessage } from './deal-message';
import { Schema_DoseForm } from './dose-forms';
import { Schema_Manufacturer } from './manufacturers';
import { Schema_Product } from './product';
import { Schema_ProductList } from './product-list';
import { Schema } from './schema';
import { Schema_SupplyUnitPrice } from './supply-unit-price';
import { Schema_Termsheet } from './termsheet';
import { Schema_TherapyArea } from './therapy-areas';
import { Schema_Substance } from './substance';
import { Schema_SubstancesTherapyAreas } from './substances-therapy-areas';

export const substanceBrandsSchema: Schema<Schema_SubstanceBrands> = {
  tableName: 'substance_brands',
  aggreagate: 'substance_brands_aggregate',
  delete: 'delete_substance_brands',
  update: 'update_substance_brands',
  insert: 'insert_substance_brands',
};

export const brandsSchema: Schema<Schema_Brand> = {
  tableName: 'brands',
  aggreagate: 'brands_aggregate',
  delete: 'delete_brands',
  update: 'update_brands',
  insert: 'insert_brands',
};

export const doseFormsSchema: Schema<Schema_DoseForm> = {
  tableName: 'forms',
  aggreagate: 'forms_aggregate',
  delete: 'delete_forms',
  update: 'update_forms',
  insert: 'insert_forms',
};

export const therapyAreasSchema: Schema<Schema_TherapyArea> = {
  tableName: 'therapy_areas',
  aggreagate: 'therapy_areas_aggregate',
  delete: 'delete_therapy_areas',
  update: 'update_therapy_areas',
  insert: 'insert_therapy_areas',
};

export const substanceTherapyAreasSchema: Schema<Schema_SubstancesTherapyAreas> = {
  tableName: 'substances_therapy_areas',
  aggreagate: 'substances_therapy_areas_aggregate',
  delete: 'delete_substances_therapy_areas',
  update: 'update_substances_therapy_areas',
  insert: 'insert_substances_therapy_areas',
};

export const manufacturersSchema: Schema<Schema_Manufacturer> = {
  tableName: 'manufacturers',
  aggreagate: 'manufacturers_aggregate',
  delete: 'delete_manufacturers',
  update: 'update_manufacturers',
  insert: 'insert_manufacturers',
};

export const dealMessageSchema: Schema<Schema_DealMessage> = {
  tableName: 'deals_messages',
  aggreagate: 'deals_messages_aggregate',
  delete: 'delete_deals_messages',
  update: 'update_deals_messages',
  insert: 'insert_deals_messages',
};

export const productsSchema: Schema<Schema_Product> = {
  tableName: 'products_v2',
  aggreagate: 'products_v2_aggregate',
  delete: 'delete_products_v2',
  update: 'update_products_v2',
  insert: 'insert_products_v2',
};

export const requestSchema: Schema<Schema_Request> = {
  tableName: 'requests',
  aggreagate: 'requests_aggregate',
  delete: 'delete_requests',
  update: 'update_requests',
  insert: 'insert_requests',
};
export const dealSchema: Schema<Schema_Deal> = {
  tableName: 'deals',
  aggreagate: 'deals_aggregate',
  delete: 'delete_deals',
  update: 'update_deals',
  insert: 'insert_deals',
};

export const productListSchema: Schema<Schema_ProductList> = {
  tableName: 'my_products_list',
  aggreagate: 'my_products_list_aggregate',
  delete: 'delete_my_products_list',
  update: 'update_my_products_list',
  insert: 'insert_my_products_list',
};
export const companyDirectoryLeadsSchema: Schema<Schema_CompanyDirectoryLeads> = {
  tableName: 'company_directory_leads',
  aggreagate: 'company_directory_leads_aggregate',
  delete: 'delete_company_directory_leads',
  update: 'update_company_directory_leads',
  insert: 'insert_company_directory_leads',
};
export const substanceSchema: Schema<Schema_Substance> = {
  tableName: 'substances',
  aggreagate: 'substances_aggregate',
  delete: 'delete_substances',
  update: 'update_substances',
  insert: 'insert_substances',
};
export const companySchema: Schema<Schema_Company> = {
  tableName: 'companies',
  aggreagate: 'companies_aggregate',
  delete: 'delete_companies',
  update: 'update_companies',
  insert: 'insert_companies',
};
export const contactPersonSchema: Schema<Schema_ContactPerson> = {
  tableName: 'contact_person',
  aggreagate: 'contact_person_aggregate',
  delete: 'delete_contact_person',
  update: 'update_contact_person',
  insert: 'insert_contact_person',
};

export const termsheetSchema: Schema<Schema_Termsheet> = {
  tableName: 'termsheets',
  aggreagate: 'termsheets_aggregate',
  delete: 'delete_requests',
  update: 'update_requests',
  insert: 'insert_requests',
};

export const supplyUnitPriceSchema: Schema<Schema_SupplyUnitPrice> = {
  tableName: 'requests',
  aggreagate: 'requests_aggregate',
  delete: 'delete_requests',
  update: 'update_requests',
  insert: 'insert_requests',
};
