import React, { Component, ReactNode } from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { ConditionalContainer } from '../misc/conditional-container';
import Spinner from './components/spinner';

const Background = styled.div<{ isLoading: boolean }>`
  min-height: ${(props) => (props.isLoading ? '400px' : 'unset')};
  display: ${(props) => (props.isLoading ? 'flex' : 'inherit')};
  flex-direction: column;
  height: auto;
  width: 100%;
  position: relative;
`;
const SpinnerW = styled(
  posed.div({
    appear: {
      scale: 1,
    },
    disapear: {
      scale: 0.2,
    },
  })
)`
  margin: auto;
  position: block;
`;
const SpinnerWrap = styled(
  posed.div({
    appear: {
      opacity: 1,
    },
    disapear: {
      opacity: 0,
    },
  })
)`
  position: ${(props) => (props.isLoading ? 'absolute' : 'absolute')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex: 1;
  z-index: 99;
  background-color: ${(props) => (props.firstLoad ? 'white' : '#ffffff55')};
  padding-top: ${(props) => (props.firstLoad ? '0px' : '200px')};
  padding-bottom: ${(props) => (props.firstLoad ? '0px' : '200px')};
  ${SpinnerW} {
    ${(props) =>
      !props.firstLoad &&
      `
      top: calc(50% - 53px);
      position: sticky;
      margin-top: 0;
    `}
  }
`;

interface IProps<Dispatch extends () => void> {
  children?: ReactNode;
  isLoading: boolean;
  firstLoad: boolean;
  invoke?: Dispatch;
}
interface IState {
  firstLoad: boolean;
}
const _400 = 400;
class Loader<T extends () => void> extends Component<IProps<T>, IState> {
  public constructor(props: IProps<T>) {
    super(props);

    this.state = {
      firstLoad: props.firstLoad,
    };
  }

  public componentDidMount() {
    if (this.props.invoke) {
      this.props.invoke();
    }
  }

  public componentDidUpdate(prevProps: IProps<T>) {
    if (!prevProps.invoke && this.props.invoke) {
      this.props.invoke();
    }
    if (!prevProps.firstLoad && this.props.firstLoad) {
      this.setState({ firstLoad: this.props.firstLoad });
    }
  }

  public render() {
    const { children, isLoading } = this.props;
    return (
      <Background isLoading={isLoading}>
        <ConditionalContainer
          show={isLoading}
          disappearDelay={_400}
          disappeared={() => this.setState({ firstLoad: this.props.firstLoad })}
        >
          <SpinnerWrap
            isLoading={isLoading}
            firstLoad={this.state.firstLoad}
            initialPose={'disapear'}
            pose={isLoading ? 'appear' : 'disapear'}
          >
            <SpinnerW
              initialPose={'disapear'}
              pose={isLoading ? 'appear' : 'disapear'}
            >
              <Spinner />
            </SpinnerW>
          </SpinnerWrap>
        </ConditionalContainer>
        <ConditionalContainer show={!isLoading || !this.state.firstLoad}>
          {children}
        </ConditionalContainer>
      </Background>
    );
  }
}

const Components = { Background, SpinnerWrap, SpinnerW };
export { IProps, Components };
export default Loader;
