import React, { useEffect, useState } from 'react';

// custom imports
import {
  ISellerContactInformation,
  ISellerInformation,
} from 'app/store/modules/seller-information/types';
import { IProps } from './index';
import Remarks from './remarks/remarks';
import SellerCommission from './seller-commission/seller-commission';
import SellerCompanyName from './seller-company-name';
import SellerContact from './seller-contact/seller-contact';
import SignedInfo from './signed-info/signed-info';
import {
  LeftSellerInfoContainer,
  MainSellerInfoContainer,
  RightSellerInfoContainer,
  SellerInformationContainer,
  SellerInformationWrapper,
} from './styles';
import CompanySummary from './company-summary/company-summary';
export interface ICommissionsPaid {
  companyId: number;
  sellerPaysCommission?: boolean;
  licenseFee?: number;
  supplyPrice?: number;
}
export interface ISignedInfo {
  companyId: number;
  isSellerCDASigned?: boolean;
  sellerCDASignatureDate?: Date;
  sellerCDAExpiryDate?: Date;
  isCPASigned?: boolean;
  CPASignatureDate?: Date;
  isEarlyBuyerDisclosureRequired?: boolean;
  isCompanyHidden?: boolean;
}
export interface IRemarks {
  companyId: number;
  adminRemarks?: string;
}
export interface ICompanySummary {
  companyId: number;
  summary?: string;
}
const SellerInformation: React.FC<IProps> = ({
  isAdmin,
  productOwnerId,
  getCompanyDetailsById,
  editSellerCompanyId,
  setSellerCompanyDetails,
  sellerInformationModalOpen,
  updateSellerCommission,
  isEdit,
  closeModal,
  sellerInformationDetails,
  updateCache,
  updateClientNameRequiredStatus,
  updateCompanyHiddenStatus,
  updateCDASignedDetails,
  updateCPASignedDetails,
  updateSellerNameAndCountry,
  updateRemarks,
  updateCompanySummary,
  getAllSellerContactByCompanyId,
  setSellerContactList,
  createSellerContact,
  updateSellerContact,
  deleteSellerContact,
  ...props
}) => {
  const [commissions, setCommissions] = useState<ICommissionsPaid>();
  const [signed, setSigned] = useState<ISignedInfo>();
  const [remarks, setRemarks] = useState<IRemarks>();
  const [companySummary, setCompanySummary] = useState<ICompanySummary>();
  const [sellerCompanyName, setSellerCompanyName] = useState<string>();
  const [sellerCompanyCountry, setSellerCompanyCountry] = useState<string>();
  const [companyId, setCompanyId] = useState<number>(0);
  const [sellerContacts, setSellerContacts] = useState<
    ISellerContactInformation[]
  >([]);
  useEffect(() => {
    if (editSellerCompanyId > 0) {
      getCompanyDetailsById(editSellerCompanyId, isAdmin).then(
        (c: ISellerInformation) => {
          setSellerCompanyName(c.name);
          setSellerCompanyCountry(c.country);
          setCompanyId(c.id);
          setSellerCompanyDetails(c);
          setCommissions({
            companyId: c!.id,
            licenseFee: c!.negotiatedLicenseFeeCommissionsPct,
            sellerPaysCommission: c!.isCommissionsPaid,
            supplyPrice: c!.negotiatedSupplyFeeCommissionsPct,
          });
          setSigned({
            companyId: c!.id,
            isSellerCDASigned: c!.isSellerCDASigned,
            sellerCDAExpiryDate: c!.sellerCDAExpiryDate,
            sellerCDASignatureDate: c!.sellerCDASignatureDate,
            isCPASigned: c!.isCPASigned,
            CPASignatureDate: c!.CPASignatureDate,
            isEarlyBuyerDisclosureRequired: c!.isEarlyBuyerDisclosureRequired,
            isCompanyHidden: c!.isCompanyHidden,
          });
          setRemarks({
            companyId: c!.id,
            adminRemarks: c!.adminRemarks,
          });
          setCompanySummary({
            companyId: c!.id,
            summary: c!.summary,
          });
        }
      );
      const sellerArr: ISellerContactInformation[] = [];
      getAllSellerContactByCompanyId(editSellerCompanyId, isAdmin).then(
        (s: ISellerContactInformation[]) => {
          if (s) {
            s.map((seller) => {
              sellerArr.push(seller);
            });
            setSellerContactList(s);
            setSellerContacts(sellerArr);
          } else {
            setSellerContactList([]);
            setSellerContacts(sellerArr);
          }
        }
      );
    }
  }, [updateCache]);
  return (
    <div id="seller-information-modal-content">
      <SellerInformationWrapper>
        <SellerInformationContainer>
          <SellerCompanyName
            companyId={companyId}
            companyName={sellerCompanyName || ''}
            companyCountry={sellerCompanyCountry || ''}
            updateSellerNameAndCountry={updateSellerNameAndCountry}
          />
          <MainSellerInfoContainer>
            <LeftSellerInfoContainer>
              <SellerContact
                companyId={editSellerCompanyId}
                createSellerContact={createSellerContact}
                updateSellerContact={updateSellerContact}
                deleteSellerContact={deleteSellerContact}
                contacts={sellerContacts}
                updateCache={updateCache}
                productOwnerId={productOwnerId}
              />
              <CompanySummary
                companySummary={
                  (companySummary ? companySummary!.summary : '') as string
                }
                updateCompanySummary={(value: string) =>
                  updateCompanySummary(editSellerCompanyId, value)
                }
                updateCache={updateCache}
              />
              <Remarks
                updateCache={updateCache}
                remarks={(remarks ? remarks!.adminRemarks : '') as string}
                updateRemarks={(value: string) =>
                  updateRemarks(editSellerCompanyId, value)
                }
              />
            </LeftSellerInfoContainer>
            <RightSellerInfoContainer>
              {commissions && (
                <SellerCommission
                  commissions={commissions}
                  updateSellerCommission={updateSellerCommission}
                  updateCache={updateCache}
                />
              )}
              {signed && (
                <SignedInfo
                  signed={signed}
                  buyerDisclosureRequired={updateClientNameRequiredStatus}
                  updateCDASignedDetails={updateCDASignedDetails}
                  updateCache={updateCache}
                  companyHidden={updateCompanyHiddenStatus}
                />
              )}
            </RightSellerInfoContainer>
          </MainSellerInfoContainer>
        </SellerInformationContainer>
      </SellerInformationWrapper>
    </div>
  );
};

export default SellerInformation;
