import {
  setCreateAccountModalOpen,
  setVerifyEmailModalOpen,
} from 'app/store/modules/auth/actions';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import PermissionsCheck, { Permissions } from './permissions-check';
export default connect(
  (state: any) => {
    return {
      isLoggedIn: state.auth.loggedIn,
      isAdmin: state.auth.loggedInUser
        ? state.auth.loggedInUser.isAdmin
        : false,
      authCheckComplete: state.auth.authCheckComplete,
      loggedInUser: state.auth.loggedInUser,
      linkedinLoginSuccess: state.auth.linkedinLoginSuccess,
    };
  },
  (dispatch) => {
    return {
      navigateToRoute: (route: string) => {
        // We don't want to use react-router's push method here because it will redirect to legacy page
        // dispatch(push(route));
        if (window) {
          window.location.href = route;
        }
      },
      openCreateAccountModal: () => {
        dispatch(setVerifyEmailModalOpen(false));
        dispatch(setCreateAccountModalOpen(true));
      },
    };
  }
)(PermissionsCheck);
export { Permissions };
