import { createActionCreator } from '@reduxify/utils';

import Termsheet from 'app/modules/admin/quotation/models/termsheet';
import { Dictionary } from 'app/utils/dictionary';
import { ICreateTermSheet } from './query';
import { ILatestByDealId } from './types';

export const createTermsheetAction = createActionCreator<ICreateTermSheet>(
  'termsheet/CREATE'
);

export const creatingTermsheet = createActionCreator<boolean>(
  'termsheet/CREATING'
);
export const createdTermsheet = createActionCreator<boolean>(
  'termsheet/CREATED'
);

export const setTermsheetCache = createActionCreator<Dictionary<Termsheet>>(
  'termsheet/SET_CACHE'
);

export const setLatestTermsheet = createActionCreator<ILatestByDealId>(
  'termsheet/SET_LATEST'
);

export const fetchLatestTermsheet = createActionCreator<{
  client: any;
  requestId: number;
}>('termsheet/FETCH_LATEST');
