import {
  setBuyerQualificationAdminViewRequestId,
  setBuyerQualificationAdminViewModalOpen,
} from 'app/store/modules/auth/actions';
import { Company, defaultEmptyCompany } from 'app/store/modules/auth/types';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import BuyerQualificationModal from './admin-buyer-qualification-view-modal';

interface IStateProps {
  isOpen: boolean;
  company: Company;
  isAdminView?: boolean;
}

interface IDispatchProps {
  closeModal: () => any;
}

export type IProps = IStateProps & IDispatchProps & IWithApolloClient;

export default withApollo<any>(
  connect<IStateProps, IDispatchProps, IWithApolloClient, IStore>(
    (state: IStore) => {
      const request =
        state.auth.buyerInformationAdminViewDealId !== 0
          ? state.requests.dealsCache[
              state.auth.buyerInformationAdminViewDealId
            ]
          : undefined;
      const buyerCompany =
        request && request.BuyerCompany
          ? request.BuyerCompany
          : defaultEmptyCompany;
      return {
        isOpen: state.auth.buyerInformationAdminViewModalOpen,
        company: buyerCompany,
      };
    },
    (dispatch, ownProps) => {
      return {
        closeModal: () => {
          dispatch(setBuyerQualificationAdminViewModalOpen(false));
          dispatch(setBuyerQualificationAdminViewRequestId(0));
        },
      };
    }
  )(BuyerQualificationModal)
);
