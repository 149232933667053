import {
  DealStatusDictionary,
  EDealStatus,
} from 'app/store/modules/requests/types';
import { media } from 'app/styles/breakpoint';
import { sg } from 'app/utils/safe-get';
import React from 'react';
import styled from 'styled-components';

const StatusContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  min-width: 87px;
  max-width: 150px;
  text-align: center;
  justify-content: flex-start;
`;
const MessageButtonIcon = styled.img`
  width: 10px;
  object-fit: contain;
`;
const Devider = styled.div`
  width: 7px;
`;
const StatusTitleContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
`;
const StatusTitle = styled.div`
  position: relative;
  display: flex;
  flex-wrap: no-wrap;
  margin: auto 0;
`;
const JustSentMarker = styled.div`
  width: 58px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.accent};
`;
const JustSentText = styled.div`
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.22px;
  color: ${(props) => props.theme.colors.white};
`;
interface IStatusMessage {
  isPaid: boolean;
}

const StatusBubble = styled.div<IStatusMessage>`
  border-radius: 17.5px;
  background-color: ${(props) =>
    props.isPaid
      ? props.theme.colors.successLight
      : props.theme.colors.dangerLight};
  padding-left: 10px;
  padding-right: 10px;
  width: fit-content;
  height: 18px;
  text-align: center;
  align-items: center;
  display: flex;
  margin-top: 4px;
`;

const StatusMessage = styled.div<IStatusMessage>`
  font-size: 9px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: uppercase;
  color: ${(props) =>
    props.isPaid ? props.theme.colors.success : props.theme.colors.danger};
`;
const StatusBubbleContainer = styled.div<{ showInLine: boolean }>`
  ${(props) => (props.showInLine ? 'width: fit-content;' : 'width: 100%;')}

  display: flex;
  justify-content: left;
  ${media.max.sm`
    width: fit-content;
  `}
`;
interface Props {
  status: EDealStatus;
  feeStatus: boolean;
  showInLine?: boolean;
  showFeeStatus: boolean;
  showJustSent?: boolean;
}
interface IState {
  showDevider: boolean;
}
class StatusComponent extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      showDevider: false,
    };

    this.switchImg = this.switchImg.bind(this);
  }

  public componentDidMount() {
    if (this.props.status === EDealStatus.Accepted) {
      this.setState({ showDevider: true });
    }
  }
  public componentDidUpdate() {
    if (this.props.status !== EDealStatus.Accepted && this.state.showDevider) {
      this.setState({ showDevider: false });
    } else if (
      this.props.status === EDealStatus.Accepted &&
      !this.state.showDevider
    ) {
      this.setState({ showDevider: true });
    }
  }

  public render() {
    return (
      <StatusContainer>
        <StatusTitleContainer>
          {this.switchImg(this.props.status)}
          <Devider />
          <StatusTitle>
            {sg(() => DealStatusDictionary[this.props.status], '')}
          </StatusTitle>
          <Devider />
          {this.props.showJustSent && (
            <JustSentMarker>
              <JustSentText>Just sent</JustSentText>
            </JustSentMarker>
          )}
        </StatusTitleContainer>
        {this.state.showDevider ? <Devider /> : ''}
        {this.props.showFeeStatus &&
          this.props.status === EDealStatus.Negotiating && (
            <StatusBubbleContainer
              showInLine={this.props.showInLine ? true : false}
            >
              <StatusBubble isPaid={this.props.feeStatus}>
                <StatusMessage isPaid={this.props.feeStatus}>
                  {this.props.feeStatus ? 'paid' : 'unpaid'}
                </StatusMessage>
              </StatusBubble>
            </StatusBubbleContainer>
          )}
      </StatusContainer>
    );
  }
  private switchImg = (status: EDealStatus) => {
    switch (status) {
      case EDealStatus.Requested:
        return <MessageButtonIcon src={'/img/question-circle.svg'} />;
      case EDealStatus.Accepted:
        return <MessageButtonIcon src={'/img/check-circle.svg'} />;
      case EDealStatus.Expired:
        return <MessageButtonIcon src={'/img/combined-shape.svg'} />;
      case EDealStatus.Negotiating:
        return <MessageButtonIcon src={'/img/clock.svg'} />;
      case EDealStatus.Declined:
        return <MessageButtonIcon src={'/img/minus-circle.svg'} />;
      default:
        return null;
    }
  };
}
export default StatusComponent;
