import { Components } from 'app/components/buttons/edit-item-blue-button/edit-item-blue-button';
import { getCountries, tokyCallPopup } from 'app/store/modules/global/actions';
import { ISellerContactInformation } from 'app/store/modules/seller-information/types';
import React, { useEffect, useReducer } from 'react';
import CountryFlagList from '../../../../components/misc/country-flag-list';
import ToolTipOverlay from '../../../../components/tooltip/tooltip-overlay';
import { ICreateContactPerson, IUpdateContactPerson } from '../index';
import {
  Column,
  EditItemBlueButton,
  Row,
  SellerContactDetails,
  SellerContactInfoAnchor,
  SellerContactInfoContainer,
  SellerContactInfoEditBtnContainer,
  SellerContactListContainer,
  SellerContactListItemContainer,
  SellerContactName,
  SellerContactResponsible,
  SellerContactResponsibleContainer,
  Separator,
  ProductOwnerContainer,
  SellerContactNameContainer,
  ProductOwnerImageContainer,
  ProductOwnerTextContainer,
} from '../styles';
import SellerContactForm from './seller-contact-form';

const { Label, AddItemIcon } = Components;

export interface ISellerContact {
  firstName: string;
  lastName: string;
  assignedMarket: string[];
  email: string;
  phoneNumber: string;
  assignedMarketsName: string;
}

interface IPropsItem {
  sellerContact: ISellerContactInformation;
  companyId: number;
  isEdit: boolean;
  onEdit: (editedState: { [key: string]: boolean }) => any;
  createSellerContact: (person: ICreateContactPerson) => any;
  updateSellerContact: (person: IUpdateContactPerson) => any;
  deleteSellerContact: (id: number, companyId: number) => any;
  productOwnerId?: number;
}

export const EditButton: React.FC<{
  editDisabled: boolean;
  setEditDisabled: (editDisabled: boolean) => any;
}> = ({ editDisabled, setEditDisabled }) => {
  return (
    <EditItemBlueButton onClick={() => setEditDisabled(!editDisabled)}>
      <AddItemIcon src={'/img/edit-pen.svg'} />
      <Label>Edit</Label>
    </EditItemBlueButton>
  );
};
const safeJsonParse = (str: string) => {
  try {
    return [null, JSON.parse(str)];
  } catch (err) {
    return [err];
  }
};
const SellerContactItem: React.FC<IPropsItem> = ({
  sellerContact,
  companyId,
  isEdit,
  onEdit,
  createSellerContact,
  updateSellerContact,
  deleteSellerContact,
  productOwnerId,
}) => {
  let assignedMarketsArray = [];
  let plusMoreCountriesString = '';
  let plusMore = 0;
  if (sellerContact.assignedMarkets) {
    const [err, result] = safeJsonParse(sellerContact!.assignedMarkets);
    if (!err) {
      assignedMarketsArray = result;
    }
    // eslint-disable-next-line no-magic-numbers
    if (assignedMarketsArray.length && assignedMarketsArray.length > 15) {
      // eslint-disable-next-line no-magic-numbers
      plusMore = assignedMarketsArray.length - 15;
      let tempAssignedMarkets = assignedMarketsArray;
      tempAssignedMarkets = tempAssignedMarkets.slice(
        // eslint-disable-next-line no-magic-numbers
        15,
        assignedMarketsArray.length
      );
      plusMoreCountriesString = tempAssignedMarkets.length
        ? getCountries(tempAssignedMarkets ? tempAssignedMarkets : [])
        : '';
      // eslint-disable-next-line no-magic-numbers
      assignedMarketsArray = assignedMarketsArray.slice(0, 15);
    }
  }
  return (
    <>
      {!isEdit ? (
        <SellerContactListItemContainer>
          <SellerContactNameContainer>
            <SellerContactName>
              {sellerContact.firstName} {sellerContact.lastName}
            </SellerContactName>
            {sellerContact.userId === productOwnerId ? (
              <ProductOwnerContainer>
                <ProductOwnerImageContainer>
                  <img src={'/img/user-check.svg'} />
                </ProductOwnerImageContainer>
                <ProductOwnerTextContainer>Owner</ProductOwnerTextContainer>
              </ProductOwnerContainer>
            ) : (
              ''
            )}
          </SellerContactNameContainer>
          <SellerContactDetails>
            <Row>
              <SellerContactInfoContainer>
                <Row>
                  <Column maxWidthPercentage="100%" padding="0px">
                    <SellerContactInfoAnchor
                      href={`mailto:${sellerContact.email}`}
                    >
                      {sellerContact.email}
                    </SellerContactInfoAnchor>
                    {sellerContact && sellerContact.phoneNumber && (
                      <>
                        <Separator>.</Separator>
                        <SellerContactInfoAnchor
                          floatLeft={true}
                          paddingTop="8px"
                          className={'toky-link'}
                          onClick={() =>
                            tokyCallPopup(sellerContact.phoneNumber)
                          }
                        >
                          {sellerContact.phoneNumber}
                        </SellerContactInfoAnchor>
                      </>
                    )}
                  </Column>
                </Row>
                {assignedMarketsArray.length === 0 &&
                  sellerContact!.assignedMarketsName !== '' && (
                    <Row>
                      <Column maxWidthPercentage="100%" padding="0px">
                        <SellerContactResponsibleContainer>
                          <SellerContactResponsible emptyFlag={true}>
                            Responsible for {sellerContact.assignedMarketsName}
                          </SellerContactResponsible>
                        </SellerContactResponsibleContainer>
                      </Column>
                    </Row>
                  )}
                {assignedMarketsArray.length > 0 && (
                  <Row>
                    <Column maxWidthPercentage="100%" padding="0px">
                      <SellerContactResponsibleContainer>
                        <SellerContactResponsible>
                          <CountryFlagList
                            countryIds={assignedMarketsArray}
                            label={'Responsible for '}
                          />
                        </SellerContactResponsible>
                        {plusMore !== 0 && (
                          <ToolTipOverlay tooltip={plusMoreCountriesString}>
                            <SellerContactResponsible>
                              +{plusMore}
                            </SellerContactResponsible>
                          </ToolTipOverlay>
                        )}
                      </SellerContactResponsibleContainer>
                    </Column>
                  </Row>
                )}
              </SellerContactInfoContainer>
              <SellerContactInfoEditBtnContainer>
                <EditButton
                  editDisabled={isEdit}
                  setEditDisabled={() =>
                    onEdit({
                      [sellerContact.id]: !isEdit,
                    })
                  }
                />
              </SellerContactInfoEditBtnContainer>
            </Row>
          </SellerContactDetails>
        </SellerContactListItemContainer>
      ) : (
        <SellerContactForm
          contact={sellerContact}
          onCancel={onEdit}
          companyId={companyId}
          createSellerContact={createSellerContact}
          updateSellerContact={updateSellerContact}
          deleteSellerContact={deleteSellerContact}
          isEdit={isEdit}
        />
      )}
    </>
  );
};

interface IProps {
  contacts: ISellerContactInformation[];
  companyId: number;
  updateCache: any;
  createSellerContact: (person: ICreateContactPerson) => any;
  updateSellerContact: (person: IUpdateContactPerson) => any;
  deleteSellerContact: (id: number, companyId: number) => any;
  productOwnerId?: number;
}
const SellerContactList: React.FC<IProps> = ({
  contacts,
  companyId,
  updateCache,
  createSellerContact,
  updateSellerContact,
  deleteSellerContact,
  productOwnerId,
}) => {
  const getInitState = () => {
    return contacts.reduce((acc, sellerContact) => {
      return {
        ...acc,
        [sellerContact.id]: false,
      };
    }, {});
  };
  const [editState, setEditState] = useReducer(
    (state: any, editedState: any) => {
      if (editedState.initState) {
        return {
          ...state,
          ...editedState.initState,
        };
      }
      return {
        ...getInitState(),
        ...editedState,
      };
    },
    {}
  );

  useEffect(() => {
    if (contacts.length) {
      setEditState({
        initState: getInitState(),
      });
    }
  }, [contacts]);
  return (
    <SellerContactListContainer>
      {contacts &&
        contacts.map((sellerContact, key) => (
          <SellerContactItem
            key={key}
            sellerContact={sellerContact}
            companyId={companyId}
            isEdit={editState[sellerContact.id]}
            onEdit={(editedState) => setEditState(editedState)}
            createSellerContact={createSellerContact}
            updateSellerContact={updateSellerContact}
            deleteSellerContact={deleteSellerContact}
            productOwnerId={productOwnerId}
          />
        ))}
    </SellerContactListContainer>
  );
};
export default SellerContactList;
