import { createActionCreator } from '@reduxify/utils';
import { toTitleCase } from 'app/store/modules/global/actions';
import { Dictionary } from 'app/utils/dictionary';

import { ApolloClient } from 'apollo-boost';
import { AnyListItem, Product } from 'app/store/modules/products/types';
import { IRequestNewProduct } from './query';
import { MarketplaceFilter, State } from './types';

export const filterMarketplace = createActionCreator<{
  filter: MarketplaceFilter;
  client: any;
}>('marketplace/FILTER_MARKETPLACE');

export const setMarketplaceFilter = createActionCreator<MarketplaceFilter>(
  'marketplace/SET_FILTER'
);

export const setIsElastic = createActionCreator<boolean>(
  'marketplace/SET_ELASTIC'
);

export const setIsFetching = createActionCreator<boolean>(
  'marketplace/SET_FECHING'
);

export const fetchMarketplaceItems = createActionCreator<{
  client: any;
}>('marketplace/FETCH_MARKETPLACE_ITEMS');

export const requestFetchMarketplaceItemsConstant =
  'marketplace/REQUEST_FETCH_MARKETPLACE';
export const requestFetchMarketplaceItems = createActionCreator<{
  client: ApolloClient<any>;
  filter: MarketplaceFilter;
  isFast?: boolean;
  skipBrand?: boolean;
}>(requestFetchMarketplaceItemsConstant);

export const fetchProduct = createActionCreator<{
  client: any;
  productId: number;
}>('marketplace/FETCH_PRODUCT');

export const fetchProductSuccess = createActionCreator<Product>(
  'marketplace/FETCH_PRODUCT_SUCCESS'
);

export const fetchMarketplaceSuccessConstant =
  'marketplace/FETCH_MARKETPLACE_SUCCESS';
export const fetchMarketplaceSuccess = createActionCreator<{
  currentPage: number;
  marketplaceItemList: any[];
  totalCount: number;
}>(fetchMarketplaceSuccessConstant);

export const setTotalCount = createActionCreator<number>(
  'marketplace/SET_TOTAL_COUNT'
);
export const setCurrentPage = createActionCreator<number>(
  'marketplace/SET_PAGE'
);
export const setPageSize = createActionCreator<number>(
  'marketplace/SET_PAGE_SIZE'
);

export const setMarketplaceProductCache = createActionCreator<
  Dictionary<Product>,
  { currentPage: number }
>('marketplace/SET_CACHE_PRODUCTS');

export const setMarketplaceItemsList = createActionCreator<
  AnyListItem[],
  { currentPage: number }
>('marketplace/SET_MARKETPLACE_ITEM_LIST');
export const requestNewProduct = createActionCreator<IRequestNewProduct>(
  'marketplace/REQUEST_NEW_PRODUCT'
);

export const getProductName = (product: Product) => {
  return product
    ? `${toTitleCase(product.Substance1.name)}${
        product.Substance2 && product.Substance2
          ? ' + ' + toTitleCase(product.Substance2.name)
          : ''
      }${
        product.Substance3 && product.Substance3
          ? ' + ' + toTitleCase(product.Substance3.name)
          : ''
      }`
    : '';
};

export const setHasFetched = createActionCreator<boolean>(
  'marketplace/SET_FETCHED'
);
export const setRequestMessage = createActionCreator<
  State['newProductRequestMessage']
>('marketplace/SET_MESSAGE');

export const requestFetchTopProducts = createActionCreator<{
  client: any;
  substanceName: string;
  substance2Name: string;
  substance3Name: string;
  brandName: string;
  type: number;
  productId: number;
}>('marketplace/FETCH_TOP_PRODUCTS');

export const setTopProducts = createActionCreator<AnyListItem[]>(
  'marketplace/SET_TOP_PRODUCTS'
);

export const requestFetchSubstanceSEOText = createActionCreator<{
  client: ApolloClient<any>;
  text: string;
}>('marketplace/REQUEST_FETCH_SUBSTANCE_SEO_TEXT');

export const setSubstanceSEOText = createActionCreator<string>(
  'marketplace/SET_SUBSTANCE_SEO_TEXT'
);
export const setBrandNameSEO = createActionCreator<string>(
  'marketplace/SET_BRAND_NAME'
);
export const setBrandSubstance = createActionCreator<{ [key: string]: string }>(
  'marketplace/SET_BRAND_SUBSTANCE'
);

export const requestFetchCountrySEOText = createActionCreator<{
  client: ApolloClient<any>;
  countryCode: string;
}>('marketplace/REQUEST_FETCH_COUNTRY_SEO_TEXT');

export const setCountrySEOText = createActionCreator<string>(
  'marketplace/SET_COUNTRY_SEO_TEXT'
);
export const setSubstanceSEOable = createActionCreator<boolean>(
  'marketplace/SET_SUBSTANCE_SEO_ABLE'
);
