import { routes } from 'app/config';
import { setDealOriginFilter } from 'app/store/modules/requests/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { OnBoardingFilter } from 'app/store/modules/products/types';
import { IStore } from 'app/store/types';
import sg from 'app/utils/safe-get';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { Fetch } from '../../index';
import MyDealsListHeader from './requests-list-header';
interface IStateProps {
  totalItems: number;
  totalDealCount: number;
  filter: OnBoardingFilter;
  isAdmin: boolean;
}
interface IDispatchProps {
  setFilter: (filter: OnBoardingFilter) => void;
}
interface IOwnProps {
  fetch: Fetch;
}
export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}
export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IWithApolloClient<IOwnProps>, IStore>(
    (state) => {
      const isAdmin = sg(() => state.auth.loggedInUser.isAdmin, false);
      let totalDealCount = 0;
      if (isAdmin) {
        totalDealCount = state.auth.navbarCount.dealCount;
      } else {
        if (state.router.location.pathname === routes.deals_buying) {
          totalDealCount = state.auth.navbarCount.dealsBuyingCount;
        }
        if (state.router.location.pathname === routes.deals_selling) {
          totalDealCount = state.auth.navbarCount.dealsSellingCount;
        }
      }
      return {
        totalItems: state.requests.totalCount,
        totalDealCount,
        filter: state.requests.originFilter,
        isAdmin: state.auth.loggedInUser
          ? state.auth.loggedInUser.isAdmin
          : false,
      };
    },
    (dispatch, ownProps) => ({
      setFilter: (filter) =>
        dispatch(
          setDealOriginFilter({
            filter,
            client: ownProps.client,
            isArchive: false,
          })
        ),
    })
  )(MyDealsListHeader)
);
export { Fetch };
