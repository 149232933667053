import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { requestFragment } from '../../requests/fragments/request';
import { companyFragment } from '../../global/fragments/company';
import { IWithApolloClient } from '../../global/types';
import {
  DealUtils,
  CompanyUtils,
  SubstanceUtils,
  UserUtils,
} from '../../query-utils';
import { dealSchema } from '../../query-utils/schema';

export interface IgetDealByIdQuery extends IWithApolloClient {
  id: number;
  isAdmin: boolean;
}
export interface IgetDealByIdQueryReturn {
  deals: {
    returning: {
      id: number;
      buyerUsersId: number;
      substance1: number;
      substance2?: number;
      substance3?: number;
      countriesOfInterest: string;
      broker?: number;
    };
  };
}

export async function getDealByIdQuery(p: IgetDealByIdQuery) {
  try {
    // @ts-ignore
    const result = await p.client.query<IgetDealByIdQueryReturn>({
      query: gql`
        ${new u.Query('getDealById', {
          isAdmin: p.isAdmin,
        })
          .params(['$id', 'Int'])
          .fragments(requestFragment, companyFragment)
          .query(dealSchema, 'deals')
          .select(
            'id',
            ['Substance2', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance3', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance1', true, SubstanceUtils.selectParams('id', 'name')],
            ['countries_of_interest', 'countriesOfInterest'],
            [
              'Broker',
              true,
              UserUtils.selectParams('id', ['full_name', 'fullName']),
            ],
            [
              'Buyer',
              true,
              UserUtils.selectParams(
                'id',
                p.isAdmin && ['full_name', 'fullName'],
                p.isAdmin && ['first_name', 'firstName'],
                p.isAdmin && ['last_name', 'lastName'],
                p.isAdmin && ['phone', 'phone'],
                p.isAdmin && ['email', 'email'],
                p.isAdmin && ['verified', 'verified'],
                p.isAdmin && [
                  'Company',
                  true,
                  CompanyUtils.selectParams(
                    'id',
                    'name',
                    ['annual_revenue', 'annualRevenue'],
                    ['sales_channels', 'salesChannels'],
                    ['portfolio_products_list', 'portfolioProductsList'],
                    ['therapy_areas', 'therapyAreas'],
                    ['number_of_employees', 'numberOfEmployees'],
                    'summary',
                    'segment'
                  ),
                ],
                !p.isAdmin && ['Company', true, CompanyUtils.selectParams('id')]
              ),
            ],
            ['countries_of_interest', 'countriesOfInterest'],
            ['estimated_annual_quantity', 'estimatedAnnualQuantity'],
            ['target_supply_price', 'targetSupplyPrice'],
            ['deal_type', 'dealType'],
            ['expect_sign_contract', 'expectSignContract'],
            'comments',
            ['dose_form_category', 'doseFormCategory'],
            ['Requests', true, requestFragment],
            ['created_at', 'createdAt'],
            ['updated_at', 'updatedAt'],
            ['deal_phase', 'dealPhase']
          )
          .limit(1)
          .where(() =>
            u._and(DealUtils._eq('id', '$id'), DealUtils._is_null('deleted_at'))
          )
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
      },
    });
    return sg(() => result!.data!.deals[0], undefined);
  } catch (e) {
    logger.error('src/app/store/modules/deal/query.ts -> getDealByIdQuery', e);
  }
}
