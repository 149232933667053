import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { Dictionary } from 'app/utils/dictionary';
import { combineReducers } from 'redux';
import * as A from './actions';
import {
  defaultPageSize,
  ICompany,
  ICompanyDirectory,
  Notifications,
  State,
} from './types';

export default combineReducers<State>({
  companiesCache: cr<Dictionary<ICompany>>(
    {},
    reduce(A.setCompaniesCache, set)
  ),
  companiesIds: cr<number[]>([], reduce(A.setCompaniesIds, set)),
  totalCount: cr<number>(0, reduce(A.setCompaniesTotalCount, set)),
  currentPage: cr<number>(0, reduce(A.setCompaniesCurrentPage, set)),
  pageSize: cr<number>(defaultPageSize, reduce(A.setCompaniesPageSize, set)),
  error: cr<Notifications>(
    { listError: '', listSuccess: '' },
    reduce(A.setCompaniesNotification, set)
  ),
  hasFetched: cr<boolean>(false, reduce(A.hasFetched, set)),
  isFetching: cr<boolean>(false, reduce(A.isFetching, set)),
  editModalOpen: cr<boolean>(false, reduce(A.setEditModal, set)),
  editCompanyId: cr<number>(0, reduce(A.setEditCompanyId, set)),
  companyDirectoryLetters: cr<string[]>([], reduce(A.setCountryNameList, set)),
  companyDirectoryCompanies: cr<ICompanyDirectory[]>(
    [],
    reduce(A.setCompanyByCountryNameList, set)
  ),
  directoryFooterCountrySeoText: cr<string>(
    '',
    reduce(A.setCompanyDirectorySEOByCountryName, set)
  ),
});
