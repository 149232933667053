import Termsheet from 'app/modules/admin/quotation/models/termsheet';
import { ITermsheet } from 'app/store/modules/termsheets/types';
import { toDictionary } from 'app/utils/dictionary';

export function prepareTermsheetCache(termsheets: ITermsheet[]) {
  const termsheetsEntities = termsheets.map(
    (termsheet) => new Termsheet(termsheet)
  );

  return toDictionary(termsheetsEntities, (v) => v.id);
}
