import * as u from 'app/store/modules/query-utils';
import {
  CompanyUtils,
  ContactPersonUtils,
} from 'app/store/modules/query-utils';
import {
  companySchema,
  contactPersonSchema,
} from 'app/store/modules/query-utils/schema';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../global/types';
import { ICompanySummary, IRemarks } from './types';

export interface IGetCompanyDetails extends IWithApolloClient {
  id: number;
  isAdmin: boolean;
}

export interface ICompanyDetailsReturn {
  company: {
    id: number;
    name: string;
    country?: string;
    contactEmail?: string;
    contactPhone?: string;
    contactTitle?: string;
    contactFirstName?: string;
    contactLastName?: string;
    isCommissionsPaid?: boolean;
    negotiatedSupplyFeeCommissionsPct?: number;
    isSellerCDASigned?: boolean;
    sellerCDASignatureDate?: Date;
    sellerCDAExpiryDate?: Date;
    isEarlyBuyerDisclosureRequired?: boolean;
    isCompanyHidden?: boolean;
    negotiatedLicenseFeeCommissionsPct?: number;
    isCPASigned?: boolean;
    CPASignatureDate?: Date;
    adminRemarks?: string;
  };
}

export async function getCompanyDetailsById(p: IGetCompanyDetails) {
  try {
    return await p.client.query<ICompanyDetailsReturn>({
      query: gql`
        ${new u.Query('getCompanyDetailsById')
          .params(['$id', 'Int'])
          .query(companySchema, 'company')
          .select(
            'id',
            'name',
            'country',
            p.isAdmin && 'contactEmail',
            p.isAdmin && 'contactPhone',
            'contactTitle',
            'contactFirstName',
            'contactLastName',
            p.isAdmin && ['is_commissions_paid', 'isCommissionsPaid'],
            p.isAdmin && [
              'negotiated_supply_fee_commissions_pct',
              'negotiatedSupplyFeeCommissionsPct',
            ],
            p.isAdmin && ['is_cda_signed', 'isSellerCDASigned'],
            p.isAdmin && ['cda_signature_date', 'sellerCDASignatureDate'],
            p.isAdmin && ['is_cda_signed', 'isSellerCDASigned'],
            p.isAdmin && ['cda_expiry_date', 'sellerCDAExpiryDate'],
            p.isAdmin && [
              'is_early_buyer_disclosure_required',
              'isEarlyBuyerDisclosureRequired',
            ],
            p.isAdmin && ['hidden', 'isCompanyHidden'],
            p.isAdmin && [
              'negotiated_license_fee_commissions_pct',
              'negotiatedLicenseFeeCommissionsPct',
            ],
            p.isAdmin && ['is_cpa_signed', 'isCPASigned'],
            p.isAdmin && ['cpa_signature_date', 'CPASignatureDate'],
            p.isAdmin && ['admin_remarks', 'adminRemarks'],
            p.isAdmin && ['summary', 'summary']
          )
          .limit(1)
          .where((t) => t._eq('id', '$id'))
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> getCompanyDetailsById',
      e
    );
  }
}
export interface ISellerCommission extends IWithApolloClient {
  id: number;
  sellerPaysCommission: boolean;
  licenseFee: number;
  supplyPrice: number;
}
export interface ISellerCommissionReturn {
  update_companies: {
    returning: {
      id: number;
      name: string;
      sellerPaysCommission: boolean;
      licenseFee: number;
      supplyPrice: number;
    }[];
  };
}
export async function updateSellerPaysCommissionInformation(
  p: ISellerCommission
) {
  try {
    return await p.client.mutate<ISellerCommissionReturn>({
      mutation: gql`
          mutation updateSellerCommisionInformation(${CompanyUtils.params(
            ['$id', 'Int'],
            ['$is_commissions_paid', 'Boolean'],
            ['$negotiated_license_fee_commissions_pct', 'float8'],
            ['$negotiated_supply_fee_commissions_pct', 'float8'],
            ['$date', 'timestamptz']
          )}) {
              update_companies(
              ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
              ${CompanyUtils._set(
                ['is_commissions_paid', '$is_commissions_paid'],
                [
                  'negotiated_license_fee_commissions_pct',
                  '$negotiated_license_fee_commissions_pct',
                ],
                [
                  'negotiated_supply_fee_commissions_pct',
                  '$negotiated_supply_fee_commissions_pct',
                ],
                ['updatedAt', '$date']
              )}
              ) {
              returning {
                  ${CompanyUtils.selectParams(
                    'id',
                    'name',
                    ['is_commissions_paid', 'sellerPaysCommission'],
                    ['negotiated_license_fee_commissions_pct', 'licenseFee'],
                    ['negotiated_supply_fee_commissions_pct', 'supplyPrice']
                  )}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        is_commissions_paid: p.sellerPaysCommission,
        negotiated_license_fee_commissions_pct: p.licenseFee,
        negotiated_supply_fee_commissions_pct: p.supplyPrice,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateSellerPaysCommissionInformation',
      e
    );
  }
}
export interface IBuyerDisclosureRequired extends IWithApolloClient {
  id: number;
  isEarlyBuyerDisclosureRequired: boolean;
}
export interface IBuyerDisclosureRequiredReturn {
  update_companies: {
    returning: {
      id: number;
      name: string;
      isEarlyBuyerDisclosureRequired: boolean;
    }[];
  };
}
export async function updateEarlyBuyerDisclosureRequiredStatus(
  p: IBuyerDisclosureRequired
) {
  try {
    return await p.client.mutate<IBuyerDisclosureRequiredReturn>({
      mutation: gql`
          mutation updateEarlyBuyerDisclouser(${CompanyUtils.params(
            ['$id', 'Int'],
            ['$is_early_buyer_disclosure_required', 'Boolean'],
            ['$date', 'timestamptz']
          )}) {
              update_companies(
              ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
              ${CompanyUtils._set(
                [
                  'is_early_buyer_disclosure_required',
                  '$is_early_buyer_disclosure_required',
                ],
                ['updatedAt', '$date']
              )}
              ) {
              returning {
                  ${CompanyUtils.selectParams('id', 'name', [
                    'is_early_buyer_disclosure_required',
                    'isEarlyBuyerDisclosureRequired',
                  ])}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        is_early_buyer_disclosure_required: p.isEarlyBuyerDisclosureRequired,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateEarlyBuyerDisclosureRequiredStatus',
      e
    );
  }
}
export interface IIsCompanyHiddenStatus extends IWithApolloClient {
  id: number;
  isCompanyHidden: boolean;
}
export interface IIsCompanyHiddenStatusReturn {
  update_companies: {
    returning: {
      id: number;
      name: string;
      isCompanyHidden: boolean;
    }[];
  };
}
export async function updateCompanyHiddenStatusQuery(
  p: IIsCompanyHiddenStatus
) {
  try {
    return await p.client.mutate<IIsCompanyHiddenStatusReturn>({
      mutation: gql`
        mutation updateCompanyHiddenStatus(${CompanyUtils.params(
          ['$id', 'Int'],
          ['$hidden', 'Boolean'],
          ['$date', 'timestamptz']
        )}) {
          update_companies(
          ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
          ${CompanyUtils._set(['hidden', '$hidden'], ['updatedAt', '$date'])}
          ) {
          returning {
            ${CompanyUtils.selectParams('id', 'name', [
              'hidden',
              'isCompanyHidden',
            ])}
          }
        }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        hidden: p.isCompanyHidden,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateCompanyHiddenStatus',
      e
    );
  }
}

export interface ICDASignedInformation extends IWithApolloClient {
  id: number;
  isSellerCDASigned: boolean;
  sellerCDASignatureDate: Date | undefined;
  sellerCDAExpiryDate: Date | undefined;
}
export interface ICDASignedInformationReturn {
  update_companies: {
    returning: {
      id: number;
      name: string;
      isSellerCDASigned: boolean;
      sellerCDASignatureDate: Date;
      sellerCDAExpiryDate: Date;
    }[];
  };
}
export async function updateCDASignedInformation(p: ICDASignedInformation) {
  try {
    return await p.client.mutate<ICDASignedInformationReturn>({
      mutation: gql`
          mutation updateCDASignatureInformation(${CompanyUtils.params(
            ['$id', 'Int'],
            ['$is_cda_signed', 'Boolean'],
            ['$cda_signature_date', 'timestamptz'],
            ['$cda_expiry_date', 'timestamptz'],
            ['$date', 'timestamptz']
          )}) {
              update_companies(
              ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
              ${CompanyUtils._set(
                ['is_cda_signed', '$is_cda_signed'],
                ['cda_signature_date', '$cda_signature_date'],
                ['cda_expiry_date', '$cda_expiry_date'],
                ['updatedAt', '$date']
              )}
              ) {
              returning {
                  ${CompanyUtils.selectParams(
                    'id',
                    'name',
                    ['is_cda_signed', 'isSellerCDASigned'],
                    ['cda_signature_date', 'sellerCDASignatureDate'],
                    ['cda_expiry_date', 'sellerCDAExpiryDate']
                  )}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        is_cda_signed: p.isSellerCDASigned,
        cda_signature_date: p.sellerCDASignatureDate
          ? p.sellerCDASignatureDate
          : null,
        cda_expiry_date: p.sellerCDAExpiryDate ? p.sellerCDAExpiryDate : null,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateCDASignedInformation',
      e
    );
  }
}
export interface ICPASignedInformation extends IWithApolloClient {
  id: number;
  isCPASigned: boolean;
  CPASignatureDate: Date | undefined;
}
export interface ICPASignedInformationReturn {
  update_companies: {
    returning: {
      id: number;
      name: string;
      isCPASigned: boolean;
      CPASignatureDate: Date | undefined;
    }[];
  };
}
export async function updateCPASignedInformation(p: ICPASignedInformation) {
  try {
    return await p.client.mutate<ICPASignedInformationReturn>({
      mutation: gql`
          mutation updateCPASignatureInformation(${CompanyUtils.params(
            ['$id', 'Int'],
            ['$is_cpa_signed', 'Boolean'],
            ['$cpa_signature_date', 'timestamptz'],
            ['$date', 'timestamptz']
          )}) {
              update_companies(
              ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
              ${CompanyUtils._set(
                ['is_cpa_signed', '$is_cpa_signed'],
                ['cpa_signature_date', '$cpa_signature_date'],
                ['updatedAt', '$date']
              )}
              ) {
              returning {
                  ${CompanyUtils.selectParams(
                    'id',
                    'name',
                    ['is_cpa_signed', 'isCPASigned'],
                    ['cpa_signature_date', 'CPASignatureDate']
                  )}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        is_cpa_signed: p.isCPASigned,
        cpa_signature_date: p.CPASignatureDate ? p.CPASignatureDate : null,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateCPASignedInformation',
      e
    );
  }
}
export interface IFetchAllSellerContacts extends IWithApolloClient {
  companyId: number;
  isAdmin: boolean;
}
export interface IFetchAllSellerContactsReturn {
  contact_person: {
    id: number;
    userId?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    assignedMarketsName?: string;
    assignedMarkets?: string;
    companyId: number;
  }[];
}
export async function getSellerContactInformationByCompanyId(
  p: IFetchAllSellerContacts
) {
  try {
    if (p.isAdmin) {
      return await p.client.query<IFetchAllSellerContactsReturn>({
        query: gql`
          ${new u.Query('getSellerContactInformationByCompanyId')
            .params(['$companyId', 'Int'])
            .query(contactPersonSchema, 'contact_person')
            .select(
              'id',
              ['user_id', 'userId'],
              ['first_name', 'firstName'],
              ['last_name', 'lastName'],
              'email',
              ['phone_number', 'phoneNumber'],
              ['assigned_markets_name', 'assignedMarketsName'],
              ['assigned_markets', 'assignedMarkets'],
              ['company_id', 'companyId']
            )
            .where((t) => t._eq('company_id', '$companyId'))
            .orderBy('id', 'asc')
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: {
          companyId: p.companyId,
        },
      });
    }
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> getSellerContactInformationByCompanyId',
      e
    );
  }
}
export interface IFetchSellerContactByUserId extends IWithApolloClient {
  companyId: number;
  userId: number;
}
export interface IFetchSellerContactByUserIdReturn {
  contact_person: {
    id: number;
    userId?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    assignedMarketsName?: string;
    assignedMarkets?: string;
    companyId: number;
  };
}
export async function getSellerContactInformationByCompanyIdAndUserId(
  p: IFetchSellerContactByUserId
) {
  try {
    return await p.client.query<IFetchSellerContactByUserIdReturn>({
      query: gql`
        ${new u.Query('getSellerContactInformationByCompanyIdAndUserId')
          .params(['$companyId', 'Int'], ['$userId', 'Int'])
          .query(contactPersonSchema, 'contact_person')
          .select(
            'id',
            ['user_id', 'userId'],
            ['first_name', 'firstName'],
            ['last_name', 'lastName'],
            'email',
            ['phone_number', 'phoneNumber'],
            ['assigned_markets_name', 'assignedMarketsName'],
            ['assigned_markets', 'assignedMarkets'],
            ['company_id', 'companyId']
          )
          .where((u) =>
            u._and(
              u._eq('company_id', '$companyId'),
              u._eq('user_id', '$userId')
            )
          )
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyId: p.companyId,
        userId: p.userId,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> getSellerContactInformationByCompanyIdAndUserId',
      e
    );
  }
}
export interface ICreateSellerContactInfo extends IWithApolloClient {
  companyId: number;
  userId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  assignedMarketsName?: string;
  assignMarket?: string;
}
export interface ICreateSellerContactInfoReturn {
  contact_person: {
    returning: {
      id: number;
      name: string;
    };
  };
}
export async function createSellerContactInfoMutation(
  p: ICreateSellerContactInfo
) {
  try {
    const date = new Date();
    return await p.client.mutate<ICreateSellerContactInfoReturn>({
      mutation: gql`
        ${new u.Mutation('createSellerContactInformation')
          .params(
            ['$companyId', 'Int'],
            ['$userId', 'Int'],
            ['$firstName', 'String'],
            ['$lastName', 'String'],
            ['$email', 'String'],
            ['$phoneNumber', 'String'],
            ['$assignedMarketsName', 'String'],
            ['$assignedMarkets', 'String'],
            ['$date', 'timestamptz']
          )
          .insert(contactPersonSchema, 'contact_person')
          .objects([
            ['company_id', '$companyId'],
            ['user_id', '$userId'],
            ['first_name', '$firstName'],
            ['last_name', '$lastName'],
            ['email', '$email'],
            ['phone_number', '$phoneNumber'],
            ['assigned_markets_name', '$assignedMarketsName'],
            ['assigned_markets', '$assignedMarkets'],
            ['created_at', '$date'],
            ['updated_at', '$date'],
          ])
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        companyId: p.companyId,
        userId: p.userId,
        firstName: p.firstName,
        lastName: p.lastName,
        email: p.email,
        phoneNumber: p.phoneNumber,
        assignedMarketsName: p.assignedMarketsName,
        assignedMarkets: p.assignMarket,
        date,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> createSellerContactInfoMutation',
      e
    );
  }
}

export interface IRemarksReturn {
  update_companies: {
    returning: {
      companyId: number;
      name: string;
      remarks: string;
    }[];
  };
}

export async function updateRemarks(p: IRemarks) {
  try {
    return await p.client.mutate<IRemarksReturn>({
      mutation: gql`
          mutation updateRemarks(${CompanyUtils.params(
            ['$id', 'Int'],
            ['$remarks', 'String']
          )}) {
              update_companies(
              ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
              ${CompanyUtils._set(['admin_remarks', '$remarks'])}
              ) {
              returning {
                  ${CompanyUtils.selectParams(['id', 'companyId'], 'name', [
                    'admin_remarks',
                    'remarks',
                  ])}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.companyId,
        remarks: p.remarks,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateRemarks',
      e
    );
  }
}

export interface ICompanySummaryReturn {
  update_companies: {
    returning: {
      companyId: number;
      name: string;
      summary: string;
    }[];
  };
}

export async function updateCompanySummary(p: ICompanySummary) {
  try {
    return await p.client.mutate<ICompanySummaryReturn>({
      mutation: gql`
        mutation updateCompanySummary(${CompanyUtils.params(
          ['$id', 'Int'],
          ['$summary', 'String']
        )}) {
          update_companies(
          ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
          ${CompanyUtils._set(['summary', '$summary'])}
          ) {
          returning {
            ${CompanyUtils.selectParams(['id', 'companyId'], 'name', [
              'summary',
              'summary',
            ])}
          }
        }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.companyId,
        summary: p.summary,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateCompanySummary',
      e
    );
  }
}

export interface IUpdateSellerContactInformation extends IWithApolloClient {
  id: number;
  companyId: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  assignedMarketsName?: string;
  assignMarket?: string;
}
export interface IUpdateSellerContactInformationReturn {
  update_contact_person: {
    returning: {
      id: number;
      companyId: number;
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      assignedMarketsName?: string;
      assignMarket?: string;
    }[];
  };
}
export async function updateSellerContactInformation(
  p: IUpdateSellerContactInformation
) {
  try {
    const date = new Date();
    return await p.client.mutate<IUpdateSellerContactInformationReturn>({
      mutation: gql`
          mutation updateSellerContactInformation(${ContactPersonUtils.params(
            ['$id', 'Int'],
            ['$companyId', 'Int'],
            ['$firstName', 'String'],
            ['$lastName', 'String'],
            ['$email', 'String'],
            ['$phoneNumber', 'String'],
            ['$assignedMarketsName', 'String'],
            ['$assignedMarkets', 'String'],
            ['$date', 'timestamptz']
          )}) {
              update_contact_person(
              ${ContactPersonUtils.where(ContactPersonUtils._eq('id', '$id'))}
              ${ContactPersonUtils._set(
                ['company_id', '$companyId'],
                ['first_name', '$firstName'],
                ['last_name', '$lastName'],
                ['email', '$email'],
                ['phone_number', '$phoneNumber'],
                ['assigned_markets_name', '$assignedMarketsName'],
                ['assigned_markets', '$assignedMarkets'],
                ['updated_at', '$date']
              )}
              ) {
              returning {
                  ${ContactPersonUtils.selectParams(
                    'id',
                    ['company_id', 'companyId'],
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    ['email', 'email'],
                    ['phone_number', 'phoneNumber'],
                    ['assigned_markets_name', 'assignedMarketsName'],
                    ['assigned_markets', 'assignedMarkets']
                  )}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        companyId: p.companyId,
        firstName: p.firstName,
        lastName: p.lastName,
        email: p.email,
        phoneNumber: p.phoneNumber,
        assignedMarketsName: p.assignedMarketsName,
        assignedMarkets: p.assignMarket,
        date,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateSellerContactInformation',
      e
    );
  }
}
export interface IDeleteSellerContact extends IWithApolloClient {
  id: number;
}
export async function deleteSellerContactMutation(p: IDeleteSellerContact) {
  try {
    const date = new Date();
    return await p.client.mutate({
      mutation: gql`
        mutation deleteSellerContactMutation(${ContactPersonUtils.params(
          ['$id', 'Int'],
          ['$date', 'timestamptz']
        )}) {
          update_contact_person(
          ${ContactPersonUtils.where(ContactPersonUtils._eq('id', '$id'))}
          ${ContactPersonUtils._set(['deleted_at', '$date'])}
          ) {
          returning {
            ${ContactPersonUtils.selectParams('id')}
          }
        }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        date,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts  -> deleteSellerContactMutation',
      e
    );
  }
}
export interface ISellerCompanyNameAndCountry extends IWithApolloClient {
  id: number;
  name: string;
  country: string;
}
export interface ISellerCompanyNameAndCountryReturn {
  update_companies: {
    returning: {
      id: number;
      name: string;
      country: string;
    }[];
  };
}
export async function updateSellerCompanyNameAndCountry(
  p: ISellerCompanyNameAndCountry
) {
  try {
    // @ts-ignore
    return await p.client.mutate<ISellerCompanyNameAndCountryReturn>({
      mutation: gql`
        mutation updateSellerCompanyNameAndCountry(${CompanyUtils.params(
          ['$id', 'Int'],
          ['$name', 'String'],
          ['$country', 'String'],
          ['$date', 'timestamptz']
        )}) {
          update_companies(
          ${CompanyUtils.where(CompanyUtils._eq('id', '$id'))}
          ${CompanyUtils._set(
            ['name', '$name'],
            ['country', '$country'],
            ['updatedAt', '$date']
          )}
          ) {
          returning {
            ${CompanyUtils.selectParams('id', 'name', ['country', 'country'])}
          }
        }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        name: p.name,
        country: p.country,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/query.ts -> updateSellerCompanyNameAndCountry',
      e
    );
  }
}
