import fetch from 'cross-fetch';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';

const uri = process.env.GQL_API_URL;
const cache = new InMemoryCache();

export const client = new ApolloClient({
  ssrMode: true,
  link: createHttpLink({
    uri,
    credentials: 'include',
    fetch,
    headers: {
      'content-type': 'application/json',
    },
  }),
  cache,
});
