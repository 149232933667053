export interface IFragment {
  fragment: (
    isAdmin?: boolean,
    logged?: boolean,
    includeDeals?: boolean
  ) => string;
  name: string;
}
export function isIFragment(a: any): a is IFragment {
  return (
    typeof a === 'object' &&
    'name' in a &&
    'fragment' in a &&
    typeof a.name === 'string' &&
    typeof a.fragment === 'function'
  );
}
