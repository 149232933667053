import { isBrowser } from 'app/store/utils/is-browser';
import request from 'app/utils/request';
import {
  getOmnisendUserEmail,
  useOmnisendUser,
} from 'app/utils/omnisend-query-params';
import { startCase } from 'lodash';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';

let analytics: any;
const xAPIKey =
  '60ad164eb4916a001a784c33-ru3XlEFiLE1HNJ2y2dvVxnQm5p3PxRLKQsSzaLIg1mFC8rsirm';
declare global {
  interface Window {
    dataLayer: any;
    location: Location;
  }
}
const productionHost = 'www.pipelinepharma.com';

if (isBrowser()) {
  const tagManagerArgs = {
    gtmId:
      window.location.hostname === productionHost
        ? 'GTM-5JTM5V9'
        : 'GTM-WCG2GR8',
  };
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  analytics = require('react-segment');

  TagManager.initialize(tagManagerArgs);
  analytics.default.load('fb59JPp8bFtMlinRsEHHwWvG7sX88kVs');
}

const ReactGA: any = {};

ReactGA.event = async (args: {
  category: string;
  action: string;
  label?: string;
  // TODO change naming to "anonymousUserId"
  // TODO add "userId" if user is logged in.
  // TODO add "email" if user is logged in.
  // TODO add "numberOfFieldsFilledInBuyerQualificationForm" if user is logged in.
  anonymousUserId?: string;
  numberOfFieldsFilledInBuyerQualificationForm?: string;
  email?: string;
}) => {
  const {
    category,
    action,
    label,
    anonymousUserId,
    numberOfFieldsFilledInBuyerQualificationForm,
    email,
  } = args;

  const dataLayer = window.dataLayer || [];

  dataLayer.push({
    event: 'GTM',
    category,
    action,
    label,
    anonymousUserId,
    numberOfFieldsFilledInBuyerQualificationForm,
  });
  if ((window as any).omnisend) {
    const omniEmail: string | null | undefined =
      email || getOmnisendUserEmail();

    omniEmail &&
      sendOmnisendEvent({
        eventName: action,
        systemEventName: action,
        email: omniEmail,
        fields: {
          anonymousUserId,
          numberOfFieldsFilledInBuyerQualificationForm,
          label,
          category,
        },
      });
  }
};

const sendOmnisendEvent = (data: {
  eventName: string;
  systemEventName: string;
  email: string;
  fields: {
    category: string;
    label?: string;
    numberOfFieldsFilledInBuyerQualificationForm?: string;
    anonymousUserId?: string;
  };
}) => {
  try {
    const payload: any = {
      name: data.eventName,
      systemName: data.systemEventName.replace(/ /g, ''),
      fields: { category: data.fields.category },
      email: data.email,
    };
    if (data.fields.label) {
      payload.fields.label = data.fields.label;
    }
    if (data.fields.numberOfFieldsFilledInBuyerQualificationForm) {
      payload.fields.numberOfFieldsFilledInBuyerQualificationForm =
        data.fields.numberOfFieldsFilledInBuyerQualificationForm;
    }
    if (data.fields.anonymousUserId) {
      payload.fields.anonymousUserId = data.fields.anonymousUserId;
    }

    return request('https://api.omnisend.com/v3/events', {
      headers: {
        'content-type': 'application/json',
        'x-api-key': xAPIKey,
      },
    }).post(payload);
  } catch (e) {
    console.log(e);
    return e;
  }
};

export { ReactGA };

export enum GACategory {
  Landing = 'Landing page',
  LinkedinAuth = 'Linkedin authentication',
  SellerLanding = 'Seller landing page',
  MABuyerLanding = 'MA buyer landing page',
  Partnering = 'Partnering with us',
  FAQForBuyersPage = 'FAQ For buyers',
  FAQForAquisitionsBuyers = 'FAQ For aquisitions buyers',
  FAQForAquisitionsSellers = 'FAQ For aquisitions sellers',
  FAQForSellersPage = 'FAQ For sellers',
  Register = 'Register',
  Search = 'Search',
  Marketplace = 'Marketplace',
  MarketplaceFilters = 'Marketplace filters',
  Contact = 'Contact',
  ProductsLicensing = 'Products licensing',
  OffersLicensing = 'Offers licensing',
  UserSettings = 'User settings',
  ProductsTrading = 'Products trading',
  OffersTrading = 'Offers trading',
  Requests = 'Requests ',
  CompanyDirectory = 'Company directory',
  PharmaceuticalManufacturers = 'Pharmaceutical manufacturers list',
  ManufacturersByCountry = 'Pharmaceutical manufacturers in country list',
  FastTrackProgram = 'Fast track program',
  ProductInfoPages = 'Product info pages',
  RFQ = 'RFQ',
}

enum GAActions {
  withPrice = 'With price',
  goToMarketplaceCenter = 'Go to marketplace page center',
  goToMarketplaceBottom = 'Go to marketplace page bottom',
  joinButtonTop = 'Join button top',
  joinButtonBottom = 'Join button bottom',
  goToFAQBottom = 'Go to FAQ bottom',
  register = 'Register',
  registerFromProductRequest = 'Register from product request',
  registerFromSellerPage = 'Register from seller page',
  registerGetStarted = 'Register (Get started)',
  registerGetStartedFromProductRequest = 'Register (Get started) from product request',
  registerGetStartedFromSellerPage = 'Register (Get started) from seller page',
  registerCreateAccount = 'Register (Create account)',
  registerCreateAccountFromProductRequest = 'Register (Create account) from product request',
  registerCreateAccountFromSellerPage = 'Register (Create account) from seller page',
  searchInNavbarText = 'Search in navbar text',
  searchInNavbarSubstance = 'Search in navbar substance select',
  searchText = 'Search text',
  searchSubstance = 'Search substance select',
  searchNotFound = 'Search product not found',
  requestProduct = 'Request product click',
  requestAlternativeSellersSkipClose = 'Request product alternative sellers modal close click (skip)',
  requestAlternativeSellersSkip = 'Request product alternative sellers skip click',
  requestAlternativeSellersSend = 'Request product alternative sellers send click',
  requestProductDealCreated = 'Request product deal created',
  requestProductAlternativeDealsCreated = 'Request product alternative requests created',
  subscribe = 'Subscribe',
  contactFormSubmit = 'contact form submit',

  regularProductInfoVisit = 'Regular product info page visit',
  regularOfferInfoVisit = 'Regular offer info page visit',
  fastTrackProductInfoVisit = 'Fast track product info page visit',
  fastTrackOfferInfoVisit = 'Fast track offer info page visit',

  linkedinLoginSuccess = 'Login with LinkedIn success',
  linkedinLoginFailed = 'Login with LinkedIn failed',
  linkedinRegisterSuccess = 'Register with LinkedIn success',
  linkedinRegisterFailed = 'Register with LinkedIn failed',

  filtersClearManufacturer = 'Clear manufacturer filters',
  filtersClearAll = 'Clear all filters',
  filterRemove = 'Remove one filter',
  filterSelect = 'Select filter',
  filterSelectValue = 'Select filter value',

  deleteLicensingProduct = 'delete licensing product',
  createLicensingProduct = 'create licensing product',
  updateLicensingProduct = 'update licensing product',

  saveUserSettings = 'save user settings',
  productsForSale = 'products for sale button click',
  becomeASeller = 'become a seller button click',
  copyEvent = 'copy event',

  companyDirectoryGetMoreDetailsAboutTheseCompanies = 'Get more details about these companies header button click',
  companyDirectoryGetTheList = 'Get the list button click',
  companyDirectoryStartSellingProducts = 'Start selling products button click',
  companyDirectoryLookingForQualifiedDealsFormSubmit = 'Looking for qualified leads for your business form submit',

  fastTrackProgramGetStartedTop = 'Top get started click',
  fastTrackProgramFindWhatYouWantClick = 'Find what you want search product click',
  fastTrackProgramGetMultipleOfferClick = 'Get multiple offer search product click',
  fastTrackProgram1PageTermSheetClick = 'One page term sheet search product click',
  fastTrackProgramFindFastTrackProductClick = 'Find fast track product bottom search product click',
  fastTrackProgramStartSellingProductsClick = 'Find fast track product bottom start selling products click',

  RFQ_GET_OFFER = 'pressed get offer',
  RFQ_SEND_SUCCESS = 'pressed send success',
  RFQ_SEND_ERROR = 'pressed send validation error',
  RFQ_GET_STARTED = 'account creation initiated',
  RFQ_CREATE_MY_ACCOUNT = 'details submited succesfully',
  RFQ_COMPANY_FIELDS_SUBMITED = 'company details submited',
}

Object.keys(GAActions).map(
  (key) => (GAActions[key] = startCase(GAActions[key]))
);
export { GAActions };

export const withTracker = (WrappedComponent: any) => {
  const trackPage = (page: any) => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      event: 'pageview',
    });
    if (analytics) {
      analytics.default.page(page);
    }
  };

  const HOC: any = (props: any) => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname,
    ]);
    useOmnisendUser(xAPIKey);
    return <WrappedComponent {...props} />;
  };

  return HOC;
};
