import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { dealMessageSchema, requestSchema } from '../../query-utils/schema';
import { EEditFields } from '../types';

export interface IApproveQuery extends IWithApolloClient {
  id: number;
  approved: boolean;
}
export async function approveQuery(p: IApproveQuery) {
  try {
    return await p.client.mutate({
      mutation: gql`
        ${new u.Mutation('approveDeal')
          .params(
            ['$id', 'Int'],
            ['$approved', 'Boolean'],
            ['$date', 'timestamptz']
          )
          .update(requestSchema)
          .where((t) => t._eq('id', '$id'))
          .set(
            ['admin_approved', '$approved'],
            ['updated_at', '$date'],
            ['buyer_updated_at', '$date'],
            ['seller_updated_at', '$date']
          )
          .returning('id')
          .toString()
          .update(dealMessageSchema, 'msg')
          .where((t) =>
            t._and(
              t._eq('request_id', '$id'),
              t._or(
                t._eq('field_id', EEditFields.ProductRequestInformation),
                t._eq('field_id', EEditFields.ProductRequestSuccessfull)
              )
            )
          )
          .set(['seller_see', true])
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        approved: p.approved,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error('src/app/store/modules/requests/query.ts -> agreeQuery', e);
  }
}
