import { Breakpoint } from 'app/styles/breakpoint';
import sg from 'app/utils/safe-get';

export interface State {
  breakpoint: Breakpoint;
  width: number;
  firstBreakpointSet: boolean;
  serviceWorker: {
    installed: boolean;
    updateAvailable: boolean;
  };
}

export const defaultStore: State = {
  breakpoint: Breakpoint.Small,
  width: sg(() => window.innerWidth, 0),
  firstBreakpointSet: false,
  serviceWorker: {
    installed: false,
    updateAvailable: false,
  },
};
