import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import * as u from '../../query-utils';
import {
  IUserSettingFragment,
  userSettingFragment,
} from '../fragments/user-settings';

export interface IGetUserSettings extends IWithApolloClient {
  uuid: string;
}
export interface IGetUserSettingsReturn {
  users: IUserSettingFragment[];
}
export async function getUserSettings(p: IGetUserSettings) {
  try {
    return await p.client.query<IGetUserSettingsReturn>({
      query: gql`
      ${userSettingFragment.fragment()}
      query getUserSettings(${u.params(['$uuid', 'String'])}) {
        users(${u.where(u._eq('uuid', '$uuid'))}) {
          ...${userSettingFragment.name}
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: { uuid: p.uuid },
    });
  } catch (e) {
    logger.error('src/app/store/modules/auth/query.ts -> getUserSettings', e);
  }
}
