import { selectParams } from '../../query-utils';
import { IFragment } from '../../query-utils/fragment';
import { Schema_Company } from '../../query-utils/schema';

export interface ICompanyFragment {
  id: number;
  name: string;
  hidden: boolean;
  country: string;
  createdBy: number;
}

export const companyFragment: IFragment = {
  fragment: (isAdmin = false, isLogged = false, isElastic = false) => `
    fragment companyFragment on ${isElastic ? 'Company' : 'companies'} {
      ${selectParams<Schema_Company>(
        'id',
        'hidden',
        isAdmin && 'name',
        'country',
        'createdBy',
        isAdmin && 'summary',
        isAdmin && ['annual_revenue', 'annualRevenue'],
        isAdmin && ['number_of_employees', 'numberOfEmployees'],
        isAdmin && ['sales_channels', 'salesChannels'],
        isAdmin && ['therapy_areas', 'therapyAreas'],
        isAdmin && ['portfolio_products_list', 'portfolioProductsList']
      )}
    }
  `,
  name: 'companyFragment',
};
