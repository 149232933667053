import {
  SubstanceBrandsUtils,
  SubstancesTherapyAreasUtils,
  SubstanceUtils,
  TherapyAreasUtils,
} from '../query-utils';
import gql from 'graphql-tag';
import * as u from '../query-utils';
import {
  brandsSchema,
  substanceBrandsSchema,
  substanceSchema,
  substanceTherapyAreasSchema,
} from '../query-utils/schema';
import { logger } from '../../../utils/logger';
import { IWithApolloClient } from '../global/types';
import { SubstanceFilter } from './types';
import sg from '../../../utils/safe-get';
import { toUpper } from 'lodash';

export interface Substance {
  name: string;
  bran?: string;
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  therapyArea?: number;
  SubstanceBrands?: any[];
  importedAt?: Date;
  importSourceId?: string;
  importId?: string;
  searchHitCount?: number;
  substanceId?: string;
}
export interface IGetSubstanceListReturn {
  substances: [];
  substances_aggregate: { aggregate: { totalCount: number } };
}
export interface IGetSubstanceList extends IWithApolloClient {
  size: number;
  offset: number;
  isAdmin: boolean;
  filter?: SubstanceFilter;
}
export async function getSubstanceListQuery(p: IGetSubstanceList) {
  try {
    const therapyFilters: string[] = [];
    for (const tf of p.filter?.therapy_area) {
      therapyFilters.push(tf.value.toString());
    }

    const brandFilters: string[] = [];
    for (const tf of p.filter?.substance_brands) {
      brandFilters.push(tf.value.toString());
    }

    const where = SubstanceUtils._and(
      SubstanceUtils._and(
        p.filter?.name ? SubstanceUtils._ilike('name', '$name') : '',
        p.filter?.therapy_area
          ? SubstanceUtils.nest(
              'therapy_areas',
              SubstancesTherapyAreasUtils.nest(
                'therapy_area',
                TherapyAreasUtils._in(
                  'therapy_area',
                  therapyFilters.map((tf) => `"${tf}"`)
                )
              )
            )
          : '',
        p.filter?.substance_brands
          ? SubstanceUtils.nest(
              'SubstanceBrands',
              SubstanceBrandsUtils._in(
                'brand_name',
                brandFilters.map((br) => `"${br}"`)
              )
            )
          : ''
      ),
      SubstanceUtils._is_null('deleted_at', true)
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetSubstanceListReturn>({
      query: gql`
        ${new u.Query('substanceListPage', {
          isAdmin: p.isAdmin,
        })
          .params(['$size', 'Int'], ['$offset', 'Int'], ['$name', 'String'])
          .query(substanceSchema, 'substances')
          .limit('$size')
          .offset('$offset')
          .orderBy('updatedAt', 'desc')
          .where(() => where)
          .select(
            ['updatedAt', 'updatedAt'],
            ['id', 'id'],
            ['name', 'name'],
            [
              'therapy_areas',
              true,
              SubstancesTherapyAreasUtils.selectParams(
                [
                  'therapy_area',
                  true,
                  TherapyAreasUtils.selectParams(['display', 'display']),
                ],
                ['therapyareas_id', 'therapyareasId']
              ),
            ],
            [
              'SubstanceBrands',
              true,
              SubstanceBrandsUtils.selectParams(['brand_name', 'brandName']),
            ],
            ['seo_text_footer', 'seoTextFooter'],
            ['substance_id', 'substanceId']
          )
          .toString()
          .aggregate(substanceSchema, 'substances_aggregate')
          .where(() => where)
          .count('totalCount')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        size: p.size,
        offset: p.offset,
        name:
          p.filter && p.filter.name
            ? SubstanceUtils.like(p.filter.name, 'begining')
            : undefined,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/substances/query.ts -> getSubstanceListQuery',
      e
    );
  }
}
export interface IDeleteSubstance extends IWithApolloClient {
  id: number;
}

export interface IDeleteSubstanceReturn {
  update_substances: {
    returning: { id: number }[];
  };
}

export async function deleteSubstanceQuery(p: IDeleteSubstance) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.mutate<IDeleteSubstanceReturn>({
      mutation: gql`
        mutation deleteSubstance(${SubstanceUtils.params(
          ['$id', 'Int'],
          ['$deletedAt', 'timestamptz']
        )}) {
          update_substances(
          ${SubstanceUtils.where(SubstanceUtils._eq('id', '$id'))}
          ${SubstanceUtils._set(['deleted_at', '$deletedAt'])}
          ) {
          returning {
            ${SubstanceUtils.selectParams('id')}
          }
        }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        deletedAt: new Date().toDateString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/substances/query.ts -> deleteSubstanceQuery',
      e
    );
  }
}

export interface IFetchBrandNames extends IWithApolloClient {
  amount: number;
  template: string;
}

export interface IFetchBrandNamesReturn {
  brands: ISelectItem<string>[];
}

export async function fetchBrandNames(p: IFetchBrandNames) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchBrandNamesReturn>({
      query: gql`
        ${new u.Query('fetchBrandNames')
          .params(['$amount', 'Int'], ['$text', 'String'])
          .query(brandsSchema, 'brands')
          .select(['id', 'value'], ['name', 'label'])
          .limit('$amount')
          .where((t) => t._ilike('name', '$text'))
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        amount: p.amount,
        text: u.like(p.template, 'begining'),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/substances/query.ts -> fetchBrandNames',
      e
    );
  }
}

export interface ICreateSubstance extends IWithApolloClient {
  name: string;
  seoText?: string;
  brands: ISelectItem<number>[];
  therapyAreas: ISelectItem<number>[];
  editSubstanceId?: number;
}
export interface ICreateSubstanceReturn {
  substances: {
    returning: {
      id: number;
    };
  };
}
export interface ICreateTherapyAreasReturn {
  substances_therapy_areas: {
    returning: {
      id: number;
    };
  };
}
export interface ICreateBrandsReturn {
  substance_brands: {
    returning: {
      id: number;
    };
  };
}
export async function createSubstanceMutation(p: ICreateSubstance) {
  try {
    const date = new Date();
    const substanceId = toUpper(p.name.trim().replace(/ /g, '_'));
    // @ts-ignore
    const result = await p.client.mutate<ICreateSubstanceReturn>({
      mutation: gql`
        ${new u.Mutation('createSubstance')
          .params(
            ['$name', 'String'],
            ['$substanceId', 'String'],
            ['$seoTextFooter', 'String'],
            ['$date', 'timestamptz']
          )
          .insert(substanceSchema, 'substances')
          .objects([
            ['name', '$name'],
            ['seo_text_footer', '$seoTextFooter'],
            ['createdAt', '$date'],
            ['updatedAt', '$date'],
            ['substance_id', '$substanceId'],
          ])
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        name: p.name,
        substanceId: substanceId,
        date,
        seoTextFooter: p.seoText,
      },
    });
    const substanceIdCreated = sg(
      () => result!.data!.substances.returning[0].id,
      undefined
    );
    for (let i = 0; i < p.therapyAreas.length; i++) {
      // @ts-ignore
      await p.client.mutate<ICreateTherapyAreasReturn>({
        mutation: gql`
          ${new u.Mutation('createTherapyArea')
            .params(['$substanceId', 'Int'], ['$therapyareasId', 'String'])
            .insert(substanceTherapyAreasSchema, 'substances_therapy_areas')
            .objects([
              ['substances_id', '$substanceId'],
              ['therapyareas_id', '$therapyareasId'],
            ])
            .returning('id')
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: {
          substanceId: substanceIdCreated,
          therapyareasId: p.therapyAreas[i],
        },
      });
    }
    for (let i = 0; i < p.brands.length; i++) {
      // @ts-ignore
      await p.client.mutate<ICreateBrandsReturn>({
        mutation: gql`
          ${new u.Mutation('createSubstanceBrands')
            .params(
              ['$substanceId', 'Int'],
              ['$substanceName', 'String'],
              ['$brandName', 'String'],
              ['$date', 'timestamptz']
            )
            .insert(substanceBrandsSchema, 'substance_brands')
            .objects([
              ['substance_id', '$substanceId'],
              ['substance_name', '$substanceName'],
              ['brand_name', '$brandName'],
              ['created_at', '$date'],
              ['updated_at', '$date'],
            ])
            .returning('id')
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: {
          substanceId: substanceIdCreated,
          substanceName: substanceId,
          brandName: p.brands[i],
          date,
        },
      });
    }
    return sg(
      () => result!.data!.substances_therapy_areas.returning[0].id,
      undefined
    );
  } catch (e) {
    logger.error(
      'src/app/store/modules/substances/query.ts -> createSubstanceMutation',
      e
    );
  }
}
export async function updateSubstanceMutation(p: ICreateSubstance) {
  try {
    const date = new Date();
    const substanceId = toUpper(p.name.trim().replace(/ /g, '_'));
    // @ts-ignore
    const result = await p.client.mutate<ICreateSubstanceReturn>({
      mutation: gql`
        ${new u.Mutation('createSubstance')
          .params(
            ['$id', 'Int'],
            ['$name', 'String'],
            ['$substanceId', 'String'],
            ['$seoTextFooter', 'String'],
            ['$date', 'timestamptz']
          )
          .update(substanceSchema, 'substances')
          .where((t) => t._eq('id', '$id'))
          .set(
            ['name', '$name'],
            ['seo_text_footer', '$seoTextFooter'],
            ['createdAt', '$date'],
            ['updatedAt', '$date'],
            ['substance_id', '$substanceId']
          )
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.editSubstanceId,
        name: p.name,
        substanceId: substanceId,
        date,
        seoTextFooter: p.seoText,
      },
    });
    // @ts-ignore
    await p.client.mutate<any>({
      mutation: gql`
        ${new u.Mutation('deleteSubstancesTherapyAreas')
          .params(['$id', 'Int'])
          .delete(substanceTherapyAreasSchema, 'substances_therapy_areas')
          .where((t) => t._eq('substances_id', '$id'))
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.editSubstanceId,
      },
    });
    // @ts-ignore
    await p.client.mutate<any>({
      mutation: gql`
        ${new u.Mutation('deleteSubstanceBrands')
          .params(['$id', 'Int'])
          .delete(substanceBrandsSchema, 'substance_brands')
          .where((t) => t._eq('substance_id', '$id'))
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.editSubstanceId,
      },
    });

    for (let i = 0; i < p.therapyAreas.length; i++) {
      // @ts-ignore
      await p.client.mutate<ICreateTherapyAreasReturn>({
        mutation: gql`
          ${new u.Mutation('createTherapyArea')
            .params(['$substanceId', 'Int'], ['$therapyareasId', 'String'])
            .insert(substanceTherapyAreasSchema, 'substances_therapy_areas')
            .objects([
              ['substances_id', '$substanceId'],
              ['therapyareas_id', '$therapyareasId'],
            ])
            .returning('id')
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: {
          substanceId: p.editSubstanceId,
          therapyareasId: p.therapyAreas[i],
        },
      });
    }
    for (let i = 0; i < p.brands.length; i++) {
      // @ts-ignore
      await p.client.mutate<ICreateBrandsReturn>({
        mutation: gql`
          ${new u.Mutation('createSubstanceBrands')
            .params(
              ['$substanceId', 'Int'],
              ['$substanceName', 'String'],
              ['$brandName', 'String'],
              ['$date', 'timestamptz']
            )
            .insert(substanceBrandsSchema, 'substance_brands')
            .objects([
              ['substance_id', '$substanceId'],
              ['substance_name', '$substanceName'],
              ['brand_name', '$brandName'],
              ['created_at', '$date'],
              ['updated_at', '$date'],
            ])
            .returning('id')
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: {
          substanceId: p.editSubstanceId,
          substanceName: substanceId,
          brandName: p.brands[i],
          date,
        },
      });
    }
    return sg(() => p.editSubstanceId, undefined);
  } catch (e) {
    logger.error(
      'src/app/store/modules/substances/query.ts -> createSubstanceMutation',
      e
    );
  }
}
