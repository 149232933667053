import styled from 'styled-components';

// custom imports
import { Td as _Td, Tr } from 'app/components/table';
import { _, media } from 'app/styles/breakpoint';

export const ActivityButtonContainer = styled.div<{ isAdmin: boolean }>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  ${(props) =>
    props.isAdmin
      ? `
        min-width: 56px;
        width: 56px;
        `
      : `
        min-width: 90px;
        width: 90px;
        ${_(media.max.md)} {
          min-width: 35px;
          width: 35px;
        }
        `}
`;

export const ButtonDivider = styled.div`
  width: 4px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  word-wrap: break-word;
`;

export const SubLabel = styled.label`
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.grayText};
  cursor: pointer;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayExtraDark};
  cursor: pointer;
`;

export const CenterField = styled.div`
  text-align: center;
`;

export const DateContainer = styled.div`
  min-width: 75px;
`;

interface IStatusBackground {
  color: string;
}

//
export const MainTr = styled(Tr)<any>`
  background: ${(props: IStatusBackground) => props.color};
  cursor: pointer;

  .flag-container {
    display: grid;
    grid-template-columns: 12px 12px 1fr;
    align-items: center;
    justify-content: center;
    grid-column-gap: 5px;
  }

  .buyer-seller-width {
    max-width: 250px;
  }
`;

export const Td = styled(_Td)`
  line-height: unset;

  .user-owned {
    display: inline;
    margin-right: 1ch;
  }
  .buyer-info {
    display: inline;
    margin-right: 7px;
  }
  .unset {
    background-color: ${({ theme }) => theme.colors.grayButton};
    color: ${({ theme }) => theme.colors.muted};
  }
`;

export const ViewLinkedDeal = styled.a`
  width: 13px;
  height: 13px;
  margin-bottom: 5px;
  margin-right: 5px;
  display: inline-block;
`;
