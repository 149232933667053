import { ISellerContactInformation } from 'app/store/modules/seller-information/types';
import React, { useState } from 'react';
import { Components } from '../../../../components/buttons/add-item-blue-button';
import { ICreateContactPerson, IUpdateContactPerson } from '../index';
import { SellerAddContactContainer, SellerContactContainer } from '../styles';
import SellerContactForm from './seller-contact-form';
import SellerContactList from './seller-contact-list';

const { AddItemBlueButtonRadius } = Components;

interface IProps {
  contacts: ISellerContactInformation[] | [];
  companyId: number;
  updateCache: any;
  createSellerContact: (person: ICreateContactPerson) => any;
  updateSellerContact: (person: IUpdateContactPerson) => any;
  deleteSellerContact: (id: number, companyId: number) => any;
  productOwnerId?: number;
}

const SellerContact: React.FC<IProps> = ({
  contacts,
  updateCache,
  createSellerContact,
  updateSellerContact,
  deleteSellerContact,
  companyId,
  productOwnerId,
}) => {
  const [doCreate, setDoCreate] = useState<boolean>(false);
  return (
    <SellerContactContainer>
      <>
        <SellerContactList
          contacts={contacts}
          companyId={companyId}
          createSellerContact={createSellerContact}
          updateSellerContact={updateSellerContact}
          deleteSellerContact={deleteSellerContact}
          updateCache={updateCache}
          productOwnerId={productOwnerId}
        />
        {doCreate && (
          <SellerContactForm
            companyId={companyId}
            createSellerContact={createSellerContact}
            updateSellerContact={updateSellerContact}
            deleteSellerContact={deleteSellerContact}
            isEdit={false}
            onCancel={() => setDoCreate(!doCreate)}
          />
        )}
        {!doCreate && (
          <SellerAddContactContainer>
            <AddItemBlueButtonRadius
              label={'Add new contact'}
              shortLabel={'Add new contact'}
              onClick={() => setDoCreate(!doCreate)}
            />
          </SellerAddContactContainer>
        )}
      </>
    </SellerContactContainer>
  );
};

export default SellerContact;
