import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { RequestUtils } from '../../query-utils';
import { ISetDealLink } from '../actions';

export interface ISetDealLinkReturn {
  update_requests: {
    returning: {
      id: number;
    }[];
  };
}
export async function setDealLinkQuery(p: ISetDealLink) {
  try {
    if (!p.id) {
      return undefined;
    }
    return await p.client.mutate<ISetDealLinkReturn>({
      mutation: gql`
      mutation setRequestLink(${RequestUtils.params(
        ['$id', 'Int'],
        ['$dealId', 'Int'],
        ['$date', 'timestamptz']
      )}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('id', '$id'))}
          ${RequestUtils._set(['deal_id', '$dealId'], ['updated_at', '$date'])}
        ) {
          returning {
            ${RequestUtils.selectParams('id')}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        dealId: p.dealId,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> setDealLinkQuery',
      e
    );
  }
}
