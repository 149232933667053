/* eslint-disable  */
import { all, call, fork, take } from '@redux-saga/core/effects';
import {
  getCalculateRankingItems,
  getProductListQueryForRanking,
  updateProductRankingBulk,
} from 'app/store/modules/admin/query';
import { calculateAutoRating as calculateProductRanking, calculateProductRankingScore } from 'app/store/modules/products/actions';
import { isBrowser } from 'app/store/utils/is-browser';
import { logger } from 'app/utils/logger';
import { convertToProduct } from '../products/fragments/product';
import { calculateRanking } from './actions';

// function* calculateRankingFlow() {
//   if (!isBrowser()) {
//     return;
//   }

//   while (true) {
//     try {
//       const { payload }: ReturnType<typeof calculateRanking> = yield take(
//         calculateRanking
//       );

//       const newRankingCalculationNumber = 2;
//       const params: Params<typeof getCalculateRankingItems> = payload;
//       // const response: Unpromisefy<
//       //   ReturnType<typeof getCalculateRankingItems>
//       // > = yield call(getCalculateRankingItems, params);
//       // if (!response) {
//       //   throw new Error('GraphQL returned undefined');

//       // }

//       // const resultList = response.data.marketplace_list;
//       const offsetString =
//         localStorage.getItem('calculate_ranking_offset') || '';
//       const sizeString =
//         localStorage.getItem('calculate_ranking_size') || '5000';

//       const offset = offsetString !== '' ? parseFloat(offsetString) : 0;
//       const size = sizeString !== '' ? parseFloat(sizeString) : 0;


//       // pagination 

      



//       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//       // @ts-ignore



//       const response: any = yield call(getProductListQueryForRanking, {
//         client: payload.client,
//         size: size,
//         offset,
//         companyId: 0,
//         isAdmin: true,
//         filter: {
//           company: '',
//           form: '',
//           product: '',
//           visible: true,
//         },
//       });
//       const resultList = response.data.products;

//       // eslint-disable-next-line no-console
//       console.log('offset');
//       // eslint-disable-next-line no-console
//       console.log(offset);
//         // eslint-disable-next-line no-console
//         console.log('size');
//         // eslint-disable-next-line no-console
//         console.log(size);
//       // eslint-disable-next-line no-console
//       console.log('totalCount');
//       // eslint-disable-next-line no-console
//       console.log(response.data.products_aggregate.aggregate.totalCount);

//       yield updateProductRankingBulk({
//         productsList: resultList.map((itemInitial: any) => {

//           const item = convertToProduct(itemInitial.Product);
//           // console.log(item)
//           return {
//             id: item.id,
//             autoRanking: calculateProductRanking(item),
//             manualRanking: item.manualRanking, // 500
//             newRankingCalculationNumber,
//           };
//         }),
//         client: params.client,
//         callback: () => {
//           console.log('test');
//         },
//       });

//       /*
//       yield all(
//         resultList.map((item: any, i: number) => {
//           try {
//             if (item.productId && item.Product) {
//               const product = convertToProduct(item.Product);

//               if (
//                 !item.Product.newRankingCalculationNumber ||
//                 item.Product.newRankingCalculationNumber <
//                   newRankingCalculationNumber ||
//                 (item.Product.newRankingCalculationNumber <=
//                   newRankingCalculationNumber &&
//                   product.autoRanking !== calculateProductRanking(product))
//               ) {
//                 // eslint-disable-next-line no-console
//                 console.log('product.id');
//                 // eslint-disable-next-line no-console
//                 console.log(product.id);
//                 // eslint-disable-next-line no-console
//                 console.log('old rank');
//                 // eslint-disable-next-line no-console
//                 console.log(product.autoRanking);
//                 // eslint-disable-next-line no-console
//                 console.log('new rank');
//                 // eslint-disable-next-line no-console
//                 console.log(calculateProductRanking(product));

//                 const callback = () => {
//                   offset++;

//                   localStorage.setItem(
//                     'calculate_ranking_offset',
//                     offset.toString()
//                   );
//                 };
//                 // eslint-disable-next-line no-console
//                 console.log('updating');

//                 return updateProductRanking({
//                   client: params.client,
//                   id: product.id,
//                   autoRanking: calculateProductRanking(product),
//                   manualRanking: product.manualRanking, // 500
//                   newRankingCalculationNumber,
//                   callback,
//                 });
//               } else {
//                 // eslint-disable-next-line no-console
//                 console.log('skiping');
//                 offset++;

//                 localStorage.setItem(
//                   'calculate_ranking_offset',
//                   offset.toString()
//                 );
//               }
//             }  else {
//               // eslint-disable-next-line no-console
//               console.log('skiping');
//               offset++;

//               localStorage.setItem(
//                 'calculate_ranking_offset',
//                 offset.toString()
//               );
//             }
//           } catch (e) {
//             localStorage.setItem('calculate_ranking_offset', offset.toString());

//             return logger.error(
//               `src/app/store/modules/admin/sagas.ts -> calculateRankingFlow -> resultList.map[${i}]`,
//               e
//             );
//           }
//         })
//       );
// */
//       // yield call(authenticate, username, password);
//     } catch (e) {
//       logger.error(
//         'src/app/store/modules/admin/sagas.ts -> calculateRankingFlow',
//         e
//       );
//     }
//   }
// }

// export default function* rootSaga() {
//   yield all([fork(calculateRankingFlow)]);
// }




function* calculateRankingFlowUpdated() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const { payload }: ReturnType<typeof calculateRanking> = yield take(
        calculateRanking
      );
      console.log('calculateRanking')

      const params: Params<typeof getCalculateRankingItems> = payload;
      // const response: Unpromisefy<
      //   ReturnType<typeof getCalculateRankingItems>
      // > = yield call(getCalculateRankingItems, params);
      // if (!response) {
      //   throw new Error('GraphQL returned undefined');

      // }

     /*

      // eslint-disable-next-line no-console
      console.log('offset');
      // eslint-disable-next-line no-console
      console.log(offset);
        // eslint-disable-next-line no-console
        console.log('size');
        // eslint-disable-next-line no-console
        console.log(size);
      // eslint-disable-next-line no-console
      console.log('totalCount');
      // eslint-disable-next-line no-console
      console.log(response.data.products_aggregate.aggregate.totalCount);
*/




      // const resultList = response.data.marketplace_list;
      const offsetString =
        localStorage.getItem('calculate_ranking_offset') || '0';
      const sizeString =
        localStorage.getItem('calculate_ranking_size') || '100';

        const updateSizeString =
        localStorage.getItem('calculate_ranking_update_size') || '20';



      const offset = offsetString !== '' ? parseFloat(offsetString) : 0;
      const size = sizeString !== '' ? parseFloat(sizeString) : 0;
      const updateSize = updateSizeString !== '' ? parseFloat(updateSizeString) : 0;

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore    
      const products = yield call(getProductListQueryForRanking, {
        client: payload.client,
        size: size,
        offset: offset,
        companyId: 0,
        isAdmin: true,
        filter: {
          company: '',
          form: '',
          product: '',
          visible: true,
        },
      });

      const startAt = Date.now()
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // const totals: any = yield call(getTotalCount, {
      //   client: payload.client,
      //   size: size,
      //   offset,
      //   companyId: 0,
      //   isAdmin: true,
      //   filter: {
      //     company: '',
      //     form: '',
      //     product: '',
      //     visible: true,
      //   },
      // });
      // console.log('totals')
      // console.log(totals)
      let i = 0
      // const count = totals.data.products_v2_aggregate.aggregate.totalCount ? totals.data.products_v2_aggregate.aggregate.totalCount : 0
      const count = products.data.products_aggregate.aggregate.totalCount ? products.data.products_aggregate.aggregate.totalCount : 0
    
      console.log('products')
      console.log(products)
      console.log('count')
      console.log(count)

      

      while (i < count) {
        // const products = await hasuraSDK.FetchProductsForIndex({
        //   limit,
        //   offset: i
        // })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore   
        // const products = yield call(getProductListQueryForRanking, {
        //   client: payload.client,
        //   size: size,
        //   offset: i,
        //   companyId: 0,
        //   isAdmin: true,
        //   filter: {
        //     company: '',
        //     form: '',
        //     product: '',
        //     visible: true,
        //   },
        // });
        // const productList = products.data.products;


          try {
            yield updateProductRankingBulk({
              // productsList: productList.map((itemInitial: any) => {
                productsList: products.data.products.slice(i, i + updateSize).map((itemInitial: any) => {
      
                const item = convertToProduct(itemInitial.Product);
                // console.log(item)
                return {
                  id: item.id,
                  autoRanking: calculateProductRanking(item),
                  manualRanking: item.manualRanking? item.manualRanking : 500,
                  productRankingScore: calculateProductRankingScore(item)
                };
              }),
              client: params.client
            });
          } catch (e) {
            logger.error(`Error while updating products `, e)
            throw e
          }

      
        i += updateSize
  
  
        console.log(`Updated product ${i} products, ${count - i} left, took: ${(Date.now() - startAt) / 1000}`)

        yield (new Promise((resolve)=> {
          setTimeout(()=>{resolve(null)}, 10000)
        } ))
      }



      /*

      // pagination 

      



      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore


      
      const response: any = yield call(getProductListQueryForRanking, {
        client: payload.client,
        size: size,
        offset,
        companyId: 0,
        isAdmin: true,
        filter: {
          company: '',
          form: '',
          product: '',
          visible: true,
        },
      });
      const resultList = response.data.products;

      // eslint-disable-next-line no-console
      console.log('offset');
      // eslint-disable-next-line no-console
      console.log(offset);
        // eslint-disable-next-line no-console
        console.log('size');
        // eslint-disable-next-line no-console
        console.log(size);
      // eslint-disable-next-line no-console
      console.log('totalCount');
      // eslint-disable-next-line no-console
      console.log(response.data.products_aggregate.aggregate.totalCount);

      yield updateProductRankingBulk({
        productsList: resultList.map((itemInitial: any) => {

          const item = convertToProduct(itemInitial.Product);
          // console.log(item)
          return {
            id: item.id,
            autoRanking: calculateProductRanking(item),
            manualRanking: item.manualRanking, // 500
            newRankingCalculationNumber,
          };
        }),
        client: params.client,
        callback: () => {
          console.log('test');
        },
      });

*/

      

    } catch (e) {
      logger.error(
        'src/app/store/modules/admin/sagas.ts -> calculateRankingFlowUpdated',
        e
      );
    }
  }
}

export default function* rootSaga() {
  yield all([fork(calculateRankingFlowUpdated)]);
}
