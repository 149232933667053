import { differenceInSeconds } from 'date-fns';
import { formatDateString } from 'app/utils/format-datestring';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// custom imports
import ArchiveButton from 'app/components/buttons/archive-gray-button';
import MessageButtonAdmin from 'app/components/buttons/message-blue-button';
import MessageButton from 'app/components/buttons/messages-large-button';
import ConditionalContainer from 'app/components/misc/conditional-container';
import ArchiveModal from 'app/components/modals/archive-modal';
import StatusItem from 'app/components/misc/status-component';
import { Td as _Td } from 'app/components/table';
import { generatePath, routes } from 'app/config';
import { useDispatch } from 'app/helpers/redux';
import { useSelector } from 'app/helpers/redux';
import {
  setLinkSellerRequestToDealId,
  setLinkSellerRequestToRequestModalOpen,
} from 'app/store/modules/requests/actions';
import { IRequest } from 'app/store/modules/requests/types';
import { setFlag_brokerChangeId } from 'app/store/modules/flags/actions';
import {
  Capitalize,
  checkIfDealBuyerQualificationInformationExist,
} from 'app/store/modules/global/actions';
import { requestDealTypesDisplayList } from 'app/store/modules/global/types';
import {
  getCountries,
  getProductName,
} from 'app/store/modules/products/actions';
import { Product } from 'app/store/modules/products/types';
import { classNames } from 'app/utils/class-names';
import { sg, sgg } from 'app/utils/safe-get';
import { Bubble } from '../bubble';
import { Flag } from '../flag';
import { Info } from '../info';
import { InitialBubble } from '../initials-bubble';
import { UserOwnedIcon } from '../user-owned';
import { IProps } from './index';
import {
  ActivityButtonContainer,
  ButtonDivider,
  CenterField,
  DateContainer,
  Label,
  LabelContainer,
  MainTr,
  SubLabel,
  Td,
  ViewLinkedDeal,
} from './styles';

const secondsToShowJustSent = 600;

const DealLargeTableItem: React.FC<IProps> = ({
  request,
  isAdmin,
  archiveDealFunc,
  restoreArchiveFunc,
  openDeal,
  iAmBuying,
  iAmSelling,
  companyId,
  openBuyerQualificationAdminView,
}) => {
  const brokerV2 = useSelector((state) =>
    sg(() => state.requests.dealsCache[request!.id]!.Deal!.Broker, null)
  );
  const broker = brokerV2 || sg(() => request!.Broker, null);

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const redirectLink = request
    ? generatePath(
        request.archivedAt ? routes.archivedInfo : routes.requestDetails,
        {
          id: request.id,
        }
      )
    : '';

  const iAmBuyingFinal =
    iAmBuying || (request && request.buyerCompanyId === companyId);
  const iAmSellingFinal =
    iAmSelling || (request && request.sellerCompanyId === companyId);

  const dispatch = useDispatch();

  const productName = (product: Product) => {
    return (
      <LabelContainer>
        <Label>
          <span>
            <ConditionalContainer
              show={isAdmin && !sgg(() => product.adminCreated, false)}
            >
              <UserOwnedIcon className="user-owned" />
            </ConditionalContainer>
          </span>
          {product.__typename === 'Product' ? getProductName(product) : ''}
        </Label>
        <SubLabel>
          {product.__typename === 'Product'
            ? Capitalize(product.formSpecification || '') +
              ' ' +
              product.strength +
              (product.volume ? ' - ' + product.volume : '')
            : ''}
        </SubLabel>
      </LabelContainer>
    );
  };

  const dateItem = (date: Date) => {
    return <DateContainer>{formatDateString(date)}</DateContainer>;
  };

  const origin = sgg<string>(() => request.Product && 'M', '');

  const fieldForAdmin = () => {
    return (
      <MainTr
        color={request && request.color}
        onClick={() => {
          const selection = window.getSelection();
          if (selection && selection.toString().length === 0) {
            openDeal(redirectLink);
          }
        }}
      >
        <Td>
          <div className="flag-container">
            <div>
              <ConditionalContainer
                show={sgg(() => request.adminApproved === null, false)}
              >
                <Flag />
              </ConditionalContainer>
            </div>
            <div>
              <ConditionalContainer show={sg(() => request.showBubble, false)}>
                <Bubble />
              </ConditionalContainer>
            </div>
            <CenterField>{sg(() => request.id, null)}</CenterField>
          </div>
        </Td>
        <Td>{sg(() => dateItem(request.createdAt!), null)}</Td>
        <Td>{sgg(() => productName(request.Product!), '')}</Td>
        <Td>
          <InitialBubble>{origin}</InitialBubble>
        </Td>
        <Td className="buyer-seller-width">
          <ConditionalContainer
            show={
              isAdmin && checkIfDealBuyerQualificationInformationExist(request)
            }
          >
            <div
              className="buyer-info"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (request) {
                  openBuyerQualificationAdminView(request.id);
                }
              }}
              style={{ display: 'inline-block', marginRight: '6px !important' }}
            >
              <Info style={{ display: 'inline' }} />
            </div>
          </ConditionalContainer>
          {request && request.BuyerCompany && request.BuyerCompany.name} <br />
          {request &&
            request.Buyer &&
            request.Buyer.firstName + ' ' + request.Buyer.lastName}{' '}
          {request && request.Buyer && request.Buyer.email}
        </Td>
        <Td className="buyer-seller-width">
          {request && request.SellerCompany && request.SellerCompany.name}{' '}
          <br />
          {request &&
            request.Seller &&
            request.Seller.firstName + ' ' + request.Seller.lastName}{' '}
          {request && request.Seller && request.Seller.email}
        </Td>
        <Td>
          <StatusItem
            status={request && request.status}
            feeStatus={request && request.isPaid}
            showFeeStatus={false}
            showJustSent={
              differenceInSeconds(
                new Date(),
                new Date(request?.dealsGroup?.split('_')?.[0] || '2000-01-01')
              ) < secondsToShowJustSent &&
              iAmBuyingFinal &&
              companyId === sg(() => request.BuyerCompany!.id, 0)
            }
          />
        </Td>
        <Td>
          <InitialBubble
            className={classNames(!sg(() => broker, false) && 'unset')}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (request && !brokerV2) {
                dispatch(setFlag_brokerChangeId(request.id));
              }
            }}
          >
            {sg(
              () =>
                broker!.fullName
                  .split(' ')
                  // eslint-disable-next-line no-magic-numbers
                  .slice(0, 2)
                  .map((v: any) => v.substr(0, 1))
                  .join(''),
              '?'
            )}
          </InitialBubble>
        </Td>
        <Td
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (request) {
              dispatch(setLinkSellerRequestToDealId(request.id));
              dispatch(setLinkSellerRequestToRequestModalOpen(true));
            }
          }}
        >
          {/* {sg(
            () =>
              request &&
              request.Deal && (
                <ViewLinkedDeal>
                  <img src={'img/edit-pen.svg'} />
                </ViewLinkedDeal>
              ),
            <div>
              <ViewLinkedDeal>
                <img src={'img/edit-pen.svg'} />
              </ViewLinkedDeal>
              Not set{' '}
            </div>
          )} */}
          {sg(
            () =>
              request &&
              request.Deal && (
                <ViewLinkedDeal
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  href={'/deal-details/' + request.Deal.id}
                >
                  <img src={'/img/view-item.svg'} />
                </ViewLinkedDeal>
              ),
            ''
          )}
        </Td>
        <Td>
          <ActivityButtonContainer isAdmin={isAdmin}>
            <Link
              onClick={(e) => {
                e.preventDefault();
              }}
              to={redirectLink}
            >
              {isAdmin ? (
                <MessageButtonAdmin />
              ) : (
                <MessageButton label={'Messages'} shortLabel={''} />
              )}
            </Link>
            <ButtonDivider />
            <ArchiveButton
              onClick={(e) => {
                e.stopPropagation();
                setArchiveModalOpen(true);
              }}
            />
          </ActivityButtonContainer>
        </Td>
      </MainTr>
    );
  };

  const getDealType = (d: IRequest) => {
    if (!d) {
      return '-';
    }

    if (d.Product) {
      const s = sg(
        () =>
          requestDealTypesDisplayList.find((v) => v.id === d.dealType)!.name,
        '-'
      );
      return s;
    }
    return '-';
  };

  const fieldForOrdinaryUser = () => {
    const marketOfInterest = `${sg(
      () => getCountries(request.market!.split(',')),
      ''
    )}`;
    return (
      <MainTr
        color={request && request.color}
        onClick={() => {
          const selection = window.getSelection();
          if (selection && selection.toString().length === 0) {
            openDeal(redirectLink);
          }
        }}
      >
        <Td>{sgg(() => productName(request.Product!), '')}</Td>
        <Td>
          <StatusItem
            status={request && request.status}
            feeStatus={request && request.isPaid}
            showFeeStatus={false}
            showJustSent={
              differenceInSeconds(
                new Date(),
                new Date(request?.dealsGroup?.split('_')?.[0] || '2000-01-01')
              ) < secondsToShowJustSent &&
              iAmBuyingFinal &&
              companyId === sg(() => request.BuyerCompany!.id, 0)
            }
          />
        </Td>
        <Td>
          {iAmBuyingFinal && sg(() => dateItem(request.buyerUpdatedAt), '')}
          {iAmSellingFinal &&
            !iAmBuyingFinal &&
            sg(() => dateItem(request.sellerUpdatedAt), '')}
        </Td>
        <Td>{marketOfInterest === '' ? '-' : marketOfInterest}</Td>
        <Td>{getDealType(request)}</Td>
        <Td>
          <ActivityButtonContainer isAdmin={isAdmin}>
            <Link
              onClick={(e) => {
                e.preventDefault();
              }}
              to={
                request
                  ? request.archivedAt === undefined ||
                    request.archivedAt === null
                    ? '/request-details/' + (request ? request.id : '')
                    : '/archive-info/' + (request ? request.id : '')
                  : '/'
              }
            >
              {isAdmin ? (
                <MessageButtonAdmin />
              ) : (
                <MessageButton label={'Messages'} shortLabel={''} />
              )}
            </Link>
          </ActivityButtonContainer>
        </Td>
      </MainTr>
    );
  };

  return (
    <>
      <ConditionalContainer show={isAdmin}>
        {fieldForAdmin()}
      </ConditionalContainer>
      <ConditionalContainer show={!isAdmin}>
        {fieldForOrdinaryUser()}
      </ConditionalContainer>
      <ConditionalContainer show={archiveModalOpen}>
        <ArchiveModal
          archiveCallback={() => {
            request && request.archivedAt
              ? restoreArchiveFunc(request.id)
              : archiveDealFunc(request.id, true);
            setArchiveModalOpen(false);
          }}
          isOpen={archiveModalOpen}
          closeModal={() => setArchiveModalOpen(false)}
          isRestoring={request && request.archivedAt ? true : false}
        />
      </ConditionalContainer>
    </>
  );
};

export default DealLargeTableItem;
