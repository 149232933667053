import request from 'app/utils/request';
const BASE_URL = process.env.V2_API_URL;

export const login = async (username: string, password: string) => {
  try {
    const response = await request(`${BASE_URL}/auth/login`, {
      credentials: 'include',
    })
      .post({ username, password })
      .json();
    return response;
  } catch (err) {
    return JSON.parse(err.toString().replace('Error: ', ''));
  }
};

export const logout = () =>
  request(`${BASE_URL}/auth/logout`, {
    credentials: 'include',
  })
    .post()
    .json();

export const checkAuth = async () => {
  try {
    const response = await request(`${BASE_URL}/auth/me`, {
      credentials: 'include',
    })
      .get()
      .json();
    return response;
  } catch (e) {
    return e;
  }
};

export const getStarted = async (user: any) => {
  try {
    const response = await request(`${BASE_URL}/auth/get-started`, {
      credentials: 'include',
    })
      .post({ user })
      .json();
    return response;
  } catch (err) {
    return JSON.parse(err.text.toString().replace('Error: ', ''));
  }
};

export const createAccount = async (user: any) => {
  try {
    const response = await request(`${BASE_URL}/auth/create-account`, {
      credentials: 'include',
    })
      .post({ user })
      .json();
    return response;
  } catch (err) {
    return JSON.parse(err.text.toString().replace('Error: ', ''));
  }
};

export const register = async (user: any) => {
  try {
    const response = await request(`${BASE_URL}/auth/register`, {
      credentials: 'include',
    })
      .post({ user })
      .json();
    return response;
  } catch (err) {
    return JSON.parse(err.text.toString().replace('Error: ', ''));
  }
};

export const updateUserSettingsAPI = async (user: any) => {
  try {
    const response = await request(`${BASE_URL}/auth/update-password`, {
      credentials: 'include',
    })
      .post({ user })
      .json();
    return response;
  } catch (err) {
    return JSON.parse(err.toString().replace('Error: ', ''));
  }
};

export const resetPassword = async (email: string) => {
  try {
    const response = await request(`${BASE_URL}/auth/forgot-password`, {
      credentials: 'include',
      headers: {
        'content-locale': 'en',
      },
    })
      .post({ email })
      .json();
    return response;
  } catch (err) {
    return JSON.parse(err.toString().replace('Error: ', ''));
  }
};

export const verifyEmail = async (token: string) => {
  try {
    const response = await request(`${BASE_URL}/auth/email-verify/${token}`, {
      credentials: 'include',
      headers: {
        'content-locale': 'en',
      },
    })
      .get()
      .json();
    return response;
  } catch (err) {
    return JSON.parse(err.toString().replace('Error: ', ''));
  }
};

export const getMessageToken = async (requestId: number | string) => {
  try {
    const response = await request(`${BASE_URL}/message/token/${requestId}`, {
      credentials: 'include',
      headers: {
        'content-locale': 'en',
      },
    })
      .get()
      .text();
    return response;
  } catch (err) {
    return '';
  }
};

export const resetPasswordSubmit = async (
  password: string,
  recoveryToken: string
) => {
  try {
    const response = await request(`${BASE_URL}/auth/resetpassword`, {
      credentials: 'include',
      headers: {
        'content-locale': 'en',
      },
    })
      .post({ password, verificationToken: recoveryToken })
      .json();
    return response;
  } catch (err) {
    try {
      const res = JSON.parse(err.toString().replace('Error: ', ''));
      return res;
    } catch (e) {
      return {
        errors:
          'There was an error durring password reset, plese try again later',
      };
    }
  }
};
