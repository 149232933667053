import {
  allCountries,
  CompanySelect,
  IPaginateable,
  ISelectItemWithCategory,
} from 'app/store/modules/global/types';
import { Dictionary } from '../../../utils/dictionary';
export interface State extends IPaginateable {
  productsCache: Dictionary<Product>;
  productIds: AnyListItem[];
  onboardingIds: AnyListItem[];
  visibleProductIds: AnyListItem[];
  deleteIds: AnyListItem[];
  filter: ProductFilter;
  editModalOpen: boolean;
  editTradeModalOpen: boolean;
  editProductId: number;
  error: Notifications;
  status: Status;
  hasFetched: boolean;
  onboardingFilter?: OnBoardingFilter;
  productsFilter?: OnBoardingFilter;
  addProductToggle: boolean;
  suggestAlternativeSellersModalOpen: boolean;
}

export enum Status {
  idle,
  loading,
}

export interface Notifications {
  listError: string;
  listSuccess: string;
}

export const defaultPageSize = 10;

export interface IProductLink {
  id?: number;
  Substance1: SubstanceTable;
  Substance2?: SubstanceTable;
  Substance3?: SubstanceTable;
  formSpecification: string;
  strength: string;
}

export interface ProductFilter {
  product: string;
  company: string;
  form: string;
  lastUpdateFrom?: Date;
  lastUpdateTo?: Date;
  visible: boolean;
  owned?: boolean;
  competitive?: boolean;
  marketOfInterests?: string;
}

export interface ProductTable {
  id: number;
  substance_1: number;
  substance_2: number;
  substance_3: number;
  Substance1: SubstanceTable;
  Substance2?: SubstanceTable;
  Substance3?: SubstanceTable;
  form_specification: string;
  strength: string;
  dossier_status: number;
  dossier_completion_quarter: string;
  dossier_completion_year: string;
  Company: {
    id: number;
    name: string;
    country: string;
    createdBy: number;
  };
  product_available_licensing_supply: boolean;
  product_available_supply_distribution: boolean;
  product_available_technology_transfer: boolean;
  product_available_special_import: boolean;
  dossier_format: number;
  gmp_approval_for_this_product_string: string;
  not_available_markets: string;
  registered_in: string;
  marketing_authorizations_achieved_country: string;
  stability_data_zones_string: string;
  comments_on_the_patent_status: string;
  marketing_message: string;
  message: string;
  cpp: string;
  finished_dose_country: string;
  finished_dose_country_eu: boolean;
  status: number;
  product_tags: string;
  product_owner_id: number;
  createdAt: Date;
  updatedAt: Date;
  product_ranking_auto_score: number;
  product_ranking_manual_score: number;
  product_ranking_score: number;
  comments: string;
  clinical_data?: string;
  admin_created: boolean;
  admin_comments?: string;
  fast_product?: boolean;
  competitive_product?: boolean;
  product_category?: string;
}

export interface Product extends Typed<'Product'> {
  id: number;
  substance1: number;
  substance2?: number;
  substance3?: number;
  Substance1: Substance;
  Substance2?: Substance;
  Substance3?: Substance;
  formSpecification: string;
  doseFormCategory: string;
  strength: string;
  dosierStatus?: number;
  dosierCompletionQuarter: string;
  dosierCompletionYear: string;
  productAvailableLicensingSupply: boolean;
  productAvailableSuplyDistribution: boolean;
  productAvailableTechnologyTransfer: boolean;
  productAvailableSpecialImport: boolean;
  dosierType: number;
  gmpApprovalForThisProduct: string[];
  notAvailableMarkets: string[];
  registeredIn: string[];
  marketingAuthorizationsAchievedCountry: string[];
  stabilityDataZones: string[];
  cppAvailableFrom: string[];
  commentsOnThePatentStatus: string;
  shelfLife: string;
  batchSize: string;
  moq: string;
  marketingMessage: string;
  finishedDoseCountry: string;
  finishedDoseCountryEu: boolean;
  status: number;
  // productTags: string[];
  productTags: string;
  productOwnerId: number;

  comments: string;
  Company: {
    id: number;
    name: string;
    country: string;
    createdBy: number;
    hidden: boolean;
  };
  companyId?: number;
  createdAt: Date;
  updatedAt: Date;
  createdBy: number;
  autoRanking: number;
  manualRanking: number;
  productRankingScore: number;
  CompanySelect?: CompanySelect;
  clinicalData: string;
  adminCreated: boolean;
  adminComments: string;
  fastProduct: boolean;
  competitiveProduct: boolean;
  packSize: string;
  volume: string;
  productCategory: string;
}

export interface ProductForm {
  id: number;
  substance1: number;
  substance1Error: string;
  substance1Select: ISelectItemWithCategory;
  substance2: number;
  substance2Select: ISelectItemWithCategory;
  substance3: number;
  substance3Select: ISelectItemWithCategory;
  formSpecification: string;
  formSpecificationSelect?: ISelectItem;
  doseFormCategory: string;
  formSpecificationError: string;
  strength: string;
  strengthError: string;
  dosierStatus?: number;
  dosierStatusError: string;
  dosierStatusSelect: ISelectItem;
  dosierCompletionQuarter: string;
  dosierCompletionYear: string;
  productAvailableLicensingSupply: boolean;
  productAvailableSuplyDistribution: boolean;
  productAvailableTechnologyTransfer: boolean;
  productAvailableSpecialImport: boolean;
  dosierType: number;
  dosierTypeError: string;
  dosierTypeSelect: ISelectItem;
  gmpApprovalForThisProduct: string[];
  gmpApprovalForThisProductError: string;
  gmpApprovalForThisProductSelect: ISelectItem[];
  notAvailableMarkets: string[];
  notAvailableMarketsSelect: ISelectItem[];
  registeredIn: string[];
  registeredInSelect: ISelectItem[];
  marketingAuthorizationsAchievedCountry: string[];
  marketingAuthorizationsAchievedCountrySelect: ISelectItem[];
  stabilityDataZones: string[];
  cppAvailableFrom: string[];
  cppAvailableFromSelect: ISelectItem[];
  commentsOnThePatentStatus: string;
  shelfLife: string;
  batchSize: string;
  moq: string;
  marketingMessage: string;
  finishedDoseCountry: string;
  finishedDoseCountryEu: boolean;
  finishedDoseCountrySelect?: ISelectItem;
  finishedDoseCountryError: string;
  status: number;
  productTags: string;
  productTagsError: string;
  // productTagsSelect: ISelectItem[];
  comments: string;
  autoRanking: string;
  autoRankingError: string;
  manualRanking: string;
  manualRankingError: string;
  productRankingScore: string;
  companyId: string;
  CompanySelect?: CompanySelect;
  isModal: boolean;
  requireFetchProduct: boolean;
  clinicalData: string;
  adminCreated: boolean;
  Company?: {
    id: number;
    name: string;
    country: string;
    createdBy: number;
  };
  createdBy: number;
  adminComments: string;
  fastProduct: boolean;
  competitiveProduct: boolean;
  doseFormList: ISelectItemWithCategory[];
  packSize: string;
  packSizeError: string;
  volume: string;
  volumeError: string;
  productCategory: string;
}

export enum ProductStatusEnum {
  waiting,
  approved,
  promotion,
}

export interface SubstanceTable {
  name: string;
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  therapy_area?: number;
  imported_at?: Date;
  import_source_id?: string;
  import_id?: string;
}

export interface Substance {
  name: string;
  brandName?: string;
  id: number;
  createdAt?: Date;
  updatedAt?: Date;
  type?: string;
  category?: number;
  therapyArea?: number;
  importedAt?: Date;
  importSourceId?: string;
  importId?: string;
  searchHitCount?: number;
}

export interface AnyListItem {
  ranking: number;
  updatedAt: Date;
  productId?: number;
  sellerUserId?: number;
  sellerCompanyId?: number;
}

export const dosierFormat: any[] = [
  { id: 2, name: 'CTD', type: ['fdf', 'ma'] },
  { id: 4, name: 'EU CTD', type: ['fdf', 'ma'] },
  { id: 6, name: 'US CTD', type: ['fdf', 'ma'] }, // previously was ANDA
  { id: 8, name: 'ACTD', type: ['ma'] },
  // { id: 7, name: 'ANVISA', type: ['fdf', 'ma'] },
  // { id: 9, name: 'Upon request', for_sale: true, type: ['fdf', 'ma'] },
  // { id: 50, name: 'USDMF', type: 'api' },
  // { id: 51, name: 'CANADIAN DMF', type: 'api' },
  // { id: 52, name: 'EDMF / ASMF', type: 'api' },
  // { id: 53, name: 'JAPANESE DMF', type: 'api' },
  // { id: 99, name: 'Others', type: ['ma'] },
];

export const dossierStatuses: {
  id: number;
  name: string;
  type?: any;
  for_sale?: boolean;
}[] = [
  { id: 1, name: 'ready to file' },
  { id: 2, name: 'under development' },
  { id: 3, name: 'outdated' },
];

export enum ProductCategoryEnum {
  GENERICS = 'Generics',
  BIOSIMILARS = 'Biosimilars',
  NUTRACEUTICALS = 'Nutraceuticals',
  MEDICAL_DEVICES = 'Medical devices',
}

export const gpmApprovals: any[] = [
  {
    id: 'FDA',
    name: 'FDA',
  },
  {
    id: 'EU GMP',
    name: 'EU GMP',
  },
  {
    id: 'ANVISA',
    name: 'ANVISA',
  },
  {
    id: 'COFEPRIS',
    name: 'COFEPRIS',
  },
  {
    id: 'TGA',
    name: 'TGA',
  },
  {
    id: 'PMDA',
    name: 'PMDA',
  },
  {
    id: 'WHO',
    name: 'WHO',
  },
  {
    id: 'PICS',
    name: 'PICS',
  },
  {
    id: 'INVIMA',
    name: 'INVIMA',
  },
  ...allCountries,
];

export const manufacturerApprovals: any[] = [
  { id: 1, name: 'EMA/MHRA (European Union)' },
  { id: 2, name: 'FDA (United States)' },
  { id: 3, name: 'PMDA (Japan)' },
  { id: 4, name: 'ANVISA (Brazil)' },
  { id: 5, name: 'ANMAT (Argentina)' },
  { id: 6, name: 'INVIMA (Colombia)' },
  { id: 7, name: 'GCC (Gulf Cooperation Council)' },
  { id: 8, name: 'TGA (Australia)' },
  { id: 9, name: 'KFDA (Korea)' },
  { id: 10, name: 'BFDA (Taiwan)' },
  { id: 11, name: 'CFDA (China)' },
  { id: 12, name: 'WHO' },
  { id: 99, name: 'Others' },
];

export const stabilityZones: any[] = ['I', 'II', 'III', 'IVa', 'IVb'];
export interface IDealType {
  id: number;
  name: string;
}
export enum DealType {
  LicensingWithSupply = 1,
  SupplyDitribution,
  TechnologyTransfer,
  SpecialImport,
  ParallelImport,
  ChangeOfContractManufacturing,
}

export const dealTypeList: IDealType[] = [
  { id: DealType.LicensingWithSupply, name: 'Licensing + supply' },
  { id: DealType.SupplyDitribution, name: 'Supply (distribution)' },
  { id: DealType.TechnologyTransfer, name: 'Technology transfer' },
];
export const dealTypeDictionary: Dictionary<string> = {
  [DealType.LicensingWithSupply]: 'Licensing + supply',
  [DealType.SupplyDitribution]: 'Supply (distribution)',
  [DealType.TechnologyTransfer]: 'Technology transfer',
  [DealType.SpecialImport]: 'Special Import',
  [DealType.ParallelImport]: 'Parallel import',
  [DealType.ChangeOfContractManufacturing]: 'Change of Contract Manufacturing',
};

export const productTypeTagList: { id: number; name: string }[] = [
  { id: 1, name: 'Original' },
  { id: 2, name: 'Biological' },
  { id: 3, name: 'Generic' },
  { id: 4, name: 'Differentiated generic' },
  { id: 5, name: 'OTC' },
  { id: 6, name: 'Medical device' },
  { id: 7, name: 'Medical food' },
  { id: 8, name: 'Food supplement' },
];

export const defaultStore: State = {
  productsCache: {},
  productIds: [],
  onboardingIds: [],
  visibleProductIds: [],
  deleteIds: [],
  totalCount: 0,
  currentPage: 0,
  pageSize: 0,
  filter: {
    product: '',
    company: '',
    form: '',
    lastUpdateFrom: undefined,
    lastUpdateTo: undefined,
    visible: false,
  },
  editModalOpen: false,
  editTradeModalOpen: false,
  editProductId: 0,
  error: { listSuccess: '', listError: '' },
  status: Status.idle,
  hasFetched: false,
  addProductToggle: false,
  suggestAlternativeSellersModalOpen: false,
};

export type OnBoardingFilter = 'all' | 'manuf' | 'trade';
export enum OnBoardingFilterEnum {
  'all' = 'all',
  'manuf' = 'manuf',
  'trade' = 'trade',
}
