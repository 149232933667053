import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import {
  companyDirectoryFragment,
  ICompanyDirectoryFragment,
} from '../fragments/ma-company';

export interface IGetCompanyDirectoryCountryListReturn {
  companydirectory: ICompanyDirectoryFragment[];
}

export async function getCompanyDirectoryCountryQuery(p: IWithApolloClient) {
  try {
    return await p.client.query({
      query: gql`
      ${companyDirectoryFragment.fragment()}
      query getCompanyDirectory {
        companydirectory: company_directory_imported (${u.distinct(
          'country'
        )}) {
        country
        }
      }
    `,
      fetchPolicy: 'no-cache',
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> getCompanyDirectoryByCountryQuery',
      e
    );
  }
}
