import AsyncSelect, {
  Components as AsyncC,
} from 'app/components/inputs/async-select';
import Datepicker, {
  Components as DatepickerC,
} from 'app/components/inputs/datepicker';
import Select, { Components as SelectC } from 'app/components/inputs/select';
import TextInput, {
  Components as TextInputC,
} from 'app/components/inputs/text-input';
import {
  DealsFilter,
  DealStatusDictionary,
} from 'app/store/modules/requests/types';
import { media } from 'app/styles/breakpoint';
import { addDays, subMilliseconds } from 'date-fns';
import { formatDateString } from 'app/utils/format-datestring';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IProps } from './index';

const FilterContainer = styled.div`
  width: 100%;
  box-shadow: ${(props) =>
    props.theme.colors.boxShadowParam +
    ' ' +
    props.theme.colors.filtersButtonShadow};
  background-color: ${(props) => props.theme.colors.white};
  display: grid;
  grid-template-columns: repeat(auto-fill, 92px);
  grid-column-gap: 15px;
  grid-row-gap: 18px;
  margin-bottom: 7px;
  padding: 14px 19px 21px 19px;
  ${media.max.sm`
    display: none;
  `}
`;

const Explanation = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  width: 100%;
  height: 100%;
  padding: 11px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  color: ${(props) => props.theme.colors.grayText};
  font-size: 11px;
  .flag-block {
    margin-right: 28px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .flag-item {
      display: flex;
      align-items: center;
      .flag-icon {
        width: 11px;
        height: 12.5px;
        object-fit: contain;
        margin-right: 6px;
      }
      .user-message-icon {
        width: 11px;
        height: 11px;
        object-fit: contain;
        margin-right: 6px;
      }
      .recommend-selller-icon {
        width: 9px;
        height: 7px;
        object-fit: contain;
        margin-right: 6px;
      }
    }
  }
`;

const FilterContainerItem = styled.div`
  &.double,
  .double {
    grid-column: span 2;
  }

  &.fill-all {
    grid-column: span 6;
  }
  &.date-grid {
    display: grid;
    grid-template-columns: 92px 92px;
    grid-column-gap: 12px;
    margin-bottom: auto;
  }

  .override {
    ${TextInputC.InputContainer},
    ${SelectC.InputContainer},
    ${TextInputC.InputStyle},
    ${AsyncC.InputContainer} {
      margin-top: 0;
    }
    ${SelectC.InputContainer} [class$="menu"],
    ${AsyncC.InputContainer} [class$="menu"] {
      z-index: 2;
    }
    ${DatepickerC.IconContainer} {
      top: 0;
    }
    ${DatepickerC.TextContainer} {
      min-width: unset;
    }
  }
  .override.no-label {
    ${TextInputC.LabelStyle} {
      display: none;
    }
  }
`;

const defaultPageSizeForBroker = 50;

const DealsFilterComponent: React.FC<IProps> = ({
  filter,
  setFilter,
  loggedInUser,
  setPageSize,
  ...props
}) => {
  const { isAdmin, isDefaultBroker, fullName } = loggedInUser;
  const [filterValue, setFilterValue] = useState<DealsFilter>(filter);
  useEffect(() => {
    if (isDefaultBroker && filter.broker === undefined) {
      setPageSize(defaultPageSizeForBroker);
      setFilter({
        ...filter,
        broker: {
          value: loggedInUser.id.toString(),
          label: fullName,
        },
      });
      setFilterValue({
        ...filter,
        broker: {
          value: loggedInUser.id.toString(),
          label: fullName,
        },
      });
    }
  }, []);

  if (!isAdmin) {
    return null;
  }

  return (
    <FilterContainer>
      <FilterContainerItem className="double">
        <TextInput
          label={'Product'}
          onChange={(v) => {
            const s = { ...filterValue, product: v };
            setFilterValue(s);
            setFilter(s);
          }}
          value={filterValue.product}
          inputMarginTop={'0px'}
          marginTop={'0px'}
        />
      </FilterContainerItem>
      <FilterContainerItem className="double">
        <TextInput
          value={filterValue.request}
          label={'Request ID'}
          onChange={(text: string) => {
            setFilterValue({ ...filterValue, request: text });
            setFilter({ ...filterValue, request: text });
          }}
          inputMarginTop={'0px'}
          marginTop={'0px'}
        />
      </FilterContainerItem>
      <FilterContainerItem className="double">
        <div className="override">
          <Select<number | undefined>
            label={'Status'}
            optionList={[
              {
                value: undefined,
                label: 'All',
              },
              ...Object.keys(DealStatusDictionary).map(
                (key): ISelectItem<number> => ({
                  value: parseFloat(key),
                  label: DealStatusDictionary[key],
                })
              ),
            ]}
            onChange={(val) => {
              setFilterValue({
                ...filterValue,
                status: val.value,
              });
              setFilter({ ...filterValue, status: val.value });
            }}
            value={
              filterValue.status === undefined
                ? undefined
                : {
                    value: filterValue.status,
                    label: DealStatusDictionary[filterValue.status],
                  }
            }
            zIndex={'unset'}
          />
        </div>
      </FilterContainerItem>
      <FilterContainerItem className="double">
        <TextInput
          value={filterValue.company}
          label={'Company'}
          onChange={(text: string) => {
            setFilterValue({ ...filterValue, company: text });
            setFilter({ ...filterValue, company: text });
          }}
          inputMarginTop={'0px'}
          marginTop={'0px'}
        />
      </FilterContainerItem>
      <FilterContainerItem className="double">
        <TextInput
          value={filterValue.email}
          label={'User e-mail'}
          onChange={(text: string) => {
            setFilterValue({ ...filterValue, email: text });
            setFilter({ ...filterValue, email: text });
          }}
          inputMarginTop={'0px'}
          marginTop={'0px'}
        />
      </FilterContainerItem>
      <FilterContainerItem className="date-grid double">
        <TextInputC.LabelStyle className="double">
          Date created
        </TextInputC.LabelStyle>
        <div className="override no-label">
          <Datepicker
            topMost={true}
            setSelectedDate={(str, date) => {
              setFilterValue({ ...filterValue, createdFrom: date });
              setFilter({ ...filterValue, createdFrom: date });
            }}
            selectedDate={filterValue.createdFrom}
            selectedDateString={
              filterValue.createdFrom
                ? formatDateString(filterValue.createdFrom)
                : ''
            }
            label="From"
            placeholder="From"
          />
        </div>
        <div className="override no-label">
          <Datepicker
            topMost={true}
            setSelectedDate={(str, date) => {
              const newDate = date
                ? subMilliseconds(addDays(new Date(date), 1), 1)
                : date;
              setFilterValue({ ...filterValue, createdTo: newDate });
              setFilter({ ...filterValue, createdTo: newDate });
            }}
            selectedDate={filterValue.createdTo}
            selectedDateString={
              filterValue.createdTo
                ? formatDateString(filterValue.createdTo)
                : ''
            }
            label="To"
            placeholder="To"
          />
        </div>
      </FilterContainerItem>
      <FilterContainerItem className="double">
        <div className="override">
          <AsyncSelect
            label={'Broker'}
            loadOptions={props.fetchBrokers}
            onChange={(val) => {
              setFilterValue({ ...filterValue, broker: val });
              setFilter({ ...filterValue, broker: val });
            }}
            value={filterValue.broker}
            zIndex={'unset'}
            placeholder={'Start typing for suggestions'}
          />
        </div>
      </FilterContainerItem>
      <FilterContainerItem className="fill-all">
        <Explanation>
          <div className={'flag-block'}>
            <div className="flag-item ">
              <img
                src={'/img/deal-not-assigned-to-seller.svg'}
                className="flag-icon"
              />{' '}
              - Request is not assigned to the seller
            </div>
            <div className="flag-item ">
              <img
                src={'/img/new-message-box.svg'}
                className="user-message-icon"
              />{' '}
              - There are unprocessed messages
            </div>
          </div>
          <div className={'flag-block'}>
            <div className="flag-item ">
              <img
                src={'/img/user-owned-product.svg'}
                className="user-message-icon"
              />{' '}
              - User owned
            </div>
            <div className="flag-item ">
              <img
                src={'/img/seller-recomendation-check.svg'}
                className="recommend-selller-icon"
              />{' '}
              - Buyer wants recommendations of alternatives
            </div>
          </div>
        </Explanation>
      </FilterContainerItem>
    </FilterContainer>
  );
};

export default DealsFilterComponent;
