import * as saga from '@redux-saga/core/effects';
import { IStore } from 'app/store/types';
import { isBrowser } from 'app/store/utils/is-browser';
import { logger } from 'app/utils/logger';
import { sg } from 'app/utils/safe-get';
import { setDealsCache } from '../actions';
import * as A from './actions';
import { convertToIMessage } from './fragments/deal-message';
import * as Q from './query';
import { IDealMessage } from './types';

export function groupMessages(messages: IDealMessage[]) {
  const grouped: Dictionary<IDealMessage> = {};
  for (const msg of messages) {
    const grp = msg.group;
    if (grouped[grp] === undefined) {
      grouped[grp] = msg;
      continue;
    }

    const change = { ...(grouped[grp].fieldChange || undefined) };
    const ch = sg(() => msg.fieldChange![msg.id], undefined);
    if (ch) {
      change[msg.id] = ch;
    }
    grouped[grp] = {
      ...grouped[grp],
      fieldChange: change,
    };
  }
  return grouped;
}
export function* fetchMessagesFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload,
      }: ReturnType<typeof A.fetchMessagesAction> = yield saga.take(
        A.fetchMessagesAction
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth, requests }: IStore = yield saga.select();
      const loggedInUser = auth.loggedInUser ? auth.loggedInUser : undefined;
      const isAdmin = loggedInUser ? loggedInUser.isAdmin : false;
      const userCompany =
        loggedInUser && loggedInUser.Company ? loggedInUser.Company.id : 0;

      const party = isAdmin
        ? 'admin'
        : requests.dealsCache[payload.requestId] &&
          userCompany === requests.dealsCache[payload.requestId].buyerCompanyId
        ? 'buyer'
        : requests.dealsCache[payload.requestId] &&
          userCompany === requests.dealsCache[payload.requestId].sellerCompanyId
        ? 'seller'
        : undefined;

      if (!party) {
        continue;
      }
      yield saga.put(A.fetchMessagesStatus(true));
      const res: Unpromisefy<
        ReturnType<typeof Q.getMessages>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield saga.call(fetchMessagesSaga, {
        ...payload,
        party,
      });
      if (!res) {
        throw new Error('GraphQL returned undefined');
      }

      const messages = res.data.messages;
      const messagesCount = res.data.agr.a.count;
      const {
        requests: { dealsCache },
      }: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      IStore = yield saga.select();
      const msgs = groupMessages(messages.map(convertToIMessage));

      yield saga.put(
        setDealsCache({
          ...dealsCache,
          [payload.requestId]: {
            ...dealsCache[payload.requestId],
            messages: {
              ...dealsCache[payload.requestId].messages,
              totalCount: messagesCount,
              messages: {
                ...dealsCache[payload.requestId].messages.messages,
                ...msgs,
              },
            },
          },
        })
      );
      yield saga.put(A.fetchMessagesStatus(false));
      yield saga.put(A.fetchMessagesDone(true));
    } catch (e) {
      logger.error(
        'src/app/store/modules/requests/messages/sagas.ts -> fetchMessagesFlow',
        e
      );
    }
  }
}
function* fetchMessagesSaga(p: Params<typeof Q.getMessages>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return yield saga.call(Q.getMessages, p);
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/sagas.ts -> fetchMessagesSaga',
      e
    );
  }
}

export function* sendMessageFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload,
      }: ReturnType<typeof A.sendMessageAction> = yield saga.take(
        A.sendMessageAction
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth, requests }: IStore = yield saga.select();

      const buyerSee =
        payload.for !== 'adminNote' &&
        (payload.for === 'buyer' ||
          payload.for === 'both' ||
          payload.for === 'quotation');

      const sellerSee =
        payload.for !== 'adminNote' &&
        (payload.for === 'seller' ||
          payload.for === 'both' ||
          payload.for === 'quotation');

      const isAdmin = sg(() => auth.loggedInUser.isAdmin, false);

      const params: Params<typeof sendMessageSaga> = {
        client: payload.client,
        requestId: payload.requestId,
        text: payload.text,
        senderId: auth.loggedInUser ? auth.loggedInUser.id : 0,
        companyId:
          auth.loggedInUser && auth.loggedInUser.Company
            ? auth.loggedInUser.Company.id
            : 0,
        fromAdmin: auth.loggedInUser.isAdmin,
        termsheetId: payload.termsheetId,
        buyerSee,
        sellerSee,
        isAdmin,
      };

      yield saga.put(A.sendMessageStatus(true));
      const res: Unpromisefy<
        ReturnType<typeof Q.sendMessage>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield saga.call(sendMessageSaga, params);
      if (!res) {
        throw new Error('GraphQL returned undefined');
      }

      const request = requests.dealsCache[payload.requestId];
      const message = sg(
        () => convertToIMessage(res.data!.message.r[0]),
        {} as IDealMessage
      );
      yield saga.put(
        setDealsCache({
          ...requests.dealsCache,
          [payload.requestId]: {
            ...request,
            messages: {
              totalCount: request.messages.totalCount + 1,
              messages: {
                ...request.messages.messages,
                [message.group]: message,
              },
            },
          },
        })
      );
      yield saga.put(A.sendMessageStatus(false));
    } catch (e) {
      logger.error(
        'src/app/store/modules/requests/messages/sagas.ts -> sendMessageFlow',
        e
      );
    }
  }
}
function* sendMessageSaga(params: Params<typeof Q.sendMessage>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return yield saga.call(Q.sendMessage, params);
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/sagas.ts -> sendMessageSaga',
      e
    );
  }
}

export function* acceptMessageFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const { payload }: ReturnType<typeof A.acceptMessage> = yield saga.take(
        A.acceptMessage
      );

      const res: Unpromisefy<
        ReturnType<typeof Q.acceptMessage>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield saga.call(Q.acceptMessage, payload);
      if (!res) {
        throw new Error('GraphQL returned undefined');
      }

      const requestId = res.data!.message.returning[0].requestId;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { requests }: IStore = yield saga.select();
      yield saga.put(
        setDealsCache({
          ...requests.dealsCache,
          [requestId]: {
            ...requests.dealsCache[requestId],
            messages: {
              ...requests.dealsCache[requestId].messages,
              messages: {
                ...requests.dealsCache[requestId].messages.messages,
                [payload.id]: {
                  ...requests.dealsCache[requestId].messages.messages[
                    payload.id
                  ],
                  status: 'accepted',
                },
              },
            },
          },
        })
      );
    } catch (e) {
      logger.error(
        'src/app/store/modules/requests/messages/sagas.ts -> sendMessageSaga',
        e
      );
    }
  }
}

export function* rejectMessageFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const { payload }: ReturnType<typeof A.rejectMessage> = yield saga.take(
        A.rejectMessage
      );

      const res: Unpromisefy<
        ReturnType<typeof Q.rejectMessage>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield saga.call(Q.rejectMessage, payload);
      if (!res) {
        throw new Error('GraphQL returned undefined');
      }
      const {
        requestId,
        rejectText: rejectReply,
      } = res.data!.message.returning[0];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { requests }: IStore = yield saga.select();
      yield saga.put(
        setDealsCache({
          ...requests.dealsCache,
          [requestId]: {
            ...requests.dealsCache[requestId],
            messages: {
              ...requests.dealsCache[requestId].messages,
              messages: {
                ...requests.dealsCache[requestId].messages.messages,
                [payload.id]: {
                  ...requests.dealsCache[requestId].messages.messages[
                    payload.id
                  ],
                  status: 'rejected',
                  rejectReply,
                },
              },
            },
          },
        })
      );
    } catch (e) {
      logger.error(
        'src/app/store/modules/requests/messages/sagas.ts -> sendMessageSaga',
        e
      );
    }
  }
}

export function* removeChangeFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload: { request: requestId, ...params },
      }: ReturnType<typeof A.removeChange> = yield saga.take(A.removeChange);

      const delret: Unpromisefy<
        ReturnType<typeof Q.removeChangeQuery>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield saga.call(removeChangeSaga, params);
      if (!delret) {
        throw new Error('GraphQL removeChangeQuery returned undefined');
      }

      const fetchret: Unpromisefy<
        ReturnType<typeof Q.fetchGroup>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield saga.call(fetchGroupSaga, {
        client: params.client,
        group: delret.data!.delete_deals_messages.returning[0].group,
      } as Params<typeof fetchGroupSaga>);
      if (!fetchret) {
        throw new Error('GraphQL fetchGroup returned undefined');
      }

      const {
        requests: { dealsCache },
      }: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      IStore = yield saga.select();
      const { messages } = dealsCache[requestId].messages;

      yield saga.put(
        setDealsCache({
          ...dealsCache,
          [requestId]: {
            ...dealsCache[requestId],
            messages: {
              ...dealsCache[requestId].messages,
              messages: {
                ...messages,
                ...groupMessages(fetchret.data.messages.map(convertToIMessage)),
              },
            },
          },
        })
      );
    } catch (e) {
      logger.error(
        'src/app/store/modules/requests/messages/sagas.ts -> removeChangeFlow',
        e
      );
    }
  }
}
async function removeChangeSaga(params: Q.IRemoveChange) {
  try {
    return await Q.removeChangeQuery(params);
  } catch (e) {
    logger.error(e);
  }
}
async function fetchGroupSaga(params: Q.IFetchGroup) {
  try {
    return await Q.fetchGroup(params);
  } catch (e) {
    logger.error(e);
  }
}
