import { Substance } from 'app/store/modules/products/types';
import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';
import { CompanyUtils, SubstanceUtils } from '../../query-utils';
import { productListSchema } from '../../query-utils/schema';

interface Company {
  id: number;
  name: string;
  hidden: boolean;
}
export interface IGetFilteredSuppliersPaginated extends IWithApolloClient {
  substance1: string;
  substance2: string | undefined;
  substance3: string | undefined;
  doseFormCategory: string | undefined;
  isAdmin: boolean;
  markets: any;
  requestedSuppliers: any;
  limit?: number;
  offset?: number;
}
export interface IGetFilteredSuppliersProductV2Paginated {
  collapsible: boolean;
  productId: number;
  SellerCompany: Company;
  Substance1: Substance;
  Substance2: Substance;
  Substance3: Substance;
  formSpecification: string;
  strength: string;
  notAvailableMarkets: string;
}
export interface IGetFilteredSuppliersReturnPaginated {
  myProductsList: {
    productsV2: IGetFilteredSuppliersProductV2Paginated;
  }[];
}
export async function getPotentialSuppliersPaginationQuery(
  p: IGetFilteredSuppliersPaginated
) {
  try {
    let substanceFilter: any = null;
    if (p.substance2 === undefined && p.substance3 === undefined) {
      substanceFilter = u._or(
        u._and(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          ),
          u._is_null('substance_2'),
          u._is_null('substance_3')
        ),
        u._and(
          u._is_null('substance_1'),
          u.nest(
            'Substance2',
            u._eq('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          ),
          u._is_null('substance_3')
        ),
        u._and(
          u._is_null('substance_1'),
          u._is_null('substance_2'),
          u.nest(
            'Substance3',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          )
        )
      );
    }
    if (p.substance2 !== undefined && p.substance3 === undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance2',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          )
        ),
        u._or(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance2.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance2',
            u._ilike('name', u.escape(`%${p?.substance2.split(' ')[0]}%`))
          )
        )
      );
    }
    if (p.substance2 !== undefined && p.substance3 !== undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance2',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance3',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          )
        ),
        u._or(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance2.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance2',
            u._ilike('name', u.escape(`%${p?.substance2.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance3',
            u._ilike('name', u.escape(`%${p?.substance2.split(' ')[0]}%`))
          )
        ),
        u._or(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance3.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance2',
            u._ilike('name', u.escape(`%${p?.substance3.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance3',
            u._ilike('name', u.escape(`%${p?.substance3.split(' ')[0]}%`))
          )
        )
      );
    }
    if (p.substance2 === undefined && p.substance3 !== undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance3',
            u._ilike('name', u.escape(`%${p?.substance1.split(' ')[0]}%`))
          )
        ),
        u._or(
          u.nest(
            'Substance1',
            u._ilike('name', u.escape(`%${p?.substance3.split(' ')[0]}%`))
          ),
          u.nest(
            'Substance3',
            u._ilike('name', u.escape(`%${p?.substance3.split(' ')[0]}%`))
          )
        )
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetFilteredSuppliersReturnPaginated>({
      query: gql`
        ${new u.Query('getFilteredSuppliersPaginated', {
          isAdmin: p.isAdmin,
        })
          .params(
            // ['$substance1', 'String'],
            // ['$substance2', 'String'],
            // ['$substance3', 'String'],
            ['$doseFormCategory', 'String'],
            ['$limit', 'Int'],
            ['$offset', 'Int']
          )
          .query(productListSchema, 'myProductsListPaginated')
          .limit('$limit')
          .offset('$offset')
          .where((t) =>
            t._and(
              t.nest(
                'Product',
                u.ProductUtils._and(
                  u.ProductUtils._or(substanceFilter),
                  u.ProductUtils._is_null('deleted_at'),
                  p.doseFormCategory
                    ? u.ProductUtils._eq(
                        'dose_form_category',
                        '$doseFormCategory'
                      )
                    : '',
                  u.ProductUtils._gte('status', 1)
                )
              )
            )
          )
          .select([
            'Product',
            'productsV2',
            u.ProductUtils.selectParams(
              ['id', 'productId'],
              [
                'Company',
                'SellerCompany',
                CompanyUtils.selectParams(
                  'id',
                  p.isAdmin ? 'name' : undefined,
                  'hidden',
                  'country'
                ),
              ],
              [
                'Substance1',
                'Substance1',
                SubstanceUtils.selectParams('id', 'name'),
              ],
              p.substance2
                ? [
                    'Substance2',
                    'Substance2',
                    SubstanceUtils.selectParams('id', 'name'),
                  ]
                : undefined,
              p.substance3
                ? [
                    'Substance3',
                    'Substance3',
                    SubstanceUtils.selectParams('id', 'name'),
                  ]
                : undefined,
              ['strength', 'strength'],
              ['form_specification', 'formSpecification'],
              ['not_available_markets', 'notAvailableMarkets'],
              ['dossier_status', 'dossierStatus'],
              ['dossier_format', 'dossierFormat'],
              ['dossier_completion_quarter', 'dossierCompletionQuarter'],
              ['dossier_completion_year', 'dossierCompletionYear'],
              [
                'gmp_approval_for_this_product_string',
                'gmpApprovalForThisProductString',
              ],
              [
                'marketing_authorizations_achieved_country',
                'marketingAuthorizationsAchievedCountry',
              ],
              [
                'product_available_licensing_supply',
                'productAvailableLicensingSupply',
              ],
              [
                'product_available_supply_distribution',
                'productAvailableSupplyDistribution',
              ],
              [
                'product_available_technology_transfer',
                'productAvailableTechnologyTransfer',
              ],
              [
                'product_available_special_import',
                'productAvailableSpecialImport',
              ],
              ['shelf_life', 'shelfLife'],
              ['batch_size', 'batchSize'],
              ['moq', 'moq'],
              p.isAdmin && ['admin_comments', 'adminComments'],
              ['registered_in', 'registeredIn'],
              ['stability_data_zones_string', 'stabilityDataZonesString'],
              ['clinical_data', 'clinicalData'],
              ['cpp', 'cpp'],
              ['cpp_available', 'cppAvailable'],
              ['finished_dose_country', 'finishedDoseCountry'],
              ['not_available_markets', 'notAvailableMarkets'],
              ['created_by_id', 'createdBy'],
              ['pack_size', 'packSize'],
              ['volume', 'volume']
            ),
          ])
          .orderBy('ranking', 'desc')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        // substance1: p?.substance1,
        // substance2: p?.substance2,
        // substance3: p?.substance3,
        doseFormCategory: p?.doseFormCategory,
        limit: p?.limit ? p.limit : 1000,
        offset: p?.offset ? p.offset : 0,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/deals/query.ts -> getPotentialSuppliersPaginationQuery',
      e
    );
  }
}
