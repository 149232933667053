import { createActionCreator as c } from '@reduxify/utils';
import { Dictionary } from 'app/utils/dictionary';
import {
  DealsFilter,
  EDealStatus,
  ICreateDealForm,
  ICreateProductDeal,
  IRequest,
  Notifications,
} from './types';

import { Fetch } from 'app/modules/requests/my-requests/my-requests-list';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IDeal } from '../deal/types';
import { OnBoardingFilter } from '../products/types';

export * from './messages/actions';

/** Data fetch section */
interface IFetchDeals extends IWithApolloClient {
  fetch: Fetch;
}
const fetchRequests = c<IFetchDeals>('requests/FETCH_REQUESTS');
export { fetchRequests, IFetchDeals };

const fetchArchiveDeals = c<IWithApolloClient>('requests/FETCH_ARCHIVE_DEALS');
export { fetchArchiveDeals };

interface IFetchDeal extends IWithApolloClient {
  id: number;
}
const fetchDeal = c<IFetchDeal>('requests/FETCH_DEAL');
export { fetchDeal };

interface ICreateProductDealAction extends IWithApolloClient {
  values: ICreateProductDeal;
}
const createProductDeal = c<ICreateProductDealAction>(
  'requests/CREATE_PRODUCT_DEAL'
);
export { ICreateProductDealAction, createProductDeal };

interface ICreateMultipleProductDealsAction extends IWithApolloClient {
  values: ICreateProductDeal[];
}
const createMultipleProductRequests = c<ICreateProductDealAction>(
  'requests/CREATE_MULTIPLE_PRODUCT_DEALS'
);
export { ICreateMultipleProductDealsAction, createMultipleProductRequests };

interface IChangeStatusDeal extends IWithApolloClient {
  id: number;
  state?: EDealStatus;
  archived: boolean;
}

const changeDealState = c<IChangeStatusDeal>('requests/CHANGE_DEAL_STATE');

export { changeDealState, IChangeStatusDeal };

const setNotification = c<Notifications>('requests/SET_NOTIFICATIONS');
export { setNotification };

const setCreateRequestAfterRegister = c<ICreateDealForm>(
  'requests/SET_CREATE_DEAL_FORM'
);
export { setCreateRequestAfterRegister };

const setDealsCache = c<Dictionary<IRequest>>('requests/SET_CACHE');
export { setDealsCache };

const setDealsIds = c<number[]>('requests/SET_IDS');
export { setDealsIds };

const setArchiveIds = c<number[]>('requests/SET_ARCHIVE_IDS');
export { setArchiveIds };

/** Pagination section */
const setTotalCount = c<number>('requests/SET_TOTAL_COUNT');
export { setTotalCount };

const setArchTotalCount = c<number>('requests/SET_ARCH_TOTAL_COUNT');
export { setArchTotalCount };

const setCurrentPage = c<number>('requests/SET_CURRENT_PAGE');
export { setCurrentPage };

const setPageSize = c<number>('requests/SET_PAGE_SIZE');
export { setPageSize };

/** Async actions section */
export const isFetching = c<boolean>('requests/FETCHING');

export const hasFetched = c<boolean>('requests/FETCHED');

export interface IChangeIsPaid extends IWithApolloClient {
  id: number;
  isPaidStatus: boolean;
}

const setPaid = c<IChangeIsPaid>('request/SET_PAID');
export { setPaid };

export interface ISetBroker extends IWithApolloClient {
  id: number;
  brokerId: number;
}

const setRequestBroker = c<ISetBroker>('request/SET_BROKER');
export { setRequestBroker };

export interface IUpdateComments extends IWithApolloClient {
  id: number;
  comments: string;
}

const updateDealComments = c<IUpdateComments>('requests/UPDATE_COMMENTS');
export { updateDealComments };

const restoreArchivedDeal = c<IFetchDeal>('requests/RESTORE_ARCHIVED');
export { restoreArchivedDeal };

interface ISetFilter extends IWithApolloClient {
  filter: DealsFilter;
  isArchive?: boolean;
  fetch: Fetch;
}
export const setFilter = c<ISetFilter>('requests/SET_FILTER');
export const setFilterValue = c<DealsFilter>('requests/SET_FILTER_VALUE');
export const agree = c<IWithApolloClient<{ request: number }>>(
  'requests/AGREE'
);
export const setRequestExistsModalOpen = c<boolean>(
  'requests/SET_DEAL_EXISTS_MODAL_OPEN'
);

export const setRequestExistsLink = c<string>('requests/SET_DEAL_EXISTS_LINK');
export const setRequestOriginFilterAction = c<OnBoardingFilter>(
  'requests/SET_ONBOARDING_FILTER_ACTION'
);
export const setDealOriginFilter = c<
  IWithApolloClient<{
    isArchive: boolean;
    filter: OnBoardingFilter;
  }>
>('requests/SET_DEAL_ORIGIN_FILTER');

interface IApproving extends IWithApolloClient {
  id: number;
  approved: boolean;
}
export const approvingDeal = c<IApproving>('requests/APPROVE_DEAL');

export const setLinkSellerRequestToDealId = c<number>(
  'requests/SET_LINK_SELLER_REQUEST_TO_DEAL_ID'
);

export const setLinkSellerRequestToRequestModalOpen = c<boolean>(
  'products/SET_LINK_SELLER_REQUEST_TO_DEAL_MODAL_OPEN'
);
export interface ISetDealLink extends IWithApolloClient {
  id: number;
  dealId?: number;
  dealItem?: IDeal;
}
const setRequestLink = c<ISetDealLink>('request/SET_DEAL_LINK');
export { setRequestLink };

export interface IUpdateDealPhase extends IWithApolloClient {
  id: number;
  phase: number;
  skippedReason?: number;
}
export const updateDealPhase = c<IUpdateDealPhase>('request/UPDATE_DEAL_PHASE');
