import AsyncSelect from 'app/components/inputs/async-select';
import { WrappedSelect } from 'app/components/inputs/select';
import { toTitleCase } from 'app/store/modules/global/actions';
import { allCountries as countriesWithRegions } from 'app/store/modules/global/types';
import { isEmpty, orderBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  AdditionalDetailsFormContainer,
  AdditionalDetailsFormWrapper,
  CreateButton,
  DropdownContainerItem,
  ErrorMessage,
  Header,
  InputContainer,
  StatusLabel,
  UpdateButton,
  ToggleContainer,
  StatusContainer,
  StatusTypeText,
} from './styles';

import { getBrokersQuery } from 'app/store/modules/requests/query';
import useStateCallback from 'app/helpers/use-state-callback';
import classNames from '../../../utils/class-names';
import { ButtonIcon } from '../../../components/buttons/admin-only-button';

type Callback<T> = (options: T) => any;

const placeholder = '';
const errorCloseTimeMS = 3000;

export const validationMessage = 'This field cannot be empty!';

const countries = countriesWithRegions.filter(
  (country: {
    id: string;
    name: string;
    region?: any;
    order: number;
    trinti?: boolean;
  }) => country.region !== true
);

const CreateDeal: React.FC<IProps> = ({
  submitForm,
  closeModal,
  fetchSubstances,
  fetchBuyers,
  fetchRequestById,
  editDealId,
  loggedInUser,
  isEdit,
  client,
  fetchDoseCategories,
}) => {
  const [formSubmitErrorMessage, setFormSubmitErrorMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [buyer, setBuyer] = useState<ISelectItem<number> | undefined>();
  const [substance1, setSubstance1] = useState<
    ISelectItem<number> | undefined
  >();
  const [substance2, setSubstance2] = useState<
    ISelectItem<number> | undefined
  >();
  const [substance3, setSubstance3] = useState<
    ISelectItem<number> | undefined
  >();
  const [broker, setBroker] = useState<ISelectItem<number> | undefined>();

  const [countriesOfInterest, setCountriesOfInterest] = useState<
    string[] | undefined
  >();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [requestCount, setRequestCount] = useState<number>(0);
  const [validationMessages, setValidationMessages] = useStateCallback({
    buyerMsg: undefined,
    substance1Msg: undefined,
    countriesOfInterestMsg: undefined,
    doseFormCategoryMsg: undefined,
  });

  const [brokersList, setBrokers] = useState<
    ISelectItem<number>[] | undefined
  >();
  const [doseFormCategories, setDoseFormCategories] = useState<
    ISelectItem<string>[]
  >([]);

  const [doseFormCategory, setDoseFormCategory] = useState<
    ISelectItem<string> | undefined
  >();

  const fetchBrokers = (
    name: string,
    callback: Callback<ISelectItem<number>[]>
  ) =>
    getBrokersQuery({ client, name }).then((r) => {
      if (r) {
        setBrokers(r.data.options);
        callback(r.data.options);
        // Defined name here as ticket says to put those 3 brokers always top in this order
        const topBrokers: string[] = ['Rokas', 'Ruta', 'Veronika'];
        // eslint-disable-next-line
        r.data.options.forEach((option: any, i: number) => {
          const index = topBrokers.indexOf(option.label.split(' ')[0]);
          if (index > -1) {
            const defaultFirstOptions = r.data.options[index];
            r.data.options[index] = option;
            r.data.options[i] = defaultFirstOptions;
          }
        });

        return [
          {
            value: undefined,
            label: '--Nepasirinktas--',
          },
          ...r.data.options,
        ];
      } else {
        return [
          {
            value: undefined,
            label: '--Nepasirinktas--',
          },
        ];
      }
    });

  const fetchDoseFormCategoriesInner = (
    name: string,
    callback: Callback<ISelectItem<string>[]>
  ) =>
    fetchDoseCategories(name).then((r: any) => {
      if (r) {
        setDoseFormCategories(r);
        callback(r);
        return r;
      } else {
        callback([]);
        return [];
      }
    });

  const validate = (onValidated: () => void) => {
    const foundDoseFormCategory = doseFormCategories.filter(
      (category) =>
        doseFormCategory && doseFormCategory.value === category.value
    )[0];

    setValidationMessages(
      {
        buyerMsg: (!buyer && validationMessage) || undefined,
        substance1Msg: (!substance1 && validationMessage) || undefined,
        countriesOfInterestMsg:
          ((!countriesOfInterest || isEmpty(countriesOfInterest)) &&
            validationMessage) ||
          undefined,
        doseFormCategoryMsg:
          !foundDoseFormCategory &&
          doseFormCategories &&
          doseFormCategories.length > 0
            ? validationMessage
            : undefined,
      },
      onValidated
    );
  };
  const [createButtonClicked, setCreateButtonClicked] = useState(false);
  const [updateButtonClicked, setUpdateButtonClicked] = useState(false);
  const requestId = editDealId ? editDealId : 0;

  useEffect(() => {
    if (isEdit && requestId > 0) {
      fetchRequestById(requestId, loggedInUser.isAdmin).then((result: any) => {
        for (const key in result) {
          // eslint-disable-next-line
          if (result.hasOwnProperty(key) && key === 'Buyer') {
            if (result[key]) {
              setBuyer({ value: result[key].id, label: result[key].fullName });
            }
            // eslint-disable-next-line
          } else if (result.hasOwnProperty(key) && key === 'Substance1') {
            if (result[key]) {
              setSubstance1({
                value: result[key].id,
                label: toTitleCase(result[key].name),
              });
            }
            // eslint-disable-next-line
          } else if (result.hasOwnProperty(key) && key === 'Substance2') {
            if (result[key]) {
              setSubstance2({
                value: result[key].id,
                label: toTitleCase(result[key].name),
              });
            }
            // eslint-disable-next-line
          } else if (result.hasOwnProperty(key) && key === 'Substance3') {
            if (result[key]) {
              setSubstance3({
                value: result[key].id,
                label: toTitleCase(result[key].name),
              });
            }
            // eslint-disable-next-line
          } else if (result.hasOwnProperty(key) && key === 'doseFormCategory') {
            if (result[key]) {
              setDoseFormCategory({
                value: result[key],
                label: toTitleCase(result[key]),
              });
            }
            // eslint-disable-next-line
          } else if (result.hasOwnProperty(key) && key === 'Broker') {
            if (result[key]) {
              setBroker({ value: result[key].id, label: result[key].fullName });
            }
          } else if (
            // eslint-disable-next-line
            result.hasOwnProperty(key) &&
            key === 'countriesOfInterest'
          ) {
            if (result[key]) {
              if (typeof result[key] === 'string') {
                setCountriesOfInterest(result[key].split(','));
              } else {
                setCountriesOfInterest(result[key]);
              }
            }
            // eslint-disable-next-line
          } else if (result.hasOwnProperty(key) && key === 'Requests') {
            if (result[key] && Array.isArray(result[key])) {
              setRequestCount(result[key]?.length || 0);
              const deal = result[key][0];
              // eslint-disable-next-line no-prototype-builtins
              if (deal?.hasOwnProperty('isActive')) {
                setIsActive(deal.isActive);
              }
            }
          }
        }
      });
    }
  }, [isEdit]);

  useEffect(() => {
    if (createButtonClicked || updateButtonClicked) {
      validate(() => null);
    }
  }, [buyer, substance1, countriesOfInterest, doseFormCategory]);

  useEffect(() => {
    const loggedId = loggedInUser ? loggedInUser.id : undefined;
    const loggedInBroker = brokersList
      ? brokersList.filter((x) => x.value === loggedId)[0]
      : undefined;
    if (brokersList && brokersList.length > 0 && !broker && loggedInBroker) {
      setBroker(loggedInBroker);
    }
  }, [brokersList]);

  const handleCreateButtonClick = async () => {
    setCreateButtonClicked(true);
    validate(async () => {
      if (
        !validationMessages.buyerMsg &&
        !validationMessages.substance1Msg &&
        !validationMessages.countriesOfInterestMsg &&
        !formSubmitted &&
        !validationMessages.doseFormCategoryMsg &&
        buyer &&
        substance1 &&
        !isEmpty(countriesOfInterest) &&
        doseFormCategory
      ) {
        setFormSubmitted(true);
        const newDealId = await submitForm(
          loggedInUser.id,
          buyer,
          countriesOfInterest,
          substance1,
          false,
          0,
          substance2,
          substance3,
          broker,
          doseFormCategory,
          true
        );

        if (newDealId) {
          closeModal();
        } else {
          setFormSubmitErrorMessage(
            'Something went wrong. Deal was not created.'
          );
          setTimeout(() => {
            closeModal();
          }, errorCloseTimeMS);
        }
      }
    });
  };
  const handleUpdateButtonClick = async () => {
    setUpdateButtonClicked(true);
    validate(async () => {
      if (
        !validationMessages.buyerMsg &&
        !validationMessages.substance1Msg &&
        !validationMessages.countriesOfInterestMsg &&
        !validationMessages.doseFormCategoryMsg &&
        !formSubmitted &&
        buyer &&
        substance1 &&
        !isEmpty(countriesOfInterest) &&
        doseFormCategory
      ) {
        setFormSubmitted(true);
        const updatedDealId = await submitForm(
          loggedInUser.id,
          buyer,
          countriesOfInterest,
          substance1,
          true,
          requestId,
          substance2,
          substance3,
          broker,
          doseFormCategory,
          isActive
        );

        if (updatedDealId) {
          closeModal();
        } else {
          setFormSubmitErrorMessage(
            'Something went wrong. Deal was not created.'
          );
          setTimeout(() => {
            closeModal();
          }, errorCloseTimeMS);
        }
      }
    });
  };

  const handleEnterPress = (event: any) => {
    if (event.key === 'Enter') {
      handleCreateButtonClick();
    }
  };
  const header = isEdit ? 'Edit Deal' : 'Create New Deal';
  return (
    <div>
      <AdditionalDetailsFormWrapper>
        <AdditionalDetailsFormContainer>
          <Header>{header}</Header>
          <InputContainer>
            <form>
              <DropdownContainerItem>
                <div
                  className={`override ${
                    buyer && buyer.value ? 'selected' : ''
                  }`}
                >
                  <AsyncSelect
                    loadOptions={fetchBuyers}
                    onChange={(val) => {
                      setBuyer(val);
                    }}
                    id={'buyer-select'}
                    label={'Buyer*'}
                    value={buyer}
                    error={validationMessages.buyerMsg}
                    zIndex={'14'}
                    placeholder={placeholder}
                    autoFocus={true}
                    onKeyDown={handleEnterPress}
                    isDisabled={isEdit}
                  />
                </div>
              </DropdownContainerItem>

              <DropdownContainerItem>
                <AsyncSelect
                  loadOptions={fetchSubstances}
                  onChange={(val) => {
                    setSubstance1(val);
                  }}
                  id={'substance-1-select'}
                  label={'Generic name (substance 1)*'}
                  value={substance1}
                  error={validationMessages.substance1Msg}
                  zIndex={'13'}
                  placeholder={placeholder}
                  onKeyDown={handleEnterPress}
                />
              </DropdownContainerItem>

              <DropdownContainerItem>
                <AsyncSelect
                  loadOptions={fetchSubstances}
                  onChange={(val) => {
                    setSubstance2(val);
                  }}
                  id={'substance-2-select'}
                  label={'Generic name (substance 2)'}
                  value={substance2}
                  zIndex={'12'}
                  placeholder={placeholder}
                  onKeyDown={handleEnterPress}
                />
              </DropdownContainerItem>

              <DropdownContainerItem>
                <AsyncSelect
                  loadOptions={fetchSubstances}
                  onChange={(val) => {
                    setSubstance3(val);
                  }}
                  id={'substance-3-select'}
                  label={'Generic name (substance 3)'}
                  value={substance3}
                  zIndex={'11'}
                  placeholder={placeholder}
                  onKeyDown={handleEnterPress}
                />
              </DropdownContainerItem>
              <DropdownContainerItem>
                <AsyncSelect
                  loadOptions={fetchDoseFormCategoriesInner as any}
                  onChange={(val) => {
                    setDoseFormCategory(val);
                  }}
                  id={'dose-form-select'}
                  label={'Dose Form Category*'}
                  value={doseFormCategory}
                  zIndex={'10'}
                  placeholder={placeholder}
                  onKeyDown={handleEnterPress}
                  error={validationMessages.doseFormCategoryMsg}
                />
              </DropdownContainerItem>

              <DropdownContainerItem>
                <WrappedSelect
                  label={'Countries of interest*'}
                  isMulti={true}
                  error={validationMessages.countriesOfInterestMsg}
                  onChangeMulti={(selectedOptions) => {
                    setCountriesOfInterest(
                      selectedOptions.map((option) => option.value)
                    );
                  }}
                  onKeyDown={handleEnterPress}
                  optionList={orderBy(
                    countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    })),
                    'label'
                  )}
                  values={
                    countriesOfInterest
                      ? countriesOfInterest.map((countryOfInteresCode) => {
                          const country = countries.filter(
                            (countryItem) =>
                              countryItem.id === countryOfInteresCode
                          )[0];
                          if (country) {
                            return {
                              value: country.id,
                              label: country.name,
                            };
                          } else {
                            return {
                              value: countryOfInteresCode,
                              label: countryOfInteresCode,
                            };
                          }
                        })
                      : []
                  }
                  zIndex={'9'}
                  id={'countries-of-interest'}
                  placeholder={placeholder}
                />
              </DropdownContainerItem>
              <DropdownContainerItem>
                <AsyncSelect
                  loadOptions={fetchBrokers}
                  onChange={(val) => {
                    setBroker(val);
                  }}
                  id={'broker-select'}
                  label={'Broker'}
                  value={broker}
                  zIndex={'8'}
                  placeholder={placeholder}
                  onKeyDown={handleEnterPress}
                />
              </DropdownContainerItem>
              {isEdit && requestId > 0 && loggedInUser.isAdmin && (
                <StatusContainer>
                  <StatusLabel>Request is</StatusLabel>
                  <>
                    <ToggleContainer className="__filter-container">
                      <div
                        className={classNames(
                          'filter-status-option',
                          isActive && requestCount > 0 && 'active'
                        )}
                        onClick={() => setIsActive(requestCount > 0)}
                      >
                        <StatusTypeText>Active</StatusTypeText>
                      </div>
                      <div
                        className={classNames(
                          'filter-status-option',
                          !isActive && loggedInUser.isAdmin && 'active'
                        )}
                        onClick={() =>
                          loggedInUser.isAdmin && setIsActive(false)
                        }
                      >
                        <ButtonIcon />
                        <StatusTypeText>Inactive</StatusTypeText>
                      </div>
                    </ToggleContainer>
                  </>
                </StatusContainer>
              )}
              {isEdit && requestId > 0 && (
                <UpdateButton
                  data-testid="update-button"
                  onClick={handleUpdateButtonClick}
                >
                  Update
                </UpdateButton>
              )}
              {!isEdit && requestId === 0 && (
                <CreateButton
                  data-testid="create-button"
                  onClick={handleCreateButtonClick}
                >
                  Create
                </CreateButton>
              )}
              {formSubmitErrorMessage !== '' && (
                <ErrorMessage>{formSubmitErrorMessage}</ErrorMessage>
              )}
            </form>
          </InputContainer>
        </AdditionalDetailsFormContainer>
      </AdditionalDetailsFormWrapper>
    </div>
  );
};

export default CreateDeal;
