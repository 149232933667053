import { IWithApolloClient } from 'app/store/modules/global/types';
import {
  setSellerCompanyId,
  setSellerInformationModalOpen,
} from 'app/store/modules/seller-information/actions';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { fetchDeal } from '../../../store/modules/requests/actions';
import SellerInformationModal from './admin-seller-information-modal';

interface IStateProps {
  isOpen: boolean;
  isAdmin?: boolean;
}

interface IDispatchProps {
  closeModal: () => any;
}
interface IOwnProps extends RouteComponentProps<RoutProps>, IWithApolloClient {}
interface RoutProps {
  id: string;
}
export interface IProps extends IStateProps, IDispatchProps {}

export default withApollo<any>(
  withRouter(
    connect<IStateProps, IDispatchProps, IOwnProps, IStore>(
      (state: IStore) => ({
        isOpen:
          state.sellerInformation.sellerInformationModalOpen &&
          state.auth.loggedInUser.isAdmin,
      }),
      (dispatch, ownProps) => {
        return {
          closeModal: () => {
            dispatch(setSellerInformationModalOpen(false));
            dispatch(setSellerCompanyId(0));
            if (ownProps.match.params.id) {
              dispatch(
                fetchDeal({
                  id: parseFloat(ownProps.match.params.id),
                  client: ownProps.client,
                })
              );
            }
          },
        };
      }
    )(SellerInformationModal)
  )
);
