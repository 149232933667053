import * as u from 'app/store/modules/query-utils';
import { companyDirectoryLeadsSchema } from 'app/store/modules/query-utils/schema';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IDirectoryCompanyListing } from '../actions';

export async function requestDirectorylisting(p: IDirectoryCompanyListing) {
  try {
    return await p.client.mutate({
      mutation: gql`
        ${new u.Mutation('')
          .params(
            ['$company', 'String'],
            ['$email', 'String'],
            ['$url', 'String'],
            ['$button', 'String']
          )
          .insert(companyDirectoryLeadsSchema)
          .objects([
            ['company_name', '$company'],
            ['email', '$email'],
            ['created_at', u.escape(new Date().toISOString())],
            ['country_url', '$url'],
            ['form_location', '$button'],
          ])
          .returning('id')
          .toString()
          .toString()}
      `,
      variables: {
        company: p.company,
        email: p.email,
        url: p.url,
        button: p.buttonLocation,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/companies-contacts/query.ts -> requestDirectorylisting',
      e
    );
  }
}
