import {
  ICompany,
  ICompanyDirectory,
} from 'app/store/modules/ma-companies/types';
import * as u from 'app/store/modules/query-utils';
import { IFragment } from '../../query-utils/fragment';
import {
  Schema_CompanyDirectory,
  Schema_MACompany,
} from '../../query-utils/schema';

export type IMACompanyFragment = ICompany;
export const maCompanyFragment: IFragment = {
  fragment: () => `
    fragment maCompanyFragment on ma_company {
      ${u.selectParams<Schema_MACompany>(
        'id',
        'ranking',
        'subject',
        'description',
        ['created_at', 'createdAt'],
        ['updated_at', 'updatedAt'],
        ['deleted_at', 'deletedAt']
      )}
    }
  `,
  name: 'maCompanyFragment',
};

export type ICompanyDirectoryFragment = ICompanyDirectory;
export const companyDirectoryFragment: IFragment = {
  fragment: () => `
    fragment companyDirectoryFragment on companydirectory {
      ${u.selectParams<Schema_CompanyDirectory>(
        'id',
        'name',
        'country',
        'website',
        'address',
        'code'
      )}
    }
  `,
  name: 'companyDirectoryFragment',
};
