import React from 'react';

export const UserOwnedIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="5.5" cy="5.5" r="5.5" fill="#AB6FDF" />
      <path
        fill="#FFF"
        d="M5.496 8.712c-.816 0-1.436-.208-1.86-.624C3.212 7.672 3 7.064 3 6.264V3h1.44v3.32c0 .405.09.717.272.936.181.219.443.328.784.328s.603-.11.784-.328c.181-.219.272-.53.272-.936V3H8v3.264c0 .795-.215 1.401-.644 1.82-.43.419-1.05.628-1.86.628z"
      />
    </g>
  </svg>
);
