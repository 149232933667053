import { _, media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import { classNames } from '../../../utils/class-names';

const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  height: 26px;
  cursor: pointer;
  min-width: 89px;
  max-width: 89px;
  background-color: ${(props) => props.theme.colors.primaryLight};
  &:not(&.disabled):hover {
    background-color: ${(props) => props.theme.colors.primaryLightHover};
  }
  ${_(media.max.md)} {
    margin-left: 0px;
    min-width: 76px;
  }
`;

const AddItemRadiusContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 17px;
  height: 34px;
  cursor: pointer;
  min-width: 164px;
  max-width: 164px;
  padding-left: 5px;
  background-color: ${(props) => props.theme.colors.primaryLight};
  &:not(&.disabled):hover {
    background-color: ${(props) => props.theme.colors.primaryLightHover};
  }
  ${_(media.max.md)} {
    margin-left: 0px;
    min-width: 164px;
  }
`;

const AddItemIcon = styled.img`
  width: 8px;
  height: 8px;
  object-fit: contain;
  cursor: pointer;
  margin: auto;
  margin-right: 5px;
`;

const AddItemOvalIconContainer = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
  margin-right: 9px;
  background: ${(props) => props.theme.colors.white};
`;

const AddItemRadiusIcon = styled.img`
  width: 8.5px;
  height: 8.5px;
  object-fit: contain;
  cursor: pointer;
  margin: auto;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.primary};
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-right: auto;
  cursor: pointer;
  white-space: nowrap;
  ${_(media.max.md)} {
    display: none;
  }
`;
const ShortLabel = styled(Label)`
  display: none;
  ${_(media.max.md)} {
    display: block;
  }
`;

interface Props {
  label: string;
  shortLabel: string;
  onClick?: () => any;
  disabled?: boolean;
}

const AddItemBlueButton: React.FC<Props> = ({
  label,
  onClick,
  shortLabel,
  disabled,
}) => {
  return (
    <AddItemContainer
      onClick={onClick}
      className={classNames(!!disabled && 'disabled')}
    >
      <AddItemOvalIconContainer>
        <AddItemIcon src={'/img/plus-alone.svg'} />
      </AddItemOvalIconContainer>
      <Label htmlFor={label}>{label}</Label>
      <ShortLabel htmlFor={label}>{shortLabel}</ShortLabel>
    </AddItemContainer>
  );
};

const AddItemBlueButtonRadius: React.FC<Props> = ({
  label,
  onClick,
  shortLabel,
  disabled,
}) => {
  return (
    <AddItemRadiusContainer
      onClick={onClick}
      className={classNames(!!disabled && 'disabled')}
    >
      <AddItemOvalIconContainer>
        <AddItemRadiusIcon src={'/img/plus-alone.svg'} />
      </AddItemOvalIconContainer>
      <Label htmlFor={label}>{label}</Label>
      <ShortLabel htmlFor={label}>{shortLabel}</ShortLabel>
    </AddItemRadiusContainer>
  );
};

export const Components = {
  AddItemContainer,
  AddItemIcon,
  Label,
  ShortLabel,
  AddItemBlueButtonRadius,
};
export default AddItemBlueButton;
