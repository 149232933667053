import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import {
  companyDirectoryFragment,
  ICompanyDirectoryFragment,
} from '../fragments/ma-company';

export interface IGetCompanyDirectoryCompanyById extends IWithApolloClient {
  companyId: string;
}
export interface IGetCompanyDirectoryCompanyByIdReturn {
  companydirectory: ICompanyDirectoryFragment[];
}
export async function getCompanyDirectoryByIdQuery(
  p: IGetCompanyDirectoryCompanyById
) {
  try {
    const whereClause = u.where(u._eq('id', '$companyId'));
    return await p.client.query({
      query: gql`
      ${companyDirectoryFragment.fragment()}
      query getCompanyDirectory(${u.params(['$companyId', 'Int'])}) {
        companydirectory(${whereClause}) {
          ...${companyDirectoryFragment.name}
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: { companyId: parseFloat(p.companyId) },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> getCompanyDirectoryCompanyByIdQuery',
      e
    );
  }
}
