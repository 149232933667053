import styled from 'styled-components';

// custom imports
import { MarketplaceButtonStyle } from 'app/components/buttons/marketplace-button';
import { Components as LoaderC } from 'app/components/loader';
import { Components as PagerComponents } from 'app/components/pagination/pager';
import { Th as _Th } from 'app/components/table';
import { _, media } from 'app/styles/breakpoint';

export const RequestTableContainer = styled.div`
  width: 100%;
  margin-bottom: 7px;
  box-shadow: ${(props) =>
    props.theme.colors.boxShadowParam +
    ' ' +
    props.theme.colors.filtersButtonShadow};
  background-color: ${(props) => props.theme.colors.white};
  min-width: 700px;
  ${_(media.max.md)} {
    min-width: 270px;
  }
  ${LoaderC.Background} {
    overflow: auto;
  }
  ${PagerComponents.Container} {
    padding: 0 16px;
  }
  .table tr:not(:first-child):hover {
    background-color: ${({ theme }) => theme.colors.adminCreatedBackground};
  }
  .broker-modal {
    width: 320px;
    padding: 20px;
    border-width: 0px;
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.colors.modalShadow};

    .header {
      font-size: 20px;
      font-weight: 600;
      margin: auto 0;
    }
    div.close {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
    }
  }
  .request-v2-modal {
    width: 800px;
    max-width: 100%;
    padding: 20px;
    border-width: 0px;
    border-radius: 3px;
    box-shadow: ${({ theme }) => theme.colors.modalShadow};

    .header {
      font-size: 20px;
      font-weight: 600;
      margin: auto 0;
    }
    div.close {
      border-bottom: 1px solid ${({ theme }) => theme.colors.grayLight};
    }
  }
`;

export const MarketplaceButtonContainer = styled.div`
  width: 100%;
  padding-top: 30px;
  display: flex;
  justify-content: center;
  ${media.max.md`
    padding-top: 20px;
    `}
  ${media.max.sm`
    padding-top: 15px;
    `}

  ${MarketplaceButtonStyle} {
    ${media.max.sm`
      width: 230px;
    `}
  }
`;

//
export const Th = styled(_Th)<any>`
  line-height: unset;
`;
