import { withTracker } from 'client/tracker';
import GlobalStyle from 'app/components/global-style';
// import TwinGlobalStyle from 'app/components/twin-global-styles';
// import IntercomWidget from 'app/components/misc/intercom-widget';
import { routes } from 'app/config';
import FeatureToggleProvider from 'app/helpers/feature-toggle-context';
import { useMount } from 'app/helpers/lifecycle';
import Admin from 'app/routes/admin';
import AdminOnboarding from 'app/routes/admin-onboarding';
import ArchivedInfo from 'app/routes/archived-info';
import DealDetails from 'app/routes/deal-details';
import Companies from 'app/routes/companies';
import CompaniesHomeBuyers from 'app/routes/companies-home-buyers';
import CompaniesHomeBuyersOld from 'app/routes/companies-home-buyers-old';
import CompaniesHomeSellers from 'app/routes/companies-home-sellers';
import CompaniesHomeSellersOld from 'app/routes/companies-home-sellers-old';
import CompaniesOld from 'app/routes/companies-old';
import CompanyDirectory from 'app/routes/company-directory';
import CompanyDirectoryCountry from 'app/routes/company-directory-country';
// temp hide company inner page
// import CompanyDirectoryCountryCompany from 'app/routes/company-directory-country-company';
import CompanyDirectoryCountryRedirect from 'app/routes/company-directory-country-redirect';
import ContactsCompanies from 'app/routes/contact-companies';
import ContactsCompaniesOld from 'app/routes/contact-companies-old';
import Contacts from 'app/routes/contacts';
import Cookies from 'app/routes/cookies';
import CreateAccount from 'app/routes/create-account';
import DealInfo from 'app/routes/request-info';
import DealsArchive from 'app/routes/requests-archive';
import EmailVerify from 'app/routes/email-verify';
import FAQForAcquisitions from 'app/routes/faq-for-acquisitions';
import FAQForBuyers from 'app/routes/faq-for-buyers';
import FastTrackProgram from 'app/routes/fast-track-program';
import GdprCompliant from 'app/routes/gdpr-compliant';
import GetStarted from 'app/routes/get-started';
import Home from 'app/routes/home';
import HomeAdvertisement from 'app/routes/home-advertisement';
import LinkedinFailed from 'app/routes/linkedin-failed';
import LinkedinSuccess from 'app/routes/linkedin-success';
import Login from 'app/routes/login';
import ManufacturersCountries from 'app/routes/manufacturers-countries';
import ManufacturersInCountry from 'app/routes/manufacturers-in-country';
import Marketplace from 'app/routes/marketplace';
import MarketplaceOld from 'app/routes/marketplace-old';
import MyDeals from 'app/routes/my-deals';
import MyRequests from 'app/routes/my-requests';
import MyDealsBuying from 'app/routes/my-requests-buying';
import MyDealsSelling from 'app/routes/my-requests-selling';
import MyProducts from 'app/routes/my-products';
import NewProduct from 'app/routes/new-product';
import Substances from 'app/routes/substances';
import PageNotFound from 'app/routes/not-found-page';
import OfferInfo from 'app/routes/offer-info';
import OfferInfoOld from 'app/routes/offer-info-old';
import OfferInfoOld1 from 'app/routes/offer-info-old-1';
import OfferTradeInfo from 'app/routes/offer-trade-info';
import OfferTradeInfoOld from 'app/routes/offer-trade-info-old';
import Partnering from 'app/routes/partnering';
import PharmaceuticalManufacturers from 'app/routes/pharmaceutical-manufacturers';
import Privacy from 'app/routes/privacy';
import ProductInfo from 'app/routes/product-info';
import ProductInfoOld from 'app/routes/product-info-old';
import ProductInfoOld1 from 'app/routes/product-info-old-1';
import ProductTradeInfo from 'app/routes/product-trade-info';
import ProductTradeInfoOld from 'app/routes/product-trade-info-old';
import Register from 'app/routes/register';
import RegisterOld from 'app/routes/register-old';
import SellersHome from 'app/routes/sellers-home';
import SellersHomeOld from 'app/routes/sellers-home-old';
import SiteMap from 'app/routes/site-map';
import TermsOfService from 'app/routes/terms-of-service';
import UpdateProduct from 'app/routes/update-product';
import UserSettings from 'app/routes/user-settings';
import theme from 'app/styles/theme';
import React from 'react';
import { useApolloClient } from 'react-apollo';
import { useLocation } from 'react-router';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useDispatch } from './helpers/redux';
import { requestFetchNavCount } from './store/modules/auth/actions';
import CreateDealModal from './components/modals/create-deal-modal';
import EditDealModal from './components/modals/edit-deal-modal';
import VerifyEmailModal from './components/modals/verify-email-modal';
import CreateAccountModal from './components/modals/create-account-modal';
import BuyerQualificationModal from './components/modals/buyer-qualification-modal';
import SellerInformationModal from './components/modals/admin-seller-information-modal';
import AdminBuyerQualificationViewModal from './components/modals/admin-buyer-qualification-view-modal';

const App: React.FC = (props) => {
  // const isAdmin = useSelector((state) => state.auth.loggedInUser.isAdmin);

  useMount(() => {
    MyDealsBuying.preload();
    MyDealsSelling.preload();
    MyProducts.preload();
    MyRequests.preload();
    MyDeals.preload();
    DealDetails.preload();
    DealInfo.preload();
    AdminOnboarding.preload();
    Substances.preload();

    // Disable all other preloading
    // Home.preload();
    // HomeAdvertisement.preload();
    // Marketplace.preload();
    // FAQForBuyers.preload();
    // Partnering.preload();
    // GetStarted.preload();
    // CreateAccount.preload();
    // Register.preload();
    // Contacts.preload();
    // Login.preload();
    // UserSettings.preload();
    // NewProduct.preload();
    // UpdateProduct.preload();
    // OfferInfo.preload();
    // ProductInfo.preload();
    // Admin.preload();
    // FAQForAcquisitions.preload();
    // TermsOfService.preload();
    // DealsArchive.preload();
    // EmailVerify.preload();
    // LinkedinFailed.preload();
    // LinkedinSuccess.preload();
    // Privacy.preload();
    // GdprCompliant.preload();
    // Cookies.preload();
    // Companies.preload();
    // ContactsCompanies.preload();
    // SellersHome.preload();
    // CompaniesHomeSellers.preload();
    // MarketplaceOld.preload();
    // CompaniesOld.preload();
    // ContactsCompaniesOld.preload();
    // SellersHomeOld.preload();
    // CompaniesHomeBuyersOld.preload();
    // CompaniesHomeSellersOld.preload();
    // RegisterOld.preload();
    // ProductTradeInfo.preload();
    // ProductTradeInfoOld.preload();
    // OfferTradeInfo.preload();
    // OfferTradeInfoOld.preload();
    // CompanyDirectory.preload();
    // CompanyDirectoryCountry.preload();
    // PharmaceuticalManufacturers.preload();
    // ManufacturersCountries.preload();
    // ManufacturersInCountry.preload();
    // CompanyDirectoryCountryRedirect.preload();
    // FastTrackProgram.preload();
    /*temp hide company inner page
    CompanyDirectoryCountryCompany.preload();*/
  });

  return (
    <>
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://px.ads.linkedin.com/collect/?pid=3937393&fmt=gif"
      />{' '}
      {/*<IntercomWidget />*/}
      <div
        id="___tooltip-portal-render-portal-element"
        style={{ position: 'fixed', zIndex: 1001 }}
      />
      <FeatureToggleProvider>
        <ThemeProvider theme={theme}>
          <>
            <Switch>
              <Route
                path={routes.home}
                exact={true}
                component={withTracker(Home)}
              />
              <Route
                path={routes.homeAdvertisement}
                exact={true}
                component={withTracker(HomeAdvertisement)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.marketplacePage}
                component={withTracker(Marketplace)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.marketplace}
                component={withTracker(Marketplace)}
              />
              <Redirect from={routes.marketplace} to={routes.marketplace} />
              <ConnectedRoute
                path={routes.marketplaceOld}
                component={withTracker(MarketplaceOld)}
              />
              <Route
                path={routes.partnering}
                component={withTracker(Partnering)}
              />
              <Redirect from={routes.partnering} to={routes.contacts} />
              <ConnectedRoute
                countFetch={true}
                path={routes.login}
                component={withTracker(Login)}
              />
              <Route path={routes.contacts} component={withTracker(Contacts)} />
              <Route
                path={routes.get_started}
                component={withTracker(GetStarted)}
              />
              <Route
                path={routes.create_account}
                component={withTracker(CreateAccount)}
              />
              <Route path={routes.register} component={withTracker(Register)} />
              <Route
                path={routes.register_old}
                component={withTracker(RegisterOld)}
              />
              <Route
                path={routes.companies}
                component={withTracker(Companies)}
              />
              <Route
                path={routes.companiesOld}
                component={withTracker(CompaniesOld)}
              />
              <Route
                path={routes.contacts_companies}
                component={withTracker(ContactsCompanies)}
              />
              <Route
                path={routes.contacts_companies_old}
                component={withTracker(ContactsCompaniesOld)}
              />
              <Route
                path={routes.usersettings}
                component={withTracker(UserSettings)}
              />
              <Route path={routes.admin} component={withTracker(Admin)} />
              <ConnectedRoute
                countFetch={true}
                path={routes.admin_onboarding}
                component={withTracker(AdminOnboarding)}
              />
              <Route
                path={routes.terms_of_service}
                component={withTracker(TermsOfService)}
              />
              <Route path={routes.privacy} component={withTracker(Privacy)} />
              <Route
                path={routes.gdpr_compliant}
                component={withTracker(GdprCompliant)}
              />
              <Route path={routes.cookies} component={withTracker(Cookies)} />
              <Route
                path={routes.email_verify}
                component={withTracker(EmailVerify)}
              />
              <Route
                path={routes.linkedin_failed}
                component={withTracker(LinkedinFailed)}
              />
              <Route
                path={routes.linkedin_success}
                component={withTracker(LinkedinSuccess)}
              />

              <Route
                path={routes.faqforbuyers}
                component={withTracker(FAQForBuyers)}
              />
              <Route
                path={routes.faqforsellers}
                component={withTracker(FAQForBuyers)}
              />
              <Route
                path={routes.faqforacquisitionsBuyers}
                component={withTracker(FAQForAcquisitions)}
              />
              <Route
                path={routes.faqforacquisitionsSellers}
                component={withTracker(FAQForAcquisitions)}
              />
              <Route
                path={routes.companies_home_seller}
                component={withTracker(CompaniesHomeSellers)}
              />
              <Route
                path={routes.companies_home_seller_old}
                component={withTracker(CompaniesHomeSellersOld)}
              />
              <Route
                path={routes.companies_home_buyer}
                component={withTracker(CompaniesHomeBuyers)}
              />
              <Route
                path={routes.companies_home_buyer_old}
                component={withTracker(CompaniesHomeBuyersOld)}
              />

              <ConnectedRoute
                countFetch={true}
                path={routes.offer_old}
                component={withTracker(OfferInfoOld)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.offer_old_1}
                component={withTracker(OfferInfoOld1)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.offer}
                component={withTracker(OfferInfo)}
              />

              <ConnectedRoute
                countFetch={true}
                path={routes.products_new}
                component={withTracker(NewProduct)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.update_product_route}
                component={withTracker(UpdateProduct)}
              />

              <ConnectedRoute
                countFetch={true}
                path={routes.product_old}
                component={withTracker(ProductInfoOld)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.product_old_1}
                component={withTracker(ProductInfoOld1)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.product}
                component={withTracker(ProductInfo)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.product_trade_old}
                component={withTracker(ProductTradeInfoOld)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.product_trade}
                component={withTracker(ProductTradeInfo)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.offer_trade_old}
                component={withTracker(OfferTradeInfoOld)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.offer_trade}
                component={withTracker(OfferTradeInfo)}
              />

              <ConnectedRoute
                countFetch={true}
                path={routes.products}
                component={withTracker(MyProducts)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.substances}
                component={withTracker(Substances)}
              />

              <ConnectedRoute
                countFetch={true}
                path={routes.requestDetails}
                component={withTracker(DealInfo)}
              />
              <Redirect
                from={routes.fallBackRequestDetails}
                to={routes.requestDetails}
              />
              <ConnectedRoute
                countFetch={false}
                path={routes.deals_buying}
                component={withTracker(MyDeals)}
              />
              <Redirect from={routes.fallBackMyDeals} to={routes.deals} />
              <ConnectedRoute
                countFetch={false}
                path={routes.deals_selling}
                component={withTracker(MyDealsSelling)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.deals}
                component={withTracker(MyDeals)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.requests}
                component={withTracker(MyRequests)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.deal_v2_details}
                component={withTracker(DealDetails)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.my_deals}
                component={withTracker(MyDeals)}
              />

              <ConnectedRoute
                countFetch={true}
                path={routes.archivedInfo}
                component={withTracker(ArchivedInfo)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.dealsarchive}
                component={withTracker(DealsArchive)}
              />
              <Route
                path={routes.sellers_home}
                component={withTracker(SellersHome)}
              />
              <Route
                path={routes.sellers_home_old}
                component={withTracker(SellersHomeOld)}
              />

              <Route path={routes.site_map} component={withTracker(SiteMap)} />
              <Route
                path={routes.company_directory_country_company}
                component={withTracker(CompanyDirectoryCountryRedirect)}
              />
              {/*
          temp hide company inner page
          <Route
            path={routes.company_directory_country_company}
            component={withTracker(CompanyDirectoryCountryCompany)}
          /> */}
              <Route
                path={routes.company_directory_country}
                component={withTracker(CompanyDirectoryCountry)}
              />
              <Route
                path={routes.company_directory}
                component={withTracker(CompanyDirectory)}
              />
              <Route
                path={routes.pharmaceutical_manufacturers}
                component={withTracker(PharmaceuticalManufacturers)}
              />
              <Route
                path={routes.pharmaceutical_manufacturers_countries}
                component={withTracker(ManufacturersCountries)}
              />
              <Route
                path={routes.pharmaceutical_manufacturers_country_page}
                component={withTracker(ManufacturersInCountry)}
              />
              <Route
                path={routes.pharmaceutical_manufacturers_country}
                component={withTracker(ManufacturersInCountry)}
              />
              <Route
                path={routes.fast_track_program}
                component={withTracker(FastTrackProgram)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.marketplaceSearchCountryPage}
                component={withTracker(Marketplace)}
              />
              <ConnectedRoute
                countFetch={true}
                path={routes.marketplaceSearchCountry}
                component={withTracker(Marketplace)}
              />
              {/*
              <ConnectedRoute
                countFetch={true}
                path={routes.marketplaceSearchPage}
                component={withTracker(Marketplace)}
              />
               <ConnectedRoute
                countFetch={true}
                path={routes.marketplaceSearch}
                component={withTracker(Marketplace)}
              />
               <Route component={withTracker(PageNotFound)} /> */}

              {/* fall back for all offers urls */}
              <Redirect from={routes.myoffers} to={routes.home} />
              <Redirect from={routes.new_offer_route} to={routes.home} />
              <Redirect from={routes.new_offer} to={routes.home} />
              <Redirect from={routes.new_offer_trade_route} to={routes.home} />
              <Redirect from={routes.new_offer_trade} to={routes.home} />
              <Redirect
                from={routes.update_offer_trade_route}
                to={routes.home}
              />
              <Redirect from={routes.update_offer_trade} to={routes.home} />
              <Redirect from={routes.update_offer_route} to={routes.home} />
              <Redirect from={routes.update_offer} to={routes.home} />
              <Redirect from={routes.offer} to={routes.home} />
              <Redirect from={routes.offer_trade} to={routes.home} />
              <Redirect from={routes.offer_old} to={routes.home} />
              <Redirect from={routes.offer_old_1} to={routes.home} />
              <Redirect from={routes.offer_trade_old} to={routes.home} />
            </Switch>
            {/* if we want to re-enable some day */}
            {/* <TwinGlobalStyle /> */}
            <GlobalStyle />
            <CreateDealModal />
            <EditDealModal />
            <VerifyEmailModal />
            <CreateAccountModal />
            <BuyerQualificationModal />
            <SellerInformationModal />
            <AdminBuyerQualificationViewModal />
          </>
        </ThemeProvider>
      </FeatureToggleProvider>
    </>
  );
};

interface IOwnProps {
  countFetch?: boolean;
}

const ConnectedRoute: React.FC<RouteProps & IOwnProps> = (props) => {
  const dispatch = useDispatch();
  const client = useApolloClient();
  const location = useLocation();

  if (
    location.pathname === routes.marketplaceSearch &&
    !location.pathname.includes('-manufacturers')
  ) {
    return <Route component={withTracker(PageNotFound)} />;
  }

  return (
    <Route
      {...props}
      render={(r) => {
        if (props.countFetch) {
          dispatch(requestFetchNavCount({ client }));
        }

        const C: any = props.component;
        return <C {...r} />;
      }}
    />
  );
};

export default App;
