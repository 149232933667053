import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';
import { DealUtils } from '../../query-utils';

export interface IUpdateDealComments extends IWithApolloClient {
  id: number;
  comments: string;
}
export interface IUpdateDealCommentsQueryReturn {
  update_deals: {
    returning: {
      id: number;
      comments: string;
    }[];
  };
}
export async function updateDealCommentsQuery(p: IUpdateDealComments) {
  try {
    return await p.client.mutate<IUpdateDealCommentsQueryReturn>({
      mutation: gql`
          mutation updateDealComments(${DealUtils.params(
            ['$id', 'Int'],
            ['$comments', 'String'],
            ['$date', 'timestamptz']
          )}) {
              update_deals(
              ${DealUtils.where(DealUtils._eq('id', '$id'))}
              ${DealUtils._set(
                ['comments', '$comments'],
                ['updated_at', '$date']
              )}
              ) {
              returning {
                  ${DealUtils.selectParams('id', 'comments')}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        comments: p.comments,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> updateDealCommentsQuery',
      e
    );
  }
}
