import round from 'lodash/round';

const ONE_HUNDRED = 100;
const PRECISION = 2;

export function calculatePercentage(net: string, gross: string) {
  const netValue = parseFloat(net);
  const grossValue = parseFloat(gross);

  let percentage = 0;

  if (!isNaN(netValue) && !isNaN(grossValue)) {
    percentage = round((1 - netValue / grossValue) * ONE_HUNDRED, PRECISION);
  }

  return percentage;
}
