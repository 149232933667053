import { createActionCreator as c } from '@reduxify/utils';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IManufacturerTypesInCountry } from './types';

const hasFetched = c<boolean>('manufacturers/SET_FETCHED');
export { hasFetched };

const isFetching = c<boolean>('manufacturers/SET_FETCHING');
export { isFetching };

const fetchCountries = c<IWithApolloClient>('manufacturers/FETCH_COUNTRIES');
export { fetchCountries };

const setCountriesList = c<string[]>('manufacturers/SET_COUNTRIES_LIST');
export { setCountriesList };

const fetchManufacturerTypesInCountry = c<
  IWithApolloClient<{
    countryId: string;
    pageNr: number;
    pageSize: number;
  }>
>('manufacturers/FETCH_MANUFACTURER_TYPES_IN_COUNTRY');
export { fetchManufacturerTypesInCountry };

const setManufacturerTypesInCountry = c<IManufacturerTypesInCountry>(
  'manufacturers/SET_MANUFACTURER_TYPES_IN_COUNTRY'
);
export { setManufacturerTypesInCountry };
