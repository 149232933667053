import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import request from 'app/utils/request';
import CryptoJS from 'crypto-js';

const OMNI_USER_QUERY_PARAM_KEY = 'omnisendContactID';
const OMNI_USER_EMAIL_KEY = 'oe';

const getEncryptedEmail = (email: string) => {
  if (email) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(email));
  }
  return email;
};

const getRealEmail = (email: string | null) => {
  if (email) {
    return CryptoJS.enc.Base64.parse(email).toString(CryptoJS.enc.Utf8);
  }
  return email;
};

export const getOmnisendUserEmail = () => {
  let email = undefined;
  if (window && window.localStorage) {
    email = window.localStorage.getItem(OMNI_USER_EMAIL_KEY);
    email = email && getRealEmail(JSON.parse(email));
  }
  return email;
};

export const useQueryParam = (paramName: string) => {
  const location = useLocation();
  const [state, setState] = useState<string | null>(null);
  useEffect(() => {
    const routerQuery = new URLSearchParams(location.search);
    const paramValue = routerQuery.get(paramName);
    if (paramValue && paramValue.trim()) {
      setState(paramValue.trim());
    }
  }, [location, paramName]);

  return state;
};

export const useLocalStorage = (key: string, initialValue: any) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback((value: any) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  }, []);

  return [storedValue, setValue];
};

export const useOmnisendUser = (xAPIKey: string) => {
  const queryParamValue = useQueryParam(OMNI_USER_QUERY_PARAM_KEY);
  const [ouid, setOUID] = useLocalStorage(OMNI_USER_QUERY_PARAM_KEY, null);
  const [oEmail, setOEmail] = useLocalStorage(OMNI_USER_EMAIL_KEY, null);

  const options = useMemo(() => {
    return {
      headers: { 'x-api-key': xAPIKey },
    };
  }, [xAPIKey]);

  useEffect(() => {
    if (queryParamValue && ouid != queryParamValue) {
      setOUID(queryParamValue);
    }
  }, [queryParamValue, ouid]);

  useEffect(() => {
    if (!oEmail && ouid) {
      request(`https://api.omnisend.com/v3/contacts/${ouid}`, options)
        .get()
        .res((res) => {
          if (!res.ok) {
            return {};
          }
          return res.json();
        })
        .then((data: any) => {
          const email = data?.email?.trim();
          if (email && oEmail !== email) {
            setOEmail(getEncryptedEmail(email));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [ouid, xAPIKey, options]);

  return oEmail;
};
