// custom imports
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';

import {
  createSellerContact,
  fetchSellerContactList,
  fetchSellerInformation,
  setSellerContactInformation,
  setSellerInformation,
  updateCDASignedInformation,
  updateClientNameRequired,
  updateCompanyHiddenStatus,
  updateCPASignedInformation,
  updateRemarks,
  updateCompanySummary,
  updateSellerCommission,
  updateSellerContact,
  updateSellerCompanyNameAndCountry,
} from 'app/store/modules/seller-information/actions';
import {
  deleteSellerContactMutation,
  getCompanyDetailsById,
  getSellerContactInformationByCompanyId,
} from 'app/store/modules/seller-information/query';
import {
  ISellerContactInformation,
  ISellerInformation,
} from 'app/store/modules/seller-information/types';
import sg from 'app/utils/safe-get';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import SellerInformation from './seller-information';
export interface ICreateContactPerson {
  companyId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  assignedMarketsName: string;
  assignMarket: string;
}
export interface IUpdateContactPerson {
  id: number;
  companyId: number;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  assignedMarketsName: string;
  assignMarket: string;
}
interface IDispatchProps {
  updateSellerCommission: (
    companyId: number,
    sellerPaysCommission: boolean,
    licenseFee: number,
    supplyPrice: number
  ) => any;
  getCompanyDetailsById: (companyId: number, isAdmin: boolean) => any;
  setSellerCompanyDetails: (company: ISellerInformation) => any;
  setSellerContactList: (seller: ISellerContactInformation[]) => any;
  updateClientNameRequiredStatus: (
    companyId: number,
    isEarlyBuyerDisclosureRequired: boolean
  ) => any;
  updateCompanyHiddenStatus: (
    companyId: number,
    isCompanyHidden: boolean
  ) => any;
  updateCDASignedDetails: (
    companyId: number,
    CDASigned: boolean,
    signedOn: Date | undefined,
    expireOn: Date | undefined
  ) => any;
  updateCPASignedDetails: (
    companyId: number,
    CPASigned: boolean,
    signedOn: Date | undefined
  ) => any;
  updateSellerNameAndCountry: (
    companyId: number,
    name: string,
    country: string
  ) => any;
  updateRemarks: (companyId: number, remarks: string) => any;
  updateCompanySummary: (companyId: number, summary: string) => any;
  getAllSellerContactByCompanyId: (companyId: number, isAdmin: boolean) => any;
  createSellerContact: (person: ICreateContactPerson) => any;
  updateSellerContact: (person: IUpdateContactPerson) => any;
  deleteSellerContact: (id: number, companyId: number) => any;
}

interface IStateProps {
  isAdmin: boolean;
  sellerInformationModalOpen: boolean;
  editSellerCompanyId: number;
  sellerInformationDetails: ISellerInformation | null;
  updateCache: any;
  productOwnerId: number;
}

interface IOwnProps {
  id?: number;
  closeModal: () => any;
  isEdit: boolean;
}
type ComposedProps = IOwnProps & IWithApolloClient & RouteComponentProps;
export type IProps = IStateProps &
  IDispatchProps &
  ComposedProps &
  RouteComponentProps;

export default withApollo<IOwnProps>(
  // @ts-ignore
  withRouter(
    connect<IStateProps, IDispatchProps, ComposedProps, IStore>(
      (state) => {
        return {
          isAdmin: state.auth.loggedInUser.isAdmin,
          sellerInformationModalOpen:
            state.sellerInformation.sellerInformationModalOpen,
          editSellerCompanyId: state.sellerInformation.sellerCompanyId,
          sellerInformationDetails: state.sellerInformation.sellerInformation,
          updateCache: state.sellerInformation.updateCache,
          productOwnerId: state.sellerInformation.productOwnerId,
        };
      },
      (dispatch, ownProps) => ({
        getCompanyDetailsById: async (companyId: number, isAdmin: boolean) => {
          const res = await getCompanyDetailsById({
            client: ownProps.client,
            id: companyId,
            isAdmin,
          });
          return sg(() => res!.data.company[0], undefined);
        },
        setSellerCompanyDetails: (company: ISellerInformation) => {
          dispatch(setSellerInformation(company));
        },
        setSellerContactList: (seller: ISellerContactInformation[]) => {
          dispatch(setSellerContactInformation(seller));
        },
        getSellerInformation: (companyId: number) => {
          dispatch(
            fetchSellerInformation({ client: ownProps.client, id: companyId })
          );
        },
        updateSellerCommission: (
          companyId: number,
          sellerPaysCommission: boolean,
          licenseFee: number,
          supplyPrice: number
        ) => {
          dispatch(
            updateSellerCommission({
              client: ownProps.client,
              id: companyId,
              sellerPaysCommission,
              licenseFee,
              supplyPrice,
            })
          );
        },
        updateClientNameRequiredStatus: (
          companyId: number,
          isEarlyBuyerDisclosureRequired: boolean
        ) => {
          dispatch(
            updateClientNameRequired({
              client: ownProps.client,
              id: companyId,
              isEarlyBuyerDisclosureRequired,
            })
          );
        },
        updateCompanyHiddenStatus: (
          companyId: number,
          isCompanyHidden: boolean
        ) => {
          dispatch(
            updateCompanyHiddenStatus({
              client: ownProps.client,
              id: companyId,
              isCompanyHidden,
            })
          );
        },
        updateCDASignedDetails: (
          companyId: number,
          CDASigned: boolean,
          signedOn: Date | undefined,
          expireOn: Date | undefined
        ) => {
          dispatch(
            updateCDASignedInformation({
              client: ownProps.client,
              id: companyId,
              isSellerCDASigned: CDASigned,
              sellerCDASignatureDate: signedOn,
              sellerCDAExpiryDate: expireOn,
            })
          );
        },
        updateSellerNameAndCountry: (
          companyId: number,
          name: string,
          country: string
        ) => {
          dispatch(
            updateSellerCompanyNameAndCountry({
              client: ownProps.client,
              id: companyId,
              name: name,
              country: country,
            })
          );
        },
        updateCPASignedDetails: (
          companyId: number,
          CPASigned: boolean,
          signedOn: Date | undefined
        ) => {
          dispatch(
            updateCPASignedInformation({
              client: ownProps.client,
              id: companyId,
              isCPASigned: CPASigned,
              CPASignatureDate: signedOn,
            })
          );
        },
        updateRemarks: (companyId: number, remarks: string) => {
          dispatch(
            updateRemarks({
              client: ownProps.client,
              companyId,
              remarks,
            })
          );
        },
        updateCompanySummary: (companyId: number, summary: string) => {
          dispatch(
            updateCompanySummary({
              client: ownProps.client,
              companyId,
              summary,
            })
          );
        },
        getAllSellerContactByCompanyId: async (
          companyId: number,
          isAdmin: boolean
        ) => {
          const res = await getSellerContactInformationByCompanyId({
            client: ownProps.client,
            companyId,
            isAdmin,
          });
          return sg(() => res!.data.contact_person, undefined);
        },
        createSellerContact: (seller: ICreateContactPerson) => {
          dispatch(
            createSellerContact({
              client: ownProps.client,
              companyId: seller.companyId,
              firstName: seller.firstName,
              lastName: seller.lastName,
              email: seller.email,
              phoneNumber: seller.phoneNumber,
              assignedMarketsName: seller.assignedMarketsName,
              assignMarket: seller.assignMarket,
            })
          );
        },
        updateSellerContact: (seller: IUpdateContactPerson) => {
          dispatch(
            updateSellerContact({
              client: ownProps.client,
              id: seller.id,
              companyId: seller.companyId,
              firstName: seller.firstName,
              lastName: seller.lastName,
              email: seller.email,
              phoneNumber: seller.phoneNumber,
              assignedMarketsName: seller.assignedMarketsName,
              assignMarket: seller.assignMarket,
            })
          );
        },
        deleteSellerContact: async (id: number, companyId: number) => {
          await deleteSellerContactMutation({
            client: ownProps.client,
            id,
          });
          dispatch(
            fetchSellerContactList({ client: ownProps.client, id: companyId })
          );
        },
      })
    )(SellerInformation)
  )
);
