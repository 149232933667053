import MarketplaceButton, {
  MarketplaceButtonStyle,
} from './marketplace-button';
export { MarketplaceButtonStyle };
import { IWithApolloClient } from 'app/store/modules/global/types';
import { filterMarketplace } from 'app/store/modules/marketplace/actions';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';

interface IDispatchProps {
  clearFilter: () => any;
}
interface IOwnProps {
  category?: string;
  action?: string;
  fullWidth?: boolean;
  customLink?: string;
  buttonWidth?: number;
}
export interface IProps extends IDispatchProps, IOwnProps, IWithApolloClient {}
export default withApollo<any>(
  connect<any, IDispatchProps, IOwnProps & IWithApolloClient, any>(
    null,
    (dispatch, ownProps) => ({
      clearFilter: () => {
        dispatch(
          filterMarketplace({
            filter: {
              isNavbar: false,
              product: '',
              productId: undefined,
            },
            client: ownProps.client,
          })
        );
      },
    })
  )(MarketplaceButton)
);
