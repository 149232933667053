import Checkbox from 'app/components/inputs/check-box';
import TextInput from 'app/components/inputs/text-input-bordered';
import ErrorNotification from 'app/components/notifications/error-oval';
import { _, media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IProps } from './index';
import { sg } from '../../../../utils/safe-get';
import { ConditionalContainer } from '../../../../components/misc/conditional-container';
import TrustedByCompanies from '../trusted-by-companies';
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import { anonymousUserId } from 'app/config';
import isBrowser from 'app/store/utils/is-browser';

const CreateAccountContainer = styled.div`
  width: 100%;
  max-width: 920px;
  background-color: ${(props) => props.theme.colors.background};
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const CreateAccountFormWrapper = styled.div<{ isModal?: boolean }>`
  border-radius: 6px;
  ${(props) => (props.isModal ? 'margin-top: 0;' : 'margin-top: 35px;')}
  ${_(media.min.md)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 63px;')}
    padding-top: 0;
  }
  ${_(media.max.tablet)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 43px;')}
    padding-bottom: 40px;
    padding-top: 40px;
  }
  ${_(media.max.sm)} {
    ${(props) => (props.isModal ? 'margin-bottom: 0;' : 'margin-bottom: 37px;')}
    padding-bottom: 20px;
    padding-top: 20px;
  }
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.isModal
      ? ''
      : `box-shadow: 0 8px 24px 0 ${props.theme.colors.shadow};`}
`;

const CreateAccountFormContainer = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;

  ${_(media.max.sm)} {
    width: 100%;
  }
`;

const CreateAccountHeader = styled.div`
  ${_(media.min.md)} {
    margin-top: 40px;
  }
  padding-left: 42px;
  padding-right: 56px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const CreateAccountSubHeader = styled.div`
  margin-top: 4px;
  padding-left: 42px;
  padding-right: 56px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const InputContainer = styled.div<{ isModal?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 42px;
  padding-right: 56px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const CreateAccountButton = styled.div`
  height: 50px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.warning};
  width: 100%;
  margin-top: 19px;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

const PipelinepharmaLogo = styled.img`
  width: 193px;
  height: 34px;
  object-fit: contain;
  margin-top: 36px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${_(media.max.sm)} {
    flex-direction: column;
  }
`;

const FirstNameWrapper = styled.div`
  display: flex;
  margin-right: 13px;
  ${_(media.max.sm)} {
    margin-right: 0;
  }
  flex: 1;
`;

const LastNameWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const TrustedByCompaniesWrapper = styled.div`
  ${_(media.min.md)} {
    display: flex;
  }
  ${_(media.max.tablet)} {
    display: none;
  }
`;

const TrustedByCompaniesBottomWrapper = styled.div`
  ${_(media.min.md)} {
    display: none;
  }
  ${_(media.max.tablet)} {
    display: flex;
    justify-content: center;
  }
`;

const LabelStyle = styled.label`
  font-size: 15px;
  pointer-events: all;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  z-index: 1;
`;

const ErrorLine = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.errorMessage};
`;

const ErrorIcon = styled.img`
  width: 10px;
  height: 9px;
  object-fit: contain;
`;

export const companyNameErrorMessage = 'Company name is required';
export const firstNameErrorMessage = 'First name is required';
export const lastNameErrorMessage = 'Last name is required';
export const phoneNumberErrorMessage = 'Phone number is required';
export const phoneNumberCountryCodeErrorMessage =
  'Phone number has to include country code';
export const selectInterestErrorMessage = 'Select your interest';

const CreateAccount: React.FC<IProps> = ({
  register,
  error,
  clearRegisterError,
  isModal,
  loggedInUser,
  ...props
}) => {
  const [companyName, setCompanyName] = useState(
    loggedInUser &&
      loggedInUser.Company &&
      loggedInUser.Company.name !== loggedInUser.email &&
      loggedInUser.Company.name !== `${loggedInUser.email} default company`
      ? loggedInUser.Company.name
      : ''
  );
  const [companyNameError, setCompanyNameError] = useState('');

  const [firstName, setFirstName] = useState(
    loggedInUser && loggedInUser.firstName ? loggedInUser.firstName : ''
  );
  const [firstNameError, setFirstNameError] = useState('');

  const [lastName, setLastName] = useState(
    loggedInUser && loggedInUser.lastName ? loggedInUser.lastName : ''
  );
  const [lastNameError, setLastNameError] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const [formError, setFormError] = useState('');

  const [isSeller, setIsSeller] = useState(
    loggedInUser ? loggedInUser.isSeller : false
  );
  const [isBuyer, setIsBuyer] = useState(
    loggedInUser ? loggedInUser.isBuyer : false
  );
  const [userTypeError, setUserTypeError] = useState('');

  useEffect(() => {
    if (
      (error !== '' && formError === '') ||
      (formError !== '' && error !== formError && error !== '')
    ) {
      setFormError(error);
      clearRegisterError();
    }
  }, [error]);

  const validate = () => {
    let valid = true;

    if (companyName === '') {
      setCompanyNameError(companyNameErrorMessage);
      valid = false;
    }

    if (firstName === '') {
      setFirstNameError(firstNameErrorMessage);
      valid = false;
    }

    if (lastName === '') {
      setLastNameError(lastNameErrorMessage);
      valid = false;
    }
    if (phoneNumber === '') {
      setPhoneNumberError(phoneNumberErrorMessage);
      valid = false;
    }
    const twoSymbols = 2;
    if (
      phoneNumber !== '' &&
      phoneNumber.length >= twoSymbols &&
      phoneNumber[0] !== '+' &&
      phoneNumber.substr(0, twoSymbols) !== '00'
    ) {
      setPhoneNumberError(phoneNumberCountryCodeErrorMessage);
      valid = false;
    }

    if (!(isSeller || isBuyer)) {
      setUserTypeError(selectInterestErrorMessage);
      valid = false;
    }

    return valid;
  };

  return (
    <div>
      <Container isModal={isModal || false}>
        <CreateAccountFormWrapper isModal={isModal || false}>
          <CreateAccountFormContainer>
            <CreateAccountHeader>Thanks for signing up!</CreateAccountHeader>
            <CreateAccountSubHeader>
              We&apos;ll just need a few more details and you&apos;ll be ready
              to go.
            </CreateAccountSubHeader>
            <InputContainer isModal={isModal}>
              <form>
                <ConditionalContainer
                  show={sg(() => formError.length !== 0, false)}
                >
                  <ErrorNotification label={formError} />
                </ConditionalContainer>
                <LabelStyle>I am interested in:</LabelStyle>
                <NameContainer>
                  <FirstNameWrapper>
                    <Checkbox
                      label={'In-licensing/purchasing '}
                      value={isBuyer}
                      onChange={(val) => {
                        setIsBuyer(val);
                        if (isSeller || val) {
                          setUserTypeError('');
                        }
                      }}
                      marginRight={'0px'}
                      marginTop={'7px'}
                      id={'is-buyer'}
                      backgroundColor={colors.white}
                      isError={userTypeError !== ''}
                      checkOnTextClick={true}
                    />
                  </FirstNameWrapper>
                  <LastNameWrapper>
                    <Checkbox
                      label={'Out-licensing/supplying'}
                      value={isSeller}
                      onChange={(val) => {
                        setIsSeller(val);
                        if (val || isBuyer) {
                          setUserTypeError('');
                        }
                      }}
                      marginRight={'0px'}
                      marginTop={'7px'}
                      id={'is-seller'}
                      backgroundColor={colors.white}
                      isError={userTypeError !== ''}
                      checkOnTextClick={true}
                    />
                  </LastNameWrapper>
                </NameContainer>
                <ConditionalContainer
                  show={sg(() => userTypeError.length !== 0, false)}
                >
                  <ErrorLine>
                    <ErrorMessage>{userTypeError}</ErrorMessage>
                    <ErrorIcon src={'/img/formError.png'} />
                  </ErrorLine>
                </ConditionalContainer>

                <NameContainer>
                  <FirstNameWrapper>
                    <TextInput
                      id={'first-name'}
                      value={firstName}
                      label={'First name'}
                      onChange={(text: string) => {
                        setFirstName(text);
                        if (firstName !== '') {
                          setFirstNameError('');
                        }
                      }}
                      inputMarginTop={'9px'}
                      marginTop={'20px'}
                      error={firstNameError}
                    />
                  </FirstNameWrapper>
                  <LastNameWrapper>
                    <TextInput
                      id={'last-name'}
                      value={lastName}
                      label={'Last name'}
                      onChange={(text: string) => {
                        setLastName(text);
                        if (lastName !== '') {
                          setLastNameError('');
                        }
                      }}
                      inputMarginTop={'9px'}
                      marginTop={'20px'}
                      error={lastNameError}
                    />
                  </LastNameWrapper>
                </NameContainer>

                <TextInput
                  id={'company-name'}
                  value={companyName}
                  label={'Company name'}
                  onChange={(text: string) => {
                    setCompanyName(text);
                    if (companyName !== '') {
                      setCompanyNameError('');
                    }
                  }}
                  inputMarginTop={'9px'}
                  marginTop={'20px'}
                  error={companyNameError}
                />
                <TextInput
                  id={'phone-number'}
                  value={phoneNumber}
                  label={'Phone number including country code'}
                  onChange={(text: string) => {
                    setPhoneNumber(text);
                    if (phoneNumber !== '') {
                      setPhoneNumberError('');
                    }
                  }}
                  placeholder={'e.g. +44 0922 222676'}
                  error={phoneNumberError}
                  inputMarginTop={'9px'}
                  marginTop={'20px'}
                />

                <CreateAccountButton
                  data-testid="create-account-button"
                  onClick={() => {
                    if (validate()) {
                      register(
                        firstName,
                        lastName,
                        companyName,
                        phoneNumber,
                        isBuyer,
                        isSeller,
                        isModal
                      );
                      window.scrollTo(0, 0);
                      console.log(
                        GAActions ? GAActions.RFQ_CREATE_MY_ACCOUNT : ''
                      );
                      const uuid =
                        isBrowser() && localStorage.getItem(anonymousUserId);

                      ReactGA.event({
                        category: GACategory ? GACategory.RFQ : '',
                        action: GAActions
                          ? GAActions.RFQ_CREATE_MY_ACCOUNT
                          : '',
                        label: undefined,
                        anonymousUserId: uuid,
                      });
                    }
                  }}
                >
                  Create my account
                </CreateAccountButton>
              </form>
            </InputContainer>
          </CreateAccountFormContainer>
          <TrustedByCompaniesWrapper>
            <TrustedByCompanies isModal={isModal} />
          </TrustedByCompaniesWrapper>
        </CreateAccountFormWrapper>
      </Container>
      {!isModal && (
        <TrustedByCompaniesBottomWrapper>
          <TrustedByCompanies isModal={isModal} />
        </TrustedByCompaniesBottomWrapper>
      )}
    </div>
  );
};

const Container: React.FC<{ isModal: boolean }> = ({ children, isModal }) =>
  isModal ? (
    <>{children}</>
  ) : (
    <CreateAccountContainer>
      <PipelinepharmaLogo src={'/img/pp-logo-rebranded/logo-03.svg'} />
      {children}
    </CreateAccountContainer>
  );
export default CreateAccount;
