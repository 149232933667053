import { _, media } from 'app/styles/breakpoint';
import { createGlobalStyle } from 'styled-components';

//
const GlobalStyle = createGlobalStyle<any>`
  html {
    box-sizing: border-box;
  }

  body, h1, h2, h3, p, div, button, input, span {
    font-family: 'Nunito Sans', 'sans-serif';
  }

  button {
    background-color: inherit;
    border-style: solid;
  }

  h1 {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }

  body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
  }
  #app {
    min-height: 100vh;
    min-width: 100%;
    display: flex;
    flex-direction: column;
  }
  #app > div {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  body.ReactModal__Body--open  {
    overflow: hidden;
  }

  a{
    text-decoration: none;
    color: #606b7c;
  }

  .paper {
    width: 100%;
    ${_(media.min.lg)} {
      max-width: 1180px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 0px;
      padding-right: 0px;
    }
    ${_(media.max.md)} {
      padding-left: 42px;
      padding-right: 42px;
    }
    ${_(media.max.sm)} {
      padding-left: 34px;
      padding-right: 34px;
    }
    ${_(media.max.xs)} {
      padding-left: 20px;
      padding-right: 20px;
    }
    ${_(media.max.xs)} {
      min-width: 250px;
    }
  }
`;

export default GlobalStyle;
