/* eslint-disable no-magic-numbers */

import { _, media } from 'app/styles/breakpoint';
import styled from 'styled-components';

export const DashboardContainer = styled.div`
  padding: 34px;

  ${_(media.max.custom(1399))} {
    padding: 15px;
  }
  ${_(media.max.sm)} {
    padding: 0px;
  }
`;
