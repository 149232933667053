import { format } from 'date-fns';

export function formatDateString(
  dateString: string | Date,
  dateFormat = 'yyyy-MM-dd'
) {
  return format(new Date(dateString), dateFormat);
}

export function formatDate(dateString: Date, dateFormat = 'yyyy-MM-dd') {
  return format(dateString, dateFormat);
}
