import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { companyFragment } from '../../global/fragments/company';
import { IWithApolloClient } from '../../global/types';
import { IProduct, productFragment } from '../../products/fragments/product';
import { ProductFilter } from '../../products/types';
import * as u from '../../query-utils';

export interface IGetProductList extends IWithApolloClient {
  size: number;
  offset: number;
  companyId: number;
  isAdmin: boolean;
  filter?: ProductFilter;
}
export interface IGetProductListReturn {
  products: {
    Product: IProduct;
    ranking: number;
    updatedAt: Date;
    productId: number;
  }[];
  products_aggregate: { aggregate: { totalCount: number } };
}

export async function getProductListQueryForRanking(p: IGetProductList) {
  try {
    const where = u.ProductListUtils.where(
      u.ProductListUtils._or(
        u.ProductListUtils.nest(
          'Product',
          u.ProductUtils._and(
            u.ProductUtils._is_null('deleted_at'),
            u.ProductUtils._is_null('form_specification', false),
            u.ProductUtils._is_null('strength', false)
          )
        )
      )
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetProductListReturn>({
      query: gql`
        ${productFragment.fragment(p.isAdmin)}
        ${companyFragment.fragment(p.isAdmin)}

        query productListPage(${u.ProductListUtils.params(
          ['$size', 'Int'],
          ['$offset', 'Int']
        )}) {
        products: my_products_list(
          ${u.ProductListUtils.limit('$size')}
          ${u.ProductListUtils.offset('$offset')}
          ${u.ProductListUtils.orderBy('updated_at', 'desc')}
          ${where}
        ) {
          ${u.ProductListUtils.selectParams(
            'ranking',
            ['updated_at', 'updatedAt'],
            ['productid', 'productId'],
            [
              'Product',
              true,
              u.ProductUtils.selectParams(productFragment, [
                'Company',
                true,
                companyFragment,
              ]),
            ]
          )}
        }
        products_aggregate: my_products_list_aggregate(${where}) {
          aggregate {
            totalCount: count
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        size: p.size,
        offset: p.offset,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getProductListQuery',
      e
    );
  }
}

export async function getTotalCount(p: IGetProductList) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetProductListReturn>({
      query: gql`
        query getTotalCountForRankingUpdate {
          products_v2_aggregate: my_products_list_aggregate(
            where: {
              _or: [
                {
                  Product: {
                    _and: [
                      { deleted_at: { _is_null: true } }
                      { form_specification: { _is_null: false } }
                      { strength: { _is_null: false } }
                      {
                        _and: [
                          { Company: { _and: [{ name: { _ilike: "%" } }] } }
                        ]
                      }
                      {
                        _or: [
                          { Substance1: { name: { _ilike: "%" } } }
                          { Substance2: { name: { _ilike: "%" } } }
                          { Substance3: { name: { _ilike: "%" } } }
                        ]
                      }
                      { form_specification: { _ilike: "%" } }
                    ]
                  }
                }
              ]
            }
          ) {
            aggregate {
              totalCount: count
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getProductListQueryRanking',
      e
    );
    return null;
  }
}
