import React from 'react';

export const AnchorLink: React.FC<{ href: string; [x: string]: any }> = ({
  href,
  children,
  ...props
}) => (
  <a href={href} {...props}>
    {children}
  </a>
);
