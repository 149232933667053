import { createReducer, merge, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import * as A from './actions';
import { IManufacturerTypesInCountry } from './types';

export default combineReducers({
  hasFetched: createReducer<boolean>(false, reduce(A.hasFetched, set)),
  isFetching: createReducer<boolean>(false, reduce(A.isFetching, set)),
  countriesList: createReducer<string[]>([], reduce(A.setCountriesList, set)),
  manufacturerTypesInCountry: createReducer<IManufacturerTypesInCountry>(
    {},
    reduce(A.setManufacturerTypesInCountry, merge)
  ),
});
