import { media } from 'app/styles/breakpoint';
import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const CancelButtonContainer = styled.div`
  .tooltip-style {
    width: 305px;
    border: solid 1px ${(props) => props.theme.colors.grayLight} !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.18px;
    color: ${(props) => props.theme.colors.grayExtraDark};
  }
  .place-top::before {
    border-top: 8px solid ${(props) => props.theme.colors.grayLight} !important;
  }
`;

export const CancelButtonStyle = styled.button`
  width: 140px;
  height: 42px;
  border-radius: 3px;
  border: 2px solid ${(props) => props.theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  padding-left: 10px;
  padding-right: 10px;
  color: ${(props) => props.theme.colors.accent};
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.accentLight};
  }

  ${media.max.sm`
    width: 100%;

  `}
`;
export interface CancelButtonProps {
  onClick?: () => any;
  label?: string;
  type?: 'reset' | 'submit';
  tooltipText?: string;
  id?: string;
}

const CancelButton: React.FC<CancelButtonProps> = ({
  onClick,
  label = 'Cancel',
  type,
  tooltipText,
  id,
}) => {
  const tooltipId = `${label}-${id}`;

  return (
    <CancelButtonContainer onClick={onClick}>
      {tooltipText && (
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline={true}
          className="tooltip-style"
          type="light"
          offset={{ left: 50 }}
          border={true}
        >
          {tooltipText}
        </ReactTooltip>
      )}
      <CancelButtonStyle type={type} data-tip={true} data-for={tooltipId}>
        {label}
      </CancelButtonStyle>
    </CancelButtonContainer>
  );
};

export default CancelButton;
