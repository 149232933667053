import { useDidUpdate, useMemory } from 'app/utils/hooks';
import * as React from 'react';

interface IProps {
  show: boolean;
  children: any;
  appearDelay?: number;
  disappearDelay?: number;

  appeared?: () => void;
  disappeared?: () => void;

  beforeAppear?: () => void;
  beforeDisapear?: () => void;
}

const ConditionalContainer: React.FC<IProps> = (props) => {
  const [, setTimeoutValue] = useMemory<NodeJS.Timeout | undefined>(
    undefined,
    (tm) => {
      if (tm) {
        clearTimeout(tm);
      }
    }
  );
  const [changeRender, setChangeRender] = React.useState(props.show);

  useDidUpdate(() => {
    changeState(
      props.show,
      props.show ? props.appearDelay : props.disappearDelay
    );
  }, [props.show]);

  useDidUpdate(() => {
    if (changeRender && props.appeared) {
      props.appeared();
    } else if (!changeRender && props.disappeared) {
      props.disappeared();
    }
  }, [changeRender]);

  function changeState(state: boolean, delay = 0) {
    function changeStateInner(s: boolean) {
      if (s && props.beforeAppear) {
        props.beforeAppear();
      } else if (!s && props.beforeDisapear) {
        props.beforeDisapear();
      }
      setChangeRender(state);
    }
    try {
      if (delay !== 0) {
        setTimeoutValue(
          setTimeout(() => {
            changeStateInner(state);
          }, delay)
        );
      } else {
        changeStateInner(state);
      }
    } catch (error) {
      // suppress error
    }
  }

  return changeRender ? props.children : null;
};

const MemoizedConditionalContainer = React.memo(
  ConditionalContainer,
  (prevProps, props) =>
    prevProps.show === props.show && prevProps.children === props.children
);

export { MemoizedConditionalContainer as ConditionalContainer };
export default MemoizedConditionalContainer;
