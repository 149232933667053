import CancelButton from 'app/components/buttons/cancel-button';
import DeleteButton from 'app/components/buttons/orange-button';
import Modal from 'app/components/modals/overrided-modal';
import { media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React from 'react';
import styled from 'styled-components';

const ModalContainer = styled.div`
  padding: 50px;
  margin: 15px;
  z-index: 100;
  align-items: flex-start;
  ${media.max.lg`
  padding: 30px;
  margin: 5px;

  `}
  ${media.max.md`
padding: 20px;
margin: 0px;

`}
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
`;
const ArchiveIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;
const ArchiveIcon = styled.img`
  width: 54px;
  height: 54px;
  object-fit: contain;
`;
const ArchiveText = styled.p`
  margin-top: 26px;
  font-size: 26px;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-bottom: 0;
`;

const ButtonLine = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  ${media.max.sm`
    flex-direction: column;

  `}
`;
const ButtonDivider = styled.div`
  height: 10px;
  width: 15px;
`;

interface Props {
  archiveCallback: () => any;
  closeModal: () => any;
  isOpen: boolean;
  isRestoring: boolean;
}

const ArchiveModal: React.FC<Props> = ({
  isOpen,
  archiveCallback,
  closeModal,
  isRestoring,
}) => {
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: colors.white,
      borderWidth: '0px',
      // width: '30%',
      padding: 20,
      borderRadius: 3,
      boxShadow: colors.modalShadow,
    },
    overlay: {
      backgroundColor: colors.modalOverlay,
      zIndex: 1000,
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Delete product"
      ariaHideApp={false}
    >
      <IconContainer>
        <div onClick={closeModal}>
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer>
        <ArchiveIconContainer>
          <ArchiveIcon src={'/img/alert.svg'} />
        </ArchiveIconContainer>
        {isRestoring ? (
          <ArchiveText>Are you sure you want to restore this deal?</ArchiveText>
        ) : (
          <ArchiveText>Are you sure you want to archive this deal?</ArchiveText>
        )}

        <ButtonLine>
          <CancelButton onClick={() => closeModal()} />
          <ButtonDivider />
          <DeleteButton
            onClick={() => archiveCallback()}
            label={isRestoring ? 'Restore' : 'Archive'}
          />
        </ButtonLine>
      </ModalContainer>
    </Modal>
  );
};

export default ArchiveModal;
