import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from '@redux-saga/core/effects';
import { ApolloClient } from 'apollo-boost';
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import { anonymousUserId, routes } from 'app/config';
import {
  requestFetchNavCount,
  requestSetOwnsProductStatus,
  requestSetUserType,
} from 'app/store/modules/auth/actions';
import {
  fetchProductSuccess,
  setIsFetching,
  setTotalCount as setMarketplaceTotalCount,
} from 'app/store/modules/marketplace/actions';
import { removeDeletedItem } from 'app/store/modules/marketplace/sagas';
import { IStore } from 'app/store/types';
import { isBrowser } from 'app/store/utils/is-browser';
import { toDictionary } from 'app/utils/dictionary';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import { push } from 'connected-react-router';
import { TypeOrigin, UserTypeEnum } from '../global/types';
import { MarketplaceItemType } from '../marketplace/types';
import {
  addDeleteId as addDeleteIdAction,
  createProduct,
  deleteProduct as deleteProductAction,
  deleteSelected as deleteSelectedAction,
  fetchOnboardingSuccess,
  fetchProducts as fetchProductsAction,
  fetchProductsSuccess,
  filterOnboarding,
  filterProduct,
  getProductNameFull,
  hasFetched,
  removeDeleteId as removeDeleteIdAction,
  requestFetchOnboarding,
  requestFetchProduct,
  requestFetchProducts,
  setCurrentPage,
  setDeleteId,
  setEditModal,
  setNotification,
  setOnboardingFilter,
  setOnboardingIds,
  setProductCache,
  setProductFilter,
  setProductIds,
  setProductTypeFilter,
  setProductTypeFilterAction,
  setStatus,
  setTotalCount,
  setVisibleProductIds,
} from './actions';
import { convertToProduct, IProduct } from './fragments/product';
import {
  createProductQuery,
  deleteProductQuery,
  getAdminOnboardingListQuery,
  getProductListQuery,
  getProductQuery,
} from './query';
import { AnyListItem, Product, ProductForm, Status } from './types';
import { getSellerContactInformationByCompanyIdAndUserId } from '../seller-information/query';
import { createSellerContact } from '../seller-information/actions';

function* fetchProductsSaga(action: ReturnType<typeof requestFetchProducts>) {
  try {
    const {
      client,
      filter,
      companyId,
      isFast,
      isSuggestedProducts,
      pageSize,
      isMarketplaceView,
      sortByField,
      sortType,
    } = action.payload;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { products, auth }: IStore = yield select();
    if (client && auth.loggedInUser) {
      const ms300 = 300;

      if (!isFast) {
        yield delay(ms300);
      }
      yield put(setStatus(Status.loading));

      const response: Unpromisefy<
        ReturnType<typeof getProductListQuery>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield call(getProductListQuery, {
        client,
        size: pageSize || products.pageSize,
        offset: products.currentPage * products.pageSize,
        companyId,
        isAdmin: auth.loggedInUser.isAdmin,
        isSuggestedProducts,
        filter,
        type: products.productsFilter,
        isMarkeplaceView: isMarketplaceView,
        sortByField,
        sortType,
      });
      if (!response) {
        throw new Error('GraphQL returned undefined');
      }
      const transformedData = {
        productList: response.data?.products?.map((p: IProduct) => ({
          ranking: p.productRankingScore, //p.manualRanking || 0 + p.autoRanking || 0,
          productId: p.id,
          updatedAt: p.updatedAt,
          Product: p,
        })),
        totalCount: response.data.products_aggregate.aggregate.totalCount,
      };
      yield put(fetchProductsSuccess(transformedData));
    }
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    yield put(
      setNotification({
        ...state.products.error,
        listError: 'There was an error loading your products.',
      })
    );
  }
}

function* watchFetchData() {
  if (!isBrowser()) {
    return;
  }
  yield takeLatest(requestFetchProducts, fetchProductsSaga);
}

function* fetchSuccess() {
  try {
    if (!isBrowser()) {
      return;
    }
    while (true) {
      const action: ReturnType<typeof fetchProductsSuccess> = yield take(
        fetchProductsSuccess
      );

      const { productList, totalCount } = action.payload;
      const productCache = toDictionary(
        productList
          .filter((x) => !!x.Product)
          .map((v) => convertToProduct(v.Product)),
        (v) => v.id
      );

      const productListItems: AnyListItem[] = productList.map((x) => ({
        ranking: x.ranking,
        productId: x.productId,
        updatedAt: x.updatedAt,
      }));
      yield all([
        put(setProductCache(productCache)),
        put(setProductIds(productListItems)),
        put(setVisibleProductIds(productListItems)),
        put(setTotalCount(totalCount)),
        put(setStatus(Status.idle)),
        put(hasFetched(true)),
      ]);
    }
  } catch (err) {
    logger.err(err);
  }
}

function* setAdminOnboardingFilterSaga() {
  try {
    if (!isBrowser()) {
      return;
    }
    while (true) {
      const action: ReturnType<typeof filterOnboarding> = yield take(
        filterOnboarding
      );
      const { client, filter } = action.payload;
      yield put(setOnboardingFilter(filter));
      yield put(requestFetchOnboarding({ client, filter }));
    }
  } catch (err) {
    logger.err(err);
  }
}

function* watchFetchOnboardingData() {
  if (!isBrowser()) {
    return;
  }
  yield takeLatest(requestFetchOnboarding, fetchAdminOnboardingSaga);
}

function* fetchAdminOnboardingSaga(
  action: ReturnType<typeof requestFetchOnboarding>
) {
  try {
    const { client, filter } = action.payload;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { products, auth }: IStore = yield select();
    if (client && auth.loggedInUser) {
      yield put(setStatus(Status.loading));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(getAdminOnboardingListQuery, {
        client,
        size: products.pageSize,
        offset: products.currentPage * products.pageSize,
        filter,
      });
      if (!response) {
        throw new Error('GraphQL returned undefined');
      }
      yield put(
        fetchOnboardingSuccess({
          onboardingList: response.data.admin_onboarding,
          totalCount:
            response.data.admin_onboarding_aggregate.aggregate.totalCount,
        })
      );
    }
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    yield put(
      setNotification({
        ...state.products.error,
        listError: 'There was an error loading your onboarding.',
      })
    );
  }
}

function* fetchOnboardingSuccessSaga() {
  try {
    if (!isBrowser()) {
      return;
    }
    while (true) {
      const action: ReturnType<typeof fetchOnboardingSuccess> = yield take(
        fetchOnboardingSuccess
      );

      const { onboardingList, totalCount } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state = yield select();

      const productCache = {
        ...state.products.productCache,
        ...toDictionary(
          onboardingList
            .filter((x) => x.Product !== undefined && x.Product !== null)
            .map((v) => convertToProduct(v.Product)),
          (v) => v.id
        ),
      };

      const onboardingListItems: AnyListItem[] = onboardingList.map((x) => ({
        ranking: x.ranking,
        productId: x.productId,
        updatedAt: x.updatedAt,
      }));

      yield all([
        put(setProductCache(productCache)),
        put(setOnboardingIds(onboardingListItems)),
        put(setTotalCount(totalCount)),
        put(setStatus(Status.idle)),
        put(hasFetched(true)),
      ]);
    }
  } catch (err) {
    logger.err(err);
  }
}

function* deleteProductFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof deleteProductAction> = yield take(
      deleteProductAction
    );
    const { client, productId, type } = action.payload;

    yield call(deleteProduct, productId, type, client, false);
  }
}

function* deleteProduct(
  productId?: number,
  type?: number,
  client?: ApolloClient<any>,
  isMulti?: boolean
) {
  try {
    if (!isBrowser()) {
      return;
    }

    if (client && productId && type !== undefined) {
      const isLicensing = type === TypeOrigin.licensing;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore

      const state: IStore = yield select();
      const email = state.auth.loggedInUser
        ? state.auth.loggedInUser.email
        : undefined;
      const anonymousUserIdItem = localStorage.getItem(anonymousUserId);

      ReactGA.event({
        category: GACategory
          ? isLicensing
            ? GACategory.ProductsLicensing
            : GACategory.ProductsTrading
          : '',
        action: GAActions
          ? isLicensing
            ? GAActions.deleteLicensingProduct
            : ''
          : '',
        value: productId,
        email,
        anonymousUserIdItem,
      });
      let ids = [...state.products.productIds];
      let visibleIds = [...state.products.visibleProductIds];
      let onboardingIds = [...state.products.onboardingIds];
      let deleteIds = [...state.products.deleteIds];

      if (
        state.router.location.pathname === routes.marketplace ||
        state.router.location.pathname.includes('-manufacturers')
      ) {
        yield put(setIsFetching(true));

        if (isLicensing) {
          const response: Unpromisefy<
            ReturnType<typeof deleteProductQuery>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          > = yield call(deleteProductQuery, { client, id: productId });

          if (
            !(
              response &&
              response.data &&
              response.data.update_products_v2 &&
              response.data.update_products_v2.returning[0] &&
              response.data.update_products_v2.returning[0].id === productId
            )
          ) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return yield put(setIsFetching(false));
          }
        }

        yield all([
          put(setMarketplaceTotalCount(state.marketplace.totalCount - 1)),
          put(setIsFetching(false)),
        ]);
      }

      if (isLicensing) {
        yield call(deleteProductQuery, { client, id: productId });
      }

      const check = (x: AnyListItem) => {
        return x.productId && x.productId.toString() !== productId.toString();
      };

      ids = ids.filter((x: AnyListItem) => check(x));
      visibleIds = visibleIds.filter((x: AnyListItem) => check(x));
      deleteIds = deleteIds.filter((x: AnyListItem) => check(x));
      onboardingIds = [...onboardingIds.filter((x: AnyListItem) => check(x))];

      yield all([
        put(setProductIds(ids)),
        put(setVisibleProductIds(visibleIds)),
        put(setOnboardingIds(onboardingIds)),
        put(setDeleteId(deleteIds)),
        call(removeDeletedItem, productId, MarketplaceItemType.product, type),
      ]);

      if (!isMulti) {
        const authUser = state.auth.loggedInUser
          ? state.auth.loggedInUser
          : undefined;
        if (authUser) {
          yield put(requestFetchNavCount({ client }));

          yield put(
            requestFetchProducts({
              client,
              companyId: authUser.Company.id,
              filter: state.products.filter,
            })
          );

          if (state.router.location.pathname !== routes.marketplace) {
            yield put(
              setNotification({
                ...state.products.error,
                listSuccess: 'Product deleted successfully',
              })
            );
          }
        }
      } else {
        yield put(requestFetchNavCount({ client }));
        yield put(
          setNotification({
            ...state.products.error,
            listSuccess: 'Product deleted successfully',
          })
        );
      }
    }
  } catch (err) {
    logger.error(
      'src/app/store/modules/products/sagas.ts -> deleteProduct',
      err
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();

    yield put(
      setNotification({
        ...state.products.error,
        listError:
          'There was an error deleting this product. Please try again later.',
      })
    );
  }
}

function* addDeleteIdFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof addDeleteIdAction> = yield take(
      addDeleteIdAction
    );
    const { id } = action.payload;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    const ids: AnyListItem[] = [...state.products.deleteIds, id];
    yield put(setDeleteId(ids));
  }
}

function* removeDeleteIdFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof removeDeleteIdAction> = yield take(
      removeDeleteIdAction
    );
    const { id } = action.payload;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    const ids: AnyListItem[] = [
      ...state.products.deleteIds.filter(
        (x: AnyListItem) => x.productId && x.productId !== id.productId
      ),
    ];
    yield put(setDeleteId(ids));
  }
}

function* deleteSelectedFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof deleteSelectedAction> = yield take(
      deleteSelectedAction
    );
    const { client } = action.payload;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    const idToDelete: AnyListItem[] = [...state.products.deleteIds];

    yield all(
      idToDelete.map((x) => {
        const productId = x.productId ? x.productId : 0;

        const type: TypeOrigin = x.productId
          ? TypeOrigin.licensing
          : TypeOrigin.trade;

        return call(deleteProduct, productId, type, client);
      })
    );

    yield put(setDeleteId([]));
    const authUser = state.auth.loggedInUser
      ? state.auth.loggedInUser
      : undefined;
    if (authUser) {
      yield put(
        requestFetchProducts({
          client,
          companyId: authUser.Company.id,
          filter: state.products.filter,
        })
      );
    }
  }
}

function* filterProductSetFlow() {
  if (!isBrowser()) {
    return;
  } else {
    while (true) {
      const action: ReturnType<typeof filterProduct> = yield take(
        filterProduct
      );
      const { filter, client } = action.payload;
      yield put(setProductFilter(filter));
      yield put(setCurrentPage(0));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state = yield select();
      const authUser = state.auth.loggedInUser
        ? state.auth.loggedInUser
        : undefined;
      if (authUser) {
        yield put(
          requestFetchProducts({
            client,
            companyId: authUser.Company.id,
            filter,
          })
        );
      }
    }
  }
}

function* fetchProductFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const action: ReturnType<typeof fetchProductsAction> = yield take(
      fetchProductsAction
    );
    const { client } = action.payload;

    // yield fork(filterProductFlow, client, filter);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    const authUser = state.auth.loggedInUser
      ? state.auth.loggedInUser
      : undefined;
    if (authUser) {
      yield put(
        requestFetchProducts({
          client,
          companyId: authUser.Company.id,
          filter: state.products.filter,
        })
      );
    }
  }
}

function* fetchOneProductFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    try {
      const action: ReturnType<typeof requestFetchProduct> = yield take(
        requestFetchProduct
      );
      const { client, productId } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state = yield select();
      const authUser = state.auth.loggedInUser
        ? state.auth.loggedInUser
        : undefined;
      yield put(setStatus(Status.loading));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const resp: Unpromisefy<ReturnType<typeof getProductQuery>> = yield call(
        // @ts-ignore
        getProductQuery,
        {
          client,
          id: productId,
          isAdmin: authUser ? authUser.isAdmin : false,
          isProductForm: true,
        }
      );
      if (!resp) {
        throw new Error('GraphQL returned undefined');
      }
      const productFetched: Product = convertToProduct(resp.data.products[0]);
      if (
        authUser &&
        (authUser.Company.id.toString() ===
          productFetched.Company.id.toString() ||
          authUser.isAdmin)
      ) {
        if (productFetched) {
          const newCache = {
            ...state.products.productsCache,
            [productFetched.id]: productFetched,
          };

          const newListItem: AnyListItem = {
            productId: productFetched.id,
            ranking: productFetched.manualRanking + productFetched.autoRanking,
            updatedAt: productFetched.updatedAt,
          };

          const ids =
            state.products.productIds.filter(
              (x: AnyListItem) => x.productId === productFetched.id
            ).length > 0
              ? state.products.productIds
              : [...state.products.productIds, newListItem];
          if (
            state.router.location.pathname.includes('/product/') ||
            state.router.location.pathname.includes('/trade-product/') ||
            state.router.location.pathname.includes('/wholesale-product/') ||
            state.router.location.pathname.includes(routes.marketplace) ||
            state.router.location.pathname.includes('-manufacturers')
          ) {
            yield put(fetchProductSuccess(productFetched));
          }
          yield all([put(setProductCache(newCache)), put(setProductIds(ids))]);
          yield put(setStatus(Status.idle));
        }
      } else {
        yield put(setStatus(Status.idle));
      }
    } catch (e) {
      logger.error(
        'src/app/store/modules/products/sagas.ts -> fetchOneProductFlow',
        e
      );
    }
  }
}

function* createProductFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const action: ReturnType<typeof createProduct> = yield take(createProduct);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield select();

    const companyId = sg(
      () =>
        action.payload.values.CompanySelect
          ? parseFloat(action.payload.values.CompanySelect.value)
          : state.auth.loggedInUser.Company &&
            state.auth.loggedInUser.Company.id,
      0
    );

    yield call(
      createProductSaga,
      action.payload.client,
      action.payload.values,
      companyId
    );
  }
}
function* createProductSaga(
  client: any,
  values: ProductForm,
  companyId: number
) {
  console.log('values');
  console.log(values);
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield select();
    const isAdmin = sg(() => state.auth.loggedInUser.isAdmin, false);
    const email = state.auth.loggedInUser
      ? state.auth.loggedInUser.email
      : undefined;
    const anonymousUserIdItem = localStorage.getItem(anonymousUserId);

    ReactGA.event({
      category: GACategory.ProductsLicensing,
      action:
        values.id.toString() === '0'
          ? GAActions
            ? GAActions.createLicensingProduct
            : ''
          : GAActions
          ? GAActions.updateLicensingProduct
          : '',
      value: values.id.toString() === '0' ? 0 : values.id,
      email,
      anonymousUserIdItem,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res: Unpromisefy<ReturnType<typeof createProductQuery>> = yield call(
      createProductQuery,
      {
        client,
        args: values,
        isAdmin,
        companyId,
      }
    );
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    if (
      state.router.location.pathname === routes.products ||
      state.router.location.pathname.indexOf(routes.update_product) >= 0 ||
      state.router.location.pathname.indexOf(routes.products_new) >= 0
    ) {
      if (values.id.toString() === '0') {
        const createdItem = getProductNameFull(
          convertToProduct(res.data!.product.returning[0])
        );

        yield put(
          setNotification({
            listSuccess: 'You’ve successfully added new product ' + createdItem,
            listError: '',
          })
        );
        const userId = sg(() => state.auth.loggedInUser.id, 0);
        if (userId) {
          yield put(
            requestSetUserType({
              userId,
              client,
              userType: UserTypeEnum.Seller,
            })
          );
          const contactExists: Unpromisefy<
            ReturnType<typeof getSellerContactInformationByCompanyIdAndUserId>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          > = yield call(getSellerContactInformationByCompanyIdAndUserId, {
            client: client,
            companyId: companyId,
            userId: userId,
          });
          if (
            contactExists &&
            contactExists?.data?.contact_person?.length === 0 &&
            companyId &&
            userId
          ) {
            console.log(userId);
            const loggedInUser = state.auth.loggedInUser;
            yield put(
              createSellerContact({
                client: client,
                companyId: loggedInUser.companyId,
                userId: userId,
                firstName: loggedInUser.firstName,
                lastName: loggedInUser.lastName,
                email: loggedInUser.email,
                phoneNumber: loggedInUser.phone,
              })
            );
          }
          yield put(
            requestSetOwnsProductStatus({
              ownsProduct: true,
              client,
            })
          );
        }
      } else {
        const updatedItem = getProductNameFull(
          convertToProduct(res.data!.product.returning[0])
        );
        yield put(
          setNotification({
            listSuccess:
              'You’ve successfully updated your product ' + updatedItem,
            listError: '',
          })
        );
      }
    }

    if (values.isModal) {
      if (
        state.router.location.pathname === routes.products ||
        state.router.location.pathname.indexOf(routes.update_product) >= 0 ||
        state.router.location.pathname.indexOf(routes.products_new) >= 0
      ) {
        const authUser = state.auth.loggedInUser
          ? state.auth.loggedInUser
          : undefined;
        if (authUser) {
          yield put(
            requestFetchProducts({
              client,
              companyId: sg(() => authUser.Company.id, 0),
              filter: state.products.filter,
            })
          );
        }
      } else {
        if (
          state.router.location.pathname === routes.marketplace ||
          state.router.location.pathname.includes('-manufacturers')
        ) {
          const productId = Number(values.id);

          if (isAdmin) {
            yield put(requestFetchProduct({ client, productId }));
          } else {
            // Item is removed from marketplace for admin approval
            yield call(
              removeDeletedItem,
              productId,
              MarketplaceItemType.product,
              TypeOrigin.licensing
            );
            yield put(
              setMarketplaceTotalCount(state.marketplace.totalCount - 1)
            );
          }
        }
        if (state.router.location.pathname === routes.admin_onboarding) {
          yield put(requestFetchOnboarding({ client }));
        }
      }
      yield put(setEditModal(false));
    } else {
      yield put(push(routes.products));
    }

    return res;
  } catch (e) {
    if (values.id.toString() === '0') {
      yield put(
        setNotification({
          listSuccess: '',
          listError: 'There was an error creating your product.',
        })
      );
    } else {
      yield put(
        setNotification({
          listSuccess: '',
          listError: 'There was an error updating your product.',
        })
      );
    }
    logger.error(
      'src/app/store/modules/products/sagas.ts -> createProductSaga',
      e
    );
    throw e;
  }
}

function* productTypeFilterSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof setProductTypeFilter> = yield take(
      setProductTypeFilter
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const store: IStore = yield select();
    yield put(setProductTypeFilterAction(payload.type));
    yield put(
      requestFetchProducts({
        client: payload.client,
        companyId:
          store.auth.loggedInUser && store.auth.loggedInUser.Company
            ? store.auth.loggedInUser.Company.id
            : 0,
        filter: store.products.filter,
      })
    );
  }
}

export default function* rootSaga() {
  yield all([
    fork(fetchProductFlow),
    fork(watchFetchOnboardingData),
    fork(fetchOnboardingSuccessSaga),
    fork(deleteProductFlow),
    fork(deleteProduct),
    fork(addDeleteIdFlow),
    fork(removeDeleteIdFlow),
    fork(deleteSelectedFlow),
    fork(filterProductSetFlow),
    fork(fetchSuccess),
    fork(watchFetchData),
    fork(fetchOneProductFlow),
    fork(createProductFlow),
    fork(setAdminOnboardingFilterSaga),
    fork(productTypeFilterSaga),
  ]);
}
