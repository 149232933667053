import { createActionCreator as c } from '@reduxify/utils';
import { Notifications } from '../requests/types';
import { DealFilter, IDeal, ICreateProductDeal } from './types';

import { IWithApolloClient } from 'app/store/modules/global/types';
export interface IFilterDealAction extends IWithApolloClient {
  searchText: string;
}
export const fetchRequests = c<IFilterDealAction>('deal/FETCH_REQUESTS');

export const setDeals = c<IDeal[]>('deal/SET_DEALS');

export const setTotalCount = c<number>('deal/SET_DEALS_TOTAL_COUNT');

export const setCurrentPage = c<number>('deal/SET_DEALS_CURRENT_PAGE');

export const setPageSize = c<number>('deal/SET_PAGE_SIZE');

export interface ISetDealBrokerType extends IWithApolloClient {
  id: number;
  broker_id: number;
}
export const setDealBroker = c<any>('deal/SET_BROKER');
export interface ISetDealActiveStatusType extends IWithApolloClient {
  deal_id: number;
  is_active: boolean;
}
export const setRequestActiveStatus = c<any>('deal/SET_DEAL_ACTIVE_STATUS');

/** Async actions section */
export const isFetching = c<boolean>('deal/FETCHING');

export const hasFetched = c<boolean>('deal/FETCHED');

export const setDealFilter = c<DealFilter>('deal/SET_FILTER');

export const setCreateRequestModalOpen = c<boolean>(
  'deal/SET_CREATE_DEAL_MODAL_OPEN'
);
export const setEditRequestModalOpen = c<boolean>(
  'deal/SET_EDIT_DEAL_MODAL_OPEN'
);
export const setEditRequestId = c<number | null>(
  'deal/SET_EDIT_DEAL_MODAL_OPEN'
);

export const setNotification = c<Notifications>('deal/SET_NOTIFICATIONS');

export interface IUpdateDealComments extends IWithApolloClient {
  id: number;
  comments: string;
}
export const updateDealComments = c<IUpdateDealComments>(
  'deal/UPDATE_COMMENTS'
);
export interface IUpdateDealTargetSupplyPrice extends IWithApolloClient {
  id: number;
  targetSupplyPrice: string;
}
export const updateDealTargetSupplyPrice = c<IUpdateDealTargetSupplyPrice>(
  'deal/UPDATE_TARGET_SUPPLY_PRICE'
);
export interface IUpdateDealQuantityEstimation extends IWithApolloClient {
  id: number;
  quantityEstimation: string;
}
export const updateDealQuantityEstimation = c<IUpdateDealQuantityEstimation>(
  'deal/UPDATE_QUANTITY_ESTIMATION'
);

export interface IUpdateDealClosingEstimation extends IWithApolloClient {
  id: number;
  closingEstimation: string;
}
export const updateDealClosingEstimation = c<IUpdateDealClosingEstimation>(
  'deal/UPDATE_CLOSING_ESTIMATION'
);
export interface IUpdateDealType extends IWithApolloClient {
  id: number;
  dealType: number;
}
export const updateDealType = c<IUpdateDealType>('deal/UPDATE_TYPE');
export interface ICreateProductDealAction extends IWithApolloClient {
  values: ICreateProductDeal;
}
export const addToRequestFromPotentialSuppliers = c<ICreateProductDealAction>(
  'deal/ADD_TO_DEAL'
);
export const setFilterTextDeal = c<string>('deal/SET_FILTER_TEXT_DEAL_V2');
