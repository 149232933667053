import { _, media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const ArchiveItemContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 26px;
  min-width: 26px;
  border-radius: 2px;
  height: 26px;
  cursor: pointer;
  width: fit-content;
  background-color: ${(props) => props.theme.colors.grayButton};
  :hover {
    background-color: ${(props) => props.theme.colors.grayButtonHover};
  }
  ${_(media.max.sm)} {
    margin-left: 0px;
  }
`;

const ArchiveItemIcon = styled.img`
  width: 14px;
  height: 13px;
  object-fit: contain;
  margin: auto;
  cursor: pointer;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.muted};
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-left: 9px;
  cursor: pointer;
`;

interface Props {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  label?: string;
}

const EditItemBlueButton: React.FC<Props> = ({ onClick, label }) => {
  return (
    <ArchiveItemContainer
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
    >
      <ArchiveItemIcon src={'/img/6-h.svg'} />
      {label !== undefined && <Label htmlFor={label}>{label}</Label>}
    </ArchiveItemContainer>
  );
};

export const Components = {
  ArchiveItemContainer,
  ArchiveItemIcon,
  Label,
};
export default EditItemBlueButton;
