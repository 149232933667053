import { createReducer, merge, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import Termsheet from 'app/modules/admin/quotation/models/termsheet';

import * as A from './actions';
import { ILatestByDealId, TermsheetState } from './types';

export default combineReducers<TermsheetState>({
  isCreating: createReducer<boolean>(false, reduce(A.creatingTermsheet, set)),
  hasCreated: createReducer<boolean>(false, reduce(A.createdTermsheet, set)),
  cache: createReducer<Dictionary<Termsheet>>(
    {},
    reduce(A.setTermsheetCache, merge)
  ),
  latestByDealId: createReducer<ILatestByDealId>(
    {},
    reduce(A.setLatestTermsheet, merge)
  ),
});
