import { ICompanyFragment } from '../../global/fragments/company';
import { IFragment } from '../../query-utils/fragment';
import { Company, User } from '../types';

export interface IUserSettingFragment {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  position: string;
  uuid: string;
  isAdmin: boolean;
  isBeta: boolean;
  isSeller: boolean;
  isBuyer: boolean;
  verified: boolean;
  verificationToken: string;
  Company: ICompanyFragment;
  intercomHash: string;
  ownsProduct: boolean;
}

export const userSettingFragment: IFragment = {
  fragment: () => `
    fragment userSettingFragment on users {
      id: id
      firstName: first_name
      lastName: last_name
      fullName: full_name
      email: email
      position: position
      uuid: uuid
      isAdmin: is_admin
      isBeta: is_beta
      verified: verified
      verificationToken: verification_token
      isSeller: is_seller
      isBuyer: is_buyer
      Company { id }
    }
  `,
  name: 'userSettingFragment',
};

export interface IUserMinimalFragment {
  id: number;
  fullName: string;
  email: string;
}
export const userMinimalFragment: IFragment = {
  fragment: () => `
    fragment userMinimalFragment on users {
      id: id
      fullName: full_name
      email: email
    }
  `,
  name: 'userMinimalFragment',
};

export function convertToUser(p: IUserSettingFragment): User {
  return {
    ...p,
    isDefaultBroker: false,
    Company: p.Company as Company,
  };
}
