import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { RequestUtils } from '../../query-utils';

export interface ISetDealPhaseQueryParams extends IWithApolloClient {
  requestId: number;
  phase: number;
  skippedReason?: number;
}

export interface ISetDealPhaseQueryReturn {
  update_requests: {
    returning: {
      id: number;
      phase: number;
      skippedReason?: number;
    }[];
  };
}

export async function setDealPhaseQuery(p: ISetDealPhaseQueryParams) {
  try {
    if (!p.requestId) {
      return;
    }
    return await p.client.mutate<ISetDealPhaseQueryReturn>({
      mutation: gql`
      mutation setBroker(${RequestUtils.params(
        ['$requestId', 'Int'],
        ['$phase', 'Int'],
        ['$skippedReason', 'Int'],
        ['$date', 'timestamptz']
      )}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('id', '$requestId'))}
          ${RequestUtils._set(
            ['phase', '$phase'],
            ['skipped_reason', '$skippedReason'],
            ['updated_at', '$date']
          )}
        ) {
          returning {
            id,
            phase,
            skippedReason: skipped_reason
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        requestId: p.requestId,
        phase: p.phase,
        skippedReason: p.skippedReason,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> setDealPhaseQuery',
      e
    );
  }
}
