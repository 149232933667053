import { media } from 'app/styles/breakpoint';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const Tr = styled.tr<{ markUserSelfManagedItemForAdmin?: boolean }>`
  ${(props) =>
    props.markUserSelfManagedItemForAdmin
      ? `background-color: ${props.theme.colors.adminCreatedBackground};
      border: solid 1px #e9edf0;
      border-left: 0px;
      border-right: 0px;
      `
      : `background-color: ${props.theme.colors.white};`}

  ${media.min.md`
width: 100%;

`}
  height: 50px;
  :first-child td {
    border-top: 0;
  }

  ${media.max.md`
  min-height: 50px;
`}
`;

export const Body = styled.tbody`
  width: 100%;
`;

export const Th = styled.th<{ noBorder?: boolean }>`
  padding: 16px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.muted};
  ${(props) =>
    props.noBorder === true
      ? ''
      : `border-right: solid 1px ${props.theme.colors.grayLight}`};
  :last-child {
    border-right: 0px;
  }
  &.min-content {
    width: 1%;
    min-width: unset;
  }
`;

export const Td = styled.td<{ noBorder?: boolean }>`
  padding: 8px 16px 8px 16px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${(props) =>
    props.noBorder === true
      ? ''
      : `border: solid 1px ${props.theme.colors.grayLight}`};
  :last-child {
    border-right: 0px;
  }
  :first-child {
    border-left: 0;
  }
`;

export const SmallTableItem = styled.div<{
  markUserSelfManagedItemForAdmin?: boolean;
}>`
  width: 100%;
  padding: 16px;
  border-color: ${(props) => props.theme.colors.grayLight};
  border-style: solid;
  border-width: 0px;
  border-top-width: 1px;
  :first-child {
    border-top-width: 0px;
  }
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.markUserSelfManagedItemForAdmin
      ? `background-color: ${props.theme.colors.adminCreatedBackground};
      border: solid 1px #e9edf0;
      border-left: 0px;
      border-right: 0px;
      `
      : `background-color: ${props.theme.colors.white};`}
`;

export const TrStyles = styled.div`
  display: flex;
`;
export const TdStyles = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

export const ThStyles = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 110px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.09;
  letter-spacing: normal;
  text-align: left;
  color: ${(props) => props.theme.colors.muted};
`;
