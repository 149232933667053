import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 26px;
  min-width: 26px;
  height: 26px;
  cursor: pointer;
  border-radius: 2px;
  width: fit-content;
  background-color: ${(props) => props.theme.colors.primaryLight};
  :hover {
    background-color: ${(props) => props.theme.colors.primaryLightHover};
  }
  ${media.max.sm`
    margin-left: 0px;
    max-width: 63px;
    min-width: 63px;
  `}
`;

const AddItemIcon = styled.img`
  width: 12.6px;
  height: 13.4px;
  object-fit: contain;
  cursor: pointer;
  margin: auto;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.primary};
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-right: auto;
  cursor: pointer;
`;

interface Props {
  onClick?: (ev: any | null) => any;
  label?: string;
}

const EditItemBlueButton: React.FC<Props> = ({ onClick, label }) => {
  return (
    <AddItemContainer onClick={onClick}>
      <AddItemIcon src={'/img/edit-pen.svg'} />
      {label !== undefined && <Label htmlFor={label}>{label}</Label>}
    </AddItemContainer>
  );
};

export const Components = {
  AddItemContainer,
  AddItemIcon,
  Label,
};
export default EditItemBlueButton;
