import { createActionCreator } from '@reduxify/utils';
function flag<T = boolean>(type: string) {
  return createActionCreator<T>(`flag/${type}`);
}

/* eslint-disable  */
/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
export const setFlag_offerTradeFormSaveCompleted = flag('offerTradeFormSaveCompleted');
export const setFlag_directoriesListModalOpen = flag('directoriesListModalOpen');
export const setFlag_directoriesListModalOpenLocation = flag<string>('directoriesListModalLocation');
export const setFlag_preventRedirectAfterLogin = flag<boolean>('preventRedirectAfterLogin');
export const setFlag_preventRedirectAfterLoginWC = flag<{ redirect: boolean; callback?: () => void }>('preventRedirectAfterLoginWC');
export const setFlag_brokerChangeId = flag<number | null>('brokerChangeId');
export const setFlagBannerSeen = flag('bannerSeen');
