import * as saga from '@redux-saga/core/effects';
import { ApolloClient } from 'apollo-boost';
import { routes } from 'app/config';
import { isBrowser } from 'app/store/utils/is-browser';
import { toDictionary } from 'app/utils/dictionary';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import { push } from 'connected-react-router';
import { IStore } from '../../types';
import { IQueryWithPagination, IWithApolloClient } from '../global/types';
import * as A from './actions';
import { IMACompanyFragment } from './fragments/ma-company';
import * as Query from './query';
import { CompanyFormFields, ICompany, State as CompaniesState } from './types';

/** Fetch companies */
interface IFetchCompanies extends IQueryWithPagination, IWithApolloClient {}

function* fetchCompaniesFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const action: ReturnType<typeof A.fetchCompanies> = yield saga.take(
      A.fetchCompanies
    );

    const state = yield saga.select();
    const companies: CompaniesState = state.companies;

    const params: IFetchCompanies = {
      client: action.payload.client,
      limit: companies.pageSize,
      offset: companies.currentPage * companies.pageSize,
    };

    yield saga.put(A.isFetching(true));
    yield saga.call(fetchCompanies, params);
  }
}
function* fetchCompanies(params: IFetchCompanies) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.getCompaniesListQuery>
    > = yield saga.call(Query.getCompaniesListQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const compnaiesDictionary = toDictionary<IMACompanyFragment, ICompany>(
      res.data.ma_company,
      (v) => v.id
    );

    yield saga.put(A.setCompaniesIds(res.data.ma_company.map((v) => v.id)));
    yield saga.put(A.setCompaniesCache(compnaiesDictionary));
    yield saga.put(
      A.setCompaniesTotalCount(
        res.data.ma_company_aggregate.aggregate.totalCount
      )
    );
    yield saga.put(A.hasFetched(true));
    yield saga.put(A.isFetching(false));
  } catch (e) {
    const state: IStore = yield saga.select();
    yield saga.put(
      A.setCompaniesNotification({
        ...state.companies.error,
        listError: 'There was an error loading your ma_companies.',
      })
    );
  }
}

function* deleteOfferFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.deleteCompany> = yield saga.take(
      A.deleteCompany
    );

    yield saga.call(deleteMaCompany, action.payload.id, action.payload.client);
  }
}
function* deleteMaCompany(companyId?: number, client?: ApolloClient<any>) {
  try {
    if (client && companyId) {
      const params: Params<typeof Query.deleteCompanyQuery> = {
        id: companyId,
        client,
      };
      yield saga.call(Query.deleteCompanyQuery, params);

      const state: IStore = yield saga.select();
      const ids = [
        ...state.companies.companiesIds.filter((x) => x !== companyId),
      ];

      yield saga.put(A.setCompaniesIds(ids));
      yield saga.put(A.setCompaniesTotalCount(state.companies.totalCount - 1));
    }
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/saga.ts -> deleteMaCompany',
      e
    );
    const state = yield saga.select();
    yield saga.put(
      A.setCompaniesNotification({
        ...state.offers.error,
        listError:
          'There was an error deleting this company. Please try again later.',
      })
    );
  }
}

function* createMaCompanyFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    try {
      const action: ReturnType<typeof A.createCompany> = yield saga.take(
        A.createCompany
      );
      const state: IStore = yield saga.select();

      if (state.auth.loggedIn) {
        const res: Unpromisefy<
          ReturnType<typeof Query.createCompanyQuery>
        > = yield saga.call(
          createMaCompany,
          action.payload.client,
          action.payload.values
        );
        if (!res) {
          throw new Error('GraphQL returned undefined');
        }

        if (action.payload.values.id === 0) {
          const companyDictionary = {
            ...state.companies.companiesCache,
            [res.data!.maComapny.returning[0].id]: {
              ...res.data!.maComapny.returning[0],
            },
          };
          const companyIds = [
            ...state.companies.companiesIds,
            res.data!.maComapny.returning[0].id,
          ];
          yield saga.put(A.setCompaniesCache(companyDictionary));
          yield saga.put(A.setCompaniesIds(companyIds));
        } else {
          const companyDictionary = {
            ...state.companies.companiesCache,
            [res.data!.maComapny.returning[0].id]: {
              ...res.data!.maComapny.returning[0],
            },
          };
          yield saga.put(A.setCompaniesCache(companyDictionary));
        }
        yield saga.put(A.fetchCompanies({ client: action.payload.client }));
      }
    } catch (e) {
      logger.error(
        'src/app/store/modules/ma-companies/saga.ts -> createMaCompanyFlow',
        e
      );
    }
  }
}
function* createMaCompany(
  client: ApolloClient<any>,
  values: CompanyFormFields
) {
  try {
    if (!isBrowser()) {
      return;
    }

    const params: Params<typeof Query.createCompanyQuery> = {
      client,
      values,
    };
    const res: Unpromisefy<
      ReturnType<typeof Query.createCompanyQuery>
    > = yield saga.call(Query.createCompanyQuery, params);

    yield saga.put(A.setEditModal(false));

    return res;
  } catch (e) {
    throw e;
  }
}

export interface IFetchCompany extends IWithApolloClient {
  id: number;
}

function* fetchCompanyFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.fetchCompany> = yield saga.take(
      A.fetchCompany
    );

    const params: IFetchCompany = {
      client: action.payload.client,
      id: action.payload.id,
    };

    yield saga.put(A.isFetching(true));
    yield saga.call(fetchCompany, params);
  }
}
function* fetchCompany(params: IFetchCompany) {
  try {
    if (!isBrowser()) {
      return;
    }
    const res: Unpromisefy<
      ReturnType<typeof Query.getCompanyQuery>
    > = yield saga.call(Query.getCompanyQuery, params);
    if (!res) {
      throw new Error('Query response is undefined');
    }
    const company = sg(() => res.data.ma_company[0], {} as ICompany);
    const state: IStore = yield saga.select();
    const ids = state.companies.companiesIds;
    if (ids.indexOf(company.id) === -1) {
      yield saga.put(A.setCompaniesIds([...ids, company.id]));
    }
    yield saga.put(
      A.setCompaniesCache({
        ...state.companies.companiesCache,
        [company.id]: {
          ...state.companies.companiesCache[company.id],
          ...company,
        },
      })
    );
    yield saga.put(A.isFetching(false));
    yield saga.put(A.hasFetched(false));
  } catch (e) {
    const state = yield saga.select();
    yield saga.put(
      A.setCompaniesNotification({
        ...state.companies.error,
        listError: 'There was an error loading your company.',
      })
    );
  }
}

function* fetchCompanyDirectoryCountryNameListFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.fetchCompanyDirectoryNameList
    > = yield saga.take(A.fetchCompanyDirectoryNameList);

    const params: IWithApolloClient = {
      client: action.payload.client,
    };

    yield saga.put(A.isFetching(true));
    yield saga.call(fetchCompanyDirectoryCountryNameList, params);
  }
}
function* fetchCompanyDirectoryCountryNameList(p: IWithApolloClient) {
  try {
    if (!isBrowser()) {
      return;
    }
    const res: Unpromisefy<
      ReturnType<typeof Query.getCompanyDirectoryCountryQuery>
    > = yield saga.call(Query.getCompanyDirectoryCountryQuery, p);
    if (!res) {
      throw new Error('Query response is undefined');
    }
    const allLetter = (inputtxt: string) => {
      const letters = /^[A-Za-z ]+$/;
      if (inputtxt.match(letters)) {
        return true;
      } else {
        return false;
      }
    };

    const companyNameList = sg(
      () =>
        res.data.companydirectory
          .map((x) => (x.country ? x.country : ''))
          .filter((x) => x !== '' && allLetter(x)),
      [] as string[]
    );
    yield saga.put(A.setCountryNameList(companyNameList));
    yield saga.put(A.isFetching(false));
    yield saga.put(A.hasFetched(true));
  } catch (e) {
    //
  }
}

function* fetchCompanyDirectoryCompanyByCountryFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.fetchCompanyDirectoryByCountryList
    > = yield saga.take(A.fetchCompanyDirectoryByCountryList);
    const params: Query.IGetCompanyDirectory = {
      client: action.payload.client,
      country: action.payload.country,
    };

    yield saga.put(A.isFetching(true));
    yield saga.call(fetchCompanyDirectoryByCountry, params);
  }
}
function* fetchCompanyDirectoryByCountry(p: Query.IGetCompanyDirectory) {
  try {
    if (!isBrowser()) {
      return;
    }

    const res: Unpromisefy<
      ReturnType<typeof Query.getCompanyDirectoryQuery>
    > = yield saga.call(Query.getCompanyDirectoryQuery, p);
    if (!res) {
      throw new Error('Query response is undefined');
    }
    if (res.data && res.data.companydirectory.length > 0) {
      yield saga.put(A.setCompanyByCountryNameList(res.data.companydirectory));
    } else {
      yield saga.put(push(routes.page_not_found));
    }
    yield saga.put(A.isFetching(false));
    yield saga.put(A.hasFetched(false));
  } catch (e) {
    //
  }
}

function* fetchCompanyDirectoryCompanyByIdFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.fetchCompanyDirectoryCompanyById
    > = yield saga.take(A.fetchCompanyDirectoryCompanyById);

    const params: Query.IGetCompanyDirectoryCompanyById = {
      client: action.payload.client,
      companyId: action.payload.companyId,
    };

    yield saga.put(A.isFetching(true));
    yield saga.call(fetchCompanyDirectoryById, params);
  }
}
function* fetchCompanyDirectoryById(p: Query.IGetCompanyDirectoryCompanyById) {
  try {
    if (!isBrowser()) {
      return;
    }
    const res: Unpromisefy<
      ReturnType<typeof Query.getCompanyDirectoryByIdQuery>
    > = yield saga.call(Query.getCompanyDirectoryByIdQuery, p);
    if (!res) {
      throw new Error('Query response is undefined');
    }
    const state: IStore = yield saga.select();
    const arr =
      state.companies.companyDirectoryCompanies.filter(
        (x) => x.id.toString() === p.companyId.toString()
      ).length > 0
        ? [...state.companies.companyDirectoryCompanies]
        : [
            ...state.companies.companyDirectoryCompanies,
            res.data.companydirectory[0],
          ];

    yield saga.put(A.setCompanyByCountryNameList(arr));
    yield saga.put(A.isFetching(false));
    yield saga.put(A.hasFetched(false));
  } catch (e) {
    //
  }
}

function* requestFetchDirectoryCountrySEOTextSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload,
      }: ReturnType<
        typeof A.fetchCompanyDirectorySEOByCountryName
      > = yield saga.take(A.fetchCompanyDirectorySEOByCountryName);

      const response: any = yield saga.call(
        Query.fetchDirectoryCountrySEOTextByName,
        {
          countryName: payload.countryName,
          client: payload.client,
        }
      );

      if (response && response.data.countries.length > 0) {
        const country = response.data.countries[0];
        yield saga.put(
          A.setCompanyDirectorySEOByCountryName(
            country.seoTextFooterDirectory ? country.seoTextFooterDirectory : ''
          )
        );
      } else {
        yield saga.put(A.setCompanyDirectorySEOByCountryName(''));
      }
    } catch (e) {
      logger.error(
        'src/app/store/modules/marketplace/sagas.ts -> requestFetchSubstanceSEOText',
        e
      );
    }
  }
}

export default function* rootSaga() {
  yield saga.all([
    saga.fork(fetchCompaniesFlow),
    saga.fork(deleteOfferFlow),
    saga.fork(createMaCompanyFlow),
    saga.fork(fetchCompanyFlow),
    saga.fork(fetchCompanyDirectoryCountryNameListFlow),
    saga.fork(fetchCompanyDirectoryCompanyByCountryFlow),
    saga.fork(fetchCompanyDirectoryCompanyByIdFlow),
    saga.fork(requestFetchDirectoryCountrySEOTextSaga),
  ]);
}
