function sg<T, K = T>(getter: () => T, defaultValue: K): T | K {
  try {
    const v = getter();
    if (!emptyCheck(v)) {
      throw new Error('Empty value');
    }

    return v;
  } catch {
    return defaultValue;
  }
}

function sgg<T>(...getters: any): T {
  for (const getter of getters) {
    try {
      if (!(getter instanceof Function)) {
        return getter;
      }
      const v = getter();
      if (!emptyCheck(v)) {
        throw new Error('Empty value');
      }
      return v;
    } catch {
      continue;
    }
  }

  // eslint-disable-next-line
  // throw new Error('No values has been valid');
  return undefined as any;
}
export { sg, sgg };
export default sg;

export function emptyCheck<T>(v: T): boolean {
  if (v === undefined || v === null) {
    return false;
  } else if (typeof v === 'boolean' && v === false) {
    return false;
  } else if (typeof v === 'string' && v.length === 0) {
    return false;
  } else if (Array.isArray(v) && v.length === 0) {
    return false;
  } else if (typeof v === 'object' && Object.keys(v).length === 0) {
    return false;
  } else if (typeof v === 'number' && isNaN(v)) {
    return false;
  }
  return true;
}
