import ToolTip from 'app/components/tooltip/tooltip-component';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled, { css } from 'styled-components';
import { sg } from '../../../utils/safe-get';

const InputContainer = styled.div<{ marginTop?: string }>`
  width: 100%;
  max-width: 620px;

  ${media.max.sm`
  margin-top: 8px;
  `}

  ${(props) =>
    props.marginTop !== undefined &&
    props.marginTop !== '' &&
    `margin-top: ${props.marginTop};`}
`;

const ErrorLine = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.errorMessage};
`;

const ErrorIcon = styled.img`
  width: 10px;
  height: 9px;
  object-fit: contain;
`;

const PasswordIcon = styled.img`
  width: 16px;
  height: 10px;
  object-fit: contain;
  position: absolute;
  right: 0;
  bottom: 12px;
  z-index: 1;
  cursor: pointer;
`;

const InputWrapperNew = styled.div`
  display: flex;
  flex-flow: column-reverse;
  position: relative;
`;

const LabelStyle = styled.label<{ textAlign?: string }>`
  transition: all 200ms ease;
  font-size: 14px;
  pointer-events: all;
  color: ${(props) => props.theme.colors.grayText};
  z-index: 1;
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};
`;

const LabelLenghtStyle = styled.span`
  color: ${(props) => props.theme.colors.grayDark};
`;
export const InputSuffix = styled.span`
  font-size: 11px;
  line-height: 15px;
  text-align: right;
  color: #bbc1cb;
`;

interface IInputStyleBaseProps {
  error: boolean;
  floatingLabel: boolean;
  noBorder: boolean;
  background: string;
  inputMarginTop: string;
  isEmpty: boolean;
  maxLength?: number;
  maxRows?: number;
  textAlign?: string;
}
interface IInputStyleProps extends IInputStyleBaseProps {
  password: boolean;
}
export const InputStyle = styled.input<IInputStyleProps>`
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};
  color: ${(props) => props.theme.colors.grayExtraDark};
  background-color: ${(props) =>
    props.background !== '' ? props.background : props.theme.colors.white};
  z-index: 1;
  transition: transform 200ms ease;
  font-family: 'Nunito Sans';
  font-size: 15px;
  border: 0;
  border-bottom: 1px solid #ccc;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  border: 0px;
  padding: 8px;
  caret-color: ${(props) => props.theme.colors.accent};
  padding-right: ${(props) =>
    props.textAlign ? '0px' : props.password ? '20px' : '8px'};
  padding-left: ${(props) =>
    props.textAlign && props.textAlign === 'right' ? '8px' : '0px'};
  padding-bottom: 9px;
  height: 39px;

  &:not([value='']) {
    border-bottom: ${(props) =>
      props.error
        ? `2px solid ${props.theme.colors.danger}`
        : `1px solid ${props.theme.colors.grayText}`};

    font-weight: 400;
  }

  border-bottom: ${(props) =>
    props.error
      ? `2px solid ${props.theme.colors.danger}`
      : `1px solid ${props.theme.colors.gray}`};

  margin-top: ${(props) =>
    props.inputMarginTop !== '' ? props.inputMarginTop : '1px'};

  :focus {
    outline: 0;
    padding-bottom: 8px;
    border-bottom: 2px solid ${(props) => props.theme.colors.accent};
    ${(props) =>
      props.noBorder &&
      `
        border-bottom: 0px;
      `}
  }

  ${(props) =>
    props.floatingLabel &&
    `
      &:placeholder-shown + ${LabelStyle} {
        cursor: text;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125em) scale(1);
      }
      &:not(:placeholder-shown) + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
        color: ${props.theme.colors.grayText};
      }
      &:focus + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
        color: ${props.theme.colors.grayText};
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
        font-size: 15px;
        font-weight: normal;
        font-family: 'Nunito Sans';
      }
    `}
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.muted};
    font-size: 15px;
    font-family: 'Nunito Sans';
  }
  ::placeholder {
    color: ${(props) => props.theme.colors.muted};
    font-size: 15px;
    font-family: 'Nunito Sans';
  }
`;

export const InputStyleTextarea = styled.textarea<IInputStyleBaseProps>`
  color: ${(props) => props.theme.colors.grayExtraDark};
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.background !== '' ? props.background : props.theme.colors.white};
  z-index: 1;
  max-width: 100%;
  height: 100%;
  transition: transform 200ms ease;
  font-family: 'Nunito Sans';
  font-size: 15px;
  border: 0;
  border-bottom: 1px solid #ccc;
  -webkit-appearance: none;
  border-radius: 0;
  width: 100%;
  border: 0px;
  padding: 8px;
  caret-color: ${(props) => props.theme.colors.accent};
  padding-right: 8px;
  padding-left: 0px;
  padding-bottom: 9px;

  border-bottom: ${(props) =>
    props.error
      ? `2px solid ${props.theme.colors.danger}`
      : `1px solid ${props.theme.colors.gray}`};

  ${(props) =>
    !props.isEmpty &&
    `border-bottom: 1px solid ${props.theme.colors.grayText};
  font-weight: 400;

    `};
  margin-top: ${(props) =>
    props.inputMarginTop !== '' ? props.inputMarginTop : '1px'};
  :focus {
    outline: 0;
    padding-bottom: 8px;
    border-bottom: 2px solid ${(props) => props.theme.colors.accent};
    ${(props) =>
      props.noBorder &&
      `
        border-bottom: 0px;
      `}
  }

  overflow: hidden;
  resize: none;

  ${(props) =>
    props.floatingLabel &&
    `
      &:placeholder-shown + ${LabelStyle} {
        cursor: text;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125em) scale(1);
      }
      &:not(:placeholder-shown) + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
        color: ${props.theme.colors.grayText};

      }
      &:focus + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
        font-size: 15px;
        font-weight: normal;
        font-family: 'Nunito Sans';
      }
    `}
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.muted};
    font-size: 15px;
    font-family: 'Nunito Sans';
  }
  ::placeholder {
    color: ${(props) => props.theme.colors.muted};
    font-size: 15px;
    font-family: 'Nunito Sans';
  }
`;
const TextAreaWrap = styled.div<{ maxRows?: number }>`
  position: relative;
  pre {
    min-height: 15px;
    margin: 0;
    padding: 8px 8px 8px 0;
    font-size: 15px;
    font-family: 'Nunito Sans';
    word-break: break-word;
    white-space: pre-wrap;
    color: ${(props) => props.theme.colors.grayExtraDark};
  }
  ${({ maxRows }) => {
    const lineHeight = 1.4;
    if (maxRows) {
      return css`
        textarea,
        pre {
          line-height: ${lineHeight}em;
          max-height: ${maxRows * lineHeight}em !important;
        }
        textarea {
          overflow: auto !important;
          box-sizing: content-box;
        }
      `;
    }
  }}
`;

export interface Props {
  value?: string;
  placeholder?: string;
  label?: string;
  onChange: (text: string) => any;
  error?: string;
  multiline?: boolean;
  type?: string;
  showPasswordIcon?: boolean;
  onFocus?: () => any;
  onBlur?: () => any;
  floatingLabel?: boolean;
  noBorder?: boolean;
  tabindex?: string;
  testId?: string;
  background?: string;
  marginTop?: string;
  inputMarginTop?: string;
  onKeyPress?: (e: any) => any;
  tooltip?: string;
  startRows?: number;
  maxRows?: number;
  autoComplete?: string;
  id?: string;
  maxLength?: number;
  autocomplete?: string;
  browserAutocompleteEnabled?: boolean;
  textAlign?: string;
  suffix?: string;
}

enum InputType {
  password = 'password',
}

interface IState {
  seePassword: boolean;
  textLen: number;
}
interface IInputBaseProps {
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  tabIndex?: number;
  maxLength?: number;
  autocomplete?: string;
  onBlur?(): any;
  onFocus?(): void;
  onKeyPress?(e: any): void;
}

class TextInput extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      seePassword: false,
      textLen: 0,
    };

    this.setSeePassword = this.setSeePassword.bind(this);
  }

  // eslint-disable-next-line
  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.value && this.props.value !== '' && nextProps.value === '') {
      if (this.props.maxLength) {
        this.setState({ textLen: 0 });
      }
    }
  }

  public render() {
    const inputBaseStyleProps: IInputStyleBaseProps = {
      textAlign: this.props.textAlign,
      background: this.props.background || '',
      error: !!this.props.error,
      floatingLabel: !!this.props.floatingLabel,
      noBorder: !!this.props.noBorder,
      isEmpty: this.props.value === undefined || this.props.value === '',
      inputMarginTop: this.props.inputMarginTop
        ? this.props.inputMarginTop
        : '',
      maxLength: this.props.maxLength,
    };

    const inputBaseProps: IInputBaseProps = {
      id: this.props.id ? this.props.id : this.props.label,
      name: this.props.label,
      placeholder: this.props.floatingLabel
        ? ' '
        : this.props.placeholder || '',
      value: this.props.value,
      onFocus: () => this.props.onFocus && this.props.onFocus(),
      tabIndex:
        (this.props.tabindex && parseFloat(this.props.tabindex)) || undefined,
      onKeyPress: this.props.onKeyPress,
      onBlur: this.props.onBlur,
      maxLength: this.props.maxLength,
      autocomplete: this.props.autocomplete,
    };

    const InputItem = !this.props.multiline ? (
      <InputStyle
        {...inputBaseStyleProps}
        {...inputBaseProps}
        readOnly={
          this.props.type === InputType.password &&
          !this.props.browserAutocompleteEnabled
        }
        onFocus={(e) => {
          if (this.props.type === InputType.password) {
            e.target.removeAttribute('readonly');
          }
          if (this.props.onFocus) {
            this.props.onFocus();
          }
        }}
        data-testid={this.props?.testId || this.props.label}
        id={this.props.id ? this.props.id : this.props.label}
        password={this.props.type === 'password'}
        type={this.state.seePassword ? 'text' : this.props.type}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          this.props.onChange(ev.target.value);
          this.setState({ textLen: ev.target.value.length });
        }}
        maxLength={this.props.maxLength}
        autoComplete={this.props.autoComplete}
      />
    ) : (
      <TextAreaWrap maxRows={this.props.maxRows}>
        <pre>
          {sg(() => this.props.value!.length > 0, false)
            ? this.props.value
            : this.props.placeholder}{' '}
        </pre>
        <InputStyleTextarea
          {...inputBaseStyleProps}
          {...inputBaseProps}
          readOnly={
            this.props.type === InputType.password &&
            !this.props.browserAutocompleteEnabled
          }
          onFocus={(e) => {
            if (this.props.type === InputType.password) {
              e.target.removeAttribute('readonly');
            }
            if (this.props.onFocus) {
              this.props.onFocus();
            }
          }}
          maxLength={this.props.maxLength}
          id={this.props.id ? this.props.id : this.props.label}
          onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            this.props.onChange(ev.target.value);
            this.setState({ textLen: ev.target.value.length });
          }}
        />
      </TextAreaWrap>
    );
    return (
      <InputContainer marginTop={this.props.marginTop}>
        <InputWrapperNew>
          {this.props.suffix && <InputSuffix>{this.props.suffix}</InputSuffix>}
          {InputItem}
          <LabelStyle
            htmlFor={this.props.id ? this.props.id : this.props.label}
            textAlign={this.props.textAlign}
          >
            {this.props.label}
            {this.props.maxLength ? (
              <LabelLenghtStyle>
                {` (${
                  this.props.maxLength - this.state.textLen
                } characters left)`}
              </LabelLenghtStyle>
            ) : (
              ''
            )}
            {this.props.tooltip && <ToolTip tooltip={this.props.tooltip} />}
          </LabelStyle>
          {this.props.type === InputType.password &&
            this.props.showPasswordIcon && (
              <PasswordIcon
                onClick={() => {
                  this.setSeePassword(!this.state.seePassword);
                }}
                src={'/img/showPasswordIcon.svg'}
              />
            )}
        </InputWrapperNew>

        {this.props.error !== undefined && this.props.error.length > 0 && (
          <ErrorLine>
            <ErrorMessage>{this.props.error}</ErrorMessage>
            <ErrorIcon src={'/img/formError.png'} />
          </ErrorLine>
        )}
      </InputContainer>
    );
  }

  private setSeePassword(seePassword: boolean): void {
    this.setState({ seePassword });
  }
}

const Components = {
  InputContainer,
  ErrorLine,
  ErrorMessage,
  ErrorIcon,
  PasswordIcon,
  InputWrapperNew,
  LabelStyle,
  InputStyle,
  InputStyleTextarea,
};

export { Components };
export default TextInput;
