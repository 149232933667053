import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { MACompanyUtils } from '../../query-utils';
import { Schema_MACompany } from '../../query-utils/schema';

export interface IDeleteCompany extends IWithApolloClient {
  id: number;
}
export interface IDeleteCompanyReturn {
  update_ma_company: {
    returning: {
      id: number;
    }[];
  };
}
export async function deleteCompanyQuery(p: IDeleteCompany) {
  try {
    return await p.client.mutate({
      mutation: gql`
      mutation deleteCompany(${MACompanyUtils.params(
        ['$id', 'Int'],
        ['$deletedAt', 'timestamptz']
      )}) {
        update_ma_company(
          ${MACompanyUtils.where(MACompanyUtils._eq('id', '$id'))}
          ${MACompanyUtils._set(['deleted_at', '$deletedAt'])}
        ) {
          returning {
            ${u.selectParams<Schema_MACompany>('id')}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        deletedAt: new Date().toDateString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> deleteCompanyQuery',
      e
    );
  }
}
