import { AppState } from 'app/store/types';
import { DeepPartial } from 'app/utils/types';
import { routerMiddleware } from 'connected-react-router';
import { applyMiddleware, compose, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';
import configureMockStore from 'redux-mock-store';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducer';

const trace = process.env.NODE_ENV === 'development';

export const configureStore = (
  history: any,
  preloadedState?: DeepPartial<AppState>
) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware, routerMiddleware(history)];
  const reducer = createReducer(history);

  const enhancedCreateStore = compose<typeof createStore>(
    applyMiddleware(...middleware),
    devToolsEnhancer({ trace })
  )(createStore);

  const store = enhancedCreateStore(reducer, preloadedState);

  return {
    ...store,
    runSaga: sagaMiddleware.run,
  };
};

export const configureTestStore = (history: any) => {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  const mockStore = configureMockStore(middleware);
  return {
    mockStore,
    runSaga: sagaMiddleware.run,
  };
};
