import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient, UserTypeEnum } from '../../global/types';
import * as u from '../../query-utils';
import {
  IUserSettingFragment,
  userSettingFragment,
} from '../fragments/user-settings';

export interface IUpdateUserType extends IWithApolloClient {
  userType: number;
  userId: number;
}
export interface IUpdateUserTypeReturn {
  update_users: {
    returning: IUserSettingFragment[];
  };
}

export async function updateUserTypeQuery(p: IUpdateUserType) {
  try {
    return await p.client.mutate<IUpdateUserTypeReturn>({
      mutation: gql`
      ${userSettingFragment.fragment()}
      mutation update_users(${u.params(
        ['$id', 'Int'],
        p.userType === UserTypeEnum.Buyer && ['$isBuyer', 'Boolean'],
        p.userType === UserTypeEnum.Seller && ['$isSeller', 'Boolean']
      )}) {
        update_users(
          ${u.where(u._eq('id', '$id'))}
          ${u._set(
            p.userType === UserTypeEnum.Buyer && ['is_buyer', '$isBuyer'],
            p.userType === UserTypeEnum.Seller && ['is_seller', '$isSeller']
          )}
        ) {
          returning {
            ...${userSettingFragment.name}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.userId,
        isBuyer: p.userType === UserTypeEnum.Buyer ? true : undefined,
        isSeller: p.userType === UserTypeEnum.Seller ? true : undefined,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/auth/query.ts -> updateUserTypeQuery',
      e
    );
  }
}
