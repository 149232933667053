// interface IBaseModel {
//     id: string;
// }

class Base {
  public id: string;
  public data: any;

  constructor(data: any) {
    this.id = data.id;
    this.data = data;
  }
}

export default Base;
