import { media } from 'app/styles/breakpoint';
import Dayzed from 'dayzed';
import React from 'react';
import styled from 'styled-components';

const monthNamesShort = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const weekdayNamesShort = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const MainCalendarContainer = styled.div`
  max-width: 300px;
  width: 300px;
  margin: 0 auto;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 4px 28px 0 rgba(53, 64, 82, 0.1);
  ${media.max.sm`
margin-left: 0px
margin-right: 0px;
  `}
`;

export const MonthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  height: 35px;
`;

export const ChevronContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  cursor: pointer;
`;
export const MonthAndYear = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;

export const ChevronIcon = styled.img`
  width: 15px;
  height: 9.2px;
  object-fit: contain;
`;

const Icon = styled.svg`
  flex: none;
`;
const IconPath = styled.path`
  fill: ${(props) => props.theme.colors.white};
  opacity: 0.6;
  ${ChevronContainer}:hover & {
    opacity: 1;
  }
`;

export const WeekNameShortContainer = styled.div`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  height: 35px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const WeekNameShortItem = styled.div`
  flex: 1;
  text-align: center;
  border: none;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
`;
export const DayContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 7px;
  padding-right: 6px;
  padding-bottom: 6px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const DayItem = styled.div<{ selected: boolean }>`
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.primary
      : props.theme.colors.grayExtraLight};
  color: ${(props) =>
    props.selected
      ? props.theme.colors.white
      : props.theme.colors.grayExtraDark};
  :hover {
    background-color: ${(props) => props.theme.colors.deleteButtonBackground};
    color: ${(props) => props.theme.colors.primary};
  }
  cursor: pointer;
  height: 40px;
  width: 40px;
  margin-right: 1px;
  margin-bottom: 1px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InvisibleDayItem = styled.div`
  width: 40px;
  height: 40px;
  border: none;
  background: transparent;
  margin-right: 1px;
  margin-bottom: 1px;
  border: none;
`;

interface Props {
  onDateSelected: (data: any) => any;
  selected?: Date;
}
class Datepicker extends React.Component<Props> {
  public render() {
    return (
      <Dayzed
        onDateSelected={this.props.onDateSelected}
        selected={this.props.selected}
        firstDayOfWeek={1}
        render={({
          calendars,
          getBackProps,
          getForwardProps,
          getDateProps,
        }) => {
          if (calendars.length) {
            return (
              <MainCalendarContainer>
                {calendars.map((calendar) => (
                  <div
                    key={`${calendar.month}${calendar.year}`}
                    style={{
                      display: 'inline-block',
                      width: '100%',
                      boxSizing: 'border-box',
                    }}
                  >
                    <MonthContainer>
                      <ChevronContainer {...getBackProps({ calendars })}>
                        <Icon
                          xmlns="http://www.w3.org/2000/svg"
                          width="6"
                          height="9"
                          viewBox="0 0 6 9"
                        >
                          <IconPath
                            d={
                              'M.551 4.627l4.225 4.218.986-.984-3.24-3.234 3.24-3.235-.986-.984z'
                            }
                          />
                        </Icon>
                      </ChevronContainer>

                      <MonthAndYear>
                        {monthNamesShort[calendar.month]} {calendar.year}
                      </MonthAndYear>
                      <ChevronContainer {...getForwardProps({ calendars })}>
                        <Icon
                          xmlns="http://www.w3.org/2000/svg"
                          width="6"
                          height="9"
                          viewBox="0 0 6 9"
                        >
                          <IconPath
                            d={
                              'M5.762 4.627L1.537 8.845.55 7.861 3.79 4.627.551 1.392l.986-.984z'
                            }
                          />
                        </Icon>
                      </ChevronContainer>
                    </MonthContainer>
                    <WeekNameShortContainer>
                      {weekdayNamesShort.map((weekday) => (
                        <WeekNameShortItem
                          key={`${calendar.month}${calendar.year}${weekday}`}
                        >
                          {weekday}
                        </WeekNameShortItem>
                      ))}
                    </WeekNameShortContainer>
                    <DayContainer>
                      {calendar.weeks.map((week, weekIndex) =>
                        week.map((dateObj, index) => {
                          const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                          if (!dateObj) {
                            return <InvisibleDayItem key={key} />;
                          }
                          const { date, selected, selectable } = dateObj;

                          return (
                            <DayItem
                              selected={selected}
                              key={key}
                              {...getDateProps({ dateObj })}
                            >
                              {selectable ? date.getDate() : 'X'}
                            </DayItem>
                          );
                        })
                      )}
                    </DayContainer>
                  </div>
                ))}
              </MainCalendarContainer>
            );
          }
          return null;
        }}
      />
    );
  }
}

export default Datepicker;
