import { IStore } from 'app/store/types';
import {
  useSelector as _useSelector,
  useStore as _useStore,
} from 'react-redux';
import { Store } from 'redux';

export { useDispatch } from 'react-redux';
export function useSelector<T>(
  selector: (state: IStore) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  return _useSelector(selector, equalityFn);
}
export function useStore(): Store<IStore> {
  return _useStore();
}
