import { createActionCreator as c } from '@reduxify/utils';
import { IWithApolloClient } from '../global/types';
import { Notifications } from './types';

export interface ICreateSubscribe extends IWithApolloClient {
  email: string;
}

const createSubscribe = c<ICreateSubscribe>('subscribe/CREATE_SUBSCRIBE');
export { createSubscribe };

const setSbuscribeNotification = c<Notifications>('subscribe/SET_NOTIFICATION');
export { setSbuscribeNotification };
