import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';
import { dealSchema } from '../../query-utils/schema';

export interface ICreateDeal extends IWithApolloClient {
  createdBy: number;
  buyerUsersId: number;
  substance1: number;
  substance2?: number;
  substance3?: number;
  countriesOfInterest: string;
  broker?: number;
  doseFormCategory?: string;
}
export interface ICreateDealReturn {
  deals: {
    returning: {
      id: number;
    };
  };
}
export async function createDealMutation(p: ICreateDeal) {
  try {
    const date = new Date();
    const result = await p.client.mutate<ICreateDealReturn>({
      mutation: gql`
        ${new u.Mutation('createDeal')
          .params(
            ['$buyer', 'Int'],
            ['$substance1', 'Int'],
            ['$substance2', 'Int'],
            ['$substance3', 'Int'],
            ['$countriesOfInterest', 'String'],
            ['$date', 'timestamptz'],
            ['$createdBy', 'Int'],
            ['$broker', 'Int'],
            ['$doseFormCategory', 'String']
          )
          .insert(dealSchema, 'deals')
          .objects([
            ['buyer_users_id', '$buyer'],
            ['substance_1', '$substance1'],
            ['substance_2', '$substance2'],
            ['substance_3', '$substance3'],
            ['countries_of_interest', '$countriesOfInterest'],
            ['created_at', '$date'],
            ['updated_at', '$date'],
            ['created_by', '$createdBy'],
            ['broker_id', '$broker'],
            ['dose_form_category', '$doseFormCategory'],
          ])
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        buyer: p.buyerUsersId,
        substance1: p.substance1,
        substance2: p.substance2,
        substance3: p.substance3,
        countriesOfInterest: p.countriesOfInterest,
        date,
        createdBy: p.createdBy,
        broker: p.broker,
        doseFormCategory: p.doseFormCategory,
      },
    });
    return sg(() => result!.data!.deals.returning[0].id, undefined);
  } catch (e) {
    logger.error(
      'src/app/store/modules/deal/query.ts -> createDealsMutation',
      e
    );
  }
}
