import gql from 'graphql-tag';
import { logger } from '../../../utils/logger';
import { companyFragment } from '../global/fragments/company';
import {
  IProductSubstance,
  ISelectItemWithCategory,
  IWithApolloClient,
} from '../global/types';
import {
  AdminOnboardUtils,
  CompanyUtils,
  ISet,
  ProductListUtils,
  ProductUtils,
  SubstanceUtils,
} from '../query-utils';
import * as u from '../query-utils';
import {
  brandsSchema,
  doseFormsSchema,
  manufacturersSchema,
  ProductCommonFields,
  // productListSchema,
  productsSchema,
  Schema_Product,
  substanceBrandsSchema,
  substanceSchema,
  therapyAreasSchema,
} from '../query-utils/schema';
import { IProduct, productFragment } from './fragments/product';
import {
  OnBoardingFilter,
  OnBoardingFilterEnum,
  ProductFilter,
  ProductForm,
} from './types';
import { calculateProductRankingScoreFromArgs } from 'app/store/modules/products/actions';

const minCharsForFuzzySearch = 5;

export interface IFetchProductsSelectReturn {
  options: {
    value: number;
    label: string;
  }[];
  marketplace_autocomplete_aggregate: {
    aggregate: {
      totalCount: number;
    };
  };
}

export interface IFetchSubstance extends IWithApolloClient {
  type?: number;
}

export async function fetchProductsSelect(
  p: IFetchSubstance,
  offset?: number,
  limit?: number
) {
  try {
    const defaultLimit = 50;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchProductsSelectReturn>({
      query: gql`
      query productsSelect(${u.MarketplaceAutocompleteUtils.params(
        ['$limit', 'Int'],
        ['$offset', 'Int'],
        p.type !== undefined && ['$type', 'Int']
      )}) {
        options: marketplace_autocomplete(${u.MarketplaceAutocompleteUtils.limit(
          '$limit'
        )}, ${u.MarketplaceAutocompleteUtils.offset(
        '$offset'
      )}, ${u.MarketplaceAutocompleteUtils.orderBy('name', 'asc')}  ${
        p.type !== undefined
          ? u.MarketplaceAutocompleteUtils.where(
              u.MarketplaceAutocompleteUtils._eq('type', '$type')
            )
          : ''
      }) {
          ${u.MarketplaceAutocompleteUtils.selectParams(
            ['id', 'value'],
            ['name', 'label'],
            ['type', 'type']
          )}
        }
        marketplace_autocomplete_aggregate {
          aggregate {
            totalCount: count
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        limit: limit || defaultLimit,
        offset: offset || 0,
        type: p.type,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchProductsSelect',
      e
    );
  }
}

export interface IFilterProductsSelect extends IWithApolloClient {
  text: string;
  type?: number;
}

export interface IFilterProductsSelectReturn {
  options: {
    value: number;
    label: string;
  }[];
}

async function filterProductsSelectFuzzy(p: IFilterProductsSelect) {
  try {
    return await p.client.query({
      query: gql`
      query filterSubstanceFuzzy(${u.MarketplaceAutocompleteUtils.params(
        ['$text', 'String'],
        ['$limit', 'Int'],
        p.type !== undefined ? ['$type', 'Int'] : undefined
      )}) {
        options: ${
          p.type !== undefined
            ? 'search_autocomplete_with_type'
            : 'search_autocomplete'
        }(args: {
          keyword: $text,
          return_limit: $limit
          ${p.type !== undefined ? 'item_type: $type' : ''}
        }) {
          ${u.MarketplaceAutocompleteUtils.selectParams(
            ['id', 'value'],
            ['name', 'label']
          )}
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        text: p.text,
        limit: 50,
        type: p.type,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> filterProductsSelectFuzzy',
      e
    );
  }
}

export async function filterProductsSelect(p: IFilterProductsSelect) {
  if (p.text.length >= minCharsForFuzzySearch) {
    return filterProductsSelectFuzzy(p);
  }

  try {
    return await p.client.query({
      query: gql`
      query filterSubstance(${SubstanceUtils.params(
        ['$text', 'String'],
        ['$limit', 'Int'],
        p.type !== undefined && ['$type', 'Int!']
      )}) {
        options: marketplace_autocomplete(
          ${u.MarketplaceAutocompleteUtils.where(
            u._and(
              u.MarketplaceAutocompleteUtils._ilike('name', '$text'),
              p.type !== undefined &&
                u.MarketplaceAutocompleteUtils._eq('type', '$type')
            )
          )}
          ${u.MarketplaceAutocompleteUtils.limit('$limit')}
          ${u.MarketplaceAutocompleteUtils.orderBy('name', 'asc')}

        ) {
          ${SubstanceUtils.selectParams(['id', 'value'], ['name', 'label'])}
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        text: u.MarketplaceAutocompleteUtils.like(p.text, 'begining'),
        limit: 50,
        type: p.type,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> filterProductsSelect',
      e
    );
  }
}

export interface ICreateProduct extends IWithApolloClient {
  args: ProductForm;
  isAdmin: boolean;
  companyId: number;
}

export interface ICreateProductReturn {
  product: {
    returning: IProduct[];
  };
}

export async function createProductQuery(p: ICreateProduct) {
  try {
    const isCreate = p.args.id.toString() === '0';
    // set Veronika id if createdBy is undefined 11187
    const VeronikaId = 11187;

    let createdBy = isCreate && p.args.createdBy ? p.args.createdBy : undefined;

    if (p.isAdmin && !createdBy) {
      createdBy = VeronikaId;
    }
    const fields: ISet<Schema_Product>[] = [
      isCreate && ['company_id', '$companyId'],
      isCreate && ['created_by_id', '$createdBy'],
      p.isAdmin && ['admin_comments', '$adminComments'],
      ['awaiting_approval', p.isAdmin ? false : true],
      ['substance_1', '$substance1'],
      ['substance_2', '$substance2'],
      ['substance_3', '$substance3'],
      ['form_specification', '$formSpecification'],
      ['dose_form_category', '$doseFormCategory'],
      ['strength', '$strength'],
      ['dossier_status', '$dosierStatus'],
      ['dossier_completion_quarter', '$dosierCompletionQuarter'],
      ['dossier_completion_year', '$dosierCompletionYear'],
      [
        'product_available_licensing_supply',
        '$productAvailableLicensingSupply',
      ],
      [
        'product_available_supply_distribution',
        '$productAvailableSuplyDistribution',
      ],
      [
        'product_available_technology_transfer',
        '$productAvailableTechnologyTransfer',
      ],
      ['product_available_special_import', '$productAvailableSpecialImport'],
      ['dossier_format', '$dosierType'],
      ['gmp_approval_for_this_product_string', '$gmpApprovalForThisProduct'],
      ['finished_dose_country', '$finishedDoseCountry'],
      ['finished_dose_country_eu', '$finishedDoseCountryEu'],
      ['not_available_markets', '$notAvailableMarkets'],
      ['registered_in', '$registeredIn'],
      [
        'marketing_authorizations_achieved_country',
        '$marketingAuthorizationsAchievedCountry',
      ],
      ['stability_data_zones_string', '$stabilityDataZones'],
      ['cpp', '$cppAvailableFrom'],
      ['comments_on_the_patent_status', '$commentsOnThePatentStatus'],
      ['shelf_life', '$shelfLife'],
      ['batch_size', '$batchSize'],
      ['moq', '$moq'],
      ['marketing_message', '$marketingMessage'],
      ['status', '$status'],
      ['product_tags', '$productTags'],
      ['comments', '$comments'],
      ['product_ranking_manual_score', '$manualRanking'],
      ['product_ranking_auto_score', '$autoRanking'],
      ['product_ranking_score', '$productRankingScore'],
      isCreate && ['created_at', '$date'],
      ['updated_at', '$date'],
      ['clinical_data', '$clinicalData'],
      ['admin_created', '$adminCreated'],
      ['pack_size', '$packSize'],
      ['volume', '$volume'],
      p.isAdmin && ['fast_product', '$fastProduct'],
      p.isAdmin && ['competitive_product', '$competitiveProduct'],
    ];
    const q = new u.Mutation(
      isCreate ? 'insert_products_v2' : 'update_products_v2',
      {
        isAdmin: p.isAdmin,
      }
    )
      .fragments(productFragment, companyFragment)
      .params(
        !isCreate && ['$id', 'Int'],
        isCreate && ['$createdBy', 'Int'],
        isCreate && ['$companyId', 'Int'],
        ['$date', 'timestamptz'],
        ['$substance1', 'Int!'],
        ['$substance2', 'Int'],
        ['$substance3', 'Int'],
        ['$formSpecification', 'String!'],
        ['$doseFormCategory', 'String'],
        ['$strength', 'String!'],
        ['$dosierStatus', 'Int'],
        ['$dosierCompletionYear', 'String'],
        ['$dosierCompletionQuarter', 'String'],
        ['$productAvailableLicensingSupply', 'Boolean'],
        ['$productAvailableSuplyDistribution', 'Boolean'],
        ['$productAvailableTechnologyTransfer', 'Boolean'],
        ['$productAvailableSpecialImport', 'Boolean'],
        ['$dosierType', 'Int'],
        ['$gmpApprovalForThisProduct', 'String'],
        ['$finishedDoseCountry', 'String'],
        ['$finishedDoseCountryEu', 'Boolean'],
        ['$notAvailableMarkets', 'String'],
        ['$registeredIn', 'String'],
        ['$marketingAuthorizationsAchievedCountry', 'String'],
        ['$stabilityDataZones', 'String'],
        ['$cppAvailableFrom', 'String'],
        ['$commentsOnThePatentStatus', 'String'],
        ['$shelfLife', 'String'],
        ['$batchSize', 'String'],
        ['$moq', 'String'],
        ['$marketingMessage', 'String'],
        ['$status', 'Int'],
        ['$productTags', 'String'],
        ['$comments', 'String'],
        ['$autoRanking', 'Int'],
        ['$manualRanking', 'Int'],
        ['$productRankingScore', 'Int'],
        ['$clinicalData', 'String'],
        ['$adminCreated', 'Boolean'],
        p.isAdmin && ['$adminComments', 'String'],
        ['$packSize', 'String'],
        ['$volume', 'String'],

        p.isAdmin && ['$fastProduct', 'Boolean'],
        p.isAdmin && ['$competitiveProduct', 'Boolean']
      );
    if (isCreate) {
      q.insert(productsSchema, 'product')
        .objects(fields)
        .returning(productFragment, ['Company', true, companyFragment]);
    } else {
      q.update(productsSchema, 'product')
        .where((t) => t._eq('id', '$id'))
        .set(...fields)
        .returning(productFragment, ['Company', true, companyFragment]);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.mutate<ICreateProductReturn>({
      mutation: gql`
        ${q.toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: isCreate ? undefined : p.args.id,
        companyId: p.companyId && isCreate ? p.companyId : undefined,
        substance1: p.args.substance1,
        substance2: p.args.substance2 !== 0 ? p.args.substance2 : undefined,
        substance3: p.args.substance3 !== 0 ? p.args.substance3 : undefined,
        formSpecification: p.args.formSpecification,
        doseFormCategory: p.args.doseFormCategory,
        strength: p.args.strength,
        dosierStatus: p.args.dosierStatus,
        dosierCompletionYear: p.args.dosierCompletionYear,
        dosierCompletionQuarter: p.args.dosierCompletionQuarter,
        productAvailableLicensingSupply: p.args.productAvailableLicensingSupply,
        productAvailableSuplyDistribution:
          p.args.productAvailableSuplyDistribution,
        productAvailableTechnologyTransfer:
          p.args.productAvailableTechnologyTransfer,
        productAvailableSpecialImport: p.args.productAvailableSpecialImport,
        dosierType: p.args.dosierType,
        gmpApprovalForThisProduct: p.args.gmpApprovalForThisProduct.join(','),
        finishedDoseCountry: p.args.finishedDoseCountry,
        finishedDoseCountryEu: p.args.finishedDoseCountryEu,
        notAvailableMarkets: p.args.notAvailableMarkets.join(','),
        registeredIn: p.args.registeredIn.join(','),
        marketingAuthorizationsAchievedCountry: p.args.marketingAuthorizationsAchievedCountry.join(
          ','
        ),
        stabilityDataZones: p.args.stabilityDataZones.join(','),
        cppAvailableFrom: p.args.cppAvailableFrom.join(','),
        commentsOnThePatentStatus: p.args.commentsOnThePatentStatus,
        shelfLife: p.args.shelfLife,
        batchSize: p.args.batchSize,
        moq: p.args.moq,
        marketingMessage: p.args.marketingMessage,
        status: p.isAdmin ? p.args.status : 0,
        // productTags: p.args.productTags.join(','),
        productTags: p.args.productTags,
        comments: p.args.comments,
        autoRanking:
          p.args.autoRanking.length === 0
            ? null
            : parseFloat(p.args.autoRanking),
        manualRanking:
          p.args.manualRanking.length === 0
            ? null
            : parseFloat(p.args.manualRanking),
        productRankingScore: calculateProductRankingScoreFromArgs(p.args),
        date: new Date().toISOString(),
        clinicalData: p.args.clinicalData,
        adminCreated: p.args.adminCreated,
        createdBy: createdBy && isCreate ? createdBy : undefined,
        adminComments: p.isAdmin ? p.args.adminComments : undefined,
        fastProduct: p.isAdmin ? p.args.fastProduct : undefined,
        competitiveProduct: p.isAdmin ? p.args.competitiveProduct : undefined,
        packSize: p.args.packSize,
        volume: p.args.volume,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> createProductQuery',
      e
    );
  }
}

export interface IGetProductList extends IWithApolloClient {
  size: number;
  offset: number;
  companyId?: number;
  isAdmin: boolean;
  isSuggestedProducts?: boolean;
  filter?: ProductFilter;
  type?: OnBoardingFilter;
  isMarkeplaceView?: boolean;
  sortByField?: string;
  sortType?: string;
}

export interface IGetProductListReturn {
  products: {
    Product: IProduct;
    ranking: number;
    updatedAt: Date;
    productId: number;
  }[];
  products_aggregate: { aggregate: { totalCount: number } };
}

export async function getProductListQuery(p: IGetProductList) {
  try {
    const sortByField = p.sortByField ? p.sortByField : 'updated_at';
    const sortType = p.sortType ? p.sortType : 'desc';

    const companyCheck =
      !p.isAdmin &&
      !p.isSuggestedProducts &&
      CompanyUtils._eq('id', '$companyId');

    // eslint-disable-next-line
    /* const marketOfInterests = (
      (p && p.filter && p.filter.marketOfInterests) ||
      ''
    ).split(',');*/
    const productWhere = ProductUtils._and(
      !p.isAdmin &&
        !p.isSuggestedProducts &&
        ProductUtils._or(
          ProductUtils._eq('admin_created', false),
          ProductUtils._is_null('admin_created', true)
        ),
      p?.isAdmin && ProductUtils._eq('status', 1),
      ProductUtils._is_null('deleted_at'),
      ProductUtils._is_null('form_specification', false),
      ProductUtils._is_null('strength', false),
      ProductUtils._and(
        ProductUtils.nest(
          'Company',
          CompanyUtils._and(
            !p.isSuggestedProducts &&
              (p.isAdmin && p.filter && p.filter.company
                ? CompanyUtils._ilike('name', '$company')
                : ''),
            CompanyUtils._eq('hidden', '$hidden'),
            companyCheck
          )
        )
      ),
      ProductUtils._or(
        ProductUtils.nest(
          'Substance1',
          SubstanceUtils._ilike('name', '$product')
        ),
        ProductUtils.nest(
          'Substance2',
          SubstanceUtils._ilike('name', '$product')
        ),
        ProductUtils.nest(
          'Substance3',
          SubstanceUtils._ilike('name', '$product')
        )
      ),
      ProductUtils._ilike('form_specification', '$form'),
      p.filter &&
        p.filter.lastUpdateFrom &&
        ProductUtils._gte('updated_at', '$updatedFrom'),
      p.filter &&
        p.filter.lastUpdateTo &&
        ProductUtils._lte('updated_at', '$updatedTo'),
      p.filter && p.filter.owned !== undefined
        ? ProductUtils._eq('admin_created', p.filter.owned)
        : undefined,
      p.filter && p.filter.competitive === true
        ? ProductUtils._eq('competitive_product', true)
        : undefined
    );
    const queryParameters = {
      isAdmin: p.isAdmin,
      isLogged: !!p.companyId,
      isElastic: false,
    };

    const full = !!p.isMarkeplaceView;
    let selectFields: any[];
    if (full) {
      selectFields = [
        'id',
        'cpp',
        'moq',
        'status',
        'strength',
        ['id', 'productId'],
        ['updated_at', 'updatedAt'],
        ['product_ranking_manual_score', 'manualRanking'],
        ['product_ranking_auto_score', 'autoRanking'],
        ['product_ranking_score', 'productRankingScore'],
        ['form_specification', 'formSpecification'],
        ['dossier_status', 'dossierStatus'],
        ['dossier_completion_quarter', 'dossierCompletionQuarter'],
        ['dossier_completion_year', 'dossierCompletionYear'],
        [
          'product_available_licensing_supply',
          'productAvailableLicensingSupply',
        ],
        [
          'product_available_supply_distribution',
          'productAvailableSupplyDistribution',
        ],
        [
          'product_available_technology_transfer',
          'productAvailableTechnologyTransfer',
        ],

        ['product_available_special_import', 'productAvailableSpecialImport'],
        ['dossier_format', 'dosierType'],
        ['dossier_status', 'dossierStatus'],
        ['gmp_approval_for_this_product_string', 'gmpApprovalForThisProduct'],
        ['finished_dose_country', 'finishedDoseCountry'],
        ['finished_dose_country_eu', 'finishedDoseCountryEu'],

        ['not_available_markets', 'notAvailableMarkets'],
        ['registered_in', 'registeredIn'],
        [
          'marketing_authorizations_achieved_country',
          'marketingAuthorizationsAchievedCountry',
        ],
        ['stability_data_zones_string', 'stabilityDataZones'],

        ['clinical_data', 'clinicalData'],
        ['comments_on_the_patent_status', 'commentsOnThePatentStatus'],
        ['shelf_life', 'shelfLife'],
        ['batch_size', 'batchSize'],
        ['marketing_message', 'marketingMessage'],

        ['product_tags', 'productTags'],
        ['comments', 'comments'],
        ['product_owner_id', 'productOwnerId'],
        ['created_at', 'createdAt'],
        ['company_id', 'companyId'],
        ['admin_created', 'adminCreated'],

        ['created_by_id', 'createdBy'],
        ['fast_product', 'fastProduct'],
        ['competitive_product', 'competitiveProduct'],
        ['dose_form_category', 'doseFormCategory'],
        ['pack_size', 'packSize'],
        ['product_category', 'productCategory'],
        ['volume', 'volume'],
        [
          'Substance1',
          true,
          SubstanceUtils.selectParams('id', 'name', [
            'SubstanceBrands',
            true,
            u.SubstanceBrandsUtils.selectParams('brand_name'),
          ]),
        ],
        [
          'Substance2',
          true,
          SubstanceUtils.selectParams('id', 'name', [
            'SubstanceBrands',
            true,
            u.SubstanceBrandsUtils.selectParams('brand_name'),
          ]),
        ],
        [
          'Substance3',
          true,
          SubstanceUtils.selectParams('id', 'name', [
            'SubstanceBrands',
            true,
            u.SubstanceBrandsUtils.selectParams('brand_name'),
          ]),
        ],
        ['Company', true, companyFragment],
        p.isAdmin ? ['admin_comments', 'adminComments'] : undefined,
      ].filter((s) => s !== undefined);
    } else {
      selectFields = [
        'id',
        'status',
        'strength',
        ['id', 'productId'],
        ['updated_at', 'updatedAt'],
        ['product_ranking_manual_score', 'manualRanking'],
        ['product_ranking_auto_score', 'autoRanking'],
        ['product_ranking_score', 'productRankingScore'],
        ['form_specification', 'formSpecification'],
        ['product_owner_id', 'productOwnerId'],
        ['created_at', 'createdAt'],
        ['company_id', 'companyId'],
        ['admin_created', 'adminCreated'],
        ['product_category', 'productCategory'],
        ['created_by_id', 'createdBy'],
        [
          'Substance1',
          true,
          SubstanceUtils.selectParams('id', 'name', [
            'SubstanceBrands',
            true,
            u.SubstanceBrandsUtils.selectParams('brand_name'),
          ]),
        ],
        [
          'Substance2',
          true,
          SubstanceUtils.selectParams('id', 'name', [
            'SubstanceBrands',
            true,
            u.SubstanceBrandsUtils.selectParams('brand_name'),
          ]),
        ],
        [
          'Substance3',
          true,
          SubstanceUtils.selectParams('id', 'name', [
            'SubstanceBrands',
            true,
            u.SubstanceBrandsUtils.selectParams('brand_name'),
          ]),
        ],
        ['Company', true, companyFragment],
      ].filter((s) => s !== undefined);
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetProductListReturn>({
      query: gql`
        ${new u.Query('productListPageFull', queryParameters)
          .fragments(productFragment, companyFragment)
          .params(
            ['$size', 'Int'],
            ['$offset', 'Int'],
            !p.isAdmin && ['$companyId', 'Int'],
            p.filter && p.filter.company ? ['$company', 'String'] : undefined,
            ['$form', 'String'],
            ['$product', 'String'],
            ['$hidden', 'Boolean'],
            ['$updatedFrom', 'timestamptz'],
            ['$updatedTo', 'timestamptz']
          )
          .query(productsSchema, 'products')
          .limit('$size')
          .offset('$offset')
          .orderBy(sortByField as any, sortType as any)
          .where(() => productWhere)
          .select(...selectFields)
          .toString()
          .aggregate(productsSchema, 'products_aggregate')
          .where(() => productWhere)
          .count('totalCount')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        size: p.size,
        offset: p.offset,
        companyId: !p.isAdmin ? p.companyId : undefined,
        company:
          p.filter && p.filter.company
            ? ProductListUtils.like(p.filter.company, 'middle')
            : undefined,
        form: ProductListUtils.like(p.filter?.form || '', 'middle'),
        product: ProductListUtils.like(p.filter?.product || '', 'begining'),
        hidden: p.filter && !p.filter.visible,
        updatedFrom:
          p.filter && p.filter.lastUpdateFrom
            ? p.filter.lastUpdateFrom
            : undefined,
        updatedTo:
          p.filter && p.filter.lastUpdateTo ? p.filter.lastUpdateTo : undefined,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getProductListQuery',
      e
    );
  }
}

export async function getProductListQuerySmall(p: IGetProductList) {
  try {
    const companyCheck =
      !p.isAdmin &&
      !p.isSuggestedProducts &&
      CompanyUtils._eq('id', '$companyId');

    // eslint-disable-next-line
    /* const marketOfInterests = (
      (p && p.filter && p.filter.marketOfInterests) ||
      ''
    ).split(',');*/
    const productWhere = ProductUtils._and(
      !p.isAdmin &&
        !p.isSuggestedProducts &&
        ProductUtils._or(
          ProductUtils._eq('admin_created', false),
          ProductUtils._is_null('admin_created', true)
        ),
      ProductUtils._is_null('deleted_at'),
      ProductUtils._is_null('form_specification', false),
      ProductUtils._is_null('strength', false),
      ProductUtils._and(
        ProductUtils.nest(
          'Company',
          CompanyUtils._and(
            !p.isSuggestedProducts &&
              (p.isAdmin && p.filter && p.filter.company
                ? CompanyUtils._ilike('name', '$company')
                : ''),
            CompanyUtils._eq('hidden', '$hidden'),
            companyCheck
          )
        )
      ),
      ProductUtils._or(
        ProductUtils.nest(
          'Substance1',
          SubstanceUtils._ilike('name', '$product')
        ),
        ProductUtils.nest(
          'Substance2',
          SubstanceUtils._ilike('name', '$product')
        ),
        ProductUtils.nest(
          'Substance3',
          SubstanceUtils._ilike('name', '$product')
        )
      ),
      ProductUtils._ilike('form_specification', '$form'),
      p.filter &&
        p.filter.lastUpdateFrom &&
        ProductUtils._gte('updated_at', '$updatedFrom'),
      p.filter &&
        p.filter.lastUpdateTo &&
        ProductUtils._lte('updated_at', '$updatedTo'),
      p.filter && p.filter.owned !== undefined
        ? ProductUtils._eq('admin_created', p.filter.owned)
        : undefined,
      p.filter && p.filter.competitive === true
        ? ProductUtils._eq('competitive_product', true)
        : undefined
    );
    const queryParameters = {
      isAdmin: p.isAdmin,
      isLogged: !!p.companyId,
      isElastic: false,
    };

    const selectFields: any[] = [
      'id',
      'status',
      'strength',
      ['id', 'productId'],
      ['updated_at', 'updatedAt'],
      ['product_ranking_manual_score', 'manualRanking'],
      ['product_ranking_auto_score', 'autoRanking'],
      ['product_ranking_score ', 'productRankingScore'],
      ['form_specification', 'formSpecification'],
      ['product_owner_id', 'productOwnerId'],
      ['created_at', 'createdAt'],
      ['company_id', 'companyId'],
      ['admin_created', 'adminCreated'],

      ['created_by_id', 'createdBy'],
      [
        'Substance1',
        true,
        SubstanceUtils.selectParams('id', 'name', [
          'SubstanceBrands',
          true,
          u.SubstanceBrandsUtils.selectParams('brand_name'),
        ]),
      ],
      [
        'Substance2',
        true,
        SubstanceUtils.selectParams('id', 'name', [
          'SubstanceBrands',
          true,
          u.SubstanceBrandsUtils.selectParams('brand_name'),
        ]),
      ],
      [
        'Substance3',
        true,
        SubstanceUtils.selectParams('id', 'name', [
          'SubstanceBrands',
          true,
          u.SubstanceBrandsUtils.selectParams('brand_name'),
        ]),
      ],
      ['Company', true, companyFragment],
    ].filter((s) => s !== undefined);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetProductListReturn>({
      query: gql`
        ${new u.Query('productListPage', queryParameters)
          .fragments(productFragment, companyFragment)
          .params(
            ['$size', 'Int'],
            ['$offset', 'Int'],
            !p.isAdmin && ['$companyId', 'Int'],
            p.filter && p.filter.company ? ['$company', 'String'] : undefined,
            ['$form', 'String'],
            ['$product', 'String'],
            ['$hidden', 'Boolean'],
            ['$updatedFrom', 'timestamptz'],
            ['$updatedTo', 'timestamptz']
          )
          .query(productsSchema, 'products')
          .limit('$size')
          .offset('$offset')
          .orderBy('id', 'desc')
          .where(() => productWhere)
          .select(...selectFields)
          .toString()
          .aggregate(productsSchema, 'products_aggregate')
          .where(() => productWhere)
          .count('totalCount')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        size: p.size,
        offset: p.offset,
        companyId: !p.isAdmin ? p.companyId : undefined,
        company:
          p.filter && p.filter.company
            ? ProductListUtils.like(p.filter.company, 'middle')
            : undefined,
        form: ProductListUtils.like(p.filter?.form || '', 'middle'),
        product: ProductListUtils.like(p.filter?.product || '', 'begining'),
        hidden: p.filter && !p.filter.visible,
        updatedFrom:
          p.filter && p.filter.lastUpdateFrom
            ? p.filter.lastUpdateFrom
            : undefined,
        updatedTo:
          p.filter && p.filter.lastUpdateTo ? p.filter.lastUpdateTo : undefined,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getProductListQuerySmall',
      e
    );
  }
}

export interface IGetProduct extends IWithApolloClient {
  id: number;
  isAdmin: boolean;
  isLoggedIn: boolean;
  isProductForm?: boolean;
}

export interface IGetProductReturn {
  products: IProduct[];
}

export async function getProductQuery(p: IGetProduct) {
  try {
    const UtilsToUse = new u.Utils<ProductCommonFields>();
    const fragmentToUse = productFragment;
    const notDeleted = u._is_null('deleted_at');
    const companyWhere = u.nest('Company', u._eq('hidden', false));

    const productWhere = (isProductForm?: boolean) =>
      u._and(
        UtilsToUse._eq('id', '$id'),
        notDeleted,
        companyWhere,
        !isProductForm && u._gte('status', 1)
      );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetProductReturn>({
      query: gql`
      ${fragmentToUse.fragment(p.isAdmin, p.isLoggedIn)}
      ${companyFragment.fragment(p.isAdmin)}

      query getProduct(${UtilsToUse.params(
        ['$id', 'Int']
        // ['$date', 'timestamptz']
      )}) {
        ${'products'}: ${'products_v2'}(${UtilsToUse.where(
        productWhere(p.isProductForm)
      )}) {
          ${UtilsToUse.selectParams(fragmentToUse, [
            'Company',
            true,
            companyFragment,
          ])}
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        // date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getProductQuery',
      e
    );
  }
}

export interface IDeleteProduct extends IWithApolloClient {
  id: number;
}

export interface IDeleteProductReturn {
  update_products_v2: {
    returning: { id: number }[];
  };
}

export async function deleteProductQuery(p: IDeleteProduct) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.mutate<IDeleteProductReturn>({
      mutation: gql`
      mutation deleteProduct(${ProductUtils.params(
        ['$id', 'Int'],
        ['$deletedAt', 'timestamptz']
      )}) {
        update_products_v2(
          ${ProductUtils.where(ProductUtils._eq('id', '$id'))}
          ${ProductUtils._set(['deleted_at', '$deletedAt'])}
        ) {
          returning {
            ${ProductUtils.selectParams('id')}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        deletedAt: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> deleteProductQuery',
      e
    );
  }
}

export interface IGetProductSelect extends IWithApolloClient {
  product: string;
  isAdmin: boolean;
}

export interface IGetProductSelectReturn {
  products: {
    id: number;
    Substance1: IProductSubstance;
    Substance2?: IProductSubstance;
    Substance3?: IProductSubstance;
    autoRanking: number;
    formSpecification: string;
  }[];
}

export async function getProductSelectQuery(p: IGetProductSelect) {
  try {
    return await p.client.query({
      query: gql`
      query productListPage(${ProductUtils.params(
        ['$product', 'String'],
        ['$limit', 'Int']
      )}) {
        products: products_v2(
          ${ProductUtils.limit('$limit')}
          order_by: { Substance1: { name: asc } }
          ${ProductUtils.where(
            ProductUtils._and(
              !p.isAdmin &&
                ProductUtils._or(
                  ProductUtils._eq('admin_created', false),
                  ProductUtils._is_null('admin_created', true)
                ),
              ProductUtils._is_null('deleted_at'),
              ProductUtils._is_null('form_specification', false),
              ProductUtils._is_null('strength', false),

              ProductUtils.nest('Company', CompanyUtils._eq('hidden', false)),
              ProductUtils._or(
                ProductUtils.nest(
                  'Substance1',
                  SubstanceUtils._ilike('name', '$product')
                ),
                ProductUtils.nest(
                  'Substance2',
                  SubstanceUtils._ilike('name', '$product')
                ),
                ProductUtils.nest(
                  'Substance3',
                  SubstanceUtils._ilike('name', '$product')
                )
              )
            )
          )}
        ) {
          ${ProductUtils.selectParams(
            'id',
            ['product_ranking_auto_score', 'autoRanking'],
            ['product_ranking_manual_score', 'manualRanking'],
            ['product_ranking_score', 'productRankingScore'],
            ['form_specification', 'formSpecification'],
            ['pack_size', 'packSize'],
            ['volume', 'volume'],
            ['Substance1', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance2', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance3', true, SubstanceUtils.selectParams('id', 'name')],
            ['created_by_id', 'createdBy']
          )}
        }
      }
    `,
      variables: {
        limit: 1000,
        product: ProductUtils.like(p.product, 'begining'),
      },
      fetchPolicy: 'no-cache',
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getProductSelectQuery',
      e
    );
  }
}

export interface IGetProductSelectByCompany extends IWithApolloClient {
  product: string;
  companyId: number;
  isAdmin?: boolean;
}

export function getProductSElectByCompanyQueryText(p: {
  product: string;
  companyId: number;
  isAdmin?: boolean;
}) {
  const utils = new u.Utils<ProductCommonFields>();
  const whereClause = u.where(
    u._and(
      !p.isAdmin &&
        ProductUtils._or(
          ProductUtils._eq('admin_created', false),
          ProductUtils._is_null('admin_created', true)
        ),
      utils._gte('status', 1),
      utils._is_null('deleted_at'),
      utils._is_null('form_specification', false),
      utils._is_null('strength', false),
      !p.isAdmin && utils.nest('Company', CompanyUtils._eq('id', '$companyId')),
      utils.nest('Company', CompanyUtils._eq('hidden', false)),
      u._or(
        utils.nest('Substance1', SubstanceUtils._ilike('name', '$product')),
        utils.nest('Substance2', SubstanceUtils._ilike('name', '$product')),
        utils.nest('Substance3', SubstanceUtils._ilike('name', '$product'))
      )
    )
  );

  return gql`
      query productListPage(${ProductUtils.params(
        ['$product', 'String'],
        ['$companyId', 'Int'],
        ['$limit', 'Int']
      )}) {
        products: ${'products_v2'}(
          ${ProductUtils.limit('$limit')}
          order_by: { Substance1: { name: asc } }
          ${whereClause}
        ) {
          ${u.selectParams<any>(
            'id',
            ['form_specification', 'formSpecification'],
            ['strength', 'strength'],
            ['pack_size', 'packSize'],
            ['volume', 'volume'],
            ['product_ranking_auto_score', 'autoRanking'],
            ['product_ranking_manual_score', 'manualRanking'],
            ['product_ranking_score', 'productRankingScore'],
            ['Substance1', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance2', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance3', true, SubstanceUtils.selectParams('id', 'name')],
            'status',
            ['created_by_id', 'createdBy']
          )}
        }
      }
    `;
}

export async function getProductSelectByCompanyQuery(
  p: IGetProductSelectByCompany,
  trade = false
) {
  try {
    const query = getProductSElectByCompanyQueryText(p);
    return await p.client.query({
      query,
      variables: {
        product: ProductUtils.like(p.product, 'begining'),
        companyId:
          typeof p.companyId === 'string'
            ? parseInt(p.companyId, 10)
            : p.companyId,
        limit: 1000,
      },
      fetchPolicy: 'no-cache',
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getProductSelectByCompanyQuery',
      e
    );
  }
}

export interface IGetAdminOnboardingList extends IWithApolloClient {
  size: number;
  offset: number;
  filter?: OnBoardingFilter;
}

export interface IGetAdminOnboardingListReturn {
  admin_onboarding: {
    Product: IProduct;
    ranking: number;
    updatedAt: Date;
    productId: number;
  }[];
  admin_onboarding_aggregate: { aggregate: { totalCount: number } };
}

export async function getAdminOnboardingListQuery(p: IGetAdminOnboardingList) {
  try {
    const productWhereQuery = (name: string) =>
      ProductUtils._and(
        ProductUtils._is_null('deleted_at'),
        ProductUtils._is_null('form_specification', false),
        ProductUtils._is_null('strength', false),
        ProductUtils._and(
          ProductUtils.nest(
            'Company',
            CompanyUtils._and(
              CompanyUtils._eq('hidden', '$hidden')
              // CompanyUtils._ilike('name', '$company')
            )
          )
        ),
        ProductUtils._eq('awaiting_approval', true)

        // name === 'Product' &&
        //   ProductUtils._or(
        //     ProductUtils.nest(
        //       'Substance1',
        //       SubstanceUtils._ilike('name', '$product')
        //     ),
        //     ProductUtils.nest(
        //       'Substance2',
        //       SubstanceUtils._ilike('name', '$product')
        //     ),
        //     ProductUtils.nest(
        //       'Substance3',
        //       SubstanceUtils._ilike('name', '$product')
        //     )
        //   )
      );

    const where = AdminOnboardUtils.where(
      AdminOnboardUtils._and(
        AdminOnboardUtils._or(
          AdminOnboardUtils.nest('Product', productWhereQuery('Product'))
        ),
        p.filter &&
          p.filter === OnBoardingFilterEnum.trade &&
          AdminOnboardUtils._is_null('productid', true)
      )
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetAdminOnboardingListReturn>({
      query: gql`
        ${productFragment.fragment(true)}
        ${companyFragment.fragment(true)}
      

        query productListPage(${AdminOnboardUtils.params(
          ['$size', 'Int'],
          ['$offset', 'Int'],
          // ['$company', 'String'],
          // ['$form', 'String'],
          // ['$product', 'String'],
          ['$hidden', 'Boolean']
        )}) {
          admin_onboarding: admin_onboarding(
          ${AdminOnboardUtils.limit('$size')}
          ${AdminOnboardUtils.offset('$offset')}
          ${AdminOnboardUtils.orderBy('updated_at', 'desc')}
          ${where}
        ) {
          ${AdminOnboardUtils.selectParams(
            [
              'Product',
              true,
              ProductUtils.selectParams(productFragment, [
                'Company',
                true,
                companyFragment,
              ]),
            ],
            'ranking',
            ['updated_at', 'updatedAt'],
            ['productid', 'productId']
          )}
        }
        admin_onboarding_aggregate: admin_onboarding_aggregate(${where}) {
          aggregate {
            totalCount: count
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        size: p.size,
        offset: p.offset,
        // company: AdminOnboardUtils.like('', 'middle'),
        // form: AdminOnboardUtils.like('', 'begining'),
        // product: AdminOnboardUtils.like('', 'begining'),
        hidden: false,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> getAdminOnboardingListQuery',
      e
    );
  }
}

export interface IFetchBrandNames extends IWithApolloClient {
  amount: number;
  template: string;
}

export interface IFetchBrandNamesReturn {
  brands: ISelectItem<string>[];
}

export async function fetchBrandNames(p: IFetchBrandNames) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchBrandNamesReturn>({
      query: gql`
        ${new u.Query('fetchBrandNames')
          .params(['$amount', 'Int'], ['$text', 'String'])
          .query(brandsSchema, 'brands')
          .select(['id', 'value'], ['name', 'label'])
          .limit('$amount')
          .where((t) => t._ilike('name', '$text'))
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        amount: p.amount,
        text: u.like(p.template, 'begining'),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchBrandNames',
      e
    );
  }
}

export interface IFetchExactDoseForms extends IWithApolloClient {
  amount: number;
  template: string;
}

export interface IFetchExactDoseFormsReturn {
  doseForms: ISelectItemWithCategory<string>[];
}

export async function fetchExactDoseForms(p: IFetchExactDoseForms) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchExactDoseFormsReturn>({
      query: gql`
        ${new u.Query('fetchExactDoseForms')
          .params(['$amount', 'Int'], ['$text', 'String'])
          .query(doseFormsSchema, 'doseForms')
          .select(['id', 'value'], ['name', 'label'], 'category')
          .limit('$amount')
          .where((t) => t._ilike('name', '$text'))
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        amount: p.amount,
        text: u.like(p.template, 'begining'),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchExactDoseForms',
      e
    );
  }
}

export interface IFetchDoseForms extends IWithApolloClient {
  amount: number;
  template: string;
}

export interface IFetchDoseFormsReturn {
  doseForms: ISelectItemWithCategory<string>[];
}

export async function fetchDoseForms(p: IFetchDoseForms) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchDoseFormsReturn>({
      query: gql`
        ${new u.Query('fetchDoseForms')
          .params(['$amount', 'Int'], ['$text', 'String'])
          .query(doseFormsSchema, 'doseForms')
          .select(['id', 'value'], ['name', 'label'], 'category')
          .limit('$amount')
          .where((t) => t._ilike('name', '$text'))
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        amount: p.amount,
        text: u.like(p.template, 'middle'),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchDoseForms',
      e
    );
  }
}

export interface IFetchDoseCategories extends IWithApolloClient {
  template: string;
}

export interface IFetchDoseCategoriesReturn {
  doseCategories: ISelectItem<string>[];
}

export async function fetchDoseCategories(p: IFetchDoseCategories) {
  try {
    //
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchDoseCategoriesReturn>({
      query: gql`
          query fetchDoseCategories(${u.MarketplaceAutocompleteUtils.params([
            '$text',
            'String',
          ])}

          ) {
            doseCategories: forms( ${u.MarketplaceAutocompleteUtils.where(
              u._ilike('category', '$text')
            )}
            distinct_on: [category]
            ) {
              ${
                //
                u.selectParams(
                  ['category', 'value'] as any,
                  ['category', 'label'] as any
                )
              }
            }
          }
      `,

      fetchPolicy: 'no-cache',
      variables: {
        text: u.like(p.template, 'middle'),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchDoseCategories',
      e
    );
  }
}

export interface IFetchTherapyAreas extends IWithApolloClient {
  amount: number;
  template: string;
}

export interface IFetchTherapyAreasReturn {
  therapyAreas: ISelectItem<string>[];
}

export async function fetchTherapyAreas(p: IFetchTherapyAreas) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchTherapyAreasReturn>({
      query: gql`
        ${new u.Query('fetchTherapyAreas')
          .params(['$amount', 'Int'], ['$text', 'String'])
          .query(therapyAreasSchema, 'therapyAreas')
          .select(['therapy_area', 'value'], ['display', 'label'])
          .limit('$amount')
          .where((t) => t._ilike('display', '$text'))
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        amount: p.amount,
        text: u.like(p.template, 'middle'),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchTherapyAreas',
      e
    );
  }
}

export interface IFetchManufacturerNames extends IWithApolloClient {
  amount: number;
  template: string;
}

export interface IFetchManufacturerNamesReturn {
  manufacturers: ISelectItem<string>[];
}

export async function fetchManufacturerNames(p: IFetchManufacturerNames) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchManufacturerNamesReturn>({
      query: gql`
        ${new u.Query('fetchManufacturerNames')
          .params(['$amount', 'Int'], ['$text', 'String'])
          .query(manufacturersSchema, 'manufacturers')
          .select(['id', 'value'], ['name', 'label'])
          .limit('$amount')
          .where((t) => t._ilike('name', '$text'))
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        amount: p.amount,
        text: u.like(p.template, 'begining'),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchManufacturerNames',
      e
    );
  }
}

export interface IFetchSEOTextFromSubstance extends IWithApolloClient {
  text: string;
}

export interface IFetchSEOTextFromCountry extends IWithApolloClient {
  countryCode: string;
}

export interface IFetchSEOTextFromSubstanceReturn {
  substance: {
    id: number;
    text: string;
  }[];
}

export async function fetchSubstanceSEOTextByName(
  p: IFetchSEOTextFromSubstance
) {
  try {
    return await p.client.query({
      query: gql`
        ${new u.Query('substances')
          .params(['$text', 'String'])
          .query(substanceSchema, 'substances')
          .where((w) => w._ilike('name', '$text'))
          .limit(1)
          .select('id', ['name', 'text'], ['seo_text_footer', 'seoText'])
          .toString()
          .query(substanceBrandsSchema, 'brands')
          .where((w) => w._ilike('brand_name', '$text'))
          .limit(1)
          .select(
            'id',
            ['brand_name', 'name'],
            ['substance_name', 'substanceName']
          )
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        text: `%${p.text}%`,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchSubstanceSEOTextByName',
      e
    );
  }
}

export async function fetchCountrySEOTextByCode(p: IFetchSEOTextFromCountry) {
  try {
    return await p.client.query({
      query: gql`
      query countries(${u.MarketplaceAutocompleteUtils.params(
        ['$countryCode', 'String'],
        ['$limit', 'Int']
      )}

      ) {
        countries( ${u.MarketplaceAutocompleteUtils.where(
          u._eq('code', '$countryCode')
        )}
        ${u.limit('1')}) {
          ${
            //
            u.selectParams(
              ['name', 'text'] as any,
              ['seo_text_footer', 'seoText'] as any
            )
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        countryCode: p.countryCode,
        limit: 1,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/products/query.ts -> fetchCountrySEOTextByCode',
      e
    );
  }
}
