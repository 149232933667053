import React from 'react';

export const Bubble: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="11"
    height="11"
    viewBox="0 0 11 11"
    {...props}
  >
    <path
      fill="#1C8ED3"
      fillRule="nonzero"
      d="M9.706 0H1.294C.58 0 0 .58 0 1.294v5.824c0 .713.58 1.294 1.294 1.294h.647V11l3.45-2.588h4.315c.714 0 1.294-.58 1.294-1.294V1.294C11 .58 10.42 0 9.706 0"
    />
  </svg>
);
