import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';
import { dealSchema } from '../../query-utils/schema';

export interface IUpdateDeal extends IWithApolloClient {
  id: number;
  buyerUsersId: number;
  substance1: number;
  substance2?: number;
  substance3?: number;
  countriesOfInterest: string;
  broker?: number;
  doseFormCategory?: string;
}
export async function updateDealMutation(p: IUpdateDeal) {
  try {
    const date = new Date();
    return await p.client.mutate({
      mutation: gql`
        ${new u.Mutation('updateDeal')
          .params(
            ['$id', 'Int'],
            ['$buyer', 'Int'],
            ['$substance1', 'Int'],
            ['$substance2', 'Int'],
            ['$substance3', 'Int'],
            ['$countriesOfInterest', 'String'],
            ['$date', 'timestamptz'],
            ['$broker', 'Int'],
            ['$doseFormCategory', 'String']
          )
          .update(dealSchema, 'deals')
          .where((t) => t._eq('id', '$id'))
          .set(
            ['buyer_users_id', '$buyer'],
            ['substance_1', '$substance1'],
            ['substance_2', '$substance2'],
            ['substance_3', '$substance3'],
            ['countries_of_interest', '$countriesOfInterest'],
            ['updated_at', '$date'],
            ['broker_id', '$broker'],
            ['dose_form_category', '$doseFormCategory']
          )
          .returning('id')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        buyer: p.buyerUsersId,
        substance1: p.substance1,
        substance2: p.substance2,
        substance3: p.substance3,
        countriesOfInterest: p.countriesOfInterest,
        date,
        broker: p.broker,
        doseFormCategory: p.doseFormCategory,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/deal/query.ts -> updateDealMutation',
      e
    );
  }
}
