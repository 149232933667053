import Modal from 'app/components/modals/overrided-modal';
import { _, media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React from 'react';
import styled from 'styled-components';
import VerifyEmail from './verify-email';

const ModalContainer = styled.div`
  margin: 15px;
  z-index: 100;
  padding-top: 0px;
  align-items: flex-start;
  ${_(media.max.lg)} {
    padding-top: 0px;
    margin: 5px;
  }
  ${_(media.max.md)} {
    margin: 0px;
  }
  ${_(media.max.sm)} {
    margin: 0px;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
  position: relative;
  > div {
    position: absolute;
    margin: 0 auto;
    right: 30px;
    top: 10px;

    ${_(media.max.xs)} {
      right: 10px;
      top: 0px;
    }
  }
`;

const CloseIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: fixed;
`;

interface Props {
  closeModal: () => any;
  isOpen: boolean;
  email: string;
}
const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderWidth: 0,
    borderRadius: 6,
    boxShadow: colors.modalShadow,
    overflow: 'auto',
    position: 'static',
    margin: 'auto',
    maxHeight: '90%',
  },
  overlay: {
    backgroundColor: colors.darkModalOverlay,
    zIndex: 999,
    display: 'flex',
  },
};

const VerifyEmailModal: React.FC<Props> = ({ isOpen, email, closeModal }) => (
  <Modal
    isOpen={isOpen}
    onRequestClose={closeModal}
    style={customStyles as any}
    contentLabel="Verify email"
    ariaHideApp={false}
  >
    <IconContainer>
      <div onClick={closeModal}>
        <CloseIcon src={'/img/close.svg'} />
      </div>
    </IconContainer>
    <ModalContainer>
      <VerifyEmail email={email} />
    </ModalContainer>
  </Modal>
);

export default VerifyEmailModal;
