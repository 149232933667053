import { sg } from 'app/utils/safe-get';
import * as React from 'react';
import {
  checkForError,
  getError,
  IRule,
  IWrappedComponent,
} from '../validation';
import TextInput, { Components, ErrorMessage, Props } from './text-input';

interface IProps extends Omit<Props, 'onChange' | 'value' | 'error'> {
  value?: string;
  error?: string;
  rules?: IRule<string>[];
  onChange?: (value: string) => void;
  id?: string;
  maxLength?: number;
}
interface IState {
  value: string;
  changed: boolean;
  forcedError: boolean;
}

class WrappedTextInput
  extends React.Component<IProps, IState>
  implements IWrappedComponent<string> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      value: props.value || '',
      changed: false,
      forcedError: sg(() => this.props.error!.length > 0, false),
    };

    this.getError = this.getError.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.getValue = this.getValue.bind(this);
    this.isValid = this.isValid.bind(this);
  }

  public componentDidUpdate(prevProps: IProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value || '' });
    }
    if (prevProps.error !== this.props.error) {
      this.setState({ forcedError: true });
    }
  }

  public getValue() {
    return this.state.value;
  }
  public setValue(value: string) {
    this.setState({ value });
  }
  public isValid(forced = false) {
    if (forced) {
      this.setState({ changed: true });
    }
    return checkForError(this.state.value, this.props.rules);
  }

  public render() {
    return (
      <TextInput
        {...this.props}
        onChange={this.handleChange}
        value={this.state.value}
        error={this.getError()}
        id={this.props.id}
      />
    );
  }

  private handleChange(value: string) {
    this.setState(
      (prevState) => {
        const newState = { ...prevState, value };

        if (!prevState.changed) {
          newState.changed = true;
        }
        if (prevState.forcedError) {
          newState.forcedError = false;
        }

        return newState;
      },
      () => sg(() => this.props.onChange!(this.state.value), null)
    );
  }

  private getError(): string | undefined {
    if (sg(() => this.state.forcedError, false)) {
      return this.props.error;
    }

    if (this.state.changed) {
      return getError(this.state.value, this.props.rules);
    }

    return undefined;
  }
}

export { WrappedTextInput, Components, ErrorMessage };
export default WrappedTextInput;
