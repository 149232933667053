import { createActionCreator } from '@reduxify/utils';
import { ApolloClient } from 'apollo-boost';
import { IWithApolloClient } from '../global/types';
import { Company, Notifications, State, User } from './types';

export const setLoggedIn = createActionCreator<boolean>('auth/SET_LOGGED_IN');
export const setRegisterError = createActionCreator<string>(
  'auth/SET_REGISTER_ERROR'
);
export const requestFetchNavCount = createActionCreator<IWithApolloClient>(
  'auth/REQUEST_FETCH_NAV_COUNT'
);
export const setNavbarCount = createActionCreator<State['navbarCount']>(
  'auth/SET_NAVBAR_COUNT'
);

export const setNavbarCountFetchComplete = createActionCreator<boolean>(
  'auth/SET_NAVBAR_COUNT_FETCH_COMPLETE'
);

export const setLoginError = createActionCreator<string>(
  'auth/SET_LOGIN_ERROR'
);
export const setLoginSuccess = createActionCreator<string>(
  'auth/SET_LOGIN_SUCCESS'
);

export const linkedinSuccess = createActionCreator<IWithApolloClient>(
  'auth/LINKEDIN_LOGIN_SUCCESS'
);
export const setLinkedinSuccess = createActionCreator<boolean>(
  'auth/SET_LINKEDIN_LOGIN_SUCCESS'
);
export const setLoggedInUser = createActionCreator<User>(
  'auth/SET_LOGGED_IN_USER'
);
export const login = createActionCreator<
  {
    username: string;
    password: string;
    isModal?: boolean;
  } & IWithApolloClient
>('auth/LOGIN');

export const openPopupIfNeeded = createActionCreator<{
  calledFrom?: string;
}>('auth/OPEN_POPUP_IF_NEEDED');

export const logout = createActionCreator('auth/LOGOUT');
export const checkAuth = createActionCreator('auth/CHECK_AUTH');

export const getStarted = createActionCreator<{
  password: string;
  businessEmail: string;
  isModal?: boolean;
  isSeller?: boolean;
  isSellerLanding?: boolean;
  client?: ApolloClient<any>;
}>('auth/GET_STARTED');

export const createAccount = createActionCreator<{
  firstName: string;
  lastName: string;
  companyName: string;
  phoneNumber?: string;
  isBuyer: boolean;
  isSeller: boolean;
  isModal?: boolean;
  isSellerLanding?: boolean;
  client?: ApolloClient<any>;
}>('auth/CREATE_ACCOUNT');

export const register = createActionCreator<{
  password: string;
  name: string;
  surname: string;
  businessEmail: string;
  company: string;
  phoneNumber: string;
  country: string;
  isBuyer: boolean;
  isSeller: boolean;
  isModal?: boolean;
  isSellerLanding?: boolean;
  client?: ApolloClient<any>;
}>('auth/REGISTER');

export const requestUserSettings = createActionCreator<IWithApolloClient>(
  'auth/REQUEST_FETCH_SETTINGS'
);

export const setUserSettings = createActionCreator<User>(
  'auth/SET_USER_SETTINGS'
);
export const setUserCompany = createActionCreator<Company>(
  'auth/SET_USER_COMPANY'
);

export const setNotification = createActionCreator<Notifications>(
  'auth/SET_NOTIFICATION'
);

export const setAuthCheckComplete = createActionCreator<boolean>(
  'auth/SET_AUTH_CHECK_COMPLETE'
);

export const setResetPasswordModal = createActionCreator<boolean>(
  'auth/SET_RESET_MODAL'
);

export const requestCheckAuth = createActionCreator<{
  isLinkedin?: boolean;
  dealCreate?: boolean;
  client?: any;
}>('auth/REQUEST_CHECK_AUTH');

export const updateSettings = createActionCreator<
  IWithApolloClient & {
    user?: User;
    company: Company;
  }
>('auth/UPDATE_USER_SETINGS');

export const updateBuyerInfoAction = createActionCreator<
  IWithApolloClient & {
    buyer: User;
    company: Company;
  }
>('auth/UPDATE_BUYER_INFO_AT_REQUEST');

export const requestRemindPassword = createActionCreator<{ email: string }>(
  'auth/REQUEST_REMIND_PASSWORD'
);
export const requestResetPassword = createActionCreator<{
  password: string;
  recoveryToken: string;
}>('auth/REQUEST_RESET_PASSWORD');

export const setRegisterModalOpen = createActionCreator<boolean>(
  'auth/SET_REGISTER_MODAL_OPEN'
);

export const setGetStartedModalOpen = createActionCreator<boolean>(
  'auth/SET_GET_STARTED_MODAL_OPEN'
);
export const setCreateAccountModalOpen = createActionCreator<boolean>(
  'auth/SET_CREATE_ACCOUNT_MODAL_OPEN'
);

export const setBuyerQualificationModalOpen = createActionCreator<boolean>(
  'auth/SET_BUYER_QUALIFICATION_MODAL_OPEN'
);

export const setBuyerQualificationModalShown = createActionCreator<boolean>(
  'atuh/SET_BUYER_QUALIFICATION_SHOWN'
);

export const setBuyerQualificationAdminViewModalOpen = createActionCreator<boolean>(
  'auth/SET_BUYER_QUALIFICATION_ADMIN_VIEW_MODAL_OPEN'
);

export const setBuyerQualificationAdminViewRequestId = createActionCreator<number>(
  'auth/SET_BUYER_QUALIFICATION_ADMIN_VIEW_DEAL_ID'
);

export const setVerifyEmailModalOpen = createActionCreator<boolean>(
  'auth/SET_VERIFY_EMAIL_MODAL_OPEN'
);

export const requestVerifyEmail = createActionCreator<string>(
  'auth/REQUEST_VERIFY_EMAIL'
);

export const requestSetUserType = createActionCreator<
  IWithApolloClient & {
    userId: number;
    userType: number;
  }
>('auth/REQUEST_SET_USER_TYPE');

export const requestSetOwnsProductStatus = createActionCreator<
  IWithApolloClient & {
    ownsProduct: boolean;
  }
>('auth/REQUEST_SET_USER_OWNS_PRODUCT');
