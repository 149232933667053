import { Countries } from 'app/store/modules/ma-companies/types';
import * as u from 'app/store/modules/query-utils';
import { IFragment } from '../../query-utils/fragment';
import { Schema_Countries } from '../../query-utils/schema';

export type ICountryFragment = Countries;
export const companyDirectoryCountrySEOTextFragment: IFragment = {
  fragment: () => `
    fragment countryFragment on countries {
      ${u.selectParams<Schema_Countries>(
        'code',
        'name',
        'order',
        ['seo_text_footer', 'seoTextFooter'],
        ['seo_text_footer_directory', 'seoTextFooterDirectory']
      )}
    }
  `,
  name: 'countryFragment',
};
