import { createActionCreator } from '@reduxify/utils';
import {
  allCountries,
  EU28Countries,
  IWithApolloClient,
  languages,
  TypeOrigin,
} from 'app/store/modules/global/types';
import {
  AnyListItem,
  dosierFormat,
  dossierStatuses,
  gpmApprovals,
  IProductLink,
  Notifications,
  OnBoardingFilter,
  Product,
  ProductFilter,
  ProductForm,
  // productTypeTagList,
} from 'app/store/modules/products/types';
import { sg } from 'app/utils/safe-get';
import { WithApolloClient } from 'react-apollo';
import { Dictionary } from '../../../utils/dictionary';
import { toTitleCase } from '../global/actions';
import { IGetAdminOnboardingListReturn, IGetProductListReturn } from './query';

export const filterProduct = createActionCreator<{
  filter: ProductFilter;
  client: any;
}>('products/FILTER_PRODUCT');

export const setProductFilter = createActionCreator<ProductFilter>(
  'products/SET_FILTER'
);

export const fetchProducts = createActionCreator<{
  client: any;
}>('products/FETCH_PRODUCTS');

export const requestFetchProducts = createActionCreator<{
  client: any;
  companyId?: number;
  filter: ProductFilter;
  isFast?: boolean;
  isSuggestedProducts?: boolean;
  pageSize?: number;
  isMarketplaceView?: boolean;
  sortByField?: string;
  sortType?: string;
}>('products/REQUEST_FETCH_PRODUCTS');

export const setSuggestAlternativeSellersModalOpen = createActionCreator<boolean>(
  'products/SET_SUGGEST_ALTERNATIVE_SELLERS_MODAL_OPEN'
);

export const setEditModal = createActionCreator<boolean>(
  'products/SET_EDIT_MODAL'
);

export const setEditTradeModal = createActionCreator<boolean>(
  'products/SET_TRADE_EDIT_MODAL'
);
export const setNotification = createActionCreator<Notifications>(
  'products/SET_NOTIFICATION'
);

export const setStatus = createActionCreator<number>('products/SET_STATUS');

export const setEditProductId = createActionCreator<number>(
  'products/SET_EDIT_PRODUCT_ID'
);

export const deleteProduct = createActionCreator<{
  productId: number;
  type: TypeOrigin;
  client: any;
}>('products/DELETE_PRODUCT');

export const fetchProductsSuccess = createActionCreator<{
  productList: IGetProductListReturn['products'];
  totalCount: number;
}>('products/FETCH_PRODUCTS_SUCCESS');

export const fetchSuggestedProductsSuccess = createActionCreator<{
  productList: IGetProductListReturn['products'];
  totalCount: number;
}>('products/FETCH_SUGGESTED_PRODUCTS_SUCCESS');

export const setTotalCount = createActionCreator<number>(
  'products/SET_TOTAL_COUNT'
);
export const setCurrentPage = createActionCreator<number>('products/SET_PAGE');
export const setPageSize = createActionCreator<number>(
  'products/SET_PAGE_SIZE'
);

export const setProductCache = createActionCreator<Dictionary<Product>>(
  'products/SET_PRODUCT_CACHE'
);
export const setProductIds = createActionCreator<AnyListItem[]>(
  'products/SET_IDS'
);

export const setVisibleProductIds = createActionCreator<AnyListItem[]>(
  'products/SET_VISIBLE_IDS'
);

export const setOnboardingIds = createActionCreator<AnyListItem[]>(
  'products/SET_ONBOARDING_IDS'
);

export const addDeleteId = createActionCreator<{
  id: AnyListItem;
}>('products/ADD_DELETE_ID');
export const removeDeleteId = createActionCreator<{
  id: AnyListItem;
}>('products/REMOVE_DELETE_ID');

export const setDeleteId = createActionCreator<AnyListItem[]>(
  'products/SET_DELETE_ID'
);

export const deleteSelected = createActionCreator<{ client: any }>(
  'products/DELETE_SELECTED'
);
interface ICreateProduct {
  client: any;
  values: ProductForm;
}
export const createProduct = createActionCreator<ICreateProduct>(
  'products/CREATE_PRODUCT'
);

export const requestFetchOnboarding = createActionCreator<{
  client: any;
  filter?: OnBoardingFilter;
}>('products/REQUEST_FETCH_ONBOARDING');

export const fetchOnboardingSuccess = createActionCreator<{
  onboardingList: IGetAdminOnboardingListReturn['admin_onboarding'];
  totalCount: number;
}>('products/FETCH_ONBOARDING_SUCCESS');

export interface OnboardingFilterSet extends WithApolloClient<any> {
  filter: OnBoardingFilter;
}
export const filterOnboarding = createActionCreator<OnboardingFilterSet>(
  'products/FILTER_ONBOARDING'
);

export const setOnboardingFilter = createActionCreator<OnBoardingFilter>(
  'products/SET_ONBOARDING_FILTER'
);
export const setAddProductToggle = createActionCreator<boolean>(
  'products/SET_ADD_PRODUCT_TOGGLE'
);

export const calculateProductRankingScoreFromArgs = (
  values: ProductForm | Product
) => {
  const ranking =
    (`${values.autoRanking}`.trim().length > 0
      ? parseFloat(`${values.autoRanking}`)
      : calculateAutoRating(values)) +
    (`${values.manualRanking}`.trim().length > 0
      ? parseFloat(`${values.manualRanking}`)
      : 500);
  return ranking;
};

export const calculateProductRankingScore = (values: ProductForm | Product) => {
  const ranking =
    calculateAutoRating(values) +
    parseFloat(values.manualRanking ? values.manualRanking.toString() : '500');
  return ranking;
};

export const calculateAutoRating = (values: ProductForm | Product) => {
  let points = 0;
  const thirty = 30;
  const hundred = 100;
  const twentyFive = 25;
  const fifteen = 15;
  const three = 3;
  const five = 5;
  const registeredInBonus: string[] = [
    'europe',

    ...EU28Countries.map((x) => x.id),
  ];
  const registeredInBonus15: string[] = ['US', 'CA', 'JP', 'AU'];

  const rankingDebugMode = true;
  const debugLog = (text: any) => {
    if (rankingDebugMode) {
      // eslint-disable-next-line no-console
      console.log(text);
    }
  };

  let productUserCountry = values.Company ? values.Company.country : '';
  if (productUserCountry === '') {
    productUserCountry = values.CompanySelect
      ? values.CompanySelect.country
      : '';
  }
  if (rankingDebugMode) {
    debugLog(productUserCountry);
  }

  // Country of Manufacturer in regulated market (EU28, US, Canada, Japan, Australia)
  debugLog(
    'Country of Manufacturer in regulated market (EU28, US, Canada, Japan, Australia)'
  );
  if (
    values.finishedDoseCountry &&
    values.finishedDoseCountry.length > 0 &&
    values.finishedDoseCountry.includes(productUserCountry) &&
    registeredInBonus.includes(productUserCountry)
  ) {
    debugLog(hundred * thirty);
    points = hundred * thirty + points;
  } else if (
    values.finishedDoseCountry &&
    values.finishedDoseCountry.length > 0 &&
    values.finishedDoseCountry.includes(productUserCountry) &&
    registeredInBonus15.includes(productUserCountry)
  ) {
    debugLog(fifteen * thirty);
    points = fifteen * thirty + points;
  } else {
    if (values.registeredIn && values.registeredIn.length > 0) {
      debugLog(thirty);
      points = thirty + points;
    }
  }

  // Factory has one of these approvals: EU GMP (any EU28 market), FDA/Canada, PMDA (Japan), TGA (Australia)
  debugLog(
    'Factory has one of these approvals: EU GMP (any EU28 market), FDA/Canada, PMDA (Japan), TGA (Australia)'
  );
  // const approvalBonus: string[] = ['EU GMP', 'FDA', 'PMDA', 'TGA', 'CA'];

  const approvalBonusNew: string[] = [
    'EU GMP',
    'CH',
    'IS',
    'GB',
    ...EU28Countries.map((x) => x.id),
  ];
  const approvalBonus15: string[] = ['FDA', 'TGA', 'CA', 'PMDA'];

  if (
    values.gmpApprovalForThisProduct &&
    values.gmpApprovalForThisProduct.length > 0 &&
    values.gmpApprovalForThisProduct.filter((x) => approvalBonusNew.includes(x))
      .length > 0
  ) {
    debugLog(twentyFive * thirty);
    points = twentyFive * thirty + points;
  } else if (
    values.gmpApprovalForThisProduct &&
    values.gmpApprovalForThisProduct.length > 0 &&
    values.gmpApprovalForThisProduct.filter((x) => approvalBonus15.includes(x))
      .length > 0
  ) {
    debugLog(fifteen * thirty);
    points = fifteen * thirty + points;
  } else {
    if (
      values.gmpApprovalForThisProduct &&
      values.gmpApprovalForThisProduct.length > 0
    ) {
      debugLog(thirty);
      points = thirty + points;
    }
  }

  // Factory has Dossier type 5 - EU CTD dossiers, 1-other countries
  debugLog('Dossier type EU CTD, 1 other country');
  const dossierTypeBonus: string[] = ['EU CTD'];
  const dossierTypeBonus15: string[] = ['US CTD'];
  if (
    values.dosierType &&
    dossierTypeBonus.includes(
      dosierFormat.filter(
        (x) => x.id.toString() === values.dosierType.toString()
      )[0].name
    )
  ) {
    debugLog(twentyFive * thirty);

    points = twentyFive * thirty + points;
  } else if (
    values.dosierType &&
    dossierTypeBonus15.includes(
      dosierFormat.filter(
        (x) => x.id.toString() === values.dosierType.toString()
      )[0].name
    )
  ) {
    debugLog(fifteen * thirty);

    points = fifteen * thirty + points;
  } else {
    debugLog(thirty);

    points = thirty + points;
  }

  // Dossier ready to file (ready to file arba under development)
  debugLog(' Dossier ready to file (ready to file arba under development)');
  if (values.dosierStatus && values.dosierStatus.toString() === '1') {
    debugLog(five * thirty);
    points = five * thirty + points;
  } else {
    if (
      values.dosierStatus &&
      values.dosierStatus.toString() === '0' &&
      values.dosierCompletionQuarter !== '' &&
      values.dosierCompletionYear !== ''
    ) {
      debugLog(three * thirty);
      points = three * thirty + points;
    } else {
      debugLog(thirty);
      points = thirty + points;
    }
  }

  // Clinical data BE studies available
  debugLog('Clinical data');
  if (values.clinicalData && values.clinicalData !== '') {
    points = five * fifteen + points;
    debugLog(five * fifteen);
  } else {
    points = fifteen + points;
    debugLog(fifteen);
  }

  debugLog('values.stabilityDataZones.includes IVb');
  if (values.stabilityDataZones && values.stabilityDataZones.includes('IVb')) {
    points = five * fifteen + points;
    debugLog(five * fifteen);
  } else {
    points = fifteen + points;
    debugLog(fifteen);
  }

  // Product registered in the country of origin (CPP)
  debugLog('Product registered in the country of origin (CPP)');
  if (
    values.cppAvailableFrom &&
    values.cppAvailableFrom.length > 0 &&
    values.cppAvailableFrom.filter((x) => registeredInBonus.includes(x))
      .length > 0
  ) {
    points = five * fifteen + points;
    debugLog(five * fifteen);
  } else {
    points = fifteen + points;
    debugLog(fifteen);
  }

  // Product registered in regulated markets
  const registeredInBonusNew: string[] = ['US', 'CA', 'AU'];
  debugLog('Product registered in regulated markets');
  if (
    values.registeredIn &&
    values.registeredIn.length > 0 &&
    values.registeredIn.filter((x) => approvalBonusNew.includes(x)).length > 0
  ) {
    points = twentyFive * fifteen + points;
    debugLog(twentyFive * fifteen);
  } else if (
    values.registeredIn &&
    values.registeredIn.length > 0 &&
    values.registeredIn.filter((x) => registeredInBonusNew.includes(x)).length >
      0
  ) {
    points = fifteen * fifteen + points;
    debugLog(fifteen * fifteen);
  } else {
    if (values.registeredIn && values.registeredIn.length > 0) {
      points = fifteen + points;
      debugLog(fifteen);
    }
  }

  // Product fast track
  debugLog('Fast track product');
  if (values.fastProduct || !values.adminCreated) {
    points = five * thirty + points;
    debugLog(five * thirty);
  } else {
    points = thirty + points;
    debugLog(thirty);
  }

  /*
  // Marketing authorization is available for business
  debugLog('Marketing authorization is available for business');
  const comercializationMarketsBonus: string[] = [
    'europe',
    'US',
    'CA',
    'JP',
    'AU',
    ...EU28Countries.map((x) => x.id),
  ];
  if (
    values.marketingAuthorizationsAchievedCountry &&
    values.marketingAuthorizationsAchievedCountry.length > 0 &&
    values.marketingAuthorizationsAchievedCountry.filter((x) =>
      comercializationMarketsBonus.includes(x)
    ).length > 0
  ) {
    points = five * thirty + points;
    debugLog(five * thirty);
  } else {
    points = thirty + points;
    debugLog(thirty);
  }
  */

  debugLog('points');
  debugLog(points);

  return points;
};

export const getDossierType = (type: number) => {
  const item = dosierFormat.filter(
    (x) => type && x.id.toString() === type.toString()
  )[0];

  if (item) {
    return item.name;
  } else {
    return '';
  }
};

export const getDossierStatus = (id?: number) => {
  if (!id) {
    return '';
  }
  const status = dossierStatuses.filter(
    (x) => x && x.id.toString() === id.toString()
  )[0];
  if (status) {
    return status.name;
  }

  return '';
};

export const getAvailableFor = (product: Product) => {
  let str = '';
  if (product.productAvailableLicensingSupply) {
    str = 'Licensing + supply';
  }
  if (product.productAvailableSuplyDistribution) {
    if (str !== '') {
      str = str + ', Supply (distribution)';
    } else {
      str = 'Supply (distribution)';
    }
  }
  if (product.productAvailableTechnologyTransfer) {
    if (str !== '') {
      str = str + ', Technology transfer';
    } else {
      str = 'Technology transfer';
    }
  }
  if (product.productAvailableSpecialImport) {
    if (str !== '') {
      str = str + ', Special import';
    } else {
      str = 'Special import';
    }
  }

  return str;
};

export const getGPMApprovals = (ids: string[]) => {
  if (ids === undefined || ids.length === 0) {
    return '';
  }
  return ids
    .map((x) =>
      sg(
        () =>
          gpmApprovals.filter(
            (y) =>
              y &&
              // added check to country id and name
              (y.id.toString() === x.trim().toString() ||
                y.name.toString() === x.trim().toString())
          )[0].name,
        ''
      )
    )
    .join(', ');
};

export const getCountries = (ids: string[]) => {
  if (!ids || ids.length === 0) {
    return '';
  }

  return ids
    .map((x) =>
      sg(
        () =>
          allCountries.filter(
            (y) => y && y.id.toString() === x.trim().toString()
          )[0].name,
        ''
      )
    )
    .sort()
    .join(', ');
};

export const getCountriesEuAsOne = (id: string) => {
  if (!id || id.length === 0) {
    return '';
  }
  const EU_CODE = 'eu';
  // checking EU as API does not returns eu countries anymore
  if (id.toLowerCase().includes(EU_CODE)) {
    return 'European Union';
  } else {
    return sg(
      () =>
        allCountries.filter(
          (country) => country && country.id.toString() === id.trim()
        )[0].name,
      ''
    );
  }
};

export const getLanguages = (ids: string[]): string => {
  if (!ids || ids.length === 0) {
    return '';
  }
  return ids
    .map((id) =>
      sg(
        () =>
          languages.filter(
            (language) =>
              language && language.id.toString() === id.trim().toString()
          )[0].name,
        ''
      )
    )
    .join(', ');
};

export const getStabilityZones = (ids: string[]) => {
  if (ids === undefined || ids.length === 0) {
    return '';
  }
  return ids.join(', ');
};

export const getDossierComplete = (product: Product) => {
  return (
    (product.dosierCompletionQuarter
      ? product.dosierCompletionQuarter + 'Q '
      : '') + (product.dosierCompletionYear ? product.dosierCompletionYear : '')
  );
};

const replaceSymbolsWithDashes = (text: string) =>
  text
    .replace(/ /g, '-')
    .replace(/,/g, '-')
    .replace(/\//g, '-')
    .replace(/%/g, '-')
    .replace(/--/g, '-')
    .replace(/&/g, '-')
    .replace(/;/g, '-')
    .replace(/\+/g, '-')
    .replace(/:/g, '-');

export const getProductLink = (
  product?: IProductLink,
  isManufacturer?: boolean,
  customId?: number
) => {
  return product
    ? (
        replaceSymbolsWithDashes(product.Substance1.name) +
        (product.Substance2
          ? '-' + replaceSymbolsWithDashes(product.Substance2.name)
          : '') +
        (product.Substance3
          ? '-' + replaceSymbolsWithDashes(product.Substance3.name)
          : '') +
        (sg(() => product.formSpecification.length > 0, false)
          ? '-' + replaceSymbolsWithDashes(product.formSpecification)
          : '') +
        (sg(() => product.strength.length > 0, false)
          ? '-' + replaceSymbolsWithDashes(product.strength)
          : '') +
        (isManufacturer
          ? '-manufacturer-' + (customId ? customId : product.id)
          : '')
      ).toLowerCase()
    : '';
};

export const getProductLinkName = (product?: Product) => {
  return product
    ? (
        product.Substance1.name +
        (product.Substance2 ? ' + ' + product.Substance2.name : '') +
        (product.Substance3 ? ' + ' + product.Substance3.name : '') +
        (sg(() => product.formSpecification.length > 0, false)
          ? ' ' + product.formSpecification
          : '') +
        (product.strength !== '' ? ' ' + product.strength : '') +
        (product.volume ? ' - ' + product.volume : '')
      ).toLowerCase()
    : '';
};

export function getProductName(
  product?: Product,
  isStrenght?: boolean
): string {
  if (product === undefined) {
    return '';
  }

  return [
    sg(() => toTitleCase(product.Substance1.name), ''),
    sg(() => ` + ${toTitleCase(product.Substance2!.name)}`, ''),
    sg(() => ` + ${toTitleCase(product.Substance3!.name)}`, ''),
    isStrenght ? (product.strength !== '' ? ' ' + product.strength : '') : '',
    // product.packSize ? ' - ' + product.packSize : '',
  ].join('');
}

export function getProductSubstanceName(
  product?: Product,
  isTitleCase?: boolean
): string {
  if (product === undefined) {
    return '';
  }

  return [
    sg(
      () =>
        isTitleCase
          ? toTitleCase(product.Substance1.name)
          : product.Substance1.name,
      ''
    ),
    sg(
      () =>
        ` + ${
          isTitleCase
            ? toTitleCase(product.Substance2!.name)
            : product.Substance2!.name
        }`,
      ''
    ),
    sg(
      () =>
        ` + ${
          isTitleCase
            ? toTitleCase(product.Substance3!.name)
            : product.Substance3!.name
        }`,
      ''
    ),
  ].join('');
}

export function getProductDoseForm(product?: Product): string {
  if (product === undefined) {
    return '';
  }

  return product.formSpecification ? product.formSpecification : '';
}

export const requestFetchProduct = createActionCreator<{
  client: any;
  productId: number;
}>('products/REQUEST_FETCH_PRODUCT');

export const toProductForm = (product: Product) => {
  const trimmedGMPApprovals = product.gmpApprovalForThisProduct.map((x) =>
    x.trim()
  );

  return {
    id: product.id,
    companyId: product && product.Company ? product.Company.id.toString() : '0',
    CompanySelect: {
      value: product && product.Company ? product.Company.id.toString() : '0',
      label: product.Company.name,
      country: product.Company.country,
      createdBy: product.createdBy,
    },
    Company: product.Company,
    substance1: product.substance1,
    substance1Select: {
      value: product.Substance1.id.toString(),
      label: toTitleCase(product.Substance1.name),
      category: product.Substance1.category,
    },
    formSpecification: product.formSpecification,
    doseFormCategory: product.doseFormCategory,
    strength: product.strength,
    dosierStatus: product.dosierStatus,
    dosierStatusSelect: dossierStatuses
      .filter((x) => x.id === product.dosierStatus)
      .map((x) => ({ value: x.id.toString(), label: x.name }))[0],
    dosierCompletionQuarter: product.dosierCompletionQuarter,
    dosierCompletionYear: product.dosierCompletionYear,
    productAvailableLicensingSupply: product.productAvailableLicensingSupply,
    productAvailableSuplyDistribution:
      product.productAvailableSuplyDistribution,
    productAvailableTechnologyTransfer:
      product.productAvailableTechnologyTransfer,
    productAvailableSpecialImport: product.productAvailableSpecialImport,
    dosierType: product.dosierType,
    dosierTypeError: '',
    dosierTypeSelect: dosierFormat
      .filter(
        (x) =>
          x && x.type && x.type.includes('ma') && x.id === product.dosierType
      )
      .map((x) => ({
        value: x.id.toString(),
        label: x.name,
      }))[0],
    gmpApprovalForThisProduct: gpmApprovals
      .filter(
        (x) =>
          trimmedGMPApprovals.includes(x.id) ||
          trimmedGMPApprovals.includes(x.name)
      )
      .map((x) => x.name),
    gmpApprovalForThisProductSelect:
      trimmedGMPApprovals.length > 0
        ? gpmApprovals
            .filter(
              (x) =>
                trimmedGMPApprovals.includes(x.id) ||
                trimmedGMPApprovals.includes(x.name)
            )
            .map((x) => ({ value: x.name, label: x.name }))
        : [],
    finishedDoseCountry: product.finishedDoseCountry,
    finishedDoseCountryEu: product.finishedDoseCountryEu,
    finishedDoseCountrySelect:
      product.finishedDoseCountry.length > 0
        ? allCountries
            .filter((x) => product.finishedDoseCountry === x.id)
            .map((x) => ({ value: x.id, label: x.name }))[0]
        : undefined,
    notAvailableMarkets: product.notAvailableMarkets,
    notAvailableMarketsSelect:
      product.notAvailableMarkets.length > 0
        ? allCountries
            .filter((x) => product.notAvailableMarkets.includes(x.id))
            .map((x) => ({ value: x.id, label: x.name }))
        : [],
    registeredIn: product.registeredIn,
    registeredInSelect:
      product.registeredIn.length > 0
        ? allCountries
            .filter((x) => product.registeredIn.includes(x.id))
            .map((x) => ({ value: x.id, label: x.name }))
        : [],
    marketingAuthorizationsAchievedCountry:
      product.marketingAuthorizationsAchievedCountry,
    marketingAuthorizationsAchievedCountrySelect:
      product.marketingAuthorizationsAchievedCountry.length > 0
        ? allCountries
            .filter((x) =>
              product.marketingAuthorizationsAchievedCountry.includes(x.id)
            )
            .map((x) => ({ value: x.id, label: x.name }))
        : [],
    stabilityDataZones: product.stabilityDataZones,
    cppAvailableFrom: product.cppAvailableFrom,
    cppAvailableFromSelect:
      product.cppAvailableFrom.length > 0
        ? allCountries
            .filter((x) => product.cppAvailableFrom.includes(x.id))
            .map((x) => ({ value: x.id, label: x.name }))
        : [],
    commentsOnThePatentStatus: product.commentsOnThePatentStatus,
    shelfLife: product.shelfLife,
    batchSize: product.batchSize,
    moq: product.moq,
    marketingMessage: product.marketingMessage,
    status: product.status,
    productTags: product.productTags,
    // productTagsSelect:
    //   product.productTags.length > 0
    //     ? productTypeTagList
    //         .filter((x) => product.productTags.includes(x.id.toString()))
    //         .map((x) => ({ value: x.id.toString(), label: x.name }))
    //     : [],
    comments: product.comments,
    autoRanking:
      product.autoRanking && product.autoRanking !== null
        ? product.autoRanking.toString()
        : '',
    manualRanking:
      product.manualRanking && product.manualRanking !== null
        ? product.manualRanking.toString()
        : '',
    substance2:
      product.substance2 !== undefined && product.substance2 !== null
        ? product.substance2
        : 0,
    substance2Select: product.Substance2
      ? {
          value: product.Substance2.id.toString(),
          label: toTitleCase(product.Substance2.name),
          category: product.Substance2.category,
        }
      : { value: '', label: '' },
    substance3:
      product.substance3 !== undefined && product.substance3 !== null
        ? product.substance3
        : 0,
    substance3Select: product.Substance3
      ? {
          value: product.Substance3.id.toString(),
          label: toTitleCase(product.Substance3.name),
          category: product.Substance3.category,
        }
      : { value: '', label: '' },
    substance1Error: '',
    manualRankingError: '',
    autoRankingError: '',
    finishedDoseCountryError: '',
    strengthError: '',
    dosierStatusError: '',
    formSpecificationError: '',
    gmpApprovalForThisProductError: '',
    requireFetchProduct: false,
    clinicalData: product.clinicalData ? product.clinicalData : '',
    adminCreated: product.adminCreated,
    createdBy: product.createdBy,
    adminComments: product.adminComments,
    competitiveProduct: product.competitiveProduct,
    fastProduct: product.fastProduct,
    doseFormOptions: [],
    packSize: product.packSize,
    packSizeError: '',
    productTagsError: '',
    volume: product.volume,
    volumeError: '',
    productCategory: product?.productCategory,
  };
};

export const hasFetched = createActionCreator<boolean>('products/HAS_FETCHED');

export const setProductTypeFilter = createActionCreator<
  IWithApolloClient<{ type: OnBoardingFilter }>
>('products/SET_PRODUCTS_FILTER');

export const setProductTypeFilterAction = createActionCreator<OnBoardingFilter>(
  'products/SET_PRODUCTS_FILTER'
);
export const getAvailableForSupplier = (product: any) => {
  let str = '';
  if (product.productAvailableLicensingSupply) {
    str = 'Licensing + supply';
  }
  if (product.productAvailableSupplyDistribution) {
    if (str !== '') {
      str = str + ', Supply (distribution)';
    } else {
      str = 'Supply (distribution)';
    }
  }
  if (product.productAvailableTechnologyTransfer) {
    if (str !== '') {
      str = str + ', Technology transfer';
    } else {
      str = 'Technology transfer';
    }
  }
  if (product.productAvailableSpecialImport) {
    if (str !== '') {
      str = str + ', Special import';
    } else {
      str = 'Special import';
    }
  }

  return str;
};

export const getProductNameFull = (product: Product) => {
  if (!product) {
    return;
  }

  return toTitleCase(
    [
      [
        product.Substance1.name,
        sg(() => product.Substance2!.name, ''),
        sg(() => product.Substance3!.name, ''),
      ]
        .filter((v) => v.length > 0)
        .join(' + '),
      product.formSpecification || '',
      product.strength || '',
    ]
      .filter((v) => v.length > 0)
      .join(' ')
  );
};
