export interface IState {
  offerTradeFormSaveCompleted: boolean;
  directoriesListModalOpen: boolean;
  directoriesListModalOpenLocation: string;
  preventRedirectAfterLogin: {
    redirect: boolean;
    callback?: () => void;
  };
  brokerChangeId: number | null;
  bannerSeen: boolean;
}

export const defaultStore: IState = {
  offerTradeFormSaveCompleted: false,
  directoriesListModalOpen: false,
  directoriesListModalOpenLocation: '',
  preventRedirectAfterLogin: {
    redirect: false,
  },
  brokerChangeId: null,
  bannerSeen: false,
};
