import { useEffect, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export function useMeasure(): [
  { ref: React.MutableRefObject<any> },
  { left: number; top: number; width: number; height: number }
] {
  const ref = useRef();
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 });
  const [ro] = useState(
    () => new ResizeObserver(([entry]) => set(entry.contentRect))
  );
  useEffect(() => {
    if (ref.current) {
      ro.observe(ref.current as any);
    }
    return () => ro.disconnect();
  }, []);
  return [{ ref }, bounds];
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => void (ref.current = value), [value]);
  return ref.current;
}

export function useDidUpdate(
  callback: React.EffectCallback,
  deps: any[]
): void {
  const mounted = useRef(false);
  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }

    return callback();
  }, deps);
}
export function useMemory<T>(
  value: T,
  callback?: (current: T) => void
): [T, (next: T) => void] {
  const v = useRef(value);
  const setV = (next: T) => {
    if (callback) {
      callback(v.current);
    }
    v.current = next;
  };

  return [v.current, setV];
}

export function useDidMount(callback: React.EffectCallback) {
  return useEffect(callback, []);
}

export function useScrollControll(dependencie: boolean) {
  const className = 'ReactModal__Body--open';
  useEffect(() => {
    if (dependencie) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [dependencie]);
}
