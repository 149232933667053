const colors = {
  primary: '#1c8ed3',
  primaryHover: '#007dc8',
  primaryLight: '#e3f1f9',
  primaryLightHover: '#bee3f8',
  primaryExtraLight: '#f2fbfc',

  heading: '#1188e4',

  accent: '#fca216',
  warning: '#fca216',
  orange: '#f19301',

  accentLight: '#feefd9',
  warningLight: '#feefd9',
  warningLightHover: '#feeacd',
  warningLightHover2: '#fee2b7',

  success: '#64b00d',
  successLight: '#e6fad0',
  successHover: '#cef2a6',

  info: '#3cb2b8',
  infoLight: '#d6f5f7',
  infoLightHover: '#bfeff2',

  itemExpandBackground: '#f8f9fb',
  /** #d1e1eb */
  itemExpandButtonBorder: '#d1e1eb',
  itemExpandButtonBorderHover: '#a5cde4',

  danger: '#e55757',
  dangerHover: '#c63838',
  dangerLight: '#fae1e1',
  dangerLightHover2: '#f7cbcb',
  dangerLightHover: '#F8D3D3',
  errorMessage: '#d82e43',

  muted: '#a1a9b5',
  mutedLight: '#edf0f3',

  grayButton: '#eceff4',
  grayButtonHover2: '#e2e4e8',
  grayButtonHover: '#dde3ec',
  grayButtonText: '#8891a0',

  white: '#ffffff',
  black: '#000000',
  shadow: 'rgba(53, 64, 82, 0.03)',
  sellerInfoShadow: '0 8px 24px 0 rgba(53, 64, 82, 0.03)',
  filtersShadow: 'rgba(23, 3, 79, 0.03)',
  boxShadowParam: '0 8px 15px',
  filtersButtonShadow: 'rgba(53, 64, 82, 0.06)',
  notificationShadow: 'rgba(53, 64, 82, 0.03)',

  grayExtraLight: '#fafafa',
  grayMidLight: '#b4b4b4',
  grayLight: '#dfe4e8',
  grayLighter: '#DADADA',
  gray: '#bbc1cb',
  greyDark: '#818b9a',
  grayDark: '#818b9a',
  grayExtraDark: '#354052',
  gray700: '#334155',
  gray500: '#64748b',
  blueishGrayLight: '#59cbcd15',
  yellowLight: '#f8e71c15',
  grayBorder: '#a3abb6',
  grayDivider: '#e9edf0',
  footerBackground: '#020C55',

  checkBoxBackground: '#f8f9fa',

  youGotDealColor: '#a5b3cb',

  blueLinkedinLight: '#1f6aa7',
  blueLinkedinHover: '#144c7b',
  blueLinkedinDark: '#17568a',

  background: '#f5f6f8',
  socialItemBackground: '#f3f6f8',
  deleteButtonBackground: '#f3f5f7',

  primaryDark: '#c9e7f7',
  grayText: '#606b7c',

  navbarNotActive: '#828B9C',

  modalOverlay: 'rgba(30, 51, 85,0.1)',
  modalShadow: '0 2px 6px 0 rgba(69, 73, 91, 0.08)',

  darkModalOverlay: 'rgba(30, 51, 85, 0.6)',

  successNotificationBorder: '#99cb60',
  successNotificationText: '#5D9918',
  successNotificationIconBackground: '#e6fad0',
  successNotificationHover: '#daf8ba',
  successNotificationHover2: '#cfe7b4',

  selectBorder: '#b4bac4',

  orangeHover: '#fff5e5',

  pagerLightGrey: '#a7b0b9',
  pagerDisabledBackground: '#f3f5f7',

  errorNotificationBorder: '#f1a4a4',
  errorNotificationText: '#dc4759',
  errorNotificationIconBackground: '#fae1e1',

  warningNotificationBorder: '#fdba51',
  warningNotificationText: '#fca216',
  warningNotificationIconBackground: '#feefd9',

  adminMessage: '#f5a6232E',
  adminNote: '#efd0ff',
  quotation: '#ffff00',
  unseenMessage: '#fffeee',
  newDeal: '#f2fbfc',

  socialIconColor: '#5b6576',
  socialIconHoverColor: '#8793a4',

  footerLine: '#434F62',

  adminCreatedBackground: '#f7f7f8',
  adminCreatedBorder: '#e9edf0',

  disabledAddButtonColor: '#BBC1CB',
  messageAvatarSender: '#D5E5EF',
  messageAvatarReceiver: '#e6eaf1',
  messageAvatarAdmin: '#f9e8d0',

  addSubstanceColor: '#e3f1f9',
  addSubstanceHoverColor: '#BEE3F8',
  addSubstancePlusColor: '#0289DC',

  fastTrackHeaderBackground: '#f1f7fd',
  fastTrackBlueButtonColor: '#1b6dc2',
  fastTrackBlueButtonHoverColor: '#0b59a7',
  fastTrackOrangeButtonColor: '#ff9400',
  fastTrackOrangeButtonHoverColor: '#ec7400',

  adminOnlyButtonColor: '#ab50be',
  adminOnlyButtonBackground: '#fbf9fe',
  adminOnlyButtonBorder: '#f0eafc',
  adminOnlyBorderBottom: '#e5cbeb',
  adminOnlyButtonBackgroundDark: '#FBE8FF',

  clickToEditBackground: '#e4cee9',
  clickToEditColor: '#ab50be',
  adminOnlyTextColor: '#ab50be',

  infoFieldBackground: 'rgba(171, 80, 190, 0.05)',
  infoFieldBorder: 'rgba(171, 80, 190, 0.2)',

  lightBlue: '#F5FCFF',
  midLightBlue: '#e1f1fa',
  selectionRemoveIconBg: '#eeeeee',
  selectionRemoveIconHoverBg: '#cccccc',
  // next marketplace colors
  primaryMain: '#186CE8',
  secondary50: '#F3F3F7',
  secondary400: '#353D77',
  secondary600: '#020A44',
  tertiaryMain: '#F96E46',
  gray100: '#f5f5f5',
  gray300: '#f5f5f5',
  // end next marketplace colors
};

export default colors;
