import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { CompanyContactFields } from '../types';

export interface ICreateCompanyContact extends IWithApolloClient {
  values: CompanyContactFields;
}
export interface ICreateCompanyContactReturn {
  insert_ma_company_contact: {
    returning: {
      id: number;
    }[];
  };
}
export async function createCompanyContactQuery(p: ICreateCompanyContact) {
  try {
    return await p.client.mutate<ICreateCompanyContactReturn>({
      mutation: gql`
      mutation insert_company_contact(${u.params(
        ['$full_name', 'String'],
        ['$company_name', 'String'],
        ['$email', 'String'],
        ['$phone', 'String'],
        ['$subject', 'String'],
        ['$messages', 'String']
      )}) {
        insert_ma_company_contact(
          ${u._objects([
            ['full_name', '$full_name'],
            ['company_name', '$company_name'],
            ['email', '$email'],
            ['phone', '$phone'],
            ['subject', '$subject'],
            ['messages', '$messages'],
          ])}) {
          returning { id }
        }
      }
    `,
      variables: {
        full_name: p.values.fullName,
        company_name: p.values.companyName,
        email: p.values.email,
        phone: p.values.phone,
        subject: p.values.subject,
        messages: p.values.messages,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/companies-contacts/query.ts -> createCompanyContactQuery',
      e
    );
  }
}
