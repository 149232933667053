import { Breakpoint } from 'app/styles/breakpoint';
import noop from 'app/utils/noop';
import { END, eventChannel } from 'redux-saga';
import { getBreakpoint } from './breakpoint';
import * as serviceWorker from './service-worker';

export interface ServiceWorkerChannelAction {
  type: 'success' | 'update';
  registration: ServiceWorkerRegistration;
}

export const createServiceWorkerChannel = () =>
  eventChannel<ServiceWorkerChannelAction>((emitter) => {
    serviceWorker.register({
      onSuccess: (registration) => emitter({ type: 'success', registration }),
      onUpdate: (registration) => emitter({ type: 'update', registration }),
    });

    return noop;
  });

export type BreakpointChannelAction =
  | {
      breakpoint: Breakpoint;
      width: number;
    }
  | END;

export const createBreakpointChannel = () =>
  eventChannel<BreakpointChannelAction>((emitter) => {
    if (
      process.env.TARGET !== 'browser' ||
      typeof window === 'undefined' ||
      typeof window === undefined ||
      window === undefined ||
      window.requestAnimationFrame === undefined ||
      !(window && typeof window.requestAnimationFrame === 'function')
    ) {
      emitter(END);
      return noop;
    }

    const detect = () => {
      if (
        process.env.TARGET === 'browser' &&
        !(
          typeof window === 'undefined' ||
          typeof window === undefined ||
          window === undefined ||
          window.requestAnimationFrame === undefined ||
          !(window && typeof window.requestAnimationFrame === 'function')
        )
      ) {
        if (typeof window.requestAnimationFrame === 'function') {
          return window.requestAnimationFrame(() =>
            emitter({
              breakpoint: getBreakpoint(),
              width: window.innerWidth,
            })
          );
        } else {
          return noop;
        }
      } else {
        return noop;
      }
    };

    detect();

    window.addEventListener('resize', detect);

    return () => window.removeEventListener('resize', detect);
  });

export const createNetworkChannel = () =>
  eventChannel((emitter) => {
    if (process.env.TARGET !== 'browser') {
      emitter(END);
      return noop;
    }

    window.addEventListener('online', emitter);
    window.addEventListener('offline', emitter);

    return () => {
      window.removeEventListener('online', emitter);
      window.removeEventListener('offline', emitter);
    };
  });
