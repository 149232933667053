import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const AdminOnlyButtonContainer = styled.div<{ moreInfo?: boolean }>`
  .tooltip-style {
    padding: 8px 16px;
    border: solid 1px ${(props) => props.theme.colors.grayLight} !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: 0.18px;
    color: ${(props) => props.theme.colors.grayExtraDark};
    box-shadow: 0 6px 24px 0 rgba(53, 64, 82, 0.05);
  }
  .place-top::before {
    border-top: 8px solid ${(props) => props.theme.colors.grayLight} !important;
  }

  @media (max-width: 650px) {
    width: ${(props) => (props.moreInfo ? '100%' : '')};
  }
`;

interface StyledAdminOnlyButtonProps {
  moreInfo?: boolean;
  isSmall?: boolean;
}

export const StyledAdminOnlyButton = styled.div<StyledAdminOnlyButtonProps>`
  padding: ${(props) => (props.isSmall ? '2px 8px' : '8px 12px')};
  border-radius: 2px;
  background-color: ${(props) =>
    props.moreInfo
      ? props.theme.colors.primaryLight
      : props.theme.colors.adminOnlyButtonBackground};
  border: 1px solid ${(props) => props.theme.colors.adminOnlyButtonBorder};
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.26px;
  color: ${(props) =>
    props.moreInfo
      ? props.theme.colors.primary
      : props.theme.colors.adminOnlyButtonColor};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';
  cursor: pointer;
`;

export const ButtonIcon = styled.img.attrs((props) => ({
  src: '/img/admin-only-lock.svg',
}))`
  width: 8.2px;
  height: 11px;
  object-fit: contain;
  margin-right: 5px;
`;

interface Props {
  label: string;
  showIcon?: boolean;
  onClick?: (e?: any) => any;
  id?: string;
  tooltipText?: string;
  isSmall?: boolean;
  moreInfo?: boolean;
}

const AdminOnlyButton: React.FC<Props> = ({
  label,
  showIcon = true,
  onClick,
  id,
  tooltipText = 'This information is only visible to admins',
  isSmall = false,
  moreInfo = false,
}) => {
  const tooltipId = `${label}-${id}`;

  return (
    <AdminOnlyButtonContainer onClick={onClick} moreInfo={moreInfo}>
      {tooltipText && !moreInfo && (
        <ReactTooltip
          id={tooltipId}
          effect="solid"
          multiline={true}
          className="tooltip-style"
          type="light"
          border={true}
        >
          {tooltipText}
        </ReactTooltip>
      )}
      <StyledAdminOnlyButton
        isSmall={isSmall}
        id={id}
        data-tip={true}
        data-for={tooltipId}
        moreInfo={moreInfo}
      >
        {!moreInfo && showIcon ? <ButtonIcon /> : null} {label}
      </StyledAdminOnlyButton>
    </AdminOnlyButtonContainer>
  );
};

export default AdminOnlyButton;
