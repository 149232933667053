import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { RequestUtils } from '../../query-utils';

export interface IChangePaidState extends IWithApolloClient {
  id: number;
  isPaidStatus: boolean;
}
export interface IChangePaidStateReturn {
  update_requests: {
    returning: {
      id: number;
      isPaid: number;
    }[];
  };
}
export function changePaidState(p: IChangePaidState) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return p.client.mutate<IChangePaidStateReturn>({
      mutation: gql`
      mutation changePaidState(${RequestUtils.params(
        ['$id', 'Int'],
        ['$isPaid', 'Int'],
        ['$date', 'timestamptz']
      )}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('id', '$id'))}
          ${RequestUtils._set(
            ['is_paid', '$isPaid'],
            ['updated_at', '$date'],
            ['buyer_updated_at', '$date'],
            ['seller_updated_at', '$date']
          )}
        ) {
          returning {
            ${RequestUtils.selectParams('id', ['is_paid', 'isPaid'])}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        isPaid: p.isPaidStatus ? 1 : 0,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> changePaidState',
      e
    );
  }
}
