import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import * as u from '../../query-utils';
import {
  IUserSettingFragment,
  userMinimalFragment,
} from '../fragments/user-settings';

export interface IGetUsersByNameOrEmail extends IWithApolloClient {
  text: string;
}
export interface IGetUsersByNameOrEmailReturn {
  users: IUserSettingFragment[];
}
export async function getUsersByNameOrEmail(p: IGetUsersByNameOrEmail) {
  const limit = 30;
  const maxSymbolsToSearchByBeginning = 2;
  try {
    const whereClause = u.where(
      u._and(
        u.nest('Company', u._eq('hidden', '$hidden')),
        u._is_null('deletedAt'),
        u._or(u._ilike('full_name', '$name'), u._ilike('email', '$name'))
      )
    );
    return await p.client.query<IGetUsersByNameOrEmailReturn>({
      query: gql`
        ${userMinimalFragment.fragment()}
        query getUsersByName(${u.params(
          ['$limit', 'Int'],
          ['$name', 'String'],
          ['$hidden', 'Boolean']
        )}) {
          users(${whereClause} ${u.limit('$limit')} ${u.orderBy(
        'id',
        'desc'
      )}) {
          ...${userMinimalFragment.name}
        }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        name: p.text
          ? p.text.length > maxSymbolsToSearchByBeginning
            ? `%${p.text}%`
            : `${p.text}%`
          : '%',
        limit,
        hidden: false,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/auth/query.ts -> getUsersByNameOrEmail',
      e
    );
  }
}
