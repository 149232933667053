import { differenceInSeconds } from 'date-fns';
import { formatDateString } from 'app/utils/format-datestring';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// custom imports
import ArchiveButton from 'app/components/buttons/archive-gray-button';
import MessageButton from 'app/components/buttons/message-blue-button';
import ConditionalContainer from 'app/components/misc/conditional-container';
import ArchiveModal from 'app/components/modals/archive-modal';
import StatusItem from 'app/components/misc/status-component';
import { TdStyles, TrStyles } from 'app/components/table';
import { useDispatch } from 'app/helpers/redux';
import {
  setLinkSellerRequestToDealId,
  setLinkSellerRequestToRequestModalOpen,
} from 'app/store/modules/requests/actions';
import { IRequest } from 'app/store/modules/requests/types';
import { setFlag_brokerChangeId } from 'app/store/modules/flags/actions';
import {
  checkIfDealBuyerQualificationInformationExist,
  getCountries,
  toTitleCase,
} from 'app/store/modules/global/actions';
import { requestDealTypesDisplayList } from 'app/store/modules/global/types';
import { getProductName } from 'app/store/modules/products/actions';
import { Product } from 'app/store/modules/products/types';
import { sg, sgg } from 'app/utils/safe-get';
import { Bubble } from '../bubble';
import { Flag } from '../flag';
import { Info } from '../info';
import { UserOwnedIcon } from '../user-owned';
import { IProps } from './index';
import {
  ActivityButtonContainer,
  ButtonDivider,
  Label,
  LabelContainer,
  NewTdStyle,
  SmallTableItem,
  SubLabel,
  ThStyles,
  ViewLinkedDeal,
} from './styles';

const secondsToShowJustSent = 600;

const DealSmallTableItem: React.FC<IProps> = ({
  isAdmin,
  request,
  dealItem,
  archiveDealFunc,
  restoreArchiveFunc,
  iAmBuying,
  iAmSelling,
  companyId,
  openBuyerQualificationAdminView,
}) => {
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const iAmBuyingFinal =
    iAmBuying || (request && request.buyerCompanyId === companyId);
  const iAmSellingFinal =
    iAmSelling || (request && request.sellerCompanyId === companyId);
  const dispatch = useDispatch();
  const broker = dealItem ? dealItem!.Broker : request!.Broker;

  const productName = (product: Product) => {
    if (!product) {
      return null;
    }
    return (
      <LabelContainer>
        <Label>{getProductName(product)}</Label>
        <SubLabel>
          {product &&
            toTitleCase(product.formSpecification) +
              ' ' +
              product.strength +
              (product.volume ? ' - ' + product.volume : '')}
        </SubLabel>
      </LabelContainer>
    );
  };

  const dateItem = (date: Date) => {
    return <TdStyles>{formatDateString(date)}</TdStyles>;
  };

  const getDealType = (d: IRequest) => {
    if (!d) {
      return '-';
    }

    if (d.Product) {
      const s = sg(
        () =>
          requestDealTypesDisplayList.find((v) => v.id === d.dealType)!.name,
        '-'
      );
      return s;
    }
    return '-';
  };

  let origin = '';
  if (request && request.Product) {
    origin = 'Manufacturer';
  }

  const fieldForAdmin = () => {
    return (
      <SmallTableItem color={request && request.color}>
        <TrStyles>
          <TdStyles>
            <div className="flag-container">
              <div>
                <ConditionalContainer
                  show={sgg(() => request.adminApproved === null, false)}
                >
                  <Flag />
                </ConditionalContainer>
              </div>
              <div>
                <ConditionalContainer show={request.showBubble}>
                  <Bubble />
                </ConditionalContainer>
              </div>
              <div>
                <ConditionalContainer
                  show={!sgg(() => request.Product!.adminCreated, false)}
                >
                  <UserOwnedIcon />
                </ConditionalContainer>
              </div>
              {sgg<any>(() => productName(request.Product!), null)}
            </div>
          </TdStyles>
        </TrStyles>
        <TrStyles>
          <ThStyles>Origin</ThStyles>
          <TdStyles>{origin}</TdStyles>
        </TrStyles>
        <TrStyles>
          <ThStyles>Request ID</ThStyles>
          <TdStyles>{request && request.id}</TdStyles>
        </TrStyles>
        <TrStyles>
          <ThStyles>Created at</ThStyles>
          {sg(() => dateItem(request.createdAt!), null)}
        </TrStyles>
        <TrStyles>
          <ThStyles>Buyer</ThStyles>
          <TdStyles>
            <ConditionalContainer
              show={
                isAdmin &&
                checkIfDealBuyerQualificationInformationExist(request)
              }
            >
              <div
                className="buyer-info"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (request) {
                    openBuyerQualificationAdminView(request.id);
                  }
                }}
              >
                <Info />
              </div>
            </ConditionalContainer>
            {request && request.BuyerCompany && request.BuyerCompany.name}{' '}
            <br />
            {request &&
              request.Buyer &&
              request.Buyer.firstName + ' ' + request.Buyer.lastName}{' '}
            {request && request.Buyer && request.Buyer.email}
          </TdStyles>
        </TrStyles>
        <TrStyles>
          <ThStyles>Seller</ThStyles>
          <TdStyles>
            {request && request.SellerCompany && request.SellerCompany.name}{' '}
            <br />
            {request &&
              request.Seller &&
              request.Seller.firstName + ' ' + request.Seller.lastName}{' '}
            {request && request.Seller && request.Seller.email}
          </TdStyles>
        </TrStyles>
        <TrStyles>
          <ThStyles>Status</ThStyles>
          <NewTdStyle>
            <StatusItem
              status={request && request.status}
              feeStatus={request && request.isPaid}
              showFeeStatus={false}
              showJustSent={
                differenceInSeconds(
                  new Date(),
                  new Date(request?.dealsGroup?.split('_')?.[0] || '2000-01-01')
                ) < secondsToShowJustSent &&
                iAmBuyingFinal &&
                companyId === sg(() => request.BuyerCompany!.id, 0)
              }
            />
          </NewTdStyle>
        </TrStyles>
        <TrStyles
          onClick={() => {
            if (request && !dealItem) {
              dispatch(setFlag_brokerChangeId(request.id));
            }
          }}
        >
          <ThStyles>Broker</ThStyles>
          <TdStyles>
            {broker ? broker!.fullName : '-'}
            <span style={{ marginLeft: '0.5ch', cursor: 'pointer' }}>
              <img src="img/edit-pen.svg" />
            </span>
          </TdStyles>
        </TrStyles>

        <TrStyles
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            if (request) {
              dispatch(setLinkSellerRequestToDealId(request.id));
              dispatch(setLinkSellerRequestToRequestModalOpen(true));
            }
          }}
        >
          <ThStyles>Request</ThStyles>
          <TdStyles>
            {/* {sg(
              () =>
                request &&
                request.Deal && (
                  <ViewLinkedDeal>
                    <img src={'img/edit-pen.svg'} />
                  </ViewLinkedDeal>
                ),
              <div>
                <ViewLinkedDeal>
                  <img src={'img/edit-pen.svg'} />
                </ViewLinkedDeal>
                Not set{' '}
              </div>
            )} */}
            {sg(
              () =>
                request &&
                request.Deal && (
                  <ViewLinkedDeal
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    href={'/deal-details/' + request.Deal.id}
                  >
                    <img src={'/img/view-item.svg'} />
                  </ViewLinkedDeal>
                ),
              ''
            )}
          </TdStyles>
        </TrStyles>

        <ActivityButtonContainer>
          <Link
            onClick={(e) => {
              e.preventDefault();
            }}
            to={
              request
                ? request.archivedAt === undefined ||
                  request.archivedAt === null
                  ? '/request-details/' + (request ? request.id : '')
                  : '/archive-info/' + (request ? request.id : '')
                : '/'
            }
          >
            <MessageButton label={'Messaging'} />
          </Link>
          <ButtonDivider />
          <ArchiveButton
            onClick={() => {
              setArchiveModalOpen(true);
            }}
            label={'Archive'}
          />
        </ActivityButtonContainer>
      </SmallTableItem>
    );
  };
  const fieldForOrdinaryUser = () => {
    const marketOfInterest = `${sg(
      () => getCountries(request.market!.split(',')),
      ''
    )}`;
    return (
      <SmallTableItem color={request && request.color}>
        <TrStyles>
          <TdStyles>
            {sgg<any>(() => productName(request.Product!), null)}
          </TdStyles>
        </TrStyles>
        <TrStyles>
          <ThStyles>Status</ThStyles>
          <NewTdStyle>
            <StatusItem
              status={request && request.status}
              feeStatus={request && request.isPaid}
              showFeeStatus={false}
              showJustSent={
                differenceInSeconds(
                  new Date(),
                  new Date(request?.dealsGroup?.split('_')?.[0] || '2000-01-01')
                ) < secondsToShowJustSent &&
                iAmBuyingFinal &&
                companyId === sg(() => request.BuyerCompany!.id, 0)
              }
            />
          </NewTdStyle>
        </TrStyles>
        <TrStyles>
          <ThStyles>Updated</ThStyles>
          {sg(
            () =>
              iAmSellingFinal &&
              !iAmBuyingFinal &&
              dateItem(request.sellerUpdatedAt),
            ''
          )}
          {sg(() => iAmBuyingFinal && dateItem(request.buyerUpdatedAt), '')}
        </TrStyles>
        <TrStyles>
          <ThStyles>Market</ThStyles>
          <ThStyles>
            {marketOfInterest === '' ? '-' : marketOfInterest}
          </ThStyles>
        </TrStyles>
        <TrStyles>
          <ThStyles>Deal type</ThStyles>
          <TdStyles>{getDealType(request)}</TdStyles>
        </TrStyles>
        <ActivityButtonContainer>
          <Link
            onClick={(e) => {
              e.preventDefault();
            }}
            to={
              request
                ? request.archivedAt === undefined ||
                  request.archivedAt === null
                  ? '/request-details/' + (request ? request.id : '')
                  : '/archive-info/' + (request ? request.id : '')
                : '/'
            }
          >
            <MessageButton label={'Messaging'} />
          </Link>
          <ButtonDivider />
          {isAdmin && (
            <ArchiveButton
              onClick={() => {
                setArchiveModalOpen(true);
              }}
              label={'Archive'}
            />
          )}
        </ActivityButtonContainer>
      </SmallTableItem>
    );
  };

  if (isAdmin) {
    return (
      <>
        {fieldForAdmin()}
        <ArchiveModal
          archiveCallback={() => {
            request && request.archivedAt
              ? restoreArchiveFunc(request.id)
              : archiveDealFunc(request.id, true);
            setArchiveModalOpen(false);
          }}
          isOpen={archiveModalOpen}
          closeModal={() => setArchiveModalOpen(false)}
          isRestoring={request && request.archivedAt ? true : false}
        />
      </>
    );
  }
  return fieldForOrdinaryUser();
};

export default DealSmallTableItem;
