import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import * as A from './actions';
import { State } from './types';
import { IPotentialSupplier } from '../../requests/types';
import { User } from '../../auth/types';

// @ts-ignore
export default combineReducers<State>({
  currentPage: cr<number>(0, reduce(A.setCurrentPage, set)),
  totalCount: cr<number>(0, reduce(A.setTotalCount, set)),
  pageSize: cr<number>(20, reduce(A.setPageSize, set)),
  potentialSuppliers: cr<IPotentialSupplier[]>(
    [],
    reduce(A.setPotentialSuppliers, set)
  ),
  buyer: cr<User | null>(null, reduce(A.setDealDetailsBuyer, set)),
});
