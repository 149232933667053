import {
  createAccount,
  setRegisterError,
} from 'app/store/modules/auth/actions';
import { User } from 'app/store/modules/auth/types';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CreateAccount from './create-account';

interface IDispatchProps {
  register: (
    firstName: string,
    lastName: string,
    companyName: string,
    phoneNumber: string,
    isBuyer: boolean,
    isSeller: boolean,
    isModal?: boolean
  ) => any;
  clearRegisterError: () => any;
}
interface IStateProps {
  error: string;
  isOpen: boolean;
  loggedInUser: User;
}
interface IOwnProps {
  isModal?: boolean;
  haveAccountCallback?: () => void;
}
type ComposedProps = IOwnProps & IWithApolloClient & RouteComponentProps;
export type IProps = IStateProps &
  IDispatchProps &
  ComposedProps &
  RouteComponentProps;
export default withApollo<IOwnProps>(
  withRouter(
    connect<IStateProps, IDispatchProps, ComposedProps, IStore>(
      (state) => ({
        error: state.auth.registerError,
        isOpen: state.auth.getStartedModalOpen,
        loggedInUser: state.auth.loggedInUser,
      }),
      (dispatch, ownProps) => ({
        register: (
          firstName,
          lastName,
          companyName,
          phoneNumber,
          isBuyer,
          isSeller,
          isModal?
        ) => {
          return dispatch(
            createAccount({
              firstName,
              lastName,
              companyName,
              phoneNumber,
              isBuyer,
              isSeller,
              isModal,
              client: ownProps.client,
            })
          );
        },
        clearRegisterError: () => dispatch(setRegisterError('')),
      })
    )(CreateAccount)
  )
);
