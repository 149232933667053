import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeEvery,
} from '@redux-saga/core/effects';

import { setNotification } from 'app/store/modules/requests/actions';
import { sendMessageAction } from 'app/store/modules/requests/actions';
import { IStore } from 'app/store/types';
import { isBrowser } from 'app/store/utils/is-browser';
import { logger } from 'app/utils/logger';
import { sg } from 'app/utils/safe-get';

import * as actions from '../actions';
import * as queries from '../query';
import { ILatestByDealId } from '../types';
import { prepareTermsheetCache } from '../utils';

function* createTermsheetFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const action: ReturnType<
        typeof actions.createTermsheetAction
      > = yield take(actions.createTermsheetAction);

      const { client, data } = action.payload;

      const requestId = sg(() => data.request_id, null);

      const { auth }: IStore = yield select();

      if (client && auth.loggedInUser && requestId) {
        yield put(actions.creatingTermsheet(true));
        yield put(actions.createdTermsheet(false));

        const response: Unpromisefy<
          ReturnType<typeof queries.createTermSheetQuery>
        > = yield call(queries.createTermSheetQuery, {
          client,
          data,
        });

        if (!response) {
          throw new Error('GraphQL returned undefined');
        }

        const termsheetId = response.data!.insert_termsheets.returning[0].id!;
        const termsheets = sg(
          () => response.data!.insert_termsheets.returning,
          []
        );

        const supplyUnitPrices = sg(
          () =>
            data.supply_unit_prices.map((item) => {
              return { ...item, termsheet_id: termsheetId };
            }),
          []
        );

        for (const item of supplyUnitPrices) {
          yield call(queries.createSupplyUnitPricesQuery, {
            client,
            data: item,
          });
        }

        const termsheet = termsheets[0];
        termsheet.supply_unit_prices = supplyUnitPrices;

        const text = JSON.stringify(termsheet);

        yield put(
          sendMessageAction({
            client,
            requestId,
            text,
            for: sg(() => data.for!, 'both'),
            termsheetId,
          })
        );

        const termsheetCache = prepareTermsheetCache(termsheets);
        const termsheetIdByDealId: ILatestByDealId = {
          [data.request_id]: termsheetId,
        };

        yield put(actions.creatingTermsheet(false));
        yield put(actions.createdTermsheet(true));
        yield put(actions.setTermsheetCache(termsheetCache));
        yield put(actions.setLatestTermsheet(termsheetIdByDealId));
      }
    } catch (err) {
      logger.error(
        'src/app/store/modules/termsheets/sagas.ts -> createTermsheetFlow',
        err
      );
      const state = yield select();
      yield put(
        setNotification({
          ...state.products.error,
          listError: 'There was an error while sending the quotation',
        })
      );

      yield put(actions.creatingTermsheet(false));
    }
  }
}

function* fetchLatestTermsheet(
  action: ReturnType<typeof actions.fetchLatestTermsheet>
) {
  try {
    const { client, requestId } = action.payload;

    const { auth }: IStore = yield select();

    if (client && auth.loggedInUser && requestId) {
      const response: Unpromisefy<
        ReturnType<typeof queries.getLatestTermsheetQuery>
      > = yield call(queries.getLatestTermsheetQuery, {
        client,
        request_id: requestId,
      });
      if (!response) {
        throw new Error('GraphQL returned undefined');
      }

      const termsheets = sg(() => response.data!.returning, []);
      if (termsheets.length > 0) {
        const termsheetId = response.data!.returning[0].id!;

        const termsheetCache = prepareTermsheetCache(termsheets);
        const termsheetIdByDealId: ILatestByDealId = {
          [requestId]: termsheetId,
        };

        yield put(actions.setTermsheetCache(termsheetCache));
        yield put(actions.setLatestTermsheet(termsheetIdByDealId));
      }
    }
  } catch (err) {
    logger.error(
      'src/app/store/modules/termsheet/sagas/index.ts -> fetchLatestTermsheet',
      err
    );
  }
}

function* fetchLatestTermsheetSaga() {
  if (!isBrowser()) {
    return;
  }
  yield takeEvery(actions.fetchLatestTermsheet, fetchLatestTermsheet);
}

export default function* rootSaga() {
  yield all([fork(createTermsheetFlow), fork(fetchLatestTermsheetSaga)]);
}
