import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import * as A from './actions';
import {
  DealFilter,
  defaultPageSize,
  IDeal,
  MyDealFilterEnum,
  State,
} from './types';

export default combineReducers<State>({
  currentPage: cr<number>(0, reduce(A.setCurrentPage, set)),
  isFetching: cr<boolean>(false, reduce(A.isFetching, set)),
  hasFetched: cr<boolean>(false, reduce(A.hasFetched, set)),
  requests: cr<IDeal[]>([], reduce(A.setDeals, set)),
  totalCount: cr<number>(0, reduce(A.setTotalCount, set)),
  createDealModalOpen: cr<boolean>(
    false,
    reduce(A.setCreateRequestModalOpen, set)
  ),
  editDealModalOpen: cr<boolean>(false, reduce(A.setEditRequestModalOpen, set)),
  editDealId: cr<number | null>(null, reduce(A.setEditRequestId, set)),
  pageSize: cr<number>(defaultPageSize, reduce(A.setPageSize, set)),
  filter: cr<DealFilter>(
    { myRequests: MyDealFilterEnum.everyone },
    reduce(A.setDealFilter, set)
  ),
  filterText: cr<string | ''>('', reduce(A.setFilterTextDeal, set)),
});
