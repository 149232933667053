import { media } from 'app/styles/breakpoint';
import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PagerStyle = styled.div`
  text-align: center;
  margin-left: auto;
  display: flex;
  ${media.max.xs`
   margin-left: 0px;
  `}
  ul {
    display: flex;
    padding-left: 15px;
    font-size: 13px;
    ${media.max.xs`
   padding-left: 0px;
   margin-left: -4px;
  `}
  }
  li > a:focus {
    outline: 0;
  }
  li {
    margin-top: auto;
    margin-bottom: auto;
    border: transparent;
    list-style-type: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
    color: ${(props) => props.theme.colors.grayDark};
    > a {
      color: ${(props) => props.theme.colors.grayDark};
    }
    &.active {
      color: ${(props) => props.theme.colors.primary};
      font-weight: bold;
      border-radius: 5px;
      padding: 5px;
      > a {
        color: ${(props) => props.theme.colors.primary};
      }
    }
    &.break a {
      cursor: default;
    }
  }
  li.next,
  li.previous {
    padding: 0;
  }
`;
const PagerIconContainer = styled.div`
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
`;
const PagerIcon = styled.img`
  width: 6px;
  height: 10.1px;
  object-fit: contain;
`;
const Container = styled.div`
  display: flex;
  position: sticky;
  left: 0px;
  min-height: 80px;
  ${media.max.sm`
  padding-bottom: 15px;
  `}

  ${media.max.sm`
    flex-wrap:wrap;
  `}

  > * {
    /* margin-top: auto; */
    /* margin-bottom: auto; */
  }
  .wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
  }
`;
const Showing = styled.p`
  margin-right: 32px;
  font-size: 13px;
  color: ${(props) => props.theme.colors.grayDark};
`;
const PageSizeContainer = styled.div`
  font-size: 13px;
  display: flex;
  color: ${(props) => props.theme.colors.grayDark};
  margin-top: auto;
  margin-bottom: auto;
`;

interface IPageSizeLabel {
  active: boolean;
}
const PageSizeLabel = styled.span<IPageSizeLabel>`
  margin: 0 10px;
  cursor: pointer;
  ${(props) =>
    props.active &&
    `
    color: ${props.theme.colors.primary}
    font-weight: bold;
  `}
`;
interface IProps {
  totalCount: number;
  pageSize: number;
  page: number;
  setPage: (val: number) => void;
  setPageSize: (val: number) => void;
  onPageChange: () => void;
  hideCount?: boolean;
}

const _2 = 1;
const _7 = 3;
/* eslint-disable no-magic-numbers */
const pageSizes = [10, 20, 50];
const Pager: React.FC<IProps> = (props) => {
  const pageSizesCurrent = pageSizes.filter(
    (x) => x <= props.totalCount || props.totalCount > 20
  );
  return (
    <Container>
      <div className="wrapper">
        {props.hideCount !== true && (
          <Showing>{`Showing ${props.page * props.pageSize + 1} to ${Math.min(
            (props.page + 1) * props.pageSize,
            props.totalCount
          )} of ${props.totalCount} elements`}</Showing>
        )}
        <PageSizeContainer>
          {props.hideCount !== true &&
            pageSizesCurrent.length > 0 &&
            `Show per page:`}
          {props.hideCount !== true &&
            pageSizesCurrent.map((v, i) => (
              <PageSizeLabel
                onClick={() => {
                  props.setPageSize(v);
                  props.setPage(0);
                  props.onPageChange();
                }}
                key={i}
                active={props.pageSize === v}
              >
                {v}
              </PageSizeLabel>
            ))}
        </PageSizeContainer>
      </div>
      <PagerStyle>
        <ReactPaginate
          nextLabel={
            <PagerIconContainer>
              <PagerIcon src={'/img/forward.svg'} />
            </PagerIconContainer>
          }
          previousLabel={
            <PagerIconContainer>
              <PagerIcon src={'/img/back.svg'} />
            </PagerIconContainer>
          }
          key={props.page}
          breakLabel={'...'}
          breakClassName={'break'}
          pageCount={props.totalCount / props.pageSize}
          marginPagesDisplayed={_2}
          pageRangeDisplayed={_7}
          initialPage={props.page}
          onPageChange={(selectedPage: any) => {
            props.setPage(selectedPage.selected);
            if (selectedPage.selected !== props.page) {
              props.onPageChange();
            }
          }}
          activeClassName={'active'}
        />
      </PagerStyle>
    </Container>
  );
};

export const Components = {
  PagerStyle,
  PagerIconContainer,
  PagerIcon,
  Container,
  Showing,
  PageSizeContainer,
  PageSizeLabel,
};
export default Pager;
