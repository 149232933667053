import { createActionCreator as c } from '@reduxify/utils';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { Dictionary } from 'app/utils/dictionary';
import {
  CompanyFormFields,
  ICompany,
  ICompanyDirectory,
  Notifications,
} from './types';

/** Data fetch section */
const fetchCompanies = c<IWithApolloClient>('companies/FETCH_MA_COMPANIES');
export { fetchCompanies };

interface IFetchCompany extends IWithApolloClient {
  id: number;
}

const fetchCompany = c<IFetchCompany>('companies/FETCH_MA_COMPANY');
export { fetchCompany };

const setCompaniesCache = c<Dictionary<ICompany>>(
  'companies/SET_MA_COMPANIES_CECHE'
);
export { setCompaniesCache };

const setCompaniesNotification = c<Notifications>('companies/SET_NOTIFICATION');
export { setCompaniesNotification };

const setCompaniesIds = c<number[]>('companies/SET_IDS');
export { setCompaniesIds };

const setCompaniesTotalCount = c<number>('companies/SET_TOTAL_COUNT');
export { setCompaniesTotalCount };

const setCompaniesCurrentPage = c<number>('companies/SET_CURRENT_PAGE');
export { setCompaniesCurrentPage };

const setCompaniesPageSize = c<number>('companies/SET_PAGE_SIZE');
export { setCompaniesPageSize };

const hasFetched = c<boolean>('companies/SET_FETCHED');
export { hasFetched };

const isFetching = c<boolean>('companies/SET_FETCHING');
export { isFetching };

const deleteCompany = c<IFetchCompany>('companies/DELETE_COMPANY');
export { deleteCompany };

interface ICreateCompany extends IWithApolloClient {
  values: CompanyFormFields;
}
const createCompany = c<ICreateCompany>('companies/CREATE_COMPANY');
export { createCompany };

const setEditModal = c<boolean>('companies/SET_EDIT_MODAL');
export { setEditModal };

const setEditCompanyId = c<number>('companies/SET_EDIT_COMPANY_ID');
export { setEditCompanyId };

const fetchCompanyDirectoryNameList = c<IWithApolloClient>(
  'companies/FETCH_COMPANY_DIRECTORY_COUNTRY_NAME_LIST'
);
export { fetchCompanyDirectoryNameList };

interface IFetchByCountry extends IWithApolloClient {
  country: string;
}
const fetchCompanyDirectoryByCountryList = c<IFetchByCountry>(
  'companies/FETCH_COMPANY_DIRECTORY_BY_COUNTRY_LIST'
);
export { fetchCompanyDirectoryByCountryList };

const setCountryNameList = c<string[]>(
  'companies/SET_COMPANY_DIRECTORY_COUNTRY_NAME_LIST'
);
export { setCountryNameList };

const setCompanyByCountryNameList = c<ICompanyDirectory[]>(
  'companies/SET_COMPANY_DIRECTORY_BY_COUNTRY_LIST'
);

export { setCompanyByCountryNameList };

export interface IFetchByCompanyId extends IWithApolloClient {
  companyId: string;
}
export const fetchCompanyDirectoryCompanyById = c<IFetchByCompanyId>(
  'companies/FETCH_COMPANY_DIRECTORY_COMPANY_BY_ID'
);

export interface FetchCompanyDirectoryCountrySEOTextProps
  extends IWithApolloClient {
  countryName: string;
}
export const fetchCompanyDirectorySEOByCountryName = c<FetchCompanyDirectoryCountrySEOTextProps>(
  'companies/FETCH_COMPANY_DIRECTORY_COUNTRY_SEO_TEXT_BY_NAME'
);

export const setCompanyDirectorySEOByCountryName = c<string>(
  'companies/SET_COMPANY_DIRECTORY_COUNTRY_SEO_TEXT_BY_NAME'
);
