import * as saga from '@redux-saga/core/effects';
import { IStore } from 'app/store/types';
import { isBrowser } from 'app/store/utils/is-browser';
import * as A from './actions';
import * as Query from '../query';
import sg from '../../../../utils/safe-get';
import _ from 'lodash';
import { IGetFilteredSuppliersProductV2Paginated } from '../query';

function* watchFetchPotentialSuppliers() {
  if (!isBrowser()) {
    return;
  }
  yield saga.takeLatest(A.fetchPotentialSupplier, fetchPotentialSuppliers);
}
function getProducts(recommendedProducts: any, payload: any) {
  const products: any = [];
  const sellerCompanies: any = [];

  if (payload.requestedSuppliers !== undefined) {
    payload.requestedSuppliers.map((reqSup: any) => {
      const reqSupObj = {
        id: sg(() => reqSup.SellerCompany.id, 0),
        form_specification: sg(() => reqSup.Product.formSpecification, null),
        strength: sg(() => reqSup.Product.strength, null),
      };
      sellerCompanies.push(reqSupObj);
    });
  }

  if (recommendedProducts) {
    recommendedProducts
      .filter(
        (x: any) =>
          !payload.existingProductIds ||
          payload.existingProductIds.length === 0 ||
          payload.existingProductIds.indexOf(x.productsV2.productId) < 0
      )
      .forEach(
        ({
          productsV2: pv,
        }: {
          productsV2: IGetFilteredSuppliersProductV2Paginated;
        }) => {
          if (pv.SellerCompany) {
            const prodObj = {
              id: pv.SellerCompany.id,
              form_specification: pv.formSpecification,
              strength: pv.strength,
            };
            const sellerRequested = _.some(sellerCompanies, prodObj);
            const companyStatus = pv.SellerCompany.hidden;
            if (!sellerRequested && companyStatus === false) {
              const notAvailableMarkets = pv.notAvailableMarkets;
              if (payload.markets.length) {
                if (notAvailableMarkets !== null) {
                  const intersection = payload.markets!.filter(
                    (country: string) => notAvailableMarkets.includes(country)
                  );
                  if (intersection.length !== payload.markets.length) {
                    pv.collapsible = false;
                    products.push(pv);
                  }
                } else {
                  pv.collapsible = false;
                  products.push(pv);
                }
              }
            }
          }
        }
      );
  }
  return products;
}
function* fetchPotentialSuppliers({
  payload,
}: ReturnType<typeof A.fetchPotentialSupplier>) {
  try {
    const {
      auth: { loggedInUser },
      dealDetails: { currentPage, pageSize },
    }: // @ts-ignore
    IStore = yield saga.select();

    if (loggedInUser.id) {
      const params: Params<
        typeof Query.getPotentialSuppliersPaginationQuery
      > = {
        isAdmin: payload.isAdmin,
        doseFormCategory: payload.doseFormCategory,
        markets: payload.markets,
        requestedSuppliers: payload.requestedSuppliers,
        substance1: payload.substance1,
        substance2: payload?.substance2,
        substance3: payload?.substance3,
        client: payload.client,
        limit: pageSize,
        offset: currentPage * pageSize,
      };

      const res: Unpromisefy<
        ReturnType<typeof Query.getPotentialSuppliersPaginationQuery>
        // @ts-ignore
      > = yield saga.call(Query.getPotentialSuppliersPaginationQuery, params);
      if (!res) {
        throw new Error('GraphQL returned undefined');
      }
      const recommendedProducts = sg(
        () => res!.data.myProductsListPaginated,
        undefined
      );
      const products = getProducts(recommendedProducts, payload);

      yield saga.put(A.setPotentialSuppliers(products));
    }
  } catch (e) {
    console.log(e);
  }
}
function* watchPotentialSupplierCount() {
  if (!isBrowser()) {
    return;
  }
  yield saga.takeLatest(A.getPotentialSupplierCount, getPotentialSupplierCount);
}
function* getPotentialSupplierCount({
  payload,
}: ReturnType<typeof A.getPotentialSupplierCount>) {
  try {
    const {
      auth: { loggedInUser },
      dealDetails: { totalCount },
    }: // @ts-ignore
    IStore = yield saga.select();

    if (loggedInUser.id && totalCount < 1) {
      const params: Params<typeof Query.getPotentialSuppliersQuery> = {
        isAdmin: payload.isAdmin,
        doseFormCategory: payload.doseFormCategory,
        substance1: payload.substance1,
        substance2: payload?.substance2,
        substance3: payload?.substance3,
        client: payload.client,
      };

      const res: Unpromisefy<
        ReturnType<typeof Query.getPotentialSuppliersQuery>
      > = yield saga.call(Query.getPotentialSuppliersQuery, params);
      if (!res) {
        throw new Error('GraphQL returned undefined');
      }
      // @ts-ignore
      const recommendedProducts = sg(() => res!.data.myProductsList, undefined);
      const products = getProducts(recommendedProducts, payload);
      yield saga.put(A.setTotalCount(products.length));
    }
  } catch (e) {
    console.log(e);
  }
}
export default function* rootSaga() {
  yield saga.all([
    saga.fork(watchFetchPotentialSuppliers),
    saga.fork(watchPotentialSupplierCount),
  ]);
}
