import { _, media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import { Fetch, IProps } from './index';

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  margin-bottom: 15px;
  ${_(media.max.md)} {
    margin-bottom: 7px;
  }
  ${media.max.sm`
    padding:14px;
    padding-bottom: 0px;
  `}
`;
const ContentWrappers = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  min-width: 200px;
  margin-top: 3px;
`;

const ListContainerHeading = styled.h1`
  font-size: 34px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.grayExtraDark};
  margin: 0;
`;

const ListTotal = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.grayDark};
  margin-left: 11px;
  margin-right: 17px;
  margin-bottom: 5px;
`;

const MyDealsListHeader: React.FC<IProps> = ({
  totalItems,
  totalDealCount,
  fetch,
  isAdmin,
  ...props
}) => {
  const headers = {
    [Fetch.All]: 'Requests',
    [Fetch.Buying]: 'I am buying',
    [Fetch.Selling]: 'I am selling',
  };
  return (
    <HeaderContainer>
      <ContentWrappers>
        <ListContainerHeading>{headers[fetch]}</ListContainerHeading>
        <ListTotal>{totalItems} Total</ListTotal>
      </ContentWrappers>
    </HeaderContainer>
  );
};

export default MyDealsListHeader;
