import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { companyDirectoryFragment } from '../fragments/ma-company';

export interface IGetCompanyDirectory extends IWithApolloClient {
  country: string;
}
export interface IGetCompanyDirectoryReturn {
  companydirectory: { id: number; country: string; name: string }[];
}
export async function getCompanyDirectoryQuery(p: IGetCompanyDirectory) {
  try {
    const whereClause = u.where(u._ilike('country', '$country'));
    return await p.client.query({
      query: gql`
      ${companyDirectoryFragment.fragment()}
      query getCompanyDirectory(${u.params(['$country', 'String'])}) {
        companydirectory: company_directory_imported(${whereClause}) {
          id
          name
          country
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: { country: p.country },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> getCompanyDirectoryQuery',
      e
    );
  }
}
