export const ROOT_ID = 'root';
export const intercomAppId = 'lg3659pm';
export const intercomAppIdTest = 'f9h4cr2f';
export const anonymousUserId = 'anonymousUserId';
export const routes = {
  home: '/',
  blog: 'https://www.pipelinepharma.com/blog',
  homeAdvertisement: '/lp/home',
  marketplacePage: '/marketplace/page/:pageNr',
  marketplace: '/marketplace',
  marketplaceSearchPage: '/:keyword/page/:pageNr',
  marketplaceSearch: '/:keyword',
  marketplaceSearchCountry: '/:keyword/country/:country',
  marketplaceSearchCountryPage: '/:keyword/country/:country/page/:pageNr',
  marketplaceOld: '/marketplace',
  pharmaceutical_manufacturers: '/pharmaceutical/manufacturers/page/:pageNr',
  pharmaceutical_manufacturers_countries:
    '/pharmaceutical/manufacturers/countries',
  pharmaceutical_manufacturers_country:
    '/pharmaceutical/manufacturers/country/:country',
  pharmaceutical_manufacturers_country_page:
    '/pharmaceutical/manufacturers/country/:country/page/:pageNr',
  faqforbuyers: '/faq-for-buyers',
  faqforsellers: '/faq-for-sellers',
  faqforacquisitionsBuyers: '/faq-for-acquisitions-buyers',
  faqforacquisitionsSellers: '/faq-for-acquisitions-sellers',
  terms_of_service: '/terms-of-service',
  privacy: '/privacy',
  gdpr_compliant: '/gdpr-compliant',
  cookies: '/cookies',
  partnering: '/partnering-with-us',
  contacts: '/contacts',
  get_started: '/get-started',
  create_account: '/create-account',
  register: '/sign-up',
  register_old: '/register',
  login: '/login',
  nextLogin: '/sign-in',
  products: '/list-products',
  myoffers: '/my-offers',
  deals: '/deals*',
  fallBackMyDeals: '/my-deals',
  requests: '/requests',
  my_deals: '/requests',
  deal_v2_details: '/deal-details/:id',
  deals_buying: '/deals/buying',
  deals_selling: '/deals/selling',
  requestDetails: '/request-details/:id',
  fallBackRequestDetails: '/deal-info/:id',
  archivedInfo: '/archive-info/:id',
  dealsarchive: '/requests-archive',
  substances: '/list-substances',
  substances_new: '/create-substance',
  usersettings: '/users-settings',
  products_new: '/create-product',
  trade_product_new: '/create-wholesale-product',
  update_product_route: '/update-product/:id',
  update_product: '/update-product',
  update_trade_product_route: '/update-wholesale-product/:id',
  update_trade_product: '/update-wholesale-product',
  new_offer_route: '/create-offer/:id',
  new_offer: '/create-offer',
  new_offer_trade_route: '/create-wholesale-offer/:id',
  new_offer_trade: '/create-wholesale-offer',
  update_offer_trade_route: '/update-wholesale-offer/:id',
  update_offer_trade: '/update-wholesale-offer',
  update_offer_route: '/update-offer/:id',
  update_offer: '/update-offer',
  offer: '/offer/:type/:name',
  offer_trade: '/wholesale-offer/:type/:name',
  offer_old: '/offer/:type/:name/:id',
  offer_old_1: '/offers/:name',
  offer_trade_old: '/trade-offer/:type/:name',
  product: '/product/:type/:name',
  product_trade: '/wholesale-product/:type/:name',
  product_old: '/product/:type/:name/:id',
  product_old_1: '/products/:name',
  product_trade_old: '/trade-product/:type/:name',
  admin: '/admin',
  email_verify: '/email-verify/:id',
  linkedin_failed: '/auth/linkedin/failure',
  linkedin_success: '/auth/linkedin/success',
  companies: '/assets-for-sale',
  companiesOld: '/companies',
  contacts_companies: '/asset-for-sale/:id',
  contacts_companies_old: '/companies-connect/:id',
  sellers_home: '/sell-products',
  sellers_home_old: '/sellers-home',
  site_map: '/site-map',
  admin_onboarding: '/onboarding-products',
  companies_home_seller: '/sell-assets',
  companies_home_seller_old: '/sell-business',
  companies_home_buyer: '/buy-assets',
  companies_home_buyer_old: '/buy-business',
  page_not_found: '/page-not-found',
  company_directory: '/pharmaceutical-companies',
  company_directory_country: '/pharmaceutical-companies/:country',
  company_directory_country_company:
    '/pharmaceutical-companies/:country/:company',
  fast_track_program: '/fast-track-program',
  about_us: '/about-us',
  buyer: '/buyer',
  seller: '/seller',
  nextGetStarted: '/get-started',
};

export { generatePath } from 'react-router';
