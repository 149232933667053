import {
  fetchSubstancesSuccess,
  filterSubstance,
  requestFetchSubstances,
  setNotification,
  setStatus,
  setSubstanceCache,
  setSubstanceFilter,
  setSubstanceIds,
  setVisibleSubstanceIds,
  setCurrentPage,
  deleteSubstance as deleteSubstanceAction,
} from './actions';
import { IStore } from '../../types';
import {
  all,
  call,
  fork,
  put,
  select,
  take,
  takeLatest,
} from '@redux-saga/core/effects';
import { Status } from './types';
import { isBrowser } from '../../utils/is-browser';
import { deleteSubstanceQuery, getSubstanceListQuery } from './query';
import { hasFetched, setTotalCount } from './actions';
import { logger } from '../../../utils/logger';
import { toDictionary } from '../../../utils/dictionary';
import { ApolloClient } from 'apollo-boost';
import { requestFetchNavCount } from '../auth/actions';

function* fetchSubstancesSaga(
  action: ReturnType<typeof requestFetchSubstances>
) {
  try {
    const { client, pageSize } = action.payload;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { substances, auth }: IStore = yield select();
    if (client && auth.loggedInUser) {
      yield put(setStatus(Status.loading));

      const response: Unpromisefy<
        ReturnType<typeof getSubstanceListQuery>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield call(getSubstanceListQuery, {
        client,
        size: pageSize || substances.pageSize,
        offset: substances.currentPage * substances.pageSize,
        isAdmin: auth.loggedInUser.isAdmin,
        filter: substances.filter,
      });
      if (!response) {
        throw new Error('GraphQL returned undefined');
      }

      yield put(
        fetchSubstancesSuccess({
          substanceList: response.data.substances,
          totalCount: response.data.substances_aggregate.aggregate.totalCount,
        })
      );
    }
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    yield put(
      setNotification({
        ...state.substances.error,
        listError: 'There was an error loading your substances.',
      })
    );
  }
}

function* watchFetchData() {
  if (!isBrowser()) {
    return;
  }
  yield takeLatest(requestFetchSubstances, fetchSubstancesSaga);
}

function* fetchSuccess() {
  try {
    if (!isBrowser()) {
      return;
    }
    while (true) {
      const action: ReturnType<typeof fetchSubstancesSuccess> = yield take(
        fetchSubstancesSuccess
      );

      const { substanceList, totalCount } = action.payload;
      const substanceCache = toDictionary(
        substanceList.filter((x: any) => !!x.id).map((v) => v),
        (v: any) => v.id
      );
      const substanceListItems: any[] = substanceList.map((x: any) => ({
        id: x.id,
        updatedAt: x.updatedAt,
      }));
      yield all([
        put(setSubstanceCache(substanceCache)),
        put(setSubstanceIds(substanceListItems)),
        put(setVisibleSubstanceIds(substanceListItems)),
        put(setTotalCount(totalCount)),
        put(setStatus(Status.idle)),
        put(hasFetched(true)),
      ]);
    }
  } catch (err) {
    logger.err(err);
  }
}
function* filterSubstanceSetFlow() {
  if (!isBrowser()) {
    return;
  } else {
    while (true) {
      const action: ReturnType<typeof filterSubstance> = yield take(
        filterSubstance
      );
      const { filter, client } = action.payload;
      yield put(setSubstanceFilter(filter));
      yield put(setCurrentPage(0));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state = yield select();
      const authUser = state.auth.loggedInUser
        ? state.auth.loggedInUser
        : undefined;
      if (authUser) {
        yield put(
          requestFetchSubstances({
            client,
            filter,
          })
        );
      }
    }
  }
}
function* deleteSubstanceFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof deleteSubstanceAction> = yield take(
      deleteSubstanceAction
    );
    const { client, substanceId } = action.payload;

    yield call(deleteSubstance, substanceId, client);
  }
}
function* deleteSubstance(substanceId?: number, client?: ApolloClient<any>) {
  try {
    if (!isBrowser()) {
      return;
    }

    if (client && substanceId) {
      const state: IStore = yield select();

      let ids = [...state.substances.substanceIds];
      let visibleIds = [...state.substances.visibleSubstanceIds];
      yield call(deleteSubstanceQuery, { client, id: substanceId });

      const check = (x: any) => {
        return x.id && x.id.toString() !== substanceId.toString();
      };

      ids = ids.filter((x: any) => check(x));
      visibleIds = visibleIds.filter((x: any) => check(x));

      yield all([
        put(setSubstanceIds(ids)),
        put(setVisibleSubstanceIds(visibleIds)),
      ]);

      const authUser = state.auth.loggedInUser
        ? state.auth.loggedInUser
        : undefined;
      if (authUser) {
        yield put(requestFetchNavCount({ client }));

        yield put(
          requestFetchSubstances({
            client,
            filter: state.substances.filter,
          })
        );
        yield put(
          setNotification({
            ...state.substances.error,
            listSuccess: 'Substance deleted successfully',
          })
        );
      }
    }
  } catch (err) {
    logger.error(
      'src/app/store/modules/substances/sagas.ts -> deleteSubstance',
      err
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();

    yield put(
      setNotification({
        ...state.substances.error,
        listError:
          'There was an error deleting this product. Please try again later.',
      })
    );
  }
}
export default function* rootSaga() {
  yield all([
    fork(fetchSuccess),
    fork(watchFetchData),
    fork(filterSubstanceSetFlow),
    fork(deleteSubstanceFlow),
  ]);
}
