import { media } from 'app/styles/breakpoint';
import * as React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  icon: string;
  childBold: string;
  childSimple: string;
  isDeal?: boolean;
  button?: React.ReactNode;
  isMarketplace?: boolean;
}

const FirstIconContainer = styled.div<any>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 35px;

  ${(props) =>
    props.isDeal &&
    css`
      max-width: 100%;
      padding: 0;
      background: ${props.theme.colors.white};
      border-radius: 3px;
      box-shadow: 0 8px 24px 0 rgba(53, 64, 82, 0.03);
      border: none;
    `}

  ${media.max.sm`
    padding: 50px 30px 50px 30px;
  `}

${(props) =>
    props.isMarketplace &&
    `
  ${media.max.sm`
    padding-left:5%;
    padding-right:5%;
    `}
`}
`;

const FirstIcon = styled.img`
  width: 140px;
  height: 140px;
  object-fit: contain;
  margin-bottom: 20px;
`;

const FirstTimeText1 = styled.h2`
  font-size: 26px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  text-align: center;
  margin: 0;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${media.max.sm`
  font-size: 24px;
`}
`;

const FirstTimeText2 = styled.p`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  padding-top: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin: 0;
  width: 500px;
  max-width: 100%;
  ${media.max.sm`
  font-size: 14px;
  padding-top: 17px;
`}
`;

const InnerContainer = styled.div<any>`
  border: dashed 2px ${(props) => props.theme.colors.grayLight};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 42px;
  padding-top: 65px;
  padding-bottom: 65px;
  width: 100%;

  ${(props) =>
    props.isDeal &&
    css`
      border: none;
    `}

  ${media.max.sm`
    flex-direction:column;
    padding: 30px;
  `}
`;

class FirstTimePageComponent extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <FirstIconContainer
        isDeal={this.props.isDeal}
        isMarketplace={this.props.isMarketplace}
      >
        <InnerContainer isDeal={this.props.isDeal}>
          <FirstIcon src={this.props.icon} />
          <FirstTimeText1>{this.props.childBold}</FirstTimeText1>
          <FirstTimeText2>{this.props.childSimple}</FirstTimeText2>
          {this.props.button}
        </InnerContainer>
      </FirstIconContainer>
    );
  }
}

export { FirstTimePageComponent };
export default FirstTimePageComponent;
