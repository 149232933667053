import { newProductsDate } from 'app/store/modules/marketplace/query';
import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';

interface ProductCountry {
  countryCode: string;
}

export interface IGetProductCountriesReturn {
  productCountries: ProductCountry[];
}

export async function getProductCountriesQuery(
  p: IWithApolloClient
): Promise<any> {
  const notDeleted = u._is_null('deleted_at');
  const companyWhere = u.nest('Company', u._eq('hidden', false));
  const oldFilters = u._or(u._gte('created_at', '$newProductsDate'));
  try {
    return await p.client.query({
      query: gql`
      query getProductsCountries(${u.params(
        ['$filterOld', 'String'],
        ['$filterOld2', 'String'],
        ['$filterOld3', 'Boolean'],
        ['$newProductsDate', 'timestamptz']
      )}) {
        productCountries: products_v2 (
          ${u.distinct('finished_dose_country')}
          ${u.where(
            u._and(notDeleted, companyWhere, oldFilters, u._gte('status', 1))
          )}
        ){
          countryCode: finished_dose_country
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        newProductsDate,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/manufacturers/query.ts -> getProductCountriesQuery',
      e
    );
  }
}
