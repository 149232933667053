import { IQuotationFormData } from 'app/modules/admin/quotation/types';
import { PRICING_MODEL } from 'app/config/quotation';
import { ECurrency } from 'app/store/modules/global/types';
import { ITermsheet } from 'app/store/modules/termsheets/types';
import { emptyCheck, sg } from 'app/utils/safe-get';
import { uuid } from 'app/utils/uuid';
import { formatDateString } from 'app/utils/format-datestring';

import Base from './base';
import SupplyUnitPrice from './supply-price-unit';

class Termsheet extends Base {
  public currency: ECurrency;
  public netLicenseFee: string;
  public grossLicenseFee: string;
  public countriesOfInterest: any[];
  public licenseFeeCommissionPct: number;
  public sharedDate?: Date;
  public netRevenueSharePct: string;
  public grossRevenueSharePct: string;
  public combinedRevenueSharePct: number;

  public supplyUnitPrices: SupplyUnitPrice[];
  public pricingModel?: number;
  public buyerUserId: number;
  public sellerUserId: number;
  public createdAt: string;
  public incoterm: string;
  public deliveryDestination: string;

  constructor(termsheet: ITermsheet) {
    super(termsheet);

    this.currency = termsheet.currency;
    this.netLicenseFee = sg(
      () =>
        termsheet.net_license_fee.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      ''
    );
    this.grossLicenseFee = sg(
      () =>
        termsheet.gross_license_fee.toLocaleString('en-US', {
          maximumFractionDigits: 2,
        }),
      ''
    );
    // TODO: no longer used. this is taken from related request information
    this.countriesOfInterest = sg(
      () => termsheet.target_markets.split(','),
      []
    );
    this.licenseFeeCommissionPct = termsheet.license_fee_commision_pct;
    this.sharedDate = sg(() => termsheet.shared_date, undefined);

    this.pricingModel = termsheet.pricing_model;
    this.netRevenueSharePct =
      termsheet.net_selling_price_revenue_share_pct !== null
        ? `${termsheet.net_selling_price_revenue_share_pct}`
        : '';
    this.grossRevenueSharePct =
      termsheet.gross_selling_price_revenue_share_pct !== null
        ? `${termsheet.gross_selling_price_revenue_share_pct}`
        : '';
    this.buyerUserId = termsheet.buyer_user_id;
    this.sellerUserId = termsheet.seller_user_id;
    this.createdAt = termsheet.created_at
      ? formatDateString(termsheet.created_at)
      : '';

    this.incoterm = termsheet.incoterm;
    this.deliveryDestination = termsheet.delivery_destination;
    this.combinedRevenueSharePct =
      (termsheet.net_selling_price_revenue_share_pct !== null
        ? termsheet.net_selling_price_revenue_share_pct
        : 0) +
      (termsheet.gross_selling_price_revenue_share_pct !== null
        ? termsheet.gross_selling_price_revenue_share_pct
        : 0);
    // TODO
    // in quotation message text termsheet has supply_price_units property
    // in latest quotation data termsheet has SupplyUnitPrices
    // fix this
    // eslint-disable-next-line
    if (termsheet.hasOwnProperty('supply_unit_prices')) {
      this.supplyUnitPrices = sg(
        () =>
          termsheet.supply_unit_prices!.map(
            (supplyUnitPrice) => new SupplyUnitPrice(supplyUnitPrice)
          ),
        []
      );
      // eslint-disable-next-line
    } else if (termsheet.hasOwnProperty('SupplyUnitPrices')) {
      this.supplyUnitPrices = sg(
        () =>
          termsheet.SupplyUnitPrices!.map(
            (supplyUnitPrice) => new SupplyUnitPrice(supplyUnitPrice)
          ),
        []
      );
    } else {
      this.supplyUnitPrices = [];
    }
  }

  public hasLicenseFees() {
    return emptyCheck(this.netLicenseFee) && emptyCheck(this.grossLicenseFee);
  }

  public hasSupplyFees() {
    return this.supplyUnitPrices.length > 0;
  }

  public isFixedPricing() {
    return this.pricingModel === PRICING_MODEL.FIXED.value;
  }

  public hasIncoterm() {
    return this.incoterm !== null;
  }

  public getFormData(formData: IQuotationFormData) {
    const supplyUnitPrices = {};
    let key;
    this.supplyUnitPrices.forEach((supplyUnitPrice) => {
      key = uuid();
      supplyUnitPrices[key] = {
        id: supplyUnitPrice.id,
        key,
        strength: supplyUnitPrice.strength,
        unitText: supplyUnitPrice.unitText,
        netSupplyPrice: supplyUnitPrice.netPrice,
        grossSupplyPrice: supplyUnitPrice.grossPrice,
        supplyPriceCommissionPct: supplyUnitPrice.commission,
      };
    });

    const updatedFormData = {
      currency: this.currency,

      netLicenseFee: this.netLicenseFee,
      grossLicenseFee: this.grossLicenseFee,
      licenseFeeCommission: this.licenseFeeCommissionPct,

      pricingModel: this.pricingModel,
      netSellingPriceRevenueSharePct: this.netRevenueSharePct,
      grossSellingPriceRevenueSharePct: this.grossRevenueSharePct,

      sharedDate: this.sharedDate,
      incoterm: this.incoterm,
      deliveryDestination: this.deliveryDestination,
      supplyUnitPrices: {
        ...supplyUnitPrices,
        ...formData.supplyUnitPrices,
      },
    };

    return updatedFormData;
  }
}

export default Termsheet;
