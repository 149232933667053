import * as saga from '@redux-saga/core/effects';
import { IStore } from 'app/store/types';
import { isBrowser } from 'app/store/utils/is-browser';
import sg from 'app/utils/safe-get';
import { logger } from '../../../utils/logger';
import * as QueryDeals from '../requests/query';
import * as A from './actions';
import * as Query from './query';
import { IDeal, MyDealFilterEnum } from './types';

function* watchFetchDeal() {
  if (!isBrowser()) {
    return;
  }
  yield saga.takeLatest(A.fetchRequests, fetchDeal);
}

function* fetchDeal({ payload }: ReturnType<typeof A.fetchRequests>) {
  try {
    yield saga.put(A.isFetching(true));
    yield saga.put(A.hasFetched(false));

    const {
      auth: { loggedInUser },
      deals: { currentPage, pageSize, filter, filterText },
    }: IStore = yield saga.select();

    if (loggedInUser.id) {
      const params: Params<typeof Query.getDealQuery> = {
        client: payload.client,
        limit: pageSize,
        offset: currentPage * pageSize,
        userId: loggedInUser.id,
        isAdmin: loggedInUser.isAdmin,
        isBroker: loggedInUser.isDefaultBroker,
        isBuyer: loggedInUser.isBuyer,
        isSeller: loggedInUser.isSeller,
        filter,
        searchText: payload.searchText !== '' ? payload.searchText : filterText,
      };

      const res: Unpromisefy<
        ReturnType<typeof Query.getDealQuery>
      > = yield saga.call(Query.getDealQuery, params);

      const deals = sg(
        () =>
          res!.data.requests.map(
            (request: { countriesOfInterest: string }) => ({
              ...request,
              countriesOfInterest: request.countriesOfInterest
                ? request.countriesOfInterest.split(',')
                : [],
            })
          ),
        []
      );

      const sagaArray = [];
      for (const deal of deals) {
        const reqParams: Params<typeof Query.getDealByIdQuery> = {
          client: payload.client,
          isAdmin: loggedInUser.isAdmin,
          id: deal.id,
        };

        sagaArray.push(saga.call(Query.getDealByIdQuery, reqParams));
      }
      const dealsArray = yield saga.all(sagaArray);

      const usersOwn = filter.myRequests === MyDealFilterEnum.me;

      const caseCount = usersOwn
        ? sg(() => res!.data.ownDealsCount.total.count, 0)
        : sg(() => res!.data.allDealsCount.total.count, 0);

      yield saga.call(fetchDealSuccess as any, dealsArray, caseCount);
    }
  } catch (e) {
    const state: IStore = yield saga.select();
    yield saga.put(
      A.setNotification({
        ...state.requests.error,
        listError: 'There was an error loading your v2 requests.',
      })
    );
  }
}

function* fetchDealSuccess(deals: IDeal[], caseCount: number) {
  yield saga.all([
    saga.put(A.setDeals(deals)),
    saga.put(A.setTotalCount(caseCount)),
    saga.put(A.isFetching(false)),
    saga.put(A.hasFetched(true)),
  ]);
}

function* updateDealCommentsFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.updateDealComments> = yield saga.take(
      A.updateDealComments
    );

    const params: A.IUpdateDealComments = {
      client: action.payload.client,
      id: action.payload.id,
      comments: action.payload.comments,
    };

    yield saga.call(updateDealComments, params);
  }
}
function* updateDealComments(params: A.IUpdateDealComments) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateDealCommentsQuery>
    > = yield saga.call(Query.updateDealCommentsQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.update_deals.returning[0].id,
      comments: res.data!.update_deals.returning[0].comments,
    };
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.deals.requests,
      id: request.id,
      comments: request.comments,
    };
    yield saga.put(A.setDeals(dealsDictionary));
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> updateComments',
      e
    );
  }
}
function* updateDealTargetSupplyPriceFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.updateDealTargetSupplyPrice
    > = yield saga.take(A.updateDealTargetSupplyPrice);

    const params: A.IUpdateDealTargetSupplyPrice = {
      client: action.payload.client,
      id: action.payload.id,
      targetSupplyPrice: action.payload.targetSupplyPrice,
    };

    yield saga.call(updateDealTargetSupplyPrice, params);
  }
}
function* updateDealTargetSupplyPrice(params: A.IUpdateDealTargetSupplyPrice) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateDealSupplyPriceQuery>
    > = yield saga.call(Query.updateDealSupplyPriceQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.update_deals.returning[0].id,
      targetSupplyPrice: res.data!.update_deals.returning[0]
        .target_supply_price,
    };
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.deals.requests,
      id: request.id,
      targetSupplyPrice: request.targetSupplyPrice,
    };
    yield saga.put(A.setDeals(dealsDictionary));
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> updateTargetSupplyPrice',
      e
    );
  }
}
function* updateDealQuantityEstimationPriceFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.updateDealQuantityEstimation
    > = yield saga.take(A.updateDealQuantityEstimation);

    const params: A.IUpdateDealQuantityEstimation = {
      client: action.payload.client,
      id: action.payload.id,
      quantityEstimation: action.payload.quantityEstimation,
    };

    yield saga.call(updateDealQuantityEstimation, params);
  }
}
function* updateDealQuantityEstimation(
  params: A.IUpdateDealQuantityEstimation
) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateDealQuantityEstimationQuery>
    > = yield saga.call(Query.updateDealQuantityEstimationQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.update_deals.returning[0].id,
      quantityEstimation: res.data!.update_deals.returning[0]
        .estimated_annual_quantity,
    };
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.deals.requests,
      id: request.id,
      estimatedAnnualQuantity: request.quantityEstimation,
    };
    yield saga.put(A.setDeals(dealsDictionary));
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> updateQuantityEstimation',
      e
    );
  }
}
function* updateDealClosingEstimationPriceFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.updateDealClosingEstimation
    > = yield saga.take(A.updateDealClosingEstimation);

    const params: A.IUpdateDealClosingEstimation = {
      client: action.payload.client,
      id: action.payload.id,
      closingEstimation: action.payload.closingEstimation,
    };

    yield saga.call(updateDealClosingEstimation, params);
  }
}
function* updateDealClosingEstimation(params: A.IUpdateDealClosingEstimation) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateDealClosingEstimationQuery>
    > = yield saga.call(Query.updateDealClosingEstimationQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.update_deals.returning[0].id,
      closingEstimation: res.data!.update_deals.returning[0]
        .expect_sign_contract,
    };
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.deals.requests,
      id: request.id,
      closingEstimation: request.closingEstimation,
    };
    yield saga.put(A.setDeals(dealsDictionary));
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> updateClosingEstimation',
      e
    );
  }
}
function* updateDealTypeFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.updateDealType> = yield saga.take(
      A.updateDealType
    );

    const params: A.IUpdateDealType = {
      client: action.payload.client,
      id: action.payload.id,
      dealType: action.payload.dealType,
    };
    yield saga.call(updateDealType, params);
  }
}
function* updateDealType(params: A.IUpdateDealType) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateDealTypeQuery>
    > = yield saga.call(Query.updateDealTypeQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.update_deals.returning[0].id,
      dealType: res.data!.update_deals.returning[0].deal_type,
    };
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.deals.requests,
      id: request.id,
      dealType: request.dealType,
    };
    yield saga.put(A.setDeals(dealsDictionary));
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> updateDealType',
      e
    );
  }
}

function* setBrokerFlow(): any {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.setDealBroker> = yield saga.take(
      A.setDealBroker
    );

    const params: A.ISetDealBrokerType = {
      client: action.payload.client,
      id: action.payload.id,
      broker_id: action.payload.broker_id,
    };
    yield saga.call(setBroker, params);
  }
}
function* setBroker(params: A.ISetDealBrokerType): any {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.setDealBrokerQuery>
    > = yield saga.call(Query.setDealBrokerQuery, params);
    const params2 = {
      brokerId: params.broker_id,
      client: params.client,
      dealId: params.id,
    };
    yield saga.call(QueryDeals.setBrokersByDealDealQuery, params2);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
  } catch (e) {
    logger.error('src/app/store/modules/requests/sagas.ts -> setBroker', e);
  }
}
function* setDealActiveStatusFlow(): any {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.setRequestActiveStatus> = yield saga.take(
      A.setRequestActiveStatus
    );

    const params: A.ISetDealActiveStatusType = {
      client: action.payload.client,
      deal_id: action.payload.deal_id,
      is_active: action.payload.is_active,
    };
    yield saga.call(setRequestActiveStatus, params);
  }
}
function* setRequestActiveStatus(params: A.ISetDealActiveStatusType): any {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.setDealActiveStatusQuery>
    > = yield saga.call(Query.setDealActiveStatusQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> setRequestActiveStatus',
      e
    );
  }
}
function* addToDealFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.addToRequestFromPotentialSuppliers
    > = yield saga.take(A.addToRequestFromPotentialSuppliers);

    const { auth }: IStore = yield saga.select();
    const dealsGroup = sg(() => action.payload.values.dealsGroup, false)
      ? action.payload.values.dealsGroup
      : `${new Date().toISOString()}_${sg(() => auth.loggedInUser.id, 0)}_${sg(
          () => action.payload.values.product!.id,
          null
        )}_${sg(() => action.payload.values.productTrade!.id, null)}`;

    const params: Params<typeof addToDeal> = {
      client: action.payload.client,
      values: { ...action.payload.values, dealsGroup },
      currentUserId: sg(() => auth.loggedInUser.id, 0),
      currentUserCompanyId: sg(() => auth.loggedInUser.Company.id, 0),
      isAdmin: auth.loggedInUser.isAdmin,
    };
    if (auth.loggedIn) {
      yield saga.call(addToDeal, params);
    }
  }
}
function* addToDeal(params: Params<typeof Query.addToDealQuery>) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.addToDealQuery>
    > = yield saga.call(Query.addToDealQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.insert_requests.returning[0].id,
      Buyer: res.data!.insert_requests.returning[0].Buyer,
    };
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.deals.requests,
      id: request.id,
      Buyer: request.Buyer,
    };
    yield saga.put(A.setDeals(dealsDictionary));
  } catch (e) {
    throw e;
  }
}
export default function* rootSaga() {
  yield saga.all([
    saga.fork(watchFetchDeal),
    saga.fork(updateDealCommentsFlow),
    saga.fork(updateDealTargetSupplyPriceFlow),
    saga.fork(updateDealQuantityEstimationPriceFlow),
    saga.fork(updateDealClosingEstimationPriceFlow),
    saga.fork(updateDealTypeFlow),
    saga.fork(setBrokerFlow),
    saga.fork(addToDealFlow),
    saga.fork(setDealActiveStatusFlow),
  ]);
}
