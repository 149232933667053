import { isEmpty, isNull, isUndefined } from 'lodash';

// custom imports
import { allCountries as countries } from 'app/store/modules/global/types';

export const getCountryName = (countryCode: string) => {
  if (isUndefined(countryCode) || isNull(countryCode) || isEmpty(countryCode)) {
    return '';
  } else {
    const filtered = countries.filter((country) => country.id === countryCode);

    return filtered.length > 0 ? filtered[0].name : countryCode;
  }
};
