import { User } from 'app/store/modules/auth/types';

export const needMoreInfo = (loggedInUser: User): boolean => {
  const { firstName, lastName, Company, email } = loggedInUser;
  return !(
    firstName &&
    firstName !== '' &&
    lastName &&
    lastName !== '' &&
    Company &&
    Company.name &&
    Company.name !== '' &&
    Company.name !== email &&
    Company.name !== `${email} default company`
  );
};
