import styled from 'styled-components';

// custom imports
import { Components as ArchiveButtonComponents } from 'app/components/buttons/archive-gray-button';
import { Components as MessageButtonComponents } from 'app/components/buttons/message-blue-button';
import {
  SmallTableItem as MainTableStyle,
  TdStyles,
  ThStyles as MainThStyle,
} from 'app/components/table';

export const ActivityButtonContainer = styled.div`
  display: flex;
  width: fit-content;
  flex-wrap: wrap;

  ${ArchiveButtonComponents.ArchiveItemContainer},
  ${MessageButtonComponents.MessageButtonContainer} {
    height: 34px;
    padding: 7px 10px;
    max-width: unset;
  }
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  word-wrap: break-word;
`;

export const SubLabel = styled.label`
  line-height: 22px;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.64;
  letter-spacing: 0.2px;
  color: ${(props) => props.theme.colors.grayText};
  cursor: pointer;
  width: 100%;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grayExtraDark};
  cursor: pointer;
`;

export const ButtonDivider = styled.div`
  width: 4px;
`;

export const ThStyles = styled(MainThStyle)`
  min-width: 60px;
`;

export const NewTdStyle = styled(TdStyles)`
  padding: 0;
  display: flex;
  align-items: center;
`;

interface IStatusBackground {
  color: string;
}

export const SmallTableItem = styled(MainTableStyle)<IStatusBackground>`
  background: ${(props) => props.color};
  border-top-width: 0px;
  border-bottom-width: 1px;

  .flag-container {
    display: grid;
    grid-template-columns: 12px 12px 12px 1fr;
    align-items: center;
    justify-content: center;
    grid-column-gap: 5px;
  }
  .buyer-info {
    display: inline;
    margin-right: 7px;
  }
`;

export const ViewLinkedDeal = styled.a`
  width: 10px;
  height: 10px;
`;
