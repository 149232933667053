import { ReactGA } from 'client/tracker';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

import { anonymousUserId, routes } from 'app/config';
import { AnchorLink } from 'app/components/links';
import { useSelector } from 'app/helpers/redux';
import isBrowser from 'app/store/utils/is-browser';

const MarketplaceButtonStyle = styled.div<{ buttonWidth?: number }>`
  ${(props) =>
    props.buttonWidth ? ` width:${props.buttonWidth}px;` : 'width: 220px;'}
  height: 50px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.warning};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: center;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
  ${media.max.sm`
  width: 100%;
  `}
`;

const Wrapper = styled.div<{ widthFull?: boolean; paddingTop?: number }>`
  display: flex;
  justify-content: center;
  ${media.max.sm`
  width: 100%;`}
  ${(props) => props.widthFull && ' & > a {width: 100%;}'}
  ${(props) => props.paddingTop && ` padding-top:${props.paddingTop}px;`}
`;

interface Props {
  category?: string;
  action?: string;
  fullWidth?: boolean;
  customLink?: string;
  clearFilter?: () => any;
  text?: string;
  paddingTop?: number;
  buttonWidth?: number;
}

const MarketplaceButton: React.FC<Props> = ({
  category,
  action,
  fullWidth,
  customLink,
  clearFilter,
  text,
  paddingTop,
  buttonWidth,
}) => {
  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  const anonymousUserIdItem =
    isBrowser() && localStorage.getItem(anonymousUserId);
  return (
    <Wrapper widthFull={fullWidth} paddingTop={paddingTop}>
      <AnchorLink
        href={customLink ? customLink : routes.marketplace}
        onClick={() => {
          if (!customLink && clearFilter) {
            clearFilter();
          }
        }}
      >
        <MarketplaceButtonStyle
          buttonWidth={buttonWidth}
          onClick={() => {
            if (category && action) {
              ReactGA.event({
                category,
                action,
                email,
                anonymousUserId: anonymousUserIdItem,
              });
            }
          }}
        >
          {text || 'Products for sale'}
        </MarketplaceButtonStyle>
      </AnchorLink>
    </Wrapper>
  );
};

export { MarketplaceButtonStyle };
export default MarketplaceButton;
