/* eslint-disable no-console */

import { formatDate } from 'app/utils/format-datestring';

const logger = {
  log,
  err: error,
  error,
  group,
  groupEnd: endGroup,
};

function log(...values: any[]) {
  console.log(`${formatDate(new Date(), 'HH:mm:ss.SSS')}`, ...values);
}
function error(...values: any[]) {
  console.error(`${formatDate(new Date(), 'HH:mm:ss.SSS')}`, ...values);
}
function group(...title: any[]) {
  console.group(...title);
}
function endGroup(callback: void) {
  console.groupEnd();
}

export { logger };
export default logger;
