import AsyncSelect from 'app/components/inputs/async-select';
import { Modal } from 'app/components/modals/modal';
import { useDispatch, useSelector } from 'app/helpers/redux';
import { getRequestForLinkSelect } from 'app/store/modules/deal/query';
import { IDeal } from 'app/store/modules/deal/types';
import {
  setRequestLink,
  setLinkSellerRequestToRequestModalOpen,
} from 'app/store/modules/requests/actions';
import { IRequest } from 'app/store/modules/requests/types';
import { toTitleCase } from 'app/store/modules/global/actions';
import { getCountryName } from 'app/utils/get-country-name';
import React, { useState } from 'react';
import { useApolloClient } from 'react-apollo';
import Flag from 'react-world-flags';
import styled from 'styled-components';
interface LinkSellerOptions {
  value: number;
  label: any;
  dealItem?: IDeal;
}

const getDealSubstances = (
  request?: IRequest
): {
  substance1: string;
  substance2: string;
  substance3: string;
} => {
  if (request && request.Product) {
    return {
      substance1: request.Product.Substance1.name,
      substance2: request.Product.Substance2
        ? request.Product.Substance2.name
        : '',
      substance3: request.Product.Substance3
        ? request.Product.Substance3.name
        : '',
    };
  }
  return {
    substance1: '',
    substance2: '',
    substance3: '',
  };
};

export const getDealNameDisplay = (dealItem: IDeal, request?: IRequest) => {
  const substance1 = dealItem.Substance1.name;
  const substance2 = dealItem.Substance2
    ? ' + ' + dealItem.Substance2.name
    : '';
  const substance3 = dealItem.Substance3
    ? ' + ' + dealItem.Substance3.name
    : '';

  const dealSubstances = getDealSubstances(request);

  const substanceOfRequest1 = dealSubstances.substance1;
  const substanceOfRequest2 =
    dealSubstances.substance2 !== '' ? ' + ' + dealSubstances.substance2 : '';
  const substanceOfRequest3 =
    dealSubstances.substance3 !== '' ? ' + ' + dealSubstances.substance3 : '';

  const dealItemString = toTitleCase(substance1 + substance2 + substance3);
  const dealITemString = toTitleCase(
    substanceOfRequest1 + substanceOfRequest2 + substanceOfRequest3
  );

  const countrieList =
    dealItem && dealItem.countriesOfInterest
      ? (dealItem.countriesOfInterest as any).split(',')
      : [];
  const countries = countrieList.map((countryCode: string) => {
    return (
      <SingleCountryContainer key={countryCode}>
        <Flag code={countryCode} style={{ height: 12 }} />
        <SingleCountryText>
          {countryCode && countryCode.length > 0
            ? getCountryName(countryCode)
            : ''}
        </SingleCountryText>
      </SingleCountryContainer>
    );
  });
  const buyer = dealItem.Buyer.fullName + ', ' + dealItem.Buyer.Company.name;

  return (
    <LabelContainer
      style={
        dealItemString === dealITemString ? { fontWeight: 'bold' } : undefined
      }
    >
      {dealItemString} &nbsp; for {countries} ({buyer}){' '}
    </LabelContainer>
  );
};

export const getSearchTextFromDeal = (dealItem: IDeal) => {
  const substance1 = dealItem.Substance1.name;
  const substance2 = dealItem.Substance2
    ? ' + ' + dealItem.Substance2.name
    : '';
  const substance3 = dealItem.Substance3
    ? ' + ' + dealItem.Substance3.name
    : '';
  const countrieList =
    dealItem && dealItem.countriesOfInterest
      ? (dealItem.countriesOfInterest as any).split(',')
      : [];
  const countries = countrieList.map((countryCode: string) => {
    return countryCode && countryCode.length > 0
      ? getCountryName(countryCode)
      : '';
  });

  const buyer = dealItem.Buyer.fullName + ', ' + dealItem.Buyer.Company.name;
  return `${toTitleCase(
    substance1 + substance2 + substance3
  )} for ${countries} (
    ${buyer})`;
};

const LinkSellerRequestDealModal: React.SFC = () => {
  const modalOpen = useSelector(
    (state) => state.requests.linkSellerRequestModalOpen
  );

  const request = useSelector(
    (state) =>
      state.requests.dealsCache[state.requests.linkSellerRequestToDealId]
  );

  const selectedId = request && request.Deal ? request.Deal.id : undefined;

  const buyerId = request && request.Buyer ? request.Buyer.id : undefined;

  const [options, setOptions] = useState<LinkSellerOptions[]>([]);

  const client = useApolloClient();
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setLinkSellerRequestToRequestModalOpen(false));
  };

  const mapToLinkSelect = (caseList: IDeal[]): LinkSellerOptions[] => {
    return caseList.map((dealItem) => {
      return {
        value: dealItem.id,
        label: getDealNameDisplay(dealItem, request),
        request: dealItem,
      };
    });
  };

  const fetchBuyerRequests = () =>
    getRequestForLinkSelect({ client, buyerId }).then((r) => {
      if (r) {
        setOptions(mapToLinkSelect(r));

        return [
          {
            value: undefined,
            label: '--Nepasirinktas--',
            request: undefined,
          },
          ...mapToLinkSelect(r),
        ];
      } else {
        return [
          {
            value: undefined,
            label: '--Nepasirinktas--',
            request: undefined,
          },
        ];
      }
    });
  const selectedValue = options.filter(
    (x) => selectedId && x.value.toString() === selectedId.toString()
  )[0]
    ? options.filter((x) => x.value === selectedId)[0]
    : {
        value: undefined,
        label: '--Nepasirinktas--',
        request: undefined,
      };
  return (
    <Modal
      isOpen={modalOpen}
      onClose={closeModal}
      className="request-v2-modal"
      header={<p className="header">Request change</p>}
    >
      <AsyncSelect
        loadOptions={fetchBuyerRequests}
        label={'Request'}
        filterOption={(item: any, search: string) => {
          const filterString = item.data.request
            ? getSearchTextFromDeal(item.data.request)
            : '';
          return filterString
            .toLowerCase()
            .includes(search ? search.toLowerCase() : '')
            ? true
            : false;
        }}
        onChange={(val: LinkSellerOptions) => {
          dispatch(
            setRequestLink({
              client,
              dealId: val.value,
              id: request.id,
              dealItem: val.dealItem,
            })
          );
          closeModal();
        }}
        value={selectedValue as any}
        placeholder={'Start typing for suggestions'}
      />
    </Modal>
  );
};

export default LinkSellerRequestDealModal;

const SingleCountryContainer = styled.span`
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
`;

const SingleCountryText = styled.span`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;
const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;
