import fetch from 'node-fetch';
import wretch from 'wretch';

wretch().polyfills({
  fetch,
});

const request = wretch;

export default request;
