export const PRICING_MODEL = {
  SHARED: {
    value: 0,
    label: 'Price sharing',
    tooltip: 'Price offer depending on the customer’s selling price',
  },
  FIXED: {
    value: 1,
    label: 'Fixed price',
    tooltip: 'Supply prices are independent of the selling price',
  },
};
