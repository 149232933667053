import { RefObject } from 'react';
/**
 * Defines constraint to check if input is valid.
 * If rule returns false, that means constraints is not met and error should be shown.
 */
interface IRule<T> {
  rule: (value?: T) => boolean;
  errorMessage: string;
}

function _getError<T>(value: T, rule?: IRule<T>): string | undefined {
  if (!rule) {
    return undefined;
  }

  if (!rule.rule(value)) {
    return rule.errorMessage;
  }
  return undefined;
}
function getError<T>(value: T, rules?: IRule<T>[]): string | undefined {
  if (!rules) {
    return undefined;
  }

  for (const rule of rules) {
    const error = _getError(value, rule);
    if (error) {
      return error;
    }
  }

  return undefined;
}
function checkForError<T>(value: T, rules?: IRule<T>[]): boolean {
  return getError(value, rules) === undefined;
}

export { getError, checkForError, IRule };

type MappedFields<T> = { [K in keyof T]: RefObject<T[K]> };
interface IWrappedComponent<T> {
  getValue: () => T | undefined;
  isValid: (forced?: boolean) => boolean;
  setValue?: (value: T) => void;
}
function isIWrappedComponent<T = any>(arg: any): arg is IWrappedComponent<T> {
  return typeof arg === 'object' && 'getValue' in arg && 'isValid' in arg;
}

export { MappedFields, IWrappedComponent, isIWrappedComponent };

type State<T> = {
  [K in keyof T]: T[K] extends RefObject<any>
    ? NonNullable<T[K]['current']> extends IWrappedComponent<any>
      ? ReturnType<NonNullable<T[K]['current']>['getValue']>
      : undefined
    : undefined;
};

function getState<T>(references: T): State<T> {
  const keys = Object.keys(references);
  const state = {} as State<T>;

  for (const key of keys) {
    const reference: RefObject<any> = references[key];
    if (reference.current && isIWrappedComponent(reference.current)) {
      state[key] = reference.current.getValue();
      continue;
    }
    state[key] = undefined;
  }

  return state;
}
function validate<T>(references: T): boolean {
  const keys = Object.keys(references);

  for (const key of keys) {
    const reference: RefObject<any> = references[key];
    if (reference.current && isIWrappedComponent(reference.current)) {
      if (!reference.current.isValid(true)) {
        return false;
      }
      continue;
    }
  }

  return true;
}

export { getState, State, validate };
