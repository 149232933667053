import { IWithApolloClient } from 'app/store/modules/global/types';
import * as u from 'app/store/modules/query-utils';
import logger from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { dealMessageSchema, requestSchema } from '../../query-utils/schema';
import { EEditFields } from '../types';
import { IAcceptMessage, IRejectMessage } from './actions';
import {
  dealMessageFragment as messagesFragment,
  IDealMessageFragment as IMessagesFragment,
} from './fragments/deal-message';
interface IGetMessages extends IWithApolloClient {
  offset: number;
  limit: number;
  requestId: number;
  party: 'buyer' | 'seller' | 'admin';
}
export async function getMessages(params: IGetMessages) {
  const showEmails = params.showEmails
    ? ''
    : u.DealMessagesUtils._neq('sender_id', 0);

  try {
    return await params.client
      .query({
        query: gql`
          query getGroups(${u.params(
            ['$id', 'Int'],
            ['$limit', 'Int'],
            ['$offset', 'Int']
          )}) {
            you: deals_messages(
              ${u.where(
                u._and(
                  u._eq('request_id', '$id'),
                  showEmails,
                  params.party === 'buyer' && u._eq('buyer_see', true),
                  params.party === 'seller' && u._eq('seller_see', true)
                )
              )}
              ${u.DealMessagesUtils.orderBy('group_id', 'desc')}
              ${u.limit('$limit')}
              ${u.offset('$offset')}
              ${u.DealMessagesUtils.distinct('group_id')}
            ) {
              ${u.DealMessagesUtils.selectParams([
                'group_id',
                'aint_suppose_to_see_this',
              ])}
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id: params.requestId,
          limit: params.limit,
          offset: params.offset,
        },
      })
      .then((groups: { data: { you: any[] } }) =>
        params.client.query({
          query: gql`
            ${messagesFragment.fragment(params.party === 'admin')}
            query getMessages(${u.params(['$id', 'Int'])}) {
              messages: deals_messages(
                ${u.where(
                  u._and(
                    u.DealMessagesUtils._in(
                      'group_id',
                      groups.data.you.map((you) => you.aint_suppose_to_see_this)
                    ),
                    u.DealMessagesUtils._eq('request_id', '$id'),
                    showEmails
                  )
                )}
                ${u.orderBy('id', 'desc')}
              ) {
                ${u.DealMessagesUtils.selectParams(messagesFragment)}
              }
              agr: deals_messages_aggregate(
                ${u.where(
                  u._and(
                    u._eq('request_id', '$id'),
                    showEmails,
                    params.party === 'buyer' && u._eq('buyer_see', true),
                    params.party === 'seller' && u._eq('seller_see', true)
                  )
                )}
                ${u.DealMessagesUtils.distinct('group_id')}
              ) {
                a: aggregate { count }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            id: params.requestId,
          },
        })
      );
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/query.ts -> getMessages',
      e
    );
  }
}

export async function acceptMessage(params: IAcceptMessage) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await params.client
      .mutate<{
        message: {
          returning: {
            id: number;
            requestId: number;
            sender: number;
            seller: number;
            buyer: number;
          }[];
        };
      }>({
        mutation: gql`
          ${new u.Mutation('acceptMessage', { isAdmin: true, isLogged: true })
            .params(['$id', 'Int'], ['$group', 'Int'])
            .update(dealMessageSchema, 'message')
            .where((t) =>
              t._and(t._eq('group_id', '$group'), t._eq('request_id', '$id'))
            )
            .set(['buyer_see', true], ['seller_see', true])
            .returning(
              'id',
              ['request_id', 'requestId'],
              ['sender_company_id', 'sender'],
              ['seller_company_id', 'seller'],
              ['buyer_company_id', 'buyer']
            )
            .toString()
            .update(dealMessageSchema, 'msg')
            .where((t) =>
              t._and(
                t._eq('request_id', '$id'),
                t._or(
                  t._eq('field_id', EEditFields.ProductRequestInformation),
                  t._eq('field_id', EEditFields.ProductRequestSuccessfull)
                )
              )
            )
            .set(['seller_see', true])
            .returning('id')
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id: params.requestId,
          group: params.id,
        },
      })
      .then(async (res: any) => {
        const request = res.data!.message.returning[0];
        const isBuyer = request.sender === request.buyer;

        if (!request || !request.requestId) {
          return res;
        }

        await params.client.mutate({
          mutation: gql`
            ${new u.Mutation('setSeenOnAccept', {
              isAdmin: true,
              isLogged: true,
            })
              .params(['$id', 'Int'], ['$date', 'timestamptz'])
              .update(requestSchema, 'request')
              .where((t) => t._eq('id', '$id'))
              .set(
                isBuyer ? ['seller_seen', false] : ['buyer_seen', false],
                ['admin_approved', true],
                ['updated_at', '$date'],
                isBuyer
                  ? ['seller_updated_at', '$date']
                  : ['buyer_updated_at', '$date']
              )
              .returning('id')
              .toString()
              .toString()}
          `,
          fetchPolicy: 'no-cache',
          variables: {
            id: request.requestId,
            date: new Date().toISOString(),
          },
        });
        return res;
      });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/query.ts -> acceptMessage',
      e
    );
  }
}

export async function rejectMessage(params: IRejectMessage) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await params.client
      .mutate<{
        message: {
          returning: {
            id: number;
            requestId: number;
            rejectText: string;
            sender: number;
            seller: number;
            buyer: number;
          }[];
        };
      }>({
        mutation: gql`
          mutation rejectMessage(${u.params(
            ['$requestId', 'Int'],
            ['$group', 'Int'],
            ['$reason', 'String']
          )}) {
            message: update_deals_messages(
              ${u.where(
                u._and(
                  u.DealMessagesUtils._eq('group_id', '$group'),
                  u.DealMessagesUtils._eq('request_id', '$requestId')
                )
              )}
              ${u.DealMessagesUtils._set(['admin_reply_text', '$reason'])}
            ) {
              returning {
                ${u.DealMessagesUtils.selectParams(
                  'id',
                  ['request_id', 'requestId'],
                  ['admin_reply_text', 'rejectText'],
                  ['sender_company_id', 'sender'],
                  ['seller_company_id', 'seller'],
                  ['buyer_company_id', 'buyer']
                )}
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          group: params.id,
          requestId: params.requestId,
          reason: params.reason,
        },
      })
      .then(async (res: any) => {
        const request = res.data!.message.returning[0];
        const isBuyer = request.sender === request.buyer;

        if (!request || !request.requestId) {
          return res;
        }

        await params.client.mutate({
          mutation: gql`
            mutation setSeenOnReject(
              ${u.params(['$id', 'Int'], ['$date', 'timestamptz'])}
            ) {
              update_requests(
                ${u.where(u.RequestUtils._eq('id', '$id'))}
                ${u.RequestUtils._set(
                  isBuyer && ['buyer_seen', false],
                  !isBuyer && ['seller_seen', false],
                  ['updated_at', '$date'],
                  isBuyer
                    ? ['buyer_updated_at', '$date']
                    : ['seller_updated_at', '$date']
                )}
              ) {
                returning { ${u.RequestUtils.selectParams('id')} }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            id: request.requestId,
            date: new Date().toISOString(),
          },
        });
        return res;
      });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/query.ts -> rejectMessage',
      e
    );
  }
}

export interface ISendMessage extends IWithApolloClient {
  requestId: number;
  text: string;
  senderId: number;
  companyId: number;
  fromAdmin?: boolean;
  buyerSee?: boolean;
  sellerSee?: boolean;
  isAdmin?: boolean;
  termsheetId?: number | null;
}
export async function sendMessage(params: ISendMessage) {
  try {
    if (!sg(() => params.requestId, false)) {
      throw new Error('Request is not supplied in message send query');
    }
    return await getLastMessageGroup({
      client: params.client,
      id: params.requestId,
    }).then((request) => {
      const adminSeen = params.fromAdmin || false;
      const buyerSeen = params.companyId === request.buyerCompany || false;
      const sellerSeen = params.companyId === request.sellerCompany || false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return params.client.mutate<{ message: { r: IMessagesFragment[] } }>({
        mutation: gql`
          ${messagesFragment.fragment(params.isAdmin)}
          mutation sendMessage(${u.params(
            ['$termsheetId', 'Int'],
            ['$requestId', 'Int'],
            ['$message', 'String'],
            ['$senderId', 'Int'],
            ['$companyId', 'Int'],
            params.fromAdmin && ['$fromAdmin', 'Boolean'],
            ['$buyerSee', 'Boolean'],
            ['$sellerSee', 'Boolean'],
            ['$buyerCompany', 'Int'],
            ['$sellerCompany', 'Int'],
            ['$group', 'Int'],
            ['$date', 'timestamptz']
          )}) {
            message: insert_deals_messages(
              ${u.DealMessagesUtils._objects([
                ['termsheet_id', '$termsheetId'],
                ['request_id', '$requestId'],
                ['text', '$message'],
                ['sender_id', '$senderId'],
                ['sender_company_id', '$companyId'],
                params.fromAdmin && ['is_admin_message', '$fromAdmin'],
                ['buyer_see', '$buyerSee'],
                ['seller_see', '$sellerSee'],
                ['buyer_company_id', '$buyerCompany'],
                ['seller_company_id', '$sellerCompany'],
                ['group_id', '$group'],
                ['created_at', '$date'],
              ])}
            ) {
              r: returning {
                 ${u.DealMessagesUtils.selectParams(messagesFragment)}
              }
            }
            ${
              params.requestId
                ? `
            update_requests(
              ${u.where(u.RequestUtils._eq('id', '$requestId'))}
              ${u.RequestUtils._set(
                ['admin_seen', adminSeen],
                params.buyerSee && ['buyer_seen', buyerSeen],
                params.sellerSee && ['seller_seen', sellerSeen],
                ['updated_at', '$date'],
                params.buyerSee && ['buyer_updated_at', '$date'],
                params.sellerSee && ['seller_updated_at', '$date']
              )}
            ) { returning { ${u.RequestUtils.selectParams('id')} } }
            `
                : ''
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          requestId: params.requestId,
          message: params.text,
          senderId: params.senderId,
          companyId: params.companyId,
          buyerSee: params.buyerSee || false,
          sellerSee: params.sellerSee || false,
          fromAdmin: params.fromAdmin ? true : undefined,
          termsheetId: params.termsheetId,
          buyerCompany: request.buyerCompany,
          sellerCompany: request.sellerCompany,
          group: request.lastGroup + 1,
          date: new Date().toISOString(),
        },
      });
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/query.ts -> sendMessage',
      e
    );
  }
}

interface IGetNextMessageGroup extends IWithApolloClient {
  /** Request id */
  id: number;
}
export async function getLastMessageGroup(p: IGetNextMessageGroup) {
  interface IQuery {
    request: {
      buyerCompanyId: number;
      sellerCompanyId: number;
    }[];
    deala: { a: { max: { group_id: number } } };
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const ret = await p.client.query<IQuery>({
    query: gql`
      ${new u.Query('getLastGroup')
        .params(['$id', 'Int'])
        .query(requestSchema, 'request')
        .where((t) => t._eq('id', '$id'))
        .select(
          ['buyer_company_id', 'buyerCompanyId'],
          ['seller_company_id', 'sellerCompanyId']
        )
        .toString()
        .aggregate(dealMessageSchema, 'deala', 'a')
        .where((t) => t._eq('request_id', '$id'))
        .max('group_id')
        .toString()
        .toString()}
    `,
    fetchPolicy: 'no-cache',
    variables: {
      id: p.id,
    },
  });

  return {
    buyerCompany: ret.data.request[0].buyerCompanyId,
    sellerCompany: ret.data.request[0].sellerCompanyId,
    lastGroup: ret.data.deala.a.max.group_id || 0,
  };
}

export interface IRemoveChange extends IWithApolloClient {
  id: number;
}
export interface IRemoveChangeReturn {
  delete_deals_messages: {
    returning: {
      group: number;
    }[];
  };
}
export async function removeChangeQuery(p: IRemoveChange) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.mutate<IRemoveChangeReturn>({
      mutation: gql`
        mutation removeChange(${u.params(['$id', 'Int'])}) {
          delete_deals_messages(${u.where(
            u.DealMessagesUtils._eq('id', '$id')
          )}) {
            returning { ${u.DealMessagesUtils.selectParams([
              'group_id',
              'group',
            ])} }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: { id: p.id },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/query.ts -> removeChangeQuery',
      e
    );
  }
}

export interface IFetchGroup extends IWithApolloClient {
  group: number;
  requestId: number;
}
export interface IFetchGroupReturn {
  messages: IMessagesFragment[];
}
export async function fetchGroup(p: IFetchGroup) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IFetchGroupReturn>({
      query: gql`
      ${messagesFragment.fragment()}
        query fetchGroup(${u.params(
          ['$group', 'Int'],
          ['$requestId', 'Int']
        )}) {
          messages: deals_messages(${u.where(
            u._and(
              u.DealMessagesUtils._eq('group_id', '$group'),
              u.DealMessagesUtils._eq('request_id', '$requestId')
            )
          )}) {
            ${u.DealMessagesUtils.selectParams(messagesFragment)}
          }
        }
      `,
      variables: {
        group: p.group,
        requestId: p.requestId,
      },
      fetchPolicy: 'no-cache',
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/messages/query.ts -> fetchGroup',
      e
    );
  }
}
