import React from 'react';

export const Info: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <circle cx="6" cy="6" r="6" fill="#BBC1CB" />
      <path
        fill="#FFF"
        d="M6.807 4.62v4.87h-1.65V4.62h1.65zm.138-1.598c0 .124-.026.24-.076.349-.05.108-.12.204-.207.285-.088.082-.19.147-.306.196-.116.048-.24.073-.371.073-.128 0-.248-.025-.359-.073-.111-.049-.21-.114-.295-.196-.086-.081-.154-.177-.202-.285-.05-.109-.074-.225-.074-.349 0-.127.025-.245.074-.356.048-.11.116-.207.202-.29.086-.084.184-.15.295-.196.111-.047.23-.07.359-.07.131 0 .255.023.371.07.116.047.218.112.306.196.087.083.156.18.207.29.05.11.076.23.076.356z"
      />
    </g>
  </svg>
);
