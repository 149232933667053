import { User } from '../auth/types';
import { IRequest } from '../requests/types';
import { IPaginateable } from '../global/types';
import { AnyListItem, Product, Substance } from '../products/types';

export interface State extends IPaginateable {
  isFetching: boolean;
  hasFetched: boolean;
  requests: IDeal[];
  totalCount: number;
  currentPage: number;
  createDealModalOpen: boolean;
  editDealModalOpen: boolean;
  editDealId: number | null;
  filter: DealFilter;
  filterText: string;
}

export interface IDeal {
  id: number;
  Buyer: User;
  Substance1: Substance;
  Substance2?: Substance;
  Substance3?: Substance;
  countriesOfInterest: string[];
  Broker?: User;
  targetSupplyPrice?: string;
  estimatedAnnualQuantity?: string;
  expectSignContract?: string;
  dealType?: number;
  comments?: string;
  doseFormCategory?: string;
  Requests: IRequest[];
  createdAt?: string;
  updatedAt?: string;
  dealPhase?: number;
}

export type MyDealFilter = 'me' | 'everyone';
export type MyDealFilterStatus = 'all' | 'active';
export type DealsPhaseDealFilter = number;

export interface DealFilter {
  myRequests: MyDealFilter;
  phase?: DealsPhaseDealFilter;
  status?: MyDealFilterStatus;
  broker?: number;
}

export enum MyDealFilterEnum {
  'everyone' = 'everyone',
  'me' = 'me',
}
export enum MyDealFilterStatusEnum {
  'all' = 'all',
  'active' = 'active',
}

export const defaultPageSize = 50;

export const defaultStore: State = {
  isFetching: false,
  hasFetched: false,
  requests: [],
  totalCount: 0,
  currentPage: 0,
  pageSize: defaultPageSize,
  createDealModalOpen: false,
  editDealModalOpen: false,
  editDealId: null,
  filter: {
    myRequests: MyDealFilterEnum.everyone,
    status: MyDealFilterStatusEnum.all,
  },
  filterText: '',
};
export interface ICreateProductDeal {
  product?: Product;
  dealType?: number;
  market?: string;
  estimatedAnualQuantity?: string;
  comments?: string;
  dealsGroup?: string;
  leadingPriceLevel?: string;
  isLogedUserRequest?: boolean;
  selectedProducts?: AnyListItem[];
  currentUserCompanyId?: number;
  expectSignContract?: string;
  productId: number;
  createdBy: number;
  sellerUserId: number;
  sellerCompanyId: number;
  buyerUserId: number;
  buyerCompanyId: number;
  dealId: number;
}
