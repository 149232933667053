import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import {
  IProductFragment,
  productFragment,
} from '../../products/fragments/product';
import { CompanyUtils, UserUtils } from '../../query-utils';
import { requestSchema } from '../../query-utils/schema';
import { requestFragment, IRequestFragment } from '../fragments/request';
import { Company } from './get-requests-list';

export interface ISetSeenMutationReturn {
  data: {
    u: {
      returning: {
        id: number;
        seen: Date;
      }[];
    };
  };
}
export interface IGetRequestList extends IWithApolloClient {
  id: number;
  isAdmin: boolean;
  userCompany: number;
}
export interface IGetRequestListReturn {
  requests: (IRequestFragment & {
    BuyerCompany: Company;
    SellerCompany: Company;
    Product: IProductFragment;
    counterProposal: {
      text: string;
    }[];
  })[];
}
export function getRequestListQuery(p: IGetRequestList) {
  try {
    return p.client
      .query({
        query: gql`
          ${new u.Query('getRequestsList', {
            isAdmin: p.isAdmin,
            isLogged: !!p.userCompany,
          })
            .fragments(productFragment, requestFragment)
            .params(['$id', 'Int'])
            .query(requestSchema, 'requests')
            .where((t) => t._eq('id', '$id'))
            .select(
              requestFragment,
              p.isAdmin && [
                'Buyer_company',
                'BuyerCompany',
                CompanyUtils.selectParams(
                  'id',
                  p.isAdmin ? 'name' : undefined,
                  'hidden',
                  p.isAdmin ? ['annual_revenue', 'annualRevenue'] : undefined,
                  p.isAdmin ? ['sales_channels', 'salesChannels'] : undefined,
                  p.isAdmin
                    ? ['portfolio_products_list', 'portfolioProductsList']
                    : undefined,
                  p.isAdmin ? ['therapy_areas', 'therapyAreas'] : undefined,
                  p.isAdmin
                    ? ['number_of_employees', 'numberOfEmployees']
                    : undefined,
                  p.isAdmin ? 'summary' : undefined
                ),
              ],
              p.isAdmin && [
                'Seller_company',
                'SellerCompany',
                CompanyUtils.selectParams(
                  'id',
                  p.isAdmin ? 'name' : undefined,
                  'hidden',
                  p.isAdmin ? ['annual_revenue', 'annualRevenue'] : undefined,
                  p.isAdmin ? ['sales_channels', 'salesChannels'] : undefined,
                  p.isAdmin
                    ? ['portfolio_products_list', 'portfolioProductsList']
                    : undefined,
                  p.isAdmin ? ['therapy_areas', 'therapyAreas'] : undefined,
                  p.isAdmin
                    ? ['number_of_employees', 'numberOfEmployees']
                    : undefined,
                  p.isAdmin ? 'summary' : undefined
                ),
              ],
              p.isAdmin && [
                'Buyer',
                'Buyer',
                UserUtils.selectParams(
                  'id',
                  ['first_name', 'firstName'],
                  ['last_name', 'lastName'],
                  'phone',
                  'email',
                  'verified'
                ),
              ],
              p.isAdmin && [
                'Seller',
                'Seller',
                UserUtils.selectParams(
                  'id',
                  ['first_name', 'firstName'],
                  ['last_name', 'lastName'],
                  'phone',
                  'email',
                  'verified'
                ),
              ],
              ['Product', true, productFragment],

              [
                [
                  'Messages',
                  {
                    where: u.where(
                      u.DealMessagesUtils._eq('request_id', '$id')
                    ),
                    limit: 1,
                    order: u.DealMessagesUtils.orderBy('created_at', 'desc'),
                  },
                ],
                'counterProposal',
                u.DealMessagesUtils.selectParams('text'),
              ]
            )
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: { id: p.id },
      })
      .then(async (res: any) => {
        try {
          const request = res.data.requests[0];
          const requestSeenQuery = (
            party: 'admin' | 'buyer' | 'seller',
            firstSeen: boolean
          ) => {
            try {
              if (!p.id) {
                return;
              }
              return p.client.mutate({
                mutation: gql`
                mutation setSeen(${u.params(
                  ['$id', 'Int'],
                  ['$date', 'timestamp']
                )}) {
                  u: update_requests(
                    ${u.where(u.RequestUtils._eq('id', '$id'))}
                    ${u.RequestUtils._set(
                      party === 'admin' && ['admin_seen', true],
                      party === 'admin' &&
                        firstSeen && ['admin_first_seen', '$date'],
                      party === 'buyer' && ['buyer_seen', true],
                      party === 'buyer' &&
                        firstSeen && ['buyer_first_seen', '$date'],
                      party === 'seller' && ['seller_seen', true],
                      party === 'seller' &&
                        firstSeen && ['seller_first_seen', '$date']
                    )}
                  ) {
                    returning {
                      ${u.RequestUtils.selectParams(
                        'id',
                        party === 'admin' && ['admin_first_seen', 'seen'],
                        party === 'buyer' && ['buyer_first_seen', 'seen'],
                        party === 'seller' && ['seller_first_seen', 'seen']
                      )}
                    }
                  }
                }
              `,
                fetchPolicy: 'no-cache',
                variables: {
                  id: p.id,
                  date: firstSeen ? new Date().toISOString() : undefined,
                },
              }) as ISetSeenMutationReturn;
            } catch (e) {
              logger.log('error', e);
            }
          };

          if (p.isAdmin && !request.adminSeen) {
            const r = await requestSeenQuery(
              'admin',
              request.adminFirstSeen === null
            );
            if (r) {
              res.data.requests[0].adminSeen = true;
              res.data.requests[0].adminFirstSeen = new Date(
                r.data!.u.returning[0].seen
              );
            }
            return res;
          }
          if (p.userCompany === request.buyerCompanyId && !request.buyerSeen) {
            const r = await requestSeenQuery(
              'buyer',
              request.buyerFirstSeen === null
            );
            if (r) {
              res.data.requests[0].buyerSeen = true;
              res.data.requests[0].buyerFirstSeen = new Date(
                r.data!.u.returning[0].seen
              );
            }
            return res;
          }
          if (
            p.userCompany === request.sellerCompanyId &&
            !request.sellerSeen
          ) {
            const r = await requestSeenQuery(
              'seller',
              request.sellerFirstSeen === null
            );
            if (r) {
              res.data.requests[0].sellerSeen = true;
              res.data.requests[0].sellerFirstSeen = new Date(
                r.data!.u.returning[0].seen
              );
            }
            return res;
          }
        } catch (e) {
          logger.log('error', e);
        }
        return res;
      });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> getRequestsListQuery',
      e
    );
  }
}
