/* eslint-disable no-magic-numbers */

import { isBrowser } from 'app/store/utils/is-browser';
import * as React from 'react';
import { animated, useSpring } from 'react-spring';
import styled from 'styled-components';
interface IProps {
  children: React.ReactNode;
  visible: boolean;
  parent: React.RefObject<HTMLDivElement>;
}

const defaultMaxWidth = 290;
const defaultLeftOffset = -12;
const defaultMinSpace = 5;

const TooltipBuble: React.FC<IProps> = (props) => {
  interface PositionProperties {
    className?: string;
    style?: React.CSSProperties;
  }

  const reff = React.useRef<HTMLDivElement>(null);
  const [style, setStyle] = React.useState<PositionProperties>({});
  const [appeared, setAppear] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (
      !(
        typeof window === 'undefined' ||
        typeof window === undefined ||
        window === undefined ||
        window.requestAnimationFrame === undefined ||
        typeof window.requestAnimationFrame !== 'function'
      )
    ) {
      if (typeof window.requestAnimationFrame === 'function' && isBrowser()) {
        window.requestAnimationFrame(() => setStyle(getPosition()));
      }
    }
  }, [props.visible]);

  function getPosition(): PositionProperties {
    if (!props.parent || !props.parent.current) {
      return {};
    }
    const parent = props.parent.current.getBoundingClientRect();
    let className: 'top' | 'bottom' = 'top';
    let left = Math.max(defaultMinSpace, parent.left + defaultLeftOffset);
    let top = parent.top - 4;
    const transform = 'translate(0, -100%)';

    if (!document.body || !reff.current) {
      return { className, style: { top, left, transform } };
    }
    const child = reff.current.getBoundingClientRect();
    top = parent.top - 4 - child.height;

    // Normalize by window dimensions
    const leftOffset =
      document.body.clientWidth - defaultMinSpace - (left + child.width);
    if (leftOffset < 0) {
      left += leftOffset;
    }
    const topOffset = top - defaultMinSpace;
    if (topOffset < 0) {
      const fromBottom = parent.bottom + 4;
      const bottomOffset =
        document.body.clientHeight -
        defaultMinSpace -
        fromBottom -
        child.height;
      if (bottomOffset > 0) {
        top = fromBottom;
        className = 'bottom';
      } else if (topOffset > bottomOffset) {
        top -= topOffset;
      } else {
        top = fromBottom + bottomOffset;
        className = 'bottom';
      }
    }
    return {
      className,
      style: { top, left },
    };
  }

  if (
    !props.parent ||
    !props.parent.current ||
    !style.style ||
    !style.className
  ) {
    return null;
  }

  const getArrowPos = (): number | undefined => {
    if (
      style.style &&
      typeof style.style.left === 'number' &&
      props.parent.current
    ) {
      const parent = props.parent.current.getBoundingClientRect();
      return Math.min(
        parent.left +
          parent.width / 2 -
          (((style as any).style.left as number) || 0),
        288
      );
    }
  };

  return (
    <Wrapper visible={props.visible && appeared}>
      <MountCallback
        callback={() => {
          setStyle(getPosition());
          setAppear(true);
        }}
      >
        <TooltipBubleTextContainer ref={reff} {...style} arrow={getArrowPos()}>
          <TooltipBubleText>{props.children}</TooltipBubleText>
        </TooltipBubleTextContainer>
      </MountCallback>
    </Wrapper>
  );
};

export { TooltipBuble };
export default TooltipBuble;

const TooltipBubleText = styled.div`
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

interface TooltipBubleTextContainer {
  arrow?: number;
}
const TooltipBubleTextContainer = styled(animated.div)`
  width: auto;
  max-width: ${defaultMaxWidth}px;
  padding: 15px;
  box-shadow: 0 6px 24px 0 rgba(53, 64, 82, 0.05);

  position: fixed;
  z-index: 1;

  box-sizing: border-box;
  text-align: left;
  border-radius: 5px;
  border: solid 1px ${(props) => props.theme.colors.grayLight};
  background-color: ${(props) => props.theme.colors.white};

  &:after,
  &:before {
    border: solid transparent;
    content: ' ';
    height: 0px;
    width: 0px;
    position: absolute;
    pointer-events: none;
    border-color: transparent;
  }
  &:after {
    border-width: 3.1px;
  }
  &:before {
    border-width: 4.6px;
  }

  &.top {
    &:after,
    &:before {
      top: 100%;
    }
    &:after {
      margin-left: ${(props: TooltipBubleTextContainer) =>
        (props.arrow || 0) - 19.1}px;
      border-top-color: ${(props) => props.theme.colors.white};
    }
    &:before {
      margin-left: ${(props: TooltipBubleTextContainer) =>
        (props.arrow || 0) - 20.6}px;
      border-top-color: ${(props) => props.theme.colors.grayLight};
    }
  }
  &.bottom {
    &:after,
    &:before {
      top: 0;
      transform: translate(0, -100%);
    }
    &:after {
      margin-left: ${(props: TooltipBubleTextContainer) =>
        (props.arrow || 0) - 19.1}px;
      border-bottom-color: ${(props) => props.theme.colors.white};
    }
    &:before {
      margin-left: ${(props: TooltipBubleTextContainer) =>
        (props.arrow || 0) - 20.6}px;
      border-bottom-color: ${(props) => props.theme.colors.grayLight};
    }
  }
`;

const Wrapper: React.FC<{ visible: boolean }> = ({
  visible = false,
  children,
}) => {
  const spr = useSpring({
    opacity: visible ? 1 : 0,
    config: {
      duration: 200,
    },
  });

  return (
    <animated.div
      style={{
        display: spr.opacity.interpolate((o) =>
          visible || o ? 'block' : 'none'
        ),
        ...spr,
      }}
    >
      {children}
    </animated.div>
  );
};

const MountCallback: React.FC<{ callback: () => void }> = (props) => {
  React.useEffect(props.callback, []);
  return props.children as any;
};
