import { useEffect, useRef } from 'react';

export const useIsMountedRef: any = () => {
  const isMountedRef: any = useRef(null);
  useEffect((): any => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
};
