import React, { FC } from 'react';

const Spinner: FC = () => (
  <svg
    width="106px"
    height="106px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
  >
    <rect x="0" y="0" width="100" height="100" fill="none" />
    <circle
      cx="50"
      cy="50"
      r="40"
      stroke="#1C8ED3"
      fill="none"
      strokeWidth="6"
      strokeLinecap="round"
    >
      <animate
        attributeName="stroke-dashoffset"
        dur="1.5s"
        repeatCount="indefinite"
        from="502"
        to="0"
      />
      <animate
        attributeName="stroke-dasharray"
        dur="1.5s"
        repeatCount="indefinite"
        values="150.6 100.4;1 250;150.6 100.4"
      />
    </circle>
  </svg>
);

export default Spinner;
