import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';

// custom imports
import { DashboardContainer } from 'app/components/common-components';
import SuccessLarge from 'app/components/notifications/success-large';
import PermissionCheck, {
  Permissions,
} from 'app/modules/auth/permissions/permissions-check';
import { sgg } from 'app/utils/safe-get';
import { IProps } from './index';
import DealsTable from './components/request-table';
import DealsFilter from './components/requests-filter';
import DealsListHeader from './components/requests-list-header';

const secondsToShowMultipleDealsMessage = 360;

const MyRequestsList: React.FC<IProps> = ({
  error,
  success,
  clearNotification,
  fetch,
  verify,
  userSettingFetched,
  createDealForm,
}) => {
  const [currentError, setCurrentError] = useState('');
  const [currentSuccess, setCurrentSuccess] = useState('');

  useEffect(() => {
    if (
      (error !== '' && currentError === '') ||
      (error !== '' && currentError !== '' && error !== currentError)
    ) {
      setCurrentError(error);
    }
    if (
      (success !== '' && currentSuccess === '') ||
      (success !== '' && currentSuccess !== '' && success !== currentSuccess)
    ) {
      setCurrentSuccess(success);
    }
    const clearAfter1s = 1000;
    setTimeout(() => {
      clearNotification();
    }, clearAfter1s);
  }, [error, success]);

  const numberOfAlternativeDealsCreated: number = sgg(
    () => createDealForm.product!.selectedProducts!.length,
    0
  );
  const multipleDealsCreationTime: string = sgg(
    () => createDealForm.product!.dealsGroup!.split('_')[0],
    0
  );
  const showMultipleDealsMessage: boolean =
    //
    differenceInSeconds(
      new Date(),
      (multipleDealsCreationTime as any) || new Date()
    ) < secondsToShowMultipleDealsMessage;

  return (
    <DashboardContainer>
      <DealsListHeader fetch={fetch} />
      <DealsFilter fetch={fetch} />
      {verify === false && userSettingFetched === true && (
        <SuccessLarge
          hideSuccess={true}
          closable={false}
          label={'Please verify your email address.'}
          text={
            'To complete verification process and get access to all our features, please check your mailbox and click the link in the email we have sent you.'
          }
          marginBottom={'12px;'}
        />
      )}
      {numberOfAlternativeDealsCreated > 0 && showMultipleDealsMessage && (
        <SuccessLarge
          hideSuccess={true}
          closable={false}
          label={`Your request has been sent to ${
            numberOfAlternativeDealsCreated + 1
          } suppliers`}
          marginBottom={'12px;'}
        />
      )}
      <DealsTable fetch={fetch} />
      <PermissionCheck allowedPermission={Permissions.registeredUser} />
    </DashboardContainer>
  );
};

export default MyRequestsList;
