import { User } from '../../auth/types';
import {
  convertToDeal,
  requestFragment,
  IRequestFragment,
} from '../../requests/fragments/request';
import { IProductSubstance } from '../../global/types';
import {
  DealUtils,
  CompanyUtils,
  SubstanceUtils,
  UserUtils,
} from '../../query-utils';
import { IFragment } from '../../query-utils/fragment';
import { IDeal } from '../types';

export interface IDealFragment {
  id: number;
  Buyer: User;
  Substance1: IProductSubstance;
  Substance2?: IProductSubstance;
  Substance3?: IProductSubstance;
  countriesOfInterest: string[];
  Broker?: User;
  targetSupplyPrice?: string;
  estimatedAnnualQuantity?: string;
  expectSignContract?: string;
  dealType?: number;
  comments?: string;
  Requests: IRequestFragment[];
}
export const dealsFragment: IFragment = {
  fragment: (isAdmin = false, includeDeals = false) => `
    fragment dealsFragment on deals {
      ${DealUtils.selectParams(
        ['id', 'id'],
        ['Substance1', true, SubstanceUtils.selectParams('id', 'name')],
        ['Substance2', true, SubstanceUtils.selectParams('id', 'name')],
        ['Substance3', true, SubstanceUtils.selectParams('id', 'name')],
        ['countries_of_interest', 'countriesOfInterest'],
        ['target_supply_price', 'targetSupplyPrice'],
        ['estimated_annual_quantity', 'estimatedAnnualQuantity'],
        ['expect_sign_contract', 'expectSignContract'],
        ['deal_type', 'dealType'],
        ['comments', 'comments'],
        includeDeals && ['Requests', true, requestFragment],
        [
          'Buyer',
          true,
          UserUtils.selectParams(
            'id',
            ['full_name', 'fullName'],
            ['first_name', 'firstName'],
            ['last_name', 'lastName'],
            isAdmin && 'email',
            isAdmin && 'phone',
            isAdmin && [
              'Company',
              true,
              CompanyUtils.selectParams('id', 'name'),
            ]
          ),
        ],
        [
          'Broker',
          true,
          UserUtils.selectParams(
            'id',
            ['full_name', 'fullName'],
            isAdmin && 'email'
          ),
        ]
      )}
    }
  `,
  name: 'dealFragment',
};
export function convertToCase(
  p: IDealFragment,
  party: 'admin' | 'buyer' | 'seller'
): IDeal {
  return {
    id: p.id,
    Buyer: p.Buyer,
    Substance1: p.Substance1,
    Substance2: p.Substance2,
    Substance3: p.Substance3,
    countriesOfInterest: p.countriesOfInterest,
    Requests:
      p.Requests && p.Requests.length > 0
        ? p.Requests.map((request) => convertToDeal(request, party))
        : [],
    Broker: p.Broker ? p.Broker : undefined,
    comments: p.comments,
    dealType: p.dealType,
    estimatedAnnualQuantity: p.estimatedAnnualQuantity,
    expectSignContract: p.expectSignContract,
    targetSupplyPrice: p.targetSupplyPrice,
  };
}
