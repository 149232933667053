import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';

export interface IRequestNewProduct extends IWithApolloClient {
  email: string;
  product: string;
  message: string;
  onlyReady: boolean;
  coDevelopment: boolean;
}
export async function requestNewProduct(p: IRequestNewProduct) {
  try {
    return await p.client.mutate({
      mutation: gql`
        mutation requestNewProduct(${u.params(
          ['$email', 'String'],
          ['$product', 'String'],
          ['$message', 'String'],
          ['$ready', 'Boolean'],
          ['$dev', 'Boolean'],
          ['$date', 'timestamptz']
        )}) {
          insert_product_request(${u.ProductRequest._objects([
            ['email', '$email'],
            ['message', '$message'],
            ['product', '$product'],
            ['co_development', '$dev'],
            ['ready_to_file_only', '$ready'],
            ['created_at', '$date'],
          ])}) {
            returning {
              ${u.ProductRequest.selectParams('id')}
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        email: p.email,
        product: p.product,
        message: p.message,
        ready: p.onlyReady,
        dev: p.coDevelopment,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/marketplace/query.ts -> requestNewProduct',
      e
    );
  }
}
