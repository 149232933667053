import { _, media } from 'app/styles/breakpoint';
import styled from 'styled-components';

//
export const SellerInformationWrapper = styled.div`
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  ${_(media.max.sm)} {
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  display: flex;
  flex-direction: row;
`;

export const SellerInformationContainer = styled.div`
  width: 1200px;
  display: flex;
  flex-direction: column;

  ${_(media.max.sm)} {
    width: 100%;
  }
`;

export const Header = styled.div`
  margin-top: 4px;
  padding-left: 42px;
  padding-right: 56px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

export const Row = styled.div`
  &:after {
    content: '';
    display: table;
    clear: both;
  }
`;

export const Column = styled.div<{
  maxWidthPercentage?: string;
  padding?: string;
}>`
  float: left;
  width: ${(props) => props.maxWidthPercentage || '50%'};
  padding: ${(props) => props.padding || '10px'};
`;

export const SellerCommissionContainer = styled.div<{
  paysCommission: boolean;
}>`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 14px 24px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 8px 24px rgba(53, 64, 82, 0.0262964);
  border-radius: 3px;
  border-top: 5px solid
    ${(props) => (props.paysCommission ? `#64b00d` : `#e55757`)};
`;
export const SellerPaysCommissionText = styled.div`
  display: flex;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grayExtraDark};
`;

export const PriceAndFeeTextContainer = styled.div`
  display: flex;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grayExtraDark};
`;
export const PriceAndFeeDivContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
export const DoesNotPayTextContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 5px;
  @media (max-width: 450px) {
    flex-flow: wrap;
  }
`;
export const BoldTextContainer = styled.div`
  display: flex;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grayExtraDark};
`;
export const CommonDealByTextContainer = styled.div`
  display: flex;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.grayExtraDark};
`;
export const MainCommissionInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  flex-wrap: wrap;
`;
export const LeftCommissionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  gap: 8px;
  @media (max-width: 850px) {
    width: 90%;
  }
  @media (max-width: 450px) {
    width: 83%;
  }
`;
export const RightCommissionInfoContainer = styled.div`
  display: flex;
  width: 20%;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (max-width: 850px) {
    width: 10%;
  }
`;
export const EditCommissionContainer = styled.div`
  width: 100%;
  height: auto;
  padding: 16px 23px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.grayLighter};
`;
export const EditPriceContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  div > label {
    text-align: left !important;
  }
`;
export const EditInputFieldContainer = styled.div`
  width: 70%;
`;
export const PercentageContainer = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: self-end;
  margin: auto;
  padding-top: 18px;
  padding-left: 5px;
`;
export const PercentageLabel = styled.label`
  display: flex;
  height: 20px;
  left: 70px;
  top: 24px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grayText};
`;
export const EditLicenseAndSupplyFeeContainer = styled.div`
  width: 80%;
  position: relative;
  min-height: auto;
  display: flex;
  flex-direction: row;
  @media (max-width: 1050px) {
    width: 88%;
  }
`;
export const LabelPreAgreedContainer = styled.label`
  display: flex;
  padding-top: 8px;
  padding-bottom: 16px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.grayText};
`;
export const CommissionFormActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 4px;
`;
export const CompanyFormActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 4px;
  width: 30%;
`;
export const CancelButtonContainer = styled.div`
  width: auto;
  height: auto;
  background: ${(props) => props.theme.colors.white};
  & > button {
    position: relative;
    right: 10px;
    width: 100%;
    height: 34px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    padding: 9px 17px;
    border: 1px solid ${(props) => props.theme.colors.grayLight};
    box-sizing: border-box;
    border-radius: 2px;
    color: ${(props) => props.theme.colors.muted};
    outline: none;
    &:hover {
      cursor: pointer;
      background: ${(props) => props.theme.colors.grayExtraLight};
    }
  }
`;
export const SaveButtonContainer = styled.div`
  width: auto;
  height: auto;
  background: ${(props) => props.theme.colors.white};
  & > button {
    height: 34px;
    width: 104px;
    min-width: 104px;
    font-family: Nunito Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 37px;
  }
`;
export const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  width: 64.62px;
  height: 26px;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px 11px;
  background-color: ${(props) => props.theme.colors.primaryLight};
  :hover {
    background-color: ${(props) => props.theme.colors.primaryLightHover};
  }
`;
export const AddItemContainerBGless = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px 11px;
`;
export const SignedInfoContainer = styled.div`
  min-height: 200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  @media (max-width: 850px) {
    margin-top: 0;
    min-height: 0;
    padding-left: 30px;
  }
  @media (max-width: 450px) {
    padding-top: 12px;
    padding-left: 0;
  }
`;
export const CheckboxContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
`;

export const ActionIconCommonCSS = `
  text-align: center;
  padding: 2px 5px 2px 5px;
  margin-left: 1px;
  transition: all 0.3s;
  &:hover {
    cursor: pointer;
  }
`;

export const GrayColoredSpan = styled.div<{
  paddingLeft?: string;
  margin?: string;
}>`
  color: ${(props) => props.theme.colors.greyDark};
  padding-left: ${(props) => props.paddingLeft || '5px'};
  padding-right: 5px;
  font-size: 14px;
  margin: ${(props) => props.margin || 'unset'};
`;
export const GrayColoredSpanAligned = styled.div<{
  paddingLeft?: string;
}>`
  color: ${(props) => props.theme.colors.greyDark};
  padding-left: ${(props) => props.paddingLeft || '5px'};
  padding-right: 5px;
  font-size: 14px;
  margin: 0 0 30px 7px;
  align-self: flex-end;
`;
export const FormContainerEditMode = styled.div<{
  paddingLeft?: string;
}>`
  color: ${(props) => props.theme.colors.greyDark};
  padding-left: ${(props) => props.paddingLeft || '5px'};
  padding-right: 5px;
  font-size: 14px;
  margin: 0 10px 0 10px;
  align-self: flex-end;
`;
export const LabelAdditionalContainer = styled.div`
  display: flex;
  font-size: 15px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 22px;
  color: ${(props) => props.theme.colors.grayExtraDark};
  @media (max-width: 450px) {
    align-items: center;
  }
`;

export const DateContainer = styled.div<{ hideBorderAndIcon: boolean }>`
  & > div > div {
    width: 120px;
    min-width: 120px;
  }
  & input {
    position: relative;
    top: -2px;
    background: rgb(245, 246, 248);
    margin-top: 0px;
    height: 28px;
    font-size: 14px;
    color: ${(props) => props.theme.colors.grayDark};
    ${(props) =>
      props.hideBorderAndIcon ? 'border-bottom: 0px !important;' : ''}
  }
  & img {
    position: relative;
    top: -10px;
    ${(props) => (props.hideBorderAndIcon ? 'display: none;' : '')}
  }
`;

export const LabelContainer = styled.div<{ top: string }>`
  position: relative;
  top: ${(props) => props.top};
`;
export const RemarksBox = styled.div`
  width: 100%;
  height: auto;
`;
export const SummaryBox = styled.div`
  margin-top: 32px;
  width: 100%;
  height: auto;
`;

export const RemarksContainer = styled.div<{ showBorder: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  z-index: 2;
  padding: 12px 24px;
  border-radius: 3px;
  border: ${(props) => (props.showBorder ? '1px solid #DADADA' : 'none')};
  background: ${(props) => props.theme.colors.white};
  @media (max-width: 1050px) {
    margin-bottom: 0;
  }
`;
export const SummaryContainer = styled.div<{ showBorder: boolean }>`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  z-index: 2;
  padding: 12px 24px;
  border-radius: 3px;
  border: ${(props) => (props.showBorder ? '1px solid #DADADA' : 'none')};
  background: ${(props) => props.theme.colors.white};
  @media (max-width: 1050px) {
    margin-bottom: 0;
  }
`;
export const HeaderContainer = styled.div`
  display: flex;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  color: ${({ theme }) => theme.colors.grayText};
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`;
export const RemarksContent = styled.div`
  display: flex;
`;

export const SummaryContent = styled.div`
  display: flex;
`;

export const RemarksText = styled.p`
  display: flex;
  width: 88%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: inherit;
  color: ${({ theme }) => theme.colors.grayExtraDark};
  @media (max-width: 450px) {
    width: 85%;
  }
`;

export const SummaryText = styled.p`
  display: flex;
  width: 88%;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  text-align: inherit;
  color: ${({ theme }) => theme.colors.grayExtraDark};
  @media (max-width: 450px) {
    width: 85%;
  }
`;

export const ButtonContainer = styled.div`
  width: auto;
  height: auto;
`;
export const EditButtonContainer = styled.div`
  display: flex;
  width: 12%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;
export const EditActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 16px;
  margin-bottom: 4px;
`;

export const EditRemarksContainer = styled.div`
  & > div {
    max-width: 100%;
  }
`;

export const AssignMarketDropDownContainer = styled.div`
  position: relative;
  width: 100%;
`;
export const AssignMarketDropDownSelect = styled.div`
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.colors.white};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray};
`;

export const Label = styled.div`
  display: block;
  width: 100%;
  padding-top: 10px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayText};
  cursor: default;
`;

export const NoOption = styled.div`
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  text-align: center;
  padding-bottom: 10px;
  color: ${(props) => props.theme.colors.grayText};
`;

export const DropDownOptionContainer = styled.div<{ display: string }>`
  position: absolute;
  z-index: 2;
  width: 100%;
  max-height: 280px;
  padding-bottom: 12px;
  display: ${(props) => props.display || 'flex'};
  background: ${(props) => props.theme.colors.white};
  overflow-y: auto;
  border-left: 1px solid ${(props) => props.theme.colors.grayLight};
  border-right: 1px solid ${(props) => props.theme.colors.grayLight};
  border-bottom: 1px solid ${(props) => props.theme.colors.grayLight};
  box-shadow: 0px 8px 24px rgba(53, 64, 82, 0.0262964);
  border-radius: 3px;
  & > div {
    width: 100%;
    position: relative;
    top: 10px;
  }
`;

export const ItemContainer = styled.div<{ hoverBGColor: string }>`
  &:hover {
    background: ${(props) => props.hoverBGColor};
  }
`;

export const CountryItem = styled.div`
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  & div[class*='check-box__CheckboxStyle'] {
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }
  & img {
    width: 9px;
    height: 7px;
  }
`;

export const RegionItemContainer = styled.div`
  width: 100%;
  padding: 0px 12px;
`;

export const RegionItemContent = styled.div`
  padding: 12px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

export const RegionName = styled.span`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.black};
  cursor: default;
`;

export const RegionSelection = styled.span`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  color: ${(props) => props.theme.colors.grayMidLight};
  cursor: default;
`;

export const SelectedCountriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-height: 80px;
  overflow-y: auto;
`;

export const TextInputBox = styled.input`
  width: auto;
  border: none;
  outline: none;
  caret-color: ${(props) => props.theme.colors.accent};
`;

export const SelectedCountryContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 4px;
  margin-bottom: 4px;
  cursor: pointer;
`;
export const SelectedCountryName = styled.span`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: ${(props) => props.theme.colors.black};
  padding: 0px 10px;
  background: ${(props) => props.theme.colors.midLightBlue};
`;
export const SelectedCountryRemoveIcon = styled.span`
  padding: 0px 5.5px;
  background: ${(props) => props.theme.colors.selectionRemoveIconBg};
  & > svg {
    margin-top: 5px;
  }
  &:hover {
    background: ${(props) => props.theme.colors.selectionRemoveIconHoverBg};
  }
`;
export const SellerContactContainer = styled.div`
  height: auto;
  width: 100%;
  display: block;
`;
export const SellerAddContactContainer = styled.div`
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;
export const ContactFormContainer = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 13px;
  padding: 24px 24px 12px 24px;
  background: ${(props) => props.theme.colors.white};
  border: 1px solid #dadada;
  box-shadow: 0px 8px 24px rgba(53, 64, 82, 0.0262964);
  border-radius: 3px;
`;
export const ContactForm = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 2;
`;
export const ContactFormActions = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: flex-end;
`;
export const DeleteButtonContainer = styled.div`
  width: 34px;
  margin-right: 20px;
  background: ${(props) => props.theme.colors.grayButton};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px 20px;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.grayLight};
  }
`;
export const DeleteButtonIcon = styled.img`
  width: 14.98px;
  object-fit: contain;
`;

export const CancelButton = styled.button`
  position: relative;
  right: 10px;
  width: 100%;
  height: 34px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  padding: 9px 17px;
  border: 1px solid ${(props) => props.theme.colors.grayLight};
  box-sizing: border-box;
  border-radius: 2px;
  color: ${(props) => props.theme.colors.muted};
  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.colors.grayExtraLight};
  }
  &:focus {
    outline: 5px auto Highlight;
    outline: 5px auto -webkit-focus-ring-color;
  }
`;
export const SellerContactListContainer = styled.div`
  width: 100%;
  height: auto;
`;
export const SellerContactListItemContainer = styled.div`
  margin-bottom: 13px;
  width: 100%;
  height: auto;
  padding: 12px 24px;
  -webkit-box-shadow: 0px 0px 20px -20px rgba(85, 85, 85, 1);
  -moz-box-shadow: 0px 0px 20px -20px rgba(85, 85, 85, 1);
  box-shadow: 0px 8px 24px rgba(53, 64, 82, 0.0262964);
  border-radius: 3px;
  background: ${(props) => props.theme.colors.white};
`;

export const SellerContactNameContainer = styled.b`
  width: 100%;
  height: auto;
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};
  display: flex;
  flex-direction: row;
  gap: 5px;
`;
export const SellerContactName = styled.b`
  height: auto;
  font-size: 14px;
  color: ${(props) => props.theme.colors.black};
`;

export const SellerContactDetails = styled.div`
  width: 100%;
  height: auto;
  padding-top: 5px;
`;

export const SellerContactInfoAnchor = styled.a<{
  floatLeft?: boolean;
  paddingTop?: string;
}>`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-decoration: underline;
  cursor: pointer;
  color: ${(props) => props.theme.colors.grayText};
  @media (max-width: 450px) {
    ${(props) => props.floatLeft && 'float: left;'}
    ${(props) => props.paddingTop && 'padding-top: 8px;'}
  }
`;
export const Separator = styled.span`
  padding: 8px;
`;
export const SellerContactResponsible = styled.div<{ emptyFlag?: boolean }>`
  position: relative;
  top: 3px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.grayText};
  ${(props) => props.emptyFlag && 'padding-bottom: 8px;'}
`;
export const SellerContactResponsibleContainer = styled.div`
  position: relative;
  top: 8px;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.grayText};
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-bottom: 8px;
`;

export const EditItemBlueButton = styled.div`
  display: flex;
  align-items: center;
  width: 64.62px;
  height: 26px;
  cursor: pointer;
  border-radius: 2px;
  padding: 4px 11px;
  background-color: ${(props) => props.theme.colors.primaryLight};
  :hover {
    background-color: ${(props) => props.theme.colors.primaryLightHover};
  }
`;
export const MainSellerInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  @media (max-width: 850px) {
    flex-direction: column;
  }
`;

export const LeftSellerInfoContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 67%;
  padding: 10px;
  float: left;

  @media (max-width: 1050px) {
  }
  @media (max-width: 850px) {
    width: 100%;
  }
  @media (max-width: 450px) {
  }
`;

export const RightSellerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  float: left;
  padding: 10px;
  @media (max-width: 1050px) {
  }
  @media (max-width: 850px) {
    flex-direction: row;
    width: 100%;
  }
  @media (max-width: 450px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const SellerCommissionContainerDiv = styled.div`
  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const SellerContactInfoContainer = styled.div`
  float: left;
  width: 92%;
  @media (max-width: 450px) {
    width: 75%;
  }
`;

export const SellerContactInfoEditBtnContainer = styled.div`
  float: left;
  width: 8%;
  @media (max-width: 450px) {
    width: 25%;
  }
`;
export const SellerContactFormRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;
export const SellerContactFormFieldContainer = styled.div<{ width?: string }>`
  float: left;
  width: ${(props) => props.width || '33.3%'};
  padding: 10px;
  @media (max-width: 450px) {
    width: 100%;
  }
`;
export const ProductOwnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 2px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0px 8px 24px rgba(53, 64, 82, 0.0262964);
  border-radius: 3px;
  border: 2px solid ${(props) => props.theme.colors.primary};
`;
export const ProductOwnerImageContainer = styled.div``;
export const ProductOwnerTextContainer = styled.div`
  position: static;
  width: 37px;
  height: 16px;
  left: 21px;
  top: 2px;

  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  color: ${(props) => props.theme.colors.primary};

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 4px;
`;
