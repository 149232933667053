import {
  AnyListItem,
  Product,
  ProductTable,
} from 'app/store/modules/products/types';
import { Dictionary } from 'app/utils/dictionary';
import { IProduct } from '../products/fragments/product';

export interface State {
  marketplaceProductCache: Dictionary<Product>;
  marketplaceListItem: AnyListItem[];
  marketplaceTopProducts: AnyListItem[];
  totalCount: number;
  currentPage: number;
  pageSize: number;
  filter: MarketplaceFilter;
  isElastic: boolean;
  isFetching: boolean;
  hasFetched: boolean;

  newProductRequestMessage: {
    type?: 'error' | 'success';
    message?: string;
  };
  substanceSEOText: string;
  substanceSEOable: boolean;
  countrySEOText: string;
  brandSEOName: string;
}

export const defaultPageSize = 10;
export interface MarketplaceCache {
  [id: string]: MarketplaceItem;
}

export interface MarketplaceFilterWithoutProduct {
  productId?: number;
  type?: number;
  isNavbar?: boolean;
  resetPage?: boolean;
  masForSale?: string[];
  offerFor?: string[];
  countryOfOrigin?: string[];
  dossierStatus?: number;
  dossierType?: number[];
  gmpApprovals?: string[];
  formSpecification?: string[];
  doseCategory?: string[];
  therapyArea?: string[];
  manufacturer?: string[];
  productionCountry?: string[];
  registrationCountry?: string[];
  availableDocuments?: {
    coa?: boolean;
    coo?: boolean;
  };
  packLanguage?: string[];
  doseFormTrade?: string[];
  doseCategoryTrade?: string[];
  therapyAreaTrade?: string[];
  traderProducts?: boolean;
  manufacturerProducts?: boolean;
  isBrandName?: boolean;
  withPriceProducts?: boolean;
}

export interface MarketplaceFilter extends MarketplaceFilterWithoutProduct {
  product: string;
}

export interface MarketplaceTable {
  ranking: number;
  Product: ProductTable;
  productid: number;
}

export interface MarketplaceItem {
  ranking: number;
  Product?: IProduct;
  productId: number;
  updatedAt: Date;
}

export interface NotFoundRequestFormType {
  email: string;
  interestedInProduct: string;
  message: string;
  onlyInterestedInReadyToFile: boolean;
  interestedInCoDevelopmentCoInvestment: boolean;
}

export const defaultStore: State = {
  marketplaceProductCache: {},
  marketplaceListItem: [],
  marketplaceTopProducts: [],
  totalCount: 0,
  currentPage: 0,
  pageSize: 0,
  filter: {
    isNavbar: false,
    product: '',
    productId: undefined,
    offerFor: [],
    dossierStatus: undefined,
  },
  isElastic: true,
  isFetching: false,
  hasFetched: false,
  newProductRequestMessage: {
    type: undefined,
    message: undefined,
  },
  substanceSEOText: '',
  countrySEOText: '',
  substanceSEOable: false,
  brandSEOName: '',
};

export enum AutocompleteProductType {
  Substance = 1,
  Brand = 2,
}

export enum MarketplaceItemType {
  product = 'product',
}

export enum MarketplaceFilterType {
  masForSale = 'masForSale',
  offerFor = 'offerFor',
  countryOfOrigin = 'countryOfOrigin',
  dossierStatus = 'dossierStatus',
  product = 'product',
  dossierType = 'dossierType',
  gmpApprovals = 'gmpApprovals',
  formSpecification = 'formSpecification',
  doseCategory = 'doseCategory',
  therapyArea = 'therapyArea',
  manufacturer = 'manufacturer',
  productionCountry = 'productionCountry',
  registrationCountry = 'registrationCountry',
  availableDocuments = 'availableDocuments',
  therapyAreaTrade = 'therapyAreaTrade',
  packLanguage = 'packLanguage',
  doseFormTrade = 'doseFormTrade',
  doseCategoryTrade = 'doseCategoryTrade',
  traderProducts = 'traderProducts',
  manufacturerProducts = 'manufacturerProducts',
  withPriceProducts = 'withPriceProducts',
}

export type RemoveFilterType = (filterName: string, filterValue: any) => any;
