import { Dictionary } from 'app/utils/dictionary';

export interface State extends IPaginatable {
  companiesCache: Dictionary<ICompany>;
  companiesIds: number[];
  error: Notifications;
  isFetching: boolean;
  hasFetched: boolean;
  editModalOpen: boolean;
  editCompanyId: number;
  companyDirectoryLetters: string[];
  companyDirectoryCompanies: ICompanyDirectory[];
  directoryFooterCountrySeoText: string;
}

export interface ICompany {
  id: number;
  subject: string;
  description: string;
  ranking?: number;

  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
}

export interface ICompanyDirectory {
  id: number;
  name: string;
  country?: string;
  website?: string;
  address?: string;
  code?: string;
}
export interface IPaginatable {
  totalCount: number;
  currentPage: number;
  pageSize: number;
}

export interface Notifications {
  listError: string;
  listSuccess: string;
}

export const defaultPageSize = 10;

export interface CompanyFormFields {
  id: number;
  subject: string;
  subjectError?: string;
  description: string;
  descriptionError?: string;
  ranking?: number;
  rankingError?: string;
  createdAt?: Date;
  deletedAt?: Date;
}

export interface Countries {
  code: string;
  name: string;
  order: number;
  seoTextFooter: string;
  seoTextFooterDirectory: string;
}

export const defaultStore: State = {
  companiesCache: {},
  companiesIds: [],
  currentPage: 0,
  error: { listError: '', listSuccess: '' },
  isFetching: false,
  hasFetched: false,
  pageSize: 0,
  totalCount: 0,
  editModalOpen: false,
  editCompanyId: 0,
  companyDirectoryLetters: [],
  companyDirectoryCompanies: [],
  directoryFooterCountrySeoText: '',
};
