import { createActionCreator as c, createActionCreator } from '@reduxify/utils';
import { SubstanceFilter } from './types';
import { IGetSubstanceListReturn } from './query';
import { Notifications } from './types';
import { Dictionary } from '../../../utils/dictionary';

export const requestFetchSubstances = createActionCreator<{
  client: any;
  filter: SubstanceFilter;
  pageSize?: number;
}>('substances/REQUEST_FETCH_SUBSTANCES');
export const setCurrentPage = createActionCreator<number>(
  'substances/SET_PAGE'
);
export const setPageSize = createActionCreator<number>(
  'substances/SET_PAGE_SIZE'
);
export const deleteSubstance = createActionCreator<{
  substanceId: number;
  client: any;
}>('substances/DELETE_SUBSTANCE');
export const fetchSubstancesSuccess = createActionCreator<{
  substanceList: IGetSubstanceListReturn['substances'];
  totalCount: number;
}>('substances/FETCH_SUBSTANCES_SUCCESS');
export const setNotification = createActionCreator<Notifications>(
  'substances/SET_NOTIFICATION'
);
export const setStatus = createActionCreator<number>('substances/SET_STATUS');

export const setSubstanceFilter = createActionCreator<SubstanceFilter>(
  'substances/SET_FILTER'
);
export const hasFetched = createActionCreator<boolean>(
  'substances/HAS_FETCHED'
);
export const setSubstanceCache = createActionCreator<Dictionary<any>>(
  'substances/SET_SUBSTANCE_CACHE'
);
export const setTotalCount = createActionCreator<number>(
  'substances/SET_TOTAL_COUNT'
);
export const setSubstanceIds = createActionCreator<any[]>('substances/SET_IDS');

export const setVisibleSubstanceIds = createActionCreator<any[]>(
  'substances/SET_VISIBLE_IDS'
);
export const filterSubstance = createActionCreator<{
  filter: SubstanceFilter;
  client: any;
}>('substances/FILTER_SUBSTANCE');
export const setEditSubstanceId = c<number | null>(
  'substances/SET_SUBSTANCE_EDIT_ID'
);
