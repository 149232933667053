import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
  takeLatest,
} from '@redux-saga/core/effects';
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import {
  fetchCountrySEOTextByCode,
  fetchSubstanceSEOTextByName,
  getProductQuery,
} from 'app/store/modules/products/query';
import {
  fetchMarketplaceItems as fetchMarketplaceItemsAction,
  fetchMarketplaceSuccess,
  fetchProduct,
  fetchProductSuccess,
  filterMarketplace,
  requestFetchCountrySEOText,
  requestFetchMarketplaceItems,
  requestFetchSubstanceSEOText,
  requestFetchTopProducts,
  requestNewProduct,
  setBrandNameSEO,
  setBrandSubstance,
  setCountrySEOText,
  setHasFetched,
  setIsFetching,
  setMarketplaceFilter,
  setMarketplaceItemsList,
  setMarketplaceProductCache,
  setRequestMessage,
  setSubstanceSEOable,
  setSubstanceSEOText,
  setTopProducts,
  setTotalCount,
} from './actions';
import {
  getMarketplaceItemsListQuery,
  getMarketplaceTopItemsListQuery,
  IGetTopItemsListReturn,
  marketplaceSearchElasticQuery,
  requestNewProduct as requestNewProductQuery,
} from './query';

import { isBrowser } from 'app/store/utils/is-browser';
import { toDictionary } from 'app/utils/dictionary';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import { IStore } from '../../types';
import { TypeOrigin } from '../global/types';
import { convertToProduct } from '../products/fragments/product';
import { AnyListItem, Product } from '../products/types';
import {
  AutocompleteProductType,
  MarketplaceItem,
  MarketplaceItemType,
} from './types';
import { anonymousUserId } from 'app/config';

const pageNrWhenPagingIsNotUsed = 0;

export function* fetchMarketplaceItemsSaga(
  action: ReturnType<typeof requestFetchMarketplaceItems>
) {
  try {
    const ms300 = 300;
    const initialOffset = 0;
    const { client, filter, isFast, skipBrand } = action.payload;

    if (!isFast) {
      yield delay(ms300);
    }

    if (client) {
      // @ts-ignore
      const state: IStore = yield select();
      const authUser = state.auth.loggedInUser
        ? state.auth.loggedInUser
        : undefined;
      const isAdmin = authUser ? authUser.isAdmin : false;
      const isElastic = sg(() => state.marketplace.isElastic, false);

      yield put(setIsFetching(true));

      if (!skipBrand) {
        yield put(
          requestFetchSubstanceSEOText({ text: filter.product, client })
        );
        const {
          payload: brandName,
        }: ReturnType<typeof setBrandNameSEO> = yield take(setBrandNameSEO);
        filter.isBrandName = brandName ? true : false;
      }

      const isNavbar = filter.isNavbar;
      let totalCount = 0;

      // with elastic search
      if (isElastic) {
        const response: Unpromisefy<
          ReturnType<typeof marketplaceSearchElasticQuery>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        > = yield call(marketplaceSearchElasticQuery, {
          client,
          size: state.marketplace.pageSize,
          offset:
            state.marketplace.currentPage === 0
              ? initialOffset
              : state.marketplace.currentPage * state.marketplace.pageSize,
          filter,
          isAdmin,
        } as Params<typeof marketplaceSearchElasticQuery>);

        if (!response) {
          throw new Error('GraphQL response is undefined');
        }

        totalCount = response.data.products_search.total;
        yield put(
          fetchMarketplaceSuccess({
            marketplaceItemList: response.data.products_search.results,
            totalCount,
            currentPage: state.marketplace.currentPage,
          })
        );
      }

      if (state.marketplace.currentPage === 0) {
        if (filter.product !== '') {
          const email = authUser ? authUser.email : undefined;
          const anonymousUserIdItem = localStorage.getItem(anonymousUserId);

          if (isNavbar) {
            ReactGA.event({
              category: GACategory.Search,
              action: filter.productId
                ? GAActions
                  ? GAActions.searchInNavbarSubstance
                  : ''
                : GAActions
                ? GAActions.searchInNavbarText
                : '',
              label:
                filter.product +
                (filter.type
                  ? ` {type = ${
                      filter.type === AutocompleteProductType.Substance
                        ? 'Substance'
                        : filter.type === AutocompleteProductType.Brand
                        ? 'Brand'
                        : ''
                    }} `
                  : '') +
                (totalCount > 0
                  ? ' : Total returned (' + totalCount + ')'
                  : ' : Not found') +
                (filter.productId && ` [substance Id = ${filter.productId}]`),
              email,
              anonymousUserIdItem,
            });
          } else {
            ReactGA.event({
              category: GACategory.Search,
              action: filter.productId
                ? GAActions
                  ? GAActions.searchSubstance
                  : ''
                : GAActions
                ? GAActions.searchText
                : '',
              label:
                filter.product +
                (filter.type
                  ? ` {type = ${
                      filter.type === AutocompleteProductType.Substance
                        ? 'Substance'
                        : filter.type === AutocompleteProductType.Brand
                        ? 'Brand'
                        : ''
                    }} `
                  : '') +
                (totalCount > 0
                  ? ' : Total returned (' + totalCount + ')'
                  : ' : Not found') +
                (filter.productId && ` [substance Id = ${filter.productId}]`),
              email,
              anonymousUserIdItem,
            });
          }
        }
      }
    }
  } catch (e) {
    logger.error(
      'src/app/store/modules/marketplace/sagas.ts -> fetchMarketplaceItemsSaga',
      e
    );
  }
}

export function* watchFetchData() {
  if (process.env.TARGET !== 'browser') {
    return;
  }
  yield takeLatest(requestFetchMarketplaceItems, fetchMarketplaceItemsSaga);
}

export function* fetchSuccess() {
  try {
    if (process.env.TARGET !== 'browser' && process.env.NODE_ENV !== 'test') {
      return;
    }
    while (true) {
      const action: ReturnType<typeof fetchMarketplaceSuccess> = yield take(
        fetchMarketplaceSuccess
      );

      const { currentPage, totalCount } = action.payload;
      const substanceNames = ['Substance1', 'Substance2', 'Substance3'];
      const marketplaceItemList: NonNullable<
        Unpromisefy<ReturnType<typeof getMarketplaceItemsListQuery>>
      >['data']['marketplace_list'] = action.payload.marketplaceItemList;

      const productCache = toDictionary<any, Product>(
        marketplaceItemList
          .filter((x: any) => x.Product)
          .map((x: any) => convertToProduct(x.Product)),
        (v) => v.id
      );

      const marketplaceListItems: AnyListItem[] = marketplaceItemList.map(
        (item: MarketplaceItem) => {
          const obj = {
            ranking: item.ranking,
            productId: item.productId,
            updatedAt: item.updatedAt,
          };
          return obj;
        }
      );

      const brandSubstance = substanceNames.reduce((acc, substanceName) => {
        if (
          marketplaceItemList?.length &&
          marketplaceItemList[0]?.Product[substanceName]?.SubstanceBrands
            ?.length
        ) {
          const hash = {};
          marketplaceItemList[0]?.Product[
            substanceName
          ]?.SubstanceBrands.forEach(
            ({ brand_name }: { brand_name: string }) => {
              hash[brand_name?.toLowerCase().trim()] =
                marketplaceItemList[0]?.Product[substanceName].name;
            }
          );
          return {
            ...acc,
            ...hash,
          };
        }
        return acc;
      }, {});

      yield all([
        put(setHasFetched(true)),
        put(setIsFetching(false)),
        put(setBrandSubstance(brandSubstance)),
        put(setMarketplaceProductCache(productCache, { currentPage })),
        put(setMarketplaceItemsList(marketplaceListItems, { currentPage })),
        put(setTotalCount(totalCount)),
      ]);
    }
  } catch (err) {
    logger.error(
      'src/app/store/modules/marketplace/sagas.ts -> fetchSuccess',
      err
    );
  }
}

function* fetchMarketplaceItemFlow() {
  if (process.env.TARGET !== 'browser') {
    return;
  } else {
    while (true) {
      const action: ReturnType<typeof fetchMarketplaceItemsAction> = yield take(
        fetchMarketplaceItemsAction
      );
      const { client } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { marketplace }: IStore = yield select();
      yield put(
        requestFetchMarketplaceItems({
          client,
          filter: marketplace.filter,
        })
      );
    }
  }
}

function* watchSetFilterMarketplace() {
  if (process.env.TARGET !== 'browser') {
    return;
  }
  yield takeLatest(filterMarketplace, setFilterMarketplaceSaga);
}

function* setFilterMarketplaceSaga(
  action: ReturnType<typeof filterMarketplace>
) {
  const { filter, client } = action.payload;
  yield put(setMarketplaceFilter(filter));
  yield put(
    requestFetchMarketplaceItems({
      client,
      filter,
    })
  );
}

function* fetchMarketplaceProduct(action: ReturnType<typeof fetchProduct>) {
  if (process.env.TARGET !== 'browser') {
    return;
  } else {
    const { client, productId } = action.payload;
    if (client) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state = yield select();
      const authUser = state.auth.loggedInUser
        ? state.auth.loggedInUser
        : undefined;
      const isAdmin = authUser ? authUser.isAdmin : false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield getProductQuery({
        client,
        id: productId,
        isAdmin,
      });

      const product = response.data.products.map((x: any) =>
        convertToProduct(x)
      )[0];

      yield put(fetchProductSuccess(product));
    }
  }
}

function* watchFetchProductData() {
  if (process.env.TARGET !== 'browser') {
    return;
  }
  yield takeLatest(fetchProduct, fetchMarketplaceProduct);
}

export function* removeDeletedItem(
  itemId: number,
  type: string,
  origin?: number
) {
  if (process.env.TARGET !== 'browser') {
    return;
  } else {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state = yield select();
    const newList = [
      ...state.marketplace.marketplaceListItem.filter(
        (x: AnyListItem) =>
          !(
            x.productId === itemId &&
            type === MarketplaceItemType.product &&
            origin === TypeOrigin.licensing
          )
      ),
    ];

    yield put(
      setMarketplaceItemsList(newList, {
        currentPage: pageNrWhenPagingIsNotUsed,
      })
    );
  }
}

function* requestNewProductFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const { payload }: ReturnType<typeof requestNewProduct> = yield take(
        requestNewProduct
      );

      yield call(requestNewProductQuery, payload);
      yield put(
        setRequestMessage({
          type: 'success',
          message: 'Great! Your request has been successfully submited!',
        })
      );
    } catch (e) {
      logger.error(
        'src/app/store/modules/marketplace/sagas.ts -> requestNewProductFlow',
        e
      );
      yield put(
        setRequestMessage({
          type: 'error',
          message: 'There was an error while submiting your request.',
        })
      );
    }
  }
}

function* requestFetchTopProductsSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload,
      }: ReturnType<typeof requestFetchTopProducts> = yield take(
        requestFetchTopProducts
      );

      if (payload.client) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const state: IStore = yield select();

        yield put(setIsFetching(true));

        const response: Unpromisefy<
          ReturnType<typeof getMarketplaceTopItemsListQuery>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        > = yield call(getMarketplaceTopItemsListQuery, {
          client: payload.client,
          substanceName: payload.substanceName,
          substance2Name: payload.substance2Name,
          substance3Name: payload.substance3Name,
          brandName: payload.brandName,
          type: payload.type,
          productId: payload.productId,
          isAdmin: state.auth.loggedInUser
            ? state.auth.loggedInUser.isAdmin
            : false,
        } as Params<typeof getMarketplaceTopItemsListQuery>);

        if (!response) {
          throw new Error('GraphQL response is undefined');
        }
        const marketplaceItemList: IGetTopItemsListReturn['marketplace_list'] =
          response.data.marketplace_list;
        const productCache = {
          ...toDictionary<any, Product>(
            marketplaceItemList
              .filter((x) => x.Product)
              .map((x) => {
                return convertToProduct(x.Product);
              }),
            (v) => v.id
          ),
          ...state.marketplace.marketplaceProductCache,
        };

        const marketplaceTopProducts: AnyListItem[] = marketplaceItemList.map(
          (x: MarketplaceItem) => ({
            ranking: x.ranking,
            productId: x.productId,
            updatedAt: x.updatedAt,
          })
        );
        if (
          marketplaceItemList.filter((x: MarketplaceItem) => x.Product).length >
          0
        ) {
          yield put(
            setMarketplaceProductCache(productCache, {
              currentPage: pageNrWhenPagingIsNotUsed,
            })
          );
        }

        yield all([
          put(setHasFetched(true)),
          put(setIsFetching(false)),

          put(setTopProducts(marketplaceTopProducts)),
        ]);
      }
    } catch (e) {
      logger.error(
        'src/app/store/modules/marketplace/sagas.ts -> requestFetchTopProductsSaga',
        e
      );
    }
  }
}

function* requestFetchSubstanceSEOTextSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload,
      }: ReturnType<typeof requestFetchSubstanceSEOText> = yield take(
        requestFetchSubstanceSEOText
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(fetchSubstanceSEOTextByName, {
        text: payload.text,
        client: payload.client,
      });
      if (
        response &&
        response.data.substances.length > 0 &&
        payload.text !== ''
      ) {
        const substance = response.data.substances[0];
        yield put(
          setSubstanceSEOText(substance.seoText ? substance.seoText : '')
        );
      } else {
        yield put(setSubstanceSEOText(''));
      }

      if (response && response.data.brands.length > 0) {
        const brand = response.data.brands[0];
        yield put(setBrandNameSEO(brand?.name || ''));
      } else {
        yield put(setBrandNameSEO(''));
      }
      yield put(
        setSubstanceSEOable(
          sg(
            () => response.data.substances.length + response.data.brands.length,
            0
          ) > 0
        )
      );
    } catch (e) {
      logger.error(
        'src/app/store/modules/marketplace/sagas.ts -> requestFetchSubstanceSEOText',
        e
      );
    }
  }
}

function* requestFetchCountrySEOTextSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload,
      }: ReturnType<typeof requestFetchCountrySEOText> = yield take(
        requestFetchCountrySEOText
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(fetchCountrySEOTextByCode, {
        countryCode: payload.countryCode,
        client: payload.client,
      });
      if (response && response.data.countries.length > 0) {
        const country = response.data.countries[0];
        yield put(setCountrySEOText(country.seoText ? country.seoText : ''));
      } else {
        yield put(setCountrySEOText(''));
      }
    } catch (e) {
      logger.error(
        'src/app/store/modules/marketplace/sagas.ts -> requestFetchCountrySEOTextSaga',
        e
      );
    }
  }
}

export default function* rootSaga() {
  yield all([
    fork(fetchMarketplaceItemFlow),
    fork(fetchSuccess),
    fork(watchFetchData),
    fork(watchFetchProductData),
    fork(watchSetFilterMarketplace),
    fork(requestNewProductFlow),
    fork(requestFetchTopProductsSaga),
    fork(requestFetchSubstanceSEOTextSaga),
    fork(requestFetchCountrySEOTextSaga),
  ]);
}
