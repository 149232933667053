import { all, call, put, takeEvery } from '@redux-saga/core/effects';
import { isBrowser } from 'app/store/utils/is-browser';
import { logger } from 'app/utils/logger';
import { setFlag_directoriesListModalOpen } from '../flags/actions';
import * as A from './actions';
import { createCompanyContactQuery, requestDirectorylisting } from './query';

function* createCompanyContactFlow({
  payload,
}: ReturnType<typeof A.createCompanyContact>) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof createCompanyContactQuery>
    > = yield call(createCompanyContactQuery, payload);

    if (payload.values.isSellerLanding) {
      yield put(A.setAssetsModalOpen(true));
    } else {
      yield put(A.setContactModalOpen(true));
    }

    return res;
  } catch (e) {
    logger.error(
      'src/app/store/modules/companies-contacts/sagas.ts -> createCompanyContact',
      e
    );
  }
}

function* requestDirectoryListingSaga({
  payload,
}: ReturnType<typeof A.directoryCompanyListing>) {
  try {
    yield call(requestDirectorylisting, payload);
    yield put(setFlag_directoriesListModalOpen(false));
  } catch (e) {
    logger.error(
      'src/app/store/modules/companies-contacts/sagas.ts -> createCompanyContact',
      e
    );
  }
}
export default function* rootSaga() {
  if (!isBrowser()) {
    return;
  }
  yield all([
    takeEvery(A.createCompanyContact, createCompanyContactFlow),
    takeEvery(A.directoryCompanyListing, requestDirectoryListingSaga),
  ]);
}
