import styled from 'styled-components';

export const ErrorLine = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.errorMessage};
`;

export const ErrorIcon = styled.img`
  width: 10px;
  height: 9px;
  object-fit: contain;
`;

export const InputContainer = styled.div<any>`
  margin-top: ${(props) => (props.hasLabel ? '24px' : '0px')};
  max-width: 620px;
`;

export const Label = styled.label`
  font-size: 14px;
  color: ${(props) => props.theme.colors.grayText};
  width: 100%;
`;

interface InputWrapperProps {
  isMulti: boolean;
  error: boolean;
  zIndex: string;
  selected: boolean;
  placeholderColor?: string;
  placeholderFontSize?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  div,
  span {
    color: ${(props) => props.theme.colors.grayExtraDark}!important;
    font-weight: 400;
  }

  [class$='placeholder'] {
    color: ${(props) =>
      props.placeholderColor
        ? props.placeholderColor
        : props.theme.colors.muted}!important;
    font-family: 'Nunito Sans';
    ${(props) =>
      props.placeholderFontSize &&
      `font-size: ${props.placeholderFontSize}!important`}
  }

  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: ${(props) =>
    props.error
      ? `2px solid ${props.theme.colors.danger}`
      : `1px solid ${props.theme.colors.gray}`};

  ${(props) =>
    props.selected &&
    `border-bottom: 1px solid ${props.theme.colors.grayText};`}

  font-size: 14px;
  min-height: 36px;

  > div {
    width: 100%;
  }
  :focus-within {
    outline: 0;
    border-bottom: 2px solid ${(props) => props.theme.colors.accent};
  }

  > div > div {
    border: 0px;
    outline: none;
    border: 0;
    border-radius: 0;
    border-color: white;
    box-shadow: none;
    caret-color: ${(props) => props.theme.colors.accent};
    min-height: 32px;
    :hover {
      border-color: white;
      border: 0;
      box-shadow: none;
    }
    > div {
      padding-left: 0px;

      > div {
        flex-direction: 'row-reversed';
        font-size: 15px;
        color: ${(props) => props.theme.colors.grayExtraDark};
      }
    }

    > div:last-child {
      box-shadow: 0 4px 28px 0 rgba(53, 64, 82, 0.1);
    }
    > div + div {
      display: none;
    }

    ${(props) =>
      props.isMulti &&
      `

        > div > div> div{

          :not(:last-child){
         
            /*color: hsl(217, 21%, 26%) !important;
            color: ${props.theme.colors.grayExtraDark}!important;*/
          font-family: 'Nunito Sans';
            font-style: normal;
            background-color: ${props.theme.colors.primaryLight};
           }
           div + div:hover{
            background-color: ${props.theme.colors.danger};
    
           }
        }
   `}
  }
  z-index: ${(props) => (props.zIndex ? props.zIndex : '3')};
`;

export const SelectIcon = styled.img`
  width: 9px;
  height: 6px;
  object-fit: contain;
  position: absolute;
  right: 3px;
`;
