import React, { createContext, useState } from 'react';

export interface FeatureToggleContextType {
  enabledFeatures: string[];
  saveEnabledFeatures: (features: string) => void;
}

export const FeatureToggleContext = createContext<FeatureToggleContextType>(
  undefined!
);

const FeatureToggleProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [enabledFeatures, setEnabledFeatures] = useState<string[]>([]);

  const saveEnabledFeatures = (featureString = '') => {
    if (featureString.length === 0) {
      if (enabledFeatures.length !== 0) {
        setEnabledFeatures([]);
      }
    } else {
      const featuresFromUrl: string[] = featureString
        .split(',')
        .map((featureName) => featureName.trim());
      setEnabledFeatures(featuresFromUrl);
    }
  };

  const providerValue: FeatureToggleContextType = {
    enabledFeatures,
    saveEnabledFeatures,
  };

  return (
    <FeatureToggleContext.Provider value={providerValue}>
      {children}
    </FeatureToggleContext.Provider>
  );
};

export default FeatureToggleProvider;
