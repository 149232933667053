import ToolTip from 'app/components/tooltip/tooltip-component';
import React from 'react';
import styled, { css } from 'styled-components';

const InputContainer = styled.div<{ marginTop?: string }>`
  width: 100%;

  ${(props) =>
    props.marginTop &&
    props.marginTop !== '' &&
    `margin-top: ${props.marginTop};`}
`;

const ErrorLine = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

export const ErrorMessage = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors.errorMessage};
`;

const ErrorIcon = styled.img`
  width: 10px;
  height: 9px;
  object-fit: contain;
`;

const PasswordIcon = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: absolute;
  right: 14px;
  bottom: 13px;
  z-index: 1;
  cursor: pointer;
`;

const InputWrapperNew = styled.div`
  display: flex;
  flex-flow: column-reverse;
  position: relative;
`;

const LabelStyle = styled.label`
  transition: all 200ms ease;
  font-size: 15px;
  pointer-events: all;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  z-index: 1;
`;

const LabelLenghtStyle = styled.span`
  color: ${(props) => props.theme.colors.grayDark};
`;

interface IInputStyleBaseProps {
  error: boolean;
  floatingLabel: boolean;
  noBorder: boolean;
  background: string;
  inputMarginTop: string;
  isEmpty: boolean;
  maxLength?: number;
  maxRows?: number;
}
interface IInputStyleProps extends IInputStyleBaseProps {
  password: boolean;
}
export const InputStyle = styled.input<IInputStyleProps>`
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  background-color: ${(props) =>
    props.background !== '' ? props.background : props.theme.colors.white};
  z-index: 1;
  transition: transform 200ms ease;
  font-family: 'Nunito Sans';
  -webkit-appearance: none;
  width: 100%;
  padding: 15px 17px 18px 17px;
  padding-right: ${(props) => (props.password ? '37px' : '17px')};
  height: 50px;

  &:not([value='']) {
    border: ${(props) =>
      props.error
        ? `2px solid ${props.theme.colors.danger}`
        : `1px solid ${props.theme.colors.selectBorder}`};

    //font-weight: 400;
  }

  border: ${(props) =>
    props.error
      ? `2px solid ${props.theme.colors.danger}`
      : `1px solid ${props.theme.colors.grayLight}`};
  border-radius: 3px;
  margin-top: ${(props) =>
    props.inputMarginTop !== '' ? props.inputMarginTop : '1px'};

  :focus {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colors.selectBorder};
    ${(props) =>
      props.noBorder &&
      `
        border-bottom: 0px;
      `}
  }

  ${(props) =>
    props.floatingLabel &&
    `
      &:placeholder-shown + ${LabelStyle} {
        cursor: text;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125em) scale(1);
      }
      &:not(:placeholder-shown) + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
        color: ${props.theme.colors.grayText};
      }
      &:focus + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
        color: ${props.theme.colors.grayText};
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
        font-size: 15px;
        font-weight: normal;
        color: ${props.theme.colors.muted};
        font-family: 'Nunito Sans';
      }
    `}
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.muted};
    font-size: 15px;
    font-family: 'Nunito Sans';
  }
`;

export const InputStyleTextarea = styled.textarea<IInputStyleBaseProps>`
  color: ${(props) => props.theme.colors.grayExtraDark};
  background-color: ${(props) =>
    props.background !== '' ? props.background : props.theme.colors.white};
  z-index: 1;
  max-width: 100%;
  font-size: 15px;
  font-family: 'Nunito Sans';
  width: calc(100% - 34px);
  padding: 15px 17px 18px 17px;
  min-height: 70px;

  &:not(:empty) {
    border: ${(props) =>
      props.error
        ? `2px solid ${props.theme.colors.danger}`
        : `1px solid ${props.theme.colors.selectBorder}`};
  }

  border: ${(props) =>
    props.error
      ? `2px solid ${props.theme.colors.danger}`
      : `1px solid ${props.theme.colors.grayLight}`};
  border-radius: 3px;
  margin-top: ${(props) =>
    props.inputMarginTop !== '' ? props.inputMarginTop : '1px'};

  :focus {
    outline: 0;
    border: 1px solid ${(props) => props.theme.colors.selectBorder};
  }

  overflow: hidden;
  resize: none;

  ${(props) =>
    props.floatingLabel &&
    `
      &:placeholder-shown + ${LabelStyle} {
        cursor: text;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transform-origin: left bottom;
        transform: translate(0, 2.125em) scale(1);
      }
      &:not(:placeholder-shown) + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
        color: ${props.theme.colors.grayText};

      }
      &:focus + ${LabelStyle} {
        transform: translate(0, 0) scale(1);
        cursor: default;
      }
      ::-webkit-input-placeholder {
        opacity: 0;
        transition: inherit;
        font-size: 15px;
        font-weight: normal;
        font-family: 'Nunito Sans';
        color: ${props.theme.colors.muted};
        font: inherit;
      }
    `}
  ::-webkit-input-placeholder {
    color: ${(props) => props.theme.colors.muted};
    font-family: 'Nunito Sans';
    font: inherit;
    font-size: 15px;
  }
`;
const TextAreaWrap = styled.div<{ maxRows?: number }>`
  position: relative;
  pre {
    min-height: 85px;
    margin: 0;
    padding: 8px 8px 8px 0;
    font-size: 15px;
    font-family: 'Nunito Sans';
    word-break: break-word;
    white-space: pre-wrap;
    color: ${(props) => props.theme.colors.grayExtraDark};
  }
  ${({ maxRows }) => {
    const lineHeight = 1.4;
    if (maxRows) {
      return css`
        textarea,
        pre {
          line-height: ${lineHeight}em;
          max-height: ${maxRows * lineHeight}em !important;
        }
        textarea {
          overflow: auto !important;
          box-sizing: content-box;
        }
      `;
    }
  }}
`;

export interface Props {
  value?: string;
  placeholder?: string;
  label?: string;
  onChange: (text: string) => any;
  error?: string;
  multiline?: boolean;
  type?: string;
  showPasswordIcon?: boolean;
  onFocus?: () => any;
  onBlur?: () => any;
  floatingLabel?: boolean;
  noBorder?: boolean;
  tabindex?: string;
  background?: string;
  marginTop?: string;
  inputMarginTop?: string;
  onKeyPress?: (e: any) => any;
  tooltip?: string;
  startRows?: number;
  maxRows?: number;
  autoComplete?: string;
  id?: string;
  maxLength?: number;
  autocomplete?: string;
  browserAutocompleteEnabled?: boolean;
  className?: string;
}

enum InputType {
  password = 'password',
}

interface IState {
  seePassword: boolean;
  textLen: number;
}
interface IInputBaseProps {
  id?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  tabIndex?: number;
  maxLength?: number;
  autocomplete?: string;
  onBlur?(): any;
  onFocus?(): void;
  onKeyPress?(e: any): void;
}

class TextInput extends React.Component<Props, IState> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      seePassword: false,
      textLen: 0,
    };

    this.setSeePassword = this.setSeePassword.bind(this);
  }

  // eslint-disable-next-line
  public componentWillReceiveProps(nextProps: Props) {
    if (this.props.value && this.props.value !== '' && nextProps.value === '') {
      if (this.props.maxLength) {
        this.setState({ textLen: 0 });
      }
    }
  }

  public render() {
    const inputBaseStyleProps: IInputStyleBaseProps = {
      background: this.props.background || '',
      error: !!this.props.error,
      floatingLabel: !!this.props.floatingLabel,
      noBorder: !!this.props.noBorder,
      isEmpty: this.props.value === undefined || this.props.value === '',
      inputMarginTop: this.props.inputMarginTop
        ? this.props.inputMarginTop
        : '',
      maxLength: this.props.maxLength,
    };

    const inputBaseProps: IInputBaseProps = {
      id: this.props.id ? this.props.id : this.props.label,
      name: this.props.label,
      placeholder: this.props.floatingLabel
        ? ' '
        : this.props.placeholder || '',
      value: this.props.value,
      onFocus: () => this.props.onFocus && this.props.onFocus(),
      tabIndex:
        (this.props.tabindex && parseFloat(this.props.tabindex)) || undefined,
      onKeyPress: this.props.onKeyPress,
      onBlur: this.props.onBlur,
      maxLength: this.props.maxLength,
      autocomplete: this.props.autocomplete,
    };

    const InputItem = !this.props.multiline ? (
      <InputStyle
        className={this.props.className ? this.props.className : ''}
        {...inputBaseStyleProps}
        {...inputBaseProps}
        readOnly={
          this.props.type === InputType.password &&
          !this.props.browserAutocompleteEnabled
        }
        onFocus={(e) => {
          if (this.props.type === InputType.password) {
            e.target.removeAttribute('readonly');
          }
          if (this.props.onFocus) {
            this.props.onFocus();
          }
        }}
        id={this.props.id ? this.props.id : this.props.label}
        password={this.props.type === 'password'}
        type={this.state.seePassword ? 'text' : this.props.type}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          this.props.onChange(ev.target.value);
          this.setState({ textLen: ev.target.value.length });
        }}
        maxLength={this.props.maxLength}
        autoComplete={this.props.autoComplete}
      />
    ) : (
      <TextAreaWrap maxRows={this.props.maxRows}>
        <InputStyleTextarea
          {...inputBaseStyleProps}
          {...inputBaseProps}
          readOnly={
            this.props.type === InputType.password &&
            !this.props.browserAutocompleteEnabled
          }
          onFocus={(e) => {
            if (this.props.type === InputType.password) {
              e.target.removeAttribute('readonly');
            }
            if (this.props.onFocus) {
              this.props.onFocus();
            }
          }}
          maxLength={this.props.maxLength}
          id={this.props.id ? this.props.id : this.props.label}
          onChange={(ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            this.props.onChange(ev.target.value);
            this.setState({ textLen: ev.target.value.length });
          }}
        />
      </TextAreaWrap>
    );

    return (
      <InputContainer marginTop={this.props.marginTop}>
        <InputWrapperNew>
          {InputItem}
          <LabelStyle
            htmlFor={this.props.id ? this.props.id : this.props.label}
          >
            {this.props.label}
            {this.props.maxLength ? (
              <LabelLenghtStyle>
                {` (${
                  this.props.maxLength - this.state.textLen
                } characters left)`}
              </LabelLenghtStyle>
            ) : (
              ''
            )}
            {this.props.tooltip && <ToolTip tooltip={this.props.tooltip} />}
          </LabelStyle>
          {this.props.type === InputType.password &&
            this.props.showPasswordIcon && (
              <PasswordIcon
                onClick={() => {
                  this.setSeePassword(!this.state.seePassword);
                }}
                src={
                  this.state.seePassword
                    ? '/img/hide-password.svg'
                    : '/img/show-password.svg'
                }
              />
            )}
        </InputWrapperNew>

        {this.props.error !== undefined && this.props.error.length > 0 && (
          <ErrorLine>
            <ErrorMessage>{this.props.error}</ErrorMessage>
            <ErrorIcon src={'/img/formError.png'} />
          </ErrorLine>
        )}
      </InputContainer>
    );
  }

  private setSeePassword(seePassword: boolean): void {
    this.setState({ seePassword });
  }
}

const Components = {
  InputContainer,
  ErrorLine,
  ErrorMessage,
  ErrorIcon,
  PasswordIcon,
  InputWrapperNew,
  LabelStyle,
  InputStyle,
  InputStyleTextarea,
};

export { Components };
export default TextInput;
