import { IFragment } from '../../query-utils/fragment';
import { Company } from '../types';

export interface ICompanySettingsFragment {
  id: number;
  name: string;
  address?: string;
  contactPhone?: string;
  country?: string;
  website?: string;
  code?: string;
  vatCode?: string;
  annualTurnover?: string;
  numberOfEmployees?: string;
  summary?: string;
  annualRevenue?: string;
  salesChannels?: string;
  therapyAreas?: string;
  portfolioProductsList?: string;
}

export const companySettingsFragment: IFragment = {
  fragment: () => `
    fragment companySettingsFragment on companies {
      id: id
      name: name
      address: address
      contactPhone: contactPhone
      country: country
      website: website
      code: code
      vatCode: vat_code
      annualTurnover: annual_turnover
      numberOfEmployees: number_of_employees
    }
  `,
  name: 'companySettingsFragment',
};

export const companySettingsPersonalFragment: IFragment = {
  fragment: () => `
    fragment companySettingsPersonalFragment on companies_personal {
      id: id
      name: name
      address: address
      contactPhone: contactPhone
      country: country
      website: website
      code: code
      vatCode: vat_code
      annualTurnover: annual_turnover
      numberOfEmployees: number_of_employees
      summary: summary
      annualRevenue: annual_revenue
      salesChannels: sales_channels
      therapyAreas: therapy_areas
      portfolioProductsList: portfolio_products_list
    }
  `,
  name: 'companySettingsPersonalFragment',
};

export function convertToCompany(p: ICompanySettingsFragment): Company {
  return {
    id: p.id,
    name: p.name,
    address: p.address,
    contactPhone: p.contactPhone,
    country: p.country,
    website: p.website,
    code: p.code,
    vatCode: p.vatCode,
    annualTurnover: p.annualTurnover,
    numberOfEmployees: p.numberOfEmployees,
    summary: p.summary,
    annualRevenue: p.annualRevenue,
    salesChannels: p.salesChannels && p.salesChannels.split(','),
    therapyAreas: p.therapyAreas && p.therapyAreas.split(','),
    portfolioProductsList:
      p.portfolioProductsList && p.portfolioProductsList.split(','),
  } as Company;
}
