import React from 'react';

export const Flag: React.FC = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="13"
    viewBox="0 0 12 13"
    className="__status-flag"
  >
    <path
      fill="#E55757"
      fillRule="nonzero"
      d="M7.05 1.5L6.75 0H0v12.75h1.5V7.5h4.2L6 9h5.25V1.5z"
    />
  </svg>
);
