/* eslint-disable  */

import * as u from 'app/store/modules/query-utils';
import colors from 'app/styles/colors';
import sg from 'app/utils/safe-get';
import { convertToCompany } from '../../auth/fragments/company-settings';
import { Company, User } from '../../auth/types';
import {
  dealsFragment,
  convertToCase,
  IDealFragment,
} from '../../deal/fragments/deal';
import { ICompanyFragment } from '../../global/fragments/company';
import { RequestUtils, UserUtils } from '../../query-utils';
import { IFragment } from '../../query-utils/fragment';
import { IRequest } from '../types';

interface IUser {
  id: number;
  fullName: string;
  email: string;
}
export interface IRequestFragment {
  id: number;
  status: number;
  isPaid: number;
  dealType: number;
  market: string;
  estimatedAnualQuantity: string;
  leadingPriceLevel: string;
  expectSignContract: string;
  comments: string;
  createdAt: Date;
  updatedAt: Date;
  dealsGroup: string;
  sellerUpdatedAt: Date;
  buyerUpdatedAt: Date;
  adminArchivedAt: Date;
  sellerArchivedAt: Date;
  buyerArchivedAt: Date;
  Buyer: IUser;
  Seller: IUser;
  Broker: IUser;
  buyerCompanyId: number;
  sellerCompanyId: number;
  adminFirstSeen: Date;
  buyerFirstSeen: Date;
  sellerFirstSeen: Date;
  adminSeen: boolean;
  sellerSeen: boolean;
  buyerSeen: boolean;
  BuyerCompany: ICompanyFragment;
  SellerCompany: ICompanyFragment;
  counterProposal: {
    text: string;
  }[];
  adminApproved: boolean;
  recommendAlternativeSuppliers: boolean;
  dealId: number;
  Deal: IDealFragment;
  phase?: number;
  skippedReason?: number;
  createdBy?: number;
}
export const requestFragment: IFragment = {
  fragment: (isAdmin = false, isLoggedIn, includeDeals = false) => {
    return `
    fragment requestFragment on requests {
      ${RequestUtils.selectParams(
        ['id', 'id'],
        ['status', 'status'],
        ['is_paid', 'isPaid'],
        ['deal_type', 'dealType'],
        ['market', 'market'],
        ['estimated_anual_quantity', 'estimatedAnualQuantity'],
        ['leading_price_level', 'leadingPriceLevel'],
        ['expect_sign_contract', 'expectSignContract'],
        ['comments', 'comments'],
        ['created_at', 'createdAt'],
        ['updated_at', 'updatedAt'],
        ['deals_group', 'dealsGroup'],
        ['seller_updated_at', 'sellerUpdatedAt'],
        ['buyer_updated_at', 'buyerUpdatedAt'],
        isAdmin && ['admin_archived_at', 'adminArchivedAt'],
        ['seller_archived_at', 'sellerArchivedAt'],
        ['buyer_archived_at', 'buyerArchivedAt'],
        ['buyer_company_id', 'buyerCompanyId'],
        ['seller_company_id', 'sellerCompanyId'],
        ['admin_first_seen', 'adminFirstSeen'],
        ['buyer_first_seen', 'buyerFirstSeen'],
        ['seller_first_seen', 'sellerFirstSeen'],
        ['admin_seen', 'adminSeen'],
        ['seller_seen', 'sellerSeen'],
        ['buyer_seen', 'buyerSeen'],
        ['product_id', 'productId'],
        ['admin_approved', 'adminApproved'],
        ['recommend_alternative_suppliers', 'recommendAlternativeSuppliers'],
        ['deal_id', 'dealId'],
        ['phase', 'phase'],
        ['skipped_reason', 'skippedReason'],
        ['created_by', 'createdBy'],
        ['is_active', 'isActive'],
        includeDeals && ['Deal', true, dealsFragment],
        [
          'Buyer',
          true,
          UserUtils.selectParams(
            'id',
            ['full_name', 'fullName'],
            ['first_name', 'firstName'],
            ['last_name', 'lastName'],
            isAdmin && 'email'
          ),
        ],
        [
          'Seller',
          true,
          UserUtils.selectParams(
            'id',
            ['full_name', 'fullName'],
            ['first_name', 'firstName'],
            ['last_name', 'lastName'],
            isAdmin && 'email',
            isAdmin && ['Company',true, u.CompanyUtils.selectParams(
              'id',
              'name',
              ['annual_revenue', 'annualRevenue'],
              ['sales_channels', 'salesChannels'],
              ['portfolio_products_list', 'portfolioProductsList'],
              ['therapy_areas', 'therapyAreas'],
              ['number_of_employees', 'numberOfEmployees'],
              'summary',
              'segment'
            )]
          ),
        ],
        [
          'Broker',
          true,
          UserUtils.selectParams(
            'id',
            ['full_name', 'fullName'],
            ['first_name', 'firstName'],
            ['last_name', 'lastName'],
            isAdmin && 'email'
          ),
        ],
        [
          [
            'Messages',
            {
              // last admin
              limit: 1,
              order: u.DealMessagesUtils.orderBy('created_at', 'desc'),
              where: u.where(u.DealMessagesUtils._is_null('field_id')),
            },
          ],
          'lastMessage',
          u.DealMessagesUtils.selectParams([
            'is_admin_message',
            'adminMessage',
          ]),
        ],
        [
          [
            'Messages',
            {
              limit: 1,
              where: u.where(
                u._and(
                  u.DealMessagesUtils._is_null('field_id'),
                  u._and(
                    u.DealMessagesUtils._is_null('admin_reply_text'),
                    u._or(
                      u.DealMessagesUtils._eq('buyer_see', false),
                      u.DealMessagesUtils._eq('seller_see', false)
                    )
                  )
                )
              ),
            },
          ],
          'uncheckedMessages',
          'id',
        ],
        [
          [
            'Termsheets',
            {
              limit: 1,
              order: u.TermsheetUtils.orderBy('created_at', 'desc'),
            },
          ],
          'Termsheets',
          u.TermsheetUtils.selectParams(
            'id',
            'created_at',
            'target_markets',
            'annual_forecast',
            'net_license_fee',
            'gross_license_fee',
            'pricing_model',
            'net_selling_price_revenue_share_pct',
            'gross_selling_price_revenue_share_pct',
            'currency',
            'buyer_company_id',
            'buyer_user_id',
            'seller_user_id',
            'originated_date',
            'shared_date',
            'license_fee_commision_pct',
            'request_id'
          ),
        ]
      )}
    }
  `;
  },
  name: 'requestFragment',
};
export function convertToDeal(
  p: IRequestFragment,
  party: 'admin' | 'buyer' | 'seller'
): IRequest {
  return {
    id: p.id,
    status: p.status,
    isPaid: p.isPaid === 1,
    dealType: p.dealType,
    market: p.market,
    estimatedAnualQuantity: p.estimatedAnualQuantity,
    leadingPriceLevel: p.leadingPriceLevel,
    expectSignContract: p.expectSignContract,
    comments: p.comments,
    createdAt: p.createdAt,
    dealsGroup: p.dealsGroup,
    archivedAt:
      party === 'admin'
        ? p.adminArchivedAt
        : party === 'buyer'
        ? p.buyerArchivedAt
        : party === 'seller'
        ? p.sellerArchivedAt
        : undefined,
    Buyer: p.Buyer as User,
    Seller: p.Seller as User,
    Broker: p.Broker as User,
    buyerCompanyId: p.buyerCompanyId,
    sellerCompanyId: p.sellerCompanyId,

    BuyerCompany: p.BuyerCompany
      ? convertToCompany(p.BuyerCompany)
      : (p.BuyerCompany as Company),
    SellerCompany: p.SellerCompany
      ? convertToCompany(p.SellerCompany)
      : (p.SellerCompany as Company),
    updatedAt: p.updatedAt,
    buyerUpdatedAt: p.buyerUpdatedAt,
    sellerUpdatedAt: p.sellerUpdatedAt,
    messages: {
      totalCount: 0,
      messages: {},
    },
    counterProposal: sg(() => p.counterProposal[0].text, ''),
    color: (() => {
      switch (party) {
        case 'admin':
          return color(p.adminSeen, p.adminFirstSeen);
        case 'seller':
          return color(p.sellerSeen, p.sellerFirstSeen);
        case 'buyer':
        default:
          return color(p.buyerSeen, p.buyerFirstSeen);
      }
    })(),
    adminApproved: p.adminApproved,
    showBubble: false,
    recommendAlternativeSuppliers: !!p.recommendAlternativeSuppliers,
    dealId: p.dealId,
    Deal: p.Deal ? convertToCase(p.Deal, party) : undefined,
    phase: p.phase,
    skippedReason: p.skippedReason,
  };
}

function color(seen: boolean, firstSeen: Date): string {
  if (seen) {
    return 'white';
  }
  if (!firstSeen) {
    return colors.newDeal;
  }
  return colors.unseenMessage;
}
