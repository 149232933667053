import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { RequestUtils } from '../../query-utils';

export interface IRestoreArchivedDeal extends IWithApolloClient {
  id: number;
  party?: 'admin' | 'buyer' | 'seller';
}
export interface IRestoreArchivedDealReturn {
  update_requests: {
    returning: {
      id: number;
    }[];
  };
}
export async function restoreArchivedRequestQuery(p: IRestoreArchivedDeal) {
  try {
    if (!p.party) {
      throw new Error('Party must be set');
    }
    return await p.client.mutate<IRestoreArchivedDealReturn>({
      mutation: gql`
      mutation restoreArchived(${RequestUtils.params(['$id', 'Int'])}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('id', '$id'))}
          ${RequestUtils._set(
            p.party === 'admin' && ['admin_archived_at', null],
            p.party === 'buyer' && ['buyer_archived_at', null],
            p.party === 'seller' && ['seller_archived_at', null]
          )}
        ) {
          returning {
            ${RequestUtils.selectParams('id')}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: { id: p.id },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> restoreArchivedRequestQuery',
      e
    );
  }
}
