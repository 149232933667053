import { formatDateString } from 'app/utils/format-datestring';
import gql from 'graphql-tag';

import { IWithApolloClient } from 'app/store/modules/global/types';
import { logger } from 'app/utils/logger';
import { sg } from 'app/utils/safe-get';
import { TermsheetUtils } from '../../query-utils';
import { ICreateTermsheetData, ITermsheet } from '../types';

export interface ICreateTermSheet extends IWithApolloClient {
  data: ICreateTermsheetData;
}

export interface ICreateTermSheetReturn {
  insert_termsheets: {
    returning: ITermsheet[];
  };
}

export async function createTermSheetQuery({ client, data }: ICreateTermSheet) {
  const sharedDate = data?.shared_date?.toDateString();
  const originatedDate = data!.originated_date!.toDateString();

  try {
    // @ts-ignore
    return await client.mutate<ICreateTermSheetReturn>({
      mutation: gql`
        mutation insert_termsheets(${TermsheetUtils.params(
          ['$target_markets', 'String'],
          ['$annual_forecast', 'String'],
          ['$net_license_fee', 'float8'],
          ['$gross_license_fee', 'float8'],
          ['$pricing_model', 'Int'],
          ['$net_selling_price_revenue_share_pct', 'float8'],
          ['$gross_selling_price_revenue_share_pct', 'float8'],
          ['$currency', 'String'],
          ['$buyer_company_id', 'Int'],
          ['$seller_company_id', 'Int'],
          ['$buyer_user_id', 'Int'],
          ['$seller_user_id', 'Int'],
          ['$originated_date', 'timestamptz'],
          ['$shared_date', 'timestamptz'],
          ['$license_fee_commision_pct', 'float8'],
          ['$request_id', 'Int'],
          ['$delivery_destination', 'String'],
          ['$incoterm', 'String']
        )}) {
          insert_termsheets(${TermsheetUtils._objects([
            ['target_markets', '$target_markets'],
            ['annual_forecast', '$annual_forecast'],
            ['net_license_fee', '$net_license_fee'],
            ['gross_license_fee', '$gross_license_fee'],
            ['pricing_model', '$pricing_model'],
            [
              'net_selling_price_revenue_share_pct',
              '$net_selling_price_revenue_share_pct',
            ],
            [
              'gross_selling_price_revenue_share_pct',
              '$gross_selling_price_revenue_share_pct',
            ],
            ['currency', '$currency'],
            ['buyer_company_id', '$buyer_company_id'],
            ['seller_company_id', '$seller_company_id'],
            ['buyer_user_id', '$buyer_user_id'],
            ['seller_user_id', '$seller_user_id'],
            ['originated_date', '$originated_date'],
            ['shared_date', '$shared_date'],
            ['license_fee_commision_pct', '$license_fee_commision_pct'],
            ['request_id', '$request_id'],
            ['incoterm', '$incoterm'],
            ['delivery_destination', '$delivery_destination'],
          ])}) {
            returning {
              id
              created_at
              target_markets
              annual_forecast
              net_license_fee
              gross_license_fee
              pricing_model
              net_selling_price_revenue_share_pct
              gross_selling_price_revenue_share_pct
              currency
              buyer_company_id
              seller_company_id
              buyer_user_id
              seller_user_id
              originated_date
              shared_date
              license_fee_commision_pct,
              request_id,
              incoterm,
              delivery_destination
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        target_markets: sg(() => data.target_markets, null),
        annual_forecast: sg(() => data.annual_forecast, null),
        net_license_fee: sg(() => data.net_license_fee, null),
        gross_license_fee: sg(() => data.gross_license_fee, null),
        pricing_model: sg(() => data.pricing_model, null),
        net_selling_price_revenue_share_pct: sg(
          () => data.net_selling_price_revenue_share_pct,
          null
        ),
        gross_selling_price_revenue_share_pct: sg(
          () => data.gross_selling_price_revenue_share_pct,
          null
        ),
        currency: sg(() => data.currency, null),
        buyer_company_id: sg(() => data.buyer_company_id, null),
        seller_company_id: sg(() => data.seller_company_id, null),
        buyer_user_id: sg(() => data.buyer_user_id, null),
        seller_user_id: sg(() => data.seller_user_id, null),
        originated_date: originatedDate
          ? formatDateString(originatedDate)
          : null,
        shared_date: sharedDate ? formatDateString(sharedDate) : null,
        license_fee_commision_pct: sg(
          () => data.license_fee_commision_pct,
          null
        ),
        request_id: sg(() => data.request_id, null),
        incoterm: sg(() => data.incoterm, null),
        delivery_destination: sg(() => data.delivery_destination, null),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> createTermSheetQuery',
      e
    );
  }
}
