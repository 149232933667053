import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// custom imports
import {
  requestUserSettings,
  setBuyerQualificationAdminViewRequestId,
  setBuyerQualificationAdminViewModalOpen,
  updateBuyerInfoAction,
} from 'app/store/modules/auth/actions';
import { setBuyerQualificationModalOpen } from 'app/store/modules/auth/actions';
import {
  Company,
  defaultEmptyCompany,
  defaultEmptyUser,
  User,
} from 'app/store/modules/auth/types';
import { toTitleCase } from 'app/store/modules/global/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { AutocompleteProductType } from 'app/store/modules/marketplace/types';
import { fetchTherapyAreas } from 'app/store/modules/products/query';
import {
  fetchProductsSelect,
  filterProductsSelect,
} from 'app/store/modules/products/query';
import { IStore } from 'app/store/types';
import BuyerQualification from './buyer-qualification';

interface IDispatchProps {
  fetchTherapyAreas: (
    amount: number,
    text: string
  ) => Promise<ISelectItem<string>[]>;
  fetchSettings: () => any;
  submitForm: (user: User, company: Company, buyer?: User) => any;
  closeModal: () => any;
  fetchSubstance: (
    name: string,
    callback: (p: ISelectItem[]) => any
  ) => Promise<ISelectItem<string>[]>;
}
interface IStateProps {
  userSettings: User;
  company: Company;
  companyDeal?: Company;
  buyer?: User;
}
interface IOwnProps {
  name?: string;
  setName?: (name?: string) => any;
  summary?: string;
  setSummary: (summary?: string) => any;
  numberOfEmployees?: string;
  setNumberOfEmployees: (numberOfEmployees?: string) => any;
  annualRevenue?: string;
  setAnnualRevenue: (annualRevenue?: string) => any;
  salesChannels?: string[];
  setSalesChannels: (salesChannels?: string[]) => any;
  therapyAreas?: string[];
  setTherapyAreas: (therapyAreas?: string[]) => any;
  portfolioProductsList?: string[];
  setPortfolioProductsList: (portfolioProductsList?: string[]) => any;
  closeModal: (user: User, company: Company) => any;
  isAdminView?: boolean;
  companyDeal?: Company;
}
type ComposedProps = IOwnProps & IWithApolloClient & RouteComponentProps;
export type IProps = IStateProps &
  IDispatchProps &
  ComposedProps &
  RouteComponentProps;
export default withApollo<IOwnProps>(
  // @ts-ignore
  withRouter(
    //
    connect<IStateProps, IDispatchProps, ComposedProps, IStore>(
      (state) => {
        const request =
          state.auth.buyerInformationAdminViewDealId !== 0
            ? state.requests.dealsCache[
                state.auth.buyerInformationAdminViewDealId
              ]
            : undefined;
        let buyerCompany =
          request && request.BuyerCompany
            ? request.BuyerCompany
            : defaultEmptyCompany;
        let buyer = request && request.Buyer ? request.Buyer : defaultEmptyUser;
        if (!request) {
          buyer = state.dealDetails.buyer;
          buyerCompany = state.dealDetails.buyer?.Company;
        }

        return {
          isLoggedIn: state.auth.loggedIn,
          userSettings: state.auth.loggedInUser,
          company: state.auth.loggedInUser.Company,
          companyDeal: buyerCompany,
          buyer: buyer,
        };
      },
      (dispatch, ownProps) => ({
        fetchTherapyAreas: async (amount, text) => {
          const res = await fetchTherapyAreas({
            client: ownProps.client,
            amount,
            template: text,
          });
          if (!res) {
            return [];
          }
          return res.data.therapyAreas.map(
            (therapyArea: { value: any; label: string }) => ({
              value: therapyArea.value,
              label: toTitleCase(therapyArea.label),
            })
          );
        },
        fetchSubstance: async (name, callback) => {
          const res =
            !name || name.length === 0
              ? await fetchProductsSelect({
                  client: ownProps.client,
                  type: AutocompleteProductType.Substance,
                })
              : await filterProductsSelect({
                  client: ownProps.client,
                  text: name,
                  type: AutocompleteProductType.Substance,
                });

          if (!res) {
            callback([]);
            return [];
          }

          const r: ISelectItem[] = res.data.options.map((x: ISelectItem) => ({
            ...x,
            label: toTitleCase(x.label),
          }));
          callback(r);
          return r;
        },
        fetchSettings: () => {
          dispatch(requestUserSettings({ client: ownProps.client }));
        },
        submitForm: (buyer, company) => {
          // dispatch(updateSettings({ user, company, client: ownProps.client }));
          dispatch(
            updateBuyerInfoAction({ buyer, company, client: ownProps.client })
          );
        },
        closeModal: () => {
          dispatch(setBuyerQualificationModalOpen(false));
          dispatch(setBuyerQualificationAdminViewModalOpen(false));
          dispatch(setBuyerQualificationAdminViewRequestId(0));
        },
      })
    )(BuyerQualification)
  )
);
