import { isNull, isUndefined } from 'lodash';
import React, { useEffect } from 'react';

// custom imports
import GoToMyProducts from 'app/components/buttons/go-to-products-button';
import MarketplaceButton from 'app/components/buttons/marketplace-button';
import { ConditionalContainer } from 'app/components/misc/conditional-container';
import FirstTimePage from 'app/modules/general/first-time-page';
import AsyncSelect from 'app/components/inputs/async-select';
import Loader from 'app/components/loader';
import Modal from 'app/components/modals/modal';
import Pager from 'app/components/pagination/pager';
import { Body, Table, Th as _Th, Tr } from 'app/components/table';
import { useIsMountedRef } from 'app/helpers/mounted-ref';
import { useSelector } from 'app/helpers/redux';
import { IDeal } from 'app/store/modules/deal/types';
import { Breakpoint } from 'app/styles/breakpoint';
import { sg } from 'app/utils/safe-get';
import LinkSellerRequestDealModal from '../link-seller-request-deal-modal';
import { IProps } from './index';
import LargeTableItemContainer from './large-table-item';
import SmallTableItemContainer from './small-table-item';
import {
  RequestTableContainer,
  MarketplaceButtonContainer,
  Th,
} from './styles';

const RequestTable: React.FC<IProps> = (props) => {
  const requestsList = useSelector((state) => state.requests.dealsCache);
  const dealItem: IDeal =
    !isNull(props.brokerSetDeal) && !isUndefined(props.brokerSetDeal)
      ? sg(() => {
          //
          return requestsList[props.brokerSetDeal].dealItem;
        }, null)
      : null;

  const broker = dealItem ? dealItem!.Broker : props.broker;

  const isMountedRef = useIsMountedRef();

  const renderDeals = (dealsListInner: number[]) => {
    return dealsListInner.map((item: number, index) => {
      return (
        <LargeTableItemContainer
          key={index}
          id={item}
          isAdmin={props.isAdmin}
          iAmBuying={props.iAmBuying}
          iAmSelling={props.iAmSelling}
          companyId={props.companyId}
        />
      );
    });
  };
  const renderSmallDealsTable = (dealsListInner: number[]) => {
    return dealsListInner.map((item: number, index) => {
      return (
        <SmallTableItemContainer
          key={index}
          id={item}
          isAdmin={props.isAdmin}
          iAmBuying={props.iAmBuying}
          iAmSelling={props.iAmSelling}
          companyId={props.companyId}
        />
      );
    });
  };

  useEffect(() => {
    if (isMountedRef.current && props.loggedIn) {
      props.fetchRequestsAction();
    }
  }, [props.companyId, props.loggedIn]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.dealList]);

  const isSmallTable =
    props.breakpoint === Breakpoint.Small ||
    props.breakpoint === Breakpoint.ExtraSmall;

  const newProductButtonWidth = 200;
  const newProductHeight = 50;
  const newProductFontSize = 18;
  return (
    <RequestTableContainer>
      <Loader
        key={'requests'}
        isLoading={props.isLoading || !props.hasFetched}
        firstLoad={!props.hasFetched}
      >
        <ConditionalContainer show={props.totalCount > 0}>
          <ConditionalContainer show={isSmallTable}>
            {renderSmallDealsTable(props.dealList)}
          </ConditionalContainer>
          <ConditionalContainer show={!isSmallTable}>
            <Table className="table">
              <Body>
                <ConditionalContainer show={!props.isAdmin}>
                  <Tr>
                    <Th>Product name, form, strength</Th>
                    <Th>Status</Th>
                    <Th>Last update</Th>
                    <Th>Market</Th>
                    <Th>Deal type</Th>
                    <Th>Action</Th>
                  </Tr>
                </ConditionalContainer>
                <ConditionalContainer show={props.isAdmin}>
                  <Tr>
                    <Th>Request ID</Th>
                    <Th>Created at</Th>
                    <Th>Product name, form, strength</Th>
                    <Th>Origin</Th>
                    <Th>Buyer</Th>
                    <Th>Seller</Th>
                    <Th>Status</Th>
                    <Th>Broker</Th>
                    <Th>Deal</Th>
                    <Th>Action</Th>
                  </Tr>
                </ConditionalContainer>
                {renderDeals(props.dealList)}
              </Body>
            </Table>
          </ConditionalContainer>
          <ConditionalContainer show={props.totalCount > 0}>
            <Pager
              page={props.page}
              totalCount={props.totalCount}
              pageSize={props.pageSize}
              setPage={props.setPage}
              setPageSize={props.setPageSize}
              onPageChange={props.fetchRequestsAction}
            />
          </ConditionalContainer>
        </ConditionalContainer>
        <ConditionalContainer show={props.totalCount <= 0}>
          <FirstTimePage
            childBold={
              props.iAmBuying
                ? 'You have no purchase deals yet'
                : 'You have no sales deals yet'
            }
            childSimple={
              props.iAmBuying
                ? 'Visit the pharmaceutical marketplace and search, make inquiries, negotiate or accept the offers.'
                : 'Make competitive offers for your products and thus increase the chances of successful requests.'
            }
            icon={
              props.iAmBuying
                ? '/img/group-8.svg'
                : '/img/selling-first-time.svg'
            }
            button={
              props.iAmBuying ? (
                <MarketplaceButtonContainer>
                  <MarketplaceButton />
                </MarketplaceButtonContainer>
              ) : (
                <MarketplaceButtonContainer>
                  <GoToMyProducts
                    width={newProductButtonWidth}
                    height={newProductHeight}
                    fontSize={newProductFontSize}
                  />
                </MarketplaceButtonContainer>
              )
            }
          />
        </ConditionalContainer>
      </Loader>

      <Modal
        isOpen={props.brokerSetDeal !== null && !dealItem}
        onClose={props.closeBrokerChange}
        className="broker-modal"
        header={<p className="header">Broker change</p>}
      >
        <AsyncSelect
          loadOptions={props.fetchBrokers}
          label={'Broker'}
          onChange={(val) => {
            if (!dealItem) {
              props.changeBroker(props.brokerSetDeal!, parseFloat(val.value));
              props.closeBrokerChange();
            }
          }}
          value={{
            label: sg(() => broker!.fullName, ''),
            value: sg(() => broker!.id.toString(), ''),
          }}
          placeholder={'Start typing for suggestions'}
        />
      </Modal>
      <LinkSellerRequestDealModal />
    </RequestTableContainer>
  );
};

export default RequestTable;
