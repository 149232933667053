import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';
import {
  DealUtils,
  CompanyUtils,
  SubstanceUtils,
  UserUtils,
} from '../../query-utils';
import { dealSchema } from '../../query-utils/schema';

export interface GetDealForLinkSelectProps extends IWithApolloClient {
  buyerId?: number;
}

export interface GetDealForLinkSelectReturn {
  deals: [];
}

export async function getRequestForLinkSelect(p: GetDealForLinkSelectProps) {
  try {
    if (!p.buyerId) {
      return undefined;
    }
    const result = await p.client.query<GetDealForLinkSelectReturn>({
      query: gql`
        ${new u.Query('getDealById')
          .params(['$buyerId', 'Int'])
          .query(dealSchema, 'deals')
          .select(
            'id',
            ['Substance2', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance3', true, SubstanceUtils.selectParams('id', 'name')],
            ['Substance1', true, SubstanceUtils.selectParams('id', 'name')],
            ['countries_of_interest', 'countriesOfInterest'],
            [
              'Buyer',
              true,
              UserUtils.selectParams(
                'id',
                ['full_name', 'fullName'],
                ['Company', true, CompanyUtils.selectParams('name')]
              ),
            ],
            [
              'Broker',
              true,
              UserUtils.selectParams(
                'id',
                ['full_name', 'fullName'],
                ['email', 'email']
              ),
            ],
            ['countries_of_interest', 'countriesOfInterest'],
            ['estimated_annual_quantity', 'estimatedAnnualQuantity'],
            ['target_supply_price', 'targetSupplyPrice'],
            ['deal_type', 'dealType'],
            ['expect_sign_contract', 'expectSignContract'],
            'comments',
            ['dose_form_category', 'doseFormCategory']
          )
          .where(() =>
            u._and(
              DealUtils._eq('buyer_users_id', '$buyerId'),
              DealUtils._is_null('deleted_at')
            )
          )
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        buyerId: p.buyerId,
      },
    });
    return sg(() => result!.data!.deals, undefined);
  } catch (e) {
    logger.error('src/app/store/modules/deal/query.ts -> getDealByIdQuery', e);
  }
}
