import { Components } from 'app/components/buttons/edit-item-blue-button/edit-item-blue-button';
import React, { useEffect, useState } from 'react';
import SaveButton from '../../../../components/buttons/save-button';
import Checkbox from '../../../../components/inputs/check-box';
import TextInput from '../../../../components/inputs/text-input';
import { ICommissionsPaid } from '../seller-information';
import { CancelButton, SellerCommissionContainerDiv } from '../styles';
import {
  AddItemContainer,
  BoldTextContainer,
  CancelButtonContainer,
  CommissionFormActions,
  CommonDealByTextContainer,
  DoesNotPayTextContainer,
  EditCommissionContainer,
  EditInputFieldContainer,
  EditLicenseAndSupplyFeeContainer,
  EditPriceContainer,
  LabelPreAgreedContainer,
  LeftCommissionInfoContainer,
  MainCommissionInfoContainer,
  PercentageContainer,
  PercentageLabel,
  PriceAndFeeDivContainer,
  PriceAndFeeTextContainer,
  RightCommissionInfoContainer,
  SaveButtonContainer,
  SellerCommissionContainer,
  SellerPaysCommissionText,
} from '../styles';
const { Label, AddItemIcon } = Components;

interface Props {
  onClick?: () => any;
  label?: string;
}
const EditItemBlueButton: React.FC<Props> = ({ onClick, label }) => {
  return (
    <AddItemContainer onClick={onClick}>
      <AddItemIcon src={'/img/edit-pen.svg'} />
      {label !== undefined && <Label htmlFor={label}>{label}</Label>}
    </AddItemContainer>
  );
};
interface IProps {
  commissions?: ICommissionsPaid | undefined;
  updateSellerCommission: (
    companyId: number,
    sellerPaysCommission: boolean,
    licenseFee: number,
    supplyPrice: number
  ) => any;
  updateCache: any;
}
const SellerCommission: React.FC<IProps> = ({
  commissions,
  updateSellerCommission,
  updateCache,
}) => {
  const [
    checkedSellerPaysCommission,
    setCheckedSellerPaysCommission,
  ] = useState<boolean>(false);
  const [editCommissionOpen, setEditCommissionOpen] = useState<boolean>(false);
  const [editLicenseFee, setEditLicenseFee] = useState<string>('');
  const [editSupplyPrice, setEditSupplyPrice] = useState<string>('');
  let paysCommissionText = '';
  let licenseFeeText = 'on a case by case basis';
  let supplyPriceText = 'on a case by case basis';
  let commonDealByText = '';
  let licenseFeeValue = '';
  let supplyPriceValue = '';
  const paysCommission = commissions!.sellerPaysCommission;
  let licenseFee = commissions!.licenseFee;
  let supplyPrice = commissions!.supplyPrice;
  paysCommissionText = paysCommission
    ? 'Seller pays commissions'
    : 'Seller does not pay commissions';

  if (licenseFee) {
    licenseFeeText = 'on the license fee';
    licenseFeeValue = licenseFee + ' %';
  }

  if (supplyPrice) {
    supplyPriceText = ' on the supply price';
    supplyPriceValue = supplyPrice + ' %';
  }
  if (!licenseFee && !supplyPrice) {
    commonDealByText = 'on a case by case basis';
  } else {
    commonDealByText = '';
  }
  useEffect(() => {
    if (paysCommission) {
      setCheckedSellerPaysCommission(true);
    }
    if (licenseFee) {
      setEditLicenseFee(licenseFee.toString());
    }
    if (supplyPrice) {
      setEditSupplyPrice(supplyPrice.toString());
    }
  }, []);
  const submit = () => {
    updateSellerCommission(
      commissions!.companyId,
      checkedSellerPaysCommission,
      editLicenseFee && checkedSellerPaysCommission
        ? parseInt(editLicenseFee as string, 10)
        : 0,
      editSupplyPrice && checkedSellerPaysCommission
        ? parseInt(editSupplyPrice as string, 10)
        : 0
    );
    setEditCommissionOpen(!editCommissionOpen);
  };
  const handleReset = () => {
    setEditCommissionOpen(!editCommissionOpen);
    setCheckedSellerPaysCommission(false);
    setEditSupplyPrice('');
    setEditLicenseFee('');
  };
  const toggleEditCommission = () => {
    setEditCommissionOpen(!editCommissionOpen);
    if (editCommissionOpen) {
      setCheckedSellerPaysCommission(checkedSellerPaysCommission);
      setEditLicenseFee(licenseFee!.toString());
      setEditSupplyPrice(supplyPrice!.toString());
    } else {
      setCheckedSellerPaysCommission(!!commissions!.sellerPaysCommission);
      setEditLicenseFee(
        commissions!.licenseFee ? commissions!.licenseFee.toString() : ''
      );
      setEditSupplyPrice(
        commissions!.supplyPrice ? commissions!.supplyPrice.toString() : ''
      );
    }
  };
  const validateFloatNumber = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    // eslint-disable-next-line
    if (/[^0-9\.]/.test(keyValue)) {
      event.preventDefault();
    }
  };
  const validateCheckedCommission = (event: any) => {
    setCheckedSellerPaysCommission(event);
    if (!event) {
      setEditSupplyPrice('');
      licenseFee = 0;
      supplyPrice = 0;
      setEditLicenseFee('');
    } else {
      setEditSupplyPrice(supplyPrice!.toString());
      setEditLicenseFee(licenseFee!.toString());
    }
  };
  return (
    <SellerCommissionContainerDiv>
      {!editCommissionOpen && (
        <SellerCommissionContainer paysCommission={!!paysCommission}>
          <MainCommissionInfoContainer>
            <LeftCommissionInfoContainer>
              {paysCommission && (
                <SellerPaysCommissionText>
                  {paysCommissionText}
                </SellerPaysCommissionText>
              )}
              {!paysCommission && (
                <DoesNotPayTextContainer>
                  <SellerPaysCommissionText>Seller</SellerPaysCommissionText>
                  <BoldTextContainer>does not pay </BoldTextContainer>
                  <SellerPaysCommissionText>
                    commissions
                  </SellerPaysCommissionText>
                </DoesNotPayTextContainer>
              )}
              {paysCommission && commonDealByText !== '' && (
                <CommonDealByTextContainer>
                  {commonDealByText}
                </CommonDealByTextContainer>
              )}
              {paysCommission && commonDealByText === '' && (
                <>
                  <PriceAndFeeDivContainer>
                    {licenseFeeValue && (
                      <>
                        <BoldTextContainer>
                          {licenseFeeValue}{' '}
                        </BoldTextContainer>{' '}
                        <PriceAndFeeTextContainer>
                          {licenseFeeText}
                        </PriceAndFeeTextContainer>
                      </>
                    )}
                  </PriceAndFeeDivContainer>
                  <PriceAndFeeDivContainer>
                    {supplyPriceValue && (
                      <>
                        <BoldTextContainer>
                          {supplyPriceValue}
                        </BoldTextContainer>{' '}
                        <PriceAndFeeTextContainer>
                          {supplyPriceText}
                        </PriceAndFeeTextContainer>
                      </>
                    )}
                  </PriceAndFeeDivContainer>
                </>
              )}
            </LeftCommissionInfoContainer>
            <RightCommissionInfoContainer>
              <EditItemBlueButton
                label={'Edit'}
                onClick={() => toggleEditCommission()}
              />
            </RightCommissionInfoContainer>
          </MainCommissionInfoContainer>
        </SellerCommissionContainer>
      )}
      {editCommissionOpen && (
        <>
          <EditCommissionContainer>
            <Checkbox
              marginTop="0px"
              fontSize={'15px'}
              lineHeight={'24px'}
              label="Seller pays commissions"
              value={checkedSellerPaysCommission}
              onChange={(val) => {
                validateCheckedCommission(val);
              }}
            />
            <LabelPreAgreedContainer>
              Pre-agreed commissions in the agency agreement
            </LabelPreAgreedContainer>
            <EditLicenseAndSupplyFeeContainer>
              <EditPriceContainer>
                <EditInputFieldContainer>
                  <TextInput
                    textAlign="right"
                    label="License fee"
                    type="text"
                    id="licenseFeeEdit"
                    value={editLicenseFee ? editLicenseFee!.toString() : ''}
                    onChange={(val) => setEditLicenseFee(val)}
                    onKeyPress={(e) => validateFloatNumber(e)}
                    browserAutocompleteEnabled={false}
                  />
                </EditInputFieldContainer>
                <PercentageContainer>
                  <PercentageLabel>%</PercentageLabel>
                </PercentageContainer>
              </EditPriceContainer>
              <EditPriceContainer>
                <EditInputFieldContainer>
                  <TextInput
                    textAlign="right"
                    label="Supply price"
                    type="text"
                    id="supplyPriceEdit"
                    value={editSupplyPrice ? editSupplyPrice!.toString() : ''}
                    onChange={(val) => setEditSupplyPrice(val)}
                    onKeyPress={(e) => validateFloatNumber(e)}
                    browserAutocompleteEnabled={false}
                  />
                </EditInputFieldContainer>
                <PercentageContainer>
                  <PercentageLabel>%</PercentageLabel>
                </PercentageContainer>
              </EditPriceContainer>
            </EditLicenseAndSupplyFeeContainer>
            <CommissionFormActions>
              <CancelButtonContainer>
                <CancelButton onClick={() => handleReset()}>
                  Cancel
                </CancelButton>
              </CancelButtonContainer>
              <SaveButtonContainer>
                <SaveButton onClick={() => submit()} />
              </SaveButtonContainer>
            </CommissionFormActions>
          </EditCommissionContainer>
        </>
      )}
    </SellerCommissionContainerDiv>
  );
};

export default SellerCommission;
