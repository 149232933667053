import ToolTip from 'app/components/tooltip/tooltip-component';
import colors from 'app/styles/colors';
import React, { useRef } from 'react';
import { Async } from 'react-select';
import {
  ErrorIcon,
  ErrorLine,
  ErrorMessage,
  InputContainer,
  InputWrapper,
  Label,
  SelectIcon,
} from './styles';

export interface Props<T extends string | number = string> {
  value?: ISelectItem<T>;
  values?: ISelectItem<T>[];
  label?: string;
  placeholder?: string;
  placeholderColor?: string;
  placeholderFontSize?: string;
  onChange?: (value: ISelectItem<T>) => any;
  onChangeMulti?: (value: ISelectItem<T>[]) => any;
  loadOptions: (
    inputValue: string,
    callback: (options: ISelectItem<T>[]) => any
  ) => any;
  isMulti?: boolean;
  error?: string;
  tabindex?: string;
  zIndex?: string;
  id?: string;
  isDisabled?: boolean;
  tooltip?: string;
  maxMenuHeight?: number;
  minCharsCountForLessStrictFilter?: number;
  autoFocus?: boolean;
  onKeyDown?: (event: any) => any;
  filterOption?: (val: any, search: string) => any;
}

const AsyncSelectInput: <T extends string | number = string>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  label,
  value,
  placeholder,
  onChange,
  loadOptions,
  isMulti,
  values,
  error,
  onChangeMulti,
  tabindex,
  zIndex,
  id,
  placeholderColor,
  placeholderFontSize,
  tooltip,
  maxMenuHeight,
  minCharsCountForLessStrictFilter,
  isDisabled,
  autoFocus,
  onKeyDown,
  filterOption,
}) => {
  const ref = useRef<any>(null);
  return (
    <InputContainer hasLabel={label !== undefined && label !== ''}>
      <Label htmlFor={label}>
        {label} {tooltip && <ToolTip tooltip={tooltip} />}
      </Label>
      <InputWrapper
        isMulti={isMulti ? true : false}
        error={error !== undefined && error !== ''}
        zIndex={zIndex ? zIndex : '3'}
        selected={
          (isMulti && values && values.length > 0) ||
          (!isMulti && value && value.label !== '')
            ? true
            : false
        }
        placeholderColor={placeholderColor}
        placeholderFontSize={placeholderFontSize}
      >
        <Async
          instanceId="dropdown"
          isDisabled={isDisabled}
          id={id}
          ref={ref}
          cacheOptions={false}
          isClearable={true}
          loadOptions={loadOptions}
          defaultOptions={true}
          maxMenuHeight={maxMenuHeight}
          inputId={`input-${id}`}
          minCharsCountForLessStrictFilter={minCharsCountForLessStrictFilter}
          filterOption={filterOption}
          onChange={(val: any) => {
            if (onChange && !isMulti) {
              onChange(val);
            }
            if (onChangeMulti && isMulti) {
              onChangeMulti(val);
            }
          }}
          placeholder={placeholder ? placeholder : ''}
          isMulti={isMulti}
          value={isMulti ? values : value}
          label={label}
          style={{
            option: (provided: any, state: any) => ({
              ...provided,
              color: state.isSelected ? colors.grayText : colors.white,
            }),

            control: (base: any, state: any) => ({
              ...base,
              border: state.isFocused ? 0 : 0,
              // This line disable the blue border
              boxShadow: state.isFocused ? 0 : 0,
              '&:hover': {
                border: state.isFocused ? 0 : 0,
              },
              fontSize: 14,
              color: colors.grayExtraDark,
              // You can also use state.isFocused to conditionally style based on the focus state
            }),
          }}
          theme={(theme: any) => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              text: colors.grayExtraDark,
              primary: colors.primaryLightHover,
              primary25: colors.primaryLight,
            },
          })}
          tabIndex={tabindex}
          autoFocus={autoFocus}
          onKeyDown={onKeyDown}
        />
        <SelectIcon
          src={'/img/selectDownIcon.svg'}
          onClick={() => {
            if (ref !== null) {
              ref.current.focus();
              ref.current.select.onMenuOpen();
            }
          }}
        />
      </InputWrapper>
      {error !== '' && error !== undefined && (
        <ErrorLine>
          <ErrorMessage>{error}</ErrorMessage>
          <ErrorIcon src={'/img/formError.png'} />
        </ErrorLine>
      )}
    </InputContainer>
  );
};

export const Components = {
  ErrorLine,
  ErrorMessage,
  ErrorIcon,
  InputContainer,
  Label,
  InputWrapper,
  SelectIcon,
};
export default AsyncSelectInput;
