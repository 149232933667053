import { createActionCreator as c } from '@reduxify/utils';
import { Notifications } from '../requests/types';
import { IWithApolloClient } from '../global/types';
import {
  ICompanySummary,
  IRemarks,
  ISellerContactInformation,
  ISellerInformation,
} from './types';

export const setSellerInformationModalOpen = c<boolean>(
  'sellerInformation/SET_SELLER_INFORMATION_MODAL_OPEN'
);
export const setSellerCompanyId = c<number>(
  'sellerInformation/SET_SELLER_COMPANY_ID'
);
export const setProductOwnerId = c<number | undefined>(
  'sellerInformation/SET_PRODUCT_OWNER_ID'
);
export const setSellerInformation = c<ISellerInformation>(
  'sellerInformation/SET_SELLER_INFORMATION'
);
export const setSellerContactInformation = c<ISellerContactInformation[]>(
  'sellerInformation/SET_CONTACT_PERSON'
);
interface IFetchSellerInformation extends IWithApolloClient {
  id: number;
}
export const fetchSellerInformation = c<IFetchSellerInformation>(
  'sellerInformation/FETCH_SELLER_INFORMATION'
);
interface IFetchSellerContactList extends IWithApolloClient {
  id: number;
}
export const fetchSellerContactList = c<IFetchSellerContactList>(
  'sellerInformation/FETCH_SELLER_CONTACT_LIST'
);
export const setNotification = c<Notifications>(
  'sellerInformation/SET_NOTIFICATIONS'
);
export const setUpdateCache = c<any>('sellerInformation/SET_UPDATE_CACHE');
export interface ISellerCommission extends IWithApolloClient {
  id: number;
  sellerPaysCommission: boolean;
  licenseFee: number;
  supplyPrice: number;
}
export const updateSellerCommission = c<ISellerCommission>(
  'sellerInformation/UPDATE_SELLER_COMMISSION'
);
export interface IEarlyBuyerDisclosureRequired extends IWithApolloClient {
  id: number;
  isEarlyBuyerDisclosureRequired: boolean;
}
export const updateClientNameRequired = c<IEarlyBuyerDisclosureRequired>(
  'sellerInformation/UPDATE_EARLY_BUYER_DISCLOSURE_REQUIRED'
);
export interface IIsCompanyHiddenStatus extends IWithApolloClient {
  id: number;
  isCompanyHidden: boolean;
}
export const updateCompanyHiddenStatus = c<IIsCompanyHiddenStatus>(
  'sellerInformation/UPDATE_COMPANY_HIDDEN_STATUS'
);
export interface ICDASignedInformation extends IWithApolloClient {
  id: number;
  isSellerCDASigned: boolean;
  sellerCDASignatureDate: Date | undefined;
  sellerCDAExpiryDate: Date | undefined;
}
export const updateCDASignedInformation = c<ICDASignedInformation>(
  'sellerInformation/UPDATE_CDA_SIGNED_INFORMATION'
);
export interface ISellerCompanyNameAndCountry extends IWithApolloClient {
  id: number;
  name: string;
  country: string;
}
export const updateSellerCompanyNameAndCountry = c<ISellerCompanyNameAndCountry>(
  'sellerInformation/UPDATE_COMPANY_NAME_AND_COUNTRY'
);
export interface ICPASignedInformation extends IWithApolloClient {
  id: number;
  isCPASigned: boolean;
  CPASignatureDate: Date | undefined;
}
export const updateCPASignedInformation = c<ICPASignedInformation>(
  'sellerInformation/UPDATE_CPA_SIGNED_INFORMATION'
);

export const updateRemarks = c<IRemarks>(
  'sellerInformation/UPDATE_ADMIN_REMARKS'
);
export const updateCompanySummary = c<ICompanySummary>(
  'sellerInformation/UPDATE_COMPANY_SUMMARY'
);
export interface ICreateSellerContact extends IWithApolloClient {
  companyId: number;
  userId?: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  assignedMarketsName?: string;
  assignMarket?: string;
}
export const createSellerContact = c<ICreateSellerContact>(
  'sellerInformation/CREATE_SELLER_CONTACT'
);
export interface IUpdateSellerContact extends IWithApolloClient {
  id: number;
  companyId: number;
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  assignedMarketsName?: string;
  assignMarket?: string;
}
export const updateSellerContact = c<IUpdateSellerContact>(
  'sellerInformation/UPDATE_SELLER_CONTACT'
);
