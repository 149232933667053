import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import { Notifications } from '../requests/types';
import * as A from './actions';
import { ISellerContactInformation, ISellerInformation, State } from './types';

export default combineReducers<State>({
  sellerInformation: cr<ISellerInformation | null>(
    null,
    reduce(A.setSellerInformation, set)
  ),
  sellerContacts: cr<ISellerContactInformation[]>(
    [],
    reduce(A.setSellerContactInformation, set)
  ),
  sellerInformationModalOpen: cr<boolean>(
    false,
    reduce(A.setSellerInformationModalOpen, set)
  ),
  sellerCompanyId: cr<number>(0, reduce(A.setSellerCompanyId, set)),
  error: cr<Notifications>(
    { listError: '', listSuccess: '', messageSendError: '' },
    reduce(A.setNotification, set)
  ),
  updateCache: cr<any>(null, reduce(A.setUpdateCache, set)),
  productOwnerId: cr<number | null>(null, reduce(A.setProductOwnerId, set)),
});
