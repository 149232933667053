import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { RequestUtils, UserUtils } from '../../query-utils';
import { ISetBrokerQueryReturn } from './set-broker-query';

export interface ISetBrokerByDealDealParams extends IWithApolloClient {
  dealId: number;
  brokerId: number;
}

export async function setBrokersByDealDealQuery(p: ISetBrokerByDealDealParams) {
  try {
    if (!p.dealId) {
      return;
    }
    return await p.client.mutate<ISetBrokerQueryReturn>({
      mutation: gql`
      mutation setBroker(${RequestUtils.params(
        ['$dealId', 'Int'],
        ['$brokerId', 'Int'],
        ['$date', 'timestamptz']
      )}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('deal_id', '$dealId'))}
          ${RequestUtils._set(['broker', '$brokerId'], ['updated_at', '$date'])}
        ) {
          returning {
            ${RequestUtils.selectParams('id', 'broker', [
              'Broker',
              true,
              UserUtils.selectParams(['full_name', 'fullName'], 'email'),
            ])}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        dealId: p.dealId,
        brokerId: p.brokerId,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> setBrokersByDealDealQuery',
      e
    );
  }
}
