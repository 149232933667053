import { _, media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import { classNames } from '../../../utils/class-names';

const MessagesLargeContainer = styled.div`
  display: flex;
  align-items: center;
  border-radius: 2px;
  justify-content: center;
  height: 26px;
  padding: 7px;
  cursor: pointer;
  background-color: ${(props) => props.theme.colors.primaryLight};
  &:not(&.disabled):hover {
    background-color: ${(props) => props.theme.colors.primaryLightHover};
  }
  width: fit-content;
  background-color: ${(props) => props.theme.colors.primaryLight};
  :hover {
    background-color: ${(props) => props.theme.colors.primaryLightHover};
  }
  ${_(media.max.sm)} {
    margin-left: 0px;
  }
`;

const MessagesIcon = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  margin: auto;
`;

const Label = styled.label`
  color: ${(props) => props.theme.colors.primary};
  font-size: 13px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-right: auto;
  cursor: pointer;
  margin-left: 5px;
  white-space: nowrap;
  ${_(media.max.md)} {
    display: none;
    margin-left: 0px;
  }
`;
const ShortLabel = styled(Label)`
  display: none;
  ${_(media.max.md)} {
    display: block;
  }
`;

interface Props {
  label: string;
  shortLabel: string;
  onClick?: () => any;
  disabled?: boolean;
}

const MessagesLargeButton: React.FC<Props> = ({
  label,
  onClick,
  shortLabel,
  disabled,
}) => {
  return (
    <MessagesLargeContainer
      onClick={onClick}
      className={classNames(!!disabled && 'disabled')}
    >
      <MessagesIcon src={'/img/3-h.svg'} />
      <Label htmlFor={label}>{label}</Label>
      <ShortLabel htmlFor={label}>{shortLabel}</ShortLabel>
    </MessagesLargeContainer>
  );
};

export const Components = {
  MessagesLargeContainer,
  MessagesIcon,
  Label,
  ShortLabel,
};
export default MessagesLargeButton;
