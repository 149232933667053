import { createReducer, reduce, set } from '@reduxify/utils';
import { OK } from 'http-status-codes';
import { combineReducers } from 'redux';
import { setStatusCode } from './actions';
import { State } from './types';

const statusCode = createReducer(OK, reduce(setStatusCode, set));

export default combineReducers<State>({
  statusCode,
});
