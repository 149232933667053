import { Substance } from 'app/store/modules/products/types';
import { RequestUtils } from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { User } from '../../auth/types';
import { IRequest } from '../../requests/types';
import { IWithApolloClient } from '../../global/types';
import {
  DealUtils,
  CompanyUtils,
  SubstanceUtils,
  UserUtils,
} from '../../query-utils';
import { DealFilter, MyDealFilterEnum, MyDealFilterStatusEnum } from '../types';

export interface IGetDeal extends IWithApolloClient {
  userId: number;
  isAdmin: boolean;
  isBroker: boolean;
  isBuyer: boolean;
  isSeller: boolean;
  limit: number;
  offset: number;
  filter: DealFilter;
  searchText?: string;
}
export interface IGetDealReturn {
  requests: {
    id: number;
    Buyer: User;
    Substance1: Substance;
    Substance2: Substance;
    Substance3: Substance;
    countriesOfInterest: string;
    Requests: IRequest[];
    createdAt: string;
    updatedAt: string;
  }[];
  ownDealsCount: { total: { count: number } };
  allDealsCount: { total: { count: number } };
}
export async function getDealQuery(p: IGetDeal) {
  try {
    // console.log('filter');
    // console.log(p.filter);

    const dealUserAndCompanyFilter: any = p.searchText
      ? DealUtils.nest(
          'Buyer',
          UserUtils._or(
            UserUtils._ilike(
              'first_name',
              UserUtils.escape(`%${p.searchText}%`)
            ),
            UserUtils._ilike(
              'last_name',
              UserUtils.escape(`%${p.searchText}%`)
            ),
            UserUtils._ilike(
              'full_name',
              UserUtils.escape(`%${p.searchText}%`)
            ),
            UserUtils._ilike('email', UserUtils.escape(`%${p.searchText}%`)),
            UserUtils._ilike('phone', UserUtils.escape(`%${p.searchText}%`)),
            UserUtils.nest(
              'Company',
              CompanyUtils._or(
                CompanyUtils._ilike(
                  'name',
                  CompanyUtils.escape(`%${p.searchText}%`)
                ),
                CompanyUtils._ilike(
                  'contactEmail',
                  CompanyUtils.escape(`%${p.searchText}%`)
                ),
                CompanyUtils._ilike(
                  'contactFirstName',
                  CompanyUtils.escape(`%${p.searchText}%`)
                ),
                CompanyUtils._ilike(
                  'contactLastName',
                  CompanyUtils.escape(`%${p.searchText}%`)
                )
              )
            )
          )
        )
      : null;
    // console.log('----1');

    const dealSellerUserCompanyFilter: any = p.searchText
      ? DealUtils.nest(
          'Requests',
          RequestUtils.nest(
            'Seller_company',

            CompanyUtils._or(
              CompanyUtils._ilike(
                'name',
                CompanyUtils.escape(`%${p.searchText}%`)
              )
            )
          )
        )
      : null;
    // console.log('----2');

    const dealSubstance1Filter: any = p.searchText
      ? DealUtils.nest(
          'Substance1',
          SubstanceUtils._ilike(
            'name',
            SubstanceUtils.escape(`%${p.searchText}%`)
          )
        )
      : null;
    const dealSubstance2Filter: any = p.searchText
      ? DealUtils.nest(
          'Substance2',
          SubstanceUtils._ilike(
            'name',
            SubstanceUtils.escape(`%${p.searchText}%`)
          )
        )
      : null;
    const dealSubstance3Filter: any = p.searchText
      ? DealUtils.nest(
          'Substance3',
          SubstanceUtils._ilike(
            'name',
            SubstanceUtils.escape(`%${p.searchText}%`)
          )
        )
      : null;
    // console.log('----3');

    const dealPhaseFilter: any =
      p?.filter.phase !== undefined && p.filter.phase > -1
        ? DealUtils._eq('deal_phase', p.filter.phase)
        : null;

    const statusFilter: any =
      p.filter.status === MyDealFilterStatusEnum.all
        ? null
        : DealUtils.nest('Requests', RequestUtils._eq('is_active', 'true'));

    // console.log('----4');

    console.log(
      DealUtils.where(
        DealUtils._and(
          dealPhaseFilter,
          statusFilter,
          // p.filter.phase
          //   ? DealUtils._eq('dealPhase', 'dealPhase')
          //   : '',
          DealUtils._or(
            dealUserAndCompanyFilter,
            dealSubstance1Filter,
            dealSubstance2Filter,
            dealSubstance3Filter,
            dealSellerUserCompanyFilter
          )
        )
      )
    );

    // console.log(
    //   'data',
    //   DealUtils.where(
    //     DealUtils._and(
    //       (p.filter?.broker === 0
    //         ? DealUtils._is_null('broker_id', true)
    //         : null) as any,
    //       (p.filter?.broker && p.filter?.broker !== undefined
    //         ? DealUtils._eq('broker_id', p.filter?.broker)
    //         : null) as any,

    //       dealPhaseFilter,
    //       statusFilter,
    //       // p.filter.phase
    //       //   ? DealUtils._eq('dealPhase', 'dealPhase')
    //       //   : '',
    //       DealUtils._or(
    //         dealUserAndCompanyFilter,
    //         dealSubstance1Filter,
    //         dealSubstance2Filter,
    //         dealSubstance3Filter,
    //         dealSellerUserCompanyFilter
    //       )
    //     )
    //   )
    // );

    return (await p.client.query({
      query: gql`

      query getDeals(${DealUtils.params(
        ['$userId', 'Int'],
        ['$limit', 'Int'],
        ['$offset', 'Int']
      )}) {

                requests: deals(
                ${DealUtils.limit('$limit')}
                ${DealUtils.offset('$offset')}
                ${DealUtils.orderBy('updated_at', 'desc')}
                ${
                  !p.isAdmin
                    ? DealUtils.where(
                        DealUtils._and(
                          DealUtils._eq('buyer_users_id', '$userId')
                        )
                      )
                    : DealUtils.where(
                        DealUtils._and(
                          (p.filter?.broker === 0
                            ? DealUtils._is_null('broker_id', true)
                            : null) as any,
                          (p.filter?.broker && p.filter?.broker !== undefined
                            ? DealUtils._eq('broker_id', p.filter?.broker)
                            : null) as any,

                          dealPhaseFilter,
                          statusFilter,
                          // p.filter.phase
                          //   ? DealUtils._eq('dealPhase', 'dealPhase')
                          //   : '',
                          DealUtils._or(
                            dealUserAndCompanyFilter,
                            dealSubstance1Filter,
                            dealSubstance2Filter,
                            dealSubstance3Filter,
                            dealSellerUserCompanyFilter
                          )
                        )
                      ).length > 0
                    ? DealUtils.where(
                        DealUtils._and(
                          (p.filter?.broker === 0
                            ? DealUtils._is_null('broker_id', true)
                            : null) as any,
                          (p.filter?.broker && p.filter?.broker !== undefined
                            ? DealUtils._eq('broker_id', p.filter?.broker)
                            : null) as any,

                          dealPhaseFilter,
                          statusFilter,
                          // p.filter.phase
                          //   ? DealUtils._eq('dealPhase', 'dealPhase')
                          //   : '',
                          DealUtils._or(
                            dealUserAndCompanyFilter,
                            dealSubstance1Filter,
                            dealSubstance2Filter,
                            dealSubstance3Filter,
                            dealSellerUserCompanyFilter
                          )
                        )
                      )
                    : ''
                }

                ) {
                ${DealUtils.selectParams(
                  ['id', 'id'],
                  p.isAdmin && [
                    'Buyer',
                    true,
                    UserUtils.selectParams(
                      'id',
                      ['full_name', 'fullName'],
                      ['first_name', 'firstName'],
                      ['last_name', 'lastName'],
                      ['email', 'email'],
                      ['phone', 'phone'],
                      [
                        'Company',
                        true,
                        CompanyUtils.selectParams('name', 'id', 'segment'),
                      ]
                    ),
                  ],

                  p.isAdmin && [
                    'Broker',
                    true,
                    UserUtils.selectParams(
                      'id',
                      ['full_name', 'fullName'],
                      ['email', 'email']
                    ),
                  ],
                  [
                    'Substance1',
                    true,
                    SubstanceUtils.selectParams('id', 'name'),
                  ],
                  [
                    'Substance2',
                    true,
                    SubstanceUtils.selectParams('id', 'name'),
                  ],
                  [
                    'Substance3',
                    true,
                    SubstanceUtils.selectParams('id', 'name'),
                  ],
                  ['countries_of_interest', 'countriesOfInterest'],
                  ['dose_form_category', 'doseFormCategory'],
                  ['created_at', 'createdAt'],
                  ['updated_at', 'updatedAt'],
                  ['deal_phase', 'dealPhase']
                )}
            },
      ownDealsCount: deals_aggregate ${
        DealUtils.where(
          DealUtils._and(
            (p.filter?.broker === 0
              ? DealUtils._is_null('broker_id', true)
              : null) as any,
            (p.filter?.broker && p.filter?.broker !== undefined
              ? DealUtils._eq('broker_id', p.filter?.broker)
              : null) as any,
            dealPhaseFilter,
            statusFilter,
            // p.filter.phase ? DealUtils._eq('dealPhase', 'dealPhase') : null,
            DealUtils._or(
              dealUserAndCompanyFilter,
              dealSubstance1Filter,
              dealSubstance2Filter,
              dealSubstance3Filter,
              dealSellerUserCompanyFilter
            )
          )
        ).length > 0
          ? `(${DealUtils.where(
              DealUtils._and(
                (p.filter?.broker === 0
                  ? DealUtils._is_null('broker_id', true)
                  : null) as any,
                (p.filter?.broker && p.filter?.broker !== undefined
                  ? DealUtils._eq('broker_id', p.filter?.broker)
                  : null) as any,
                dealPhaseFilter,
                statusFilter,
                // p.filter.phase ? DealUtils._eq('dealPhase', 'dealPhase') : null,
                DealUtils._or(
                  dealUserAndCompanyFilter,
                  dealSubstance1Filter,
                  dealSubstance2Filter,
                  dealSubstance3Filter,
                  dealSellerUserCompanyFilter
                )
              )
            )})`
          : ''
      } {
      total: aggregate { count }
      },
      allDealsCount: deals_aggregate ${
        p.filter.myRequests === MyDealFilterEnum.me
          ? `(${DealUtils.where(
              DealUtils._and(
                (p.filter?.broker === 0
                  ? DealUtils._is_null('broker_id', true)
                  : null) as any,
                (p.filter?.broker && p.filter?.broker !== undefined
                  ? DealUtils._eq('broker_id', p.filter?.broker)
                  : null) as any,
                dealPhaseFilter,
                p.isAdmin || p.isBroker ? statusFilter : null,
                // p.filter.phase ? DealUtils._eq('dealPhase', 'dealPhase') : null,
                DealUtils._or(
                  dealUserAndCompanyFilter,
                  dealSubstance1Filter,
                  dealSubstance2Filter,
                  dealSubstance3Filter
                )
              )
            )})`
          : `${
              DealUtils.where(
                DealUtils._and(
                  (p.filter?.broker === 0
                    ? DealUtils._is_null('broker_id', true)
                    : null) as any,
                  (p.filter?.broker && p.filter?.broker !== undefined
                    ? DealUtils._eq('broker_id', p.filter?.broker)
                    : null) as any,
                  dealPhaseFilter,
                  p.isAdmin || p.isBroker ? statusFilter : null,
                  // p.filter.phase ? DealUtils._eq('dealPhase', 'dealPhase') : '',
                  DealUtils._or(
                    dealUserAndCompanyFilter,
                    dealSubstance1Filter,
                    dealSubstance2Filter,
                    dealSubstance3Filter
                  )
                )
              ).length > 0
                ? `(${DealUtils.where(
                    DealUtils._and(
                      (p.filter?.broker === 0
                        ? DealUtils._is_null('broker_id', true)
                        : null) as any,
                      (p.filter?.broker && p.filter?.broker !== undefined
                        ? DealUtils._eq('broker_id', p.filter?.broker)
                        : null) as any,
                      dealPhaseFilter,
                      p.isAdmin || p.isBroker ? statusFilter : null,
                      // p.filter.phase ? DealUtils._eq('dealPhase', 'dealPhase') : '',
                      DealUtils._or(
                        dealUserAndCompanyFilter,
                        dealSubstance1Filter,
                        dealSubstance2Filter,
                        dealSubstance3Filter
                      )
                    )
                  )})`
                : ''
            }`
      } {
        total: aggregate { count }
      }
            }`,
      fetchPolicy: 'no-cache',
      variables: {
        // userId: p.userId,
        limit: p.limit,
        offset: p.offset,
      },
    })) as IGetDealReturn;
  } catch (e) {
    logger.error('src/app/store/modules/deal/query.ts -> getDealQuery', e);
  }
}
