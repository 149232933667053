import * as Sentry from '@sentry/browser';
import { formatDate } from 'app/utils/format-datestring';
import React from 'react';

interface IState {
  hasError: boolean;
  eventId: any;
}
export default class ErrorBoundary extends React.Component<any, IState> {
  public static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, eventId: null };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    // eslint-disable-next-line no-console
    console.error(
      `${formatDate(new Date(), 'HH:mm:ss.SSS')} Unhandled error`,
      ...error,
      ...errorInfo
    );
    if (this.props.reportToSentry) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({
          eventId,
        });
      });
    }
  }

  public render() {
    if (this.state.hasError) {
      return (
        <>
          <h1>Something went wrong.</h1>
          {this.props.reportToSentry && (
            <button
              onClick={() =>
                Sentry.showReportDialog({ eventId: this.state.eventId })
              }
            >
              Report feedback
            </button>
          )}
        </>
      );
    }
    return this.props.children;
  }
}
