import * as u from 'app/store/modules/query-utils';
import sg from 'app/utils/safe-get';
import { addSeconds } from 'date-fns';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import {
  companyFragment,
  ICompanyFragment,
} from '../../global/fragments/company';
import { IWithApolloClient } from '../../global/types';
import { EEditFields } from '../../requests/types';
import { RequestUtils } from '../../query-utils';
import { dealMessageSchema } from '../../query-utils/schema';
import { requestFragment, IRequestFragment } from '../fragments/request';
import { getLastMessageGroup } from '../messages/query';
import { DealPhases, EDealStatus, ICreateProductDeal } from '../types';

export interface ICreateDeal extends IWithApolloClient {
  values: ICreateProductDeal;
  currentUserId: number;
  currentUserCompanyId: number;
  isAdmin: boolean;
}
export interface ICreateRequestReturn {
  insert_requests: {
    returning: (IRequestFragment & {
      BuyerCompany: ICompanyFragment;
      SellerCompany: ICompanyFragment;
    })[];
  };
}
export async function createRequestQuery(p: ICreateDeal) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ret = await p.client.mutate<ICreateRequestReturn>({
      mutation: gql`
      ${companyFragment.fragment(p.isAdmin)}
      ${requestFragment.fragment(p.isAdmin)}

      mutation insert_requests(${u.params(
        ['$status', 'Int'],
        ['$isPaid', 'Int'],
        ['$deal_type', 'Int'],
        ['$market', 'String'],
        ['$estimatedAnualQuantity', 'String'],
        ['$leadingPriceLevel', 'String'],
        ['$expectSignContract', 'String'],
        ['$comments', 'String'],
        ['$product_id', 'Int'],
        ['$buyer_user_id', 'Int'],
        ['$buyer_company_id', 'Int'],
        ['$seller_user_id', 'Int'],
        ['$seller_company_id', 'Int'],
        ['$broker', 'Int'],
        ['$product_trade_id', 'Int'],
        ['$buyer_seen', 'Boolean'],
        ['$currentDate', 'timestamptz'],
        ['$deals_group', 'String'],
        ['$phase', 'Int'],
        ['$created_by', 'Int']
      )}) {
        insert_requests(
          ${RequestUtils._objects([
            ['status', '$status'],
            ['is_paid', '$isPaid'],
            ['deal_type', '$deal_type'],
            ['market', '$market'],
            ['estimated_anual_quantity', '$estimatedAnualQuantity'],
            ['leading_price_level', '$leadingPriceLevel'],
            ['expect_sign_contract', '$expectSignContract'],
            ['comments', '$comments'],
            ['product_id', '$product_id'],
            ['buyer_user_id', '$buyer_user_id'],
            ['buyer_company_id', '$buyer_company_id'],
            ['seller_user_id', '$seller_user_id'],
            ['seller_company_id', '$seller_company_id'],
            ['broker', '$broker'],
            ['buyer_seen', '$buyer_seen'],
            ['buyer_first_seen', u.escape(new Date().toISOString())],
            ['created_at', '$currentDate'],
            ['updated_at', '$currentDate'],
            ['buyer_updated_at', '$currentDate'],
            ['seller_updated_at', '$currentDate'],
            ['deals_group', '$deals_group'],
            ['phase', '$phase'],
            ['created_by', '$created_by'],
          ])}) {
          returning {
            ${RequestUtils.selectParams(
              requestFragment,
              ['Buyer_company', 'BuyerCompany', companyFragment],
              ['Seller_company', 'SellerCompany', companyFragment]
            )}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        status: EDealStatus.Requested,
        isPaid: 0,
        deal_type: p.values.dealType,
        market: p.values.market,
        estimatedAnualQuantity: p.values.estimatedAnualQuantity,
        leadingPriceLevel: p.values.leadingPriceLevel,
        expectSignContract: p.values.expectSignContract,
        comments: p.values.comments,
        product_id: sg(() => p.values.product!.id, null),
        buyer_company_id: p.currentUserCompanyId,
        seller_user_id: sg(
          () =>
            p.values.product && p.values.product.createdBy
              ? p.values.product.createdBy
              : null,
          null
        ),
        seller_company_id: sg(
          () => (p.values.product ? p.values.product.Company.id : null),
          null
        ),
        broker: null,
        buyer_seen: true,
        deals_group: p.values.dealsGroup,
        currentDate: new Date().toISOString(),
        phase: DealPhases['Requesting offer'],
        buyer_user_id: p.currentUserId,
        created_by: p.currentUserId,
      },
    });

    await getLastMessageGroup({
      client: p.client,
      id: ret.data!.insert_requests.returning[0].id,
    }).then((g) => {
      const date = new Date();
      return p.client.mutate({
        mutation: gql`
          ${new u.Mutation('requestSuccessMessage')
            .params(
              ['$id', 'Int'],
              ['$empty', 'String'],
              ['$sender', 'Int'],
              ['$senderCompany', 'Int'],
              ['$buyerCompanyId', 'Int'],
              ['$sellerCompanyId', 'Int']
              // ['$group', 'Int']
            )
            .insert(dealMessageSchema, 'msg')
            .objects(
              [
                ['request_id', '$id'],
                ['text', '$empty'],
                ['sender_id', '$sender'],
                ['sender_company_id', '$senderCompany'],
                ['buyer_see', true],
                ['seller_see', false],
                ['buyer_company_id', '$buyerCompanyId'],
                ['seller_company_id', '$sellerCompanyId'],
                ['group_id', g.lastGroup + 1],
                ['created_at', u.escape(date.toISOString())],
                ['field_id', EEditFields.ProductRequestSuccessfull],
              ],
              [
                ['request_id', '$id'],
                ['text', '$empty'],
                ['sender_id', '$sender'],
                ['sender_company_id', '$senderCompany'],
                ['buyer_see', true],
                ['seller_see', false],
                ['buyer_company_id', '$buyerCompanyId'],
                ['seller_company_id', '$sellerCompanyId'],
                ['group_id', g.lastGroup + 1 + 1],
                ['created_at', u.escape(addSeconds(date, 1).toISOString())],
                ['field_id', EEditFields.ProductRequestInformation],
              ]
            )
            .returning('id')
            .toString()
            .toString()}
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id: ret.data!.insert_requests.returning[0].id,
          empty: '',
          senderCompany: p.currentUserCompanyId,
          buyerCompanyId: g.buyerCompany,
          sellerCompanyId: g.sellerCompany,
          // group: g.lastGroup + 1,
          sender: p.currentUserId,
        },
      });
    });
    return ret;
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> createRequestQuery',
      e
    );
  }
}
