import { EU28Countries } from 'app/store/modules/global/types';
import { newProductsDate } from 'app/store/modules/marketplace/query';
import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';

export interface IGetManufacturerTypesInCountryReturn {
  substances: [
    {
      Substance1?: {
        name?: string;
      };
      Substance2?: {
        name?: string;
      };
      Substance3?: {
        name?: string;
      };
    }
  ];
  products_v2_aggregate: {
    aggregate?: {
      totalCount?: number;
    };
  };
}
export async function getManufacturerTypesInCountryQuery(
  p: IWithApolloClient<{ countryId: string; pageNr: number; pageSize: number }>
) {
  try {
    const euCountry = EU28Countries.find(
      (eu28Country) => eu28Country.id === p.countryId
    );
    const notDeleted = u._is_null('deleted_at');
    const companyWhere = u.nest('Company', u._eq('hidden', false));
    const distinctClause = u.distinct('substance_1 substance_2 substance_3');
    const oldFilters = u._or(u._gte('created_at', '$newProductsDate'));
    const countryFilter = u._or(
      u._ilike('finished_dose_country', u.escape(`%${p.countryId}%`)),
      euCountry && u._eq('finished_dose_country_eu', true)
    );
    const whereClause = u.where(
      u._and(
        notDeleted,
        companyWhere,
        oldFilters,
        countryFilter,
        u._gte('status', 1)
      )
    );
    return await p.client.query<IGetManufacturerTypesInCountryReturn>({
      query: gql`
      query getManufacturerTypesInCountry(${u.params(
        ['$limit', 'Int'],
        ['$offset', 'Int'],
        ['$newProductsDate', 'timestamptz']
      )}) {
        substances: products_v2 (
          ${u.offset('$offset')}
          ${u.limit('$limit')}
          ${distinctClause}
          ${whereClause}
        ){
          Substance1 {
            name
          }
          Substance2 {
            name
          }
          Substance3 {
            name
          }
        }
        products_v2_aggregate(
          ${distinctClause}
          ${whereClause}
        ){
          aggregate {
            totalCount: count
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        limit: p.pageSize,
        offset: p.pageNr > 0 ? (p.pageNr - 1) * p.pageSize : 0,
        newProductsDate,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/manufacturers/query.ts -> getManufacturerTypesInCountryQuery',
      e
    );
  }
}
