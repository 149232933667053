import { ISupplyUnitPrice } from 'app/store/modules/termsheets/types';
import { calculatePercentage } from 'app/utils/calculate-percentage';
import { sg } from 'app/utils/safe-get';
import Base from './base';

class SupplyUnitPrice extends Base {
  public strength: string;
  public unitText: string;
  public grossPrice: string;
  public netPrice: string;
  public commission: number;
  // public grossLicenseFee: number;

  constructor(supplyUnitPrice: ISupplyUnitPrice) {
    super(supplyUnitPrice);

    this.strength = sg(() => supplyUnitPrice.strength, '');
    this.unitText = `${supplyUnitPrice.number_of_units} ${
      supplyUnitPrice.unit_name ? supplyUnitPrice.unit_name : ''
    }`;
    this.grossPrice = supplyUnitPrice.gross_supply_price.toLocaleString(
      'en-US',
      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    );
    this.netPrice = supplyUnitPrice.net_supply_price.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    this.commission = calculatePercentage(
      `${supplyUnitPrice.net_supply_price}`,
      `${supplyUnitPrice.gross_supply_price}`
    );
  }
}

export default SupplyUnitPrice;
