import ToolTip from 'app/components/tooltip/tooltip-component';
import colors from 'app/styles/colors';
import { sgg } from 'app/utils/safe-get';
import React from 'react';
import Select from 'react-select';
import { checkForError, IRule, IWrappedComponent } from '../validation';
import {
  ErrorIcon,
  ErrorLine,
  ErrorMessage,
  InputContainer,
  InputWrapper,
  Label,
  SelectIcon,
} from './styles';

export interface Props<T = string> {
  closeMenuOnSelect?: boolean;
  controlShouldRenderValue?: boolean;
  hideSelectedOptions?: boolean;
  value?: ISelectItem<T>;
  values?: ISelectItem<T>[];
  label?: string;
  placeholder?: string;
  placeholderColor?: string;
  placeholderFontSize?: string;
  placeholderMarginLeft?: string;
  onChange?: (value: ISelectItem<T>) => any;
  onChangeMulti?: (value: ISelectItem<T>[]) => any;
  optionList: ISelectItem<T>[];
  isMulti?: boolean;
  error?: string;
  tabindex?: string;
  zIndex?: string;
  rules?: IRule<ISelectItem<T>[]>[];
  id?: string;
  isDisabled?: boolean;
  tooltip?: string;
  maxMenuHeight?: number;
  minCharsCountForLessStrictFilter?: number;
  onKeyDown?: (event: any) => any;
  onBlur?: () => any;
  noTopMargin?: boolean;
}

class SelectInput<T = string>
  extends React.Component<Props<T>>
  implements IWrappedComponent<ISelectItem<T> | ISelectItem<T>[]> {
  private ref: any;
  public constructor(props: Props<T>) {
    super(props);

    this.getValue = this.getValue.bind(this);
    this.isValid = this.isValid.bind(this);
    this.ref = React.createRef();
  }

  public getValue() {
    if (this.props.isMulti) {
      return this.props.values;
    }
    return this.props.value;
  }
  public isValid() {
    if (this.props.isMulti) {
      return checkForError(this.props.values!, this.props.rules);
    }
    return checkForError([this.props.value!], this.props.rules);
  }

  public render() {
    const {
      closeMenuOnSelect = true,
      controlShouldRenderValue = true,
      hideSelectedOptions = true,
      label,
      value,
      placeholder,
      onChange,
      optionList,
      isMulti,
      values,
      error,
      onChangeMulti,
      tabindex,
      zIndex,
      id,
      isDisabled,
      placeholderColor,
      placeholderFontSize,
      placeholderMarginLeft,
      tooltip,
      maxMenuHeight,
      minCharsCountForLessStrictFilter,
      onKeyDown,
      onBlur,
      noTopMargin,
    } = this.props;

    let hasLabel = label !== undefined && label !== '';
    if (noTopMargin) {
      hasLabel = !noTopMargin;
    }

    return (
      <InputContainer hasLabel={hasLabel}>
        <Label htmlFor={label}>{label}</Label>
        {tooltip && <ToolTip tooltip={tooltip} />}
        <InputWrapper
          isMulti={isMulti ? true : false}
          error={error !== undefined && error !== ''}
          zIndex={zIndex ? zIndex : '3'}
          selected={
            (isMulti && values && values.length > 0) ||
            (!isMulti && value && value.label !== '')
              ? true
              : false
          }
          placeholderColor={placeholderColor}
          placeholderFontSize={placeholderFontSize}
          placeholderMarginLeft={placeholderMarginLeft}
        >
          <Select
            instanceId="dropdown"
            isDisabled={isDisabled}
            id={id}
            maxMenuHeight={maxMenuHeight}
            inputId={`input-${id}`}
            closeMenuOnSelect={closeMenuOnSelect}
            controlShouldRenderValue={controlShouldRenderValue}
            hideSelectedOptions={hideSelectedOptions}
            ref={this.ref}
            onBlur={onBlur}
            options={optionList}
            onChange={(val: any) => {
              if (onChange && !isMulti) {
                onChange(val);
              }
              if (onChangeMulti && isMulti) {
                onChangeMulti(val);
              }
            }}
            onKeyDown={onKeyDown}
            filterOption={(option: any, filterText) => {
              if (filterText === '') {
                return true;
              }
              if (option && option.value) {
                const searchTextInOption = option.label
                  .toLowerCase()
                  .substr(0, filterText.length);
                return (
                  searchTextInOption === filterText.toLowerCase() ||
                  (!!minCharsCountForLessStrictFilter &&
                    filterText.length >= minCharsCountForLessStrictFilter &&
                    option.label
                      .toLowerCase()
                      .includes(filterText.toLowerCase()))
                );
              } else {
                return false;
              }
            }}
            isClearable={true}
            placeholder={placeholder ? placeholder : ''}
            isMulti={isMulti}
            value={isMulti ? values : value}
            label={label}
            styles={{
              option: (provided: any, state: any) => {
                return {
                  ...provided,
                  backgroundColor:
                    state.isFocused && state.isDisabled
                      ? colors.white
                      : provided.backgroundColor,
                  color: sgg(
                    () => state.isDisabled && `${colors.muted} !important`,
                    colors.grayExtraDark
                  ),
                };
              },
              control: (base: any, state: any) => ({
                ...base,
                border: state.isFocused ? 0 : 0,
                // This line disable the blue border
                boxShadow: state.isFocused ? 0 : 0,
                '&:hover': {
                  border: state.isFocused ? 0 : 0,
                },
                fontSize: 14,
                color: colors.grayExtraDark,
                text: colors.grayExtraDark,
                // You can also use state.isFocused to conditionally style based on the focus state
              }),
              singleValue: (base: any) => ({
                ...base,
                color: colors.grayExtraDark,
                text: colors.grayExtraDark,
              }),
              input: (base: any) => ({
                ...base,
                color: colors.grayExtraDark,
                text: colors.grayExtraDark,
              }),
              placeholder: (base: any) => ({
                ...base,
                color: colors.grayText,
                text: colors.grayText,
              }),
            }}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                text: colors.grayExtraDark,
                primary: colors.primaryLightHover,
                primary25: colors.primaryLight,
              },
            })}
            tabIndex={tabindex}
          />
          <SelectIcon
            src={
              isDisabled
                ? '/img/selectDownIconDisabled.svg'
                : '/img/selectDownIcon.svg'
            }
            onClick={() => {
              this.ref.current.focus();
              this.ref.current.onMenuOpen();
            }}
          />
        </InputWrapper>
        {error !== '' && error !== undefined && (
          <ErrorLine>
            <ErrorMessage>{error}</ErrorMessage>
            <ErrorIcon src={'/img/formError.png'} />
          </ErrorLine>
        )}
      </InputContainer>
    );
  }
}

const Components = {
  ErrorLine,
  ErrorMessage,
  ErrorIcon,
  InputContainer,
  Label,
  InputWrapper,
  SelectIcon,
};
export { Components };
export default SelectInput;
