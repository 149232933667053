import { ITermsheet } from 'app/store/modules/termsheets/types';
import { Dictionary } from 'app/utils/dictionary';
import { Company, User } from '../auth/types';
import { IDeal } from '../deal/types';
import { IPaginateable } from '../global/types';
import { AnyListItem, OnBoardingFilter, Product } from '../products/types';
import { IDealMessage } from './messages/types';

export { IDealMessage };
export interface State extends IPaginateable {
  dealsCache: Dictionary<IRequest>;
  dealsIds: number[];
  archiveIds: number[];
  error: Notifications;
  archivedTotalCount: number;
  createDealForm: ICreateDealForm;
  isFetching: boolean;
  hasFetched: boolean;
  sendingMessage: boolean;
  isFetchingMessages: boolean;
  hasFetchedMessages: boolean;
  dealExistsModalOpen: boolean;
  dealExistsLink: string;
  filter: DealsFilter;
  originFilter: OnBoardingFilter;
  linkSellerRequestModalOpen: boolean;
  linkSellerRequestToDealId: number;
}

export interface ICreateDealForm {
  product?: ICreateProductDeal;
}

export interface IRequest {
  id: number;
  status: EDealStatus;
  isPaid: boolean;
  dealType?: number;
  market?: string;
  estimatedAnualQuantity?: string;
  leadingPriceLevel?: string;
  expectSignContract?: string;
  comments?: string;
  createdAt?: Date;
  archivedAt?: Date;
  updatedAt: Date;
  sellerUpdatedAt: Date;
  buyerUpdatedAt: Date;
  recommendAlternativeSuppliers: boolean;

  productId?: number;
  Product?: Product;

  buyerUserId?: number;
  buyerCompanyId: number;
  Buyer?: User;
  BuyerCompany?: Company;

  sellerUserId?: number;
  sellerCompanyId: number;
  Seller?: User;
  SellerCompany?: Company;

  broker?: number;
  Broker?: User;

  messages: {
    totalCount: number;
    messages: Dictionary<IDealMessage>;
  };

  Termsheets?: ITermsheet[];

  color: string;

  counterProposal?: string;
  adminApproved: boolean | null;
  showBubble: boolean;

  dealsGroup?: string;
  dealId?: number;
  Deal?: IDeal;
  phase?: number;
  skippedReason?: number;

  lastMessage?: any[];
  uncheckedMessages?: any[];

  adminSeen?: boolean;
  adminFirstSeen?: Date | string;

  sellerSeen?: boolean;
  sellerFirstSeen?: Date | string;

  buyerSeen?: boolean;
  buyerFirstSeen?: Date | string;
}

export interface Notifications {
  listError?: string;
  listSuccess?: string;
  messageSendError?: string;
}
export interface ICreateProductDeal {
  product?: Product;
  dealType?: number;
  market?: string;
  estimatedAnualQuantity?: string;
  comments?: string;
  dealsGroup?: string;
  leadingPriceLevel?: string;
  isLogedUserRequest?: boolean;
  selectedProducts?: AnyListItem[];
  currentUserCompanyId?: number;
  expectSignContract?: string;
  createdBy: number;
}

export interface ISubstance {
  id: number;
  name: string;
  brand_name?: string;
  category?: number;
}
export interface ISupplier {
  requestId: number;
  Product: {
    id: number;
    formSpecification?: string;
    strength?: string;
    dossierFormat: number;
    Substance1?: ISubstance;
    Substance2?: ISubstance;
    Substance3?: ISubstance;
    packSize?: string;
    volume?: string;
  };
  SellerCompany: {
    id: number;
    name?: string;
    hidden?: boolean;
    country: string;
  };
  phase: number;
  skippedReason?: number;
  Termsheets?: ITermsheet[];
}
export interface IPotentialSupplier {
  productId: number;
  SellerCompany: {
    id: number;
    name?: string;
    hidden?: boolean;
    country: string;
  };
  Substance1: {
    id: number;
    name: string;
    brand_name?: string;
    category?: number;
  };
  Substance2: {
    id: number;
    name: string;
    brand_name?: string;
    category?: number;
  };
  Substance3: {
    id: number;
    name: string;
    brand_name?: string;
    category?: number;
  };
  formSpecification: string;
  strength: string;
  packSize: string;
  volume: string;
  notAvailableMarkets: string;
  dossierStatus: number;
  dossierFormat: number;
  dossierCompletionQuarter: string;
  dossierCompletionYear: string;
  gmpApprovalForThisProductString: string;
  marketingAuthorizationsAchievedCountry: string;
  registeredIn: string;
  productAvailableLicensingSupply: boolean;
  productAvailableSupplyDistribution: boolean;
  productAvailableTechnologyTransfer: boolean;
  productAvailableSpecialImport: boolean;
  shelfLife: string;
  batchSize: string;
  moq: string;
  adminComments: string;
  gmpApprovals: string[];
  stabilityDataZonesString: string;
  clinicalData: string;
  cpp: string;
  cppAvailable: boolean;
  finishedDoseCountry: string;
  createdBy: number;
  collapsible: boolean;
}

export enum EDealStatus {
  Requested = 1,
  Negotiating,
  Accepted,
  Expired,
  Declined,
}
export const DealStatusDictionary = {
  [EDealStatus.Requested]: 'Requested',
  [EDealStatus.Negotiating]: 'Negotiating',
  [EDealStatus.Accepted]: 'Accepted',
  [EDealStatus.Expired]: 'Expired',
  [EDealStatus.Declined]: 'Declined',
};

export const defaultPageSize = 10;

export const defaultStore: State = {
  dealsCache: {},
  dealsIds: [],
  archiveIds: [],
  error: { listError: '', listSuccess: '', messageSendError: '' },
  archivedTotalCount: 0,
  isFetching: false,
  hasFetched: false,
  sendingMessage: false,
  isFetchingMessages: false,
  hasFetchedMessages: false,
  currentPage: 0,
  pageSize: 10,
  totalCount: 0,
  filter: {},
  dealExistsModalOpen: false,
  dealExistsLink: '',
  createDealForm: {
    product: undefined,
  },
  originFilter: 'all',
  linkSellerRequestModalOpen: false,
  linkSellerRequestToDealId: 0,
};

export interface DealsFilter {
  product?: string;
  request?: string;
  status?: number;
  company?: string;
  email?: string;
  createdFrom?: Date;
  createdTo?: Date;
  broker?: ISelectItem;
}

export const DealPhasesDisplayList = [
  { name: 'Request incomplete', id: 0, order: 13 },
  { name: 'Requesting offer', id: 1, order: 10 },
  { name: 'Waiting for offer', id: 2, order: 9 },
  { name: 'Evaluating offer', id: 3, order: 8 },
  { name: 'Negotiating terms', id: 4, order: 7 },
  { name: 'Preparing personal introduction', id: 5, order: 6 },
  { name: 'Auditing CTD', id: 6, order: 5 },
  { name: 'Negotiating contract', id: 7, order: 4 },
  { name: 'Registration', id: 8, order: 3 },
  { name: 'Commercialization', id: 9, order: 2 },
  { name: 'Waiting', id: 10, order: 1 },
  { name: 'Done', id: 11, order: 11 },
  { name: 'Skipped', id: 12, order: 12 },
];

export const DealPhaseSkipped = { name: 'Skipped', id: 12, order: 12 };

// priority from low to high in order
export interface DealPhasesOrderDictionaryType {
  name: string;
  id: number;
  order: number;
}

export const DealPhasesOrderDictionary = {
  // id in string form is the key
  '0': { name: 'Request incomplete', id: 0, order: 13 },
  '12': { name: 'Skipped', id: 12, order: 1 },
  '10': { name: 'Waiting', id: 10, order: 2 },
  '11': { name: 'Done', id: 11, order: 3 },
  '1': { name: 'Requesting offer', id: 1, order: 4 },
  '2': { name: 'Waiting for offer', id: 2, order: 5 },
  '3': { name: 'Evaluating offer', id: 3, order: 6 },
  '4': { name: 'Negotiating terms', id: 4, order: 7 },
  '5': { name: 'Preparing personal introduction', id: 5, order: 8 },
  '6': { name: 'Auditing CTD', id: 6, order: 9 },
  '7': { name: 'Negotiating contract', id: 7, order: 10 },
  '8': { name: 'Registration', id: 8, order: 11 },
  '9': { name: 'Commercialization', id: 9, order: 12 },
  // for undefined or null phases
  undefined: { name: '', id: undefined, order: 13 },
  null: { name: '', id: null, order: 13 },
};

export const DealPhasesOrderListWithEmpty = [
  { name: 'Waiting', id: 10, order: 1 },
  { name: 'Commercialization', id: 9, order: 2 },
  { name: 'Registration', id: 8, order: 3 },
  { name: 'Negotiating contract', id: 7, order: 4 },
  { name: 'Auditing CTD', id: 6, order: 5 },
  { name: 'Preparing personal introduction', id: 5, order: 6 },
  { name: 'Negotiating terms', id: 4, order: 7 },
  { name: 'Evaluating offer', id: 3, order: 8 },
  { name: 'Waiting for offer', id: 2, order: 9 },
  { name: 'Requesting offer', id: 1, order: 10 },
  { name: 'Request incomplete', id: 0, order: 13 },
  { name: 'Done', id: 11, order: 11 },
  { name: 'Skipped', id: 12, order: 12 },
  // for undephined phases
  { name: '', id: undefined, order: 13 },
  { name: '', id: null, order: 13 },
];

export enum DealPhases {
  'Request incomplete' = 0,
  'Requesting offer' = 1,
  'Waiting for offer' = 2,
  'Evaluating offer' = 3,
  'Negotiating terms' = 4,
  'Preparing personal introduction' = 5,
  'Auditing CTD' = 6,
  'Negotiating contract' = 7,
  'Registration' = 8,
  'Commercialization' = 9,
  'Waiting' = 10,
  'Done' = 11,
  'Skipped' = 12,
}

export enum DealSkippedReasonsOld {
  'Buyer' = 1,
  'Intention' = 2,
  'Seller' = 3,
  'Transaction' = 4,
  'Product Data' = 5,
}

export enum DealSkippedReasons {
  'Seller is not responding' = 1,
  'Seller has become unresponsive' = 2,
  'Seller wrong contact' = 3,
  'Seller is not willing to cooperate' = 4,
  'Buyer is not responding' = 5,
  'Buyer has become unresponsive' = 6,
  'Buyer is No ICP' = 7,
  'Terms regulatory/product/deal type mismatch' = 8,
  'Terms pricing/commercial conditions mismatch' = 9,
  'Terms regulatory/product mismatch' = 10,
  'Terms deal type mismatch' = 11,
  'Terms territory mismatch | market is not available' = 12,
  'Buyer changed priorities' = 13,
  'Buyer negotiating with other suppliers' = 14,
  'Database quality | product does not exist' = 15,
}

export const RequestSkippedReasonsList = [
  { name: 'Please select reason', id: undefined, order: 1 },
  { name: 'Seller is not responding', id: 1, order: 2 },
  { name: 'Seller has become unresponsive', id: 2, order: 3 },
  { name: 'Seller wrong contact', id: 3, order: 4 },
  { name: 'Seller is not willing to cooperate', id: 4, order: 5 },
  { name: 'Buyer is not responding', id: 5, order: 7 },
  { name: 'Buyer has become unresponsive', id: 6, order: 8 },
  { name: 'Buyer is No ICP', id: 7, order: 6 },
  {
    name: 'Terms regulatory/product/deal type mismatch',
    id: 8,
    order: 10,
    disabled: true,
  },
  { name: 'Terms pricing/commercial conditions mismatch', id: 9, order: 11 },
  { name: 'Terms regulatory/product mismatch', id: 10, order: 12 },
  { name: 'Terms deal type mismatch', id: 11, order: 14 },
  {
    name: 'Terms territory mismatch | market is not available',
    id: 12,
    order: 15,
  },
  { name: 'Buyer changed priorities', id: 13, order: 9 },
  { name: 'Buyer negotiating with other suppliers', id: 14, order: 10 },
  { name: 'Database quality | product does not exist', id: 15, order: 16 },
];

export enum EEditFields {
  Init = -1,
  Currency = 100,
  MOQ,
  PaymentTerms,
  Incoterms,
  LeadTime,
  Comments,

  OfferFor = 200,
  DealType,
  AnnualForecast,
  LicenseFee,
  MilestonePayments,
  SupplyPriceType,
  PriceSharingProposal,
  SupplyPriceChange,
  SupplyPriceAdd,
  SupplyPriceDelete,
  BatchSize,
  ShelfLife,
  Jurisdiction,
  Validity,

  SupplyPrice = 300,
  MonthlySupply,

  /** Offer accept message */
  OfferAcceptSuccessfull = 400,
  /** Offer negotiate message */
  OfferNegotiateSuccessfull,
  /** Product request message */
  ProductRequestSuccessfull,
  /** Product request accepted */
  ProductRequestInformation,
  /** Offer negotiate accepted */
  DealNegotiateAcceptSuccessfull,
}
export const EEditFieldsDescription: Dictionary<string> = {
  [EEditFields.Currency]: 'Currency changed',
  [EEditFields.MOQ]: 'MOQ changed',
  [EEditFields.PaymentTerms]: 'Payment terms changed',
  [EEditFields.Incoterms]: 'Incoterms changed',
  [EEditFields.LeadTime]: 'Lead time changed',
  [EEditFields.Comments]: 'Comments changed',

  [EEditFields.OfferFor]: 'Offer for changed',
  [EEditFields.DealType]: 'Deal type changed',
  [EEditFields.AnnualForecast]: 'Annual forecast changed',
  [EEditFields.LicenseFee]: 'License fee changed',
  [EEditFields.MilestonePayments]: 'Milestone payments changed',
  [EEditFields.SupplyPriceType]: 'Supply price type changed',
  [EEditFields.PriceSharingProposal]: 'Price sharing proposal changed',
  [EEditFields.SupplyPriceChange]: 'Supply price changed',
  [EEditFields.SupplyPriceAdd]: 'Suppy price added',
  [EEditFields.SupplyPriceDelete]: 'Supply price removed',
  [EEditFields.BatchSize]: 'Batch size changed',
  [EEditFields.ShelfLife]: 'Shelf life changed',
  [EEditFields.Jurisdiction]: 'Jurisdiction changed',
  [EEditFields.Validity]: 'Offer validity changed',

  [EEditFields.SupplyPrice]: 'Supply price changed',
  [EEditFields.MonthlySupply]: 'Monthly supply changed',
};
