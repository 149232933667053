import { Components as AsyncSelectC } from 'app/components/inputs/async-select';
import { Components as SelectC } from 'app/components/inputs/select';
import { _, media } from 'app/styles/breakpoint';
import styled from 'styled-components';

//
export const AdditionalDetailsFormWrapper = styled.div`
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  //padding-top: 20px;
  ${_(media.max.sm)} {
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
`;

export const AdditionalDetailsFormContainer = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;

  ${_(media.max.sm)} {
    width: 100%;
  }
`;

export const Header = styled.div`
  margin-top: 4px;
  padding-left: 42px;
  padding-right: 56px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
  margin-bottom: 14px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  padding-right: 40px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
  div > label {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 32px;
    /* identical to box height, or 178% */

    display: flex;
    align-items: center;

    /* Black */

    color: #212121;
  }
`;

export const CancelButton = styled.div`
  height: 50px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.gray100};
  width: 100%;
  margin-top: 19px;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.secondary600};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.gray100};
  }
`;

export const UpdateButton = styled.div`
  height: 50px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.primaryMain};
  width: 100%;
  margin-top: 19px;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.primaryMain};
  }
`;

export const TwoItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  ${_(media.max.sm)} {
    flex-direction: column;
  }
`;

export const FirstItemWrapper = styled.div`
  display: flex;
  margin-right: 13px;
  ${_(media.max.sm)} {
    margin-right: 0;
  }
  flex: 1;
`;

export const SecondItemWrapper = styled.div`
  display: flex;
  flex: 1;
`;

export const MainImageWrapper = styled.div`
  ${_(media.min.md)} {
    display: flex;
  }
  ${_(media.max.tablet)} {
    display: none;
  }
  ${_(media.min.lg)} {
    margin: -45px -25px -45px 0;
  }
  ${_(media.min.xlg)} {
    margin: -55px -35px -55px 0;
  }
  margin: -40px -20px -40px 0;
  width: 410px;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.background};
`;

export const MainImage = styled.img`
  width: 386px;
  height: 563px;
  object-fit: contain;
`;

export const DropdownContainerItem = styled.div`
  //margin-bottom: 20px;
  .override {
    ${SelectC.InputContainer}, ${AsyncSelectC.InputContainer} {
      min-height: 50px;
      border-radius: 3px;
      border: solid 1px ${(props) => props.theme.colors.grayLight};
    }

    ${SelectC.InputWrapper}, ${AsyncSelectC.InputWrapper} {
      min-height: 48px;
      border-bottom: 0;
      border-radius: 3px;
      text-indent: 6px;
      padding-left: 10px;
      padding-right: 15px;
      div {
        cursor: pointer;
      }
      :focus-within {
        border: 1px solid ${(props) => props.theme.colors.selectBorder};
      }
    }
    ${SelectC.SelectIcon}, ${AsyncSelectC.SelectIcon} {
      margin-right: 6px;
    }
  }
  .selected {
    ${SelectC.InputWrapper}, ${AsyncSelectC.InputWrapper} {
      border: 1px solid ${(props) => props.theme.colors.selectBorder};
      border-radius: 3px;
    }
  }
  .disabled {
    ${SelectC.InputWrapper}, ${AsyncSelectC.InputWrapper} {
      background-color: ${(props) => props.theme.colors.grayExtraLight};
      [class$='control'] {
        background-color: ${(props) => props.theme.colors.grayExtraLight};
      }
    }
  }
`;
export const DropdownLabel = styled.div`
  margin-bottom: 9px;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  /* identical to box height, or 178% */

  display: flex;
  align-items: center;

  /* Black */

  color: #212121;
  //color: ${(props) => props.theme.colors.grayExtraDark};
`;

export const SingleChoiceDropdownContainerItem = styled.div`
  margin: 0 0 20px 0;
  width: 100%;
  .override {
    ${SelectC.InputContainer}, ${AsyncSelectC.InputContainer} {
      min-height: 50px;
      border-radius: 3px;
      border: solid 1px ${(props) => props.theme.colors.grayLight};
    }
    ${SelectC.InputWrapper}, ${AsyncSelectC.InputWrapper} {
      min-height: 48px;
      border-bottom: 0;
      border-radius: 3px;
      text-indent: 6px;
      div {
        cursor: pointer;
      }
      :focus-within {
        border: 1px solid ${(props) => props.theme.colors.selectBorder};
      }
    }
    ${SelectC.SelectIcon}, ${AsyncSelectC.SelectIcon} {
      margin-right: 10px;
    }
  }
  .selected {
    ${SelectC.InputWrapper}, ${AsyncSelectC.InputWrapper} {
      border: 1px solid ${(props) => props.theme.colors.selectBorder};
      border-radius: 3px;
    }
  }
  .disabled {
    ${SelectC.InputWrapper}, ${AsyncSelectC.InputWrapper} {
      background-color: ${(props) => props.theme.colors.grayExtraLight};
      [class$='control'] {
        background-color: ${(props) => props.theme.colors.grayExtraLight};
      }
    }
  }
`;
export const CompanyInfoHeaderContainer = styled.div<{
  width?: string;
  padding?: boolean;
}>`
  display: flex;
  width: ${(props) => props.width || '90%'};
  padding: ${(props) => (props.padding ? '12px 12px' : '')};
`;
export const CompanyInfoHeader = styled.h2`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 40px;
  color: #1188e4;
  margin: 0px;
  padding: 0px;
  //margin-bottom: 25px;
  margin-left: 7px;
  @media (max-width: 450px) {
    width: 90%;
  }
`;
export const PersonalInfoHeaderContainer = styled.div<{
  width?: string;
}>`
  display: flex;
  width: ${(props) => props.width || '90%'};
  padding-top: 24px;
`;
export const RequiredInfoLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #212121;

  flex: none;
  order: 5;
  flex-grow: 0;
  padding-top: 24px;
`;
export const EmailLabel = styled.label`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;

  display: flex;
  align-items: center;

  color: #212121;
`;
export const EmailId = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 156% */

  display: flex;
  align-items: center;

  /* Black */

  color: #212121;
`;
export const EmailVerified = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 20px;
  /* identical to box height, or 156% */

  display: flex;
  align-items: center;

  /* Black */

  color: #fb9b80;
`;
export const BuyerEmailContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding-top: 24px;
`;
export const ButtonLine = styled.div`
  margin-top: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-left: 10px;
  padding-right: 35px;
  gap: 20px;
}
  ${media.max.sm`
    flex-direction: column;

  `}
`;
export const ButtonDivider = styled.div`
  height: 10px;
  width: 15px;
`;
