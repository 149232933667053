import { all, fork } from 'redux-saga/effects';
import admin from './modules/admin/sagas';
import auth from './modules/auth/sagas';
import deals from './modules/deal/sagas';
import companiesContact from './modules/companies-contacts/sagas';
import contactUs from './modules/contact-us/saga';
import requests from './modules/requests/sagas';
import device from './modules/device/sagas';
import companies from './modules/ma-companies/saga';
import manufacturers from './modules/manufacturers/saga';
import marketplace from './modules/marketplace/sagas';
import products from './modules/products/sagas';
import sellerInformation from './modules/seller-information/saga';
import subscribe from './modules/subscribe/saga';
import termsheets from './modules/termsheets/sagas';
import substances from './modules/substances/sagas';
import dealDetails from './modules/deal/deal-details/sagas';

function* rootSaga() {
  yield all([
    fork(device),
    fork(auth),
    fork(products),
    fork(marketplace),
    fork(manufacturers),
    fork(admin),
    fork(requests),
    fork(deals),
    fork(dealDetails),
    fork(companies),
    fork(companiesContact),
    fork(subscribe),
    fork(contactUs),
    fork(sellerInformation),
    fork(termsheets),
    fork(substances),
  ]);
}

export default rootSaga;
