import classNames from 'app/utils/class-names';
import React from 'react';
import ReactModal, { Props } from 'react-modal';

export interface IProps extends Props {
  scrollControl?: boolean;
  children: any;
}

const Modal: React.FC<IProps> = ({ scrollControl = true, ...props }) => {
  return (
    <ReactModal
      {...props}
      bodyOpenClassName={scrollControl ? 'ReactModal__Body--open' : ''}
      portalClassName={classNames('modal', props.portalClassName)}
    />
  );
};

export { Modal };
export default Modal;
