import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import { Dictionary } from '../../../utils/dictionary';
import {
  hasFetched,
  setAddProductToggle,
  setCurrentPage,
  setDeleteId,
  setEditModal,
  setEditProductId,
  setEditTradeModal,
  setNotification,
  setOnboardingFilter,
  setOnboardingIds,
  setPageSize,
  setProductCache,
  setProductFilter,
  setProductIds,
  setProductTypeFilterAction,
  setStatus,
  setSuggestAlternativeSellersModalOpen,
  setTotalCount,
  setVisibleProductIds,
} from './actions';
import {
  AnyListItem,
  defaultPageSize,
  Notifications,
  OnBoardingFilter,
  Product,
  ProductFilter,
  State,
} from './types';

const productsCache = cr<Dictionary<Product>>({}, reduce(setProductCache, set));
const deleteIds = cr<AnyListItem[]>([], reduce(setDeleteId, set));
const productIds = cr<AnyListItem[]>([], reduce(setProductIds, set));
const totalCount = cr<number>(0, reduce(setTotalCount, set));
const currentPage = cr<number>(0, reduce(setCurrentPage, set));
const pageSize = cr<number>(defaultPageSize, reduce(setPageSize, set));
const editModalOpen = cr<boolean>(false, reduce(setEditModal, set));
const editTradeModalOpen = cr<boolean>(false, reduce(setEditTradeModal, set));
const editProductId = cr<number>(0, reduce(setEditProductId, set));
const suggestAlternativeSellersModalOpen = cr<boolean>(
  false,
  reduce(setSuggestAlternativeSellersModalOpen, set)
);
const error = cr<Notifications>(
  {
    listError: '',
    listSuccess: '',
  },
  reduce(setNotification, set)
);
const status = cr<number>(0, reduce(setStatus, set));
const filter = cr<ProductFilter>(
  {
    product: '',
    company: '',
    form: '',
    visible: true,
  },
  reduce(setProductFilter, set)
);
const visibleProductIds = cr<AnyListItem[]>(
  [],
  reduce(setVisibleProductIds, set)
);

const addProductToggle = cr<boolean>(false, reduce(setAddProductToggle, set));

const onboardingIds = cr<AnyListItem[]>([], reduce(setOnboardingIds, set));

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default combineReducers<State>({
  productsCache,
  productIds,
  visibleProductIds,
  deleteIds,
  totalCount,
  currentPage,
  pageSize,
  filter,
  editModalOpen,
  editTradeModalOpen,
  editProductId,
  suggestAlternativeSellersModalOpen,
  error,
  status,
  hasFetched: cr<boolean>(false, reduce(hasFetched, set)),
  onboardingIds,
  onboardingFilter: cr<OnBoardingFilter>(
    'all',
    reduce(setOnboardingFilter, set)
  ),
  productsFilter: cr<OnBoardingFilter>(
    'all',
    reduce(setProductTypeFilterAction, set)
  ),
  addProductToggle,
});
