import gql from 'graphql-tag';
import { isArray } from 'lodash';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import * as u from '../../query-utils';
import {
  companySettingsPersonalFragment,
  ICompanySettingsFragment,
} from '../fragments/company-settings';
import { Company } from '../types';

export interface IUpdateCompanySettings extends IWithApolloClient {
  company: Company;
}
export interface IUpdateCompanySettingsReturn {
  update_companies: {
    returning: ICompanySettingsFragment[];
  };
}
export async function updateCompanySettingsQuery(p: IUpdateCompanySettings) {
  try {
    return await p.client.mutate<IUpdateCompanySettingsReturn>({
      mutation: gql`
      ${companySettingsPersonalFragment.fragment()}
      mutation update_companies_personal(${u.params(
        ['$id', 'Int'],
        ['$name', 'String'],
        ['$address', 'String'],
        ['$country', 'String'],
        ['$website', 'String'],
        ['$code', 'String'],
        ['$vat_code', 'String'],
        ['$annualTurnover', 'String'],
        ['$numberOfEmployees', 'String'],
        ['$summary', 'String'],
        ['$annualRevenue', 'String'],
        ['$salesChannels', 'String'],
        ['$therapyAreas', 'String'],
        ['$portfolioProductsList', 'String']
      )}) {
        update_companies_personal(
          ${u.where(u._eq('id', '$id'))}
          ${u._set(
            ['name', '$name'],
            ['address', '$address'],
            ['country', '$country'],
            ['website', '$website'],
            ['code', '$code'],
            ['vat_code', '$vat_code'],
            ['annual_turnover', '$annualTurnover'],
            ['number_of_employees', '$numberOfEmployees'],
            ['summary', '$summary'],
            ['annual_revenue', '$annualRevenue'],
            ['sales_channels', '$salesChannels'],
            ['therapy_areas', '$therapyAreas'],
            ['portfolio_products_list', '$portfolioProductsList']
          )}
        ) {
          returning {
            ...${companySettingsPersonalFragment.name}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.company.id,
        name: p.company.name,
        address: p.company.address,
        country: p.company.country,
        website: p.company.website,
        code: p.company.code,
        vat_code: p.company.vatCode,
        annualTurnover: p.company.annualTurnover,
        numberOfEmployees: p.company.numberOfEmployees,
        summary: p.company.summary,
        annualRevenue: p.company.annualRevenue,
        salesChannels: isArray(p.company.salesChannels)
          ? p.company.salesChannels.join(',')
          : p.company.salesChannels,
        therapyAreas: isArray(p.company.therapyAreas)
          ? p.company.therapyAreas.join(',')
          : p.company.therapyAreas,
        portfolioProductsList: isArray(p.company.portfolioProductsList)
          ? p.company.portfolioProductsList.join(',')
          : p.company.portfolioProductsList,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/auth/query.ts -> updateCompanySettingsQuery',
      e
    );
  }
}
