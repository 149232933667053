import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { RequestUtils, UserUtils } from '../../query-utils';

export interface ISetBroker extends IWithApolloClient {
  id: number;
  brokerId: number;
}
export interface ISetBrokerQuery {
  update_requests: {
    returning: {
      id: number;
      broker: number;
    }[];
  };
}
export interface ISetBrokerQueryReturn {
  update_requests: {
    returning: {
      id: number;
      broker: number;
      Broker: { fullName: string; email: string };
    }[];
  };
}
export async function setBrokerQuery(p: ISetBroker) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.mutate<ISetBrokerQueryReturn>({
      mutation: gql`
      mutation setBroker(${RequestUtils.params(
        ['$id', 'Int'],
        ['$brokerId', 'Int'],
        ['$date', 'timestamptz']
      )}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('id', '$id'))}
          ${RequestUtils._set(['broker', '$brokerId'], ['updated_at', '$date'])}
        ) {
          returning {
            ${RequestUtils.selectParams('id', 'broker', [
              'Broker',
              true,
              UserUtils.selectParams(['full_name', 'fullName'], 'email'),
            ])}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        brokerId: p.brokerId,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> setBrokerQuery',
      e
    );
  }
}
