import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { UserUtils } from '../../query-utils';

export interface IGetBrokers extends IWithApolloClient {
  name: string;
}
export interface IGetBrokersReturn {
  options: {
    value: number;
    label: string;
  }[];
}
export async function getBrokersQuery(p: IGetBrokers) {
  try {
    return await p.client.query<IGetBrokersReturn>({
      query: gql`query getBrokers(${UserUtils.params(
        ['$name', 'String'],
        ['$limit', 'Int']
      )}) {
      options: users(
        ${UserUtils.limit('$limit')}
        ${UserUtils.orderBy('full_name', 'asc')}
        ${UserUtils.where(
          UserUtils._and(
            UserUtils._eq('is_default_broker', true),
            UserUtils._ilike('full_name', '$name')
          )
        )}
      ) {
        ${UserUtils.selectParams(['id', 'value'], ['full_name', 'label'])}
      }
    }`,
      fetchPolicy: 'no-cache',
      variables: {
        name: UserUtils.like(p.name, 'middle'),
        limit: 50,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> getBrokersQuery',
      e
    );
  }
}
