import { createReducer, reduce, set } from '@reduxify/utils';
import {
  setAuthCheckComplete,
  setBuyerQualificationAdminViewRequestId,
  setBuyerQualificationAdminViewModalOpen,
  setBuyerQualificationModalOpen,
  setBuyerQualificationModalShown,
  setCreateAccountModalOpen,
  setGetStartedModalOpen,
  setLinkedinSuccess,
  setLoggedIn,
  setLoggedInUser,
  setLoginError,
  setLoginSuccess,
  setNavbarCount,
  setNavbarCountFetchComplete,
  setNotification,
  setRegisterError,
  setRegisterModalOpen,
  setResetPasswordModal,
  setUserCompany,
  setUserSettings,
  setVerifyEmailModalOpen,
} from 'app/store/modules/auth/actions';
import { combineReducers } from 'redux';
import {
  Company,
  defaultEmptyCompany,
  defaultEmptyUser,
  defaultStore,
  Notifications,
  State,
  User,
} from './types';

const registerError = createReducer<string>('', reduce(setRegisterError, set));
const loginError = createReducer<string>('', reduce(setLoginError, set));
const loginSuccess = createReducer<string>('', reduce(setLoginSuccess, set));
const registerModalOpen = createReducer<boolean>(
  false,
  reduce(setRegisterModalOpen, set)
);
const getStartedModalOpen = createReducer<boolean>(
  false,
  reduce(setGetStartedModalOpen, set)
);
const buyerQualificationModalOpen = createReducer<boolean>(
  false,
  reduce(setBuyerQualificationModalOpen, set)
);
const buyerQualificationModalShown = createReducer<boolean>(
  false,
  reduce(setBuyerQualificationModalShown, set)
);
const verifyEmailModalOpen = createReducer<boolean>(
  false,
  reduce(setVerifyEmailModalOpen, set)
);
const createAccountModalOpen = createReducer<boolean>(
  false,
  reduce(setCreateAccountModalOpen, set)
);

const linkedinLoginSuccess = createReducer<boolean>(
  false,
  reduce(setLinkedinSuccess, set)
);
const loggedIn = createReducer(false, reduce(setLoggedIn, set));
const loggedInUser = createReducer(
  defaultEmptyUser,
  reduce(setLoggedInUser, set)
);
const navbarCount = createReducer<State['navbarCount']>(
  defaultStore.navbarCount,
  reduce(setNavbarCount, set)
);

const userSettings = createReducer<User>(
  defaultEmptyUser,
  reduce(setUserSettings, set)
);
const userCompany = createReducer<Company>(
  defaultEmptyCompany,
  reduce(setUserCompany, set)
);

const notifications = createReducer<Notifications>(
  { error: '', success: '' },
  reduce(setNotification, set)
);
const authCheckComplete = createReducer<boolean>(
  false,
  reduce(setAuthCheckComplete, set)
);
const resetPasswordModal = createReducer<boolean>(
  false,
  reduce(setResetPasswordModal, set)
);

const navbarCountFetchComplete = createReducer<boolean>(
  false,
  reduce(setNavbarCountFetchComplete, set)
);

const buyerInformationAdminViewDealId = createReducer<number>(
  0,
  reduce(setBuyerQualificationAdminViewRequestId, set)
);

const buyerInformationAdminViewModalOpen = createReducer<boolean>(
  false,
  reduce(setBuyerQualificationAdminViewModalOpen, set)
);

export default combineReducers<State>({
  loggedIn,
  loggedInUser,
  linkedinLoginSuccess,
  registerError,
  loginError,
  navbarCount,
  userSettings,
  userCompany,
  notifications,
  authCheckComplete,
  loginSuccess,
  registerModalOpen,
  getStartedModalOpen,
  buyerQualificationModalOpen,
  buyerQualificationModalShown,
  verifyEmailModalOpen,
  createAccountModalOpen,
  resetPasswordModal,
  navbarCountFetchComplete,
  buyerInformationAdminViewDealId,
  buyerInformationAdminViewModalOpen,
});
