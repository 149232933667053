import { createActionCreator as c } from '@reduxify/utils';
import { IWithApolloClient } from '../global/types';
import { ContactUsFields, Notifications } from './types';

export interface ICreateContactUs extends IWithApolloClient {
  values: ContactUsFields;
}
const createContactUs = c<ICreateContactUs>('contactus/CREATE_CONTACT');
export { createContactUs };

const setContactUsNotification = c<Notifications>('contactus/SET_NOTIFICATION');
export { setContactUsNotification };
