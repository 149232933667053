export interface State {
  loggedIn: boolean;
  loggedInUser: User;
  registerError: string;
  loginError: string;
  loginSuccess: string;
  linkedinLoginSuccess: boolean;
  navbarCount: {
    productCount: number;
    dealCount: number;
    dealNotSeenCount: number;
    dealsBuyingCount: number;
    dealsSellingCount: number;
    dealsBuyingNotSeenCount: number;
    dealsSellingNotSeenCount: number;
    onboardingCount: number;
    marketplaceExistingDealIds: MarketplaceExistingDeal[];
    archiveCount: number;
  };
  navbarCountFetchComplete: boolean;
  userSettings: User;
  userCompany: Company;
  notifications: Notifications;
  authCheckComplete: boolean;
  registerModalOpen: boolean;
  getStartedModalOpen: boolean;
  verifyEmailModalOpen: boolean;
  buyerQualificationModalOpen: boolean;
  buyerQualificationModalShown: boolean;
  createAccountModalOpen: boolean;
  resetPasswordModal: boolean;
  buyerInformationAdminViewDealId: number;
  buyerInformationAdminViewModalOpen: boolean;
}

export interface MarketplaceExistingDeal {
  id: number;
  status: number;
  archivedAt?: Date;
  Product: {
    mainProductId: number;
  };
}

export interface Notifications {
  error: string;
  success: string;
}
export interface User {
  id: number;
  Company: Company;
  createdAt?: Date;
  deletedAt?: Date;
  isDefaultBroker: boolean;
  isAdmin: boolean;
  isBeta: boolean;
  phone?: string;
  position?: string;
  lastName: string;
  firstName: string;
  fullName: string;
  email: string;
  uuid: string;
  newPassword?: string;
  oldPassword?: string;
  verified: boolean;
  verificationToken: string;
  isBuyer: boolean;
  isSeller: boolean;
  intercomHash: string;
  ownsProduct: boolean;
}

export interface UserMinimal {
  id: number;
  fullName: string;
  email: string;
}

export interface Company {
  name: string;
  country?: string;
  description?: string;
  importedName?: string;
  contactEmail?: string;
  contactPhone?: string;
  contactTitle?: string;
  contactFirstName?: string;
  id: number;
  createdAt: Date;
  updatedAt: Date;
  contactLastName?: string;
  code?: string;
  vatCode?: string;
  address?: string;
  website?: string;
  deletedAt?: Date;
  annualTurnover?: string;
  numberOfEmployees?: string;
  summary?: string;
  annualRevenue?: string;
  salesChannels?: string[];
  therapyAreas?: string[];
  portfolioProductsList?: string[];
  hidden: boolean;
  can_offer_api: boolean;
  admin_remarks: string;
  imported_at?: Date;
  import_source_id?: string;
  import_id?: string;
  createdBy: number;
  is_commissions_paid?: boolean;
  negotiated_supply_fee_commissions_pct?: number;
  is_cda_signed?: boolean;
  cda_signature_date?: Date;
  cda_expiry_date?: Date;
  is_early_buyer_disclosure_required?: boolean;
  negotiated_license_fee_commissions_pct?: number;
  is_cpa_signed?: boolean;
  cpa_signature_date?: Date;
  segment: string;
}
export type ShortCompany = Pick<Company, 'id' | 'name' | 'country'>;

export const codePlacesRelatedToVerifyEmailModal = {
  authenticate: 'authenticate',
  checkAuthSaga: 'checkAuthSaga',
  createAccountSaga: 'createAccountSaga',
  buyerQualificationModal: 'buyerQualificationModal',
  suggestAlternativeSellers: 'suggestAlternativeSellers',
  suggestAlternativeSellersModal: 'suggestAlternativeSellersModal',
};
export const defaultEmptyCompany: Company = {
  name: '',
  id: 0,
  createdAt: new Date(),
  updatedAt: new Date(),
  hidden: false,
  can_offer_api: false,
  admin_remarks: '',
  createdBy: 0,
  segment: '',
};
export const defaultEmptyUser: User = {
  id: 0,
  Company: defaultEmptyCompany,
  isDefaultBroker: false,
  isAdmin: false,
  isBeta: false,
  lastName: '',
  firstName: '',
  fullName: '',
  email: '',
  uuid: '',
  verified: false,
  verificationToken: '',
  isBuyer: false,
  isSeller: false,
  intercomHash: '',
  ownsProduct: false,
};

export const defaultStore: State = {
  loggedIn: false,
  loggedInUser: defaultEmptyUser,
  registerError: '',
  loginError: '',
  loginSuccess: '',
  linkedinLoginSuccess: false,
  navbarCount: {
    productCount: 0,
    dealCount: 0,
    dealNotSeenCount: 0,
    dealsBuyingCount: 0,
    dealsBuyingNotSeenCount: 0,
    dealsSellingCount: 0,
    dealsSellingNotSeenCount: 0,
    onboardingCount: 0,
    archiveCount: 0,
    marketplaceExistingDealIds: [],
  },
  userSettings: defaultEmptyUser,
  userCompany: defaultEmptyCompany,
  notifications: { error: '', success: '' },
  authCheckComplete: false,
  registerModalOpen: false,
  getStartedModalOpen: false,
  verifyEmailModalOpen: false,
  buyerQualificationModalOpen: false,
  buyerQualificationModalShown: false,
  createAccountModalOpen: false,
  resetPasswordModal: false,
  navbarCountFetchComplete: false,
  buyerInformationAdminViewDealId: 0,
  buyerInformationAdminViewModalOpen: false,
};
