import { Breakpoint } from 'app/styles/breakpoint';
import { all, fork, put, take } from 'redux-saga/effects';
import {
  setBreakpoint,
  setFirstBreakpointSet,
  setServiceWorkerInstalled,
  setServiceWorkerUpdateAvailable,
  setWidth,
} from './actions';
import {
  createBreakpointChannel,
  createServiceWorkerChannel,
  ServiceWorkerChannelAction,
} from './channels';

function* serviceWorkerFlow() {
  if (process.env.TARGET !== 'browser') {
    return;
  }

  const channel = createServiceWorkerChannel();

  while (true) {
    const { type }: ServiceWorkerChannelAction = yield take(channel);

    if (type === 'success') {
      yield put(setServiceWorkerInstalled(true));
    }

    if (type === 'update') {
      yield put(setServiceWorkerUpdateAvailable(true));
    }
  }
}

function* breakpointFlow() {
  if (process.env.TARGET !== 'browser') {
    return;
  }

  const channel = createBreakpointChannel();

  while (true) {
    const {
      breakpoint,
      width,
    }: { breakpoint: Breakpoint; width: number } = yield take(channel);

    yield put(setBreakpoint(breakpoint));
    yield put(setFirstBreakpointSet(true));
    yield put(setWidth(width));
  }
}

export default function* rootSaga() {
  yield all([fork(serviceWorkerFlow), fork(breakpointFlow)]);
}
