import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import * as A from './actions';
import { Notifications, State } from './types';

export default combineReducers<State>({
  error: cr<Notifications>(
    { listError: '', listSuccess: '' },
    reduce(A.setCompaniesContactNotification, set)
  ),
  isContactModalOpen: cr<boolean>(false, reduce(A.setContactModalOpen, set)),
  isSellAssetsModalOpen: cr<boolean>(false, reduce(A.setAssetsModalOpen, set)),
});
