import React, { useState } from 'react';
import styled from 'styled-components';

// custom imports
import BuyerQualification from 'app/modules/admin/buyer-qualification';
import Modal from 'app/components/modals/overrided-modal';
import { _, media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import { IProps } from './index';

//
const ModalContainer = styled.div`
  margin: 15px;
  z-index: 100;
  padding-top: 0;
  align-items: flex-start;
  ${_(media.max.lg)} {
    padding-top: 0;
    margin: 5px;
  }
  ${_(media.max.md)} {
    margin: 0;
  }
  ${_(media.max.sm)} {
    margin: 0;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
  position: relative;
  > div {
    position: absolute;
    z-index: 99;
    margin: 0 auto;
    right: 35px;
    top: 0;
  }
`;

const CloseIcon = styled.img`
  margin-top: 16px;
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: fixed;
`;

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderWidth: 0,
    borderRadius: 6,
    boxShadow: colors.modalShadow,
    overflow: 'auto',
    position: 'static',
    margin: 'auto',
    maxHeight: '100%',
  },
  overlay: {
    backgroundColor: colors.darkModalOverlay,
    zIndex: 999,
    display: 'flex',
  },
};

const BuyerQualificationModal: React.FC<IProps> = ({
  userSettings,
  closeModal,
  isOpen,
  company,
}) => {
  const [name, setName] = useState(company.name);
  const [summary, setSummary] = useState(company.summary);
  const [numberOfEmployees, setNumberOfEmployees] = useState(
    company.numberOfEmployees
  );
  const [annualRevenue, setAnnualRevenue] = useState(company.annualRevenue);
  const [salesChannels, setSalesChannels] = useState(company.salesChannels);
  const [therapyAreas, setTherapyAreas] = useState(company.therapyAreas);
  const [portfolioProductsList, setPortfolioProductsList] = useState(
    company.portfolioProductsList
  );

  const handleClose = () => {
    closeModal(
      {
        ...userSettings,
      },
      {
        ...company,
        name,
        summary,
        numberOfEmployees,
        annualRevenue,
        salesChannels,
        therapyAreas,
        portfolioProductsList,
      }
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles as any}
      contentLabel="Additional details"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >
      <IconContainer>
        <div onClick={handleClose}>
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer>
        <BuyerQualification
          name={name}
          setName={setName}
          summary={summary}
          setSummary={setSummary}
          numberOfEmployees={numberOfEmployees}
          setNumberOfEmployees={setNumberOfEmployees}
          annualRevenue={annualRevenue}
          setAnnualRevenue={setAnnualRevenue}
          salesChannels={salesChannels}
          setSalesChannels={setSalesChannels}
          therapyAreas={therapyAreas}
          setTherapyAreas={setTherapyAreas}
          portfolioProductsList={portfolioProductsList}
          setPortfolioProductsList={setPortfolioProductsList}
          closeModal={closeModal}
        />
      </ModalContainer>
    </Modal>
  );
};

export default BuyerQualificationModal;
