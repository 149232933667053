import React, { useState } from 'react';

// custom imports
import SaveButton from 'app/components/buttons/save-button/save-button';
import TextInput from 'app/components/inputs/text-input';
import { EditButton } from '../seller-contact/seller-contact-list';
import { CancelButton, SaveButtonContainer } from '../styles';
import {
  ButtonContainer,
  EditActions,
  EditButtonContainer,
  EditRemarksContainer,
  HeaderContainer,
  RemarksBox,
  RemarksContainer,
  RemarksContent,
  RemarksText,
} from '../styles';

interface IProps {
  remarks: string;
  updateRemarks: (remarks: string) => any;
  setEditDisabled?: (editDisabled: boolean) => any;
  updateCache: any;
}

const EditRemarks: React.FC<IProps> = ({
  remarks,
  updateRemarks,
  setEditDisabled,
}) => {
  const [remarksValue, setRemarksValue] = useState<string>(remarks);
  return (
    <EditRemarksContainer>
      <TextInput
        multiline={true}
        value={remarksValue}
        onChange={(value) => setRemarksValue(value)}
      />
      <EditActions>
        <ButtonContainer>
          <CancelButton
            onClick={() => {
              if (setEditDisabled) {
                setEditDisabled(true);
              }
            }}
          >
            Cancel
          </CancelButton>
        </ButtonContainer>
        <SaveButtonContainer>
          <SaveButton
            onClick={() => {
              updateRemarks(remarksValue);
              if (setEditDisabled) {
                setEditDisabled(true);
              }
            }}
          />
        </SaveButtonContainer>
      </EditActions>
    </EditRemarksContainer>
  );
};

const Remarks: React.FC<IProps> = ({ remarks, updateCache, updateRemarks }) => {
  const headerText = 'Remarks';
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  let adminRemarksText = '';
  if (remarks) {
    adminRemarksText = remarks || updateCache.adminRemarks;
  }

  return (
    <RemarksBox>
      {editDisabled && (
        <RemarksContainer showBorder={false}>
          <HeaderContainer>{headerText}</HeaderContainer>
          <RemarksContent>
            <RemarksText>
              {adminRemarksText || 'No remarks available'}
            </RemarksText>
            <EditButtonContainer>
              <EditButton
                editDisabled={editDisabled}
                setEditDisabled={setEditDisabled}
              />
            </EditButtonContainer>
          </RemarksContent>
        </RemarksContainer>
      )}
      {!editDisabled && (
        <RemarksContainer showBorder={true}>
          <HeaderContainer>{headerText}</HeaderContainer>
          <EditRemarks
            remarks={adminRemarksText as string}
            updateRemarks={updateRemarks}
            updateCache={updateCache}
            setEditDisabled={setEditDisabled}
          />
        </RemarksContainer>
      )}
    </RemarksBox>
  );
};

export default Remarks;
