import { createActionCreator } from '@reduxify/utils';
import { Breakpoint } from 'app/styles/breakpoint';

export const setServiceWorkerInstalled = createActionCreator<boolean>(
  'device/SET_SERVICE_WORKER_INSTALLED'
);
export const setServiceWorkerUpdateAvailable = createActionCreator<boolean>(
  'device/SET_SERVICE_WORKER_UPDATE_AVAILABLE'
);
export const setBreakpoint = createActionCreator<Breakpoint>(
  'device/SET_BREAKPOINT'
);
export const setWidth = createActionCreator<number>('device/SET_WIDTH');

export const setFirstBreakpointSet = createActionCreator<boolean>(
  'device/SET_FIRST_BREAKPOINT_SET'
);
