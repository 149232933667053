import sg from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { ContactUsFields } from '../types';

export interface ICreateContactUs extends IWithApolloClient {
  values: ContactUsFields;
}
export interface ICreateContactUsReturn {
  insert_contact_form_request: {
    returning: {
      id: number;
    }[];
  };
}
export async function createContactUsQuery(p: ICreateContactUs) {
  try {
    return await p.client.mutate<ICreateContactUsReturn>({
      mutation: gql`
        mutation isert_contact(
          $fullName: String
          $businesEmail: String
          $position: String
          $company: String
          $phone: String
          $message: String
          $agreeWithTerms: Boolean
          $agreeWithNews: Boolean
          $country: String
          $createdAt: timestamptz
        ) {
          insert_contact_form_request(
            objects: [
              {
                full_name: $fullName
                phone_number: $phone
                agree_with_newsletter: $agreeWithNews
                agree_with_terms: $agreeWithTerms
                business_email: $businesEmail
                company: $company
                position: $position
                message: $message
                country: $country
                created_at: $createdAt
              }
            ]
          ) {
            returning {
              id
            }
          }
        }
      `,
      variables: {
        fullName: p.values.fullName,
        businesEmail: p.values.businessEmail,
        position: p.values.position,
        company: p.values.company,
        phone: p.values.phoneNumber,
        message: sg(() => p.values.message, ''),
        agreeWithTerms: p.values.agreeWithTerms,
        agreeWithNews: p.values.agreeWithNews,
        country: p.values.country,
        createdAt: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/contact-us/query.ts -> createContactUsQuery',
      e
    );
  }
}
