import gql from 'graphql-tag';

import { IWithApolloClient } from 'app/store/modules/global/types';
import { logger } from 'app/utils/logger';
import * as u from '../../query-utils';
import { ITermsheet } from '../types';

export interface IGetTermsheetReturn {
  returning: ITermsheet[];
}

export interface IGetLatestTermsheet extends IWithApolloClient {
  request_id: number;
}

export async function getLatestTermsheetQuery({
  client,
  request_id,
}: IGetLatestTermsheet) {
  try {
    // @ts-ignore
    return await client.query<IGetTermsheetReturn>({
      query: gql`
        query latestTermsheet(${u.TermsheetUtils.params([
          '$request_id',
          'Int',
        ])}) {
          returning: termsheets(
            limit: 1
            ${u.orderBy('created_at', 'desc')}
            ${u.where(u._eq('request_id', '$request_id'))}
            ) {
              id
              created_at
              target_markets
              annual_forecast
              net_license_fee
              gross_license_fee
              pricing_model
              net_selling_price_revenue_share_pct
              gross_selling_price_revenue_share_pct
              currency
              buyer_company_id
              seller_company_id
              buyer_user_id
              seller_user_id
              originated_date
              shared_date
              license_fee_commision_pct
              incoterm
              delivery_destination

              SupplyUnitPrices {
                id
                created_at
                termsheet_id
                strength
                number_of_units
                unit_name
                net_supply_price
                gross_supply_price
                buyer_company_id
                seller_company_id
                buyer_user_id
                seller_user_id
                supply_price_commision_pct
              }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        request_id,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> getLatestTermsheetQuery',
      e
    );
  }
}
