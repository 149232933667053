import Checkbox from 'app/components/inputs/check-box';
import { formatDateString } from 'app/utils/format-datestring';
import React, { useEffect, useState } from 'react';
import Datepicker from '../../../../components/inputs/datepicker';
import { ISignedInfo } from '../seller-information';
import {
  CheckboxContainer,
  DateContainer,
  GrayColoredSpan,
  LabelAdditionalContainer,
  LabelContainer,
  SignedInfoContainer,
} from '../styles';

interface IProps {
  signed: ISignedInfo;
  buyerDisclosureRequired: (
    companyId: number,
    isEarlyBuyerDisclosureRequired: boolean
  ) => any;
  companyHidden: (companyId: number, isCompanyHidden: boolean) => any;
  updateCDASignedDetails: (
    companyId: number,
    CDASigned: boolean,
    signedOn: Date | undefined,
    expireOn: Date | undefined
  ) => any;
  updateCache: any;
}

interface ICDASigned {
  checkedCDA: boolean;
  signedDate: Date | undefined;
  expireDate: Date | undefined;
  submitCDA: (
    isChecked: boolean,
    signedDate: Date | undefined,
    expireDate: Date | undefined
  ) => any;
}

const CDASigned: React.FC<ICDASigned> = ({
  checkedCDA,
  signedDate,
  expireDate,
  submitCDA,
}) => {
  const [CDASignedDate, setCDASignedDate] = useState<Date | undefined>(
    signedDate
  );
  const [CDAExpireDate, setCDAExpireDate] = useState<Date | undefined>(
    expireDate
  );

  useEffect(() => {
    if (!checkedCDA) {
      setCDASignedDate(undefined);
      setCDAExpireDate(undefined);
    }
  }, [checkedCDA]);

  return (
    <LabelContainer top={checkedCDA ? '17px' : '0px'}>
      <LabelAdditionalContainer>
        CDA signed{' '}
        {checkedCDA && (
          <>
            <GrayColoredSpan>on </GrayColoredSpan>
            <DateContainer hideBorderAndIcon={CDASignedDate ? true : false}>
              <Datepicker
                topMost={true}
                setSelectedDate={(str, date) => {
                  setCDASignedDate(date as Date);
                  submitCDA(checkedCDA, date as Date, CDAExpireDate);
                }}
                selectedDate={CDASignedDate}
                selectedDateString={
                  CDASignedDate ? formatDateString(CDASignedDate) : ''
                }
                label=""
                placeholder="select date"
              />
            </DateContainer>
          </>
        )}
      </LabelAdditionalContainer>
      {checkedCDA && (
        <LabelAdditionalContainer>
          <GrayColoredSpan paddingLeft="0px">Expires on </GrayColoredSpan>
          <DateContainer hideBorderAndIcon={CDAExpireDate ? true : false}>
            <Datepicker
              topMost={true}
              setSelectedDate={(str, date) => {
                setCDAExpireDate(date as Date);
                submitCDA(checkedCDA, CDASignedDate, date as Date);
              }}
              selectedDate={CDAExpireDate}
              selectedDateString={
                CDAExpireDate ? formatDateString(CDAExpireDate) : ''
              }
              label=""
              placeholder="select date"
            />
          </DateContainer>
        </LabelAdditionalContainer>
      )}
    </LabelContainer>
  );
};

const SignedInfo: React.FC<IProps> = ({
  signed,
  buyerDisclosureRequired,
  companyHidden,
  updateCDASignedDetails,
  updateCache,
}) => {
  const [checkedCDA, setCheckedCDA] = useState<boolean>(
    signed!.isSellerCDASigned || false
  );
  const [checkedNeedClientName, setCheckedNeedClientName] = useState<boolean>(
    signed!.isEarlyBuyerDisclosureRequired || false
  );
  const [checkedHidden, setCheckedHidden] = useState<boolean>(
    signed!.isCompanyHidden || false
  );

  const submitCDA = (
    isChecked: boolean,
    signedDate: Date | undefined,
    expireDate: Date | undefined
  ) => {
    updateCDASignedDetails(
      signed!.companyId,
      isChecked,
      signedDate,
      expireDate
    );
  };

  useEffect(() => {
    if (signed.isSellerCDASigned) {
      setCheckedCDA(signed.isSellerCDASigned);
    }
    if (signed.isEarlyBuyerDisclosureRequired) {
      setCheckedNeedClientName(signed.isEarlyBuyerDisclosureRequired);
    }
    if (signed.isCompanyHidden) {
      setCheckedHidden(signed.isCompanyHidden);
    }
  }, []);

  useEffect(() => {
    buyerDisclosureRequired(signed!.companyId, checkedNeedClientName);
  }, [checkedNeedClientName]);
  useEffect(() => {
    companyHidden(signed!.companyId, checkedHidden);
  }, [checkedHidden]);
  useEffect(() => {
    if (!checkedCDA && signed!.isSellerCDASigned) {
      updateCDASignedDetails(signed!.companyId, false, undefined, undefined);
    }
  }, [checkedCDA]);

  return (
    <SignedInfoContainer>
      <div>
        <CheckboxContainer>
          <Checkbox
            label={
              <CDASigned
                checkedCDA={checkedCDA}
                signedDate={signed!.sellerCDASignatureDate}
                expireDate={signed!.sellerCDAExpiryDate}
                submitCDA={submitCDA}
              />
            }
            marginTop="0px"
            value={checkedCDA}
            onChange={(val) => setCheckedCDA(val)}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            label={
              <LabelAdditionalContainer>
                Needs to know client name
              </LabelAdditionalContainer>
            }
            marginTop="45px"
            value={checkedNeedClientName}
            onChange={(val) => setCheckedNeedClientName(val)}
          />
        </CheckboxContainer>
        <CheckboxContainer>
          <Checkbox
            label={<LabelAdditionalContainer>Hidden</LabelAdditionalContainer>}
            marginTop="45px"
            value={checkedHidden}
            onChange={(val) => setCheckedHidden(val)}
          />
        </CheckboxContainer>
      </div>
    </SignedInfoContainer>
  );
};

export default SignedInfo;
