/* eslint-disable no-magic-numbers */
/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
/* eslint-disable @typescript-eslint/naming-convention */

import sg from 'app/utils/safe-get';
import { IFragment, isIFragment } from './fragment';
import * as Schemas from './schema';
import { Schema } from './schema/schema';
import { Schema_SubstancesTherapyAreas } from './schema/substances-therapy-areas';
import { Schema_TherapyArea } from './schema/therapy-areas';
import { Schema_SubstanceBrands } from './schema/substance-brands';

export type Keys<T> = {
  [K in keyof T]: T[K] extends string | number | boolean | Date ? K : never;
}[keyof T];
export type Objects<T> = {
  [K in keyof T]: T[K] extends string | number | boolean | Date ? never : K;
}[keyof T];

export function _or(
  ...parts: (string | boolean | undefined)[]
): string | boolean {
  const p = parts.filter((x) => typeof x === 'string');
  if (p.length === 0) {
    return false;
  }
  return `_or: [${p.map((v) => `{${v}}`).join(' ')}]`;
}
export function _and(
  ...parts: (string | boolean | undefined)[]
): string | boolean {
  const p = parts.filter((x) => typeof x === 'string');
  if (p.length === 0) {
    return false;
  }
  return `_and: [${p.map((v) => `{${v}}`).join(' ')}]`;
}
export function _eq<T = any>(field: Keys<T>, value: any): string {
  return `${field}: { _eq: ${value} }`;
}
export function _is_null<T = any>(field: Keys<T>, b = true): string {
  return `${field}: { _is_null: ${b} }`;
}
export function _ilike<T = any>(field: Keys<T>, value: string): string {
  return `${field}: { _ilike: ${value} }`;
}
export function _nilike<T = any>(field: Keys<T>, value: string): string {
  return `${field}: { _nilike: ${value} }`;
}
export function like(
  value: string,
  type: 'begining' | 'end' | 'middle' | 'exact'
): string {
  if (!value || value.length === 0) {
    if (type === 'exact') {
      return '';
    }
    return '%';
  }
  switch (type) {
    case 'begining':
      return `${value}%`;
    case 'end':
      return `%${value}`;
    case 'middle':
      return `%${value}%`;
    case 'exact':
      return value;
  }

  return '';
}
export function where(condition: string | boolean | undefined): string {
  if (
    !condition ||
    typeof condition === 'boolean' ||
    (typeof condition === 'string' && condition.length === 0)
  ) {
    return '';
  }
  return ` where: {${condition}} `;
}
export function orderBy<T = any>(
  field: Keys<T>,
  direction: 'asc' | 'desc'
): string {
  return ` order_by: { ${field}: ${direction} } `;
}
export function orderByMany<T = any>(
  ...orders: { field: Keys<T>; direction: 'asc' | 'desc' }[]
): string {
  return ` order_by: { ${orders.map((v) => `${v.field}: ${v.direction} `)} } `;
}

export function nest<T = any>(
  field: Objects<T>,
  ...values: (string | boolean)[]
): string {
  return `${field}: { ${values.join(' ')} }`;
}

export function nestF<T = any>(
  field: Objects<T>,
  u: (utils: Utils<any>) => string | string[]
): string {
  const r = u(new Utils<any>());
  return Array.isArray(r) ? nest(field, ...r) : nest(field, r);
}
export function variable(name: string, n?: string | number): string {
  if (typeof n === 'string' || typeof n === 'number') {
    return `${name}: ${n.toString()} `;
  }
  return '';
}
export function limit(n?: string | number): string {
  if (typeof n === 'string' || typeof n === 'number') {
    return `limit: ${n.toString()} `;
  }
  return '';
}
export function offset(n?: string | number): string {
  if (typeof n === 'string' || typeof n === 'number') {
    return `offset: ${n.toString()} `;
  }
  return '';
}
export function _neq<T = any>(field: Keys<T>, value: any): string {
  return `${field}: { _neq: ${value} } `;
}
export function _gt<T = any>(field: Keys<T>, value: any): string {
  return `${field}: { _gt: ${value} } `;
}
export function _gte<T = any>(field: Keys<T>, value: any): string {
  return `${field}: { _gte: ${value} } `;
}
export function _lt<T = any>(field: Keys<T>, value: any): string {
  return `${field}: { _lt: ${value} } `;
}
export function _lte<T = any>(field: Keys<T>, value: any): string {
  return `${field}: { _lte: ${value} } `;
}
export function _in<T = any>(
  field: Keys<T>,
  value: (string | number)[]
): string {
  return `${field}:{_in:[${value.join(',')}]}`;
}
export function distinct<T = any>(...fields: Keys<T>[]): string {
  return `distinct_on:[${fields.join(',')}]`;
}
export function escape(value: string): string {
  return `"${value.split('"').join('\\"')}"`;
}
type Optional<T> = T | boolean | undefined | null;
export type ISet<T> = Optional<[Keys<T>, any]>;
export function _set<T = any>(...fields: ISet<T>[]): string {
  return `_set: { ${fields
    .map((v) => (isTuple(v) ? `${v[0]}: ${v[1]}` : ''))
    .join(' ')} } `;
}
export function _objects<T = any>(...rows: ISet<T>[][]): string {
  return `objects: [${rows
    .map(
      (v) =>
        `{${v
          .map((val) => (isTuple(val) ? `${val[0]}: ${val[1]}` : null))
          .filter((val) => val !== null)
          .join(' ')}}`
    )
    .join(' ')}]`;
}

type ITypes =
  | 'String'
  | 'Int'
  | 'Boolean'
  | 'timestamptz'
  | 'numeric'
  | 'timestamp'
  | 'float8';
type IMandatoryTypes =
  | 'String!'
  | 'Int!'
  | 'Boolean!'
  | 'timestamptz!'
  | 'numeric!'
  | 'timestamp!'
  | 'float8!';
type IParamsTuple = [string, ITypes | IMandatoryTypes];
export type IParams = Optional<IParamsTuple>;
export function params(...p: IParams[]): string {
  return p
    .map((v) => {
      if (isTuple(v)) {
        return `${v[0]}: ${v[1]}`;
      }
      return '';
    })
    .join(' ');
}

interface Subquery {
  where?: string;
  limit?: number | string;
  offset?: number;
  order?: string;
}
export type Alias<T> =
  | {
      [K in keyof T]: T[K] extends string | number | boolean | Date
        ? K | [K, string]
        : [K | [K, Subquery], string | boolean, string | IFragment];
    }[keyof T]
  | IFragment;

export type ISelectParams<T> = Optional<Alias<T>>[];
export function selectParams<T>(...fields: Optional<Alias<T>>[]): string {
  const mapFields = (f: Optional<Alias<T>>[]): string =>
    f
      .map((v) => {
        if (isIFragment(v)) {
          return `...${v.name}`;
        }
        if (typeof v === 'boolean' || v === undefined || v === null) {
          return '';
        }
        if (typeof v === 'string') {
          return v;
        }
        if (isTuple(v)) {
          return `${v[1]}: ${v[0]}`;
        }
        if (isTriple(v)) {
          const p = isIFragment(v[2]) ? `...${v[2].name}` : v[2];
          const table = isTuple(v[0])
            ? `${v[0][0]}${
                ((t: Subquery) => {
                  if (t.limit) {
                    return true;
                  }
                  if (t.offset) {
                    return true;
                  }
                  if (t.order && t.order.length > 0) {
                    return true;
                  }
                  if (t.where && t.where.length > 0) {
                    return true;
                  }
                  return false;
                })(v[0][1])
                  ? `(${sg(() => limit((v[0][1] as Subquery).limit), '')} ${sg(
                      () => offset((v[0][1] as Subquery).offset),
                      ''
                    )} ${sg(() => (v[0][1] as Subquery).order, '')} ${sg(
                      () => (v[0][1] as Subquery).where,
                      ''
                    )})`
                  : ''
              }`
            : v[0];
          if (typeof v[1] === 'boolean') {
            return `${table} { ${p} }`;
          }
          return `${v[1]}: ${table} { ${p} }`;
        }
        return '';
      })
      .join(' ');
  return mapFields(fields);
}

function isTuple(v: any): v is [string, any] {
  return Array.isArray(v) && v.length === 2;
}
function isTriple(v: any): v is [string, string | boolean, string | IFragment] {
  return (
    Array.isArray(v) &&
    v.length === 3 &&
    (typeof v[0] === 'string' || isTuple(v[0])) &&
    (typeof v[1] === 'string' || typeof v[1] === 'boolean') &&
    (typeof v[2] === 'string' || isIFragment(v[2]))
  );
}

export class Utils<T> {
  public distinct(...fields: Keys<T>[]) {
    return distinct<T>(...fields);
  }
  public _in(field: Keys<T>, value: (string | number)[]) {
    return _in<T>(field, value);
  }
  public _or(...parts: (string | boolean | undefined)[]): string | boolean {
    return _or(...parts);
  }
  public _and(...parts: (string | boolean | undefined)[]): string | boolean {
    return _and(...parts);
  }
  public _eq(field: Keys<T>, value: any): string {
    return _eq<T>(field, value);
  }
  public _is_null(field: Keys<T>, b = true): string {
    return _is_null<T>(field, b);
  }
  public _ilike(field: Keys<T>, value: string): string {
    return _ilike<T>(field, value);
  }
  public _nilike(field: Keys<T>, value: string): string {
    return _nilike<T>(field, value);
  }
  public like(
    value: string,
    type: 'begining' | 'end' | 'middle' | 'exact'
  ): string {
    return like(value, type);
  }
  public where(condition: string | boolean | undefined): string {
    return where(condition);
  }
  public orderBy(field: Keys<T>, direction: 'asc' | 'desc'): string {
    return orderBy(field, direction);
  }
  public orderByMany(
    ...orders: { field: Keys<T>; direction: 'asc' | 'desc' }[]
  ): string {
    return orderByMany(...orders);
  }
  public nest(field: Objects<T>, ...values: (string | boolean)[]): string {
    return nest<T>(field, ...values);
  }
  public nestF(field: Objects<T>, u: (utils: Utils<any>) => string | string[]) {
    return nestF<T>(field, u);
  }
  public limit(n: string): string {
    return limit(n);
  }
  public offset(n: string): string {
    return offset(n);
  }
  public _neq(field: Keys<T>, value: any): string {
    return _neq(field, value);
  }
  public _gt(field: Keys<T>, value: any): string {
    return _gt(field, value);
  }
  public _gte(field: Keys<T>, value: any): string {
    return _gte(field, value);
  }
  public _lt(field: Keys<T>, value: any): string {
    return _lt(field, value);
  }
  public _lte(field: Keys<T>, value: any): string {
    return _lte(field, value);
  }
  public escape(value: string): string {
    return escape(value);
  }
  public _set(...fields: ISet<T>[]): string {
    return _set<T>(...fields);
  }
  public _objects(...rows: ISet<T>[][]): string {
    return _objects<T>(...rows);
  }
  public params(...p: IParams[]): string {
    return params(...p);
  }
  public selectParams(...fields: Optional<Alias<T>>[]): string {
    return selectParams(...fields);
  }
}

export const CompanyUtils = new Utils<Schemas.Schema_Company>();
export const RequestUtils = new Utils<Schemas.Schema_Request>();
export const DealUtils = new Utils<Schemas.Schema_Deal>();
export const MACompanyUtils = new Utils<Schemas.Schema_MACompany>();
export const ProductUtils = new Utils<Schemas.Schema_Product>();
export const RoleUtils = new Utils<Schemas.Schema_Role>();
export const SubstanceUtils = new Utils<Schemas.Schema_Substance>();
export const MarketplaceAutocompleteUtils = new Utils<Schemas.Schema_MarketplaceAutocomplete>();
export const UserUtils = new Utils<Schemas.Schema_User>();
export const ProductListUtils = new Utils<Schemas.Schema_ProductList>();
export const AdminOnboardUtils = new Utils<Schemas.Schema_AdminOnboard>();
export const DealMessagesUtils = new Utils<Schemas.Schema_DealMessage>();
export const DealTermsheetUtils = new Utils<Schemas.Schema_Termsheet>();
export const ProductRequest = new Utils<Schemas.Schema_ProductRequest>();
export const BannerSeenUtils = new Utils<Schemas.Schema_BannerSeen>();
export const TermsheetUtils = new Utils<Schemas.Schema_Termsheet>();
export const SupplyUnitPricesUtils = new Utils<Schemas.Schema_SupplyUnitPrice>();
export const ContactPersonUtils = new Utils<Schemas.Schema_ContactPerson>();
export const SubstancesTherapyAreasUtils = new Utils<Schema_SubstancesTherapyAreas>();
export const TherapyAreasUtils = new Utils<Schema_TherapyArea>();
export const SubstanceBrandsUtils = new Utils<Schema_SubstanceBrands>();

export class Query {
  private _params: IParams[];
  private _fragments: IFragment[];
  private queries: OneQuery<any>[];
  private aggregates: Aggregate<any>[];
  private name: string;
  private options?: {
    isAdmin?: boolean;
    isLogged?: boolean;
    isElastic?: boolean;
  };

  public constructor(
    name: string,
    options?: {
      isAdmin?: boolean;
      isLogged?: boolean;
      isElastic?: boolean;
    }
  ) {
    this._params = [];
    this._fragments = [];
    this.queries = [];
    this.aggregates = [];
    this.name = name;
    this.options = options;
  }
  public fragments(...f: IFragment[]): Query {
    this._fragments.push(...f);
    return this;
  }
  public params(...p: IParams[]): Query {
    this._params.push(...p);
    return this;
  }

  public query<T>(schema: Schema<T>, alias?: string): OneQuery<T> {
    const q = new OneQuery<T>(schema.tableName, this, alias);
    this.queries.push(q);
    return q;
  }
  public aggregate<T>(
    schema: Schema<T>,
    alias?: string,
    aggregateAlias?: string
  ): Aggregate<T> {
    const a = new Aggregate<T>(schema.aggreagate, this, alias, aggregateAlias);
    this.aggregates.push(a);
    return a;
  }

  public toString() {
    /* eslint-disable  */
    return `
      ${
        this._fragments.length > 0
          ? this._fragments
              .map(v =>
                v.fragment(
                  this.options ? this.options.isAdmin : false,
                  this.options ? this.options.isLogged : false,
                  this.options ? this.options.isElastic : false
                )
              )
              .join('')
          : ''
      }
      query ${this.name}${
      this._params.length > 0 ? `(${params(...this._params)})` : ''
    }{
        ${this.queries.map(q => {
          const hasProps =
            q._where.length > 0 ||
            q._limit.length > 0 ||
            q._offset.length > 0 ||
            q._orderBy[0].length > 0;
          return `${q.alias ? `${q.alias}:` : ''}${q.schema}${
            hasProps
              ? `(${q._where.length > 0 ? q._where : ''} ${
                  q._limit.length > 0 ? limit(q._limit) : ''
                } ${q._offset.length > 0 ? offset(q._offset) : ''} ${
                  q._orderBy[0].length > 0
                    ? orderBy<any>(q._orderBy[0], q._orderBy[1] as any)
                    : ''
                })`
              : ''
          }{
            ${selectParams<any>(...q._selectParams)}
          }`;
        })}
        ${this.aggregates.map(a => {
          const hasProps = a._where.length > 0;
          return `${a.alias ? `${a.alias}:` : ''}${a.schema}${
            hasProps ? `(${a._where})` : ''
          }{
            ${a.aggregateAlias ? `${a.aggregateAlias}:` : ''}aggregate{
              ${
                a._count
                  ? `${a.countAlias ? `${a.countAlias}:` : ''}count,`
                  : ''
              }
              ${a._min.length > 0 ? `min{${a._min.join(',')}}` : ''}
              ${a._max.length > 0 ? `max{${a._max.join(',')}}` : ''}
              ${a._avg.length > 0 ? `avg{${a._avg.join(',')}}` : ''}
            }
          }`;
        })}
      }
    `;
  }
}
export class Mutation {
  private _params: IParams[];
  private _fragments: IFragment[];
  private updates: OneMutation_Update<any>[];
  private deletes: OneMutation_Delete<any>[];
  private inserts: OneMutation_Insert<any>[];
  private name: string;
  private options?: {
    isAdmin?: boolean;
    isLogged?: boolean;
    includeDeals?: boolean;
  };

  public constructor(
    name: string,
    options?: {
      isAdmin?: boolean;
      isLogged?: boolean;
      includeDeals?: boolean;
    }
  ) {
    this._fragments = [];
    this._params = [];
    this.updates = [];
    this.deletes = [];
    this.inserts = [];
    this.name = name;
    this.options = options;
  }
  public fragments(...f: IFragment[]): Mutation {
    this._fragments.push(...f);
    return this;
  }
  public params(...p: IParams[]): Mutation {
    this._params.push(...p);
    return this;
  }

  public update<T>(schema: Schema<T>, alias?: string): OneMutation_Update<T> {
    const q = new OneMutation_Update<T>(schema.update, this, alias);
    this.updates.push(q);
    return q;
  }
  public delete<T>(schema: Schema<T>, alias?: string): OneMutation_Delete<T> {
    const q = new OneMutation_Delete<T>(schema.delete, this, alias);
    this.deletes.push(q);
    return q;
  }
  public insert<T>(schema: Schema<T>, alias?: string): OneMutation_Insert<T> {
    const q = new OneMutation_Insert<T>(schema.insert, this, alias);
    this.inserts.push(q);
    return q;
  }

  public toString() {
    /* eslint-disable  */
    return `
      ${
        this._fragments.length > 0
          ? this._fragments
              .map(v =>
                v.fragment(
                  this.options ? this.options.isAdmin : false,
                  this.options ? this.options.isLogged : false,
                  this.options ? this.options.includeDeals : false
                )
              )
              .join('')
          : ''
      }
      mutation ${this.name}${
      this._params.length > 0 ? `(${params(...this._params)})` : ''
    }{
        ${this.updates
          .map(q => {
            const hasProps = q._where.length > 0 || q._set.length > 0;
            return `${q.alias ? `${q.alias}:` : ''}${q.schema}${
              hasProps ? `(${q._where} ${_set<any>(...q._set)})` : ''
            }{returning{${selectParams<any>(...q._selectParams)}}}`;
          })
          .join(',')}
        ${this.deletes
          .map(q => {
            const hasProps = q._where.length > 0;
            return `${q.alias ? `${q.alias}:` : ''}${q.schema}${
              hasProps ? `(${q._where})` : ''
            }{returning{${selectParams<any>(...q._selectParams)}}}`;
          })
          .join(',')}
        ${this.inserts
          .map(q => {
            const hasProps = q._objects.length > 0;
            return `${q.alias ? `${q.alias}:` : ''}${q.schema}${
              hasProps ? `(${_objects<any>(...q._objects)})` : ''
            }{returning{${selectParams<any>(...q._selectParams)}}}`;
          })
          .join(',')}
      }
    `;
    /* eslint-enable  */
  }
}
class OneQuery<T> {
  public _where: string;
  public _selectParams: Optional<Alias<T>>[];
  public schema: string;
  public alias?: string;
  public _limit: string;
  public _offset: string;
  public _orderBy: [string, string];
  private parent: Query;

  public constructor(schema: string, parent: Query, alias?: string) {
    this._where = '';
    this._selectParams = [];
    this.schema = schema;
    this._limit = '';
    this._offset = '';
    this.parent = parent;
    this.alias = alias;
    this._orderBy = ['', ''];
  }

  public where(
    u: (utils: Omit<Utils<T>, 'where'>) => Params<typeof where>
  ): OneQuery<T> {
    this._where = where(u(new Utils<T>()));
    return this;
  }
  public select(...p: Optional<Alias<T>>[]): OneQuery<T> {
    this._selectParams.push(...p);
    return this;
  }
  public limit(l: string | number): OneQuery<T> {
    this._limit = l.toString();
    return this;
  }
  public offset(l: string | number): OneQuery<T> {
    this._offset = l.toString();
    return this;
  }
  public orderBy(field: Keys<T>, direction: 'asc' | 'desc'): OneQuery<T> {
    this._orderBy = [field as string, direction];
    return this;
  }
  public toString(): Query {
    return this.parent;
  }
}

type NumberBased<T> = {
  [K in keyof T]: T[K] extends number | Date ? K : never;
}[keyof T];
class Aggregate<T> {
  public _where: string;
  public _count: boolean;
  public countAlias?: string;
  public _max: NumberBased<T>[];
  public _min: NumberBased<T>[];
  public _avg: NumberBased<T>[];
  public schema: string;
  public parent: Query;
  public alias?: string;
  public aggregateAlias?: string;

  public constructor(
    schema: string,
    parent: Query,
    alias?: string,
    aggregateAlias?: string
  ) {
    this._where = '';
    this._count = false;
    this._max = [];
    this._min = [];
    this._avg = [];
    this.schema = schema;
    this.parent = parent;
    this.alias = alias;
    this.aggregateAlias = aggregateAlias;
  }

  public where(u: (utils: Omit<Utils<T>, 'where'>) => Params<typeof where>) {
    this._where = where(u(new Utils<T>()));
    return this;
  }
  public toString(): Query {
    return this.parent;
  }
  public count(alias?: string) {
    this._count = true;
    this.countAlias = alias;
    return this;
  }
  public max(...fields: NumberBased<T>[]) {
    this.countable(this._max, ...fields);
    return this;
  }
  public min(...fields: NumberBased<T>[]) {
    this.countable(this._min, ...fields);
    return this;
  }
  public avg(...fields: NumberBased<T>[]) {
    this.countable(this._avg, ...fields);
    return this;
  }
  private countable(array: NumberBased<T>[], ...fields: NumberBased<T>[]) {
    array.push(...fields);
  }
}

export abstract class OneMutation<T> {
  public alias?: string;
  public schema: string;
  public _selectParams: Optional<Alias<T>>[];
  private parent: Mutation;

  protected constructor(schema: string, parent: Mutation, alias?: string) {
    this.parent = parent;
    this.alias = alias;
    this.schema = schema;
    this._selectParams = [];
  }

  public toString(): Mutation {
    return this.parent;
  }
  public returning(...p: Optional<Alias<T>>[]): OneMutation<T> {
    this._selectParams.push(...p);
    return this;
  }
}
class OneMutation_Update<T> extends OneMutation<T> {
  public _where: string;
  public _set: ISet<T>[];

  public constructor(schema: string, parent: Mutation, alias?: string) {
    super(schema, parent, alias);
    this._where = '';
    this._selectParams = [];
    this._set = [];
  }

  public where(
    u: (utils: Omit<Utils<T>, 'where'>) => Params<typeof where>
  ): OneMutation_Update<T> {
    this._where = where(u(new Utils<T>()));
    return this;
  }
  public set(...fields: ISet<T>[]): OneMutation_Update<T> {
    this._set.push(...fields);
    return this;
  }
}
class OneMutation_Delete<T> extends OneMutation<T> {
  public _where: string;

  public constructor(schema: string, parent: Mutation, alias?: string) {
    super(schema, parent, alias);
    this._where = '';
  }

  public where(
    u: (utils: Omit<Utils<T>, 'where'>) => Params<typeof where>
  ): OneMutation_Delete<T> {
    this._where = where(u(new Utils<T>()));
    return this;
  }
}
class OneMutation_Insert<T> extends OneMutation<T> {
  public _objects: ISet<T>[][];

  public constructor(schema: string, parent: Mutation, alias?: string) {
    super(schema, parent, alias);
    this._objects = [];
  }

  public objects(...rows: ISet<T>[][]): OneMutation_Insert<T> {
    this._objects.push(...rows);
    return this;
  }
}
