import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { FetchCompanyDirectoryCountrySEOTextProps } from '../actions';
import {
  companyDirectoryCountrySEOTextFragment,
  ICountryFragment,
} from '../fragments/countries';

// pvz iki h - tekstas 1, iki o - 2 tekastas iki z 3 tekstas
export interface FetchDirectoryCountrySEOTextByNameReturn {
  countries: ICountryFragment[];
}

export async function fetchDirectoryCountrySEOTextByName(
  p: FetchCompanyDirectoryCountrySEOTextProps
) {
  try {
    return await p.client.query<any>({
      query: gql`
      ${companyDirectoryCountrySEOTextFragment.fragment()}

      query countries(${u.MarketplaceAutocompleteUtils.params(
        ['$countryName', 'String'],
        ['$limit', 'Int']
      )}

      ) {
        countries( ${u.MarketplaceAutocompleteUtils.where(
          u._eq('name', '$countryName')
        )}
        ${u.limit('$limit')}) {
          ${
            //
            u.selectParams([
              'seo_text_footer_directory',
              'seoTextFooterDirectory',
            ])
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        countryName: p.countryName,
        limit: 1,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> fetchDirectoryCountrySEOTextByName',
      e
    );
  }
}
