import { setNotification } from 'app/store/modules/requests/actions';
import { ICreateDealForm } from 'app/store/modules/requests/types';
import { IStore } from 'app/store/types';
import { connect } from 'react-redux';
import MyDealsList from './my-requests-list';

interface IStateProps {
  verify: boolean;
  userSettingFetched: boolean;
  createDealForm: ICreateDealForm;
}
interface IDispatchProps {
  clearNotification: () => any;
}
interface IOwnProps {
  fetch: Fetch;
}
export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}
export default connect<IStateProps, IDispatchProps, IOwnProps, IStore>(
  (state) => ({
    verify: state.auth.loggedInUser.verified,
    userSettingFetched: state.auth.loggedInUser.id === 0 ? false : true,
    createDealForm: state.requests.createDealForm,
  }),
  (dispatch) => ({
    clearNotification: () =>
      dispatch(setNotification({ listError: '', listSuccess: '' })),
  })
)(MyDealsList);

export enum Fetch {
  Buying,
  Selling,
  All,
}
