import React, { useEffect, useState } from 'react';

// custom imports
import Select from 'app/components/inputs/select';
import TextInput from 'app/components/inputs/text-input-bordered';
import {
  allSalesChannels,
  annualRevenueChoices,
  numberOfEmployeesChoices,
} from 'app/store/modules/global/types';
import { IProps } from './index';
import {
  AdditionalDetailsFormContainer,
  AdditionalDetailsFormWrapper,
  DropdownContainerItem,
  DropdownLabel,
  FirstItemWrapper,
  CompanyInfoHeader,
  CompanyInfoHeaderContainer,
  PersonalInfoHeaderContainer,
  RequiredInfoLabel,
  InputContainer,
  SecondItemWrapper,
  SingleChoiceDropdownContainerItem,
  TwoItemsContainer,
  BuyerEmailContainer,
  ButtonLine,
  CancelButton,
  UpdateButton,
  EmailLabel,
  EmailId,
  EmailVerified,
} from './styles';
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import { anonymousUserId } from 'app/config';
import { useSelector } from 'app/helpers/redux';
import isBrowser from 'app/store/utils/is-browser';

const placeholder = '';
export const dropdownMaxRecordsLimit = 999;
export const minCharsCountForLessStrictFilter = 4;
export const summaryFieldRows = 4;
export const dropdownMaxMenuHeight = 200;

export const multiselectOtherProps = {
  closeMenuOnSelect: true,
  controlShouldRenderValue: true,
  isMulti: true,
  hideSelectedOptions: true,
  placeholderFontSize: '14px',
  placeholderMarginLeft: '8px',
  zIndex: 'auto',
};

export const selectOtherProps = {
  closeMenuOnSelect: true,
  controlShouldRenderValue: true,
  isMulti: false,
  hideSelectedOptions: true,
  placeholderFontSize: '14px',
  placeholderMarginLeft: '8px',
  zIndex: 'auto',
};

const BuyerQualification: React.FC<IProps> = ({
  companyDeal,
  fetchTherapyAreas,
  fetchSubstance,
  submitForm,
  closeModal,
  fetchSettings,
  isAdminView,
  userSettings,
  buyer,
  ...props
}) => {
  // const [allTherapyAreas, setAllTherapyAreas] = useState<any[]>([]);

  const [buyerName, setBuyerName] = useState<string>('');
  const [buyerSurname, setBuyerSurname] = useState<string>('');
  const [buyerPhone, setBuyerPhone] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [summary, setSummary] = useState<string>('');
  const [numberOfEmployees, setNumberOfEmployees] = useState<string>('');
  const [annualRevenue, setAnnualRevenue] = useState<string>('');
  const [salesChannels, setSalesChannels] = useState<string[]>([]);

  const [nameError, setNameError] = useState<string>('');
  const [summaryError, setSummaryError] = useState<string>('');
  const [buyerNameError, setBuyerNameError] = useState<string>('');
  const [buyerSurnameError, setBuyerSurnameError] = useState<string>('');
  const [buyerPhoneError, setBuyerPhoneError] = useState<string>('');

  useEffect(() => {
    fetchSettings();
    setBuyerName(buyer.firstName);
    setBuyerSurname(buyer.lastName);
    setBuyerPhone(buyer.phone);
    setName(companyDeal.name);
    setSummary(companyDeal.summary);
    setNumberOfEmployees(companyDeal.numberOfEmployees);
    setAnnualRevenue(companyDeal.annualRevenue);
    setSalesChannels(companyDeal.salesChannels);
  }, []);

  const differentNumberOfEmployees: ISelectItem | undefined = numberOfEmployees
    ? { label: numberOfEmployees, value: numberOfEmployees }
    : undefined;
  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  const validate = () => {
    let valid = true;
    if (name === '' || name === null) {
      setNameError('Name is required');
      valid = false;
    } else if (summary === '' || summary === null) {
      setSummaryError('Summary is required');
      valid = false;
    } else if (buyerName === '' || buyerName === null) {
      setBuyerNameError('Buyer name is required');
      valid = false;
    } else if (buyerSurname === '' || buyerSurname === null) {
      setBuyerSurnameError('Buyer surname is required');
      valid = false;
    } else if (buyerPhone === '' || buyerPhone === null) {
      setBuyerPhoneError('Buyer phone number is required');
      valid = false;
    }
    return valid;
  };
  const handleClick = () => {
    submitForm(
      {
        ...buyer,
        firstName: buyerName,
        lastName: buyerSurname,
        phone: buyerPhone,
        fullName: buyerName + ' ' + buyerSurname,
      },
      {
        id: companyDeal.id,
        numberOfEmployees,
        annualRevenue,
        name,
        summary,
        salesChannels,
      }
    );
    closeModal();
    let numberOfFieldsFilled = 0;
    if (name && name.length > 0) {
      numberOfFieldsFilled++;
    }
    if (summary && summary.length > 0) {
      numberOfFieldsFilled++;
    }
    if (numberOfEmployees && numberOfEmployees.length > 0) {
      numberOfFieldsFilled++;
    }
    if (annualRevenue && annualRevenue.length > 0) {
      numberOfFieldsFilled++;
    }
    if (salesChannels && salesChannels.length > 0) {
      numberOfFieldsFilled++;
    }

    // calculate number of fielts
    const uuid = isBrowser() && localStorage.getItem(anonymousUserId);
    ReactGA.event({
      category: GACategory ? GACategory.RFQ : '',
      action: GAActions ? GAActions.RFQ_COMPANY_FIELDS_SUBMITED : '',
      numberOfFieldsFilledInBuyerQualificationForm: numberOfFieldsFilled,
      anonymousUserId: uuid,
      email,
    });
  };

  return (
    <div>
      <AdditionalDetailsFormWrapper>
        <AdditionalDetailsFormContainer>
          <form>
            <CompanyInfoHeaderContainer>
              <CompanyInfoHeader>Company Info</CompanyInfoHeader>
            </CompanyInfoHeaderContainer>
            <InputContainer>
              <TextInput
                id={'company-name'}
                value={name}
                label={'Company name*'}
                onChange={(text: string) => {
                  setName(text);
                  if (text !== '') {
                    setNameError('');
                  }
                }}
                inputMarginTop={'9px'}
                marginTop={'18px'}
                multiline={false}
                browserAutocompleteEnabled={false}
                error={nameError}
              />
              <TwoItemsContainer>
                <FirstItemWrapper>
                  <SingleChoiceDropdownContainerItem>
                    <DropdownLabel>Number of employees</DropdownLabel>
                    <div
                      className={`override ${
                        numberOfEmployees ? 'selected' : ''
                      }`}
                    >
                      <Select
                        id={'number-of-employees'}
                        minCharsCountForLessStrictFilter={
                          minCharsCountForLessStrictFilter
                        }
                        optionList={numberOfEmployeesChoices}
                        onChange={(selectedOption) => {
                          setNumberOfEmployees(selectedOption.value);
                        }}
                        placeholder={placeholder}
                        value={
                          numberOfEmployeesChoices.find(
                            (numberOfEmployeesChoice) =>
                              !!numberOfEmployeesChoice.value &&
                              numberOfEmployeesChoice.value.includes(
                                numberOfEmployees || '?'
                              )
                          ) || differentNumberOfEmployees
                        }
                        maxMenuHeight={dropdownMaxMenuHeight}
                        {...selectOtherProps}
                      />
                    </div>
                  </SingleChoiceDropdownContainerItem>
                </FirstItemWrapper>
                <SecondItemWrapper>
                  <SingleChoiceDropdownContainerItem>
                    <DropdownLabel>Annual revenue</DropdownLabel>

                    <div
                      className={`override ${annualRevenue ? 'selected' : ''}`}
                    >
                      <Select
                        id={'annual-revenue'}
                        minCharsCountForLessStrictFilter={
                          minCharsCountForLessStrictFilter
                        }
                        optionList={annualRevenueChoices}
                        onChange={(selectedOption) => {
                          setAnnualRevenue(selectedOption.value);
                        }}
                        placeholder={placeholder}
                        value={annualRevenueChoices.find(
                          (annualRevenueChoice) =>
                            !!annualRevenueChoice.value &&
                            annualRevenueChoice.value.includes(
                              annualRevenue || '?'
                            )
                        )}
                        maxMenuHeight={dropdownMaxMenuHeight}
                        {...selectOtherProps}
                      />
                    </div>
                  </SingleChoiceDropdownContainerItem>
                </SecondItemWrapper>
              </TwoItemsContainer>
              <DropdownContainerItem>
                <DropdownLabel>Sales channels</DropdownLabel>
                <div
                  className={`override ${
                    salesChannels && salesChannels.length > 0 ? 'selected' : ''
                  }`}
                >
                  <Select
                    id={'sales-channels'}
                    minCharsCountForLessStrictFilter={
                      minCharsCountForLessStrictFilter
                    }
                    optionList={allSalesChannels}
                    onChangeMulti={(selectedOptions) => {
                      setSalesChannels(
                        selectedOptions.map(
                          (selectedOption) => selectedOption.value
                        )
                      );
                    }}
                    placeholder={placeholder}
                    values={allSalesChannels.filter(
                      (salesChannel) =>
                        salesChannels &&
                        salesChannels.includes(salesChannel.value)
                    )}
                    maxMenuHeight={dropdownMaxMenuHeight}
                    {...multiselectOtherProps}
                  />
                </div>
              </DropdownContainerItem>
              <TextInput
                id={'company-summary'}
                value={summary}
                label={'Please briefly tell us about your company*'}
                onChange={(text: string) => {
                  setSummary(text);
                  if (text !== '') {
                    setSummaryError('');
                  }
                }}
                inputMarginTop={'9px'}
                marginTop={'18px'}
                multiline={true}
                startRows={summaryFieldRows}
                maxRows={summaryFieldRows}
                error={summaryError}
              />
              <RequiredInfoLabel>
                * - Fields marked with asterisk are mandatory
              </RequiredInfoLabel>
            </InputContainer>
            <PersonalInfoHeaderContainer>
              <CompanyInfoHeader>Personal Info</CompanyInfoHeader>
            </PersonalInfoHeaderContainer>
            <InputContainer>
              <TextInput
                id={'first-name'}
                value={buyerName}
                label={'Name*'}
                onChange={(text: string) => {
                  setBuyerName(text);
                  if (text !== '') {
                    setBuyerNameError('');
                  }
                }}
                inputMarginTop={'9px'}
                marginTop={'18px'}
                multiline={false}
                browserAutocompleteEnabled={false}
                error={buyerNameError}
              />
              <TextInput
                id={'surname'}
                value={buyerSurname}
                label={'Surname*'}
                onChange={(text: string) => {
                  setBuyerSurname(text);
                  if (text !== '') {
                    setBuyerSurnameError('');
                  }
                }}
                inputMarginTop={'9px'}
                marginTop={'18px'}
                multiline={false}
                browserAutocompleteEnabled={false}
                error={buyerSurnameError}
              />
              <TextInput
                id={'surname'}
                value={buyerPhone}
                label={'Phone number (incl. country code)*'}
                onChange={(text: string) => {
                  setBuyerPhone(text);
                  if (text !== '') {
                    setBuyerPhoneError('');
                  }
                }}
                inputMarginTop={'9px'}
                marginTop={'18px'}
                multiline={false}
                browserAutocompleteEnabled={false}
                error={buyerPhoneError}
              />
              <BuyerEmailContainer>
                <EmailLabel>Email:</EmailLabel>
                <EmailId>{buyer?.email}</EmailId>
                <EmailVerified>
                  ({buyer?.verified ? 'confirmed' : 'not confirmed'})
                </EmailVerified>
              </BuyerEmailContainer>
            </InputContainer>
            <ButtonLine>
              <CancelButton
                data-testid="finish-button"
                onClick={() => {
                  closeModal();
                }}
              >
                Cancel
              </CancelButton>
              <UpdateButton
                data-testid="finish-button"
                onClick={() => {
                  if (validate()) {
                    handleClick();
                  }
                }}
              >
                Update
              </UpdateButton>
            </ButtonLine>
          </form>
        </AdditionalDetailsFormContainer>
      </AdditionalDetailsFormWrapper>
    </div>
  );
};

export default BuyerQualification;
