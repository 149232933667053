import { AnyListItem } from 'app/store/modules/products/types';
import * as u from 'app/store/modules/query-utils';
import sg from 'app/utils/safe-get';
import { addSeconds } from 'date-fns';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { companyFragment } from '../../global/fragments/company';
import { EEditFields } from '../../requests/types';
import { RequestUtils } from '../../query-utils';
import { dealMessageSchema } from '../../query-utils/schema';
import { requestFragment } from '../fragments/request';
import { DealPhases, EDealStatus } from '../types';
import { ICreateDeal, ICreateRequestReturn } from './create-request-query';

export async function createMultipleRequestsQuery(p: ICreateDeal) {
  try {
    const createDealMuatations: Promise<any>[] = [];
    const createMessagesMuatations: Promise<any>[] = [];

    if (p.values.selectedProducts) {
      p.values.selectedProducts.map((selectedProduct: AnyListItem) => {
        createDealMuatations.push(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          p.client.mutate<ICreateRequestReturn>({
            mutation: gql`
      ${companyFragment.fragment(p.isAdmin)}
      ${requestFragment.fragment(p.isAdmin)}

      mutation insert_requests(${u.params(
        ['$status', 'Int'],
        ['$isPaid', 'Int'],
        ['$deal_type', 'Int'],
        ['$market', 'String'],
        ['$estimatedAnualQuantity', 'String'],
        ['$leadingPriceLevel', 'String'],
        ['$expectSignContract', 'String'],
        ['$comments', 'String'],
        ['$product_id', 'Int'],
        ['$buyer_company_id', 'Int'],
        ['$seller_user_id', 'Int'],
        ['$seller_company_id', 'Int'],
        ['$broker', 'Int'],
        ['$product_trade_id', 'Int'],
        ['$buyer_seen', 'Boolean'],
        ['$currentDate', 'timestamptz'],
        ['$deals_group', 'String'],
        ['$phase', 'Int']
      )}) {
        insert_requests(
          ${RequestUtils._objects([
            ['status', '$status'],
            ['is_paid', '$isPaid'],
            ['deal_type', '$deal_type'],
            ['market', '$market'],
            ['estimated_anual_quantity', '$estimatedAnualQuantity'],
            ['leading_price_level', '$leadingPriceLevel'],
            ['expect_sign_contract', '$expectSignContract'],
            ['comments', '$comments'],
            ['product_id', '$product_id'],
            ['buyer_company_id', '$buyer_company_id'],
            ['seller_user_id', '$seller_user_id'],
            ['seller_company_id', '$seller_company_id'],
            ['broker', '$broker'],
            ['buyer_seen', '$buyer_seen'],
            ['buyer_first_seen', u.escape(new Date().toISOString())],
            ['created_at', '$currentDate'],
            ['updated_at', '$currentDate'],
            ['buyer_updated_at', '$currentDate'],
            ['seller_updated_at', '$currentDate'],
            ['deals_group', '$deals_group'],
            ['phase', '$phase'],
          ])}) {
          returning {
            ${RequestUtils.selectParams(
              requestFragment,
              ['Buyer_company', 'BuyerCompany', companyFragment],
              ['Seller_company', 'SellerCompany', companyFragment]
            )}
          }
        }
      }
    `,
            variables: {
              status: EDealStatus.Requested,
              isPaid: 0,
              deal_type: p.values.dealType,
              market: p.values.market,
              estimatedAnualQuantity: p.values.estimatedAnualQuantity,
              leadingPriceLevel: p.values.leadingPriceLevel,
              expectSignContract: p.values.expectSignContract,
              comments: p.values.comments,
              product_id: sg(() => selectedProduct.productId, null),
              buyer_company_id: p.currentUserCompanyId,
              seller_user_id: selectedProduct.sellerUserId,
              seller_company_id: selectedProduct.sellerCompanyId,
              broker: null,
              buyer_seen: true,
              deals_group: p.values.dealsGroup,
              currentDate: new Date().toISOString(),
              phase: DealPhases['Requesting offer'],
            },
          })
        );
      });
    }

    const date = new Date();
    const createdDealsResult = await Promise.all(createDealMuatations);

    if (createdDealsResult.length > 0) {
      createdDealsResult.map((createdDeal: any) => {
        createMessagesMuatations.push(
          p.client.mutate({
            mutation: gql`
              ${new u.Mutation('requestSuccessMessagesForMultipleDealsFlow')
                .params(
                  ['$id', 'Int'],
                  ['$empty', 'String'],
                  ['$sender', 'Int'],
                  ['$senderCompany', 'Int'],
                  ['$buyerCompanyId', 'Int'],
                  ['$sellerCompanyId', 'Int']
                  // ['$group', 'Int']
                )
                .insert(dealMessageSchema, 'msg')
                .objects(
                  [
                    ['request_id', '$id'],
                    ['text', '$empty'],
                    ['sender_id', '$sender'],
                    ['sender_company_id', '$senderCompany'],
                    ['buyer_see', true],
                    ['seller_see', false],
                    ['buyer_company_id', '$buyerCompanyId'],
                    ['seller_company_id', '$sellerCompanyId'],
                    ['group_id', 1],
                    ['created_at', u.escape(date.toISOString())],
                    ['field_id', EEditFields.ProductRequestSuccessfull],
                  ],
                  [
                    ['request_id', '$id'],
                    ['text', '$empty'],
                    ['sender_id', '$sender'],
                    ['sender_company_id', '$senderCompany'],
                    ['buyer_see', true],
                    ['seller_see', false],
                    ['buyer_company_id', '$buyerCompanyId'],
                    ['seller_company_id', '$sellerCompanyId'],
                    ['group_id', 2],
                    ['created_at', u.escape(addSeconds(date, 1).toISOString())],
                    ['field_id', EEditFields.ProductRequestInformation],
                  ]
                )
                .returning('id')
                .toString()
                .toString()}
            `,
            fetchPolicy: 'no-cache',
            variables: {
              id: sg(
                () => createdDeal.data!.insert_requests.returning[0].id,
                0
              ),
              empty: '',
              senderCompany: p.currentUserCompanyId,
              buyerCompanyId: sg(
                () =>
                  createdDeal.data!.insert_requests.returning[0].BuyerCompany
                    .id,
                0
              ),
              sellerCompanyId: sg(
                () =>
                  createdDeal.data!.insert_requests.returning[0].SellerCompany
                    .id,
                0
              ),
              // group: 1,
              sender: p.currentUserId,
            },
          })
        );
      });
    }

    await Promise.all(createMessagesMuatations);

    return createdDealsResult;
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> createMultipleRequestsQuery',
      e
    );
  }
}
