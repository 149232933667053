import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { TooltipBuble } from './tooltip-buble';

const Overlay = styled.span`
  position: relative;
`;

export interface Props {
  tooltip?: string;
  children: React.ReactNode;
}

const ToolTipOverlay: React.FC<Props> = ({ tooltip, children }) => {
  const target = React.useRef<HTMLElement | null>(null);
  const parent = React.useRef<HTMLDivElement>(null);

  const [hovered, setHover] = React.useState(false);
  const [, setMount] = React.useState(false);

  React.useEffect(() => {
    if (!document) {
      return;
    }
    target.current = document.getElementById(
      '___tooltip-portal-render-portal-element'
    );
    setMount(true);
  }, []);
  return (
    <Overlay
      ref={parent}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {children}
      {target.current &&
        createPortal(
          <TooltipBuble visible={hovered} parent={parent}>
            {tooltip}
          </TooltipBuble>,
          target.current
        )}
    </Overlay>
  );
};

export { ToolTipOverlay };
export default ToolTipOverlay;
