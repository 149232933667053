import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/browser';
import App from 'app/app';
import { ROOT_ID } from 'app/config';
import { configureStore } from 'app/store/configure-store';
import saga from 'app/store/saga';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import { ApolloProvider } from 'react-apollo';
import { CookiesProvider } from 'react-cookie';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { client } from './graphql';
import ErrorLogger from './logger-wrapper';
import ScrollToTop from './scroll-top';

const sentryTrackingUrl =
  'https://6bb333eb5d94467fb88150eff7ed3bad@sentry.io/1871063';
export enum Environment {
  development = 'development',
  staging = 'staging',
  production = 'production',
}

let environment = Environment.production;
let reportToSentry = true;
if (process && process!.env && process!.env.V2_API_URL) {
  if (
    process.env.V2_API_URL.includes('localhost') ||
    process.env.V2_API_URL.includes('dev')
  ) {
    environment = Environment.development;
    reportToSentry = false;
  } else if (process.env.V2_API_URL.includes('staging')) {
    environment = Environment.staging;
  }
}

if (
  environment === Environment.staging ||
  environment === Environment.production
) {
  Sentry.init({
    environment,
    dsn: sentryTrackingUrl,
    // ignore all chunk errors
    ignoreErrors: ['ChunkLoadError'],
  });
}

/* eslint-disable */
(function (h: any, o, t, j, a?: any, r?: any) {
  h.hj =
    h.hj ||
    function () {
      (h.hj.q = h.hj.q || []).push(arguments);
    };
  h._hjSettings = { hjid: 1506860, hjsv: 6 };
  a = o.getElementsByTagName('head')[0];
  r = o.createElement('script');
  r.async = 1;
  r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
  a.appendChild(r);
})(window, document, '//static.hotjar.com/c/hotjar-', '.js?sv=');
/* eslint-enable */

const history = createBrowserHistory();
const preloadedState = window.__PRELOADED_STATE__;
const store = configureStore(history, preloadedState);
store.runSaga(saga);

export const Component = () => (
  <ErrorLogger reportToSentry={reportToSentry}>
    <HelmetProvider>
      <CookiesProvider>
        <ApolloProvider client={client}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ScrollToTop>
                <App />
              </ScrollToTop>
            </ConnectedRouter>
          </Provider>
        </ApolloProvider>
      </CookiesProvider>
    </HelmetProvider>
  </ErrorLogger>
);

loadableReady(() => hydrate(<Component />, document.getElementById(ROOT_ID)));
