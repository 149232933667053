import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import {
  companySettingsPersonalFragment,
  ICompanySettingsFragment,
} from '../fragments/company-settings';

export interface IGetUserCompanySettings extends IWithApolloClient {
  companyId: number;
}
export interface IGetUserCompanySettingsReturn {
  companies: ICompanySettingsFragment[];
}
export async function getUserCompanySettings(p: IGetUserCompanySettings) {
  try {
    return await p.client.query<IGetUserCompanySettingsReturn>({
      query: gql`
      ${companySettingsPersonalFragment.fragment()}
      query getCompanySettings($id: Int) {
        companies:companies_personal(where: { id: { _eq: $id } }) {
          ...${companySettingsPersonalFragment.name}
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.companyId,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/auth/query.ts -> getUserCompanySettings',
      e
    );
  }
}
