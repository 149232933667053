import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { productFragment } from '../../products/fragments/product';
import { RequestUtils } from '../../query-utils';
import { EDealStatus } from '../types';

export interface IChangeDealStatus extends IWithApolloClient {
  /** Request id */
  id: number;
  archived: boolean;
  state?: EDealStatus;
  party?: 'admin' | 'buyer' | 'seller';
  /** Current user company id */
  companyId: number;
  /** Current user id */
  senderId: number;
}
export interface IChangeDealStatusReturn {
  update_requests: {
    returning: {
      id: number;
      status: number;
      archivedAt: Date;
    }[];
  };
}
export async function changeRequestStatus(p: IChangeDealStatus) {
  try {
    if (!p.party) {
      throw new Error('Party must be set');
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const ret = await p.client.mutate<IChangeDealStatusReturn>({
      mutation: gql`
      ${productFragment.fragment()}
      mutation changeRequestStatus(${u.params(
        ['$id', 'Int'],
        !p.archived && ['$status', 'Int'],
        ['$date', 'timestamptz']
      )}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('id', '$id'))}
          ${RequestUtils._set(
            !p.archived && ['status', '$status'],
            p.archived && p.party === 'admin' && ['admin_archived_at', '$date'],
            p.archived && p.party === 'buyer' && ['buyer_archived_at', '$date'],
            p.archived &&
              p.party === 'seller' && ['seller_archived_at', '$date'],
            ['updated_at', '$date'],
            ['buyer_updated_at', '$date'],
            ['seller_updated_at', '$date'],
            !!p.id && p.state === EDealStatus.Accepted && ['admin_seen', false],
            !!p.id && p.state === EDealStatus.Accepted && ['seller_seen', false]
          )}
        ) {
          returning {
            ${RequestUtils.selectParams(
              'id',
              'status',
              p.party === 'admin' && ['admin_archived_at', 'archivedAt'],
              p.party === 'buyer' && ['buyer_archived_at', 'archivedAt'],
              p.party === 'seller' && ['seller_archived_at', 'archivedAt']
            )}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        date: new Date().toISOString(),
        status: p.archived ? undefined : p.state,
      },
    });
    return ret;
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> changeRequestStatus',
      e
    );
  }
}
