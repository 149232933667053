/* eslint-disable */

import { getUsersByNameOrEmail } from 'app/store/modules/auth/query';
import { User, UserMinimal } from 'app/store/modules/auth/types';
import {
  createDealMutation,
  getDealByIdQuery,
  updateDealMutation,
} from 'app/store/modules/deal/query';

import { setDealBroker, setRequestActiveStatus } from 'app/store/modules/deal/actions';
import { toTitleCase } from 'app/store/modules/global/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { AutocompleteProductType } from 'app/store/modules/marketplace/types';
import {
  fetchDoseCategories,
  fetchProductsSelect,
  filterProductsSelect,
} from 'app/store/modules/products/query';
import { IStore } from 'app/store/types';
import sg from 'app/utils/safe-get';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import CreateDeal from './create-deal';

interface IDispatchProps {
  fetchSubstances: (
    enteredText: string,
    callback: (options: ISelectItem<number>[]) => any
  ) => Promise<ISelectItem<number>[]>;
  fetchDoseCategories: (enteredText: string) => Promise<ISelectItem<string>[]>;
  fetchBuyers: (
    enteredText: string,
    callback: (options: ISelectItem<number>[]) => any
  ) => Promise<ISelectItem<number>[]>;
  submitForm: (
    createdBy: number,
    buyer: ISelectItem<number>,
    countriesOfInterest: string[],
    substance1: ISelectItem<number>,
    isEdit: boolean,
    editDealId: number,
    substance2?: ISelectItem<number>,
    substance3?: ISelectItem<number>,
    broker?: ISelectItem<number>,
    doseFormCategory?: ISelectItem<string>
  ) => any;
  fetchRequestById: (requestId: number, isAdmin: boolean) => any;
}
interface IStateProps {
  loggedInUser: User;
  editDealId?: number | null;
}
interface IOwnProps {
  closeModal: () => any;
  isEdit: boolean;
}
type ComposedProps = IOwnProps & IWithApolloClient & RouteComponentProps;
export type IProps = IStateProps &
  IDispatchProps &
  ComposedProps &
  RouteComponentProps;
export default withApollo<IOwnProps>(
  withRouter(
    connect<IStateProps, IDispatchProps, ComposedProps, IStore>(
      (state) => {
        return {
          loggedInUser: state.auth.loggedInUser,
          editDealId: state.deals.editDealId,
        };
      },
      (dispatch, ownProps) => ({
        fetchSubstances: async (enteredText, callback) => {
          const res =
            !enteredText || enteredText.length === 0
              ? await fetchProductsSelect({
                  client: ownProps.client,
                  type: AutocompleteProductType.Substance,
                })
              : await filterProductsSelect({
                  client: ownProps.client,
                  text: enteredText,
                  type: AutocompleteProductType.Substance,
                });
          if (!res) {
            callback([]);
            return [];
          }
          const substances = sg(() => res!.data.options, []);
          const modifiedSubstances: ISelectItem<number>[] = substances.map(
            (substance: ISelectItem<number>) => ({
              ...substance,
              label: toTitleCase(substance.label),
            })
          );
          callback(modifiedSubstances);
          return modifiedSubstances;
        },
        fetchDoseCategories: async (text: string) => {
          const res = await fetchDoseCategories({
            client: ownProps.client,
            template: text,
          });
          if (!res) {
            return [];
          }
          return res.data.doseCategories.map((doseCategory) => ({
            value: doseCategory.label,
            label: doseCategory.label,
          }));
        },
        fetchBuyers: async (enteredText, callback) => {
          const res = await getUsersByNameOrEmail({
            client: ownProps.client,
            text: enteredText,
          });
          if (!res) {
            callback([]);
            return [];
          }
          const users: UserMinimal[] = sg(() => res!.data.users, []);
          const buyers: ISelectItem<number>[] = users.map(
            (user: UserMinimal) => ({
              value: user.id,
              label: `${user.fullName} (${user.email})`,
            })
          );
          callback(buyers);
          return buyers;
        },
        fetchRequestById: async (requestId, isAdmin) => {
          const res = await getDealByIdQuery({
            client: ownProps.client,
            id: requestId,
            isAdmin,
          });
          const result = {};
          for (const key in res) {
            // eslint-disable-next-line
            if (res?.hasOwnProperty(key)) {
              result[key] = res[key];
            }
          }
          return result;
        },
        submitForm: (
          createdBy,
          buyer,
          countriesOfInterest,
          substance1,
          isEdit,
          editDealId,
          substance2,
          substance3,
          broker,
          doseFormCategory,
          isActive
        ) => {
          if (!isEdit && editDealId === 0) {
            return createDealMutation({
              client: ownProps.client,
              buyerUsersId: buyer.value,
              substance1: substance1.value,
              substance2: substance2 && substance2.value,
              substance3: substance3 && substance3.value,
              countriesOfInterest: countriesOfInterest.toString(),
              createdBy,
              broker: broker ? broker.value : undefined,
              doseFormCategory: doseFormCategory
                ? doseFormCategory.value
                : undefined,
            });
          } else {
            dispatch<any>(
              setDealBroker({
                client: ownProps.client,
                id: editDealId,
                broker_id: broker ? broker.value : undefined,
              })
            );
            dispatch<any>(
              setRequestActiveStatus({
                client: ownProps.client,
                deal_id: editDealId,
                is_active: isActive
              })
            );
            return updateDealMutation({
              client: ownProps.client,
              buyerUsersId: buyer.value,
              substance1: substance1.value,
              substance2: substance2 && substance2.value,
              substance3: substance3 && substance3.value,
              countriesOfInterest: countriesOfInterest.toString(),
              broker: broker ? broker.value : undefined,
              id: editDealId,
              doseFormCategory: doseFormCategory
                ? doseFormCategory.value
                : undefined,
              isActive
            });
          }
        },
      })
    )(CreateDeal)
  )
);
