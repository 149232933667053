import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const SaveButtonStyle = styled.button`
  width: 140px;
  min-width: 125px;
  height: 42px;
  border-radius: 3px;
  border: 0;
  background-color: ${(props) => props.theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
  ${media.max.sm`
    width: 100%;

  `}
`;
interface Props {
  onClick: () => void;
  type?: 'reset' | 'submit';
  label?: string;
}

const SaveButton: React.FC<Props> = ({ onClick, label = 'Save', type }) => {
  return (
    <SaveButtonStyle onClick={onClick} type={type}>
      {label}
    </SaveButtonStyle>
  );
};

export default SaveButton;
