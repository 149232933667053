import { Substance } from 'app/store/modules/products/types';
import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';
import { CompanyUtils, SubstanceUtils } from '../../query-utils';
import { productListSchema } from '../../query-utils/schema';

interface Company {
  id: number;
  name: string;
  hidden: boolean;
}
export interface IGetFilteredSuppliers extends IWithApolloClient {
  substance1: string;
  substance2: string | undefined;
  substance3: string | undefined;
  doseFormCategory: string | undefined;
  isAdmin: boolean;
}
export interface IGetFilteredSuppliersProductV2 {
  collapsible: boolean;
  productId: number;
  SellerCompany: Company;
  Substance1: Substance;
  Substance2: Substance;
  Substance3: Substance;
  formSpecification: string;
  strength: string;
  notAvailableMarkets: string;
}
export interface IGetFilteredSuppliersReturn {
  myProductsList: {
    productsV2: IGetFilteredSuppliersProductV2;
  }[];
}
export function getPotentialSuppliersQuery(p: IGetFilteredSuppliers) {
  try {
    let substanceFilter: any = null;
    if (p.substance2 === undefined && p.substance3 === undefined) {
      substanceFilter = u._or(
        u._and(
          u.nest('Substance1', u._eq('name', '$substance1')),
          u._is_null('substance_2'),
          u._is_null('substance_3')
        ),
        u._and(
          u._is_null('substance_1'),
          u.nest('Substance2', u._eq('name', '$substance1')),
          u._is_null('substance_3')
        ),
        u._and(
          u._is_null('substance_1'),
          u._is_null('substance_2'),
          u.nest('Substance3', u._eq('name', '$substance1'))
        )
      );
    }
    if (p.substance2 !== undefined && p.substance3 === undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest('Substance1', u._eq('name', '$substance1')),
          u.nest('Substance2', u._eq('name', '$substance1'))
        ),
        u._or(
          u.nest('Substance1', u._eq('name', '$substance2')),
          u.nest('Substance2', u._eq('name', '$substance2'))
        )
      );
    }
    if (p.substance2 !== undefined && p.substance3 !== undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest('Substance1', u._eq('name', '$substance1')),
          u.nest('Substance2', u._eq('name', '$substance1')),
          u.nest('Substance3', u._eq('name', '$substance1'))
        ),
        u._or(
          u.nest('Substance1', u._eq('name', '$substance2')),
          u.nest('Substance2', u._eq('name', '$substance2')),
          u.nest('Substance3', u._eq('name', '$substance2'))
        ),
        u._or(
          u.nest('Substance1', u._eq('name', '$substance3')),
          u.nest('Substance2', u._eq('name', '$substance3')),
          u.nest('Substance3', u._eq('name', '$substance3'))
        )
      );
    }
    if (p.substance2 === undefined && p.substance3 !== undefined) {
      substanceFilter = u._and(
        u._or(
          u.nest('Substance1', u._eq('name', '$substance1')),
          u.nest('Substance3', u._eq('name', '$substance1'))
        ),
        u._or(
          u.nest('Substance1', u._eq('name', '$substance3')),
          u.nest('Substance3', u._eq('name', '$substance3'))
        )
      );
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return p.client.query<IGetFilteredSuppliersReturn>({
      query: gql`
        ${new u.Query('getFilteredSuppliers', {
          isAdmin: p.isAdmin,
        })
          .params(
            ['$substance1', 'String'],
            ['$substance2', 'String'],
            ['$substance3', 'String'],
            ['$doseFormCategory', 'String'],
            ['$filterOld', 'String'],
            ['$filterOld2', 'String'],
            ['$filterOld3', 'Boolean']
          )
          .query(productListSchema, 'myProductsList')
          .where((t) =>
            t._and(
              t.nest(
                'Product',
                u.ProductUtils._and(
                  u.ProductUtils._or(substanceFilter),
                  u.ProductUtils._is_null('deleted_at'),
                  p.doseFormCategory
                    ? u.ProductUtils._eq(
                        'dose_form_category',
                        '$doseFormCategory'
                      )
                    : '',
                  u.ProductUtils._gte('status', 1)
                )
              )
            )
          )
          .select([
            'Product',
            'productsV2',
            u.ProductUtils.selectParams(
              ['id', 'productId'],
              [
                'Company',
                'SellerCompany',
                CompanyUtils.selectParams(
                  'id',
                  p.isAdmin ? 'name' : undefined,
                  'hidden',
                  'country'
                ),
              ],
              [
                'Substance1',
                'Substance1',
                SubstanceUtils.selectParams('id', 'name'),
              ],
              p.substance2
                ? [
                    'Substance2',
                    'Substance2',
                    SubstanceUtils.selectParams('id', 'name'),
                  ]
                : undefined,
              p.substance3
                ? [
                    'Substance3',
                    'Substance3',
                    SubstanceUtils.selectParams('id', 'name'),
                  ]
                : undefined,
              ['strength', 'strength'],
              ['form_specification', 'formSpecification'],
              ['not_available_markets', 'notAvailableMarkets'],
              ['dossier_status', 'dossierStatus'],
              ['dossier_format', 'dossierFormat'],
              ['dossier_completion_quarter', 'dossierCompletionQuarter'],
              ['dossier_completion_year', 'dossierCompletionYear'],
              [
                'gmp_approval_for_this_product_string',
                'gmpApprovalForThisProductString',
              ],
              [
                'marketing_authorizations_achieved_country',
                'marketingAuthorizationsAchievedCountry',
              ],
              [
                'product_available_licensing_supply',
                'productAvailableLicensingSupply',
              ],
              [
                'product_available_supply_distribution',
                'productAvailableSupplyDistribution',
              ],
              [
                'product_available_technology_transfer',
                'productAvailableTechnologyTransfer',
              ],
              [
                'product_available_special_import',
                'productAvailableSpecialImport',
              ],
              ['shelf_life', 'shelfLife'],
              ['batch_size', 'batchSize'],
              ['moq', 'moq'],
              p.isAdmin && ['admin_comments', 'adminComments'],
              ['registered_in', 'registeredIn'],
              ['stability_data_zones_string', 'stabilityDataZonesString'],
              ['clinical_data', 'clinicalData'],
              ['cpp', 'cpp'],
              ['cpp_available', 'cppAvailable'],
              ['finished_dose_country', 'finishedDoseCountry'],
              ['not_available_markets', 'notAvailableMarkets'],
              ['created_by_id', 'createdBy'],
              ['pack_size', 'packSize'],
              ['volume', 'volume']
            ),
          ])
          .orderBy('ranking', 'desc')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        substance1: p?.substance1,
        substance2: p?.substance2,
        substance3: p?.substance3,
        doseFormCategory: p?.doseFormCategory,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> getPotentialSuppliersQuery',
      e
    );
  }
}
