import React from 'react';
import styled from 'styled-components';

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  children?: string;
}

export const InitialBubble: (props: IProps) => React.ReactElement | null = ({
  children,
  ref,
  ...props
}) =>
  children ? (
    <InitialBubbleContainer {...props}>{children}</InitialBubbleContainer>
  ) : null;

const InitialBubbleContainer = styled.div`
  width: 22px;
  height: 22px;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  border-radius: 50%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary};
  margin: auto;
  padding: 3px;
`;
