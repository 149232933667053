import React from 'react';
import Flag from 'react-world-flags';

import { ToolTipOverlay } from 'app/components/tooltip/tooltip-overlay';
import { COUNTRIES } from 'app/config/countries';

import {
  FlagContainer,
  FlagListContainer,
  FlagsContainer,
  ListLabel,
} from './country-flag-list.style';

interface IProps {
  label?: string;
  countryIds: string;
}

const CountryFlagList: React.FC<IProps> = ({ countryIds, label }) => {
  return (
    <FlagListContainer>
      {label && <ListLabel>{label}</ListLabel>}
      <FlagsContainer>
        {COUNTRIES.filter((country) => countryIds.includes(country.id)).map(
          (country: any) => {
            return (
              <FlagContainer key={country.name}>
                <ToolTipOverlay tooltip={country.name}>
                  <Flag
                    code={country.id}
                    style={{ borderRadius: '2px', height: 15.71 }}
                  />
                </ToolTipOverlay>
              </FlagContainer>
            );
          }
        )}
      </FlagsContainer>
    </FlagListContainer>
  );
};

export default CountryFlagList;
