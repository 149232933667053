import * as saga from '@redux-saga/core/effects';
import { ApolloClient } from 'apollo-boost';
import * as A from './actions';
import * as Query from './query';
import { ContactUsFields } from './types';

function isBrowser(): boolean {
  return process.env.TARGET === 'browser';
}

function* createContactUsSagaFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const action: ReturnType<typeof A.createContactUs> = yield saga.take(
      A.createContactUs
    );

    yield saga.call(
      createContactUsSaga,
      action.payload.client,
      action.payload.values
    );
  }
}
function* createContactUsSaga(
  client: ApolloClient<any>,
  values: ContactUsFields
) {
  try {
    if (!isBrowser()) {
      return;
    }

    const res: Unpromisefy<
      ReturnType<typeof Query.createContactUsQuery>
    > = yield saga.call(Query.createContactUsQuery, {
      client,
      values,
    } as Query.ICreateContactUs);

    yield saga.put(
      A.setContactUsNotification({
        listSuccess: 'Your message was sent.',
        listError: '',
      })
    );
    return res;
  } catch (e) {
    yield saga.put(
      A.setContactUsNotification({
        listError: 'Your message was not sent.',
        listSuccess: '',
      })
    );
  }
}
export default function* rootSaga() {
  yield saga.all([saga.fork(createContactUsSagaFlow)]);
}
