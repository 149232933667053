import { createActionCreator as c } from '@reduxify/utils';
import { IPotentialSupplier } from '../../requests/types';
import { IWithApolloClient } from '../../global/types';
import { User } from '../../auth/types';

export const setTotalCount = c<number>(
  'deal/SET_RECOMMENDED_SUPPLIERS_TOTAL_COUNT'
);

export const setCurrentPage = c<number>(
  'deal/SET_RECOMMENDED_SUPPLIERS_CURRENT_PAGE'
);

export const setPageSize = c<number>('deal/SET_RECOMMENDED_SUPPLIERS_SIZE');
export const setPotentialSuppliers = c<IPotentialSupplier[]>(
  'deal/SET_RECOMMENDED_SUPPLIERS_LIST'
);
export interface IFetchPotentialSupplier extends IWithApolloClient {
  substance1: string;
  substance2: string | undefined;
  substance3: string | undefined;
  markets: any;
  requestedSuppliers: any;
  doseFormCategory: string | undefined;
  isAdmin: boolean;
  existingProductIds?: number[];
}
export const fetchPotentialSupplier = c<IFetchPotentialSupplier>(
  'deal/FETCH_POTENTIAL_SUPPLIER'
);
export const getPotentialSupplierCount = c<IFetchPotentialSupplier>(
  'deal/FETCH_POTENTIAL_SUPPLIER'
);
export const setDealDetailsBuyer = c<User | null>(
  'deal/SET_DEAL_DETAILS_BUYER'
);
