import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getCountryName } from '../../../utils/get-country-name';
import {
  AddItemContainerBGless,
  CancelButton,
  CancelButtonContainer,
  CompanyFormActions,
  FormContainerEditMode,
  GrayColoredSpanAligned,
  SaveButtonContainer,
} from './styles';
import { Components } from '../../../components/buttons/edit-item-blue-button';
import TextInput from '../../../components/inputs/text-input';
import Select from '../../../components/inputs/select';
import { allCountries } from '../../../store/modules/global/types';
import SaveButton from '../../../components/buttons/save-button';

const CompanyName = styled.h2`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 34px;
  line-height: 40px;
  color: #1188e4;
  margin: 0px;
  padding: 0px;
  margin-bottom: 25px;
  margin-left: 7px;
  @media (max-width: 450px) {
    width: 90%;
  }
`;
const CountryName = styled.h2`
  color: #818b9a;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  margin: 0 0 20px 0;
  align-self: flex-end;
`;
const CompanyNameEditContainer = styled.div`
  display: flex;
  width: 30%;
`;
const WidthSelect = styled.div`
  width: 30%;
  max-width: 100%;
`;
const CompanyHeadingContainer = styled.div<{
  width?: string;
  padding?: boolean;
}>`
  display: flex;
  width: ${(props) => props.width || '90%'};
  padding: ${(props) => (props.padding ? '12px 12px' : '')};
`;
const { AddItemIcon } = Components;
interface Props {
  onClick?: () => any;
}
const EditItemBlueButton: React.FC<Props> = ({ onClick }) => {
  return (
    <AddItemContainerBGless onClick={onClick}>
      <AddItemIcon src={'/img/edit-pen.svg'} />
    </AddItemContainerBGless>
  );
};
interface IProps {
  companyId: number;
  companyName: string;
  companyCountry: string;
  updateSellerNameAndCountry: (
    companyId: number,
    name: string,
    country: string
  ) => any;
}
const SellerCompanyName: React.FC<IProps> = ({
  companyId,
  companyName,
  companyCountry,
  updateSellerNameAndCountry,
}) => {
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [editCompanyOpen, setEditCompanyOpen] = useState<boolean>(false);
  const [sellerCompanyName, setSellerCompanyName] = useState<string>(
    companyName
  );
  const [sellerCompanyCountry, setSellerCompanyCountry] = useState<ISelectItem>(
    {
      value: companyCountry,
      label: getCountryName(companyCountry),
    }
  );
  useEffect(() => {
    if (companyName) {
      setSellerCompanyName(companyName);
    }
    if (companyCountry) {
      setSellerCompanyCountry({
        value: companyCountry,
        label: getCountryName(companyCountry),
      });
    }
  }, []);
  const toggleEditCompany = () => {
    setEditCompanyOpen(!editCompanyOpen);
    if (editCompanyOpen) {
      setSellerCompanyName(sellerCompanyName);
      setSellerCompanyCountry(sellerCompanyCountry);
    } else {
      setSellerCompanyName(companyName);
      setSellerCompanyCountry({
        value: companyCountry,
        label: getCountryName(companyCountry),
      });
    }
  };
  const handleReset = () => {
    setEditCompanyOpen(!editCompanyOpen);
    setSellerCompanyName(companyName);
    setSellerCompanyCountry({
      value: companyCountry,
      label: getCountryName(companyCountry),
    });
  };
  const submit = () => {
    if (!sellerCompanyName || !sellerCompanyCountry.value) {
      setFieldError(true);
      return;
    }
    updateSellerNameAndCountry(
      companyId,
      sellerCompanyName,
      sellerCompanyCountry.value
    );
    setEditCompanyOpen(!editCompanyOpen);
  };
  return (
    <div>
      {!editCompanyOpen && (
        <CompanyHeadingContainer>
          <CompanyName>{companyName}</CompanyName>
          <GrayColoredSpanAligned>from</GrayColoredSpanAligned>
          <CountryName>
            {companyCountry && companyCountry.length > 0
              ? getCountryName(companyCountry)
              : ''}
          </CountryName>
          <EditItemBlueButton onClick={() => toggleEditCompany()} />
        </CompanyHeadingContainer>
      )}
      {editCompanyOpen && (
        <CompanyHeadingContainer width={'66%'} padding={true}>
          <CompanyNameEditContainer>
            <TextInput
              label="Company Name"
              type="text"
              value={companyName}
              error={
                fieldError && !sellerCompanyName
                  ? 'Company name is required!'
                  : ''
              }
              id={'company-name'}
              onChange={(value) => {
                setSellerCompanyName(value);
                if (value === '') {
                  setFieldError(false);
                }
              }}
            />
          </CompanyNameEditContainer>
          <FormContainerEditMode>from</FormContainerEditMode>
          <WidthSelect>
            <Select
              label={'Country'}
              noTopMargin={true}
              id={'seller-company-country'}
              optionList={allCountries
                .filter((x) => x.region !== true)
                .map((x: any) => ({
                  value: x.id,
                  label: x.name,
                }))}
              isMulti={false}
              onChange={(val) => {
                setSellerCompanyCountry(val);
              }}
              value={{
                value: sellerCompanyCountry
                  ? sellerCompanyCountry.value
                  : companyCountry,
                label: getCountryName(
                  sellerCompanyCountry
                    ? sellerCompanyCountry.value
                    : companyCountry
                ),
              }}
              zIndex={'4'}
              placeholder={'Start typing for suggestions'}
              error={
                fieldError && !sellerCompanyCountry.value
                  ? 'Country is required'
                  : ''
              }
            />
          </WidthSelect>
          <CompanyFormActions>
            <CancelButtonContainer>
              <CancelButton onClick={() => handleReset()}>Cancel</CancelButton>
            </CancelButtonContainer>
            <SaveButtonContainer>
              <SaveButton onClick={() => submit()} />
            </SaveButtonContainer>
          </CompanyFormActions>
        </CompanyHeadingContainer>
      )}
    </div>
  );
};

export default SellerCompanyName;
