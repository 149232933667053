import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import * as u from '../../query-utils';
import {
  IUserSettingFragment,
  userSettingFragment,
} from '../fragments/user-settings';
import { User } from '../types';

export interface IUpdateUserSettings extends IWithApolloClient {
  user: User;
}
export interface IUpdateUserSettingsReturn {
  update_users: {
    returning: IUserSettingFragment[];
  };
}

export async function updateUserSettingsQuery(p: IUpdateUserSettings) {
  try {
    return await p.client.mutate<IUpdateUserSettingsReturn>({
      mutation: gql`
      ${userSettingFragment.fragment()}
      mutation update_users(${u.params(
        ['$id', 'Int'],
        ['$full_name', 'String'],
        ['$first_name', 'String'],
        ['$last_name', 'String'],
        ['$email', 'String'],
        ['$phone', 'String'],
        ['$position', 'String'],
        ['$verified', 'Boolean']
      )}) {
        update_users(
          ${u.where(u._eq('id', '$id'))}
          ${u._set(
            ['full_name', '$full_name'],
            ['first_name', '$first_name'],
            ['last_name', '$last_name'],
            ['email', '$email'],
            ['phone', '$phone'],
            ['position', '$position']
          )}
        ) {
          returning {
            ...${userSettingFragment.name}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.user.id,
        full_name: p.user.fullName,
        email: p.user.email,
        phone: p.user.phone,
        position: p.user.position,
        first_name: p.user.firstName,
        last_name: p.user.lastName,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/auth/query.ts -> updateUserSettingsQuery',
      e
    );
  }
}
