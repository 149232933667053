import React from 'react';
import styled from 'styled-components';

export const Container = styled.div<{ isModal?: boolean }>`
  max-width: 380px;
  padding: 29px 18px 29px 38px;
  background-color: ${(props) => props.theme.colors.white};
`;

const MailImage = styled.img`
  width: 188px;
  height: 142px;
  object-fit: contain;
`;

const Title = styled.div`
  margin-top: 32px;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const SubTitle = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const EmailText = styled.div`
  margin-top: 28px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.47;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const ExplanationText = styled.div`
  margin-top: 18px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
`;

const UserEmailAddress = styled.span`
  font-weight: 600;
  line-height: 1.73;
`;

const SupportEmailAddress = styled.span`
  color: ${(props) => props.theme.colors.primary};
`;

const TrustedByCompanies: React.FC<{ email: string }> = ({ email }) => {
  return (
    <Container>
      <MailImage src={'/img/mail-verify.svg'} />
      <Title>You’re almost there!</Title>
      <SubTitle>Verify your email to get started.</SubTitle>
      <EmailText>
        Your verification email has been sent to:{' '}
        <UserEmailAddress>{email}</UserEmailAddress>
      </EmailText>
      <ExplanationText>
        {`Didn't receive an email? Check your junk or spam folder or contact `}
        <a href="mailto:support@pipelinepharma.com">
          <SupportEmailAddress>support@pipelinepharma.com</SupportEmailAddress>
        </a>
      </ExplanationText>
    </Container>
  );
};

export default TrustedByCompanies;
