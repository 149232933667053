import { User } from 'app/store/modules/auth/types';
import { setFilter, setPageSize } from 'app/store/modules/requests/actions';
import { getBrokersQuery } from 'app/store/modules/requests/query';
import { DealsFilter } from 'app/store/modules/requests/types';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { getProductName } from 'app/store/modules/products/actions';
import { getProductSelectQuery } from 'app/store/modules/products/query';
import { Product } from 'app/store/modules/products/types';
import { IStore } from 'app/store/types';
import { sg } from 'app/utils/safe-get';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { Fetch } from '../../index';
import DealsFilterComponent from './requests-filter';

type Callback<T> = (options: T) => any;
interface IStateProps {
  filter: DealsFilter;
  loggedInUser: User;
}
interface IDispatchProps {
  setFilter: (val: DealsFilter) => any;
  setPageSize: (size: number) => any;
  fetchProducts: (
    name: string,
    callback: Callback<ISelectItem[]>,
    isAdmin: boolean
  ) => any;
  fetchBrokers: (name: string, callback: Callback<ISelectItem[]>) => any;
}
interface IOwnProps {
  fetch: Fetch;
}

export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}
export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IOwnProps & IWithApolloClient, IStore>(
    (state) => ({
      filter: state.requests.filter,
      loggedInUser: state.auth.loggedInUser,
    }),
    (dispatch, ownProps) => ({
      setFilter: (filter: DealsFilter) =>
        dispatch(
          setFilter({ filter, client: ownProps.client, fetch: ownProps.fetch })
        ),
      setPageSize: (size) => {
        dispatch(setPageSize(size));
      },
      fetchProducts: (name, callback, isAdmin) =>
        getProductSelectQuery({
          client: ownProps.client,
          product: name,
          isAdmin,
        }).then((r) => {
          const val = sg(
            () =>
              r
                ? r.data.products.map(
                    (v: Product): ISelectItem => ({
                      value: v.id.toString(),
                      label: getProductName(v),
                    })
                  )
                : [],
            []
          );
          callback(val);
          return val;
        }),
      fetchBrokers: (name: string, callback: Callback<ISelectItem[]>) => {
        return getBrokersQuery({ client: ownProps.client, name }).then((r) => {
          if (r) {
            callback(
              r.data.options.map((v) => ({
                value: v.value.toString(),
                label: v.label,
              }))
            );
            return [
              {
                value: undefined,
                label: '--Not selected--',
              },
              {
                value: null,
                label: 'Unassigned',
              },
              ...r.data.options,
            ];
          } else {
            return [
              {
                value: undefined,
                label: '--Not selected--',
              },
              {
                value: null,
                label: 'Unassigned',
              },
            ];
          }
        });
      },
    })
  )(DealsFilterComponent)
);
