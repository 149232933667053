import React from 'react';

// custom imports
import { routes } from 'app/config';
import { User } from 'app/store/modules/auth/types';
import { needMoreInfo } from 'app/utils/auth-utils';
import sg from 'app/utils/safe-get';

interface Props {
  navigateToRoute: (route: string) => any;
  openCreateAccountModal: () => any;
  isLoggedIn: boolean;
  isAdmin: boolean;
  allowedPermission: Permissions;
  authCheckComplete: boolean;
  loggedInUser: User;
  linkedinLoginSuccess: boolean;
  redirectBackTo?: string;
}

export enum Permissions {
  anonymous = 1,
  registeredUser = 2,
  betaUser = 3,
  admin = 4,
}

const PermissionCheck: React.FC<Props> = ({
  navigateToRoute,
  isLoggedIn,
  isAdmin,
  allowedPermission,
  authCheckComplete,
  loggedInUser,
  openCreateAccountModal,
  linkedinLoginSuccess,
  redirectBackTo,
}) => {
  const queryParams =
    '?redirectBackTo=' +
    encodeURI(redirectBackTo ? sg(() => redirectBackTo, '') : '');

  const getCurrentPermissions = () => {
    if (isLoggedIn === false) {
      return Permissions.anonymous;
    }

    if (isLoggedIn === true && isAdmin === false) {
      if (loggedInUser && loggedInUser.isBeta === true) {
        return Permissions.betaUser;
      } else {
        return Permissions.registeredUser;
      }
    }
    if (isLoggedIn === true && isAdmin === true) {
      return Permissions.admin;
    }

    return Permissions.anonymous;
  };
  if (authCheckComplete) {
    const currentPermissions = getCurrentPermissions();
    if (
      currentPermissions === Permissions.registeredUser &&
      needMoreInfo(loggedInUser) &&
      !linkedinLoginSuccess
    ) {
      openCreateAccountModal();
    }

    if (allowedPermission > currentPermissions) {
      if (isLoggedIn) {
        navigateToRoute(routes.products);
      } else {
        navigateToRoute(routes.nextLogin + queryParams);
      }
    }
  }

  return <div />;
};

export default PermissionCheck;
