import {
  setBuyerQualificationAdminViewRequestId,
  setBuyerQualificationAdminViewModalOpen,
} from 'app/store/modules/auth/actions';
import {
  changeDealState,
  restoreArchivedDeal,
} from 'app/store/modules/requests/actions';
import { EDealStatus, IRequest } from 'app/store/modules/requests/types';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { push } from 'connected-react-router';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import DealLargeTableItem from './large-table-item';

interface IStateProps {
  request: IRequest;
}
interface IDispatchProps {
  archiveDealFunc: (id: number, archived: boolean, state?: EDealStatus) => any;
  restoreArchiveFunc: (id: number) => any;
  openDeal: (url: string) => any;
  openBuyerQualificationAdminView: (id: number) => any;
}
interface IOwnProps {
  id: number;
  isAdmin: boolean;
  companyId: number;
  iAmBuying?: boolean;
  iAmSelling?: boolean;
}

export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}
export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IOwnProps & IWithApolloClient, IStore>(
    (state, ownProps) => ({
      request: state.requests.dealsCache[ownProps.id],
      isAdmin: state.auth.loggedInUser
        ? state.auth.loggedInUser.isAdmin
        : false,
    }),
    (dispatch, ownProps) => {
      return {
        archiveDealFunc: (id, archived, state) =>
          dispatch(
            changeDealState({ id, client: ownProps.client, archived, state })
          ),
        restoreArchiveFunc: (id) =>
          dispatch(restoreArchivedDeal({ client: ownProps.client, id })),
        openDeal: (url: string) => {
          dispatch(push(url));
        },
        openBuyerQualificationAdminView: (id: number) => {
          dispatch(setBuyerQualificationAdminViewRequestId(id));
          dispatch(setBuyerQualificationAdminViewModalOpen(true));
        },
      };
    }
  )(DealLargeTableItem)
);
