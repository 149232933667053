import { useMount, useUnmount } from 'app/helpers/lifecycle';
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: rgba(30, 51, 85, 0.6);
  display: flex;

  div.content {
    display: flex;
    flex: 1;
    overflow: auto;
  }
  div.center {
    margin: auto;
    background: white;
  }
  div.close {
    display: flex;
    position: relative;
    .image-container {
      position: absolute;
      right: 14px;
      top: 0px;
      > img {
        position: fixed;
        top: unset;
        left: unset;
        right: unset;
        bottom: unset;
      }
    }
  }
`;

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  isOpen: boolean;
  onClose: () => void;
  header?: any;
}
const Modal: React.FC<IProps> = ({
  isOpen,
  onClose,
  children,
  header,
  ...props
}) => {
  useMount(() => document.body.classList.add('ReactModal__Body--open'));
  useUnmount(() => document.body.classList.remove('ReactModal__Body--open'));

  return (
    <Overlay onClick={onClose}>
      <div className="content">
        <div onClick={(e) => e.stopPropagation()} className="center">
          <div {...props}>
            <div className="close">
              {header}
              <div className="image-container">
                <img src="/img/close.svg" onClick={onClose} />
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </Overlay>
  );
};

const MountControll = (props: IProps) => {
  if (!props.isOpen) {
    return null;
  }
  return <Modal {...props} />;
};
export { MountControll as Modal };
export default MountControll;
