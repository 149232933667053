import { createReducer, reduce, set } from '@reduxify/utils';
import {
  setBrandNameSEO,
  setBrandSubstance,
  setCountrySEOText,
  setCurrentPage,
  setHasFetched,
  setIsElastic,
  setIsFetching,
  setMarketplaceFilter,
  setMarketplaceItemsList,
  setMarketplaceProductCache,
  setPageSize,
  setRequestMessage,
  setSubstanceSEOable,
  setSubstanceSEOText,
  setTopProducts,
  setTotalCount,
} from 'app/store/modules/marketplace/actions';
import { isEqual, uniqWith } from 'lodash';

import { Dictionary } from 'app/utils/dictionary';
import { combineReducers } from 'redux';
import { AnyListItem, Product } from '../products/types';
import {
  defaultPageSize,
  defaultStore,
  MarketplaceFilter,
  State,
} from './types';

const mergeOrResetStateForCache = (actionCreator: any): any =>
  reduce(actionCreator, (state: any, action) => {
    const { payload, meta } = action;
    const thisPage = meta.currentPage;

    return thisPage === 0 ? payload : { ...state, ...payload };
  });

const marketplaceProductCache = createReducer<Dictionary<Product>>(
  {},
  mergeOrResetStateForCache(setMarketplaceProductCache)
);

const marketplaceListItem = createReducer<AnyListItem[]>(
  [],
  reduce(setMarketplaceItemsList, (state, action) => {
    const { payload, meta } = action;
    const thisPage = meta.currentPage;

    return thisPage === 0 ? payload : uniqWith([...state, ...payload], isEqual);
  })
);
const totalCount = createReducer<number>(0, reduce(setTotalCount, set));
const currentPage = createReducer<number>(0, reduce(setCurrentPage, set));
const pageSize = createReducer<number>(
  defaultPageSize,
  reduce(setPageSize, set)
);
const isFetching = createReducer<boolean>(false, reduce(setIsFetching, set));

const isElastic = createReducer<boolean>(true, reduce(setIsElastic, set));

const filter = createReducer<MarketplaceFilter>(
  {
    product: '',
  },
  reduce(setMarketplaceFilter, set)
);

const marketplaceTopProducts = createReducer<AnyListItem[]>(
  [],
  reduce(setTopProducts, set)
);

const substanceSEOText = createReducer<string>(
  '',
  reduce(setSubstanceSEOText, set)
);

const brandSEOName = createReducer<string>('', reduce(setBrandNameSEO, set));

const brandSubstance = createReducer<{ [key: string]: string }>(
  {},
  reduce(setBrandSubstance, set)
);

const countrySEOText = createReducer<string>(
  '',
  reduce(setCountrySEOText, set)
);
export default combineReducers({
  marketplaceProductCache,
  marketplaceListItem,
  totalCount,
  currentPage,
  pageSize,
  filter,
  isElastic,
  isFetching,
  hasFetched: createReducer<boolean>(false, reduce(setHasFetched, set)),
  newProductRequestMessage: createReducer<State['newProductRequestMessage']>(
    defaultStore.newProductRequestMessage,
    reduce(setRequestMessage, set)
  ),
  marketplaceTopProducts,
  substanceSEOText,
  countrySEOText,
  substanceSEOable: createReducer<State['substanceSEOable']>(
    defaultStore.substanceSEOable,
    reduce(setSubstanceSEOable, set)
  ),
  brandSEOName,
  brandSubstance,
});
