import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { MACompanyUtils } from '../../query-utils';
import { Schema_MACompany } from '../../query-utils/schema';
import { IMACompanyFragment, maCompanyFragment } from '../fragments/ma-company';
import { CompanyFormFields } from '../types';

export interface ICreateCompany extends IWithApolloClient {
  values: CompanyFormFields;
}
export interface ICreateCompanyReturn {
  maComapny: {
    returning: IMACompanyFragment[];
  };
}
export async function createCompanyQuery(p: ICreateCompany) {
  try {
    const isUpdate = p.values.id !== 0;
    const regularFields: u.ISet<Schema_MACompany>[] = [
      ['subject', '$subject'],
      ['description', '$description'],
      ['ranking', '$ranking'],
    ];

    return await p.client.mutate({
      mutation: gql`
      ${maCompanyFragment.fragment()}
      mutation insert_company(${u.params(
        isUpdate && ['$id', 'Int'],
        !isUpdate && ['$createdAt', 'timestamptz'],
        ['$subject', 'String'],
        ['$description', 'String'],
        ['$ranking', 'numeric']
      )}){
      ${
        isUpdate
          ? `maComapny: update_ma_company(
              ${MACompanyUtils.where(MACompanyUtils._eq('id', '$id'))}
              ${MACompanyUtils._set(...regularFields)}
            )`
          : `maComapny: insert_ma_company(
              ${MACompanyUtils._objects([
                ...regularFields,
                ['created_at', '$createdAt'],
              ])}
            )`
      } {
        returning {
          ${MACompanyUtils.selectParams(maCompanyFragment)}
        }
      }
    }`,
      fetchPolicy: 'no-cache',
      variables: {
        subject: p.values.subject || '',
        description: p.values.description || '',
        ranking: p.values.ranking,
        id: isUpdate ? p.values.id : undefined,
        createdAt: isUpdate ? undefined : new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> createCompanyQuery',
      e
    );
  }
}
