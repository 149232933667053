import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { IMACompanyFragment, maCompanyFragment } from '../fragments/ma-company';

export interface IGetCompany extends IWithApolloClient {
  id: number;
}
export interface IGetCompanyReturn {
  ma_company: IMACompanyFragment[];
}
export async function getCompanyQuery(p: IGetCompany) {
  try {
    const whereClause = u.where(u._eq('id', '$id'));
    return await p.client.query({
      query: gql`
      ${maCompanyFragment.fragment()}
      query getCompany(${u.params(['$id', 'Int'])}) {
        ma_company(${whereClause}) {
          ...${maCompanyFragment.name}
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: { id: p.id },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> getCompanyQuery',
      e
    );
  }
}
