import gql from 'graphql-tag';
// custom imports
import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import { sg } from 'app/utils/safe-get';
import { companyFragment } from '../../global/fragments/company';
import { IWithApolloClient } from '../../global/types';
import { IProduct, productFragment } from '../../products/fragments/product';
import { MarketplaceFilter } from '../types';

interface IGetMarketplaceItemsList extends IWithApolloClient {
  size: number;
  offset: number;
  filter?: MarketplaceFilter;
  isAdmin?: boolean;
  isLogged?: boolean;
}
interface IMarketplaceSearchElasticQueryReturn {
  products_search: {
    results: {
      ranking: number;
      productId: number;
      Product: IProduct;
      updatedAt: Date;
    }[];
    total: number;
  };
}

export async function marketplaceSearchElasticQuery(
  p: IGetMarketplaceItemsList
) {
  try {
    const isAdmin = p.isAdmin;
    const isLogged = undefined;
    const isElastic = true;

    const query = gql`
    ${companyFragment.fragment(isAdmin, isLogged, isElastic)}
    ${productFragment.fragment(isAdmin, isLogged, isElastic)}
    query productsSearch(
      ${u.params(
        ['$size', 'Int'],
        ['$offset', 'Int'],
        ['$searchText', 'String'],
        ['$formSpecification', 'String'],
        ['$doseCategory', 'String'],
        ['$masForSale', 'String'],
        ['$countryOfOrigin', 'String'],
        ['$gmpApprovals', 'String'],
        ['$therapyArea', 'String'],
        ['$dossierStatus', 'Int'],
        ['$dossierType', 'String'],
        ['$productionCountry', 'String'],
        ['$manufacturerProducts', 'Boolean'],
        ['$isBrandName', 'Boolean'],
        ['$registrationCountry', 'String']
      )}
    ) {
      products_search(
          ${u.variable('limit', '$size')}
          ${u.variable('offset', '$offset')}
          ${u.variable('searchText', '$searchText')}
          ${u.variable('formSpecification', '$formSpecification')}
          ${u.variable('doseCategory', '$doseCategory')}
          ${u.variable('masForSale', '$masForSale')}
          ${u.variable('countryOfOrigin', '$countryOfOrigin')}
          ${u.variable('gmpApprovals', '$gmpApprovals')}
          ${u.variable('therapyArea', '$therapyArea')}
          ${u.variable('dossierStatus', '$dossierStatus')}
          ${u.variable('dossierType', '$dossierType')}
          ${u.variable('productionCountry', '$productionCountry')}
          ${u.variable('manufacturerProducts', '$manufacturerProducts')}
          ${u.variable('isBrandName', '$isBrandName')}
          ${u.variable('registrationCountry', '$registrationCountry')}
        ) {
        total
        results {
          ranking
          productId: productid
          updatedAt: updated_at
          fast_product

          Product {
            ...${productFragment.name}
            Company { ...${companyFragment.name}}
          }

        }
      }
    }
  `;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IMarketplaceSearchElasticQueryReturn>({
      query,
      fetchPolicy: 'no-cache',
      variables: {
        size: p.size,
        offset: p.offset,
        searchText: sg(() => p.filter!.product, ''),
        formSpecification: p
          ? sg(() => p.filter!.formSpecification!.join(' '), '')
          : '',
        doseCategory: sg(() => JSON.stringify(p.filter!.doseCategory), ''),
        countryOfOrigin: p
          ? sg(() => p.filter!.countryOfOrigin!.join(' '), '')
          : '',
        gmpApprovals: p ? sg(() => p.filter!.gmpApprovals!.join(' '), '') : '',
        masForSale: p ? sg(() => p.filter!.masForSale!.join(' '), '') : '',
        therapyArea: p ? sg(() => p.filter!.therapyArea!.join(' '), '') : '',
        dossierStatus: p ? sg(() => p.filter!.dossierStatus, null) : null,
        dossierType: p ? sg(() => p.filter!.dossierType!.join(' '), '') : '',
        productionCountry: p
          ? sg(() => p.filter!.productionCountry!.join(' '), '')
          : '',
        registrationCountry: p
          ? sg(() => p.filter!.registrationCountry!.join(' '), '')
          : '',
        manufacturerProducts: sg(() => p.filter!.manufacturerProducts, false),
        isBrandName: sg(() => p.filter!.isBrandName, false),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/marketplace/query.ts -> marketplaceSearchElasticQuery',
      e
    );
  }
}
