import { setEditRequestModalOpen } from 'app/store/modules/deal/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import EditDealModal from './edit-deal-modal';

interface IStateProps {
  isOpen: boolean;
}

interface IDispatchProps {
  closeModal: () => any;
}

export type IProps = IStateProps & IDispatchProps & IWithApolloClient;

export default withApollo<any>(
  connect<IStateProps, IDispatchProps, IWithApolloClient, IStore>(
    (state: IStore) => {
      return {
        isOpen:
          state.deals.editDealModalOpen && state.auth.loggedInUser.isAdmin,
      };
    },
    (dispatch, ownProps) => {
      return {
        closeModal: () => {
          dispatch(setEditRequestModalOpen(false));
        },
      };
    }
  )(EditDealModal)
);
