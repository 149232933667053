import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import auth from './modules/auth/reducer';
import deals from './modules/deal/reducer';
import companiesContact from './modules/companies-contacts/reducer';
import contactUs from './modules/contact-us/reducer';
import requests from './modules/requests/reducer';
import device from './modules/device/reducer';
import flags from './modules/flags/reducer';
import companies from './modules/ma-companies/reducer';
import manufacturers from './modules/manufacturers/reducer';
import marketplace from './modules/marketplace/reducer';
import products from './modules/products/reducer';
import response from './modules/response/reducer';
import sellerInformation from './modules/seller-information/reducer';
import subscribe from './modules/subscribe/reducer';
import termsheets from './modules/termsheets/reducer';
import substances from './modules/substances/reducer';
import dealDetails from './modules/deal/deal-details/reducer';

const createReducer = (history: any) =>
  combineReducers({
    device,
    response,
    router: connectRouter(history),
    auth,
    products,
    marketplace,
    manufacturers,
    requests,
    deals,
    dealDetails,
    companies,
    companiesContact,
    subscribe,
    contactUs,
    flags,
    sellerInformation,
    termsheets,
    substances,
  });

export default createReducer;
