import * as saga from '@redux-saga/core/effects';
import { toTitleCase } from 'app/store/modules/global/actions';
import { allCountries } from 'app/store/modules/global/types';
import { isBrowser } from 'app/store/utils/is-browser';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import { IWithApolloClient } from '../global/types';
import * as A from './actions';
import * as Query from './query';

function* fetchProductCountriesFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.fetchCountries> = yield saga.take(
      A.fetchCountries
    );

    const params: IWithApolloClient = {
      client: action.payload.client,
    };

    yield saga.put(A.isFetching(true));
    yield saga.call(fetchProductCountriesSaga, params);
  }
}
function* fetchProductCountriesSaga(p: IWithApolloClient) {
  try {
    if (!isBrowser()) {
      return;
    }

    const res: Unpromisefy<
      ReturnType<typeof Query.getProductCountriesQuery>
      // @ts-ignore
    > = yield saga.call(Query.getProductCountriesQuery, p);
    if (!res) {
      throw new Error('Query response is undefined');
    }

    const allLetter = (inputTxt: string) => {
      const letters = /^[A-Za-z]+$/;
      if (inputTxt.match(letters)) {
        return true;
      } else {
        return false;
      }
    };

    const countryCodesList = [
      ...new Set(
        sg(
          () =>
            res.data.productCountries
              .map((country: any) =>
                country.countryCode ? country.countryCode.split(',') : ''
              )
              .flat()
              .filter(
                (countryCode: string) =>
                  countryCode !== '' && allLetter(countryCode)
              ),
          [] as string[]
        )
      ),
    ];
    const countriesList = allCountries
      .filter((country) => country.region !== true)
      .filter((country) => countryCodesList.includes(country.id))
      .map((country) => country.name);
    yield saga.put(A.setCountriesList(countriesList));
    yield saga.put(A.isFetching(false));
    yield saga.put(A.hasFetched(true));
  } catch (e) {
    logger.error(
      'src/app/store/modules/manufacturers/sagas.ts -> fetchProductCountriesSaga',
      e
    );
  }
}

function* fetchManufacturerTypesInCountryFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.fetchCountries> = yield saga.take(
      A.fetchManufacturerTypesInCountry
    );

    yield saga.put(A.isFetching(true));
    yield saga.call(fetchManufacturerTypesInCountrySaga, action.payload);
  }
}
function* fetchManufacturerTypesInCountrySaga(p: IWithApolloClient<any>) {
  try {
    if (!isBrowser()) {
      return;
    }

    const res: Unpromisefy<
      ReturnType<typeof Query.getManufacturerTypesInCountryQuery>
    > = yield saga.call(Query.getManufacturerTypesInCountryQuery, p);
    if (!res) {
      throw new Error('Query response is undefined');
    }

    const manufacturerTypes: string[] = [];

    res.data.substances.map((productSubstances: any) => {
      if (productSubstances.Substance1 && productSubstances.Substance1.name) {
        manufacturerTypes.push(toTitleCase(productSubstances.Substance1.name));
      }
      if (productSubstances.Substance2 && productSubstances.Substance2.name) {
        manufacturerTypes.push(toTitleCase(productSubstances.Substance2.name));
      }
      if (productSubstances.Substance3 && productSubstances.Substance3.name) {
        manufacturerTypes.push(toTitleCase(productSubstances.Substance3.name));
      }
    });

    const uniqueManufacturerTypes = [...new Set(manufacturerTypes)].sort();
    const countryObject = {
      [p.countryId]: {
        manufacturerTypes: uniqueManufacturerTypes,
        pageNr: p.pageNr,
        totalCount:
          res.data.products_v2_aggregate &&
          res.data.products_v2_aggregate.aggregate &&
          res.data.products_v2_aggregate.aggregate.totalCount,
      },
    };

    yield saga.put(A.setManufacturerTypesInCountry(countryObject));
    yield saga.put(A.isFetching(false));
    yield saga.put(A.hasFetched(true));
  } catch (e) {
    logger.error(
      'src/app/store/modules/manufacturers/sagas.ts -> fetchManufacturerTypesInCountrySaga',
      e
    );
  }
}

export default function* rootSaga() {
  yield saga.all([
    saga.fork(fetchProductCountriesFlow),
    saga.fork(fetchManufacturerTypesInCountryFlow),
  ]);
}
