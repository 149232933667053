import { RefObject, useEffect } from 'react';

export function useOutsideClick(
  handler: () => void,
  ...elements: (RefObject<HTMLElement> | undefined)[]
) {
  function f(event: MouseEvent) {
    const path = event.composedPath();
    if (
      !elements
        .map((e) => e && e.current)
        .filter((e) => e)
        .map((e) => path.includes(e as any))
        .reduce((a, b) => a || b, false)
    ) {
      handler();
    }
  }
  useEffect(() => {
    document.addEventListener('click', f);
    return () => document.removeEventListener('click', f);
  }, []);
}
