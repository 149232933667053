import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import {
  setCurrentPage,
  setEditSubstanceId,
  setNotification,
  setPageSize,
  setStatus,
  setSubstanceCache,
  setSubstanceFilter,
  setSubstanceIds,
  setVisibleSubstanceIds,
} from './actions';
import { Notifications, SubstanceFilter } from './types';
import { defaultPageSize } from './types';
import { hasFetched } from './actions';
import { setTotalCount } from './actions';
import { Dictionary } from '../../../utils/dictionary';
const error = cr<Notifications>(
  {
    listError: '',
    listSuccess: '',
  },
  reduce(setNotification, set)
);
const status = cr<number>(0, reduce(setStatus, set));
const pageSize = cr<number>(defaultPageSize, reduce(setPageSize, set));
const currentPage = cr<number>(0, reduce(setCurrentPage, set));
const totalCount = cr<number>(0, reduce(setTotalCount, set));
const substancesCache = cr<Dictionary<any>>([], reduce(setSubstanceCache, set));
const substanceIds = cr<any[]>([], reduce(setSubstanceIds, set));
const visibleSubstanceIds = cr<any[]>([], reduce(setVisibleSubstanceIds, set));
const filter = cr<SubstanceFilter>(
  {
    name: '',
    substance_brands: '',
    therapy_area: '',
  },
  reduce(setSubstanceFilter, set)
);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default combineReducers<State>({
  error,
  status,
  pageSize,
  currentPage,
  totalCount,
  substancesCache,
  substanceIds,
  visibleSubstanceIds,
  filter,
  hasFetched: cr<boolean>(false, reduce(hasFetched, set)),
  editSubstanceId: cr<number | null>(0, reduce(setEditSubstanceId, set)),
});
