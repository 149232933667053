import {
  all,
  call,
  delay,
  fork,
  put,
  select,
  take,
} from '@redux-saga/core/effects';
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import { routes } from 'app/config';
import { ICreateDealForm } from 'app/store/modules/requests/types';
import { v4 as uuidv4 } from 'uuid';
import { anonymousUserId } from 'app/config';
import {
  createAccount as createAccountAction,
  getStarted as getStartedAction,
  linkedinSuccess,
  login as loginAction,
  logout as logoutAction,
  openPopupIfNeeded as openPopupIfNeededAction,
  register as registerAction,
  requestCheckAuth,
  requestFetchNavCount,
  requestRemindPassword,
  requestResetPassword,
  requestSetUserType,
  requestUserSettings,
  requestVerifyEmail,
  setAuthCheckComplete,
  setBuyerQualificationModalOpen,
  setBuyerQualificationModalShown,
  setCreateAccountModalOpen,
  setGetStartedModalOpen,
  setLinkedinSuccess,
  setLoggedIn,
  setLoggedInUser,
  setLoginError,
  setLoginSuccess,
  setNavbarCount,
  setNavbarCountFetchComplete,
  setNotification,
  setRegisterError,
  setUserCompany,
  setUserSettings,
  setVerifyEmailModalOpen,
  updateSettings,
  updateBuyerInfoAction,
} from './actions';
import {
  getCounts,
  getUserCompanySettings,
  getUserSettings,
  NavCountOptions,
  updateCompanySettingsQuery,
  updateUserSettingsQuery,
  updateUserTypeQuery,
} from './query';

import { ApolloClient } from 'apollo-boost';
import { setFlag_preventRedirectAfterLogin } from 'app/store/modules/flags/actions';
import { IStore } from 'app/store/types';
import { isBrowser } from 'app/store/utils/is-browser';
import { logger } from 'app/utils/logger';
import { sg, sgg } from 'app/utils/safe-get';
import { push, replace } from 'connected-react-router';
import { addDays } from 'date-fns';
import {
  createMultipleProductRequests,
  createProductDeal,
  setCreateRequestAfterRegister,
} from '../requests/actions';
import {
  checkAuth,
  createAccount,
  getStarted,
  login,
  logout,
  register,
  resetPassword,
  resetPasswordSubmit,
  updateUserSettingsAPI,
  verifyEmail,
} from './api';
import { convertToCompany } from './fragments/company-settings';
import { convertToUser } from './fragments/user-settings';
import {
  codePlacesRelatedToVerifyEmailModal,
  defaultEmptyCompany,
  defaultEmptyUser,
} from './types';

const daysToShowVerifyEmailModal = 7;
const millisecondsTillModalAppear = 500;

function* loginFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const {
      payload: { username, password, client, isModal },
    }: ReturnType<typeof loginAction> = yield take(loginAction);
    yield call(authenticate, username, password, isModal, client);
  }
}

function* authenticate(
  username: string,
  password: string,
  isModal?: boolean,
  client?: ApolloClient<any>
) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(login, username, password);
    if (response.user !== null && response.user !== undefined) {
      const companyData =
        response.userWithCompany.Company &&
        convertToCompany(response.userWithCompany.Company);
      yield all([
        put(setNavbarCountFetchComplete(false)),
        put(setLoggedInUser(response.userWithCompany)),
        put(setLoggedIn(true)),
        put(setUserSettings(convertToUser(response.user))),
        companyData && put(setUserCompany(companyData)),
      ]);

      let dealCreate = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: IStore = yield select();
      if (client) {
        const createDealForm = state.requests.createDealForm;
        if (createDealForm.product) {
          dealCreate = true;
          yield put(
            createProductDeal({
              client,
              values: createDealForm.product,
            })
          );
          if (createDealForm.product.selectedProducts) {
            yield put(
              createMultipleProductRequests({
                client,
                values: {
                  ...createDealForm.product,
                  selectedProducts: createDealForm.product.selectedProducts,
                },
              })
            );
          }
        }
      }

      if (!state.flags.preventRedirectAfterLogin.redirect) {
        if (!dealCreate) {
          if (isModal) {
            yield put(push(routes.marketplace));
          } else {
            const redirectBackTo: string = sg(
              //
              () => state.router.location.query.redirectBackTo,
              ''
            );

            !redirectBackTo
              ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                yield put(push(routes.products))
              : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                yield put(replace(redirectBackTo));
          }
        }
      } else {
        if (state.flags.preventRedirectAfterLogin.callback) {
          state.flags.preventRedirectAfterLogin.callback();
        }
        yield put(setFlag_preventRedirectAfterLogin(false));
      }

      yield put(setLoginError(''));
      yield put(setGetStartedModalOpen(false));
      ReactGA.event({
        category: 'Login',
        action: 'login',
        value: sg(() => response.userWithCompany.Company.id, 0),
      });

      yield put(
        openPopupIfNeededAction({
          calledFrom: codePlacesRelatedToVerifyEmailModal.authenticate,
        })
      );
    } else {
      yield put(setLoginError(response.error));
    }
  } catch (err) {
    logger.error(err);
  }
}

function* openPopupIfNeededFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const {
      payload: { calledFrom },
    }: ReturnType<typeof openPopupIfNeededAction> = yield take(
      openPopupIfNeededAction
    );
    yield delay(millisecondsTillModalAppear);
    yield call(openPopupIfNeeded, calledFrom);
  }
}

function* openPopupIfNeeded(calledFrom?: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const state: IStore = yield select();
  const {
    products,
    auth: {
      loggedInUser,
      verifyEmailModalOpen,
      buyerQualificationModalOpen,
      buyerQualificationModalShown,
      createAccountModalOpen,
    },
    requests: { createDealForm },
  } = state;

  if (
    loggedInUser &&
    loggedInUser.createdAt &&
    !createAccountModalOpen &&
    !verifyEmailModalOpen &&
    !products.suggestAlternativeSellersModalOpen &&
    !buyerQualificationModalOpen
  ) {
    if (
      sg(() => loggedInUser.firstName, false) &&
      (!sg(() => loggedInUser.Company.numberOfEmployees, false) ||
        !sg(() => loggedInUser.Company.summary, false) ||
        !sg(() => loggedInUser.Company.annualRevenue, false) ||
        !sg(() => loggedInUser.Company.salesChannels, false) ||
        !sg(() => loggedInUser.Company.therapyAreas, false) ||
        !sg(() => loggedInUser.Company.portfolioProductsList, false)) &&
      !buyerQualificationModalShown &&
      createDealForm.product
    ) {
      yield put(setBuyerQualificationModalOpen(true));
      yield put(setBuyerQualificationModalShown(true));
      yield put(setVerifyEmailModalOpen(false));
    } else if (
      new Date() <
        addDays(new Date(loggedInUser.createdAt), daysToShowVerifyEmailModal) &&
      !loggedInUser.verified &&
      (calledFrom === codePlacesRelatedToVerifyEmailModal.createAccountSaga ||
        calledFrom === codePlacesRelatedToVerifyEmailModal.checkAuthSaga ||
        calledFrom === codePlacesRelatedToVerifyEmailModal.authenticate ||
        calledFrom ===
          codePlacesRelatedToVerifyEmailModal.buyerQualificationModal)
    ) {
      yield put(setVerifyEmailModalOpen(true));
    }
  }
}

function* remindPasswordFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const {
      payload: { email },
    }: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ReturnType<typeof requestRemindPassword> = yield take(
      requestRemindPassword
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(resetPassword, email);

    if (response.toString() === '200') {
      yield put(setLoginError(''));
      yield put(setLoginSuccess('Password reset link is sent to your email.'));
      ReactGA.event({
        category: 'forgot-password',
        action: 'forgot-password',
      });
    } else {
      yield put(setLoginSuccess(''));
      yield put(setLoginError('User with this email was not found.'));
    }
  }
}

function* requestResetPasswordFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const {
      payload: { password, recoveryToken },
    }: ReturnType<typeof requestResetPassword> = yield take(
      requestResetPassword
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(resetPasswordSubmit, password, recoveryToken);
    if (response.user !== undefined) {
      yield put(setLoginError(''));

      yield push(routes.login);
      yield put(setLoginSuccess('Password reset success, please login.'));
      ReactGA.event({
        category: 'password-reset',
        action: 'password-reset',
      });
    } else {
      yield push(routes.login);
      yield put(setLoginSuccess(''));
      yield put(setLoginError(response.errors));
    }
  }
}

function* linkedinSuccessFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const {
      payload: { client },
    }: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    ReturnType<typeof linkedinSuccess> = yield take(linkedinSuccess);
    let dealCreate = false;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield select();
    const createDealForm = state.requests.createDealForm;
    if (createDealForm.product) {
      dealCreate = true;
    }
    yield put(setLinkedinSuccess(true));

    yield put(setNavbarCountFetchComplete(false));

    yield put(setLoggedIn(true));
    yield put(requestCheckAuth({ isLinkedin: true, dealCreate, client }));
    yield put(requestFetchNavCount({ client }));

    if (!dealCreate) {
      yield put(push(routes.products));
    } else {
      yield put(setGetStartedModalOpen(false));
    }
  }
}

function* logoutFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    yield take(logoutAction);

    yield call(logout);
    yield put(setLoggedIn(false));
    yield put(setLoggedInUser(defaultEmptyUser));
    yield put(
      setNavbarCount({
        dealCount: 0,
        dealNotSeenCount: 0,
        dealsBuyingCount: 0,
        dealsBuyingNotSeenCount: 0,
        dealsSellingCount: 0,
        dealsSellingNotSeenCount: 0,
        onboardingCount: 0,
        productCount: 0,
        archiveCount: 0,
        marketplaceExistingDealIds: [],
      })
    );
    yield put(setNavbarCountFetchComplete(false));
    yield put(setUserSettings(defaultEmptyUser));
    yield put(setUserCompany(defaultEmptyCompany));
    yield put(
      setCreateRequestAfterRegister({
        product: undefined,
      })
    );

    yield put(setLoginError(''));
    // yield put(push(routes.home));
    if (window) {
      // this is to prevent the user from being redirected to the legacy login page
      window.location.href = routes.home;
      // window.scrollTo(0, 0);
    }
  }
}

export function* windowLoadFlow() {
  if (!isBrowser()) {
    return;
  }

  try {
    yield put(requestCheckAuth({ isLinkedin: false }));
  } catch (err) {
    logger.error(err);
  }
}

export function* checkAuthSaga() {
  try {
    if (!isBrowser()) {
      return;
    }
    while (true) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const action = yield take(requestCheckAuth);
      const { isLinkedin, dealCreate, client } = action.payload;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = yield call(checkAuth);
      if (response.user) {
        yield all([
          put(setLoggedInUser(response.user)),
          put(setUserSettings(convertToUser(response.user))),
          response.user.Company &&
            put(setUserCompany(convertToCompany(response.user.Company))),
        ]);
        if (isLinkedin) {
          yield put(requestFetchNavCount({ client }));
        }
        yield put(setLoggedIn(true));
      }
      if (isLinkedin && !dealCreate) {
        yield put(push(routes.products));
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: IStore = yield select();
      const { firstName, lastName, Company, email } =
        response && !!response.user && response.user;
      if (
        isLinkedin &&
        !(
          firstName &&
          firstName !== '' &&
          lastName &&
          lastName !== '' &&
          Company &&
          Company.name &&
          Company.name !== '' &&
          Company.name !== email &&
          Company.name !== `${email} default company`
        )
      ) {
        yield put(setVerifyEmailModalOpen(false));
        yield put(setCreateAccountModalOpen(true));
      }

      if (dealCreate) {
        const createDealForm = state.requests.createDealForm;
        if (client) {
          if (createDealForm.product) {
            yield put(
              createProductDeal({
                client,
                values: createDealForm.product,
              })
            );
            if (createDealForm.product.selectedProducts) {
              yield put(
                createMultipleProductRequests({
                  client,
                  values: {
                    ...createDealForm.product,
                    selectedProducts: createDealForm.product.selectedProducts,
                  },
                })
              );
            }
          }
        }
      }

      yield put(
        openPopupIfNeededAction({
          calledFrom: codePlacesRelatedToVerifyEmailModal.checkAuthSaga,
        })
      );
      yield put(setAuthCheckComplete(true));

      const uuid = localStorage.getItem(anonymousUserId);
      if (!uuid) {
        localStorage.setItem(anonymousUserId, uuidv4());
      }
    }
  } catch (err) {
    logger.error(err);
  }
}

function* fetchCount() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload: { client },
      }: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ReturnType<typeof requestFetchNavCount> = yield take(
        requestFetchNavCount
      );
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth }: IStore = yield select();

      if (auth.loggedIn) {
        const params: Params<typeof getCounts> = {
          client,
          isAdmin: auth.loggedInUser.isAdmin,
          companyId:
            auth.loggedInUser && auth.loggedInUser.Company
              ? auth.loggedInUser.Company.id
              : 0,
          counts: [
            NavCountOptions.Products,
            ...(auth.loggedInUser.isAdmin
              ? [
                  NavCountOptions.Requests,
                  NavCountOptions.Onboarding,
                  NavCountOptions.Archive,
                ]
              : [
                  NavCountOptions.Deals_Buying,
                  NavCountOptions.Deals_Selling,
                  NavCountOptions.Archive,
                  NavCountOptions.Marketplace,
                ]),
          ],
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const counts: Unpromisefy<ReturnType<typeof getCounts>> = yield call(
          getCounts,
          params
        );
        if (!counts) {
          throw new Error('GraphQL returned undefined');
        }
        yield put(
          setNavbarCount({
            productCount: sg(
              () =>
                counts?.data?.productCount?.a?.count -
                (counts?.data?.onboardingCount?.a?.count || 0),
              auth.navbarCount.productCount
            ),
            dealCount: sg(
              () => counts.data.dealCount!.a.count,
              auth.navbarCount.dealCount
            ),
            dealNotSeenCount: sg(
              () => counts.data.dealNotSeenCount!.a.count,
              auth.navbarCount.dealNotSeenCount
            ),
            dealsBuyingCount: sg(
              () => counts.data.dealsBuyingCount!.a.count,
              auth.navbarCount.dealsBuyingCount
            ),
            dealsBuyingNotSeenCount: sg(
              () => counts.data.dealsBuyingNotSeenCount!.a.count,
              auth.navbarCount.dealsBuyingNotSeenCount
            ),
            dealsSellingCount: sg(
              () => counts.data.dealsSellingCount!.a.count,
              auth.navbarCount.dealsSellingCount
            ),
            dealsSellingNotSeenCount: sg(
              () => counts.data.dealsSellingNotSeenCount!.a.count,
              auth.navbarCount.dealsSellingNotSeenCount
            ),
            onboardingCount: sg(
              () => counts.data.onboardingCount!.a.count,
              auth.navbarCount.onboardingCount
            ),
            archiveCount: sg(
              () => counts.data.archiveCount!.a.count,
              auth.navbarCount.archiveCount
            ),
            marketplaceExistingDealIds: sg(
              () => counts.data.marketplaceExistingDealIds,
              []
            ),
          })
        );
        yield put(setNavbarCountFetchComplete(true));
        // yield put(requestUserSettings({ client }));
      }
    } catch (e) {
      logger.error('src/app/store/modules/auth/sagas.ts -> fetchCount', e);
    }
  }
}

function* getStartedFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof getStartedAction> = yield take(
      getStartedAction
    );

    yield call(getStartedSaga, payload);
  }
}

function* getStartedSaga(data: ReturnType<typeof getStartedAction>['payload']) {
  const user = {
    email: data.businessEmail,
    password: data.password,
    isAdmin: false,
    isDefault_broker: false,
    userType: undefined,
    isSeller: data.isSeller || null,
  };

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(getStarted, user);

    if (response.errors === undefined && response.status !== undefined) {
      const username = data.businessEmail;
      const password = data.password;
      const isModal = data.isModal;
      const client = data.client;
      yield call(authenticate, username, password, isModal, client);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: IStore = yield select();
      const dealForm: ICreateDealForm = state.requests.createDealForm;

      if (data.isSellerLanding) {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions ? GAActions.registerGetStartedFromSellerPage : '',
        });
      } else if (dealForm.product) {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions
            ? GAActions.registerGetStartedFromProductRequest
            : '',
        });
      } else {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions ? GAActions.registerGetStarted : '',
        });
      }

      if (data.isModal) {
        yield put(setGetStartedModalOpen(false));
      }
    } else {
      yield put(setRegisterError(response.errors));
    }
  } catch (err) {
    logger.error(err);
  }
}

function* createAccountFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof createAccountAction> = yield take(
      createAccountAction
    );

    yield call(createAccountSaga, payload);
  }
}

function* createAccountSaga(
  data: ReturnType<typeof createAccountAction>['payload']
) {
  const user = {
    fullName: data.firstName + ' ' + data.lastName,
    firstName: data.firstName,
    lastName: data.lastName,
    companyName: data.companyName,
    phone: data.phoneNumber,
    isBuyer: data.isBuyer,
    isSeller: data.isSeller,
  };

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(createAccount, user);

    if (!response.errors && response.user && response.user.id) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: IStore = yield select();
      const dealForm: ICreateDealForm = state.requests.createDealForm;

      if (data.isSellerLanding) {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions
            ? GAActions.registerCreateAccountFromSellerPage
            : '',
        });
      } else if (dealForm.product) {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions
            ? GAActions.registerCreateAccountFromProductRequest
            : '',
        });
      } else {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions ? GAActions.registerCreateAccount : '',
        });
      }

      if (data.isModal) {
        yield put(setGetStartedModalOpen(false));
      }

      if (response.user) {
        yield all([
          put(setLoggedInUser(response.user)),
          put(setUserSettings(convertToUser(response.user))),
          response.user.Company &&
            put(setUserCompany(convertToCompany(response.user.Company))),
        ]);

        let dealCreate = false;
        let createMultipleDeals = false;
        if (dealForm.product) {
          dealCreate = true;
          if (
            dealForm.product &&
            dealForm.product.selectedProducts &&
            dealForm.product.selectedProducts.length > 0
          ) {
            createMultipleDeals = true;
          }
        }
        yield put(setLoggedIn(true));

        if (dealCreate) {
          const productDeal: any = Object.values(
            state.requests.dealsCache
          ).find((request: any) =>
            sgg<boolean>(
              () => request.Product!.id === dealForm.product!.product!.id,
              undefined
            )
          );

          if (createMultipleDeals) {
            yield put(push(routes.deals_buying));
          } else if (productDeal && productDeal.id) {
            yield put(push('/request-details/' + productDeal.id));
          }
        } else {
          yield put(push(routes.products));
        }

        if (data.isModal) {
          yield put(setCreateAccountModalOpen(false));
        }
        yield put(
          openPopupIfNeededAction({
            calledFrom: codePlacesRelatedToVerifyEmailModal.createAccountSaga,
          })
        );
      }
    } else {
      yield put(setRegisterError(response.errors));
    }
  } catch (err) {
    logger.error(err);
  }
}

function* registerFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof registerAction> = yield take(
      registerAction
    );

    yield call(registerSaga, payload);
  }
}

function* registerSaga(data: ReturnType<typeof registerAction>['payload']) {
  const user = {
    fullName: data.name + ' ' + data.surname,
    name: data.name,
    surname: data.surname,
    phone: data.phoneNumber,
    companyName: data.company,
    country: data.country,
    email: data.businessEmail,
    password: data.password,
    isAdmin: false,
    isDefault_broker: false,
    userType: undefined,
  };

  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(register, user);

    if (response.errors === undefined && response.status !== undefined) {
      const username = data.businessEmail;
      const password = data.password;
      const isModal = data.isModal;
      const client = data.client;
      yield call(authenticate, username, password, isModal, client);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: IStore = yield select();
      const dealForm: ICreateDealForm = state.requests.createDealForm;
      if (data.isSellerLanding) {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions ? GAActions.registerFromSellerPage : '',
        });
      } else if (dealForm.product) {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions ? GAActions.registerFromProductRequest : '',
        });
      } else {
        ReactGA.event({
          category: GACategory ? GACategory.Register : '',
          action: GAActions ? GAActions.register : '',
        });
      }

      if (data.isModal) {
        yield put(setGetStartedModalOpen(false));
      }
    } else {
      yield put(setRegisterError(response.errors));
    }
  } catch (err) {
    logger.error(err);
  }
}

function* fetchUserSettings() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const {
        payload: { client },
      }: ReturnType<typeof requestUserSettings> = yield take(
        requestUserSettings
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth }: IStore = yield select();
      if (auth.loggedInUser.id !== 0) {
        const paramsU: Params<typeof getUserSettings> = {
          client,
          uuid: auth.loggedInUser.uuid || '',
        };
        const userSettingsResponse: Unpromisefy<
          ReturnType<typeof getUserSettings>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        > = yield call(getUserSettings, paramsU);
        if (!userSettingsResponse) {
          throw new Error('GraphQL getUserSettings returned undefined');
        }

        const paramsC: Params<typeof getUserCompanySettings> = {
          client,
          companyId:
            (auth.loggedInUser.Company && auth.loggedInUser.Company.id) || 0,
        };
        const userCompanyResponse: Unpromisefy<
          ReturnType<typeof getUserCompanySettings>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
        > = yield call(getUserCompanySettings, paramsC);
        if (!userCompanyResponse) {
          throw new Error('GraphQL getUserCompanySettings returned undefined');
        }

        const userSettings = userSettingsResponse.data.users[0];
        const companySettings = userCompanyResponse.data.companies[0];

        yield all([
          put(setUserSettings(convertToUser(userSettings))),
          companySettings &&
            put(setUserCompany(convertToCompany(companySettings))),
        ]);
      }
    } catch (e) {
      logger.error(
        'src/app/store/modules/auth/sagas.ts -> fetchUserSettings',
        e
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { auth }: IStore = yield select();
      yield put(
        setNotification({
          ...auth.notifications,
          error: 'There was an error loading your settings.',
        })
      );
    }
  }
}

function* updateSettingsFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof updateSettings> = yield take(
      updateSettings
    );

    yield call(updateUserSettings, payload);
  }
}

function* updateUserSettings(p: ReturnType<typeof updateSettings>['payload']) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { auth }: IStore = yield select();
  const paramsC: Params<typeof updateCompanySettingsQuery> = {
    client: p.client,
    company: p.company,
  };

  if (p.user) {
    ReactGA.event({
      category: GACategory.UserSettings,
      action: GAActions ? GAActions.saveUserSettings : '',
      value: p.user.id || 0,
    });

    const user = { ...auth.loggedInUser, ...p.user };

    const paramsU: Params<typeof updateUserSettingsQuery> = {
      client: p.client,
      user,
    };

    try {
      if (
        sg(() => user.newPassword!.length > 0, false) &&
        sg(() => user.oldPassword!.length > 0, false)
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const response = yield call(updateUserSettingsAPI, user);

        yield call(updateCompanySettingsQuery, paramsC);
        yield call(updateUserSettingsQuery, paramsU);

        if (response.errors === undefined) {
          yield put(
            setUserSettings({
              ...user,
              newPassword: undefined,
              oldPassword: undefined,
            })
          );
          yield put(setUserCompany(p.company));
          yield put(requestCheckAuth({ client: p.client }));
          yield put(
            setNotification({
              success: 'You’ve successfully updated your settings',
              error: '',
            })
          );
          window.scrollTo(0, 0);
        } else {
          yield put(
            setNotification({ error: response.errors.oldPassword, success: '' })
          );
          window.scrollTo(0, 0);
        }
      } else {
        yield call(updateCompanySettingsQuery, paramsC);
        yield call(updateUserSettingsQuery, paramsU);
        yield put(requestCheckAuth({ client: p.client }));

        yield put(
          setNotification({
            success: 'You’ve successfully updated your settings',
            error: '',
          })
        );
        window.scrollTo(0, 0);
      }
    } catch (err) {
      logger.error(err);
      setNotification({
        success: '',
        error: 'There was an error updating your settings.',
      });
      window.scrollTo(0, 0);
    }
  } else {
    yield call(updateCompanySettingsQuery, paramsC);
  }
}
function* updateBuyerInfoFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof updateBuyerInfoAction> = yield take(
      updateBuyerInfoAction
    );

    yield call(updateBuyerInfo, payload);
  }
}
function* updateBuyerInfo(
  p: ReturnType<typeof updateBuyerInfoAction>['payload']
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const paramsC: Params<typeof updateCompanySettingsQuery> = {
    client: p.client,
    company: p.company,
  };

  if (p.buyer) {
    const user = { ...p.buyer };

    const paramsU: Params<typeof updateUserSettingsQuery> = {
      client: p.client,
      user,
    };

    try {
      yield call(updateCompanySettingsQuery, paramsC);
      yield call(updateUserSettingsQuery, paramsU);
    } catch (err) {
      logger.error(err);
      setNotification({
        success: '',
        error: 'There was an error updating your settings.',
      });
    }
  } else {
    yield call(updateCompanySettingsQuery, paramsC);
  }
}

function* verifyEmailFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof requestVerifyEmail> = yield take(
      requestVerifyEmail
    );

    yield call(verifyEmailSaga, payload);
  }
}

function* verifyEmailSaga(p: ReturnType<typeof requestVerifyEmail>['payload']) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const response = yield call(verifyEmail, p);
    if (response.errors === undefined) {
      yield put(requestCheckAuth({ isLinkedin: false }));
    }
  } catch (err) {
    logger.error(err);
  }
}

function* requestSetUserTypeSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof requestSetUserType> = yield take(
      requestSetUserType
    );
    const param = {
      client: payload.client,
      userId: payload.userId,
      userType: payload.userType,
    };

    yield call(updateUserTypeQuery, param);
    yield put(requestCheckAuth({ client: payload.client }));
  }
}

export default function* rootSaga() {
  yield all([
    fork(loginFlow),
    fork(logoutFlow),
    fork(getStartedFlow),
    fork(createAccountFlow),
    fork(openPopupIfNeededFlow),
    fork(registerFlow),
    fork(windowLoadFlow),
    fork(fetchCount),
    fork(fetchUserSettings),
    fork(updateSettingsFlow),
    fork(checkAuthSaga),
    fork(remindPasswordFlow),
    fork(requestResetPasswordFlow),
    fork(verifyEmailFlow),
    fork(linkedinSuccessFlow),
    fork(requestSetUserTypeSaga),
    fork(updateBuyerInfoFlow),
  ]);
}
