import { ActionWithPayload, createReducer, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';
import * as actions from './actions';
import { defaultStore, IState } from './types';

function flag(
  action: (payload: boolean) => ActionWithPayload<string, boolean>
) {
  return createReducer<boolean>(false, reduce(action, set));
}
/* eslint-disable  */
 // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
export default combineReducers<IState>({
  offerTradeFormSaveCompleted: flag(actions.setFlag_offerTradeFormSaveCompleted),
  brokerChangeId: createReducer<number | null>(null, reduce(actions.setFlag_brokerChangeId, set)),
  directoriesListModalOpen: flag(actions.setFlag_directoriesListModalOpen),
  directoriesListModalOpenLocation: createReducer<string>('', reduce(actions.setFlag_directoriesListModalOpenLocation, set)),
  preventRedirectAfterLogin: createReducer(defaultStore.preventRedirectAfterLogin,
    reduce(actions.setFlag_preventRedirectAfterLogin, (state, { payload }) => ({ redirect: payload })),
    reduce(actions.setFlag_preventRedirectAfterLoginWC, set)
  ),
  bannerSeen: flag(actions.setFlagBannerSeen),
});
