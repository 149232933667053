import { IPaginateable } from '../global/types';
import { Dictionary } from '../../../utils/dictionary';

export interface SubstanceFilter {
  name: string;
  therapy_area: any;
  substance_brands: any;
}
export enum Status {
  idle,
  loading,
}
export interface Notifications {
  listError: string;
  listSuccess: string;
}
export const defaultPageSize = 10;
export interface State extends IPaginateable {
  substancesCache: Dictionary<any>;
  productIds: any[];
  visibleProductIds: any[];
  filter: SubstanceFilter;
  error: Notifications;
  status: Status;
  hasFetched: boolean;
}
export const defaultStore: State = {
  substancesCache: {},
  productIds: [],
  visibleProductIds: [],
  totalCount: 0,
  currentPage: 0,
  pageSize: 0,
  filter: {
    name: '',
    therapy_area: '',
    substance_brands: '',
  },
  error: { listSuccess: '', listError: '' },
  status: Status.idle,
  hasFetched: false,
};
