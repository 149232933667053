import React, { useState } from 'react';

// custom imports
import SaveButton from 'app/components/buttons/save-button/save-button';
import TextInput from 'app/components/inputs/text-input';
import { EditButton } from '../seller-contact/seller-contact-list';
import {
  CancelButton,
  SaveButtonContainer,
  SummaryBox,
  SummaryContainer,
  SummaryContent,
  SummaryText,
} from '../styles';
import {
  ButtonContainer,
  EditActions,
  EditButtonContainer,
  EditRemarksContainer,
  HeaderContainer,
} from '../styles';

interface IProps {
  companySummary: string;
  updateCompanySummary: (remarks: string) => any;
  setEditDisabled?: (editDisabled: boolean) => any;
  updateCache: any;
}

const EditCompanySummary: React.FC<IProps> = ({
  companySummary,
  updateCompanySummary,
  setEditDisabled,
}) => {
  const [companySummaryValue, setCompanySummaryValue] = useState<string>(
    companySummary
  );
  return (
    <EditRemarksContainer>
      <TextInput
        multiline={true}
        value={companySummaryValue}
        onChange={(value) => setCompanySummaryValue(value)}
      />
      <EditActions>
        <ButtonContainer>
          <CancelButton
            onClick={() => {
              if (setEditDisabled) {
                setEditDisabled(true);
              }
            }}
          >
            Cancel
          </CancelButton>
        </ButtonContainer>
        <SaveButtonContainer>
          <SaveButton
            onClick={() => {
              updateCompanySummary(companySummaryValue);
              if (setEditDisabled) {
                setEditDisabled(true);
              }
            }}
          />
        </SaveButtonContainer>
      </EditActions>
    </EditRemarksContainer>
  );
};

const CompanySummary: React.FC<IProps> = ({
  companySummary,
  updateCache,
  updateCompanySummary,
}) => {
  const headerText = 'Company Summary';
  const [editDisabled, setEditDisabled] = useState<boolean>(true);
  let companySummaryText = '';
  if (companySummary) {
    companySummaryText = companySummary || updateCache.summary;
  }

  return (
    <SummaryBox>
      {editDisabled && (
        <SummaryContainer showBorder={false}>
          <HeaderContainer>{headerText}</HeaderContainer>
          <SummaryContent>
            <SummaryText>
              {companySummaryText || 'No summary available'}
            </SummaryText>
            <EditButtonContainer>
              <EditButton
                editDisabled={editDisabled}
                setEditDisabled={setEditDisabled}
              />
            </EditButtonContainer>
          </SummaryContent>
        </SummaryContainer>
      )}
      {!editDisabled && (
        <SummaryContainer showBorder={true}>
          <HeaderContainer>{headerText}</HeaderContainer>
          <EditCompanySummary
            companySummary={companySummaryText as string}
            updateCompanySummary={updateCompanySummary}
            updateCache={updateCache}
            setEditDisabled={setEditDisabled}
          />
        </SummaryContainer>
      )}
    </SummaryBox>
  );
};

export default CompanySummary;
