import { routes } from 'app/config';
import {
  openPopupIfNeeded,
  setBuyerQualificationModalOpen,
  updateSettings,
} from 'app/store/modules/auth/actions';
import { Company, User } from 'app/store/modules/auth/types';
import { codePlacesRelatedToVerifyEmailModal } from 'app/store/modules/auth/types';
import { setCreateRequestAfterRegister } from 'app/store/modules/requests/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { isString } from 'lodash';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import BuyerQualificationModal from './buyer-qualification-modal';

interface IStateProps {
  isOpen: boolean;
  company: Company;
  userSettings: User;
}

interface IDispatchProps {
  closeModal: (user: User, company: Company) => any;
}

export type IProps = IStateProps & IDispatchProps & IWithApolloClient;

export default withApollo<any>(
  connect<IStateProps, IDispatchProps, IWithApolloClient, IStore>(
    (state: IStore) => {
      const pathname = state.router.location.pathname;
      const isValidPath =
        isString(pathname) && pathname.indexOf(routes.usersettings) === -1; // fix for modal shouldn't open up in userSettings.
      const isOpen = isValidPath && state.auth.buyerQualificationModalOpen;

      return {
        isOpen,
        company: state.auth.loggedInUser.Company,
        isLoggedIn: state.auth.loggedIn,
        userSettings: state.auth.loggedInUser,
      };
    },
    (dispatch, ownProps) => {
      return {
        closeModal: (user, company) => {
          dispatch(
            setCreateRequestAfterRegister({
              product: undefined,
            })
          );
          dispatch(updateSettings({ user, company, client: ownProps.client }));

          dispatch(
            openPopupIfNeeded({
              calledFrom:
                codePlacesRelatedToVerifyEmailModal.buyerQualificationModal,
            })
          );
          dispatch(setBuyerQualificationModalOpen(false));
        },
      };
    }
  )(BuyerQualificationModal)
);
