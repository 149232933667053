import * as saga from '@redux-saga/core/effects';
import { ApolloClient } from 'apollo-boost';
import * as A from './actions';
import * as Query from './query';

function isBrowser(): boolean {
  return process.env.TARGET === 'browser';
}

function* createSubscribeFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const action: ReturnType<typeof A.createSubscribe> = yield saga.take(
      A.createSubscribe
    );

    yield saga.call(
      createSubscribeSaga,
      action.payload.client,
      action.payload.email
    );
  }
}

function* createSubscribeSaga(client: ApolloClient<any>, email: string) {
  try {
    if (!isBrowser()) {
      return;
    }

    const res: Unpromisefy<
      ReturnType<typeof Query.createSubscribeQuery>
    > = yield saga.call(Query.createSubscribeQuery, client, email);

    return res;
  } catch (e) {
    yield saga.put(
      A.setSbuscribeNotification({
        listError: 'Your email is already in subscribe list.',
        listSuccess: '',
      })
    );
  }
}
export default function* rootSaga() {
  yield saga.all([saga.fork(createSubscribeFlow)]);
}
