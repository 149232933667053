/* eslint-disable  */
import React from 'react';
import Select, { Props } from './select';

interface IProps<T extends string | number = string> extends Props<T> {
  __type?: T;
  setValuesOnUnmount?: (value: ISelectItem<T>[] | ISelectItem<T>) => any;
}
export const WrappedSelect: <T extends string | number = string>(
  props: IProps<T>
) => React.ReactElement<IProps<T>> = ({
  setValuesOnUnmount,
  __type,
  ...props
}) => {
  // const [options, select] = React.useState<
  //   Array<ISelectItem<NonNullable<typeof __type>>>
  // >(
  //   (props.isMulti
  //     ? (props.values as Array<ISelectItem<NonNullable<typeof __type>>>)
  //     : [props.value as ISelectItem<NonNullable<typeof __type>>]) || []
  // );
  // React.useEffect(
  //   () => () => setValuesOnUnmount(props.isMulti ? options : options[0]),
  //   []
  // );

  return (
    <Select<any>
      {...props}
      // value={props.isMulti ? undefined : options[0]}
      // values={props.isMulti ? options : undefined}
      // onChange={val => {
      //   select([val]);
      //   if (props.onChange) {
      //     props.onChange(val);
      //   }
      // }}
      // onChangeMulti={val => {
      //   select(val);
      //   if (props.onChangeMulti) {
      //     props.onChangeMulti(val);
      //   }
      // }}
    />
  );
};
