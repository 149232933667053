import { Fetch } from 'app/modules/requests/my-requests/my-requests-list';
import * as u from 'app/store/modules/query-utils';
import sg, { sgg } from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { dealsFragment } from '../../deal/fragments/deal';
import {
  IProductFragment,
  productFragment,
} from '../../products/fragments/product';
import {
  CompanyUtils,
  RequestUtils,
  ProductUtils,
  SubstanceUtils,
  UserUtils,
} from '../../query-utils';
import { requestSchema, Schema_Request } from '../../query-utils/schema';
import { requestFragment, IRequestFragment } from '../fragments/request';
import { IRequestListTyped } from './get-requests-list-query';

export interface Company {
  id: number;
  name: string;
  hidden: boolean;
}
export interface IRequestsListReturn {
  requests: (IRequestFragment & {
    BuyerCompany: Company;
    SellerCompany: Company;
    Product: IProductFragment;
    lastMessage: { adminMessage: boolean }[];
    uncheckedMessages: { id: number }[];
  })[];
  requests_aggregate: { aggregate: { totalCount: number } };
}
export function getRequestsList(p: IRequestListTyped) {
  try {
    const isBuyerOrSeller = {
      [Fetch.All]: RequestUtils._or(
        RequestUtils._eq('buyer_company_id', '$currentId'),
        RequestUtils._eq('seller_company_id', '$currentId')
      ),
      [Fetch.Buying]: RequestUtils._eq('buyer_company_id', '$currentId'),
      [Fetch.Selling]: RequestUtils._and(
        RequestUtils._eq('seller_company_id', '$currentId'),
        RequestUtils._eq('admin_approved', true)
      ),
    };
    const orderBy: Dictionary<u.Keys<Schema_Request>> = {
      [Fetch.All]: 'updated_at',
      [Fetch.Buying]: 'buyer_updated_at',
      [Fetch.Selling]: 'seller_updated_at',
    };
    const isArchivedCheck = u._or(
      p.fetch === Fetch.All &&
        u._and(
          RequestUtils._is_null(
            'admin_archived_at',
            !p.isArchived ? true : false
          )
        ),
      p.fetch === Fetch.Buying &&
        u._and(
          RequestUtils._eq('buyer_company_id', '$currentId'),
          RequestUtils._is_null(
            'buyer_archived_at',
            !p.isArchived ? true : false
          )
        ),
      p.fetch === Fetch.Selling &&
        u._and(
          RequestUtils._eq('seller_company_id', '$currentId'),
          RequestUtils._is_null(
            'seller_archived_at',
            !p.isArchived ? true : false
          )
        )
    );

    const filterClause =
      !!p.filter &&
      RequestUtils._and(
        !!p.filter.product &&
          RequestUtils._or(
            RequestUtils.nest(
              'Product',
              ProductUtils.nest(
                'Substance1',
                SubstanceUtils._ilike('name', '$product')
              )
            ),
            RequestUtils.nest(
              'Product',
              ProductUtils.nest(
                'Substance2',
                SubstanceUtils._ilike('name', '$product')
              )
            ),
            RequestUtils.nest(
              'Product',
              ProductUtils.nest(
                'Substance3',
                SubstanceUtils._ilike('name', '$product')
              )
            )
          ),
        !!p.filter.request && RequestUtils._eq('id', '$requestId'),
        !!p.filter.status && RequestUtils._eq('status', '$status'),
        !!p.filter.company && p.isAdmin
          ? RequestUtils._or(
              RequestUtils.nest(
                'Seller_company',
                CompanyUtils._ilike('name', '$company')
              ),
              RequestUtils.nest(
                'Buyer_company',
                CompanyUtils._ilike('name', '$company')
              )
            )
          : '',
        !!p.filter.email &&
          RequestUtils._or(
            RequestUtils.nest('Buyer', UserUtils._ilike('email', '$email')),
            RequestUtils.nest('Seller', UserUtils._ilike('email', '$email'))
          ),
        !!p.filter.createdFrom && RequestUtils._gte('created_at', '$CFrom'),
        !!p.filter.createdTo && RequestUtils._lte('created_at', '$CTo'),
        !!p.filter.broker && RequestUtils._eq('broker', '$broker'),
        p.filter &&
          p.filter.broker &&
          p.filter.broker.value === null &&
          RequestUtils._is_null('broker')
      );
    const originFilter = sgg<any>(
      () =>
        p.originFilter === 'manuf' &&
        u._or(RequestUtils._is_null('product_id', false)),
      ''
    );

    const whereClause = p.isAdmin
      ? RequestUtils._and(isArchivedCheck, filterClause, originFilter)
      : RequestUtils._and(
          isBuyerOrSeller[p.fetch],
          isArchivedCheck,
          filterClause,
          originFilter
        );

    return p.client.query({
      query: gql`
        ${new u.Query('getRequestsList', { isAdmin: p.isAdmin })
          .fragments(productFragment, requestFragment, dealsFragment)
          .params(
            ['$currentId', 'Int'],
            ['$limit', 'Int'],
            ['$offset', 'Int'],
            ['$product', 'String'],
            ['$requestId', 'Int'],
            ['$company', 'String'],
            p.isAdmin && ['$email', 'String'],
            ['$CFrom', 'timestamptz'],
            ['$CTo', 'timestamptz'],
            ['$broker', 'Int'],
            ['$status', 'Int']
          )
          .query(requestSchema, 'requests')
          .offset('$offset')
          .limit('$limit')
          .where(() => whereClause)
          .orderBy(orderBy[p.fetch], 'desc')
          .select(
            requestFragment,
            [
              'Buyer_company',
              'BuyerCompany',
              CompanyUtils.selectParams(
                'id',
                p.isAdmin ? 'name' : undefined,
                'hidden',
                p.isAdmin ? ['annual_revenue', 'annualRevenue'] : undefined,
                p.isAdmin ? ['sales_channels', 'salesChannels'] : undefined,
                p.isAdmin
                  ? ['portfolio_products_list', 'portfolioProductsList']
                  : undefined,
                p.isAdmin ? ['therapy_areas', 'therapyAreas'] : undefined,
                p.isAdmin
                  ? ['number_of_employees', 'numberOfEmployees']
                  : undefined,
                p.isAdmin ? 'summary' : undefined
              ),
            ],
            [
              'Seller_company',
              'SellerCompany',
              CompanyUtils.selectParams(
                'id',
                p.isAdmin ? 'name' : undefined,
                'hidden',
                p.isAdmin ? ['annual_revenue', 'annualRevenue'] : undefined,
                p.isAdmin ? ['sales_channels', 'salesChannels'] : undefined,
                p.isAdmin
                  ? ['portfolio_products_list', 'portfolioProductsList']
                  : undefined,
                p.isAdmin ? ['therapy_areas', 'therapyAreas'] : undefined,
                p.isAdmin
                  ? ['number_of_employees', 'numberOfEmployees']
                  : undefined,
                p.isAdmin ? 'summary' : undefined
              ),
            ],
            p.isAdmin && [
              'Buyer',
              'Buyer',
              UserUtils.selectParams(
                'id',
                ['first_name', 'firstName'],
                ['last_name', 'lastName'],
                'phone',
                'email',
                'verified'
              ),
            ],
            p.isAdmin && [
              'Seller',
              'Seller',
              UserUtils.selectParams(
                'id',
                ['first_name', 'firstName'],
                ['last_name', 'lastName'],
                'phone',
                'email',
                'verified'
              ),
            ],
            ['Product', true, productFragment],

            !p.isArchived && [
              [
                'Messages',
                {
                  // last admin
                  limit: 1,
                  order: u.DealMessagesUtils.orderBy('created_at', 'desc'),
                  where: u.where(u.DealMessagesUtils._is_null('field_id')),
                },
              ],
              'lastMessage',
              u.DealMessagesUtils.selectParams([
                'is_admin_message',
                'adminMessage',
              ]),
            ],
            !p.isArchived && [
              [
                'Messages',
                {
                  limit: 1,
                  where: u.where(
                    u._and(
                      u.DealMessagesUtils._is_null('field_id'),
                      u._and(
                        u.DealMessagesUtils._is_null('admin_reply_text'),
                        u._or(
                          u.DealMessagesUtils._eq('buyer_see', false),
                          u.DealMessagesUtils._eq('seller_see', false)
                        )
                      )
                    )
                  ),
                },
              ],
              'uncheckedMessages',
              'id',
            ],
            p.isAdmin && [
              'Deal',
              true,
              u.DealUtils.selectParams(
                ['id', 'id'],
                ['Substance1', true, SubstanceUtils.selectParams('id', 'name')],
                ['Substance2', true, SubstanceUtils.selectParams('id', 'name')],
                ['Substance3', true, SubstanceUtils.selectParams('id', 'name')],
                ['countries_of_interest', 'countriesOfInterest'],
                ['target_supply_price', 'targetSupplyPrice'],
                ['estimated_annual_quantity', 'estimatedAnnualQuantity'],
                ['expect_sign_contract', 'expectSignContract'],
                ['deal_type', 'dealType'],
                ['comments', 'comments'],
                [
                  'Buyer',
                  true,
                  UserUtils.selectParams(
                    'id',
                    ['full_name', 'fullName'],
                    ['first_name', 'firstName'],
                    ['last_name', 'lastName'],
                    p.isAdmin && 'email',
                    p.isAdmin && [
                      'Company',
                      true,
                      CompanyUtils.selectParams('id', 'name'),
                    ]
                  ),
                ],
                [
                  'Broker',
                  true,
                  UserUtils.selectParams(
                    'id',
                    ['full_name', 'fullName'],
                    p.isAdmin && 'email'
                  ),
                ]
              ),
            ]
          )
          .toString()
          .aggregate(requestSchema, 'requests_aggregate', 'aggregate')
          .where(() => whereClause)
          .count('totalCount')
          .toString()
          .toString()}
      `,
      fetchPolicy: 'no-cache',
      variables: {
        limit: p.limit,
        offset: p.offset,
        currentId:
          p.companyId && !p.isAdmin
            ? sg(() => p.companyId, undefined)
            : undefined,
        product:
          p.filter && p.filter.product
            ? u.like(p.filter.product || '', 'begining')
            : undefined,
        requestId: p.filter && p.filter.request ? p.filter.request : undefined,
        company:
          p.filter && p.filter.company
            ? u.like(p.filter.company || '', 'begining')
            : undefined,
        email:
          p.isAdmin && p.filter && p.filter.email
            ? u.like(p.filter.email || '', 'begining')
            : undefined,
        CFrom: p.filter && p.filter.createdFrom,
        CTo: p.filter && p.filter.createdTo,
        broker: p.filter && p.filter.broker && p.filter.broker.value,
        status: p.filter && p.filter.status,
      },
    }) as IRequestsListReturn;
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> getRequestsList',
      e
    );
  }
}
