import { IFragment } from 'app/store/modules/query-utils/fragment';
import { ICompanyFragment } from '../../global/fragments/company';
import { IProductSubstance } from '../../global/types';
import { Product } from '../types';

export interface IProductFragment {
  id: number;
  substance1: number;
  substance2?: number;
  substance3?: number;
  Substance1: IProductSubstance;
  Substance2?: IProductSubstance;
  Substance3?: IProductSubstance;
  autoRanking: number;
  dossierStatus: number;
  dossierCompletionQuarter: string;
  dossierCompletionYear: string;
  productAvailableLicensingSupply: boolean;
  productAvailableSupplyDistribution: boolean;
  productAvailableTechnologyTransfer: boolean;
  productAvailableSpecialImport: boolean;
  dosierType: number;
  gmpApprovalForThisProduct: string;
  finishedDoseCountry: string;
  finishedDoseCountryEu: boolean;
  notAvailableMarkets: string;
  registeredIn: string;
  marketingAuthorizationsAchievedCountry: string;
  stabilityDataZones: string;
  cpp: string;
  clinicalData: string;
  commentsOnThePatentStatus: string;
  shelfLife: string;
  batchSize: string;
  moq: string;
  marketingMessage: string;
  status: number;
  productTags: string;
  comments: string;
  productOwnerId: number;
  createdAt: Date;
  updatedAt: Date;
  manualRanking: number;
  productRankingScore: number;
  companyId: number;
  formSpecification: string;
  doseFormCategory: string;
  strength: string;
  adminCreated: boolean;
  createdBy: number;
  adminComments: string;
  competitiveProduct: boolean;
  fastProduct: boolean;
  packSize: string;
  volume: string;
  productCategory: string;
}

export const productFragment: IFragment = {
  fragment: (
    isAdmin = false,
    isLogged = false,
    isElastic = false
  ) => `fragment productFragment on ${isElastic ? 'Product' : 'products_v2'} {
    formSpecification: form_specification
    strength: strength
    id: id
    substance1: ${isElastic ? 'substance1' : 'substance_1'}
    substance2: ${isElastic ? 'substance2' : 'substance_2'}
    substance3: ${isElastic ? 'substance3' : 'substance_3'}
    Substance1: Substance1 { id name SubstanceBrands { brand_name} }
    Substance2: Substance2 { id name SubstanceBrands { brand_name} }
    Substance3: Substance3 { id name SubstanceBrands { brand_name} }
    autoRanking: ${isElastic ? 'auto_ranking ' : 'product_ranking_score'}
    dossierStatus: dossier_status
    dossierCompletionQuarter: dossier_completion_quarter
    dossierCompletionYear: dossier_completion_year
    productAvailableLicensingSupply: product_available_licensing_supply
    productAvailableSupplyDistribution: product_available_supply_distribution
    productAvailableTechnologyTransfer: product_available_technology_transfer
    productAvailableSpecialImport: product_available_special_import
    dosierType: dossier_format
    gmpApprovalForThisProduct: gmp_approval_for_this_product_string
    finishedDoseCountry: finished_dose_country
    finishedDoseCountryEu: finished_dose_country_eu
    notAvailableMarkets: not_available_markets
    registeredIn: registered_in
    marketingAuthorizationsAchievedCountry: marketing_authorizations_achieved_country
    stabilityDataZones: stability_data_zones_string
    cpp: cpp
    clinicalData: clinical_data
    commentsOnThePatentStatus: comments_on_the_patent_status
    shelfLife: shelf_life
    batchSize: batch_size
    moq: moq
    marketingMessage: marketing_message
    status: status
    productTags: product_tags
    ${isLogged ? `comments: comments` : ''}
    productOwnerId: product_owner_id
    createdAt: created_at
    updatedAt: updated_at
    productRankingScore: product_ranking_score
    manualRanking: ${
      isElastic ? 'manual_ranking' : 'product_ranking_manual_score'
    }
    companyId: company_id
    adminCreated: admin_created
    createdBy: ${isElastic ? 'created_by' : 'created_by_id'}
    ${isAdmin ? `adminComments: admin_comments` : ''}
    fastProduct: fast_product
    competitiveProduct: competitive_product
    doseFormCategory: dose_form_category
    packSize: pack_size,
    volume: volume,
    productCategory: product_category
  }`,
  name: 'productFragment',
};

export interface IProduct extends IProductFragment {
  Company: ICompanyFragment;
}
const typename: Typed<Product['__typename']> = { __typename: 'Product' };
export function convertToProduct(p: IProduct): Product {
  if (!p) {
    return {
      ...typename,
    } as Product;
  }

  return {
    ...typename,
    formSpecification: p.formSpecification,
    doseFormCategory: p.doseFormCategory,
    strength: p.strength,
    id: p.id,
    Substance1: p.Substance1,
    Substance2: p.Substance2,
    Substance3: p.Substance3,
    substance1: p.substance1,
    substance2: p.substance2,
    substance3: p.substance3,
    autoRanking: p.autoRanking,
    manualRanking: p.manualRanking,
    productRankingScore: p.productRankingScore,
    dosierStatus: p.dossierStatus,
    dosierCompletionQuarter: p.dossierCompletionQuarter,
    dosierCompletionYear: p.dossierCompletionYear,
    productAvailableLicensingSupply: p.productAvailableLicensingSupply,
    productAvailableSuplyDistribution: p.productAvailableSupplyDistribution,
    productAvailableTechnologyTransfer: p.productAvailableTechnologyTransfer,
    productAvailableSpecialImport: p.productAvailableSpecialImport,
    dosierType: p.dosierType,
    gmpApprovalForThisProduct: p.gmpApprovalForThisProduct
      ? p.gmpApprovalForThisProduct.split(',')
      : [],
    finishedDoseCountry: p.finishedDoseCountry ? p.finishedDoseCountry : '',
    finishedDoseCountryEu: p.finishedDoseCountryEu,
    notAvailableMarkets: p.notAvailableMarkets
      ? p.notAvailableMarkets.split(',')
      : [],
    registeredIn: p.registeredIn ? p.registeredIn.split(',') : [],
    marketingAuthorizationsAchievedCountry: (
      p.marketingAuthorizationsAchievedCountry || ''
    ).split(','),
    stabilityDataZones:
      p.stabilityDataZones && p.stabilityDataZones !== ''
        ? p.stabilityDataZones.split(',')
        : [],
    cppAvailableFrom: p.cpp ? p.cpp.split(',') : [],
    clinicalData: p.clinicalData,
    commentsOnThePatentStatus: p.commentsOnThePatentStatus,
    shelfLife: p.shelfLife,
    batchSize: p.batchSize,
    moq: p.moq,
    marketingMessage: p.marketingMessage,
    status: p.status,
    // productTags: p.productTags ? p.productTags.split(',') : [],
    productTags: p.productTags,
    comments: p.comments,
    productOwnerId: p.productOwnerId,
    Company: p.Company,
    createdAt: new Date(p.createdAt),
    updatedAt: new Date(p.updatedAt),
    adminCreated: p.adminCreated,
    createdBy: p.createdBy,
    companyId: p.companyId,
    adminComments: p.adminComments ? p.adminComments : '',
    competitiveProduct: p.competitiveProduct ? p.competitiveProduct : false,
    fastProduct: p.fastProduct ? p.fastProduct : false,
    packSize: p.packSize ? p.packSize : '',
    volume: p.volume ? p.volume : '',
    productCategory: p.productCategory ? p.productCategory : '',
  } as Product;
}
