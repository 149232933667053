import React, { useEffect, useReducer, useRef, useState } from 'react';

// custom imports
import Checkbox from 'app/components/inputs/check-box';
import {
  allCountries,
  getCountriesByCodes,
  getCountryCodes,
  regionList,
} from './region-list';

import {
  AssignMarketDropDownContainer,
  AssignMarketDropDownSelect,
  CountryItem,
  DropDownOptionContainer,
  ItemContainer,
  Label,
  NoOption,
  RegionItemContainer,
  RegionItemContent,
  RegionName,
  RegionSelection,
  SelectedCountriesContainer,
  SelectedCountryContainer,
  SelectedCountryName,
  SelectedCountryRemoveIcon,
  TextInputBox,
} from '../styles';

import colors from 'app/styles/colors';

interface AssignMarketPayload {
  isCountry: boolean;
  payload: {
    [key: string]: {
      [key: string]: string | boolean;
    };
  };
}

interface AssignMarket {
  [key: string]: {
    [key: string]: string | boolean;
  };
}
interface Option {
  [key: string]: string | boolean;
}
interface IAssignMarketDropDownOptions {
  assignMarkets: string[];
  onAssignMarketChange: (countries: string[]) => any;
}
interface IRegionItem {
  regionName: string;
  checked: boolean;
  onChange: (isSelected: boolean) => any;
}
interface ISelectedCountries {
  selectedCountries: string[] | undefined;
  onRemove: (removeCountry: AssignMarketPayload) => any;
  onTyping: (value: string, callback?: () => void) => any;
  setTypingValue: (value: string) => any;
  typingValue: string;
}
interface ISelectedCountry {
  onRemove: (removeCountry: AssignMarketPayload) => any;
  name: string;
}

interface ITypeToSelect {
  onTyping: (value: string, callback?: () => void) => any;
  typingValue: string;
  setTypingValue: (value: string) => any;
}

const TEXT_INPUT_ID = 'assign-markets-text-input';

// Hooks for KeyPress
const useKeyPress = (targetKey: string) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  const downHandler = (e: any) => {
    if (e.key === targetKey) {
      setKeyPressed(true);
    }
  };

  const upHandler = (e: any) => {
    if (e.key === targetKey) {
      setKeyPressed(false);
    }
  };

  // Add event listeners
  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  }, []);

  return keyPressed;
};

// Functional components
const SelectedCountry: React.FC<ISelectedCountry> = ({ name, onRemove }) => {
  return (
    <SelectedCountryContainer>
      <SelectedCountryName>{name}</SelectedCountryName>
      <SelectedCountryRemoveIcon
        onClick={() => {
          if (onRemove) {
            onRemove({
              isCountry: true,
              payload: {
                [name]: {
                  isCountry: true,
                  name,
                  checked: false,
                },
              },
            });
          }
        }}
      >
        <svg
          height="14"
          width="14"
          viewBox="0 0 20 20"
          aria-hidden="true"
          focusable="false"
        >
          <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
        </svg>
      </SelectedCountryRemoveIcon>
    </SelectedCountryContainer>
  );
};

const RegionItem: React.FC<IRegionItem> = ({
  regionName,
  checked,
  onChange,
}) => {
  const text = 'all countries';
  return (
    <RegionItemContainer
      onClick={() => {
        if (onChange) {
          onChange(checked);
        }
      }}
    >
      <RegionItemContent>
        <RegionName>{regionName}</RegionName>
        <RegionSelection>
          {!checked ? `+ Add ${text}` : `- Remove ${text}`}
        </RegionSelection>
      </RegionItemContent>
    </RegionItemContainer>
  );
};

const TypeToSelect: React.FC<ITypeToSelect> = ({
  onTyping,
  typingValue,
  setTypingValue,
}) => {
  const onPressEnter = (e: any) => {
    if (e.which === parseInt('13', 10)) {
      onTyping(e.target.value, () => {
        setTypingValue('');
      });
    }
  };

  return (
    <TextInputBox
      id={TEXT_INPUT_ID}
      onKeyDown={onPressEnter}
      onChange={(e) => {
        setTypingValue(e.target.value);
        onTyping(e.target.value);
      }}
      value={typingValue}
    />
  );
};

const SelectedCountries: React.FC<ISelectedCountries> = ({
  selectedCountries,
  onRemove,
  onTyping,
  setTypingValue,
  typingValue,
}) => {
  return (
    <SelectedCountriesContainer>
      {selectedCountries &&
        selectedCountries.map((countryName: string, key: number) => (
          <SelectedCountry key={key} name={countryName} onRemove={onRemove} />
        ))}
      <TypeToSelect
        typingValue={typingValue}
        setTypingValue={setTypingValue}
        onTyping={onTyping}
      />
    </SelectedCountriesContainer>
  );
};

const AssignMarketDropDownOptions: React.FC<IAssignMarketDropDownOptions> = ({
  assignMarkets,
  onAssignMarketChange,
}) => {
  const globalMarketName = 'Global';
  const assignMarketText = ' assigned markets';
  const regionKeyPostFix = '-region';
  const wrapperRef = useRef(null);
  const [updatedAssignMarket, setUpdatedAssignMarket] = useState<boolean>(
    false
  );
  const isUpPressed = useKeyPress('ArrowUp');
  const isDownPressed = useKeyPress('ArrowDown');
  const [activeItem, setActiveItem] = useState<number>(0);
  const [clickedDropDown, setClickedDropDown] = useState<boolean>(false);
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const [typingValue, setTypingValue] = useState<string>('');
  const [suggestedItems, setSuggestedItems] = useState<AssignMarket>({});

  const scrollIntoView = (name: string, position: number) => {
    // eslint-disable-next-line
    document?.getElementById(`${name}-${position}`)?.scrollIntoView();
  };
  // returns countries with state object
  const getCountriesState = (countries: string[], checked: boolean) => {
    return countries.reduce((acc, countryName) => {
      return {
        ...acc,
        [countryName]: {
          isCountry: true,
          checked,
          name: countryName,
        },
      };
    }, {});
  };
  // returns regions with state object
  const getRegionInitState = () => {
    return Object.keys(regionList).reduce(
      (accumulator: AssignMarket, regionName: string) => {
        return {
          ...accumulator,
          [regionName]: {
            isRegion: true,
            checked: false,
            name: regionName,
          },
          [`${regionName}${regionKeyPostFix}`]: {
            isRegion: true,
            checked: true,
            name: regionName,
          },
        };
      },
      {}
    );
  };
  // filtering the suggested list on typing
  const filterAssignMarkets = (
    value: string,
    assignedMarkets: AssignMarket
  ) => {
    return Object.values(assignedMarkets).reduce(
      (acc: AssignMarket, market: any) => {
        if (market.name.toLowerCase().includes(value.toLowerCase())) {
          return {
            ...acc,
            [`${
              market.isRegion
                ? market.checked
                  ? `${market.name}${regionKeyPostFix}`
                  : market.name
                : market.name
            }`]: {
              ...market,
            },
          };
        }
        return acc;
      },
      {}
    );
  };
  // used for dropdown options
  const [selectedDropDownItems, setSelectedDropDownItems] = useReducer(
    (state: any, action: any) => {
      let newState: AssignMarket = {};
      if (action.isCountry) {
        newState = {
          ...state,
          ...action.payload,
        };
      } else if (action.isRegion) {
        let countries = {};
        if (action.name === globalMarketName && !action.checked) {
          countries = getCountriesState(allCountries, true);
        } else if (action.name === globalMarketName && action.checked) {
          countries = getCountriesState(allCountries, false);
        } else {
          countries = getCountriesState(
            regionList[action.name],
            !action.checked
          );
        }
        newState = {
          ...state,
          ...countries,
        };
      }
      if (!action.isBulk) {
        onAssignMarketChange(
          Object.values(newState)
            .filter(
              (item: Option) =>
                item.isCountry &&
                item.checked &&
                item.name &&
                `${item.name}`.trim()
            )
            .map((country: Option) => country.name) as string[]
        );
      }
      return newState;
    },
    {
      ...getRegionInitState(),
      ...getCountriesState(allCountries, false),
    }
  );
  useEffect(() => {
    if (!isTyping || !isMouseIdle) {
      return;
    }
    const suggestedKeys = Object.keys(suggestedItems);
    if (!suggestedKeys.length) {
      return;
    }
    /* eslint-disable no-magic-numbers */
    let position = 0;
    if (isUpPressed) {
      position =
        suggestedKeys.length - 1 > 0 && activeItem ? activeItem - 1 : 0;
      setActiveItem(position);
      scrollIntoView(
        suggestedItems[suggestedKeys[position]].name as string,
        position
      );
      document
        ?.getElementById('seller-information-modal-content')
        ?.scrollIntoView();
    } else if (isDownPressed) {
      position =
        suggestedKeys.length - 1 > activeItem ? activeItem + 1 : activeItem;

      setActiveItem(position);
      /* eslint-disable no-magic-numbers */
      if (position % 6 === 0) {
        scrollIntoView(
          suggestedItems[suggestedKeys[position]].name as string,
          position
        );
      }
    }
  }, [isUpPressed, isDownPressed]);

  useEffect(() => {
    const noScroll = () => {
      window.scrollTo(0, 0);
    };
    if (isTyping) {
      window.addEventListener('scroll', noScroll);
    } else {
      window.removeEventListener('scroll', noScroll);
    }
  }, [isTyping]);

  useEffect(() => {
    if (assignMarkets.length > 0 && !updatedAssignMarket) {
      setActiveItem(0);
      setUpdatedAssignMarket(true);
      setSelectedDropDownItems({
        isBulk: true,
        isCountry: true,
        payload: getCountriesState(assignMarkets, true),
      });
    }
  }, [assignMarkets]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      // eslint-disable-next-line
      if (wrapperRef.current && !wrapperRef?.current?.contains(event.target)) {
        setClickedDropDown(false);
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    if (clickedDropDown) {
      document?.getElementById(TEXT_INPUT_ID)?.focus();
    } else {
      document?.getElementById(TEXT_INPUT_ID)?.blur();
    }
  }, [clickedDropDown]);

  useEffect(() => {
    if (clickedDropDown) {
      document?.getElementById(TEXT_INPUT_ID)?.focus();
    }
    setActiveItem(0);
    setTypingValue('');
    setIsTyping(false);
  }, [selectedDropDownItems]);
  const [isMouseIdle, setIsMouseIdle] = useState(true);
  const [timeoutInstance, setTimeoutInstance] = useState<any>();
  const handleMouseAction = (position: number, hovered: boolean) => {
    if (position === -1 && isMouseIdle) {
      clearTimeout(timeoutInstance);
      setIsMouseIdle(false);
      setTimeoutInstance(
        setTimeout(() => {
          setIsMouseIdle(true);
        }, 50)
      );
      return;
    }
    if (
      position !== -1 &&
      activeItem !== position &&
      !isMouseIdle &&
      !isUpPressed &&
      !isDownPressed
    ) {
      setActiveItem(position);
    }
  };

  const DropDownOptions = (items: AssignMarket) => {
    if (Object.keys(items).length > 0) {
      return (
        <div onMouseMove={() => handleMouseAction(-1, true)}>
          {Object.values(items).map((item: any, key: number) => (
            <ItemContainer
              id={`${item.name}-${key}`}
              key={`${item.name}-${key}`}
              style={{
                background:
                  isTyping && activeItem === key ? colors.primaryLight : '',
              }}
              hoverBGColor={!isTyping ? colors.primaryLight : colors.white}
              onMouseMove={() => handleMouseAction(key, true)}
              onMouseLeave={() => handleMouseAction(key, false)}
            >
              {item.isRegion ? (
                <RegionItem
                  regionName={item.name}
                  checked={item.checked}
                  onChange={(checked) =>
                    setSelectedDropDownItems({
                      ...item,
                      ...{
                        checked,
                      },
                    })
                  }
                />
              ) : (
                <CountryItem>
                  <Checkbox
                    label={item.name}
                    marginTop="0"
                    value={item.checked}
                    onChange={(checked) =>
                      setSelectedDropDownItems({
                        isCountry: true,
                        payload: {
                          [item.name]: {
                            ...item,
                            checked,
                          },
                        },
                      })
                    }
                  />
                </CountryItem>
              )}
            </ItemContainer>
          ))}
        </div>
      );
    }
    return <NoOption>No option</NoOption>;
  };
  const selectedCountries = Object.values(selectedDropDownItems)
    .filter(
      (item: Option) =>
        item.isCountry && item.checked && item.name && `${item.name}`.trim()
    )
    .map((item) => item.name);

  return (
    <>
      <Label
        onClick={() => {
          setClickedDropDown(true);
        }}
      >
        {!selectedCountries.length ? (
          `Choose${assignMarketText}`
        ) : (
          <>
            <b>{selectedCountries.length}</b>
            {assignMarketText}
          </>
        )}
      </Label>
      <div ref={wrapperRef}>
        <AssignMarketDropDownSelect
          style={{
            border: 'none',
            borderBottom: '2px solid lightgray',
            borderColor: clickedDropDown ? '#fca216' : '',
            borderWidth: clickedDropDown ? '2px' : '1px',
          }}
          onClick={() => {
            setClickedDropDown(true);
          }}
        >
          <SelectedCountries
            typingValue={typingValue}
            setTypingValue={setTypingValue}
            selectedCountries={selectedCountries as string[]}
            onTyping={(value: string, callback?: () => void) => {
              if (!isTyping) {
                setIsTyping(true);
                setClickedDropDown(true);
              }
              if (callback) {
                callback();
                const selectionKey = Object.keys(suggestedItems);
                if (selectionKey.length) {
                  const selected: any =
                    selectedDropDownItems[selectionKey[activeItem]];
                  let selectionState = {};
                  if (selected.isRegion) {
                    selectionState = selected;
                  } else {
                    selectionState = {
                      isCountry: true,
                      payload: {
                        [selected.name]: {
                          ...selected,
                          checked: !selected.checked,
                        },
                      },
                    };
                  }
                  setSelectedDropDownItems(selectionState);
                }
              }
              if (callback || !value) {
                setSuggestedItems({});
                setIsTyping(false);
              }
              if (value) {
                setActiveItem(0);
                setSuggestedItems(
                  filterAssignMarkets(value, selectedDropDownItems)
                );
              }
            }}
            onRemove={(removeCountry: AssignMarketPayload) => {
              setSelectedDropDownItems(removeCountry);
            }}
          />
        </AssignMarketDropDownSelect>
        <DropDownOptionContainer
          display={clickedDropDown ? 'flex' : 'none'}
          style={{ display: clickedDropDown ? 'flex' : 'none' }}
        >
          {!isTyping && DropDownOptions(selectedDropDownItems)}
          {isTyping && DropDownOptions(suggestedItems)}
        </DropDownOptionContainer>
      </div>
    </>
  );
};

interface IAssignMarketDropDown {
  assignMarkets: string[];
  setSelectedCountries: (selectedCountries: string[]) => any;
}

const AssignMarketDropDown: React.FC<IAssignMarketDropDown> = ({
  setSelectedCountries,
  assignMarkets,
}) => {
  const selectedCountries = (countries: string[]) => {
    setSelectedCountries(getCountryCodes(countries));
  };

  return (
    <AssignMarketDropDownContainer>
      <AssignMarketDropDownOptions
        assignMarkets={getCountriesByCodes(assignMarkets)}
        onAssignMarketChange={(countries: string[]) =>
          selectedCountries(countries)
        }
      />
    </AssignMarketDropDownContainer>
  );
};

export default AssignMarketDropDown;
