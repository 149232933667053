import { Fetch } from 'app/modules/requests/my-requests/my-requests-list';
import { IWithApolloClient } from '../../global/types';
import { OnBoardingFilter } from '../../products/types';
import { DealsFilter } from '../types';
import { getRequestsList } from './get-requests-list';

export interface IRequestList extends IWithApolloClient {
  limit: number;
  offset: number;
  companyId: number;
  isAdmin: boolean;
  fetch: Fetch;
  filter?: DealsFilter;
  originFilter?: OnBoardingFilter;
}
export interface IRequestListTyped extends IRequestList {
  isArchived: boolean;
}
export function getRequestsListQuery(p: IRequestList) {
  return getRequestsList({ ...p, isArchived: false });
}
