import { allCountries } from 'app/store/modules/global/types';
import { sg } from 'app/utils/safe-get';
import { IRequest } from '../requests/types';

export const toTitleCase = (str: string) => {
  return str.toLowerCase().replace(/\w\S*/, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getCountries = (ids: string[]) => {
  if (!ids) {
    return '';
  }
  return ids
    .map((x) =>
      sg(
        () =>
          allCountries.filter((y) => y && y.id.toString() === x.toString())[0]
            .name,
        ''
      )
    )
    .join(', ');
};

export const Capitalize = (s: string) => {
  return sg(() => s.toString(), '').replace(/(?:^|\s)\S/g, (a) => {
    return a ? a.toUpperCase() : '';
  });
};

export const checkIfDealBuyerQualificationInformationExist = (
  request?: IRequest
) => {
  const buyerCompany = request && request.BuyerCompany;

  const stringExist = (s?: string) => s && s !== '';

  if (buyerCompany) {
    let exist = false;
    if (stringExist(buyerCompany.annualRevenue)) {
      exist = true;
    }
    if (stringExist(buyerCompany.numberOfEmployees)) {
      exist = true;
    }
    if (stringExist(buyerCompany.summary)) {
      exist = true;
    }
    if (buyerCompany.salesChannels && buyerCompany.salesChannels.length > 0) {
      exist = true;
    }
    if (buyerCompany.therapyAreas && buyerCompany.therapyAreas.length > 0) {
      exist = true;
    }
    if (
      buyerCompany.portfolioProductsList &&
      buyerCompany.portfolioProductsList.length > 0
    ) {
      exist = true;
    }
    return exist;
  }

  return false;
};

export const tokyCallPopup = (phoneNumber?: string) => {
  const title = 'Toky';
  const w = 300;
  const h = 500;
  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  const _options = 'scrollbars=no,resizable=no';
  const url = 'https://app.toky.co/business/dialer#?call=' + phoneNumber;
  // Fixes dual-screen position Most browsers Firefox
  if (
    typeof window !== 'undefined' &&
    typeof window !== undefined &&
    window !== undefined
  ) {
    const dualScreenLeft =
      window.screenLeft !== undefined
        ? window.screenLeft
        : (screen as any).left;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : (screen as any).top;
    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const two = 2;
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    const _left = width / two - w / two + dualScreenLeft;
    // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
    const _top = height / two - h / two + dualScreenTop;
    const newWindow = window.open(
      url,
      title,
      _options +
        ', width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        _top +
        ', left=' +
        _left
    );

    // Puts focus on the newWindow
    if (newWindow) {
      newWindow.focus();
    }
  }

  return false;
};
