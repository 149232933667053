import CreateAccount from 'app/modules/auth/get-started/create-account';
import Modal from 'app/components/modals/overrided-modal';
import { IStore } from 'app/store/types';
import { _, media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

const ModalContainer = styled.div`
  margin: 15px;
  z-index: 100;
  padding-top: 0;
  align-items: flex-start;
  ${_(media.max.lg)} {
    padding-top: 0;
    margin: 5px;
  }
  ${_(media.max.md)} {
    margin: 0;
  }
  ${_(media.max.sm)} {
    margin: 0;
  }
`;

const customStyles = {
  content: {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    borderWidth: 0,
    borderRadius: 6,
    boxShadow: colors.modalShadow,
    overflow: 'auto',
    position: 'static',
    margin: 'auto',
    maxHeight: '90%',
  },
  overlay: {
    backgroundColor: colors.darkModalOverlay,
    zIndex: 999,
    display: 'flex',
  },
};

const CreateAccountModal: React.FC = () => {
  const isOpen: boolean = useSelector(
    (state: IStore) =>
      state.auth.createAccountModalOpen && !state.auth.resetPasswordModal
  );

  return (
    <Modal
      isOpen={isOpen}
      style={customStyles as any}
      contentLabel="Create Account"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
    >
      <ModalContainer>
        <CreateAccount isModal={true} />
      </ModalContainer>
    </Modal>
  );
};

export default CreateAccountModal;
