import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import * as u from '../../query-utils';
import { MarketplaceExistingDeal } from '../types';

export enum NavCountOptions {
  Products = 1,
  Requests,
  Deals_Buying,
  Deals_Selling,
  Onboarding,
  Archive,
  Marketplace,
}
export interface IGetCounts extends IWithApolloClient {
  companyId: number;
  isAdmin: boolean;
  counts: NavCountOptions[];
}

export interface IGetCountsReturn {
  productCount?: { a: { count: number } };
  dealCount?: { a: { count: number } };
  dealNotSeenCount?: { a: { count: number } };
  dealsBuyingCount?: { a: { count: number } };
  dealsBuyingNotSeenCount?: { a: { count: number } };
  dealsSellingCount?: { a: { count: number } };
  dealsSellingNotSeenCount?: { a: { count: number } };
  onboardingCount?: { a: { count: number } };
  archiveCount?: { a: { count: number } };
  marketplaceExistingDealIds: MarketplaceExistingDeal[];
}

export async function getCounts(p: IGetCounts) {
  try {
    const companyCheck = !p.isAdmin ? u._eq('id', '$companyId') : '';
    const productWhereQuery = (type: string, isOnboarding?: boolean) =>
      u._and(
        !p.isAdmin &&
          u._or(
            u._eq('admin_created', false),
            u._is_null('admin_created', true)
          ),
        u._is_null('deleted_at'),
        u._and(
          u.nest('Company', u._and(u._eq('hidden', 'false'), companyCheck))
        ),

        u._is_null('form_specification', false),
        u._is_null('strength', false),
        isOnboarding && u._eq('awaiting_approval', true)
      );

    const productWhere = u.where(productWhereQuery('Product'));

    const whereOnboarding = u.AdminOnboardUtils.where(
      u.AdminOnboardUtils._and(
        u.AdminOnboardUtils.nest('Product', productWhereQuery('Product', true))
      )
    );

    const parts = {
      [NavCountOptions.Products]: `
        productCount: products_v2_aggregate(${productWhere}) { a: aggregate { count } }`,
      [NavCountOptions.Requests]: `
        dealCount: requests_aggregate(${u.where(
          u._and(
            u.RequestUtils._is_null('deleted_at'),
            u.RequestUtils._is_null('admin_archived_at')
          )
        )}) {
          a: aggregate { count }
        }
        dealNotSeenCount: requests_aggregate(${u.where(
          u._and(
            u.RequestUtils._is_null('deleted_at'),
            u.RequestUtils._is_null('admin_archived_at'),
            u.RequestUtils._neq('admin_seen', true)
          )
        )}) {
          a: aggregate { count }
        }
        `,
      [NavCountOptions.Deals_Buying]: `
        dealsBuyingCount: requests_aggregate(${u.where(
          u._and(
            u.RequestUtils._is_null('deleted_at'),
            u.RequestUtils._is_null('buyer_archived_at'),
            u.RequestUtils._eq('buyer_company_id', '$companyId')
          )
        )}) {
          a: aggregate { count }
        }
        dealsBuyingNotSeenCount: requests_aggregate(${u.where(
          u._and(
            u.RequestUtils._is_null('deleted_at'),
            u.RequestUtils._is_null('buyer_archived_at'),
            u.RequestUtils._eq('buyer_company_id', '$companyId'),
            u.RequestUtils._neq('buyer_seen', true)
          )
        )}) {
          a: aggregate { count }
        }
        `,
      [NavCountOptions.Deals_Selling]: `
        dealsSellingCount: requests_aggregate(${u.where(
          u._and(
            u.RequestUtils._is_null('deleted_at'),
            u.RequestUtils._is_null('seller_archived_at'),
            u.RequestUtils._eq('seller_company_id', '$companyId'),
            u.RequestUtils._eq('admin_approved', true)
          )
        )}) {
          a: aggregate { count }
        }
        dealsSellingNotSeenCount: requests_aggregate(${u.where(
          u._and(
            u.RequestUtils._is_null('deleted_at'),
            u.RequestUtils._is_null('seller_archived_at'),
            u.RequestUtils._eq('seller_company_id', '$companyId'),
            u.RequestUtils._eq('admin_approved', true),
            u.RequestUtils._neq('seller_seen', true)
          )
        )}) {
          a: aggregate { count }
        }
        `,
      [NavCountOptions.Onboarding]: `
        onboardingCount: admin_onboarding_aggregate(${whereOnboarding}) {
          a: aggregate { count }
        }`,
      [NavCountOptions.Marketplace]: `
      marketplaceExistingDealIds: requests(where:
          {
            _and: {
              buyer_company_id: { _eq: $companyId }
              _or: [
                { product_id: { _is_null: false } }
              ]
            }
          } ) {
          id,
          status,
          archivedAt: archived_at
         
          Product {
            mainProductId: id
          }
        
        }`,
      [NavCountOptions.Archive]: `
          archivedCount: requests_aggregate(${u.where(
            u._and(
              u.RequestUtils._is_null('deleted_at'),
              u._or(
                p.isAdmin &&
                  u._and(u.RequestUtils._is_null('admin_archived_at', false)),
                !p.isAdmin &&
                  u._and(
                    u.RequestUtils._is_null('buyer_archived_at', false),
                    u.DealMessagesUtils._eq('buyer_company_id', '$companyId')
                  ),
                !p.isAdmin &&
                  u._and(
                    u.RequestUtils._is_null('seller_archived_at', false),
                    u.DealMessagesUtils._eq('seller_company_id', '$companyId')
                  )
              )
            )
          )}) {
            a: aggregate { count }
          }`,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetCountsReturn>({
      query: gql`
      query navCount(${u.params(
        !p.isAdmin && ['$companyId', 'Int'],
        ['$company', 'String'],
        ['$product', 'String'],
        ['$hidden', 'Boolean'],
        ['$form', 'String']
      )}) {
        ${p.counts.map((v) => parts[v]).join('\n')}
      }
    `,
      fetchPolicy: 'cache-first',
      variables: {
        companyId: p.companyId && !p.isAdmin ? p.companyId : undefined,
      },
    });
  } catch (e) {
    logger.error('src/app/store/modules/auth/query.ts -> getCounts', e);
  }
}
