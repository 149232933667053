import gql from 'graphql-tag';

import { IWithApolloClient } from 'app/store/modules/global/types';
import { logger } from 'app/utils/logger';
import { sg } from 'app/utils/safe-get';
import { SupplyUnitPricesUtils } from '../../query-utils';
import { ICreateSupplyUnitPricesData, ISupplyUnitPrice } from '../types';

export interface ICreateSupplyUnitPrices extends IWithApolloClient {
  data: ICreateSupplyUnitPricesData;
}

export interface ICreateSupplyUnitPriceReturn {
  insert_supply_unit_prices: {
    returning: ISupplyUnitPrice[];
  };
}

export async function createSupplyUnitPricesQuery({
  client,
  data,
}: ICreateSupplyUnitPrices) {
  try {
    return await client.mutate<ICreateSupplyUnitPriceReturn>({
      mutation: gql`
        mutation insert_supply_unit_prices(${SupplyUnitPricesUtils.params(
          ['$termsheet_id', 'Int'],
          ['$strength', 'String'],
          ['$number_of_units', 'float8'],
          ['$unit_name', 'String'],
          ['$net_supply_price', 'float8'],
          ['$gross_supply_price', 'float8'],
          ['$buyer_company_id', 'Int'],
          ['$seller_company_id', 'Int'],
          ['$buyer_user_id', 'Int'],
          ['$seller_user_id', 'Int'],
          ['$supply_price_commision_pct', 'float8']
        )}) {
          insert_supply_unit_prices(${SupplyUnitPricesUtils._objects([
            ['termsheet_id', '$termsheet_id'],
            ['strength', '$strength'],
            ['number_of_units', '$number_of_units'],
            ['unit_name', '$unit_name'],
            ['net_supply_price', '$net_supply_price'],
            ['gross_supply_price', '$gross_supply_price'],
            ['buyer_company_id', '$buyer_company_id'],
            ['seller_company_id', '$seller_company_id'],
            ['buyer_user_id', '$buyer_user_id'],
            ['seller_user_id', '$seller_user_id'],
            ['supply_price_commision_pct', '$supply_price_commision_pct'],
          ])}) {
            returning {
                id
                created_at
                termsheet_id
                strength
                number_of_units
                unit_name
                net_supply_price
                gross_supply_price
                buyer_company_id
                seller_company_id
                buyer_user_id
                seller_user_id
                supply_price_commision_pct
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        termsheet_id: sg(() => data.termsheet_id, null),
        strength: sg(() => data.strength, null),
        number_of_units: sg(() => data.number_of_units, null),
        unit_name: sg(() => data.unit_name, null),
        net_supply_price: sg(() => data.net_supply_price, null),
        gross_supply_price: sg(() => data.gross_supply_price, null),
        buyer_company_id: sg(() => data.buyer_company_id, null),
        seller_company_id: sg(() => data.seller_company_id, null),
        buyer_user_id: sg(() => data.buyer_user_id, null),
        seller_user_id: sg(() => data.seller_user_id, null),
        supply_price_commision_pct: sg(
          () => data.supply_price_commision_pct,
          null
        ),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> createSupplyUnitPricesQuery',
      e
    );
  }
}
