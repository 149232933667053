import * as saga from '@redux-saga/core/effects';
import { logger } from '../../../utils/logger';
import sg from '../../../utils/safe-get';
import { IStore } from '../../types';
import { isBrowser } from '../../utils/is-browser';
import * as A from './actions';
import * as Query from './query';
import {
  ICompanyNameAndCountry,
  ICompanySummary,
  IRemarks,
  ISellerContactInformation,
  ISellerInformation,
} from './types';

function* fetchSellerInformationFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.fetchSellerInformation> = yield saga.take(
      A.fetchSellerInformation
    );

    const { auth }: IStore = yield saga.select();
    const params: Query.IGetCompanyDetails = {
      client: payload.client,
      id: payload.id,
      isAdmin: auth.loggedInUser.isAdmin,
    };
    yield saga.call(fetchSellerInformation, params);
  }
}
function* fetchSellerInformation(params: Query.IGetCompanyDetails) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.getCompanyDetailsById>
    > = yield saga.call(Query.getCompanyDetailsById, params);

    const resCompany: ISellerInformation = sg(
      () => res!.data.company[0],
      undefined
    );
    if (resCompany) {
      yield saga.put(A.setSellerCompanyId(resCompany.id));
      yield saga.put(A.setSellerInformation(resCompany));
      yield saga.put(A.setUpdateCache([]));
    }
  } catch (e) {
    const { sellerInformation }: IStore = yield saga.select();
    yield saga.put(
      A.setNotification({
        ...sellerInformation.error,
        listError: 'There was an error fetching seller information.',
      })
    );
  }
}
function* fetchSellerContactListFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.fetchSellerContactList> = yield saga.take(
      A.fetchSellerContactList
    );

    const { auth }: IStore = yield saga.select();
    const params: Query.IFetchAllSellerContacts = {
      client: payload.client,
      companyId: payload.id,
      isAdmin: auth.loggedInUser.isAdmin,
    };
    yield saga.call(fetchSellerContactList, params);
  }
}
function* fetchSellerContactList(params: Query.IFetchAllSellerContacts) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.getSellerContactInformationByCompanyId>
    > = yield saga.call(Query.getSellerContactInformationByCompanyId, params);
    const resCompany: ISellerContactInformation[] = sg(
      () => res!.data.contact_person,
      []
    );
    yield saga.put(A.setSellerContactInformation(resCompany));
    yield saga.put(A.setUpdateCache(resCompany));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> fetchSellerContactList',
      e
    );
  }
}
function* updateSellerPaysCommissionFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateSellerCommission> = yield saga.take(
      A.updateSellerCommission
    );

    const params: Query.ISellerCommission = {
      client: payload.client,
      id: payload.id,
      sellerPaysCommission: payload.sellerPaysCommission,
      supplyPrice: payload.supplyPrice,
      licenseFee: payload.licenseFee,
    };
    yield saga.call(updateSellerPaysCommissionsInfo, params);
  }
}
function* updateSellerPaysCommissionsInfo(params: A.ISellerCommission) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateSellerPaysCommissionInformation>
    > = yield saga.call(Query.updateSellerPaysCommissionInformation, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const commission = {
      id: res.data!.update_companies.returning[0].id,
      name: res.data!.update_companies.returning[0].name,
      sellerPaysCommission: res.data!.update_companies.returning[0]
        .sellerPaysCommission,
      licenseFee: res.data!.update_companies.returning[0].licenseFee,
      supplyPrice: res.data!.update_companies.returning[0].supplyPrice,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: commission.id,
      name: commission.name,
      isCommissionsPaid: commission.sellerPaysCommission,
      negotiatedSupplyFeeCommissionsPct: commission.supplyPrice,
      negotiatedLicenseFeeCommissionsPct: commission.licenseFee,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateSellerPaysCommissionsInfo',
      e
    );
  }
}
function* updateBuyerDisclosureRequiredStatusFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateClientNameRequired> = yield saga.take(
      A.updateClientNameRequired
    );

    const params: Query.IBuyerDisclosureRequired = {
      client: payload.client,
      id: payload.id,
      isEarlyBuyerDisclosureRequired: payload.isEarlyBuyerDisclosureRequired,
    };
    yield saga.call(updateBuyerDisclosureRequiredStatus, params);
  }
}
function* updateBuyerDisclosureRequiredStatus(
  params: A.IEarlyBuyerDisclosureRequired
) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateEarlyBuyerDisclosureRequiredStatus>
    > = yield saga.call(Query.updateEarlyBuyerDisclosureRequiredStatus, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      id: res.data!.update_companies.returning[0].id,
      name: res.data!.update_companies.returning[0].name,
      isEarlyBuyerDisclosureRequired: res.data!.update_companies.returning[0]
        .isEarlyBuyerDisclosureRequired,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.id,
      name: information.name,
      isEarlyBuyerDisclosureRequired:
        information.isEarlyBuyerDisclosureRequired,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateSellerPaysCommissionsInfo',
      e
    );
  }
}
function* updateCompanyHiddenStatusFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateCompanyHiddenStatus> = yield saga.take(
      A.updateCompanyHiddenStatus
    );

    const params: Query.IIsCompanyHiddenStatus = {
      client: payload.client,
      id: payload.id,
      isCompanyHidden: payload.isCompanyHidden,
    };
    yield saga.call(updateCompanyHiddenStatus, params);
  }
}
function* updateCompanyHiddenStatus(params: A.IIsCompanyHiddenStatus) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateCompanyHiddenStatusQuery>
    > = yield saga.call(Query.updateCompanyHiddenStatusQuery, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      id: res.data!.update_companies.returning[0].id,
      name: res.data!.update_companies.returning[0].name,
      isCompanyHidden: res.data!.update_companies.returning[0].hidden,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.id,
      name: information.name,
      isCompanyHidden: information.isCompanyHidden,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateCompanyHiddenStatus',
      e
    );
  }
}
function* updateCDASignatureInformationFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateCDASignedInformation> = yield saga.take(
      A.updateCDASignedInformation
    );

    const params: Query.ICDASignedInformation = {
      client: payload.client,
      id: payload.id,
      isSellerCDASigned: payload.isSellerCDASigned,
      sellerCDASignatureDate: payload.sellerCDASignatureDate,
      sellerCDAExpiryDate: payload.sellerCDAExpiryDate,
    };
    yield saga.call(updateCDASignatureInformation, params);
  }
}
function* updateCDASignatureInformation(params: A.ICDASignedInformation) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateCDASignedInformation>
    > = yield saga.call(Query.updateCDASignedInformation, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      id: res.data!.update_companies.returning[0].id,
      name: res.data!.update_companies.returning[0].name,
      isSellerCDASigned: res.data!.update_companies.returning[0]
        .isSellerCDASigned,
      sellerCDASignatureDate: res.data!.update_companies.returning[0]
        .sellerCDASignatureDate,
      sellerCDAExpiryDate: res.data!.update_companies.returning[0]
        .sellerCDAExpiryDate,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.id,
      name: information.name,
      isSellerCDASigned: information.isSellerCDASigned,
      sellerCDASignatureDate: information.sellerCDASignatureDate,
      sellerCDAExpiryDate: information.sellerCDAExpiryDate,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateSellerPaysCommissionsInfo',
      e
    );
  }
}
function* updateCPASignatureInformationFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateCPASignedInformation> = yield saga.take(
      A.updateCPASignedInformation
    );

    const params: Query.ICPASignedInformation = {
      client: payload.client,
      id: payload.id,
      isCPASigned: payload.isCPASigned,
      CPASignatureDate: payload.CPASignatureDate,
    };
    yield saga.call(updateCPASignatureInformation, params);
  }
}
function* updateCPASignatureInformation(params: A.ICPASignedInformation) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateCPASignedInformation>
    > = yield saga.call(Query.updateCPASignedInformation, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      id: res.data!.update_companies.returning[0].id,
      name: res.data!.update_companies.returning[0].name,
      isCPASigned: res.data!.update_companies.returning[0].isCPASigned,
      CPASignatureDate: res.data!.update_companies.returning[0]
        .CPASignatureDate,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.id,
      name: information.name,
      isCPASigned: information.isCPASigned,
      CPASignatureDate: information.CPASignatureDate,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateCPASignatureInformation',
      e
    );
  }
}
function* createSellerContactFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.createSellerContact> = yield saga.take(
      A.createSellerContact
    );

    const params: Query.ICreateSellerContactInfo = {
      client: payload.client,
      companyId: payload.companyId,
      userId: payload.userId,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      assignedMarketsName: payload.assignedMarketsName,
      assignMarket: payload.assignMarket,
    };
    yield saga.call(createSellerContact, params);
  }
}
function* createSellerContact(params: A.ICreateSellerContact) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.createSellerContactInfoMutation>
    > = yield saga.call(Query.createSellerContactInfoMutation, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      id: res.data!.contact_person.returning[0].id,
    };
    yield saga.put(A.setUpdateCache(information));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> createSellerContact',
      e
    );
  }
}
function* updateSellerContactFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateSellerContact> = yield saga.take(
      A.updateSellerContact
    );

    const params: Query.IUpdateSellerContactInformation = {
      client: payload.client,
      id: payload.id,
      companyId: payload.companyId,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      phoneNumber: payload.phoneNumber,
      assignedMarketsName: payload.assignedMarketsName,
      assignMarket: payload.assignMarket,
    };
    yield saga.call(updateSellerContact, params);
  }
}
function* updateSellerContact(params: A.IUpdateSellerContact) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateSellerContactInformation>
    > = yield saga.call(Query.updateSellerContactInformation, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      id: res.data!.update_contact_person.returning[0].id,
      companyId: res.data!.update_contact_person.returning[0].companyId,
      firstName: res.data!.update_contact_person.returning[0].firstName,
      lastName: res.data!.update_contact_person.returning[0].lastName,
      email: res.data!.update_contact_person.returning[0].email,
      phoneNumber: res.data!.update_contact_person.returning[0].phoneNumber,
      assignedMarketsName: res.data!.update_contact_person.returning[0]
        .assignedMarketsName,
      assignMarket: res.data!.update_contact_person.returning[0].assignMarket,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.id,
      companyId: information.companyId,
      firstName: information.firstName,
      lastName: information.lastName,
      email: information.email,
      phoneNumber: information.phoneNumber,
      assignedMarketsName: information.assignedMarketsName,
      assignMarket: information.assignMarket,
    };
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateSellerContact',
      e
    );
  }
}
function* updateRemarksFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const { payload }: ReturnType<typeof A.updateRemarks> = yield saga.take(
      A.updateRemarks
    );

    const params: IRemarks = {
      client: payload.client,
      companyId: payload.companyId,
      remarks: payload.remarks,
    };
    yield saga.call(updateRemarks, params);
  }
}

function* updateRemarks(params: IRemarks) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateRemarks>
    > = yield saga.call(Query.updateRemarks, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      companyId: res.data!.update_companies.returning[0].companyId,
      name: res.data!.update_companies.returning[0].name,
      remarks: res.data!.update_companies.returning[0].remarks,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.companyId,
      name: information.name,
      remarks: information.remarks,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateRemarksFlow',
      e
    );
  }
}

function* updateSummaryFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateCompanySummary> = yield saga.take(
      A.updateCompanySummary
    );

    const params: ICompanySummary = {
      client: payload.client,
      companyId: payload.companyId,
      summary: payload.summary,
    };
    yield saga.call(updateSummary, params);
  }
}

function* updateSummary(params: ICompanySummary) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateCompanySummary>
    > = yield saga.call(Query.updateCompanySummary, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      companyId: res.data!.update_companies.returning[0].companyId,
      name: res.data!.update_companies.returning[0].name,
      summary: res.data!.update_companies.returning[0].summary,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.companyId,
      name: information.name,
      remarks: information.summary,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateSummaryFlow',
      e
    );
  }
}
function* updateSellerCompanyNameAndCountryFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.updateSellerCompanyNameAndCountry> = yield saga.take(
      A.updateSellerCompanyNameAndCountry
    );
    const params: ICompanyNameAndCountry = {
      client: payload.client,
      id: payload.id,
      name: payload.name,
      country: payload.country,
    };
    yield saga.call(updateSellerCompanyNameAndCountry, params);
  }
}
function* updateSellerCompanyNameAndCountry(params: ICompanyNameAndCountry) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateSellerCompanyNameAndCountry>
    > = yield saga.call(Query.updateSellerCompanyNameAndCountry, params);

    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const information = {
      id: res.data!.update_companies.returning[0].id,
      name: res.data!.update_companies.returning[0].name,
      country: res.data!.update_companies.returning[0].country,
    };
    const state: IStore = yield saga.select();
    const sellerDict = {
      ...state.sellerInformation.sellerInformation,
      id: information.id,
      name: information.name,
      country: information.country,
    };
    yield saga.put(A.setSellerInformation(sellerDict));
    yield saga.put(A.setUpdateCache(sellerDict));
  } catch (e) {
    logger.error(
      'src/app/store/modules/seller-information/sagas.ts -> updateSellerCompanyNameAndCountryFlow',
      e
    );
  }
}

export default function* rootSaga() {
  yield saga.all([
    saga.fork(fetchSellerInformationFlow),
    saga.fork(updateSellerPaysCommissionFlow),
    saga.fork(updateBuyerDisclosureRequiredStatusFlow),
    saga.fork(updateCDASignatureInformationFlow),
    saga.fork(updateCPASignatureInformationFlow),
    saga.fork(createSellerContactFlow),
    saga.fork(updateSellerContactFlow),
    saga.fork(fetchSellerContactListFlow),
    saga.fork(updateRemarksFlow),
    saga.fork(updateCompanyHiddenStatusFlow),
    saga.fork(updateSummaryFlow),
    saga.fork(updateSellerCompanyNameAndCountryFlow),
  ]);
}
