import * as u from 'app/store/modules/query-utils';
import { logger } from 'app/utils/logger';
import { sg } from 'app/utils/safe-get';
import gql from 'graphql-tag';
import { IGetMarketplaceItemsListReturn } from '.';
import { newProductsDate } from '.';
import { companyFragment } from '../../global/fragments/company';
import { IWithApolloClient, TypeOrigin } from '../../global/types';
import { IProduct, productFragment } from '../../products/fragments/product';

export interface IGetTopItemsList extends IWithApolloClient {
  substanceName: string;
  substance2Name: string;
  substance3Name: string;
  brandName: string;
  type: number;
  productId: number;
  isAdmin: boolean;
}
export interface IGetTopItemsListReturn {
  marketplace_list: {
    ranking: number;
    productId: number;

    Product: IProduct;

    updatedAt: Date;
  }[];
  marketplace_list_aggregate: { aggregate: { totalCount: number } };
}

export async function getMarketplaceTopItemsListQuery(p: IGetTopItemsList) {
  try {
    const notDeleted = u._is_null('deleted_at');
    const companyWhere = u.nest('Company', u._eq('hidden', false));

    let substanceFilter = u._or(
      u.nest('Substance1', u._ilike('name', '$product')),
      u.nest('Substance2', u._ilike('name', '$product')),
      u.nest('Substance3', u._ilike('name', '$product'))
    );
    if (p.substance2Name !== '' && p.substance3Name === '') {
      substanceFilter = u._and(
        u._or(
          u.nest('Substance1', u._ilike('name', '$product')),
          u.nest('Substance2', u._ilike('name', '$product'))
        ),
        u._or(
          u.nest('Substance1', u._ilike('name', '$product2')),
          u.nest('Substance2', u._ilike('name', '$product2'))
        )
      );
    }
    if (p.substance2Name !== '' && p.substance3Name !== '') {
      substanceFilter = u._and(
        u._or(
          u.nest('Substance1', u._ilike('name', '$product')),
          u.nest('Substance2', u._ilike('name', '$product')),
          u.nest('Substance3', u._ilike('name', '$product'))
        ),
        u._or(
          u.nest('Substance1', u._ilike('name', '$product2')),
          u.nest('Substance2', u._ilike('name', '$product2')),
          u.nest('Substance3', u._ilike('name', '$product2'))
        ),
        u._or(
          u.nest('Substance1', u._ilike('name', '$product3')),
          u.nest('Substance2', u._ilike('name', '$product3')),
          u.nest('Substance3', u._ilike('name', '$product3'))
        )
      );
    }

    const productWhere = (alias: string, isTrade?: boolean) =>
      u.nest(
        alias,
        u._and(
          notDeleted,
          companyWhere,
          substanceFilter,
          u._gte('status', 1),
          !isTrade && u._or(u._gte('created_at', '$newProductsDate'))
        )
      );
    const where = u.where(
      u._and(
        u._or(
          p.type.toString() !== TypeOrigin.trade.toString() &&
            productWhere('Product')
        )
      )
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return await p.client.query<IGetMarketplaceItemsListReturn>({
      query: gql`
        ${productFragment.fragment(p.isAdmin)}
        ${companyFragment.fragment(p.isAdmin)}
        query marketplaceListPage(
          ${u.params(
            ['$size', 'Int'],
            ['$offset', 'Int'],
            ['$product', 'String'],
            ['$product2', 'String'],
            ['$product3', 'String'],
            ['$brandName', 'String'],
            ['$date', 'timestamptz'],
            ['$newProductsDate', 'timestamptz'],
            ['$productId', 'Int']
          )}
        ) {
          marketplace_list(
            ${u.limit('$size')}

            order_by: {ranking: desc, updated_at: desc}

            ${where}
          ) {
            ranking
            productId :productid
              updatedAt: updated_at
            Product {
              ...${productFragment.name}

              Company { ...${companyFragment.name}}
            }

          

          }
          marketplace_list_aggregate(${where}) {
            aggregate { totalCount: count }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        size: 4,
        product: u.like(
          sg(() => p.substanceName, ''),
          'exact'
        ),
        product2: u.like(
          sg(() => p.substance2Name, ''),
          'exact'
        ),
        product3: u.like(
          sg(() => p.substance3Name, ''),
          'exact'
        ),
        brandName: u.like(
          sg(() => p.brandName, ''),
          'exact'
        ),
        date: new Date().toISOString(),
        newProductsDate,
        productId: p.productId,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/marketplace/query.ts -> getMarketplaceTopItemsListQuery',
      e
    );
  }
}
