import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { RequestUtils } from '../../query-utils';

export interface IUpdateComments extends IWithApolloClient {
  id: number;
  comments: string;
}
export interface IUpdateCommentsQueryReturn {
  update_requests: {
    returning: {
      id: number;
      comments: string;
    }[];
  };
}

export async function updateCommentsQuery(p: IUpdateComments) {
  try {
    return await p.client.mutate<IUpdateCommentsQueryReturn>({
      mutation: gql`
      mutation updateComments(${RequestUtils.params(
        ['$id', 'Int'],
        ['$comments', 'String'],
        ['$date', 'timestamptz']
      )}) {
        update_requests(
          ${RequestUtils.where(RequestUtils._eq('id', '$id'))}
          ${RequestUtils._set(
            ['comments', '$comments'],
            ['updated_at', '$date']
          )}
        ) {
          returning {
            ${RequestUtils.selectParams('id', 'comments')}
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        comments: p.comments,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> updateCommentsQuery',
      e
    );
  }
}
