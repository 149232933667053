import { _, media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

export const Container = styled.div<{ isModal?: boolean }>`
  ${_(media.min.md)} {
    width: 370px;
    height: 630px;
    ${(props) => (props.isModal ? 'margin: -20px 0;' : 'margin: 0;')}
    border-radius: 0 6px 6px 0;
    border-left: 1px solid ${(props) => props.theme.colors.background};
  }
  ${_(media.max.tablet)} {
    width: 100%;
    height: 100%;
    max-width: 732px;
    min-height: 397px;
    justify-content: center;
    padding: 0 28px 53px 28px;
  }
  background-color: ${(props) => props.theme.colors.white};
`;

export const ImagesContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const CompanyLogo = styled.img`
  ${_(media.min.sm)} {
    width: 127px;
    height: 71px;
    margin: 5px 10px;
  }
  ${_(media.max.xs)} {
    width: 114px;
    height: 62px;
    margin: 0 8px;
  }
  object-fit: contain;
`;

const Title = styled.div`
  ${_(media.max.xs)} {
    margin-top: 52px;
  }
  margin-top: 55px;
  margin-bottom: 16px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.4;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const TrustedByCompanies: React.FC<{ isModal?: boolean }> = ({ isModal }) => {
  return (
    <Container isModal={isModal}>
      <Title>Trusted by</Title>
      <ImagesContainer>
        <CompanyLogo src={'/img/trusted-by-1.png'} />
        <CompanyLogo src={'/img/trusted-by-2.png'} />
        <CompanyLogo src={'/img/trusted-by-3.png'} />
        <CompanyLogo src={'/img/trusted-by-4.png'} />
        <CompanyLogo src={'/img/trusted-by-5.png'} />
        <CompanyLogo src={'/img/trusted-by-6.png'} />
        <CompanyLogo src={'/img/trusted-by-7.png'} />
        <CompanyLogo src={'/img/trusted-by-8.png'} />
        <CompanyLogo src={'/img/trusted-by-9.png'} />
        <CompanyLogo src={'/img/trusted-by-10.png'} />
        <CompanyLogo src={'/img/trusted-by-11.png'} />
        <CompanyLogo src={'/img/trusted-by-12.png'} />
      </ImagesContainer>
    </Container>
  );
};

export default TrustedByCompanies;
