import styled from 'styled-components';

export const FlagListContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;

  @media (max-width: 850px) {
    align-items: baseline;
  }
`;

export const ListLabel = styled.span`
  color: ${(props) => props.theme.colors.grayDark};
  font-size: 14px;
  line-height: 22px;
`;
export const FlagsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 450px) {
    display: contents;
  }
`;

export const FlagContainer = styled.div`
  color: ${({ theme }) => theme.colors.grayExtraDark};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  margin-bottom: 8px;
  line-height: 0;
  @media (max-width: 450px) {
    margin-bottom: 8px;
  }

  @media (max-width: 1024px) {
    margin-left: 6px;

    img {
      height: 12px;
    }
  }
`;
