import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  border-radius: 17.5px;
  background-color: ${(props) =>
    props.theme.colors.errorNotificationIconBackground};
`;

const NotificationIconContainer = styled.div`
  padding: 9px;
  display: flex;
  align-items: center;
  ${media.max.sm`

  `}
`;

const NotificationTextContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.errorNotificationText};
  text-align: left;
  ${media.max.sm`

  `}
`;

const NotificationIcon = styled.img`
  width: 16px;
  height: 16px;
  object-fit: contain;
`;

interface Props {
  label: string;
}

const Notification: React.FC<Props> = ({ label }) => {
  return (
    <NotificationContainer>
      <NotificationIconContainer>
        <NotificationIcon src={'/img/notifications/error.svg'} />
      </NotificationIconContainer>
      <NotificationTextContainer>Error!{' ' + label}</NotificationTextContainer>
    </NotificationContainer>
  );
};

export default Notification;
