import { BaseThemedCssFunction, css } from 'styled-components';
/* eslint-disable */
export enum Breakpoint {
  /**
   * mobile
   */
  ExtraSmall = 'extra-small',
  /**
   * Small tablet
   */
  Small = 'small',
  /**
   * Medium tablet
   */
  Tablet = 'tablet',
  /**
   * large tablet / small laptop
   */
  Medium = 'medium',
  /**
   * laptop / desktop
   */
  Large = 'large',
  /**
   * desktop
   */
  ExtraLarge = 'extra-large',
}

interface IBreakpoint {
  min?: number | null;
  max?: number | null;
}
interface IDefaultBreakpoint extends IBreakpoint {
  breakpoint: Breakpoint;
}

export const BREAKPOINTS: IDefaultBreakpoint[] = [
  {
    breakpoint: Breakpoint.ExtraSmall,
    min: null,
    max: 413,
  },
  {
    breakpoint: Breakpoint.Small,
    min: 414,
    max: 767,
  },
  {
    breakpoint: Breakpoint.Tablet,
    min: 768,
    max: 1023,
  },
  {
    breakpoint: Breakpoint.Medium,
    min: 1024,
    max: 1279,
  },
  {
    breakpoint: Breakpoint.Large,
    min: 1280,
    max: 1599,
  },
  {
    breakpoint: Breakpoint.ExtraLarge,
    min: 1600,
    max: null,
  },
];

export const BreakpointsDict = {
  extraSmall: {
    breakpoint: Breakpoint.ExtraSmall,
    min: null,
    max: 413,
  },
  small: {
    breakpoint: Breakpoint.Small,
    min: 414,
    max: 767,
  },
  tablet: {
    breakpoint: Breakpoint.Tablet,
    min: 768,
    max: 1023,
  },
  medium: {
    breakpoint: Breakpoint.Medium,
    min: 1024,
    max: 1279,
  },
  large: {
    breakpoint: Breakpoint.Large,
    min: 1280,
    max: 1599,
  },
  extraLarge: {
    breakpoint: Breakpoint.ExtraLarge,
    min: 1600,
    max: null,
  },
};

const minMaxMediaCss = ({
  min,
  max,
}: IBreakpoint): BaseThemedCssFunction<any> => (
  first: any,
  ...interpolations: any[]
) => {
  if (!min || !max) {
    throw new Error('Exact media creator must get both min and max values');
  }
  return css`
    @media ${`(min-width: ${min}px) and (max-width: ${max}px)`} {
      ${
        //
        css(first, ...interpolations)
      };
    }
  `;
};

const minMediaCss = ({ min }: IBreakpoint): BaseThemedCssFunction<any> => (
  first: any,
  ...interpolations: any[]
) => {
  if (!min) {
    throw new Error('Min media creator must get min value');
  }
  return css`
    @media ${`(min-width: ${min}px)`} {
      ${css(first, ...interpolations)};
    }
  `;
};

const maxMediaCss = ({ max }: IBreakpoint): BaseThemedCssFunction<any> => (
  first: any,
  ...interpolations: any[]
) => {
  if (!max) {
    throw new Error('Max media creator must get max value');
  }
  return css`
    @media ${`(max-width: ${max}px)`} {
      ${css(first, ...interpolations)};
    }
  `;
};

const createMediaCss = (mode: 'exact' | 'min' | 'max') => (
  breakpoint: Breakpoint
) => {
  const result = BREAKPOINTS.find((point) => point.breakpoint === breakpoint);

  if (!result) {
    throw new Error(
      `Failed to match breakpoint ${breakpoint}, make sure you pass correct enum value`
    );
  }

  const { max, min } = result;

  if (mode === 'min') {
    if (!min) {
      throw new Error(
        `Breakpoint "${result.breakpoint}" cannot be used with "min" mode`
      );
    }

    return minMediaCss(result);
  }

  if (mode === 'max') {
    if (!max) {
      throw new Error(
        `Breakpoint "${result.breakpoint}" cannot be used with "max" mode`
      );
    }

    return maxMediaCss(result);
  }

  if (min && !max) {
    return minMediaCss(result);
  }

  if (!min && max) {
    return maxMediaCss(result);
  }

  return minMaxMediaCss(result);
};

const createExactMediaCss = createMediaCss('exact');
const createMinMediaCss = createMediaCss('min');
const createMaxMediaCss = createMediaCss('max');

export const media = {
  xs: createExactMediaCss(Breakpoint.ExtraSmall),
  sm: createExactMediaCss(Breakpoint.Small),
  tablet: createExactMediaCss(Breakpoint.Tablet),
  md: createExactMediaCss(Breakpoint.Medium),
  lg: createExactMediaCss(Breakpoint.Large),
  xlg: createExactMediaCss(Breakpoint.ExtraLarge),
  custom: (breakpoint: { min: number; max: number }) =>
    minMaxMediaCss(breakpoint as any),

  min: {
    sm: createMinMediaCss(Breakpoint.Small),
    tablet: createMinMediaCss(Breakpoint.Tablet),
    md: createMinMediaCss(Breakpoint.Medium),
    lg: createMinMediaCss(Breakpoint.Large),
    xlg: createMinMediaCss(Breakpoint.ExtraLarge),
    custom: (min: number) => minMediaCss({ min }),
  },

  max: {
    xs: createMaxMediaCss(Breakpoint.ExtraSmall),
    sm: createMaxMediaCss(Breakpoint.Small),
    tablet: createMaxMediaCss(Breakpoint.Tablet),
    md: createMaxMediaCss(Breakpoint.Medium),
    lg: createMaxMediaCss(Breakpoint.Large),
    custom: (max: number) => maxMediaCss({ max }),
  },
};

const _2 = 2;
export function _(m: BaseThemedCssFunction<any>) {
  return m``.slice(0, _2);
}
