import { ApolloClient } from 'apollo-boost';
import { Dictionary, toDictionary } from '../../../utils/dictionary';

export interface CompanySelect extends ISelectItem<string> {
  country: string;
  createdBy: number;
}

export enum TypeOrigin {
  licensing,
  trade,
}

export enum SubstanceType {
  licensing,
  trade,
}

export enum UserType {
  Manufacturer,
  Distributor,
}

export enum UserTypeEnum {
  Buyer = 1,
  Seller = 2,
}

export const expectSignContractOptions: ISelectItem[] = [
  {
    value: 'in the next 2 months',
    label: 'in the next 2 months',
  },
  {
    value: 'in 2 – 4 months',
    label: 'in 2 – 4 months',
  },
  {
    value: 'in 4+ months',
    label: 'in 4+ months',
  },
];

export const months: ISelectItem[] = [
  { label: '-', value: '' },
  { label: 'January', value: '1' },
  { label: 'February', value: '2' },
  { label: 'March', value: '3' },
  { label: 'April', value: '4' },
  { label: 'May', value: '5' },
  { label: 'June', value: '6' },
  { label: 'July', value: '7' },
  { label: 'August', value: '8' },
  { label: 'September', value: '9' },
  { label: 'October', value: '10' },
  { label: 'November', value: '11' },
  { label: 'December', value: '12' },
];
export const years: ISelectItem[] = [
  { label: '-', value: '' },
  { label: '2019', value: '2019' },
  { label: '2020', value: '2020' },
  { label: '2021', value: '2021' },
  { label: '2022', value: '2022' },
  { label: '2023', value: '2023' },
  { label: '2024', value: '2024' },
  { label: '2025', value: '2025' },
  { label: '2026', value: '2026' },
  { label: '2027', value: '2027' },
  { label: '2028', value: '2028' },
  { label: '2029', value: '2029' },
  { label: '2030', value: '2030' },
];

export const documents: any[] = [
  { id: 'usdmf', name: 'USDMF (United States)' },
  { id: 'eudmf', name: 'EUDMF (European Union)' },
  { id: 'jdmf', name: 'JDMF (Japan)' },
  { id: 'kdmf', name: 'KDMF (Korea)' },
  { id: 'cdmf', name: 'CDMF (China)' },
  { id: 'cep', name: 'CEP (Certificate European Pharmacopoeia)' },
  { id: 'cos', name: 'COS (Certificate of Suitability)' },
  { id: 'tp', name: 'TP (Technical package)' },
  { id: 'ureq', name: 'Upon request', for_sale: true },
  { id: 'other', name: 'Other' },
];

export const specification: any[] = [
  { id: 'usp', name: 'USP (United States)' },
  { id: 'ep', name: 'EP (European)' },
  { id: 'bp', name: 'BP (British)' },
  { id: 'jp', name: 'JP (Japanese)' },
  { id: 'kp', name: 'KP (Korean)' },
  { id: 'cp', name: 'CP (China)' },
  { id: 'ureq', name: 'Upon request', for_sale: true },
  { id: 'other', name: 'Other' },
];

export const languages = [
  { id: 'af', name: 'Afrikaans' },
  { id: 'sq', name: 'Albanian' },
  { id: 'ar', name: 'Arabic' },
  { id: 'eu', name: 'Basque' },
  { id: 'be', name: 'Belarusian' },
  { id: 'bg', name: 'Bulgarian' },
  { id: 'ca', name: 'Catalan' },
  { id: 'zh', name: 'Chinese' },
  { id: 'hr', name: 'Croatian' },
  { id: 'cs', name: 'Czech' },
  { id: 'da', name: 'Danish' },
  { id: 'nl', name: 'Dutch' },
  { id: 'en', name: 'English' },
  { id: 'et', name: 'Estonian' },
  { id: 'fo', name: 'Faeroese' },
  { id: 'fa', name: 'Farsi' },
  { id: 'fi', name: 'Finnish' },
  { id: 'fr', name: 'French' },
  { id: 'gd', name: 'Gaelic' },
  { id: 'de', name: 'German' },
  { id: 'el', name: 'Greek' },
  { id: 'he', name: 'Hebrew' },
  { id: 'hi', name: 'Hindi' },
  { id: 'hu', name: 'Hungarian' },
  { id: 'is', name: 'Icelandic' },
  { id: 'id', name: 'Indonesian' },
  { id: 'ga', name: 'Irish' },
  { id: 'it', name: 'Italian' },
  { id: 'ja', name: 'Japanese' },
  { id: 'ko', name: 'Korean' },
  { id: 'ku', name: 'Kurdish' },
  { id: 'lv', name: 'Latvian' },
  { id: 'lt', name: 'Lithuanian' },
  { id: 'mk', name: 'Macedonian' },
  { id: 'ml', name: 'Malayalam' },
  { id: 'ms', name: 'Malaysian' },
  { id: 'mt', name: 'Maltese' },
  { id: 'no', name: 'Norwegian' },
  { id: 'nn', name: 'Norwegian' },
  { id: 'pl', name: 'Polish' },
  { id: 'pt', name: 'Portuguese' },
  { id: 'pa', name: 'Punjabi' },
  { id: 'rm', name: 'Rhaeto-Romanic' },
  { id: 'ro', name: 'Romanian' },
  { id: 'ru', name: 'Russian' },
  { id: 'sr', name: 'Serbian' },
  { id: 'sk', name: 'Slovak' },
  { id: 'sl', name: 'Slovenian' },
  { id: 'sb', name: 'Sorbian' },
  { id: 'es', name: 'Spanish' },
  { id: 'sv', name: 'Swedish' },
  { id: 'th', name: 'Thai' },
  { id: 'ts', name: 'Tsonga' },
  { id: 'tn', name: 'Tswana' },
  { id: 'tr', name: 'Turkish' },
  { id: 'uk', name: 'Ukrainian' },
  { id: 'ur', name: 'Urdu' },
  { id: 've', name: 'Venda' },
  { id: 'vi', name: 'Vietnamese' },
  { id: 'cy', name: 'Welsh' },
  { id: 'xh', name: 'Xhosa' },
  { id: 'ji', name: 'Yiddish' },
  { id: 'zu', name: 'Zulu' },
];

export const allCountriesItem = {
  id: 'ALL_COUNTRIES',
  name: 'All Countries',
  order: 1,
};

export const allCountries: {
  id: string;
  name: string;
  region?: any;
  order: number;
  trinti?: boolean;
}[] = [
  { id: 'AF', name: 'Afghanistan', region: 'asia', order: 1 },
  { id: 'africa', name: 'Africa', region: true, order: 1, trinti: true },
  { id: 'AL', name: 'Albania', region: 'europe', order: 1 },
  { id: 'DZ', name: 'Algeria', region: 'africa', order: 1 },
  { id: 'AX', name: 'Aland Islands', order: 1 },
  { id: 'AS', name: 'American Samoa', region: 'north_america', order: 1 },
  { id: 'AD', name: 'Andorra', region: 'europe', order: 1 },
  { id: 'AO', name: 'Angola', region: 'africa', order: 1 },
  { id: 'AI', name: 'Anguilla', region: 'south_america', order: 1 },
  { id: 'AQ', name: 'Antarctica', order: 1 },
  { id: 'AG', name: 'Antigua and/or Barbuda', order: 1 },
  { id: 'AR', name: 'Argentina', region: 'south_america', order: 1 },
  { id: 'AM', name: 'Armenia', region: 'europe', order: 1 },
  { id: 'AW', name: 'Aruba', region: 'south_america', order: 1 },
  { id: 'asia', name: 'Asia', region: true, order: 1, trinti: true },
  { id: 'AU', name: 'Australia', region: 'australia_nz', order: 2 },
  {
    id: 'australia_nz',
    name: 'Australia and New Zealand',
    region: true,
    order: 1,
    trinti: true,
  },
  { id: 'AT', name: 'Austria', region: 'europe', order: 2 },
  { id: 'AZ', name: 'Azerbaijan', region: 'europe', order: 1 },
  { id: 'BS', name: 'Bahamas', region: 'north_america', order: 1 },
  { id: 'BH', name: 'Bahrain', region: 'middle_east', order: 1 },
  { id: 'BD', name: 'Bangladesh', region: 'asia', order: 1 },
  { id: 'BB', name: 'Barbados', region: 'south_america', order: 1 },
  // { id: 'BY', name: 'Belarus', region: 'europe', order: 1 },
  { id: 'BE', name: 'Belgium', region: 'europe', order: 2 },
  { id: 'BZ', name: 'Belize', region: 'south_america', order: 1 },
  { id: 'BJ', name: 'Benin', region: 'africa', order: 1 },
  { id: 'BM', name: 'Bermuda', region: 'north_america', order: 1 },
  { id: 'BT', name: 'Bhutan', region: 'asia', order: 1 },
  { id: 'BO', name: 'Bolivia', region: 'south_america', order: 1 },
  { id: 'BA', name: 'Bosnia and Herzegovina', region: 'europe', order: 1 },
  { id: 'BW', name: 'Botswana', region: 'africa', order: 1 },
  { id: 'BV', name: 'Bouvet Island', order: 1 },
  { id: 'BR', name: 'Brazil', region: 'south_america', order: 1 },
  {
    id: 'IO',
    name: 'British lndian Ocean Territory',
    region: 'africa',
    order: 1,
  },
  { id: 'VG', name: 'British Virgin Islands', order: 1 },
  { id: 'BN', name: 'Brunei', region: 'asia', order: 1 },
  { id: 'BG', name: 'Bulgaria', region: 'europe', order: 2 },
  { id: 'BF', name: 'Burkina Faso', region: 'africa', order: 1 },
  { id: 'BI', name: 'Burundi', region: 'africa', order: 1 },
  { id: 'KH', name: 'Cambodia', region: 'asia', order: 1 },
  { id: 'CM', name: 'Cameroon', region: 'africa', order: 1 },
  { id: 'CA', name: 'Canada', region: 'north_america', order: 2 },
  { id: 'CV', name: 'Cape Verde', region: 'africa', order: 1 },
  { id: 'KY', name: 'Cayman Islands', region: 'south_america', order: 1 },
  { id: 'CF', name: 'Central African Republic', region: 'africa', order: 1 },
  { id: 'TD', name: 'Chad', region: 'africa', order: 1 },
  { id: 'CL', name: 'Chile', region: 'south_america', order: 1 },
  { id: 'CN', name: 'China', region: 'asia', order: 1 },
  { id: 'CX', name: 'Christmas Island', region: 'australia_nz', order: 1 },
  { id: 'CC', name: 'Cocos Islands', region: 'australia_nz', order: 1 },
  { id: 'CO', name: 'Colombia', region: 'south_america', order: 1 },
  { id: 'KM', name: 'Comoros', region: 'africa', order: 1 },
  { id: 'CK', name: 'Cook Islands', region: 'australia_nz', order: 1 },
  { id: 'CR', name: 'Costa Rica', region: 'south_america', order: 1 },
  { id: 'CW', name: 'Curacao', order: 1 },
  { id: 'HR', name: 'Croatia', region: 'europe', order: 2 },
  { id: 'CU', name: 'Cuba', region: 'south_america', order: 1 },
  { id: 'CY', name: 'Cyprus', region: 'middle_east', order: 2 },
  { id: 'CZ', name: 'Czech Republic', region: 'europe', order: 2 },
  { id: 'DK', name: 'Denmark', region: 'europe', order: 2 },
  { id: 'DJ', name: 'Djibouti', region: 'africa', order: 1 },
  { id: 'DM', name: 'Dominica', region: 'south_america', order: 1 },
  { id: 'DO', name: 'Dominican Republic', region: 'south_america', order: 1 },
  { id: 'TL', name: 'East Timor', region: 'asia', order: 1 },
  { id: 'EC', name: 'Ecuador', region: 'south_america', order: 1 },
  { id: 'EG', name: 'Egypt', region: 'middle_east', order: 1 },
  { id: 'SV', name: 'El Salvador', region: 'south_america', order: 1 },
  { id: 'GQ', name: 'Equatorial Guinea', region: 'africa', order: 1 },
  { id: 'ER', name: 'Eritrea', region: 'africa', order: 1 },
  { id: 'EE', name: 'Estonia', region: 'europe', order: 2 },
  { id: 'ET', name: 'Ethiopia', region: 'africa', order: 1 },
  {
    id: 'europe',
    name: 'Europe and CIS',
    region: true,
    order: 1,
    trinti: true,
  },
  { id: 'FK', name: 'Falkland Islands', region: 'south_america', order: 1 },
  { id: 'FO', name: 'Faroe Islands', region: 'europe', order: 1 },
  { id: 'FJ', name: 'Fiji', region: 'australia_nz', order: 1 },
  { id: 'FI', name: 'Finland', region: 'europe', order: 2 },
  { id: 'FR', name: 'France', region: 'europe', order: 2 },
  { id: 'IO', name: 'British Indian Ocean Territory', order: 1 },
  { id: 'GF', name: 'French Guiana', region: 'south_america', order: 1 },
  { id: 'PF', name: 'French Polynesia', order: 1 },
  { id: 'TF', name: 'French Southern Territories', region: 'europe', order: 1 },
  { id: 'GA', name: 'Gabon', region: 'africa', order: 1 },
  { id: 'GM', name: 'Gambia', region: 'africa', order: 1 },
  { id: 'GE', name: 'Georgia', region: 'europe', order: 1 },
  { id: 'DE', name: 'Germany', region: 'europe', order: 2 },
  { id: 'GH', name: 'Ghana', region: 'africa', order: 1 },
  { id: 'GI', name: 'Gibraltar', region: 'europe', order: 1 },
  { id: 'global', name: 'Global', region: true, order: 1, trinti: true },
  { id: 'GR', name: 'Greece', region: 'europe', order: 2 },
  { id: 'GL', name: 'Greenland', region: 'north_america', order: 1 },
  { id: 'GD', name: 'Grenada', region: 'south_america', order: 1 },
  { id: 'GP', name: 'Guadeloupe', order: 1 },
  { id: 'GU', name: 'Guam', region: 'australia_nz', order: 1 },
  { id: 'GT', name: 'Guatemala', region: 'south_america', order: 1 },
  { id: 'GN', name: 'Guinea', region: 'africa', order: 1 },
  { id: 'GW', name: 'Guinea-Bissau', region: 'africa', order: 1 },
  { id: 'GY', name: 'Guyana', region: 'south_america', order: 1 },
  { id: 'GG', name: 'Guernsey', order: 1 },
  { id: 'HT', name: 'Haiti', region: 'south_america', order: 1 },
  {
    id: 'HM',
    name: 'Heard Island and McDonald Islands',
    region: 'australia_nz',
    order: 1,
  },
  { id: 'HN', name: 'Honduras', region: 'south_america', order: 1 },
  { id: 'HK', name: 'Hong Kong', region: 'asia', order: 1 },
  { id: 'HU', name: 'Hungary', region: 'europe', order: 2 },
  { id: 'IS', name: 'Iceland', region: 'europe', order: 1 },
  { id: 'IN', name: 'India', region: 'asia', order: 1 },
  { id: 'ID', name: 'Indonesia', region: 'asia', order: 1 },
  { id: 'IR', name: 'Iran', region: 'middle_east', order: 1 },
  { id: 'IQ', name: 'Iraq', region: 'middle_east', order: 1 },
  { id: 'IE', name: 'Ireland', region: 'europe', order: 2 },
  { id: 'IL', name: 'Israel', region: 'middle_east', order: 1 },
  { id: 'IT', name: 'Italy', region: 'europe', order: 2 },
  { id: 'CI', name: 'Ivory Coast', region: 'africa', order: 1 },
  { id: 'IM', name: 'Isle of Man', order: 1 },
  { id: 'JM', name: 'Jamaica', region: 'south_america', order: 1 },
  { id: 'JP', name: 'Japan', region: 'asia', order: 2 },
  { id: 'JE', name: 'Jersey', order: 1 },
  { id: 'JO', name: 'Jordan', region: 'middle_east', order: 1 },
  { id: 'KZ', name: 'Kazakhstan', region: 'asia', order: 1 },
  { id: 'KE', name: 'Kenya', region: 'africa', order: 1 },
  { id: 'KI', name: 'Kiribati', region: 'australia_nz', order: 1 },
  { id: 'XK', name: 'Kosovo', region: 'europe', order: 1 },
  { id: 'KW', name: 'Kuwait', region: 'middle_east', order: 1 },
  { id: 'KG', name: 'Kyrgyzstan', region: 'asia', order: 1 },
  { id: 'LA', name: 'Laos', region: 'asia', order: 1 },
  { id: 'LV', name: 'Latvia', region: 'europe', order: 2 },
  { id: 'LB', name: 'Lebanon', region: 'middle_east', order: 1 },
  { id: 'LS', name: 'Lesotho', region: 'africa', order: 1 },
  { id: 'LR', name: 'Liberia', region: 'africa', order: 1 },
  { id: 'LY', name: 'Libya', region: 'africa', order: 1 },
  { id: 'LI', name: 'Liechtenstein', region: 'europe', order: 1 },
  { id: 'LT', name: 'Lithuania', region: 'europe', order: 2 },
  { id: 'LU', name: 'Luxembourg', region: 'europe', order: 2 },
  { id: 'MK', name: 'Macedonia', region: 'europe', order: 1 },
  { id: 'MG', name: 'Madagascar', region: 'africa', order: 1 },
  { id: 'MW', name: 'Malawi', region: 'africa', order: 1 },
  { id: 'MY', name: 'Malaysia', region: 'asia', order: 1 },
  { id: 'MV', name: 'Maldives', region: 'asia', order: 1 },
  { id: 'ML', name: 'Mali', region: 'africa', order: 1 },
  { id: 'MO', name: 'Macao', order: 1 },
  { id: 'MT', name: 'Malta', region: 'europe', order: 2 },
  { id: 'MH', name: 'Marshall Islands', order: 1 },
  { id: 'MQ', name: 'Martinique', region: 'south_america', order: 1 },
  { id: 'MR', name: 'Mauritania', region: 'africa', order: 1 },
  { id: 'MU', name: 'Mauritius', region: 'africa', order: 1 },
  { id: 'YT', name: 'Mayotte', region: 'africa', order: 1 },
  { id: 'MX', name: 'Mexico', region: 'north_america', order: 1 },
  { id: 'FM', name: 'Micronesia', region: 'australia_nz', order: 1 },
  {
    id: 'middle_east',
    name: 'Middle East',
    region: true,
    order: 1,
    trinti: true,
  },
  { id: 'ME', name: 'Montenegro', region: 'europe', order: 1 },
  { id: 'MD', name: 'Moldova', region: 'europe', order: 1 },
  { id: 'MC', name: 'Monaco', region: 'europe', order: 1 },
  { id: 'MN', name: 'Mongolia', region: 'asia', order: 1 },
  { id: 'MS', name: 'Montserrat', region: 'south_america', order: 1 },
  { id: 'MA', name: 'Morocco', region: 'africa', order: 1 },
  { id: 'MZ', name: 'Mozambique', region: 'africa', order: 1 },
  { id: 'MM', name: 'Myanmar', region: 'asia', order: 1 },
  { id: 'NA', name: 'Namibia', region: 'africa', order: 1 },
  { id: 'NR', name: 'Nauru', region: 'australia_nz', order: 1 },
  { id: 'NP', name: 'Nepal', region: 'asia', order: 1 },
  { id: 'NL', name: 'Netherlands', region: 'europe', order: 2 },
  { id: 'NC', name: 'New Caledonia', region: 'australia_nz', order: 1 },
  { id: 'NZ', name: 'New Zealand', region: 'australia_nz', order: 2 },
  { id: 'NI', name: 'Nicaragua', region: 'south_america', order: 1 },
  { id: 'NE', name: 'Niger', region: 'africa', order: 1 },
  { id: 'NG', name: 'Nigeria', region: 'africa', order: 1 },
  { id: 'NU', name: 'Niue', region: 'australia_nz', order: 1 },
  { id: 'NF', name: 'Norfolk Island', region: 'australia_nz', order: 1 },
  {
    id: 'north_america',
    name: 'North America',
    region: true,
    order: 1,
    trinti: true,
  },
  { id: 'KP', name: 'North Korea', region: 'asia', order: 1 },
  { id: 'MP', name: 'Northern Mariana Islands', order: 1 },
  { id: 'NO', name: 'Norway', region: 'europe', order: 2 },
  { id: 'OM', name: 'Oman', region: 'middle_east', order: 1 },
  { id: 'PK', name: 'Pakistan', region: 'asia', order: 1 },
  { id: 'PW', name: 'Palau', region: 'australia_nz', order: 1 },
  { id: 'PA', name: 'Panama', region: 'south_america', order: 1 },
  { id: 'PG', name: 'Papua New Guinea', region: 'australia_nz', order: 1 },
  { id: 'PY', name: 'Paraguay', region: 'south_america', order: 1 },
  { id: 'PE', name: 'Peru', region: 'south_america', order: 1 },
  { id: 'PH', name: 'Philippines', region: 'asia', order: 1 },
  { id: 'PN', name: 'Pitcairn', region: 'south_america', order: 1 },
  { id: 'PL', name: 'Poland', region: 'europe', order: 2 },
  { id: 'PT', name: 'Portugal', region: 'europe', order: 2 },
  { id: 'PR', name: 'Puerto Rico', region: 'south_america', order: 1 },
  { id: 'PS', name: 'Palestinian Territory', order: 1 },
  { id: 'QA', name: 'Qatar', region: 'middle_east', order: 1 },
  { id: 'CG', name: 'Republic of the Congo', region: 'africa', order: 1 },
  { id: 'CD', name: 'Democratic Republic of the Congo', order: 1 },
  { id: 'RE', name: 'Reunion', order: 1 },
  { id: 'RO', name: 'Romania', region: 'europe', order: 2 },
  // { id: 'RU', name: 'Russia', region: 'europe', order: 1 },
  { id: 'RW', name: 'Rwanda', region: 'africa', order: 1 },
  { id: 'RS', name: 'Serbia', region: 'europe', order: 1 },
  { id: 'SH', name: 'Saint Helena', order: 1 },
  {
    id: 'KN',
    name: 'Saint Kitts and Nevis',
    region: 'south_america',
    order: 1,
  },
  { id: 'LC', name: 'Saint Lucia', region: 'south_america', order: 1 },
  { id: 'PM', name: 'Saint Pierre and Miquelon', order: 1 },
  { id: 'VC', name: 'Saint Vincent and the Grenadines', order: 1 },
  { id: 'WS', name: 'Samoa', region: 'australia_nz', order: 1 },
  { id: 'BQ', name: 'Bonaire, Saint Eustatius and Saba ', order: 1 },
  { id: 'SM', name: 'San Marino', region: 'europe', order: 1 },
  { id: 'MF', name: 'Saint Martin', order: 1 },
  { id: 'ST', name: 'Sao Tome and Principe', region: 'africa', order: 1 },
  { id: 'SA', name: 'Saudi Arabia', region: 'middle_east', order: 1 },
  { id: 'SN', name: 'Senegal', region: 'africa', order: 1 },
  { id: 'SC', name: 'Seychelles', region: 'africa', order: 1 },
  { id: 'SL', name: 'Sierra Leone', region: 'africa', order: 1 },
  { id: 'SG', name: 'Singapore', region: 'asia', order: 1 },
  { id: 'SK', name: 'Slovakia', region: 'europe', order: 2 },
  { id: 'SI', name: 'Slovenia', region: 'europe', order: 2 },
  { id: 'SB', name: 'Solomon Islands', region: 'australia_nz', order: 1 },
  { id: 'SO', name: 'Somalia', region: 'africa', order: 1 },
  { id: 'ZA', name: 'South Africa', region: 'africa', order: 1 },
  {
    id: 'south_america',
    name: 'South America',
    region: true,
    order: 1,
    trinti: true,
  },
  { id: 'GS', name: 'South Georgia and the South Sandwich Islands', order: 1 },
  { id: 'KR', name: 'South Korea', region: 'asia', order: 1 },
  { id: 'ES', name: 'Spain', region: 'europe', order: 2 },
  { id: 'LK', name: 'Sri Lanka', region: 'asia', order: 1 },
  { id: 'SS', name: 'South Sudan', order: 1 },
  { id: 'SD', name: 'Sudan', region: 'africa', order: 1 },
  { id: 'SR', name: 'Suriname', region: 'south_america', order: 1 },
  { id: 'SJ', name: 'Svalbard and Jan Mayen', order: 1 },
  { id: 'SZ', name: 'Swaziland', region: 'africa', order: 1 },
  { id: 'SE', name: 'Sweden', region: 'europe', order: 2 },
  { id: 'CH', name: 'Switzerland', region: 'europe', order: 2 },
  { id: 'SY', name: 'Syria', region: 'middle_east', order: 1 },
  { id: 'SX', name: 'Sint Maarten', order: 1 },
  { id: 'TW', name: 'Taiwan', region: 'asia', order: 1 },
  { id: 'TJ', name: 'Tajikistan', region: 'asia', order: 1 },
  { id: 'TZ', name: 'Tanzania', region: 'africa', order: 1 },
  { id: 'TH', name: 'Thailand', region: 'asia', order: 1 },
  { id: 'TG', name: 'Togo', region: 'africa', order: 1 },
  { id: 'TK', name: 'Tokelau', region: 'australia_nz', order: 1 },
  { id: 'TO', name: 'Tonga', region: 'australia_nz', order: 1 },
  { id: 'TT', name: 'Trinidad and Tobago', region: 'south_america', order: 1 },
  { id: 'TN', name: 'Tunisia', region: 'africa', order: 1 },
  { id: 'TR', name: 'Turkey', region: 'middle_east', order: 1 },
  { id: 'TM', name: 'Turkmenistan', region: 'asia', order: 1 },
  { id: 'TC', name: 'Turks and Caicos Islands', order: 1 },
  { id: 'TV', name: 'Tuvalu', region: 'australia_nz', order: 1 },
  { id: 'VI', name: 'U.S. Virgin Islands', order: 1 },
  { id: 'UG', name: 'Uganda', region: 'africa', order: 1 },
  { id: 'UA', name: 'Ukraine', region: 'europe', order: 1 },
  { id: 'AE', name: 'United Arab Emirates', region: 'middle_east', order: 1 },
  { id: 'GB', name: 'United Kingdom', region: 'europe', order: 2 },
  { id: 'US', name: 'United States', region: 'north_america', order: 2 },
  { id: 'UM', name: 'United States Minor Outlying Islands', order: 1 },
  { id: 'UY', name: 'Uruguay', region: 'south_america', order: 1 },
  { id: 'UZ', name: 'Uzbekistan', region: 'asia', order: 1 },
  { id: 'VU', name: 'Vanuatu', region: 'australia_nz', order: 1 },
  { id: 'VA', name: 'Vatican', region: 'europe', order: 1 },
  { id: 'VE', name: 'Venezuela', region: 'south_america', order: 1 },
  { id: 'VN', name: 'Vietnam', region: 'asia', order: 1 },
  { id: 'WF', name: 'Wallis and Futuna', order: 1 },
  { id: 'BL', name: 'Saint Barthelemy', order: 1 },
  { id: 'EH', name: 'Western Sahara', region: 'africa', order: 1 },
  { id: 'YE', name: 'Yemen', region: 'middle_east', order: 1 },
  { id: 'ZM', name: 'Zambia', region: 'africa', order: 1 },
  { id: 'ZW', name: 'Zimbabwe', region: 'africa', order: 1 },
];

export const countriesDictionary = toDictionary(allCountries, (v) => v.id);
export const countriesNameDictionary = toDictionary(allCountries, (v) =>
  v.name.toLowerCase()
);

export const EU28Countries = [
  { id: 'AT', name: 'Austria' },
  { id: 'BE', name: 'Belgium' },
  { id: 'BG', name: 'Bulgaria' },
  { id: 'HR', name: 'Croatia' },
  { id: 'CY', name: 'Cyprus' },
  { id: 'CZ', name: 'Czech Republic' },
  { id: 'DK', name: 'Denmark' },
  { id: 'EE', name: 'Estonia' },
  { id: 'FI', name: 'Finland' },
  { id: 'FR', name: 'France' },
  { id: 'DE', name: 'Germany' },
  { id: 'GR', name: 'Greece' },
  { id: 'HU', name: 'Hungary' },
  { id: 'IE', name: 'Ireland' },
  { id: 'IT', name: 'Italy' },
  { id: 'LV', name: 'Latvia' },
  { id: 'LT', name: 'Lithuania' },
  { id: 'LU', name: 'Luxembourg' },
  { id: 'MT', name: 'Malta' },
  { id: 'NL', name: 'Netherlands' },
  { id: 'PL', name: 'Poland' },
  { id: 'PT', name: 'Portugal' },
  { id: 'RO', name: 'Romania' },
  { id: 'SK', name: 'Slovakia' },
  { id: 'SI', name: 'Slovenia' },
  { id: 'ES', name: 'Spain' },
  { id: 'SE', name: 'Sweden' },
  { id: 'GB', name: 'United Kingdom' },
];

export const requestDealTypes: { id: number; name: string }[] = [
  { id: 1, name: 'Licensing with supply' },
  { id: 2, name: 'Licensing without supply (Technology transfer)' },
  { id: 3, name: 'Distribution without licensing' },
  { id: 4, name: 'Special Import' },
  { id: 5, name: 'Parallel import' },
  { id: 6, name: 'Change of Contract Manufacturing' },
];

export const requestDealTypesDisplayList: {
  id: number;
  name: string;
}[] = [
  { id: 1, name: 'Licensing + supply' },
  { id: 2, name: 'Technology transfer' },
  { id: 3, name: 'Supply (distribution)' },
  { id: 4, name: 'Special Import' },
  { id: 5, name: 'Parallel import' },
  { id: 6, name: 'Change of Contract Manufacturing' },
];

export enum ECurrency {
  EUR = 'EUR',
  GBP = 'GBP',
  USD = 'USD',
}
export enum ECurrencySymbol {
  EUR = '€',
  GBP = '£',
  USD = '$',
}
export const currencyDictionary: Dictionary<{
  label: string;
  short: string;
}> = {
  [ECurrency.EUR]: {
    label: 'EUR Euro',
    short: ECurrency.EUR,
  },
  [ECurrency.GBP]: {
    label: 'GBP Pound sterling',
    short: ECurrency.GBP,
  },
  [ECurrency.USD]: {
    label: 'USD United States dollar',
    short: ECurrency.USD,
  },
};

export enum requestDealTypesEnum {
  LicensingWithSupply = 1,
  TechnologyTransfer = 2,
  DistributionWithoutLicensing = 3,
  SpecialImport = 4,
  ParallelImport = 5,
  ChangeOfContractManufacturing = 6,
  MANUFACTURERS_BRAND = 7,
  MY_COMPANY_BRAND = 8,
}
export const requestDealTypesDictionary: Dictionary<string> = {
  [requestDealTypesEnum.LicensingWithSupply]: 'Licensing with supply',
  [requestDealTypesEnum.TechnologyTransfer]:
    'Licensing without supply (Technology transfer)',
  [requestDealTypesEnum.DistributionWithoutLicensing]:
    'Distribution without licensing',
  [requestDealTypesEnum.SpecialImport]: 'Special Import',
  [requestDealTypesEnum.ParallelImport]: 'Parallel import',
  [requestDealTypesEnum.ChangeOfContractManufacturing]:
    'Change of Contract Manufacturing',
  [requestDealTypesEnum.MANUFACTURERS_BRAND]: 'Manufacturers brand',
  [requestDealTypesEnum.MY_COMPANY_BRAND]: 'My company brand',
};

export interface IPaginateable {
  totalCount: number;
  currentPage: number;
  pageSize: number;
}
export interface IQueryWithPagination {
  offset: number;
  limit: number;
}
export type IWithApolloClient<T = any> = T & {
  client: ApolloClient<any>;
};
export interface IProductSubstance {
  id: number;
  name: string;
  category?: number;
}

export const allSalesChannels = [
  { value: 'Wholesalers', label: 'Wholesalers' },
  { value: 'Pharmacies', label: 'Pharmacies' },
  { value: 'Hospitals', label: 'Hospitals' },
  { value: 'Government', label: 'Government' },
  { value: 'Not applicable', label: 'Not applicable' },
];

export const numberOfEmployeesChoices = [
  { value: 'Self-employed', label: 'Self-employed' },
  { value: '1–10', label: '1–10 employees' },
  { value: '11–50', label: '11–50 employees' },
  { value: '51–200', label: '51–200 employees' },
  { value: '201–500', label: '201–500 employees' },
  { value: '501–1000', label: '501–1000 employees' },
  { value: '1001–5000', label: '1001–5000 employees' },
  { value: '5001–10000', label: '5001–10000 employees' },
  { value: '10001+', label: '10001+ employees' },
];

export const annualRevenueChoices = [
  { value: 'under 10', label: 'under 10 Million Euro' },
  { value: '10 – 50', label: '10 – 50 Million Euro' },
  { value: '50 – 200', label: '50 – 200 Million Euro' },
  { value: '200 – 500', label: '200 – 500 Million Euro' },
  { value: 'over 500', label: 'over 500 Million Euro' },
];

export const competitiveProductMarketingMessage = 'Differentiated product';

export const featuresList = {
  feature_one: 'feature one',
};
// TODO fix the technology and supply value. it should swap
export const dealTypeOptions: ISelectItem[] = [
  {
    value: '1',
    label: 'Licensing + supply',
  },
  {
    value: '2',
    label: 'Technology transfer',
  },
  {
    value: '3',
    label: 'Supply (distribution)',
  },
  { value: '4', label: 'Special Import' },
  // { value: '5', label: 'Parallel import' },
  { value: '6', label: 'Change of Contract Manufacturing' },
];

export interface ISelectItemWithCategory<T = string> {
  label: string;
  value: T;
  category?: number;
}

export enum Incoterms {
  EXW = 'EXW',
  FOB = 'FOB',
  FCA = 'FCA',
  CIF = 'CIF',
  CIP = 'CIP',
}
