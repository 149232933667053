import React from 'react';
import styled from 'styled-components';

import CreateDeal from 'app/modules/deals/create-deal';
import Modal from 'app/components/modals/overrided-modal';
import { useWindowSize } from 'app/helpers/use-window-size';
import { _, media } from 'app/styles/breakpoint';
import colors from 'app/styles/colors';
import sg from 'app/utils/safe-get';
import { IProps } from './index';

//
const ModalContainer = styled.div`
  margin: 15px;
  z-index: 100;
  padding-top: 0;
  align-items: flex-start;
  ${_(media.max.lg)} {
    padding-top: 0;
    margin: 5px;
  }
  ${_(media.max.md)} {
    margin: 0;
  }
  ${_(media.max.sm)} {
    margin: 0;
  }
`;

const IconContainer = styled.div`
  width: 100%;
  text-align: right;
  position: relative;
  > div {
    position: absolute;
    z-index: 99;
    margin: 0 auto;
    right: 35px;
    top: 0;
  }
`;

const CloseIcon = styled.img`
  margin-top: 16px;
  width: 14px;
  height: 14px;
  object-fit: contain;
  cursor: pointer;
  position: fixed;
`;

const EditDealModal: React.FC<IProps> = ({ closeModal, isOpen }) => {
  const windowSize = useWindowSize();
  const minWidth = 780;
  const width = sg(() => windowSize.width || 0, 0);
  const isWide = width >= minWidth;

  const customStyles = {
    content: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderWidth: 0,
      borderRadius: 6,
      boxShadow: colors.modalShadow,
      overflow: 'auto',
      position: 'static',
      margin: 'auto',
      maxHeight: '100%',
      maxWidth: '100%',
      width: isWide ? '50%' : '100%',
    },
    overlay: {
      backgroundColor: colors.darkModalOverlay,
      zIndex: 999,
      display: 'flex',
    },
  };
  const handleClose = () => {
    closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      style={customStyles as any}
      contentLabel="Edit deal"
      ariaHideApp={false}
      shouldCloseOnOverlayClick={true}
      className="modal"
    >
      <IconContainer>
        <div onClick={handleClose}>
          <CloseIcon src={'/img/close.svg'} />
        </div>
      </IconContainer>
      <ModalContainer>
        <CreateDeal isEdit={true} closeModal={closeModal} />
      </ModalContainer>
    </Modal>
  );
};

export default EditDealModal;
