import * as saga from '@redux-saga/core/effects';
import { GAActions, GACategory, ReactGA } from 'client/tracker';
import { Fetch } from 'app/modules/requests/my-requests/my-requests-list';
import { anonymousUserId, routes } from 'app/config';
import { isBrowser } from 'app/store/utils/is-browser';
import { needMoreInfo } from 'app/utils/auth-utils';
import { Dictionary, toDictionary } from 'app/utils/dictionary';
import { logger } from 'app/utils/logger';
import sg from 'app/utils/safe-get';
import { push } from 'connected-react-router';
import { matchPath } from 'react-router';
import { IStore } from '../../types';
import {
  requestFetchNavCount,
  requestSetUserType,
  setGetStartedModalOpen,
  setNavbarCount,
  setVerifyEmailModalOpen,
} from '../auth/actions';
import { User } from '../auth/types';
import { UserTypeEnum } from '../global/types';
import { convertToProduct, IProduct } from '../products/fragments/product';
import * as A from './actions';
import { convertToDeal } from './fragments/request';
import * as MA from './messages/sagas';
import * as Query from './query';
import { IRequest } from './types';
/** Fetch requests */

function* watchFetchDeals() {
  if (!isBrowser()) {
    return;
  }
  yield saga.takeLatest(A.fetchRequests, fetchRequests);
}
function* fetchRequests({ payload }: ReturnType<typeof A.fetchRequests>) {
  try {
    const _300 = 300;
    yield saga.delay(_300);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { requests, auth }: IStore = yield saga.select();
    if (requests.hasFetched && requests.dealsIds.length === 0) {
      yield saga.put(A.hasFetched(false));
    }
    if (!requests.isFetching) {
      yield saga.put(A.isFetching(true));
    }

    const params: Params<typeof Query.getRequestsListQuery> = {
      client: payload.client,
      fetch: payload.fetch,
      filter: requests.filter,
      limit: requests.pageSize,
      offset: requests.currentPage * requests.pageSize,
      companyId:
        auth.loggedInUser && auth.loggedInUser.Company
          ? auth.loggedInUser.Company.id
          : 0,
      isAdmin: auth.loggedInUser.isAdmin,
      originFilter: requests.originFilter,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = yield saga.call(Query.getRequestsListQuery, params);

    const dealsCount = res.data.requests_aggregate.aggregate.totalCount;
    const dealsDictionary = toDictionary(
      res.data.requests,
      (v: any) => v.id,
      (v: any) =>
        ({
          ...convertToDeal(
            v,
            auth.loggedInUser.isAdmin
              ? 'admin'
              : (auth.loggedInUser && auth.loggedInUser.Company
                  ? auth.loggedInUser.Company.id
                  : 0) === v.sellerCompanyId
              ? 'seller'
              : 'buyer'
          ),

          Product: v.Product && convertToProduct(v.Product as IProduct),

          showBubble: !(
            v.lastMessage.length === 0 ||
            v.lastMessage[0].adminMessage ||
            v.uncheckedMessages.length === 0
          ),
        } as IRequest)
    );
    const dealsIds = res.data.requests.map((x: any) => x.id);
    yield saga.put(requestFetchNavCount({ client: payload.client }));

    yield saga.call(
      fetchRequestsSuccess,
      dealsDictionary,
      dealsIds,
      dealsCount
    );
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield saga.select();
    yield saga.put(
      A.setNotification({
        ...state.requests.error,
        listError: 'There was an error loading your deals.',
      })
    );
  }
}
function* fetchRequestsSuccess(
  d: Dictionary<IRequest>,
  dealsIds: number[],
  dealsCount: number
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { requests }: IStore = yield saga.select();
  yield saga.all([
    saga.put(A.setDealsIds(dealsIds)),
    saga.put(A.setDealsCache({ ...requests.dealsCache, ...d })),
    saga.put(A.setTotalCount(dealsCount)),
    saga.put(A.isFetching(false)),
    saga.put(A.hasFetched(true)),
  ]);
}

function* watchFetchArchivedDeals() {
  if (!isBrowser()) {
    return;
  }
  yield saga.takeLatest(A.fetchArchiveDeals, fetchArchivedDeals);
}
function* fetchArchivedDeals({
  payload,
}: ReturnType<typeof A.fetchArchiveDeals>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { requests, auth }: IStore = yield saga.select();

    if (requests.hasFetched && requests.archiveIds.length === 0) {
      yield saga.put(A.hasFetched(false));
    }
    if (!requests.isFetching) {
      yield saga.put(A.isFetching(true));
    }

    const params: Params<typeof Query.getArchiveRequestsListQuery> = {
      client: payload.client,
      companyId:
        auth.loggedInUser && auth.loggedInUser.Company
          ? auth.loggedInUser.Company.id
          : 0,
      isAdmin: auth.loggedInUser.isAdmin,
      filter: requests.filter,
      limit: requests.pageSize,
      offset: requests.currentPage * requests.pageSize,
      fetch: Fetch.All,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res: any = yield saga.call(Query.getArchiveRequestsListQuery, params);

    const dealsCount = res.data.requests_aggregate.aggregate.totalCount;
    const dealsDictionary = toDictionary(
      res.data.requests,
      (v: any) => v.id,
      (v) =>
        ({
          ...convertToDeal(
            v,
            auth.loggedInUser.isAdmin
              ? 'admin'
              : (auth.loggedInUser && auth.loggedInUser.Company
                  ? auth.loggedInUser.Company.id
                  : 0) === v.sellerCompanyId
              ? 'seller'
              : 'buyer'
          ),
          Product: v.Product && convertToProduct(v.Product as IProduct),
        } as IRequest)
    );
    yield saga.call(fetchArchivedSuccess, dealsDictionary, dealsCount);
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield saga.select();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    yield saga.put(
      A.setNotification({
        ...state.requests.error,
        listError: 'There was an error loading your deals.',
      })
    );
  }
}
function* fetchArchivedSuccess(d: Dictionary<IRequest>, dealsCount: number) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { requests }: IStore = yield saga.select();
  yield saga.all([
    saga.put(A.setArchiveIds(Object.keys(d).map((k) => parseFloat(k)))),
    saga.put(A.setDealsCache({ ...requests.dealsCache, ...d })),
    saga.put(A.setArchTotalCount(dealsCount)),
    saga.put(A.isFetching(false)),
    saga.put(A.hasFetched(true)),
  ]);
}
function* fetchDealFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const { payload }: ReturnType<typeof A.fetchDeal> = yield saga.take(
      A.fetchDeal
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth }: IStore = yield saga.select();
    const params: Query.IGetRequestList = {
      client: payload.client,
      id: payload.id,
      isAdmin: auth.loggedInUser.isAdmin,
      userCompany:
        auth.loggedInUser && auth.loggedInUser.Company
          ? auth.loggedInUser.Company.id
          : 0,
    };

    yield saga.put(A.fetchMessagesDone(false));
    yield saga.put(A.isFetching(true));
    yield saga.call(fetchDeal, params);
  }
}
function* fetchDeal(params: Query.IGetRequestList) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res = yield saga.call(Query.getRequestListQuery, params);

    const retDeal = res.data.requests[0];

    if (!retDeal) {
      yield saga.put(push(routes.page_not_found));
    }

    const { messages, color, ...request } = {
      ...convertToDeal(
        retDeal,
        params.isAdmin
          ? 'admin'
          : params.userCompany === retDeal.buyerCompanyId
          ? 'buyer'
          : 'seller'
      ),
      Product: retDeal.Product && convertToProduct(retDeal.Product as IProduct),

      counterProposal: sg(() => retDeal.counterProposal[0].text, undefined),
    } as IRequest;
    const {
      requests: { dealsIds, dealsCache },
    }: // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    IStore = yield saga.select();
    let ids = dealsIds;

    if (!ids.includes(request.id)) {
      ids = [...ids, request.id];
    }

    yield saga.put(A.setDealsIds(ids));
    yield saga.put(
      A.setDealsCache({
        ...dealsCache,
        [request.id]: {
          ...dealsCache[request.id],
          ...request,
          messages: (dealsCache[request.id] &&
            dealsCache[request.id].messages) || {
            totalCount: 0,
            messages: [],
          },
        },
      })
    );

    yield saga.put(A.isFetching(false));
    yield saga.put(A.hasFetched(true));
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { requests }: IStore = yield saga.select();
    yield saga.put(
      A.setNotification({
        ...requests.error,
        listError: 'There was an error loading your request.',
      })
    );
  }
}
function* createDealFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.createProductDeal> = yield saga.take(
      A.createProductDeal
    );
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth, requests }: IStore = yield saga.select();
    const dealsGroup = sg(() => action.payload.values.dealsGroup, false)
      ? action.payload.values.dealsGroup
      : `${new Date().toISOString()}_${sg(() => auth.loggedInUser.id, 0)}_${sg(
          () => action.payload.values.product!.id,
          null
        )}_`;

    const params: Params<typeof createDeal> = {
      client: action.payload.client,
      values: { ...action.payload.values, dealsGroup },
      currentUserId: sg(() => auth.loggedInUser.id, 0),
      currentUserCompanyId: sg(() => auth.loggedInUser.Company.id, 0),
      isAdmin: auth.loggedInUser.isAdmin,
    };
    if (auth.loggedIn) {
      if (
        !needMoreInfo(auth.loggedInUser) &&
        !requests.createDealForm.product
      ) {
        yield saga.put(setVerifyEmailModalOpen(false));
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const allinfo = yield saga.call(createDeal, params);
      window.scrollTo(0, 0);

      if (
        sg(() => action.payload.values.selectedProducts, null) ||
        !auth.loggedInUser.isAdmin
      ) {
        yield saga.put(
          push(
            `${routes.deals_buying}?requestId=${allinfo.data.insert_requests.returning[0].id}`
          )
        );
      } else {
        yield saga.put(
          push(
            '/request-details/' + allinfo.data.insert_requests.returning[0].id
          )
        );
      }
      yield saga.put(
        A.setCreateRequestAfterRegister({
          product: params.values.product ? params.values : undefined,
        })
      );
      const userId = sg(() => auth.loggedInUser.id, 0);
      if (userId) {
        yield saga.put(
          requestSetUserType({
            userId,
            client: action.payload.client,
            userType: UserTypeEnum.Buyer,
          })
        );
      }
      if (action.payload.values.product) {
        const email = auth.loggedInUser ? auth.loggedInUser.email : undefined;
        const anonymousUserIdItem = localStorage.getItem(anonymousUserId);

        ReactGA.event({
          category: GACategory ? GACategory.Requests : '',
          action: GAActions ? GAActions.requestProductDealCreated : '',
          label: `Product {${
            action.payload.values.product.id
          }} request created, user id [${sg(() => auth.loggedInUser.id, 0)}] `,
          email,
          anonymousUserIdItem,
        });
      }

      yield saga.put(requestFetchNavCount({ client: action.payload.client }));
    } else {
      yield saga.put(
        A.setCreateRequestAfterRegister({
          product: params.values.product ? params.values : undefined,
        })
      );
      yield saga.put(setGetStartedModalOpen(true));
      yield saga.put(setVerifyEmailModalOpen(false));
    }
  }
}
function* createDeal(params: Params<typeof Query.createRequestQuery>) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return yield saga.call(Query.createRequestQuery, params);
  } catch (e) {
    throw e;
  }
}
function* createMultipleDealsFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<
      typeof A.createMultipleProductRequests
    > = yield saga.take(A.createMultipleProductRequests);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth, requests }: IStore = yield saga.select();

    const params: Params<typeof createDeal> = {
      client: action.payload.client,
      values: action.payload.values,
      currentUserId: sg(() => auth.loggedInUser.id, 0),
      currentUserCompanyId: sg(() => auth.loggedInUser.Company.id, 0),
      isAdmin: auth.loggedInUser.isAdmin,
    };

    if (auth.loggedIn) {
      yield saga.call(createMultipleDeals, params);
      window.scrollTo(0, 0);

      const { selectedProducts } = action.payload.values;

      if (selectedProducts && selectedProducts.length > 0) {
        if (action.payload.values.product) {
          ReactGA.event({
            category: GACategory ? GACategory.Requests : '',
            action: GAActions
              ? GAActions.requestProductAlternativeDealsCreated
              : '',
            label: `Product {${
              action.payload.values.product.id
            }} alternative requests (count: ${
              selectedProducts.length
            }) created, user id [${sg(() => auth.loggedInUser.id, 0)}] `,
          });
        }
      }

      yield saga.put(requestFetchNavCount({ client: action.payload.client }));
    } else {
      yield saga.put(setGetStartedModalOpen(true));
    }
    yield saga.put(
      A.setCreateRequestAfterRegister({
        product:
          params.values.product &&
          sg(() => requests.createDealForm.product, undefined)
            ? {
                ...requests.createDealForm.product,
                selectedProducts: action.payload.values.selectedProducts,
              }
            : undefined,
      })
    );
  }
}
function* createMultipleDeals(
  params: Params<typeof Query.createMultipleRequestsQuery>
) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return yield saga.call(Query.createMultipleRequestsQuery, params);
  } catch (e) {
    throw e;
  }
}

function* changeDealStatusFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.changeDealState> = yield saga.take(
      A.changeDealState
    );

    const params: A.IChangeStatusDeal = {
      client: action.payload.client,
      id: action.payload.id,
      state: action.payload.state,
      archived: action.payload.archived,
    };

    yield saga.call(changeRequestStatus, params);
  }
}
function* changeRequestStatus(params: A.IChangeStatusDeal) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    let state: IStore = yield saga.select();
    const authUserCompanyId =
      state.auth.loggedInUser && state.auth.loggedInUser.Company
        ? state.auth.loggedInUser.Company.id
        : 0;
    const party = state.auth.loggedInUser.isAdmin
      ? 'admin'
      : state.requests.dealsCache[params.id].buyerCompanyId ===
        authUserCompanyId
      ? 'buyer'
      : state.requests.dealsCache[params.id].sellerCompanyId ===
        authUserCompanyId
      ? 'buyer'
      : undefined;
    const queryParams: Params<typeof Query.changeRequestStatus> = {
      archived: params.archived,
      client: params.client,
      id: params.id,
      state: params.state,
      party,
      companyId:
        state.auth.loggedInUser.Company && state.auth.loggedInUser.Company.id,
      senderId: state.auth.loggedInUser.id,
    };
    const res: Unpromisefy<
      ReturnType<typeof Query.changeRequestStatus>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    > = yield saga.call(Query.changeRequestStatus, queryParams);

    const request = {
      id: res!.data!.update_requests.returning[0].id,
      archivedAt: res!.data!.update_requests.returning[0].archivedAt,
      status: res!.data!.update_requests.returning[0].status,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    state = yield saga.select();
    const oldDeal = state.requests.dealsCache[request.id];
    const isAdmin = state.auth.loggedIn;
    const userCompany =
      state.auth.loggedInUser && state.auth.loggedInUser.Company
        ? state.auth.loggedInUser.Company.id
        : 0;

    if (!oldDeal.archivedAt && params.archived) {
      yield saga.all([
        saga.put(A.setTotalCount(state.requests.totalCount - 1)),
        saga.put(
          setNavbarCount({
            ...state.auth.navbarCount,
            archiveCount: state.auth.navbarCount.archiveCount + 1,
            dealCount: state.auth.navbarCount.dealCount - (isAdmin ? 1 : 0),
            dealsBuyingCount:
              state.auth.navbarCount.dealsBuyingCount -
              (isAdmin ? 0 : oldDeal.buyerCompanyId === userCompany ? 1 : 0),
            dealsSellingCount:
              state.auth.navbarCount.dealsSellingCount -
              (isAdmin ? 0 : oldDeal.sellerCompanyId === userCompany ? 1 : 0),
          })
        ),
      ]);
    }

    const dealsDictionary = {
      ...state.requests.dealsCache,
      [request.id]: {
        ...state.requests.dealsCache[request.id],
        archivedAt: request.archivedAt,
        status: request.status,
      },
    };

    yield saga.put(A.setDealsCache(dealsDictionary));
    if (params.archived) {
      const newIds = [
        ...state.requests.dealsIds.filter((x: number) => x !== request.id),
      ];
      yield saga.put(A.setDealsIds(newIds));

      yield saga.put(requestFetchNavCount({ client: params.client }));
    }
  } catch (e) {
    throw e;
  }
}
function* changeIsPaidStatusFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.setPaid> = yield saga.take(A.setPaid);

    const params: A.IChangeIsPaid = {
      client: action.payload.client,
      id: action.payload.id,
      isPaidStatus: action.payload.isPaidStatus,
    };

    yield saga.call(changeIsPaidStatus, params);
  }
}
function* changeIsPaidStatus(params: A.IChangeIsPaid) {
  try {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const res: any = yield saga.call(Query.changePaidState, params);

    const request = {
      id: res.data!.update_requests.returning[0].id,
      isPaid: res.data!.update_requests.returning[0].isPaid === 1,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.requests.dealsCache,
      [request.id]: {
        ...state.requests.dealsCache[request.id],
        isPaid: request.isPaid,
      },
    };
    yield saga.put(A.setDealsCache(dealsDictionary));
  } catch (e) {
    throw e;
  }
}
function* updateCommentsFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.updateDealComments> = yield saga.take(
      A.updateDealComments
    );

    const params: A.IUpdateComments = {
      client: action.payload.client,
      id: action.payload.id,
      comments: action.payload.comments,
    };

    yield saga.call(updateComments, params);
  }
}
function* updateComments(params: A.IUpdateComments) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.updateCommentsQuery>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    > = yield saga.call(Query.updateCommentsQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.update_requests.returning[0].id,
      comments: res.data!.update_requests.returning[0].comments,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.requests.dealsCache,
      [request.id]: {
        ...state.requests.dealsCache[request.id],
        comments: request.comments,
      },
    };
    yield saga.put(A.setDealsCache(dealsDictionary));
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> updateComments',
      e
    );
  }
}
function* setBrokerFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.setRequestBroker> = yield saga.take(
      A.setRequestBroker
    );

    const params: A.ISetBroker = {
      client: action.payload.client,
      id: action.payload.id,
      brokerId: action.payload.brokerId,
    };

    yield saga.call(setBroker, params);
  }
}
function* setBroker(params: A.ISetBroker) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.setBrokerQuery>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    > = yield saga.call(Query.setBrokerQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    const request = {
      id: res.data!.update_requests.returning[0].id,
      broker: res.data!.update_requests.returning[0].broker,
      Broker: {
        id: res.data!.update_requests.returning[0].broker,
        ...res.data!.update_requests.returning[0].Broker,
      },
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.requests.dealsCache,
      [request.id]: {
        ...state.requests.dealsCache[request.id],
        broker: request.broker,
        Broker: request.Broker as User,
      },
    };
    yield saga.put(A.setDealsCache(dealsDictionary));
  } catch (e) {
    logger.error('src/app/store/modules/requests/sagas.ts -> setBroker', e);
  }
}
function* restoreArchivedDFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const {
      payload,
    }: ReturnType<typeof A.restoreArchivedDeal> = yield saga.take(
      A.restoreArchivedDeal
    );

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth, requests }: IStore = yield saga.select();
    const authUserCompanyId =
      auth.loggedInUser && auth.loggedInUser.Company
        ? auth.loggedInUser.Company.id
        : 0;
    const params: Query.IRestoreArchivedDeal = {
      client: payload.client,
      id: payload.id,
      party: auth.loggedInUser.isAdmin
        ? 'admin'
        : requests.dealsCache[payload.id].buyerCompanyId === authUserCompanyId
        ? 'buyer'
        : requests.dealsCache[payload.id].sellerCompanyId === authUserCompanyId
        ? 'seller'
        : undefined,
    };

    yield saga.call(restoreArchivedD, params);
    yield saga.put(requestFetchNavCount({ client: payload.client }));
  }
}
function* restoreArchivedD(params: Query.IRestoreArchivedDeal) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.restoreArchivedRequestQuery>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    > = yield saga.call(Query.restoreArchivedRequestQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }

    const request = {
      id: res.data!.update_requests.returning[0].id,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield saga.select();
    const oldDeal = state.requests.dealsCache[request.id];
    const isAdmin = state.auth.loggedIn;
    const authUserCompanyId =
      state.auth.loggedInUser && state.auth.loggedInUser.Company
        ? state.auth.loggedInUser.Company.id
        : 0;

    yield saga.all([
      A.setTotalCount(state.requests.totalCount - 1),
      setNavbarCount({
        ...state.auth.navbarCount,
        archiveCount: state.auth.navbarCount.archiveCount - 1,
        dealCount: state.auth.navbarCount.dealCount + (isAdmin ? 1 : 0),
        dealsBuyingCount:
          state.auth.navbarCount.dealsBuyingCount +
          (isAdmin ? 0 : oldDeal.buyerCompanyId === authUserCompanyId ? 1 : 0),
        dealsSellingCount:
          state.auth.navbarCount.dealsSellingCount +
          (isAdmin ? 0 : oldDeal.sellerCompanyId === authUserCompanyId ? 1 : 0),
      }),
    ]);

    const dealsDictionary = {
      [request.id]: {
        ...state.requests.dealsCache[request.id],
        archivedAt: undefined,
      },
    };

    const list = [
      ...state.requests.archiveIds.filter(
        (x: any) => x.toString() !== params.id.toString()
      ),
    ];
    yield saga.put(A.setArchiveIds(list));

    yield saga.put(
      A.setDealsCache({ ...state.requests.dealsCache, ...dealsDictionary })
    );
    yield saga.put(requestFetchNavCount({ client: params.client }));
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/sagas.ts -> restoreArchivedD',
      e
    );
  }
}
function* setFilterFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    const { payload }: ReturnType<typeof A.setFilter> = yield saga.take(
      A.setFilter
    );
    yield saga.all([
      saga.put(A.setFilterValue(payload.filter)),
      saga.put(A.setCurrentPage(0)),
      payload.isArchive
        ? saga.put(
            A.fetchArchiveDeals({
              client: payload.client,
            })
          )
        : saga.put(
            A.fetchRequests({
              client: payload.client,
              fetch: payload.fetch,
            })
          ),
    ]);
  }
}
function* agreeFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    try {
      const { payload }: ReturnType<typeof A.agree> = yield saga.take(A.agree);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: IStore = yield saga.select();
      const request = state.requests.dealsCache[payload.request];
      const companyId =
        state.auth.loggedInUser && state.auth.loggedInUser.Company
          ? state.auth.loggedInUser.Company.id
          : 0;
      const params: Query.IAgree = {
        client: payload.client,
        request: payload.request,
        isBuyer: request.buyerCompanyId === companyId,
      };
      yield saga.call(Query.agreeQuery, params);
      yield saga.put(
        A.setDealsCache({
          ...state.requests.dealsCache,
          [payload.request]: {
            ...request,
            agreed: true,
          },
        })
      );
    } catch (e) {
      logger.error('src/app/store/modules/requests/sagas.ts -> agreeFlow', e);
    }
  }
}
function* dealOriginFilterFlow() {
  if (!isBrowser()) {
    return;
  }
  while (true) {
    try {
      const {
        payload,
      }: ReturnType<typeof A.setDealOriginFilter> = yield saga.take(
        A.setDealOriginFilter
      );
      yield saga.put(A.setCurrentPage(0));
      yield saga.put(A.setRequestOriginFilterAction(payload.filter));

      if (payload.isArchive) {
        yield saga.put(
          A.fetchArchiveDeals({
            client: payload.client,
          })
        );
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const state: IStore = yield saga.select();

        const fetch = matchPath(state.router.location.pathname, {
          path: routes.deals,
          exact: true,
        })
          ? Fetch.All
          : matchPath(state.router.location.pathname, {
              path: routes.deals_buying,
              exact: true,
            })
          ? Fetch.Buying
          : matchPath(state.router.location.pathname, {
              path: routes.deals_selling,
              exact: true,
            })
          ? Fetch.Selling
          : undefined;

        if (fetch === undefined) {
          throw new Error('Can not determine which data set to fetch');
        }
        yield saga.put(
          A.fetchRequests({
            client: payload.client,
            fetch,
          })
        );
      }
    } catch (e) {
      logger.err(
        'src/app/store/modules/requests/sagas.ts -> dealOriginFilterFlow',
        e
      );
    }
  }
}
function* approvingDealSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const { payload }: ReturnType<typeof A.approvingDeal> = yield saga.take(
        A.approvingDeal
      );
      const params: Params<typeof Query.approveQuery> = {
        client: payload.client,
        id: payload.id,
        approved: payload.approved,
      };
      yield saga.call(Query.approveQuery, params);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const state: IStore = yield saga.select();
      yield saga.put(
        A.setDealsCache({
          ...state.requests.dealsCache,
          [payload.id]: {
            ...state.requests.dealsCache[payload.id],
            adminApproved: payload.approved,
          },
        })
      );
    } catch (e) {
      logger.error(
        'src/app/store/modules/requests/messages/sagas.ts -> approvingDeal',
        e
      );
    }
  }
}

function* setDealLinkFlow() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    const action: ReturnType<typeof A.setRequestLink> = yield saga.take(
      A.setRequestLink
    );

    const params: A.ISetDealLink = {
      client: action.payload.client,
      id: action.payload.id,
      dealId: action.payload.dealItem ? action.payload.dealItem.id : undefined,
      dealItem: action.payload.dealItem,
    };

    yield saga.call(setRequestLink, params);
  }
}

function* setRequestLink(params: A.ISetDealLink) {
  try {
    const res: Unpromisefy<
      ReturnType<typeof Query.setDealLinkQuery>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    > = yield saga.call(Query.setDealLinkQuery, params);
    if (!res) {
      throw new Error('GraphQL returned undefined');
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const state: IStore = yield saga.select();
    const dealsDictionary = {
      ...state.requests.dealsCache,
      [params.id]: {
        ...state.requests.dealsCache[params.id],
        Deal: params.dealItem,
      },
    };
    yield saga.put(A.setDealsCache(dealsDictionary));
  } catch (e) {
    logger.error('src/app/store/modules/requests/sagas.ts -> setBroker', e);
  }
}

function* updateDealPhaseSaga() {
  if (!isBrowser()) {
    return;
  }

  while (true) {
    try {
      const { payload }: ReturnType<typeof A.updateDealPhase> = yield saga.take(
        A.updateDealPhase
      );
      const params: Params<typeof Query.setDealPhaseQuery> = {
        client: payload.client,
        requestId: payload.id,
        phase: payload.phase,
        skippedReason: payload.skippedReason,
      };

      const res: Unpromisefy<
        ReturnType<typeof Query.setDealPhaseQuery>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
      > = yield saga.call(Query.setDealPhaseQuery, params);

      if (res && res.data) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const state: IStore = yield saga.select();
        yield saga.put(
          A.setDealsCache({
            ...state.requests.dealsCache,
            [payload.id]: {
              ...state.requests.dealsCache[payload.id],
              phase: res.data.update_requests.returning[0].phase,
              skippedReason:
                res.data.update_requests.returning[0].skippedReason,
            },
          })
        );
      }
    } catch (e) {
      logger.error(
        'src/app/store/modules/requests/messages/sagas.ts -> updateDealPhaseSaga',
        e
      );
    }
  }
}

export default function* rootSaga() {
  yield saga.all([
    saga.fork(createDealFlow),
    saga.fork(createMultipleDealsFlow),
    saga.fork(watchFetchDeals),
    saga.fork(watchFetchArchivedDeals),
    saga.fork(fetchDealFlow),
    saga.fork(changeDealStatusFlow),
    saga.fork(changeIsPaidStatusFlow),
    saga.fork(setBrokerFlow),
    saga.fork(updateCommentsFlow),
    saga.fork(MA.sendMessageFlow),
    saga.fork(MA.fetchMessagesFlow),
    saga.fork(restoreArchivedDFlow),
    saga.fork(setFilterFlow),
    saga.fork(MA.acceptMessageFlow),
    saga.fork(MA.rejectMessageFlow),
    saga.fork(MA.removeChangeFlow),
    saga.fork(agreeFlow),
    saga.fork(dealOriginFilterFlow),
    saga.fork(approvingDealSaga),
    saga.fork(setDealLinkFlow),
    saga.fork(updateDealPhaseSaga),
  ]);
}

/*
const a = {
  status: 3,
  leading_price_level: null,
  offer_id: 438,
  buyer_archived_at: null,
  buyer_agree: null,
  buyer_user_id: 10782,
  admin_seen: false,
  seller_seen: false,
  seller_company_id: 456,
  old_deal_id: null,
  seller_first_seen: null,
  estimated_anual_quantity: 'not less than MOQ',
  temp_offer_id: null,
  product_id: null,
  market: null,
  admin_archived_at: null,
  archived_at: null,
  updated_at: '2019-10-08T06:11:35.229396+00:00',
  deleted_at: null,
  seller_archived_at: null,
  seller_agree: null,
  created_at: '2019-10-08T06:11:35.229396+00:00',
  id: 18801,
  seller_user_id: 6520,
  product_trade_id: null,
  temp_offer_trade_id: null,
  admin_first_seen: null,
  buyer_seen: false,
  offer_trade_id: null,
  buyer_company_id: 15447,
  broker: null,
  deal_type: 1,
  admin_approved: false,
  buyer_first_seen: null,
  is_paid: 0
};

const b = {
  company_id: 106980004
  created_at: "2019-10-08T06:34:13.394338+00:00"
  custom_fields:
  custom_label_1479151: 2127028
  custom_label_1479154: {}
  custom_label_1479157: "not less than MOQ"
  custom_label_1479160: null
  custom_label_1542721: 2124379
  custom_label_1542727: "Minoxidil"
  custom_label_1556119: "Inquiry for Minoxidil | "
  __proto__: Object
  deal_stage_id: 960475
  import_id: 18803
  name: 18803
  primary_contact_id: 1223617378
  source_id: 1184305
  status: 2
  updated_at: "2019-10-08T06:34:13.394338+00:00"
  user_id: 342592
}*/
