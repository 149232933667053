import { setVerifyEmailModalOpen } from 'app/store/modules/auth/actions';
import { IStore } from 'app/store/types';
import { connect } from 'react-redux';
import VerifyEmailModal from './verify-email-modal';

export default connect(
  (state: IStore) => {
    return {
      isOpen: state.auth.verifyEmailModalOpen && !state.auth.resetPasswordModal,
      email: state.auth.userSettings.email,
    };
  },
  (dispatch) => {
    return {
      closeModal: () => dispatch(setVerifyEmailModalOpen(false)),
    };
  }
)(VerifyEmailModal);
