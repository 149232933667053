import { createActionCreator as c } from '@reduxify/utils';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { Dictionary } from 'app/utils/dictionary';
import { CompanyContactFields, ICompanyContact, Notifications } from './types';

interface ICreateCompanyConatect extends IWithApolloClient {
  values: CompanyContactFields;
}
const createCompanyContact = c<ICreateCompanyConatect>(
  'compcontact/CREATE_COMPANY_CONTACT'
);
export { createCompanyContact };

const setCompaniesContactNotification = c<Notifications>(
  'compcontact/SET_NOTIFICATION'
);
export { setCompaniesContactNotification };

const setCompaniesContactIds = c<number[]>('compcontact/SET_IDS');
export { setCompaniesContactIds };

const setCompaniesContactsCache = c<Dictionary<ICompanyContact>>(
  'compcontact/SET_CACHE'
);
export { setCompaniesContactsCache };

const isFetching = c<boolean>('compcontact/SET_FETCHING');
export { isFetching };

const hasFetched = c<boolean>('compcontact/SET_FETCHED');
export { hasFetched };

const setContactModalOpen = c<boolean>('compcontact/SET_MODAL_STATE');
export { setContactModalOpen };

const setAssetsModalOpen = c<boolean>(
  'compcontact/SET_SELL_ASSETS_MODAL_STATE'
);
export { setAssetsModalOpen };

export interface IDirectoryCompanyListing extends IWithApolloClient {
  company: string;
  email: string;
  url: string;
  buttonLocation: string;
}
export const directoryCompanyListing = c<IDirectoryCompanyListing>(
  'companiesdirectories/send-request'
);
