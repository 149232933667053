import { countriesNameDictionary } from 'app/store/modules/global/types';

interface ICountry {
  countryCode?: string;
  countryName: string;
}
interface IRegionCountry {
  [key: string]: ICountry[];
}

const regionCountryMapping: IRegionCountry = {
  Global: [{ countryName: 'Australia' }, { countryName: 'New Zealand' }],
  Europe: [
    { countryName: 'Albania' },
    { countryName: 'Andorra' },
    { countryName: 'Armenia' },
    { countryName: 'Austria' },
    { countryName: 'Belarus' },
    { countryName: 'Belgium' },
    { countryName: 'Bosnia and Herzegovina' },
    { countryName: 'Bulgaria' },
    { countryName: 'Croatia' },
    { countryName: 'Cyprus' },
    { countryName: 'Czech Republic' },
    { countryName: 'Denmark' },
    { countryName: 'Estonia' },
    { countryName: 'Finland' },
    { countryName: 'France' },
    { countryName: 'Germany' },
    { countryName: 'Greece' },
    { countryName: 'Hungary' },
    { countryName: 'Iceland' },
    { countryName: 'Ireland' },
    { countryName: 'Italy' },
    { countryName: 'Kosovo' },
    { countryName: 'Latvia' },
    { countryName: 'Liechtenstein' },
    { countryName: 'Lithuania' },
    { countryName: 'Luxembourg' },
    { countryName: 'Malta' },
    { countryName: 'Moldova' },
    { countryName: 'Monaco' },
    { countryName: 'Montenegro' },
    { countryName: 'Netherlands' },
    { countryName: 'Macedonia' },
    { countryName: 'Norway' },
    { countryName: 'Poland' },
    { countryName: 'Portugal' },
    { countryName: 'Romania' },
    // { countryName: 'Russia' },
    { countryName: 'San Marino' },
    { countryName: 'Serbia' },
    { countryName: 'Slovakia' },
    { countryName: 'Slovenia' },
    { countryName: 'Spain' },
    { countryName: 'Sweden' },
    { countryName: 'Switzerland' },
    { countryName: 'Turkey' },
    { countryName: 'Ukraine' },
    { countryName: 'United Kingdom' },
  ],
  'European Union': [
    { countryName: 'Austria' },
    { countryName: 'Belgium' },
    { countryName: 'Bulgaria' },
    { countryName: 'Croatia' },
    { countryName: 'Czech Republic' },
    { countryName: 'Denmark' },
    { countryName: 'Estonia' },
    { countryName: 'Finland' },
    { countryName: 'France' },
    { countryName: 'Germany' },
    { countryName: 'Greece' },
    { countryName: 'Hungary' },
    { countryName: 'Ireland' },
    { countryName: 'Italy' },
    { countryName: 'Latvia' },
    { countryName: 'Lithuania' },
    { countryName: 'Luxembourg' },
    { countryName: 'Malta' },
    { countryName: 'Netherlands' },
    { countryName: 'Poland' },
    { countryName: 'Portugal' },
    { countryName: 'Romania' },
    { countryName: 'Slovakia' },
    { countryName: 'Slovenia' },
    { countryName: 'Spain' },
    { countryName: 'Sweden' },
  ],
  CIS: [
    { countryName: 'Armenia' },
    { countryName: 'Azerbaijan' },
    { countryName: 'Belarus' },
    { countryName: 'Georgia' },
    { countryName: 'Kazakhstan' },
    { countryName: 'Kyrgyzstan' },
    { countryName: 'Moldova' },
    // { countryName: 'Russia' },
    { countryName: 'Tajikistan' },
    { countryName: 'Turkmenistan' },
    { countryName: 'Ukraine' },
    { countryName: 'Uzbekistan' },
  ],
  CEE: [
    { countryName: 'Bulgaria' },
    { countryName: 'Czech Republic' },
    { countryName: 'Estonia' },
    { countryName: 'Hungary' },
    { countryName: 'Lithuania' },
    { countryName: 'Latvia' },
    { countryName: 'Poland' },
    { countryName: 'Romania' },
    { countryName: 'Slovenia' },
    { countryName: 'Slovakia' },
  ],
  'Eastern Europe': [
    { countryName: 'Bulgaria' },
    { countryName: 'Czech Republic' },
    { countryName: 'Hungary' },
    { countryName: 'Poland' },
    { countryName: 'Romania' },
    // { countryName: 'Russia' },
    { countryName: 'Slovakia' },
    { countryName: 'Belarus' },
    { countryName: 'Moldova' },
    { countryName: 'Ukraine' },
  ],
  'Latin America': [
    { countryName: 'Brazil' },
    { countryName: 'Mexico' },
    { countryName: 'Colombia' },
    { countryName: 'Argentina' },
    { countryName: 'Peru' },
    { countryName: 'Venezuela' },
    { countryName: 'Chile' },
    { countryName: 'Guatemala' },
    { countryName: 'Ecuador' },
    { countryName: 'Cuba' },
    { countryName: 'Bolivia' },
    { countryName: 'Haiti' },
    { countryName: 'Dominican Republic' },
    { countryName: 'Honduras' },
    { countryName: 'Paraguay' },
    { countryName: 'El Salvador' },
    { countryName: 'Nicaragua' },
    { countryName: 'Costa Rica' },
    { countryName: 'Panama' },
    { countryName: 'Puerto Rico' },
    { countryName: 'Uruguay' },
    { countryName: 'Guadeloupe' },
    { countryName: 'Martinique' },
    { countryName: 'French Guiana' },
    { countryName: 'Saint Martin' },
    { countryName: 'Saint Barthelemy' },
  ],
  'North America': [
    { countryName: 'Antigua and/or Barbuda' },
    { countryName: 'Bahamas' },
    { countryName: 'Barbados' },
    { countryName: 'Belize' },
    { countryName: 'Canada' },
    { countryName: 'Costa Rica' },
    { countryName: 'Cuba' },
    { countryName: 'Dominica' },
    { countryName: 'Dominican Republic' },
    { countryName: 'El Salvador' },
    { countryName: 'Grenada' },
    { countryName: 'Guatemala' },
    { countryName: 'Haiti' },
    { countryName: 'Honduras' },
    { countryName: 'Jamaica' },
    { countryName: 'Mexico' },
    { countryName: 'Nicaragua' },
    { countryName: 'Panama' },
    { countryName: 'Trinidad and Tobago' },
    { countryName: 'United States' },
  ],
  'Central America': [
    { countryName: 'Belize' },
    { countryName: 'Costa Rica' },
    { countryName: 'El Salvador' },
    { countryName: 'Guatemala' },
    { countryName: 'Honduras' },
    { countryName: 'Mexico' },
    { countryName: 'Nicaragua' },
    { countryName: 'Panama' },
  ],
  'South America': [
    { countryName: 'Argentina' },
    { countryName: 'Bolivia' },
    { countryName: 'Brazil' },
    { countryName: 'Chile' },
    { countryName: 'Colombia' },
    { countryName: 'Ecuador' },
    { countryName: 'French Guiana' },
    { countryName: 'Guyana' },
    { countryName: 'Paraguay' },
    { countryName: 'Peru' },
    { countryName: 'Suriname' },
    { countryName: 'Uruguay' },
    { countryName: 'Venezuela' },
  ],
  GCC: [
    { countryName: 'United Arab Emirates' },
    { countryName: 'Saudi Arabia' },
    { countryName: 'Qatar' },
    { countryName: 'Oman' },
    { countryName: 'Kuwait' },
    { countryName: 'Bahrain' },
  ],
  MENA: [
    { countryName: 'Algeria' },
    { countryName: 'Bahrain' },
    { countryName: 'Egypt' },
    { countryName: 'Iran' },
    { countryName: 'Iraq' },
    { countryName: 'Israel' },
    { countryName: 'Jordan' },
    { countryName: 'Kuwait' },
    { countryName: 'Lebanon' },
    { countryName: 'Libya' },
    { countryName: 'Morocco' },
    { countryName: 'Oman' },
    { countryName: 'Qatar' },
    { countryName: 'Saudi Arabia' },
    { countryName: 'Syria' },
    { countryName: 'Tunisia' },
    { countryName: 'United Arab Emirates' },
    { countryName: 'Yemen' },
  ],
  'Middle East': [
    { countryName: 'Bahrain' },
    { countryName: 'Egypt' },
    { countryName: 'Iran' },
    { countryName: 'Iraq' },
    { countryName: 'Israel' },
    { countryName: 'Jordan' },
    { countryName: 'Kuwait' },
    { countryName: 'Lebanon' },
    { countryName: 'Oman' },
    { countryName: 'Qatar' },
    { countryName: 'Saudi Arabia' },
    { countryName: 'Syria' },
    { countryName: 'Turkey' },
    { countryName: 'United Arab Emirates' },
    { countryName: 'Yemen' },
  ],
  Asia: [
    { countryName: 'Afghanistan' },
    { countryName: 'Armenia' },
    { countryName: 'Azerbaijan' },
    { countryName: 'Bahrain' },
    { countryName: 'Bangladesh' },
    { countryName: 'Bhutan' },
    { countryName: 'Brunei' },
    { countryName: 'Cambodia' },
    { countryName: 'China' },
    { countryName: 'Cyprus' },
    { countryName: 'Georgia' },
    { countryName: 'India' },
    { countryName: 'Indonesia' },
    { countryName: 'Iran' },
    { countryName: 'Iraq' },
    { countryName: 'Israel' },
    { countryName: 'Japan' },
    { countryName: 'Jordan' },
    { countryName: 'Kazakhstan' },
    { countryName: 'Kuwait' },
    { countryName: 'Kyrgyzstan' },
    { countryName: 'Laos' },
    { countryName: 'Lebanon' },
    { countryName: 'Malaysia' },
    { countryName: 'Maldives' },
    { countryName: 'Mongolia' },
    { countryName: 'Myanmar' },
    { countryName: 'Nepal' },
    { countryName: 'North Korea' },
    { countryName: 'Oman' },
    { countryName: 'Pakistan' },
    { countryName: 'Philippines' },
    { countryName: 'Qatar' },
    // { countryName: 'Russia' },
    { countryName: 'Saudi Arabia' },
    { countryName: 'Singapore' },
    { countryName: 'South Korea' },
    { countryName: 'Sri Lanka' },
    { countryName: 'Syria' },
    { countryName: 'Taiwan' },
    { countryName: 'Tajikistan' },
    { countryName: 'Thailand' },
    { countryName: 'Turkey' },
    { countryName: 'Turkmenistan' },
    { countryName: 'United Arab Emirates' },
    { countryName: 'Uzbekistan' },
    { countryName: 'Vietnam' },
    { countryName: 'Yemen' },
  ],
  'Southeast Asia': [
    { countryName: 'Cambodia' },
    { countryName: 'Laos' },
    { countryName: 'Myanmar' },
    { countryName: 'Malaysia' },
    { countryName: 'Thailand' },
    { countryName: 'Vietnam' },
    { countryName: 'Indonesia' },
    { countryName: 'Philippines' },
    { countryName: 'Brunei' },
    { countryName: 'Singapore' },
    { countryName: 'East Timor' },
  ],
  Africa: [
    { countryName: 'Algeria' },
    { countryName: 'Angola' },
    { countryName: 'Benin' },
    { countryName: 'Botswana' },
    { countryName: 'Burkina Faso' },
    { countryName: 'Burundi' },
    { countryName: 'Cape Verde' },
    { countryName: 'Cameroon' },
    { countryName: 'Central African Republic' },
    { countryName: 'Chad' },
    { countryName: 'Comoros' },
    { countryName: 'Democratic Republic of the Congo' },
    { countryName: 'Djibouti' },
    { countryName: 'Egypt' },
    { countryName: 'Equatorial Guinea' },
    { countryName: 'Eritrea' },
    { countryName: 'Swaziland' },
    { countryName: 'Ethiopia' },
    { countryName: 'Gabon' },
    { countryName: 'Gambia' },
    { countryName: 'Ghana' },
    { countryName: 'Guinea' },
    { countryName: 'Guinea-Bissau' },
    { countryName: 'Kenya' },
    { countryName: 'Lesotho' },
    { countryName: 'Liberia' },
    { countryName: 'Libya' },
    { countryName: 'Madagascar' },
    { countryName: 'Malawi' },
    { countryName: 'Mali' },
    { countryName: 'Mauritania' },
    { countryName: 'Mauritius' },
    { countryName: 'Morocco' },
    { countryName: 'Mozambique' },
    { countryName: 'Namibia' },
    { countryName: 'Niger' },
    { countryName: 'Nigeria' },
    { countryName: 'Rwanda' },
    { countryName: 'Sao Tome and Principe' },
    { countryName: 'Senegal' },
    { countryName: 'Seychelles' },
    { countryName: 'Sierra Leone' },
    { countryName: 'Somalia' },
    { countryName: 'South Africa' },
    { countryName: 'South Sudan' },
    { countryName: 'Sudan' },
    { countryName: 'Tanzania' },
    { countryName: 'Togo' },
    { countryName: 'Tunisia' },
    { countryName: 'Uganda' },
    { countryName: 'Zambia' },
    { countryName: 'Zimbabwe' },
  ],
};

const regionCountryPair: IRegionCountry = Object.keys(
  regionCountryMapping
).reduce((acc: any, regionName: string) => {
  const countries = regionCountryMapping[regionName].map((country) => {
    country.countryCode =
      countriesNameDictionary[country.countryName.toLowerCase()]?.id;
    return country;
  }, []);
  return {
    ...acc,
    [regionName]: countries,
  };
}, {});

const hashMap = (key: string) => {
  const alterKey = { countryName: 'countryCode', countryCode: 'countryName' };
  return Object.keys(regionCountryPair)
    .reduce((acc: ICountry[], regionName) => {
      return [...acc, ...regionCountryPair[regionName]];
    }, [])
    .reduce((acc: { [key: string]: string }, country: ICountry) => {
      return { ...acc, [country[key]]: country[alterKey[key]] };
    }, {});
};

interface IHashMap {
  [key: string]: string;
}

export const countryNameHash: IHashMap = hashMap('countryName');
export const countryCodeHash: IHashMap = hashMap('countryCode');
export const allCountries: string[] = Object.keys(countryNameHash).sort();

export const getCountryCodes = (countries: string[]) => {
  return countries.map((countryName: string) => countryNameHash[countryName]);
};

export const getCountriesByCodes = (codes: string[]) => {
  return codes
    .filter((code: string) => code && code.trim())
    .map((code: string) => countryCodeHash[code]);
};

export const regionList = Object.keys(regionCountryPair).reduce(
  (acc, regionName) => {
    return {
      ...acc,
      [regionName]: regionCountryPair[regionName].map(
        (country: ICountry) => country.countryName
      ),
    };
  },
  {}
);

export const countryToRegionHash = Object.keys(regionCountryPair).reduce(
  (acc, regionName) => {
    return {
      ...acc,
      ...regionCountryPair[regionName].reduce(
        (cAcc, country: ICountry) => ({
          ...cAcc,
          [country.countryName]: regionName,
        }),
        {}
      ),
    };
  },
  {}
);
