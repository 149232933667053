import React from 'react';
import styled from 'styled-components';
import ToolTipOverlay from './tooltip-overlay';

const TooltipBubbleWrapper = styled.div`
  position: relative;
  display: inline-block;
  margin-left: 6px;
`;

const TooltipQuestion = styled.img`
  width: 14px;
  height: 14px;
  object-fit: contain;
`;

interface Props {
  tooltip?: string;
}

const ToolTip: React.FC<Props> = (props) => {
  if (!props.tooltip) {
    return null;
  }

  return (
    <TooltipBubbleWrapper>
      <ToolTipOverlay tooltip={props.tooltip}>
        <TooltipQuestion src={'/img/tooltip.svg'} />
      </ToolTipOverlay>
    </TooltipBubbleWrapper>
  );
};
export default ToolTip;
