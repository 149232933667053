import { IDealMessage } from 'app/store/modules/requests/messages/types';
import { DealMessagesUtils, UserUtils } from 'app/store/modules/query-utils';
import { IFragment } from 'app/store/modules/query-utils/fragment';
import { sg } from 'app/utils/safe-get';

export interface IDealMessageFragment {
  id: number;
  to: string;
  from: string;
  cc: string;
  subject: string;
  messageType: string;
  text: string;
  date: Date;
  senderCompany: number;
  buyerCompany: number;
  sellerCompany: number;
  fromAdmin: boolean;
  hasTable: boolean;
  hasAttachment: boolean;
  isQuotation: boolean;
  termsheetId: number;
  rejectReply: string;
  buyerSee: boolean;
  sellerSee: boolean;
  field: number;
  group: number;
  fieldFrom: string;
  fieldTo: string;
  request: number;
  sender: number;
  Sender?: {
    firstName: string;
    fullName: string;
  };
}
export const dealMessageFragment: IFragment = {
  fragment: (isAdmin, isLoggedIn) => `
    fragment dealMessageFragment on deals_messages {
      ${DealMessagesUtils.selectParams(
        'id',
        'to',
        'from',
        'cc',
        'subject',
        'text',
        ['has_table', 'hasTable'],
        ['has_attachment', 'hasAttachment'],
        ['created_at', 'date'],
        ['message_type', 'messageType'],
        ['sender_company_id', 'senderCompany'],
        ['buyer_company_id', 'buyerCompany'],
        ['seller_company_id', 'sellerCompany'],
        ['is_admin_message', 'fromAdmin'],
        ['admin_reply_text', 'rejectReply'],
        ['buyer_see', 'buyerSee'],
        ['seller_see', 'sellerSee'],
        ['field_id', 'field'],
        ['field_value_from', 'fieldFrom'],
        ['field_value_to', 'fieldTo'],
        ['group_id', 'group'],
        ['request_id', 'request'],
        ['sender_id', 'sender'],
        ['termsheet_id', 'termsheetId'],
        isAdmin && [
          'Sender',
          true,
          UserUtils.selectParams(
            ['full_name', 'fullName'],
            ['first_name', 'firstName']
          ),
        ],
        isAdmin && ['Sender_company', 'SenderCompany', 'name']
      )}
    }
  `,
  name: 'dealMessageFragment',
};
export function convertToIMessage(m: IDealMessageFragment): IDealMessage {
  const getMessageFor = () => {
    const termsheetId = sg(() => m.termsheetId, null);
    if (m.messageType) {
      return m.messageType;
    }
    if (termsheetId) {
      return 'quotation';
    }
    if (!m.buyerSee && !m.sellerSee && m.fromAdmin) {
      return 'adminNote';
    }
    if (m.buyerSee && m.sellerSee) {
      return 'both';
    }
    if (m.buyerSee) {
      return 'buyer';
    } else {
      return 'seller';
    }
  };

  return {
    id: m.id,
    to: m.to,
    from: m.from,
    cc: m.cc,
    subject: m.subject,
    date: new Date(m.date),
    fromAdmin: m.fromAdmin,
    hasTable: m.hasTable,
    hasAttachment: m.hasAttachment,
    isQuotation: m.isQuotation,
    text: m.text,
    rejectReply: m.rejectReply,
    senderCompanyId: m.senderCompany,
    status: m.rejectReply
      ? 'rejected'
      : (m.buyerSee && m.sellerSee) || m.fromAdmin
      ? 'accepted'
      : 'none',
    buyerCompany: m.buyerCompany,
    sellerCompany: m.sellerCompany,
    group: m.group,
    fieldChange: !m.field
      ? undefined
      : {
          [m.id]: {
            field: m.field,
            from: m.fieldFrom,
            to: m.fieldTo,
          },
        },
    for: getMessageFor(),
    Sender: {
      firstName: m.Sender ? m.Sender.firstName : '',
      fullName: m.Sender ? m.Sender.fullName : '',
    },
  };
}
