import { createActionCreator as c } from '@reduxify/utils';
import { IWithApolloClient } from 'app/store/modules/global/types';

interface ILoadMoreMessages extends IWithApolloClient {
  offset: number;
  limit: number;
  requestId: number;
}
const fetchMessagesAction = c<ILoadMoreMessages>('requests/FETCH_MESSAGES');
export { ILoadMoreMessages, fetchMessagesAction };

const fetchMessagesStatus = c<boolean>('requests/FETCH_MESSAGES_STATUS');
const fetchMessagesDone = c<boolean>('requests/FETCH_MESSAGES_DONE');
export { fetchMessagesStatus, fetchMessagesDone };

interface ISendMessage extends IWithApolloClient {
  text: string;
  requestId: number;
  for: 'buyer' | 'seller' | 'both' | 'adminNote' | 'quotation';
  termsheetId: number | null;
}
const sendMessageAction = c<ISendMessage>('requests/SEND_MESSAGE');
const sendMessageStatus = c<boolean>('requests/SEND_MESSAGE_STATUS');
export { sendMessageAction, sendMessageStatus, ISendMessage };

interface IAcceptMessage extends IWithApolloClient {
  id: number;
  requestId: number;
}
const acceptMessage = c<IAcceptMessage>('requests/ACCEPT_MESSAGE');
export { acceptMessage, IAcceptMessage };

interface IRejectMessage extends IAcceptMessage {
  reason: string;
}
const rejectMessage = c<IRejectMessage>('requests/REJECT_MESSAGE');
export { rejectMessage, IRejectMessage };

interface IRemoveChange extends IWithApolloClient {
  id: number;
  request: number;
}
const removeChange = c<IRemoveChange>('requests/REMOVE_CHANGE');
export { IRemoveChange, removeChange };
