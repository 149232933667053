import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';

export interface IAgree extends IWithApolloClient {
  isBuyer: boolean;
  request: number;
}
export async function agreeQuery(p: IAgree) {
  try {
    return await p.client.mutate({
      mutation: gql`
        mutation agreeWithDealMessagingTerms(${u.params(['$request', 'Int'])}) {
          update_requests(
            ${u.where(u.RequestUtils._eq('id', '$request'))}
            ${u.RequestUtils._set(
              p.isBuyer && ['buyer_agree', true],
              !p.isBuyer && ['seller_agree', true]
            )}
          ) {
            returning {
              ${u.RequestUtils.selectParams('id')}
            }
          }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        request: p.request,
      },
    });
  } catch (e) {
    logger.error('src/app/store/modules/requests/query.ts -> agreeQuery', e);
  }
}
