import SaveButton from 'app/components/buttons/save-button/save-button';
import TextInput from 'app/components/inputs/text-input';
import { ISellerContactInformation } from 'app/store/modules/seller-information/types';
import React, { useEffect, useState } from 'react';
import { ICreateContactPerson, IUpdateContactPerson } from '../index';
import {
  CancelButton,
  CancelButtonContainer,
  ContactForm,
  ContactFormActions,
  ContactFormContainer,
  DeleteButtonContainer,
  DeleteButtonIcon,
  SaveButtonContainer,
  SellerContactFormFieldContainer,
  SellerContactFormRowContainer,
} from '../styles';
import AssignMarketDropDown from './assign-market-dropdown';

interface IProps {
  contact?: ISellerContactInformation;
  companyId: number;
  createSellerContact: (person: ICreateContactPerson) => any;
  updateSellerContact: (person: IUpdateContactPerson) => any;
  deleteSellerContact: (id: number, companyId: number) => any;
  onCancel: (editDisabled: { [key: string]: boolean }) => any;
  isEdit: boolean;
}

const SellerContactForm: React.FC<IProps> = ({
  contact,
  createSellerContact,
  updateSellerContact,
  deleteSellerContact,
  companyId,
  onCancel,
  isEdit,
}) => {
  const [fieldError, setFieldError] = useState<boolean>(false);
  const [emailValidationError, setEmailValidationError] = useState<boolean>(
    false
  );
  const [phoneValidationError, setPhoneValidationError] = useState<boolean>(
    false
  );
  const [firstName, setFirstName] = useState<string | undefined>('');
  const [lastName, setLastName] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');
  const [phone, setPhone] = useState<string | undefined>('');
  const [assignedMarketsName, setAssignedMarketsName] = useState<
    string | undefined
  >('');
  const [assignedMarketsCountry, setAssignedMarketsCountry] = useState<
    string[]
  >([]);
  const getAssignedMarkets = () => {
    try {
      return JSON.parse(contact!.assignedMarkets || '[]');
    } catch (error) {
      return [];
    }
  };
  useEffect(() => {
    if (contact) {
      setFirstName(contact!.firstName);
      setLastName(contact!.lastName);
      setEmail(contact!.email);
      setPhone(contact!.phoneNumber);
      setAssignedMarketsName(contact!.assignedMarketsName);
      setAssignedMarketsCountry(getAssignedMarkets());
    }
  }, [isEdit]);
  const handleSave = () => {
    if (!firstName || !lastName || !email) {
      setFieldError(true);
      return;
    }
    if (email) {
      if (!validateEmail(email)) {
        setFieldError(true);
        setEmailValidationError(true);
        return;
      }
    }
    if (phone) {
      if (!isValidPhone(phone)) {
        setFieldError(true);
        setPhoneValidationError(true);
        return;
      }
    }
    setFieldError(false);
    setEmailValidationError(false);
    setPhoneValidationError(false);
    if (isEdit) {
      updateSellerContact({
        id: contact!.id,
        companyId,
        firstName: firstName!,
        lastName: lastName!,
        email: email!,
        phoneNumber: phone!,
        assignedMarketsName: assignedMarketsName!,
        assignMarket: JSON.stringify(assignedMarketsCountry),
      });
      onCancel({
        [contact!.id]: false,
      });
    } else {
      createSellerContact({
        companyId,
        firstName: firstName!,
        lastName: lastName!,
        email: email!,
        phoneNumber: phone!,
        assignedMarketsName: assignedMarketsName!,
        assignMarket: JSON.stringify(assignedMarketsCountry),
      });
      onCancel({ cancel: false });
    }
  };
  const handleDelete = (id: number) => {
    deleteSellerContact(id, companyId);
    onCancel({
      [contact!.id]: false,
    });
  };
  const validatePhoneNumber = (event: any) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    // eslint-disable-next-line
    if (/[^0-9\+ ()-]/.test(keyValue)) {
      event.preventDefault();
    }
  };
  const validateEmail = (emailId: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(emailId);
  };
  const isValidPhone = (phoneNumber: string) => {
    if (phoneNumber.charAt(0) !== '+') {
      return false;
    }
    // eslint-disable-next-line no-magic-numbers
    return !(phoneNumber.length < 9 || phoneNumber.length > 30);
  };
  return (
    <ContactFormContainer>
      <ContactForm>
        <SellerContactFormRowContainer>
          <SellerContactFormFieldContainer>
            <TextInput
              label="First name"
              type="text"
              error={fieldError && !firstName ? 'First name is required!' : ''}
              id="firstName"
              value={firstName}
              onChange={(val) => {
                setFirstName(val);
                if (val !== '') {
                  setFieldError(false);
                }
              }}
            />
          </SellerContactFormFieldContainer>
          <SellerContactFormFieldContainer>
            <TextInput
              label="Last name"
              type="text"
              error={fieldError && !lastName ? 'Last name is required!' : ''}
              id="lastName"
              value={lastName}
              onChange={(val) => {
                setLastName(val);
                if (val !== '') {
                  setFieldError(false);
                }
              }}
            />
          </SellerContactFormFieldContainer>
          <SellerContactFormFieldContainer>
            <TextInput
              label="Email"
              type="email"
              error={
                fieldError && !email
                  ? 'Email is required!'
                  : '' || emailValidationError
                  ? 'Email is invalid!'
                  : ''
              }
              id="email"
              value={email}
              onChange={(value) => {
                setEmail(value);
                if (value !== '') {
                  setFieldError(false);
                  setEmailValidationError(false);
                }
              }}
            />
          </SellerContactFormFieldContainer>
        </SellerContactFormRowContainer>
        <SellerContactFormRowContainer>
          <SellerContactFormFieldContainer>
            <TextInput
              error={
                fieldError && phoneValidationError
                  ? 'Phone number is invalid! Number must contain country code & valid length.'
                  : ''
              }
              label="Phone"
              type="text"
              id="phone"
              placeholder={'+44 123 1234'}
              value={phone}
              onChange={(value) => {
                setPhone(value);
                if (value !== '') {
                  setFieldError(false);
                  setPhoneValidationError(false);
                }
              }}
              onKeyPress={(e) => validatePhoneNumber(e)}
            />
          </SellerContactFormFieldContainer>
          <SellerContactFormFieldContainer width="66.7%">
            <TextInput
              label="Assigned markets name"
              placeholder="Write here as text"
              type="text"
              id="assignedMarketsName"
              value={assignedMarketsName}
              onChange={(value) => setAssignedMarketsName(value)}
            />
          </SellerContactFormFieldContainer>
        </SellerContactFormRowContainer>
        <SellerContactFormRowContainer>
          <SellerContactFormFieldContainer width="100%">
            <AssignMarketDropDown
              assignMarkets={getAssignedMarkets()}
              setSelectedCountries={setAssignedMarketsCountry}
            />
          </SellerContactFormFieldContainer>
        </SellerContactFormRowContainer>
        <SellerContactFormRowContainer>
          <SellerContactFormFieldContainer width="100%">
            <ContactFormActions>
              {isEdit && (
                <DeleteButtonContainer>
                  <DeleteButtonIcon
                    src="/img/delete-icon.svg"
                    onClick={() => handleDelete(contact!.id)}
                  />
                </DeleteButtonContainer>
              )}
              <CancelButtonContainer>
                <CancelButton
                  onClick={() => {
                    if (isEdit) {
                      onCancel({
                        [contact!.id]: false,
                      });
                    } else {
                      onCancel({ cancel: true });
                    }
                  }}
                >
                  Cancel
                </CancelButton>
              </CancelButtonContainer>
              <SaveButtonContainer>
                <SaveButton onClick={() => handleSave()} />
              </SaveButtonContainer>
            </ContactFormActions>
          </SellerContactFormFieldContainer>
        </SellerContactFormRowContainer>
      </ContactForm>
    </ContactFormContainer>
  );
};

export default SellerContactForm;
