import { logger } from 'app/utils/logger';
import gql from 'graphql-tag';
import { IWithApolloClient } from '../../global/types';
import { DealUtils, RequestUtils, UserUtils } from '../../query-utils';

export interface ISetDealBrokerType extends IWithApolloClient {
  id: number;
  broker_id: number;
}
export interface ISetDealBrokerQueryReturn {
  update_deals: {
    returning: {
      id: number;
      broker_id: number;
      Broker: { fullName: string; email: string };
    }[];
  };
}
export async function setDealBrokerQuery(p: ISetDealBrokerType) {
  try {
    return await p.client.mutate<ISetDealBrokerQueryReturn>({
      mutation: gql`
          mutation setDealBrokerQuery(${DealUtils.params(
            ['$id', 'Int'],
            ['$broker_id', 'Int'],
            ['$date', 'timestamptz']
          )}) {
              update_deals(
              ${DealUtils.where(DealUtils._eq('id', '$id'))}
              ${DealUtils._set(
                ['broker_id', '$broker_id'],
                ['updated_at', '$date']
              )}
              ) {
              returning {
                  ${DealUtils.selectParams('id', 'broker_id', [
                    'Broker',
                    true,
                    UserUtils.selectParams(['full_name', 'fullName'], 'email'),
                  ])}
              }
          }
          }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        id: p.id,
        broker_id: p.broker_id,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> setDealBrokerQuery',
      e
    );
  }
}
export interface ISetDealActiveStatusType extends IWithApolloClient {
  deal_id: number;
  is_active: boolean;
}
export interface ISetDealActiveStatusQueryReturn {
  update_requests: {
    returning: {
      id: number;
      deal_id: number;
      Deal: { id: number; buyer_users_id: number };
    }[];
  };
}
export async function setDealActiveStatusQuery(p: ISetDealActiveStatusType) {
  try {
    return await p.client.mutate<ISetDealActiveStatusQueryReturn>({
      mutation: gql`
        mutation setDealActiveStatusQuery(${RequestUtils.params(
          ['$deal_id', 'Int'],
          ['$is_active', 'Boolean'],
          ['$date', 'timestamptz']
        )}) {
          update_requests(
          ${RequestUtils.where(RequestUtils._eq('deal_id', '$deal_id'))}
          ${RequestUtils._set(
            ['is_active', '$is_active'],
            ['updated_at', '$date']
          )}
          ) {
          returning {
            ${RequestUtils.selectParams('id', 'deal_id', [
              'Deal',
              true,
              DealUtils.selectParams(['id', 'id'], 'buyer_users_id'),
            ])}
          }
        }
        }
      `,
      fetchPolicy: 'no-cache',
      variables: {
        deal_id: p.deal_id,
        is_active: p.is_active,
        date: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/requests/query.ts -> setDealActiveStatusQuery',
      e
    );
  }
}
