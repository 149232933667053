import { sg } from './safe-get';

interface Dictionary<T> {
  [name: string]: T;
}
interface Dictionary<T> {
  [name: number]: T;
}
export { Dictionary };

type IMap<T, K> = (value: T, index: number, array: T[]) => K;
function toDictionary<T, K = T>(
  data: T[] | Dictionary<K>,
  key: (value: T) => number | string,
  map?: IMap<T, K>
): Dictionary<K> {
  if (sg(() => data.length, 0) === 0) {
    return {};
  }
  const array: any[] = Array.isArray(data) ? data : toArray(data);
  return array.reduce((p: Dictionary<K>, c: T, i, a) => {
    const k = key(c);
    const v = map ? map(c, i, a) : c;
    return { ...p, [k]: v };
  }, {});
}
function toArray<T>(dictionary: Dictionary<T>): T[] {
  if (!dictionary) {
    return [];
  }
  const keys = Object.keys(dictionary);
  if (keys.length === 0) {
    return [];
  }

  return keys.map((v) => dictionary[v]);
}
function toArrayReverse<T>(dictionary: Dictionary<T>): T[] {
  if (!dictionary) {
    return [];
  }
  const keys = Object.keys(dictionary);
  if (keys.length === 0) {
    return [];
  }

  const ret = [];
  for (let i = keys.length - 1; i >= 0; i--) {
    ret.push(dictionary[keys[i]]);
  }

  return ret;
}

export { toDictionary, toArray, toArrayReverse };
