import * as u from 'app/store/modules/query-utils';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import { IMACompanyFragment, maCompanyFragment } from '../fragments/ma-company';

export interface IGetCompaniesList extends IWithApolloClient {
  limit: number;
  offset: number;
}

export interface IGetCompaniesListReturn {
  ma_company: IMACompanyFragment[];
  ma_company_aggregate: {
    aggregate: {
      totalCount: number;
    };
  };
}

export async function getCompaniesListQuery(p: IGetCompaniesList) {
  try {
    return await p.client.query({
      query: gql`
      ${maCompanyFragment.fragment()}
      query getCompaniesList(${u.params(
        ['$limit', 'Int'],
        ['$offset', 'Int']
      )}) {
        ma_company(
          ${u.offset('$offset')}
          ${u.limit('$limit')}
          ${u.orderBy('ranking', 'desc')}
          ${u.where(u._is_null('deleted_at'))}
        ) {
          ...${maCompanyFragment.name}
        }
        ma_company_aggregate (${u.where(u._is_null('deleted_at'))}){
          aggregate {
            totalCount: count
          }
        }
      }
    `,
      fetchPolicy: 'no-cache',
      variables: {
        limit: p.limit,
        offset: p.offset,
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/ma-companies/query.ts -> getCompaniesListQuery',
      e
    );
  }
}
