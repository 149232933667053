import React from 'react';
import styled from 'styled-components';

const InputContainer = styled.div<{ marginRight?: string; marginTop?: string }>`
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '24px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '34px')};
`;

const CheckboxStyle = styled.div<{
  checked: boolean;
  backgroundColor?: string;
  isError?: boolean;
}>`
  margin-right: 15px;
  width: 25px;
  height: 25px;
  background-color: ${(props) =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.colors.checkBoxBackground};
  padding: 2px;
  border: solid 1px ${(props) => props.theme.colors.white};
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  border: solid 1px ${(props) => props.theme.colors.muted};
  ${InputContainer}:hover & {
    border: solid 1px ${(props) => props.theme.colors.warning};
  }
  ${(props) =>
    props.checked && `border: solid 1px ${props.theme.colors.warning};`}
  ${(props) =>
    props.isError && `border: solid 2px ${props.theme.colors.errorMessage};`}
`;

const CheckStyles = styled.img`
  width: 12px;
  height: 9.5px;
  object-fit: contain;
  user-select: none;
`;

const Label = styled.label<{ fontSize?: string; lineHeight?: string }>`
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : '22px')};
  color: ${(props) => props.theme.colors.grayExtraDark};
  cursor: pointer;
`;

interface Props {
  value: boolean;
  label: string | React.ReactNode;
  onChange: (value: boolean) => any;
  marginRight?: string;
  marginTop?: string;
  id?: string;
  backgroundColor?: string;
  isError?: boolean;
  checkOnTextClick?: boolean;
  fontSize?: string;
  lineHeight?: string;
}

const Checkbox: React.FC<Props> = ({
  label,
  value,
  onChange,
  marginRight,
  marginTop,
  id,
  backgroundColor,
  isError,
  checkOnTextClick,
  fontSize,
  lineHeight,
}) => {
  return (
    <InputContainer
      marginRight={marginRight}
      marginTop={marginTop}
      id={typeof label === 'string' ? label : ''}
    >
      <div>
        <CheckboxStyle
          checked={value}
          onClick={(ev) => {
            ev.stopPropagation();
            onChange(!value);
          }}
          id={id}
          backgroundColor={backgroundColor}
          isError={isError}
        >
          {value && <CheckStyles src="/img/check.svg" />}
        </CheckboxStyle>
      </div>
      <div>
        {label && (
          <Label
            fontSize={fontSize ? fontSize : '14px'}
            lineHeight={lineHeight ? lineHeight : '22px'}
            onClick={(ev) => {
              if (checkOnTextClick) {
                ev.stopPropagation();
                onChange(!value);
              }
            }}
          >
            {label}
          </Label>
        )}
      </div>
    </InputContainer>
  );
};

export default Checkbox;
