import { _, media } from 'app/styles/breakpoint';
import styled from 'styled-components';

//
export const AdditionalDetailsFormWrapper = styled.div`
  border-radius: 6px;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 20px;
  padding-top: 20px;
  ${_(media.max.sm)} {
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-top: 20px;
  }
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: row;
`;

export const AdditionalDetailsFormContainer = styled.div`
  width: 550px;
  display: flex;
  flex-direction: column;

  ${_(media.max.sm)} {
    width: 100%;
  }
`;

export const Header = styled.div`
  margin-top: 4px;
  padding-left: 42px;
  padding-right: 56px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
  margin-bottom: 14px;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.63;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

export const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 42px;
  padding-right: 56px;
  ${_(media.max.tablet)} {
    padding-right: 42px;
  }
  ${_(media.max.sm)} {
    padding-right: 30px;
    padding-left: 30px;
  }
`;

export const CreateButton = styled.div`
  height: 50px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.warning};
  width: 100%;
  margin: 40px 0 30px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;
export const UpdateButton = styled.div`
  height: 50px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.warning};
  width: 100%;
  margin: 40px 0 30px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;

export const DropdownContainerItem = styled.div`
  margin-bottom: 20px;
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: ${(props) => props.theme.colors.errorMessage};
`;
export const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  margin-top: 9px;
  align-items: center;
  gap: 8px;
`;
export const StatusLabel = styled.span`
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.grayExtraDark};
`;
export const StatusTypeText = styled.p`
  font-weight: bolder;
`;
export const ToggleContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: grid;
  grid-template-columns: repeat(3, auto);
  border-radius: 2px;
  padding: 4px;
  min-height: 34px;
  margin-right: 5px;
  border: 1px solid #f0eafc;
  border-radius: 2px;

  .filter-status-option:last-child {
    margin-left: 0px;
  }
  .filter-status-option:hover,
  .filter-status-option.active {
    background-color: ${({ theme }) =>
      theme.colors.adminOnlyButtonBackgroundDark};
    color: ${({ theme }) => theme.colors.adminOnlyButtonColor};
  }
  .filter-status-option {
    align-items: center;
    color: ${({ theme }) => theme.colors.muted};
    cursor: pointer;
    padding: 0px 12px;
    font-size: 13px;
    display: flex;
    margin-left: 2px;
  }

  .filter-status-option p {
    text-align: center;
    margin: auto;
    font-weight: 600;
  }
`;
