import TextInput from 'app/components/inputs/text-input';
import { media } from 'app/styles/breakpoint';
import { useOutsideClick } from 'app/utils/outside-click';
import { isValid, toDate } from 'date-fns';
import { formatDateString } from 'app/utils/format-datestring';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import useOnClickOutside from 'use-onclickoutside';
import Datepicker, { MainCalendarContainer } from './calendar';

interface Props {
  selectedDate?: Date;
  selectedDateString?: string;
  setSelectedDate: (value: string, date?: Date) => any;
  tabindex?: string;
  label: string;
  placeholder?: string;
  error?: string;
  topMost?: boolean;
  tooltip?: string;
}

export const DetepickerContainer = styled.div`
  position: fixed;
  width: 300px;
  ${media.max.sm`
    width: 100%;
    min-width: 100px;
    left: 3px;
  `}
`;

export const TextContainer = styled.div`
  :focus-within + ${DetepickerContainer} {
    display: block;
    position: absolute;
  }
  width: 300px;
  min-width: 300px;
  max-width: 100%;
  display: flex;
  position: relative;
  ${media.max.sm`
    width: 100%;
    min-width: 100px;
  `}
`;
const Container = styled.div`
  display: flex;
  ${MainCalendarContainer} {
    margin: unset;
  }
`;
export const IconContainer = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  bottom: 0;
  display: flex;
  z-index: 2;
  cursor: pointer;
`;
export const Icon = styled.img`
  width: 15px;
  height: 15px;
  object-fit: contain;
  margin: auto;
`;

const Single: React.FC<Props> = ({
  selectedDate,
  selectedDateString = '',
  setSelectedDate,
  tabindex,
  placeholder,
  error,
  label,
  ...props
}) => {
  const [focused, setFocus] = useState(false);
  const [picker, setPicker] = useState<DOMRect | null>(null);
  const container = React.useRef<HTMLDivElement>(null);
  const target = React.useRef<HTMLElement | null>(null);
  const parent = React.useRef<HTMLDivElement>(null);
  const datePicker = React.useRef<HTMLDivElement>(null);
  const datePickerInline = React.useRef<HTMLDivElement>(null);
  useOutsideClick(
    () => (props.topMost ? setFocus(false) : undefined),
    datePicker,
    container
  );
  useOnClickOutside(datePickerInline, () => setFocus(false));
  // eslint-disable-next-line
  /*
  React.useEffect(() => {
    const handler = () => setFocus(false);
    if (
      props.topMost &&
      typeof window !== 'undefined' &&
      typeof window !== undefined &&
      window !== undefined
    ) {
      window.addEventListener('scroll', handler);
      return () => window.removeEventListener('scroll', handler);
    }
  }, []);*/

  React.useEffect(() => {
    if (!document) {
      return;
    }
    target.current = document.getElementById(
      '___tooltip-portal-render-portal-element'
    );
  }, []);
  return (
    <div ref={container}>
      <TextContainer ref={parent}>
        <TextInput
          label={label}
          placeholder={placeholder}
          value={selectedDateString}
          onChange={(val) => {
            if (isValid(toDate(new Date(val)))) {
              setSelectedDate(val, toDate(new Date(val)));
              setFocus(false);
            } else {
              setSelectedDate(val, undefined);
            }
          }}
          onFocus={() => {
            setFocus(true);
          }}
          noBorder={true}
          tabindex={tabindex}
          error={error}
          autoComplete={'off'}
          tooltip={props.tooltip}
        />
        <IconContainer
          onClick={() => {
            setFocus(true);
          }}
        >
          <Icon src={'/img/calendar.svg'} />
        </IconContainer>
      </TextContainer>
      {props.topMost &&
        focused &&
        target.current &&
        parent.current &&
        createPortal(
          <DetepickerContainer
            style={{
              opacity: picker ? 1 : 0,
              top: parent.current.getBoundingClientRect().bottom,
              left:
                Math.max(parent.current.getBoundingClientRect().left, 0) +
                Math.min(
                  ((document && document.body.clientWidth) || 0) -
                    ((picker && picker.right) || 0),
                  0
                ),
            }}
            ref={datePicker}
          >
            <MountCallback
              callback={() =>
                setPicker(
                  datePicker.current
                    ? (datePicker.current.getBoundingClientRect() as any)
                    : null
                )
              }
              unmount={() => setPicker(null)}
            >
              <Datepicker
                selected={selectedDate}
                onDateSelected={(data: any) => {
                  setSelectedDate(formatDateString(data?.date), data.date);
                  setFocus(false);
                }}
              />
            </MountCallback>
          </DetepickerContainer>,
          target.current
        )}
      {!props.topMost && focused && (
        <Container>
          <div ref={datePickerInline}>
            <Datepicker
              selected={selectedDate}
              onDateSelected={(data: any) => {
                setSelectedDate(formatDateString(data?.date), data.date);
                setFocus(false);
              }}
            />
          </div>
        </Container>
      )}
    </div>
  );
};
const MountCallback: React.FC<{
  callback: () => void;
  unmount?: () => void;
}> = (props) => {
  React.useEffect(props.callback, []);
  React.useEffect(() => props.unmount, []);
  return props.children as any;
};

export const Components = {
  DetepickerContainer,
  TextContainer,
  IconContainer,
  Icon,
};
export default Single;
