import { createReducer as cr, reduce, set } from '@reduxify/utils';
import { Dictionary } from 'app/utils/dictionary';
import { combineReducers } from 'redux';
import { OnBoardingFilter } from '../products/types';
import * as A from './actions';
import {
  DealsFilter,
  defaultPageSize,
  ICreateDealForm,
  IRequest,
  Notifications,
  State,
} from './types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default combineReducers<State>({
  dealsCache: cr<Dictionary<IRequest>>({}, reduce(A.setDealsCache, set)),
  dealsIds: cr<number[]>([], reduce(A.setDealsIds, set)),
  totalCount: cr<number>(0, reduce(A.setTotalCount, set)),
  archivedTotalCount: cr<number>(0, reduce(A.setArchTotalCount, set)),
  currentPage: cr<number>(0, reduce(A.setCurrentPage, set)),
  pageSize: cr<number>(defaultPageSize, reduce(A.setPageSize, set)),
  createDealForm: cr<ICreateDealForm>(
    {
      product: undefined,
    },
    reduce(A.setCreateRequestAfterRegister, set)
  ),
  error: cr<Notifications>(
    { listError: '', listSuccess: '', messageSendError: '' },
    reduce(A.setNotification, set)
  ),
  archiveIds: cr<number[]>([], reduce(A.setArchiveIds, set)),
  isFetching: cr<boolean>(false, reduce(A.isFetching, set)),
  hasFetched: cr<boolean>(false, reduce(A.hasFetched, set)),
  sendingMessage: cr<boolean>(false, reduce(A.sendMessageStatus, set)),
  isFetchingMessages: cr<boolean>(false, reduce(A.fetchMessagesStatus, set)),
  hasFetchedMessages: cr<boolean>(false, reduce(A.fetchMessagesDone, set)),
  filter: cr<DealsFilter>({}, reduce(A.setFilterValue, set)),
  dealExistsModalOpen: cr<boolean>(
    false,
    reduce(A.setRequestExistsModalOpen, set)
  ),
  dealExistsLink: cr<string>('', reduce(A.setRequestExistsLink, set)),
  originFilter: cr<OnBoardingFilter>(
    'all',
    reduce(A.setRequestOriginFilterAction, set)
  ),
  linkSellerRequestToDealId: cr<number>(
    0,
    reduce(A.setLinkSellerRequestToDealId, set)
  ),
  linkSellerRequestModalOpen: cr<boolean>(
    false,
    reduce(A.setLinkSellerRequestToRequestModalOpen, set)
  ),
});
