import { CustomGql } from 'app/utils/custom-graphql';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';
import { IWithApolloClient } from '../../global/types';
import * as u from '../../query-utils';

/** products - UPDATE ranking */
export interface IUpdateProductRanking extends IWithApolloClient {
  id: number;
  autoRanking?: number;
  manualRanking?: number;
  productRankingScore?: number;
  newRankingCalculationNumber: number;
  callback: () => any;
}

export async function updateProductRanking(p: IUpdateProductRanking) {
  try {
    if (p.id !== 0) {
      const t = await p.client.mutate({
        mutation: gql`
          mutation update_products_v2(${u.params(
            ['$id', 'Int'],
            ['$autoRanking', 'Int'],
            ['$manualRanking', 'Int'],
            ['$manualRanking', 'Int'],
            ['$productRankingScore ', 'Int']
          )}) {
            update_products_v2(
              ${u.where(u._eq('id', '$id'))}
              ${u._set(
                ['product_ranking_auto_score', '$autoRanking'],
                ['product_ranking_manual_score', '$manualRanking'],
                ['product_ranking_score', '$productRankingScore']
              )}
            ) {
              returning { id }
            }
          }
        `,
        fetchPolicy: 'no-cache',
        variables: {
          id: p.id,
          autoRanking: p.autoRanking,
          manualRanking: p.manualRanking,
          productRankingScore: p.productRankingScore,
        },
      });

      p.callback();
      return t;
    }
  } catch (e) {
    logger.error(
      'src/app/store/modules/admin/query.ts -> updateProductRanking',
      e
    );
  }
}

export interface IUpdateProductRankingBulk extends IWithApolloClient {
  productsList: {
    id: number;
    autoRanking: number;
    newRankingCalculationNumber: number;
    manualRanking: number;
    productRankingScore: number;
  }[];
}

export async function updateProductRankingBulk(p: IUpdateProductRankingBulk) {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      // let customGqlContainerProps: string[] = [];
      // let customGqlStringsVariables: any = {};
      let customGqlStringsArr: string[] = [];
      let customGqlContainer = ``;

      if (p?.productsList.length) {
        p?.productsList.forEach((item, i) => {
          // customGqlContainerProps = [
          //   ...customGqlContainerProps,
          //   `$whereOEML_${i}: products_v2_bool_exp!`,
          //   `$_setOEML_${i}: products_v2_set_input`,
          // ];
          // customGqlStringsVariables = {
          //   ...customGqlStringsVariables,
          //   [`whereOEML_${i}`]: { id: { _eq: item.id } },
          //   [`_setOEML_${i}`]: {
          //     ranking_score: item.autoRanking,
          //     ranking_bonus: item.manualRanking,
          //   },
          // };

          customGqlStringsArr?.push(`
          update_products_v2_2_${i}: update_products_v2 (
            where: { id: { _eq: ${item.id} } },
            _set: {
              product_ranking_auto_score: ${item.autoRanking},
              product_ranking_manual_score: ${item.manualRanking},
              product_ranking_score: ${item.productRankingScore},
            }
          ) {
            affected_rows
            returning { id }
          }
        `);
        });
      }

      customGqlContainer = `
      mutation bulk_update_products_ranking  {
        ${customGqlStringsArr?.map((item) => item)?.join(',')}
      }
      `;
      // console.log('customGqlContainerProps');
      // console.log(customGqlContainerProps);
      // console.log('customGqlStringsVariables');
      // console.log(customGqlStringsVariables);
      // console.log('customGqlStringsArr');
      // console.log(customGqlStringsArr);
      // console.log('customGqlContainer');
      // console.log(customGqlContainer);

      if (customGqlStringsArr?.length) {
        const results = await p.client.mutate({
          mutation: CustomGql(customGqlContainer),
          // variables: customGqlStringsVariables,
          fetchPolicy: 'no-cache',
        });
        resolve(results);
      }
      // customGqlContainerProps = [];
      // customGqlStringsVariables = {};
      customGqlStringsArr = [];
      customGqlContainer = ``;
    } catch (e) {
      logger.error(
        'src/app/store/modules/admin/query.ts -> updateProductRanking',
        e
      );
      reject(null);
    }
  });
}
