import { createReducer, reduce, set } from '@reduxify/utils';
import { Breakpoint } from 'app/styles/breakpoint';
import { combineReducers } from 'redux';
import {
  setBreakpoint,
  setFirstBreakpointSet,
  setServiceWorkerInstalled,
  setServiceWorkerUpdateAvailable,
  setWidth,
} from './actions';
import { defaultStore, State } from './types';

const breakpoint = createReducer(
  Breakpoint.ExtraSmall,
  reduce(setBreakpoint, set)
);
const installed = createReducer(false, reduce(setServiceWorkerInstalled, set));
const updateAvailable = createReducer(
  false,
  reduce(setServiceWorkerUpdateAvailable, set)
);

const firstBreakpointSet = createReducer(
  false,
  reduce(setFirstBreakpointSet, set)
);
export default combineReducers<State>({
  breakpoint,
  width: createReducer<number>(defaultStore.width, reduce(setWidth, set)),
  firstBreakpointSet,
  serviceWorker: combineReducers({
    installed,
    updateAvailable,
  }),
});
