import { routes } from 'app/config';
import { User } from 'app/store/modules/auth/types';
import {
  fetchRequests,
  setCurrentPage,
  setRequestBroker,
  setPageSize,
} from 'app/store/modules/requests/actions';
import { getBrokersQuery } from 'app/store/modules/requests/query';
import { setFlag_brokerChangeId } from 'app/store/modules/flags/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import { IStore } from 'app/store/types';
import { Breakpoint } from 'app/styles/breakpoint';
import sg from 'app/utils/safe-get';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { Fetch } from '../../index';
import RequestTable from './request-table';

interface IStateProps {
  breakpoint: Breakpoint;
  dealList: number[];
  totalCount: number;
  page: number;
  pageSize: number;
  isAdmin: boolean;
  companyId: number;
  loggedIn: boolean;
  isLoading: boolean;
  hasFetched: boolean;
  navbarTotalCount: number;
  iAmBuying: boolean;
  iAmSelling: boolean;
  brokerSetDeal?: number | null;
  broker?: User;
}
type Callback<T> = (options: T) => any;

interface IDispatchProps {
  fetchRequestsAction: () => any;
  setPage: (page: number) => any;
  setPageSize: (size: number) => any;
  fetchBrokers: (name: string, callback: Callback<ISelectItem[]>) => any;
  changeBroker: (id: number, brokerId: number) => any;
  closeBrokerChange: () => void;
}
interface IOwnProps {
  fetch: Fetch;
}

export interface IProps extends IStateProps, IDispatchProps {}
export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IOwnProps & IWithApolloClient, IStore>(
    (state) => {
      const isAdmin = sg(() => state.auth.loggedInUser.isAdmin, false);
      const requestsList = state.requests.dealsIds;
      const companyId = sg(() => state.auth.loggedInUser.Company.id, 0);
      let navbarTotalCount = 0;
      if (isAdmin) {
        navbarTotalCount = state.auth.navbarCount.dealNotSeenCount;
      } else {
        if (state.router.location.pathname === routes.deals_buying) {
          navbarTotalCount = state.auth.navbarCount.dealsBuyingNotSeenCount;
        }
        if (state.router.location.pathname === routes.deals_selling) {
          navbarTotalCount = state.auth.navbarCount.dealsSellingNotSeenCount;
        }
      }

      return {
        breakpoint: state.device.breakpoint,
        dealList: requestsList,
        // temp disable requests seller
        totalCount: state.requests.totalCount,
        navbarTotalCount,
        page: state.requests.currentPage,
        pageSize: state.requests.pageSize,
        isAdmin,
        companyId,
        loggedIn: state.auth.loggedIn,
        hasFetched: state.requests.hasFetched,
        isLoading: state.requests.isFetching,
        iAmBuying: state.router.location.pathname === routes.deals_buying,
        iAmSelling: state.router.location.pathname === routes.deals_selling,
        brokerSetDeal: state.flags.brokerChangeId,
        broker: sg(
          () => state.requests.dealsCache[state.flags.brokerChangeId!].Broker,
          undefined
        ),
      };
    },
    (dispatch, ownProps) => {
      return {
        fetchRequestsAction: () =>
          dispatch(
            fetchRequests({ client: ownProps.client, fetch: ownProps.fetch })
          ),
        setPage: (page) => dispatch(setCurrentPage(page)),
        setPageSize: (size) => dispatch(setPageSize(size)),
        fetchBrokers: (name: string, callback: Callback<ISelectItem[]>) => {
          return getBrokersQuery({ client: ownProps.client, name }).then(
            (r) => {
              if (r) {
                callback(
                  r.data.options.map((v) => ({
                    ...v,
                    value: v.value.toString(),
                  }))
                );

                return [
                  {
                    value: undefined,
                    label: '--Nepasirinktas--',
                  },
                  ...r.data.options,
                ];
              } else {
                return [
                  {
                    value: undefined,
                    label: '--Nepasirinktas--',
                  },
                ];
              }
            }
          );
        },
        changeBroker: (id: number, brokerId: number) => {
          dispatch<any>(
            setRequestBroker({ client: ownProps.client, id, brokerId })
          );
        },
        closeBrokerChange: () => dispatch(setFlag_brokerChangeId(null)),
      };
    }
  )(RequestTable)
);
