import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class ScrollToTop extends React.Component<RouteComponentProps<any>> {
  public componentDidUpdate(prevProps: RouteComponentProps<any>) {
    if (this.props.location && this.props.location !== prevProps.location) {
      if (
        !this.props.location.pathname.includes('/page/') ||
        this.props.location.pathname.includes(
          '/pharmaceutical/manufacturers/page/'
        ) ||
        this.props.location.pathname.includes(
          '/pharmaceutical/manufacturers/country/'
        )
      ) {
        window.scrollTo(0, 0);
      }
    }
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter<any, any>(ScrollToTop);
