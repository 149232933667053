import { ApolloClient } from 'apollo-boost';
import gql from 'graphql-tag';
import { logger } from '../../../../utils/logger';

export async function createSubscribeQuery(
  client: ApolloClient<any>,
  email: string
) {
  try {
    return await client.mutate({
      mutation: gql`
        mutation insert_subscribe_request(
          $email: String
          $agree_with_terms_and_privacy: Boolean
          $created_at: timestamptz
        ) {
          insert_subscribe_request(
            objects: [
              {
                email: $email
                agree_with_terms_and_privacy: $agree_with_terms_and_privacy
                created_at: $created_at
              }
            ]
          ) {
            returning {
              id
            }
          }
        }
      `,
      variables: {
        email,
        agree_with_terms_and_privacy: true,
        created_at: new Date().toISOString(),
      },
    });
  } catch (e) {
    logger.error(
      'src/app/store/modules/subscribe/query.ts -> createSubscribeQuery',
      e
    );
  }
}
