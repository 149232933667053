import { routes } from 'app/config';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const GoToNewProductButtonStyles = styled.button<{
  width: number;
  height: number;
  fontSize: number;
  marginBottom?: string;
  disable?: boolean;
}>`
  ${(props) =>
    props.marginBottom && 'margin-bottom: ' + props.marginBottom + ';'}
  width: ${(props) => `${props.width}px`};
  height: ${(props) => `${props.height}px`};
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.warning};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: ${(props) => `${props.fontSize}px`};
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: 0.3px;
  font-family: 'Nunito Sans';
  color: ${(props) => props.theme.colors.white};
  cursor: pointer;
  border: 0px solid;
  ${(props) => (props.disable ? 'cursor: auto; opacity: 0.5;' : '')}
  :hover {
    ${(props) =>
      props.disable
        ? `background-color: ${props.theme.colors.warning}`
        : `background-color: ${props.theme.colors.orange}`};
  }

  ${media.max.xs`
  width: 100%;
  `}
`;

const Wrapper = styled.div<{ widthFull?: boolean }>`
  display: flex;
  justify-content: center;
  ${media.max.sm`
  width: 100%;`}
  ${(props) => props.widthFull && ' & > a {width: 100%;}'}
`;

interface Props {
  showIcon?: boolean;
  width?: number;
  height?: number;
  fontSize?: number;
  marginBottom?: string;
  disable?: boolean;
}
const defaultWidth = 150;
const defaultHeight = 34;
const defaultFontSize = 13;
const GoToNewProductButton: React.FC<Props> = ({
  showIcon = true,
  width = defaultWidth,
  height = defaultHeight,
  fontSize = defaultFontSize,
  marginBottom,
  disable,
}) => {
  return (
    <Wrapper>
      <Link to={routes.products}>
        <GoToNewProductButtonStyles
          width={width}
          height={height}
          fontSize={fontSize}
          marginBottom={marginBottom}
          disabled={disable}
          disable={disable}
        >
          <div>Go to my products</div>
        </GoToNewProductButtonStyles>
      </Link>
    </Wrapper>
  );
};

export default GoToNewProductButton;
